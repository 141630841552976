import { TsaHubAction } from '../actions/index'

export const DBCONTEXT_STORAGE_KEY = 'tsahub/dbcontext'

export interface DebugData {
  dbcontext: string | null
}

const initialState: DebugData = {
  dbcontext: sessionStorage.getItem(DBCONTEXT_STORAGE_KEY),
}

export const debugReducer = (
  state: DebugData = initialState,
  action: TsaHubAction
): DebugData => {
  switch (action.type) {
    default:
      break
  }
  return state
}

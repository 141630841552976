import { IEngagementQuestionAnswer } from './clientModels'

// tslint:disable:no-any

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined
}

export function isDefinedNotNull<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return value === undefined || value === null
}

export function isNull(value: any): value is null {
  return value === null
}

/**
 * TypeScript type guard for a string.
 */
export function isString(value: any): value is string {
  return typeof value === 'string'
}

export function isStringOrUndefined(value: any): value is string | undefined {
  return value === undefined || typeof value === 'string'
}

export function isStringOrNullOrUndefined(
  value: any
): value is string | null | undefined {
  return value === undefined || value === null || typeof value === 'string'
}

export function ensureString(value: any): string {
  return (value += '')
}

/**
 * TypeScript type guard for a number.
 */
export function isNumber(value: any): value is number {
  return (
    typeof value === 'number' && isFinite(value) && Math.floor(value) === value
  )
}

/**
 * TypeScript type guard for an object.
 */
export function isObject(value: any): value is object {
  return typeof value === 'object' && value !== null
}

export function isObjectNullOrUndefined(
  value: any
): value is object | null | undefined {
  return value === undefined || value === null || typeof value === 'object'
}

/**
 * TypeScript type guard for an array of strings.
 * Checks first to see if this is an array. If the array is empty this function
 * will return true. If the array contains any elements the first one
 * is checked to see if it is a string.
 */
export function isArrayOfStrings(value: any): value is string[] {
  if (!Array.isArray(value)) {
    return false
  }
  if (value.length === 0) {
    return true
  }
  return isString(value[0])
}

/**
 * TypeScript type guard for an array of strings or undefined
 * Checks first to see if this is an array. If the array is empty this function
 * will return true. If the array contains any elements the first one
 * is checked to see if it is a string.
 */
export function isArrayOfStringsorUndefined(
  value: any
): value is string[] | undefined {
  if (Array.isArray(value) || value === undefined) {
    return true
  }

  if (value !== undefined && value.length === 0) {
    return true
  }

  return false
}

/**
 * TypeScript type guard for an array of number.
 * Checks first to see if this is an array. If the array is empty this function
 * will return true. If the array contains any elements the first one
 * is checked to see if it is a number.
 */
export function isArrayOfNumbers(value: any): value is number[] {
  if (!Array.isArray(value)) {
    return false
  }
  if (value.length === 0) {
    return true
  }
  return isNumber(value[0])
}

/**
 * Ensure that the specified value is a number. If the value is a string it
 * will be parsed and the value returned.
 * @param value
 */
export function ensureNumber(value?: string | number): number {
  if (isUndefined(value)) {
    return 0
  }
  if (isString(value)) {
    const num = parseInt(value, 10)
    return isNaN(num) ? 0 : num
  }
  return value
}

export function safeLength(value?: any): number {
  if (isUndefined(value) || isNull(value)) {
    return 0
  }
  return value.length || 0
}

export function safeAnswerLength(answer?: IEngagementQuestionAnswer): number {
  return safeLength(answer && answer.answerValue)
}

function isArrayEmpty(value: any[]) {
  return !value.length || value.every(isReallyEmpty)
}

export function isEmpty(value: any) {
  return value === undefined || value === '' || value === null
}

export function isObjectEmpty(value: any) {
  let result = true
  if (!value) {
    return result
  }
  const keys = Object.keys(value)
  for (let i = 0; result && i < keys.length; ++i) {
    result = isReallyEmpty(value[keys[i]])
  }
  return result
}

export function isReallyEmpty(value: any): boolean {
  if (Array.isArray(value)) {
    return isArrayEmpty(value)
  }
  if (typeof value === 'object') {
    return isObjectEmpty(value)
  }
  return isEmpty(value)
}

export function isNotReallyEmpty(value: any): boolean {
  return !isReallyEmpty(value)
}

export function isFalsey(value: any): boolean {
  return value === '' || value === undefined || value === null
}

import { EtlFile, File, EntityMap } from '../../clientModels'
import { isDefinedNotNull, ensureString } from '../../guards'
import { EtlFileStatus, EtlDisplayStatus, FileStatus } from '../../enums'

export function getDisplayStatusMap(
  etlFiles: EtlFile[],
  files: File[],
  externalUser: boolean
) {
  const map: EntityMap<string> = {}
  for (const etlFile of etlFiles) {
    const file = files.find(f => f.id === etlFile.fileId)
    const upload = file?.status
    map[etlFile.id] = getDisplayStatus(externalUser, etlFile, upload)
  }
  return map
}

export function getDisplayStatus(
  externalUser: boolean,
  etlFile?: EtlFile,
  uploadStatus?: string
) {
  let displayStatus = uploadStatus

  if (
    uploadStatus === 'Uploaded' &&
    isDefinedNotNull(etlFile) &&
    isDefinedNotNull(etlFile.status)
  ) {
    displayStatus = ensureString(etlFile.status)
  }

  if (externalUser) {
    displayStatus = mapToClientStatus(displayStatus || '')
  }

  return displayStatus || ''
}

function mapToClientStatus(status: string): string {
  let clientStatus = status

  switch (status) {
    case EtlDisplayStatus.DataReady:
    case EtlFileStatus.AssignEntities:
    case EtlFileStatus.AssignTaxCodes:
    case EtlFileStatus.DefineFileFormat:
    case EtlFileStatus.DefineStructure:
    case EtlFileStatus.MissingEntitiesSubmitted:
    case EtlFileStatus.NewEntitiesAssociated:
    case EtlFileStatus.Processing:
    case EtlFileStatus.ReadyToSubmit:
    case EtlFileStatus.SubmissionInProgress:
    case EtlFileStatus.SubmittedToTrapeze:
      clientStatus = FileStatus.Processing
      break
    case EtlFileStatus.ReadyToMap:
      clientStatus = FileStatus.Uploaded
      break
    case EtlFileStatus.Error:
      clientStatus = FileStatus.Uploading
  }

  return clientStatus
}

import { TsaThunkAction } from '../store'
import { ClientAccessRequestApi } from '../services/api'
import { ClientSearch } from '../clientModels'
import * as actions from './clientAccessRequestSearchActions'
import { findMatches } from './clientSearchThunks'

export const getClientsHaveNoAccess = (
  searchString: string
): TsaThunkAction => async dispatch => {
  
  if (searchString.length > 6) {
    try {
      let clientAccessSearchStatus: string
      const results: ClientSearch[] = []
      clientAccessSearchStatus = 'Processing'
      dispatch(actions.clientSearchAction({ searchString, results, clientAccessSearchStatus }))
      
      const tempResults = await ClientAccessRequestApi.getClientsHaveNoAccess(searchString)
      
      for (const result of tempResults) {
        const numberedSearchString = parseInt(searchString)
        const convertedResult: ClientSearch = {
          clientId: result.clientId,
          masterId: result.masterId,
          name: result.name,
          matchClient: result.clientId === numberedSearchString,
          matchMaster: result.masterId === numberedSearchString,
          nameMatch: findMatches(result.name, searchString),
        }
        results.push(convertedResult)
      }
      clientAccessSearchStatus = 'Success'
      dispatch(actions.clientSearchAction({ searchString, results, clientAccessSearchStatus }))
    } catch (error) {
      if (error.message !== 'AbortError') {
        dispatch(actions.clientSearchFailAction({ error }))
      }
    }
  }
}

export const submitAccessRequest = (
  clientId: number,
  masterId: number
): TsaThunkAction => async dispatch => {
  try {
    var result = await ClientAccessRequestApi.submitClientAccessRequest(clientId, masterId)

    dispatch(actions.clientSearchSubmitResultAction({submitResult : result}))

  } catch (error) {
    if (error.message !== 'AbortError') {
      dispatch(actions.clientSearchSubmitFailAction({ error }))
    }
  }
}


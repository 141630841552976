import React, { useState } from 'react'
import { FaUsers } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { acquireToken } from '../../../oidc-auth/oidcHelper'
import { AppState } from '../../../store'
import AccountMenuLayout from './AccountMenuLayout'
import { useAdminCenter } from './AdminCenterHook'
import { SupportedApplication } from './Models'
import filterSupportLinks from './filterSupportLinks'
import {
  REACT_APP_ENGAGEMENTMANAGERURL,
  REACT_APP_HOSTURL,
  REACT_APP_AUTHORIZATIONSERVICEURL,
} from '../../../envVariables'
import { ensureNumber } from '../../../guards'
import { useLocation } from 'react-router'

export interface AccountMenuLink {
  text: string
  icon: any
  url: string
}

export interface AccountMenuProperties {
  onLogout: () => void
}

export interface User {
  profile: {
    given_name: string
    family_name?: string
  }
}

export interface SupportLink {
  text: string
  url: string
}

const AccountMenu = ({ onLogout }: AccountMenuProperties) => {
  const user = useSelector((state: AppState) => state.auth.user)
  const engagementQuestions = useSelector(
    (state: AppState) => state.engagementQuestions
  )
  const engagements = useSelector((state: AppState) => state.engagements)
  const engagementTemplates = useSelector(
    (state: AppState) => state.engagementTemplates || {}
  )
  const token = acquireToken()
  const currentEnvironment: SupportedApplication = 'TSA Hub'
  const host = `${REACT_APP_AUTHORIZATIONSERVICEURL}`
  const location = useLocation()

  let taxForm = ''
  let taxYear = 0
  let enagementId = Object.keys(engagementQuestions)[0] || ''

  const applicationUrls = {
    tsa: REACT_APP_HOSTURL || '',
    engagementManager: REACT_APP_ENGAGEMENTMANAGERURL
      ? `${REACT_APP_ENGAGEMENTMANAGERURL}/engagements`
      : '',
  }

  const tokenFactory = (): Promise<string> => {
    return new Promise(resolve => {
      resolve(token)
    })
  }
  const [currentUrl, SetCurrentUrl] = React.useState('')
  const adminCenterClaims = useAdminCenter({ host, tokenFactory }) //gets list of claims from eid.

  const filterClaims = (): string[] => {
    const claimsArray: any = Object.keys(adminCenterClaims).map(
      (c: string) => adminCenterClaims[c]
    )
    const combinedClaims: string[] = [].concat.apply([], claimsArray)
    const result: string[] = combinedClaims.filter((item, index) =>
      combinedClaims.indexOf(item) === index ? item : null
    )
    return result
  }

  const support: SupportLink[] = []
  const [showGlossary, setShowGlossary] = useState<boolean>(false)
  const [filteredClaims, setFilteredClaims] = useState<string[]>(filterClaims())
  const [filteredSupportLinks, setFilteredSupportLinks] = useState<
    SupportLink[]
  >(filterSupportLinks(filteredClaims, support))

  React.useEffect(() => {
    SetCurrentUrl(window.location.href)

    const engagementsParams = location.pathname.indexOf('engagements') > -1

    if (engagementsParams) {
      if (enagementId !== '') {
        const engagement = engagements[ensureNumber(enagementId)]

        if (
          engagement?.engagementTaxForm !== '' &&
          engagement?.taxYear !== ''
        ) {
          taxForm = engagement?.engagementTaxForm || ''
          taxYear = ensureNumber(engagement?.taxYear)

          Object.values(engagementTemplates).forEach(t => {
            if (
              !!t &&
              t?.resourceType === taxForm &&
              t.taxYear === taxYear &&
              !!t.loaded
            ) {
              if (t.hasGlossary) {
                setShowGlossary(true)
              }
            }
          })
        }
      }
    }

    const requestAssistance: SupportLink = {
      text: 'Request Assistance',
      url: `${applicationUrls.tsa}/help`,
    }
    const glossary: SupportLink = {
      text: 'Glossary',
      url: `${applicationUrls.tsa}/help/glossary/formName/${taxForm}/taxYear/${taxYear}`,
    }
    const whatsnew: SupportLink = {
      text: "What's New",
      url: `${applicationUrls.tsa}/help/whatsnew`,
    }

    support.push(requestAssistance)
    if (showGlossary) {
      support.push(glossary)
    }
    support.push(whatsnew)

    setFilteredClaims(filterClaims())
    setFilteredSupportLinks(filterSupportLinks(filteredClaims, support))
  }, [
    location,
    showGlossary,
    engagements,
    engagementQuestions,
    engagementTemplates,
  ])

  const links = [
    {
      text: 'Engagement Manager',
      icon: <FaUsers />,
      url: applicationUrls.engagementManager,
    },
  ]

  // const accountLinks = [
  //   {
  //     text: 'My Engagements',
  //     url: `${applicationUrls.tsa}engagements`,
  //   },
  // ]

  if (links) {
    links.filter(x => x.url === currentUrl)
  }

  return (
    <AccountMenuLayout
      className={'accountmenu'}
      claims={filteredClaims}
      links={links}
      variant={'normal'}
      user={{
        profile: {
          given_name: user && user.firstName ? user.firstName : 'User',
          family_name: user && user.lastName ? user.lastName : undefined,
        },
      }}
      support={filteredSupportLinks}
      // accountLinks={accountLinks}
      onLogout={onLogout}
      accessingFrom={currentEnvironment}
    />
  )
}

export default AccountMenu

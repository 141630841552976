import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { IEngagementQuestionAnswer } from '../../clientModels'
import { reviewRolesForQuestion } from '../../services/questionReviewUtilities'
import { QuestionOwnProps } from '../question/question'
import {
  makeMapStateToProps,
  mapDispatchToProps,
  QuestionContainerProps,
} from './questionContainerMappingAndProps'

export type QuestionComponent = React.ComponentType<QuestionOwnProps>

export interface QuestionComponentMap {
  _default: QuestionComponent
  [componentType: string]: QuestionComponent
}

export function questionContainer(ComponentLookup: QuestionComponentMap) {
  class QuestionContainer extends React.Component<QuestionContainerProps> {
    answerPromiseId?: string
    hasRetrievedValueLastYear: boolean = false

    shouldComponentUpdate(nextProps: QuestionContainerProps) {
      const { props } = this
      let result = false
      result = result || nextProps.engagementId !== props.engagementId
      result = result || nextProps.question !== props.question
      result = result || nextProps.answer !== props.answer
      result = result || nextProps.fileGroups !== props.fileGroups
      result =
        result || nextProps.engagementQuestion !== props.engagementQuestion
      result = result || nextProps.selected !== props.selected
      return result
    }

    // tslint:disable-next-line:no-any - answer.value is not typed
    handleChange = async (value: any, path?: string, skipRules?: boolean) => {
      // If path is set then treat this as a partial (field) update.
      // If the path is undefined then assume that the value represents
      // the entire answer.value.
      const { answerChanged, answerPartialChanged } = this.props
      if (path) {
        await answerPartialChanged({ [path]: value }, skipRules)
      } else {
        await answerChanged(value)
      }
    }

    hasDocuments = () => {
      const { fileGroups, engagementQuestion } = this.props
      if (!fileGroups) {
        return false
      }
      const showRequiredDocs =
        engagementQuestion &&
        engagementQuestion.requiredDocumentTitleIds.length > 0
      if (showRequiredDocs) {
        // If we are showing required documents then we just need to check the length of the array
        return fileGroups.length > 0
      }
      // If we are hiding required documents there might still be some in there so we have to find
      // any file group that isn't required
      return !!fileGroups.find(g => !g.documentTitleId)
    }

    onSelectQuestion = (questionId: number) => {
      const {
        props: { onSelectQuestion },
      } = this
      if (onSelectQuestion) {
        onSelectQuestion(questionId)
      }
    }

    handleSave = async (
      answer: IEngagementQuestionAnswer,
      runTheRules?: boolean
    ) => {
      const {
        props: { saveAnswer, runRules },
      } = this

      if (runTheRules) {
        await runRules()
      }
      saveAnswer(answer.questionId)
    }

    render() {
      const {
        hasDocuments,
        handleChange,
        onSelectQuestion,
        handleSave,
        props,
        props: {
          answer,
          answerPartialChanged,
          disabled,
          engagement,
          engagementQuestion,
          getAnswer,
          question,
          setNotApplicable,
          toggleFileGroupNA,
          toggleFlagged,
          toggleTickMarks,
          user,
        },
      } = this

      const questionProps = {
        ...props,
        disabled,
        handleChange,
        hasComments: engagementQuestion && engagementQuestion.commentsCount > 0,
        hasDocuments: hasDocuments(),
        hasFlags: engagementQuestion && engagementQuestion.flagged,
        isExternal: user && user.isExternal,
        onBlur: (x: any, y: any) => {
          handleSave(x, y)
        },
        // We would like saving to be explicit for the grid, since onBlur can be deceiving.
        saveQuestion: handleSave,
        onBulkChange: answerPartialChanged,
        onChange: handleChange,
        onClickCancel: getAnswer,
        onClickFlag: toggleFlagged,
        onClickTickmark: toggleTickMarks,
        onSelect: onSelectQuestion,
        onSelectQuestion,
        reviewRolesComplete: Array.from(
          (answer && answer.reviewRolesComplete) || []
        ),
        reviewRolesForUser: reviewRolesForQuestion(engagement, question, user),
        setNotApplicable,
        toggleFileGroupNA,
      }

      const Component =
        ComponentLookup[question.answerSchema.component || ''] ||
        ComponentLookup._default

      return <Component {...questionProps} />
    }
  }

  return withRouter(
    connect(makeMapStateToProps, mapDispatchToProps)(QuestionContainer)
  )
}

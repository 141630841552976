import * as _ from 'lodash'
import { TsaHubAction } from '../actions/index'
import { withoutOnce } from './reducerUtilities'
import { getType } from 'typesafe-actions'
import {
  httpBeginAction,
  httpEndAction,
  httpSaveBeginAction,
  httpSaveEndAction,
} from '../actions/httpActions'

export interface HttpState {
  lastSaved?: Date
  loading: string[]
  saving: string[]
}

const initialState: HttpState = { loading: [], saving: [] }

export function httpReducer (
  state: HttpState = initialState,
  action: TsaHubAction
): HttpState {
  switch (action.type) {
    default:
      break

    case getType(httpBeginAction):
      state = {
        ...state,
        loading: _.concat(state.loading, action.payload.id.toLowerCase()),
      }
      break

    case getType(httpEndAction):
      state = {
        ...state,
        loading: withoutOnce(state.loading, action.payload.id.toString()),
      }
      break

    case getType(httpSaveBeginAction):
      state = {
        ...state,
        saving: _.concat(state.saving, action.payload.id.toLowerCase()),
      }
      break

    case getType(httpSaveEndAction):
      state = {
        ...state,
        saving: withoutOnce(state.saving, action.payload.id.toString()),
        lastSaved: new Date(),
      }
      break
  }

  return state
}

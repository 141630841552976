import * as React from 'react'
import { Message } from '../../../clientModels'
import Alerts, { AlertsProps } from './alerts'

type GeneralAlertsProps = Exclude<AlertsProps, 'filter'>

function noPath (message: Message) {
  return !message.path
}

const GeneralAlerts: React.SFC<GeneralAlertsProps> = ({ messages }) => (
  <Alerts messages={messages} filter={noPath} />
)

export default GeneralAlerts

import { User, UserManager, UserManagerSettings } from 'oidc-client'
import { ensureNumber } from '../guards'
import { REACT_APP_GLOBALGETUSERTIMEOUTINSECS } from '../envVariables'

let context: UserManager

export function createUserManager(config: UserManagerSettings) {
  context = new UserManager(config)
  // only set this  if you want to see oidc information logged to the console.
  // Log.logger = console
  // Add a error handler for silent renew will handle the error
  // tslint:disable-next-line:no-any - err is not typed
  context.events.addSilentRenewError((err: any) => {
    // we want to handle the error here.
    // tslint:disable-next-line:no-console
    console.log(err)
    // removing the session user will place the app in the same state as inital load
    // so signinredirect will be called to authenticate again.
    // deep linking will handle the reload
    context.removeUser().then(() => {
      window.location.reload(true)
    })
  })

  const removeClaims = () => {
    sessionStorage.removeItem('user_organization_claims')
  }

  context.events.addAccessTokenExpired(removeClaims)

  context.events.addUserUnloaded(removeClaims)

  context.events.addUserSessionChanged(removeClaims)

  context.events.addUserSignedOut(removeClaims)

  return context
}

/**
 * Invoke UserManager.getUser with timeout
 */
function getUser(): Promise<User | undefined> {
  let isExpired = false

  return new Promise<User | undefined>((resolve, reject) => {
    const timeout =
      ensureNumber(REACT_APP_GLOBALGETUSERTIMEOUTINSECS) || 30
    const timer = setTimeout(() => {
      isExpired = true
      reject(new Error(`Failed to get user token after ${timeout} seconds`))
    }, timeout * 1000)

    context
      .getUser()
      .then((response) => {
        clearTimeout(timer)
        if (!isExpired) {
          resolve(response || undefined)
        }
      })
      .catch((reason) => {
        if (isExpired) {
          return
        }
        reject(reason)
      })
  })
}

/**
 * get the Access token from oidc-client active user
 */
export function acquireToken(): Promise<string> {

  if (process.env.NODE_ENV !== 'production') {
    // if (config && config['Env.NoAuth']) {
    //   return Promise.resolve('mock_token')
    // }
  }

  if (!context) {
    throw new Error('User manager not created')
  }

  return getUser().then((user) => {
    return user!.access_token
  })
}

import { createReducer } from 'typesafe-actions'
import { TsaHubAction } from '../actions/index'
import { getUserInfoAsync } from './adminCenterActions'

export interface AuthorizationState {
  claims?: any
  error?: Error
}

export const adminCenterReducer = createReducer<{}, TsaHubAction>({})
  .handleAction(getUserInfoAsync.request, (state, action) => {
    return {
      ...state,
    }
  })
  .handleAction(getUserInfoAsync.success, (state, action) => {
    return {
      ...state,
      claims: action.payload,
    }
  })
  .handleAction(getUserInfoAsync.failure, (state, action) => {
    return {
      ...state,
      error: action.payload,
    }
  })

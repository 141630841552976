import * as React from 'react'
import Icon from '../icon/icon'
import { roleLabels } from './question'

interface QuestionIconsProps {
  disabled?: boolean
  hasComments?: boolean
  hasDocuments?: boolean
  hasError?: boolean
  hasFlags?: boolean
  reviewsComplete: string[]
  reviewsMissing: string[]
  selected?: boolean
}

interface TickMarkIconProps {
  role: string
}

const TickMarkIcon: React.SFC<TickMarkIconProps> = ({ role }) => (
  <Icon
    icon='circleBigCheck'
    active={true}
    tooltip={`${roleLabels[role]} review complete`}
  />
)

interface TickMarkWarningProps {
  rolesMissing: string[]
}

const TickMarkWarningIcon: React.SFC<TickMarkWarningProps> = ({
  rolesMissing,
}) => (
  <Icon
    icon='warning'
    active={true}
    tooltip={`${rolesMissing
      .map(r => roleLabels[r])
      .join(', ')} review required`}
  />
)

export const QuestionIcons: React.SFC<QuestionIconsProps> = props => {
  const {
    disabled,
    hasComments,
    hasDocuments,
    hasFlags,
    hasError,
    reviewsComplete,
    reviewsMissing,
    selected,
  } = props

  if (disabled) {
    return null
  }

  return (
    <div className='question-icons'>
      {hasComments && <Icon icon='comment' active={true} />}
      {!selected && hasFlags && <Icon icon='flag' active={true} />}
      {hasDocuments && <Icon icon='documents' active={true} />}
      {!selected && hasError && <Icon icon='error' className='error-icon' />}
      {reviewsComplete.map(r => (
        <TickMarkIcon key={r} role={r} />
      ))}
      {!selected && reviewsMissing.length > 0 && (
        <TickMarkWarningIcon rolesMissing={reviewsMissing} />
      )}
    </div>
  )
}

import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { ClientEntity } from '../clientModels'
import { EntityMapping } from '../services/api/apiModels'

export const getFileErrorsActions = createAsyncAction(
  'GET_ETL_FILE_ERRORS_BEGIN',
  'GET_ETL_FILE_ERRORS_DONE',
  'GET_ETL_FILE_ERRORS_FAIL'
)<void, string[], Error>()

export const getEntityOptionsActions = createAsyncAction(
  'GET_ENTITY_OPTIONS_BEGIN',
  'GET_ENTITY_OPTIONS_DONE',
  'GET_ENTITY_OPTIONS_FAIL'
)<{}, { entityOptions: ClientEntity[] }, Error>()

export const setEntityMapAction = createAction('SET_ENTITY_MAP')<
  EntityMapping[]
>()

export type EtlActions =
  | ActionType<typeof getFileErrorsActions.failure>
  | ActionType<typeof getFileErrorsActions.request>
  | ActionType<typeof getFileErrorsActions.success>
  | ActionType<typeof getEntityOptionsActions.failure>
  | ActionType<typeof getEntityOptionsActions.request>
  | ActionType<typeof getEntityOptionsActions.success>
  | ActionType<typeof setEntityMapAction>

import { ActionType, createAction } from 'typesafe-actions'
import { LastNavigation, LastNavigationMap } from '../clientModels'

export const lastNavSettingsBeginAction = createAction(
  'GET_LAST_NAV_SETTINGS_BEGIN'
)<{}>()

export const lastNavSettingsDoneAction = createAction(
  'GET_LAST_NAV_SETTINGS_DONE'
)<{
  lastNavigationMap: LastNavigationMap
}>()

export const lastNavSettingsFailAction = createAction(
  'GET_LAST_NAV_SETTINGS_FAIL'
)<{}>()

export const lastNavSettingsUpdateAction = createAction(
  'UPDATE_LAST_NAV_SETTINGS'
)<{
  lastNavigation: LastNavigation
}>()

export type LastNavActions =
  | ActionType<typeof lastNavSettingsBeginAction>
  | ActionType<typeof lastNavSettingsDoneAction>
  | ActionType<typeof lastNavSettingsFailAction>
  | ActionType<typeof lastNavSettingsUpdateAction>

import classNames from 'classnames'
import * as React from 'react'
import Dropdown, { DropdownMenuExternalProps } from './dropdown'

export default function SubHeaderDropdown (props: DropdownMenuExternalProps) {
  const dropdownProps = {
    ...props,
    className: classNames(props.className, 'sub-header-dropdown'),
  }
  return <Dropdown {...dropdownProps} />
}

import { History } from 'history'
import * as React from 'react'
import { match } from 'react-router'
import { Link } from 'react-router-dom'
import Saving from '../saving/index'

interface RouteParameters {
  engagementId: string
}

interface Properties {
  match: match<RouteParameters>
  history: History
}

export default function({
  match: {
    params: { engagementId },
  },
}: Properties) {
  return (
    <div className='engagement-header'>
      <div className='flag-header flex-wrap'>
        <div>
          <Link to={`/engagements/${engagementId}/reports`}>Reports</Link>
          <Saving showLastSaved={true} />
        </div>
      </div>
    </div>
  )
}

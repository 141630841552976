import { TsaHubAction } from "../actions"
import { getType } from "typesafe-actions"
import { setConfirmModalAction, clearConfirmModalAction } from "../actions/confirmModalActions"

export interface ConfirmModalState {
  phaseChange?: string
}


export default function confirmModalReducer(state: ConfirmModalState = {}, action: TsaHubAction): ConfirmModalState {
  switch (action.type) {
    case getType(setConfirmModalAction):
      return { ...action.payload.confirmModal }
    case getType(clearConfirmModalAction):
      return {}
    default:
      return state
  }
}
import { getType } from 'typesafe-actions'
import { TsaHubAction } from '../actions/index'
import { ClientMap } from '../clientModels'
import { clientAction } from '../actions/clientActions'
import * as engagementActions from '../actions/engagementActions'

const initialState: ClientMap = {}

export function clientsReducer(
  state: ClientMap = initialState,
  action: TsaHubAction
): ClientMap {
  switch (action.type) {
    case getType(engagementActions.getEngagementAction.success):
      return { ...state, ...action.payload.clients }

    case getType(engagementActions.getEngagementListAction.success):
    case getType(clientAction.success):
      const newState = { ...state }
      // These actions return ClientLight objects so we can't just overwrite entire clients. We must merge each one.
      Object.entries(action.payload.clients).forEach(
        ([id, client]) =>
          (newState[id] = Object.assign({}, newState[id], client))
      )
      return { ...newState }

    default:
      return state
  }
}

/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import { AppState } from '../../store'
import { EngagementQuestionMap } from '../../clientModels'
import { isDefined } from '../../guards'
import { useSelector } from 'react-redux'
import { Theme } from '@rsmus/react-ui'
import { useTheme, createUseStyles } from 'react-jss'
import { NavLink } from 'react-router-dom'
import color from 'color'
import { DocumentTitleId } from '../../enums'

interface RouteParams {
  engagementId: string
}

function styles({ palette, typogrphaphy, breakpoints }: Theme) {
  return {
    container: {
      backgroundColor: palette.blue.main,
      color: palette.common.white,
      paddingTop: '1rem',
      paddingBottom: '2rem',
    },
    title: {
      ...typogrphaphy.bold,
      fontSize: '2rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    links: {
      paddingTop: '1rem',
    },
    link: {
      width: '100%',
      position: 'relative',
      lineHeight: '1.125rem',
      letterSpacing: '0.04rem',
      color: palette.common.white,
      display: 'block',
      padding: '0.5rem 2rem 0.5rem 2rem',
      '&:hover': {
        textDecoration: 'none',
        color: palette.common.white,
        backgroundColor: color(palette.blue.main)
          .lighten(0.09)
          .hex(),
      },
    },
    activeLink: {
      pointerEvents: 'none',
      cursor: 'default',
      ...typogrphaphy.bold,
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface Properties extends RouteComponentProps<RouteParams> {}

const empty = {}
const k1UpLoadDocumentTitle = DocumentTitleId.K1UpLoadDocument // we need
const getDocumentTitleUrl = (
  state: AppState,
  engagementId: string,
  documentTitleId: number
) => {
  const questions =
    state.engagementQuestions[engagementId] || (empty as EngagementQuestionMap)
  const question = Object.values(questions)
    .filter(isDefined)
    .filter(q => q.isVisible)
    .find(q => q.requiredDocumentTitleIds.includes(documentTitleId))

  if (question) {
    return `/upload/questions/${question.questionId}/documenttitle/${documentTitleId}`
  }

  return undefined
}

export default function({ match }: Properties) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const url = useMemo(
    function() {
      return match.url.replace(/\/+$/, '')
    },
    [match.url]
  )
  const engagementId = match.params.engagementId
  const k1RelativePath = useSelector((state: AppState) => {
    return getDocumentTitleUrl(state, engagementId, k1UpLoadDocumentTitle)
  })

  const docLibraryUrl = `${url}`

  const k1Url = `${url}${k1RelativePath}`

  return (
    <div className={classes.container}>
      <div className={classes.title}>Documents</div>
      <div className={classes.links}>
        <NavLink
          className={classes.link}
          to={docLibraryUrl}
          activeClassName={classes.activeLink}
          exact={true}
        >
          Document Library
        </NavLink>
        <NavLink
          className={classes.link}
          to={k1Url}
          activeClassName={classes.activeLink}
        >
          K-1 Management
        </NavLink>
      </div>
    </div>
  )
}

import { History } from 'history'
import { RouteComponentProps } from 'react-router'
import { createSelector } from 'reselect'
import { downloadEmptyExcelTemplate } from '../../actions/fileTemplateActions'
import { actions } from '../../actions/index'
import {
  Activity,
  DocumentTitle,
  Engagement,
  EngagementQuestion,
  FileGroup,
  IdentityTokenProfile,
  IdMap,
  IEngagementQuestionAnswer,
  OptionListMap,
  PropertyValues,
  Question,
} from '../../clientModels'
import { selectQuestionFileGroups } from '../../reducers/selectors'
import { questionDisabled } from '../../services/question'
import { AppState, TsaDispatch } from '../../store'

interface RouteParams {
  engagementId: string
  sectionId: string
  questionId: string
}

interface QuestionContainerOwnProps extends RouteComponentProps<RouteParams> {
  engagementId: number
  question: Question
  engagementQuestion?: EngagementQuestion
  fileGroupId?: number
  isExternalUser?: boolean
  documentTitleId?: number
  calledFromSummary?: boolean
  selected: boolean
  onSelectQuestion?: (questionId: number) => void
}

interface QuestionContainerMappedProps {
  answer?: IEngagementQuestionAnswer
  clientId?: number
  codeListIdToOptionsId?: IdMap
  disabled: boolean
  documentTitles?: DocumentTitle[]
  engagement?: Engagement
  isExternalUser?: boolean
  fileGroups?: FileGroup[]
  hasCarryForward?: boolean
  optionLists?: OptionListMap
  user?: IdentityTokenProfile
}

const makeSelectQuestionDisabled = () => {
  return createSelector(
    (state: AppState, props: QuestionContainerOwnProps) => props.engagementId,
    (state: AppState, props: QuestionContainerOwnProps) => props.question.id,
    (state: AppState) => state.engagements,
    (state: AppState) => state.activities,
    (state: AppState, props: QuestionContainerOwnProps) =>
      state.engagementQuestions[props.engagementId],
    (state: AppState) => state.auth.user,
    (state: AppState) => state.permissions.isExternalUser,
    questionDisabled
  )
}

const makeSelectQuestionFileGroups = () =>
  createSelector(
    (state: AppState) => state.fileGroups,
    (_: any, props: QuestionContainerOwnProps) => props.question.id,
    selectQuestionFileGroups
  )

// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
export const makeMapStateToProps = () => {
  const selectDisabled = makeSelectQuestionDisabled()
  const selectFileGroups = makeSelectQuestionFileGroups()
  return (
    state: AppState,
    props: QuestionContainerOwnProps
  ): QuestionContainerMappedProps => {
    const engagementId = props.engagementId
    const answers = state.engagementQuestions[engagementId]
    const answer = answers && answers[props.question.id]
    const engagement = state.engagements[engagementId]
    const documentTitles = state.documentTitles
    const user = state.auth.user
    const isExternalUser = state.permissions.isExternalUser

    return {
      answer,
      clientId: engagement && engagement.clientId,
      codeListIdToOptionsId: state.options.codeListIdToOptionsId,
      disabled: selectDisabled(state, props),
      documentTitles,
      engagement,
      fileGroups: selectFileGroups(state, props),
      optionLists: state.options.options,
      user,
      isExternalUser,
    }
  }
}

interface QuestionContainerDispatchProps {
  answerChanged: (response: any) => Promise<void> // tslint:disable-line:no-any - answer response is not typed
  answerPartialChanged: (
    properties: PropertyValues,
    skipRules?: boolean,
    runTableRules?: boolean
  ) => Promise<void>
  deleteActivity: (activityId: number, id?: string) => void
  deleteFileGroup: (id: number) => void
  downloadFile: (fileId: number) => void
  downloadExcelTemplate: (fileName: string, engagementId: number) => void
  getAnswer: () => void
  openFileUpload: (
    engagementId?: number | string,
    questionId?: number | string,
    documentTitle?: DocumentTitle,
    history?: History,
    fileUpload?: FileGroup
  ) => void
  saveActivity: (activity: Activity) => void
  saveAnswer: (questionId: number) => Promise<void>
  setNotApplicable: (notApplicable: boolean) => void
  toggleFileGroupNA: (
    documentTitle: DocumentTitle,
    fileGroupId?: number
  ) => void
  toggleFlagged: () => void
  toggleTickMarks: () => void
  runRules: () => Promise<void>
}

export const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: QuestionContainerOwnProps & RouteComponentProps<RouteParams>
): QuestionContainerDispatchProps => {
  const answerChanged = (value: any) =>
    dispatch(
      actions.answer.answerChanged(props.engagementId, props.question.id, value)
    ) // tslint:disable-line:no-any - answer response is not typed
  const answerPartialChanged = async (
    properties: PropertyValues,
    skipRules?: boolean,
    runTableRules?: boolean
  ) =>
    dispatch(
      actions.answer.answerPartialChanged(
        props.engagementId,
        props.question.id,
        properties,
        skipRules,
        runTableRules
      )
    )
  const deleteActivity = (activityId: number, id?: string) =>
    dispatch(
      actions.activity.deleteActivity(props.engagementId, activityId, id)
    )
  const deleteFileGroup = (id: number) =>
    dispatch(
      actions.file.deleteFileGroup(props.engagementId, props.question.id, id)
    )
  const downloadFile = (fileId: number) =>
    dispatch(actions.file.downloadFile(fileId))
  const downloadExcelTemplate = (fileName: string, engagementId: number) =>
    dispatch(downloadEmptyExcelTemplate(fileName, engagementId))
  const getAnswer = () =>
    dispatch(actions.answer.getAnswer(props.engagementId, props.question.id))
  const openFileUpload = (
    engagementId?: string | number,
    questionId?: string | number,
    documentTitle?: DocumentTitle,
    history?: History,
    fileGroup?: FileGroup
  ) =>
    dispatch(
      actions.file.openFileUpload(
        engagementId,
        questionId,
        documentTitle,
        history,
        fileGroup
      )
    )
  const saveActivity = (activity: Activity) =>
    dispatch(actions.activity.saveActivity(activity))
  const saveAnswer = async (questionId: number) =>
    dispatch(actions.answer.saveAnswer(props.engagementId, questionId))
  const setNotApplicable = (notApplicable: boolean) =>
    dispatch(
      actions.answer.setNotApplicable(
        props.engagementId,
        props.question.id,
        notApplicable
      )
    )
  const toggleFileGroupNA = (
    documentTitle: DocumentTitle,
    fileGroupId?: number
  ) =>
    dispatch(
      actions.file.toggleFileGroupNotApplicable(
        props.engagementId,
        props.question.id,
        documentTitle,
        fileGroupId
      )
    )
  const toggleFlagged = () =>
    dispatch(actions.flag.toggleFlagged(props.engagementId, props.question.id))
  const toggleTickMarks = () =>
    dispatch(
      actions.review.toggleTickMarks(props.engagementId, props.question.id)
    )
  const runRules = async () =>
    dispatch(
      actions.rule.runQuestionRules(props.engagementId, props.question.id)
    )
  return {
    answerChanged,
    answerPartialChanged,
    deleteActivity,
    deleteFileGroup,
    downloadExcelTemplate,
    downloadFile,
    getAnswer,
    openFileUpload,
    runRules,
    saveActivity,
    saveAnswer,
    setNotApplicable,
    toggleFileGroupNA,
    toggleFlagged,
    toggleTickMarks,
  }
}

export type QuestionContainerProps = QuestionContainerOwnProps &
  QuestionContainerMappedProps &
  QuestionContainerDispatchProps

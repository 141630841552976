import * as React from 'react'
import { IconProperties } from './properties'

const NA: React.SFC<IconProperties> = ({ className, onClick, tooltip }) => {
  return (
    <div className={className} onClick={onClick} data-tip={tooltip}>
      <div className='text'>NA</div>
    </div>
  )
}

export default NA

import classNames from 'classnames'
import { uniqueId } from 'lodash'
import * as React from 'react'
import { isStringOrNullOrUndefined } from '../../guards'
import { Alerts } from './alerts/index'
import { FocusableComponent, FormFieldProps } from './formComponentsInterfaces'
import { by, charactersRemaining, hasError } from './formUtilities'

interface TextAreaProps extends FormFieldProps {
  maxLength?: number
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  allowAltEnterNewLine?: boolean
}

export default class TextArea extends React.Component<TextAreaProps>
  implements FocusableComponent {
  inputId: string
  labelId: string
  hintId: string
  textarea?: HTMLTextAreaElement
  altDown: boolean = false

  constructor(props: TextAreaProps) {
    super(props)
    const id = uniqueId('textarea_')
    this.inputId = `${id}_input`
    this.labelId = `${id}_label`
    this.hintId = `${id}_hint`
  }

  componentDidMount() {
    const { selectedPath, path } = this.props

    if (selectedPath && path && path === selectedPath) {
      this.focus()
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: TextAreaProps) {
    if (
      nextProps.path &&
      nextProps.selectedPath &&
      nextProps.path === nextProps.selectedPath
    ) {
      this.focus()
    }
  }

  statusText(maxLength?: number) {
    if (maxLength && maxLength > 0) {
      const length = this.textarea ? this.textarea.value.length : 0
      const remaining = Math.max(0, maxLength - length)
      return 'Characters remaining: ' + remaining
    }
    return ''
  }

  focus() {
    const textarea = this.textarea
    if (textarea) {
      textarea.focus()
    }
  }

  handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { path, onChange } = this.props
    if (onChange) {
      onChange(e.currentTarget.value, path)
    }
  }

  handleBlur = () => {
    const { path, onBlur } = this.props
    if (onBlur) {
      onBlur(path)
    }
  }

  handleFocus = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { path, onFocus } = this.props
    if (onFocus) {
      onFocus(path)
    }
  }

  handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { onKeyUp } = this.props
    if (e.altKey) {
      this.altDown = false
    }

    if (onKeyUp) {
      onKeyUp(e)
    }
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { path, onChange, onKeyDown, allowAltEnterNewLine } = this.props
    if (e.altKey) {
      this.altDown = true
    }

    if (allowAltEnterNewLine && e.key === 'Enter' && this.altDown && onChange) {
      onChange(e.currentTarget.value + '\r\n', path)
    }

    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  setTextarea = (ref: HTMLTextAreaElement) => (this.textarea = ref)

  render() {
    const {
      describedBy,
      disabled,
      hint,
      label,
      labelledBy,
      maxLength,
      messages,
      placeholder,
      value,
      path,
      showAlerts,
      autoFocus,
    } = this.props

    if (!isStringOrNullOrUndefined(value)) {
      throw new Error('TextArea expects a string value.')
    }

    const ariaLabelledBy = by(labelledBy, label && this.labelId)
    const ariaDescribedBy = by(describedBy, hint && this.hintId)
    const isInError = hasError(messages, path)
    const statusText = charactersRemaining(value || '', maxLength)

    return (
      <div className={classNames('form-group', { disabled })}>
        {label && <label id={this.labelId}>{label}</label>}
        <div className='status-text-area d-flex'>
          {hint && (
            <div id={this.hintId} className='hint-text'>
              {hint}
            </div>
          )}
          {statusText && <div className='status-text'>{statusText}</div>}
        </div>
        <textarea
          aria-describedby={ariaDescribedBy}
          aria-labelledby={ariaLabelledBy}
          className={classNames('form-control', {
            'is-invalid': isInError,
            'is-valid': !isInError,
          })}
          disabled={disabled}
          id={this.inputId}
          maxLength={maxLength}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          placeholder={placeholder}
          ref={this.setTextarea}
          value={value || ''}
          autoFocus={autoFocus}
        />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

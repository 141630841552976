import { DebouncedBatchMap } from './debouncedBatchMap'
import { AppInsights } from './index'
import { REACT_APP_APPINSIGHTSINSTRUMENTATIONKEY, REACT_APP_GLOBALMAJORMINORBUILDREVISION } from '../../envVariables'

const batches = new DebouncedBatchMap(sendBatch)

export const batchedTrackMetric =
  REACT_APP_APPINSIGHTSINSTRUMENTATIONKEY
    ? trackMetric
    : Function.prototype

function trackMetric(name: string, value: number) {
  batches.addValue(name, value)
}

function sendBatch(name: string, entries: number[]) {
  const count = entries.length

  let sum: number = 0
  let min: number = entries[0]
  let max: number = entries[0]

  for (let i = 0; i < count; i += 1) {
    const value = entries[i]
    sum += value
    if (value < min) {
      min = value
    }
    if (value > max) {
      max = value
    }
  }

  const average = sum / count
  const properties = {
    version: (REACT_APP_GLOBALMAJORMINORBUILDREVISION) || '',
  }
  AppInsights.trackMetric(name, average, count, min, max, properties)
}

import { isDefined } from '../guards'
import { TsaThunkAction } from '../store'
import { defaultEngagementQuestionsAction } from './engagementSectionsQuestionsActions'

export const setDefaultEngagementSectionsQuestions = (
  engagementId: number,
  engagementTemplateId: number
): TsaThunkAction => (dispatch, getState) => {
  const state = getState()
  const template = state.engagementTemplates[engagementTemplateId]
  if (!template) {
    throw new Error('Engagement template not found.')
  }

  const questions = Object.values(state.questions)
    .filter(q => !!q && template.questions.includes(q.id))
    .filter(isDefined)

  const sections = Object.values(state.sections)
    .filter(s => !!s && template.sections.includes(s.id))
    .filter(isDefined)

  dispatch(
    defaultEngagementQuestionsAction({ engagementId, sections, questions })
  )
}

import moment, { Moment } from 'moment'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { buildApiUrl } from '../../services/api'
import { ExtError } from '../../services/error'
import { get, post } from '../../services/http'
import { hasPermission } from '../../services/permissions'
import { AppState } from '../../store'
import { WarningDialog } from '../../components/modals/index'
import { UserPermissions } from '../../enums'

interface Publication {
  type: string
  year: number
  name: string
  updated: Moment
}

interface Publications {
  url: string
  publicationsList: Publication[]
}

function publicationKey(publication: Publication) {
  return publication.type + publication.year
}

export const UpdateTemplateScene: FunctionComponent = () => {
  const [error, setError] = useState<any>()
  const [success, setSuccess] = useState<any>()
  const [processing, setProcessing] = useState('')
  const [publications, setPublications] = useState<Publications>()
  const pbcUrl = publications && publications.url
  const user = useSelector((state: AppState) => state.auth.user)
  const canUpdateTemplates = hasPermission(
    UserPermissions.EngagementTemplatesCanEdit,
    user
  )

  const [confirm, setConfirm] = useState(false)
  const [selectedRow, setSelectedRow] = useState<Publication>()

  const displayModal = (p?: Publication) => {
    setConfirm(true)
    if (p) {
      setSelectedRow(p)
    }
  }

  const publishRowSelected = () => {
    if (selectedRow !== undefined) {
      handleClickUpdate(selectedRow)
    }
    closeWarningDialog()
  }

  const publishAll = () => {
    handleClickUpdateAll()
    closeWarningDialog()
  }

  const closeWarningDialog = () => {
    setConfirm(false)
    setSelectedRow(undefined)
  }

  useEffect(() => {
    ;(function() {
      if (!canUpdateTemplates) return
      const url = buildApiUrl('/pbccontent')
      get(url)
        .then(setPublications)
        .catch(e => setError(processError(e)))
    })()
  }, [canUpdateTemplates])

  if (!canUpdateTemplates) {
    return <Redirect to='/' />
  }

  const handleClickUpdate = async (publication: Publication) => {
    const url = buildApiUrl(
      `/pbccontent/${publication.type}/${publication.year}/update`
    )

    try {
      setError(null)
      setSuccess(null)
      setProcessing(publicationKey(publication))
      await post(url, null, { headers: undefined })
      setSuccess(
        `Successfully updated ${publication.type} ${publication.year}.`
      )
      publication.updated = moment()
      setTimeout(() => setSuccess(null), 20000)
    } catch (error) {
      setError(processError(error))
    }

    setProcessing('')
  }

  const handleClickUpdateAll = async () => {
    if (publications?.publicationsList) {
      setError(null)
      setSuccess(null)

      let successes = ''
      let errors = ''

      for (var p of publications.publicationsList) {
        try {
          setProcessing(publicationKey(p))
          const url = buildApiUrl(`/pbccontent/${p.type}/${p.year}/update`)
          await post(url, null, { headers: undefined })
          if (successes.length === 0) {
            successes = 'Successfully updated '
          }
          successes += `${p.type} - ${p.year}; `
          p.updated = moment()
        } catch (error) {
          if (errors.length === 0) {
            errors = 'Failed to update '
          }
          errors += `${p.type} - ${p.year}: ${processError(error)}; `
        }
        setProcessing('')
      }

      setSuccess(successes)
      setTimeout(() => setSuccess(null), 20000)
      setError(errors)
    }
  }

  return (
    <div style={{ padding: '2rem' }}>
      <p style={{ padding: 8, maxWidth: 800 }}>
        This is a list of engagement templates that are available from the PBC
        Editor. Just because the engagement type is listed here does not mean
        that the template is available in the current TSA environment. You must
        click the Update button to load the most recent version.
      </p>
      {pbcUrl && <p style={{ padding: 8 }}>PBC API target: {pbcUrl}</p>}
      <table cellPadding='8'>
        <thead>
          <tr>
            <th />
            <th>Engagement Type</th>
            <th>Tax Year</th>
            <th>Name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {publications &&
            publications.publicationsList &&
            publications.publicationsList.length === 0 && (
              <tr>
                <td />
                <td colSpan={4}>Loading engagement templates...</td>
              </tr>
            )}
          {publications &&
            publications.publicationsList &&
            publications.publicationsList.sort(sortPublications).map((p, i) => (
              <tr key={i}>
                <td>
                  <button
                    disabled={!!processing}
                    onClick={() => displayModal(p)}
                  >
                    {processing === publicationKey(p) ? 'Updating' : 'Update'}
                  </button>
                </td>
                <td>{p.type}</td>
                <td>{p.year}</td>
                <td>{p.name}</td>
                <td>{p.updated && `Updated ${p.updated.format('LTS')}`}</td>
              </tr>
            ))}
        </tbody>
        {confirm && (
          <WarningDialog
            {...confirm}
            title='Update Template'
            info={
              selectedRow === undefined
                ? 'Are you sure you want to update these templates? Once you have updated these templates, the changes cannot be undone in the application.'
                : `Are you sure you want to update the ${selectedRow.type} ${selectedRow.year} template? Once you have updated this template, the changes cannot be undone in the application.`
            }
            primaryButtonText='Yes'
            onClickPrimary={
              selectedRow === undefined ? publishAll : publishRowSelected
            }
            onClickSecondary={closeWarningDialog}
            onClose={closeWarningDialog}
            mode='warning'
          />
        )}
      </table>
      <h3 style={{ padding: '16px 8px', color: error ? '#e31e26' : 'inherit' }}>
        {error}
        {success}
        {processing && 'Updating engagement template... '}
        {processing && (
          <div>
            <small>
              This may take a minute or two. Please do not close or refresh your
              browser until it is complete.
            </small>
          </div>
        )}
      </h3>
    </div>
  )
}

function processError(error: ExtError) {
  let modelState: any
  if (error.body) {
    try {
      modelState = JSON.parse(error.body)
    } catch {}
  }

  return modelState ? modelState.file && modelState.file[0] : error.message
}

function sortPublications(p1: Publication, p2: Publication) {
  if (p1.type < p2.type) return -1
  if (p1.type > p2.type) return 1
  if (p1.year < p2.year) return -1
  if (p1.year > p2.year) return 1
  return 0
}

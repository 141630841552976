import { EngagementTaskMap } from '../clientModels'
import { createAsyncAction, ActionType } from 'typesafe-actions'

export const taskAction = createAsyncAction(
  'GET_TASKS_BEGIN',
  'GET_TASKS_DONE',
  'GET_TASKS_FAIL'
)<
  {},
  {
    tasks: EngagementTaskMap
  },
  Error
>()

export type TaskActions =
  | ActionType<typeof taskAction.request>
  | ActionType<typeof taskAction.success>
  | ActionType<typeof taskAction.failure>

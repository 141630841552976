import { ActionType, createAsyncAction, createAction } from 'typesafe-actions'
import { EngagementMap, ReviewSummary } from '../clientModels'
import { RoleCode } from '../enums'

export const reviewQuestionAction = createAction('REVIEW_QUESTION')<{
  engagementId: number | string
  questionId: number | string
  roles: RoleCode[]
  action: 'mark' | 'clear'
}>()

export const reviewDoneAction = createAsyncAction(
  'REVIEW_DONE_START',
  'REVIEW_DONE',
  'REVIEW_DONE_ERROR'
)<
  {
    engagementId: number | string
    reviewSummary: ReviewSummary
  },
  {
    engagements: EngagementMap
  },
  Error
>()

export type ReviewActions =
  | ActionType<typeof reviewDoneAction.request>
  | ActionType<typeof reviewDoneAction.success>
  | ActionType<typeof reviewDoneAction.failure>
  | ActionType<typeof reviewQuestionAction>

import classNames from 'classnames'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import Icon from '../icon/icon'
import './sidebar.scss'

interface SidebarProps {
  className?: string
  renderTray?: () => React.ReactNode
  open: boolean
  onToggleOpen: () => void
}

// tslint:disable
export class Sidebar extends React.Component<SidebarProps> {
  // HACK: This is a workaround for the lack of support in CSSTransition for an initial exited state.
  status?: string

  componentDidMount() {
    const node = this.getNode()
    if (node && this.status === 'exited') {
      node.classList.add('sidebar-exit-done')
    }
  }

  componentDidUpdate() {
    const node = this.getNode()
    if (node && this.status !== 'exited') {
      node.classList.remove('sidebar-exit-done')
    }
  }

  render() {
    const { open } = this.props

    /**
     * Note: The timeout prop on CSSTransition must match the
     * CSS transition duration in the SCSS file.
     */
    return (
      <CSSTransition in={open} classNames='sidebar' timeout={300}>
        {this.renderChildren}
      </CSSTransition>
    )
  }

  private renderChildren = (status: string) => {
    const { children, className, onToggleOpen, open, renderTray } = this.props

    this.status = status
    const expanderTooltip = open ? 'Collapse' : 'Expand'

    const sidebarClassName = classNames(className, 'sidebar')
    return (
      <div className={sidebarClassName}>
        <div className='sidebar-expander' onClick={onToggleOpen}>
          <Icon icon='isClosed' tooltip={expanderTooltip} />
        </div>
        <div className='sidebar-tray' onClick={onToggleOpen}>
          {renderTray && renderTray()}
        </div>
        <div className='sidebar-content-container'>{children}</div>
      </div>
    )
  }

  private getNode() {
    return ReactDOM.findDOMNode(this) as HTMLDivElement | undefined
  }
}

import * as React from 'react'
import { Engagement, IdentityTokenProfile } from '../../clientModels'
import { PhaseCode } from '../../enums'
import CchErrorLinks from './cchErrorLinks/cchErrorLinks'

interface EngagementSummaryMessageProps {
  engagement: Engagement
  user?: IdentityTokenProfile
}

export class EngagementSummaryMessage extends React.Component<
  EngagementSummaryMessageProps
> {
  clientUserMessage() {
    const { engagement } = this.props
    const phaseComplete =
      engagement.successfulQuestions >= engagement.totalQuestions

    const latestTaxIntegrationStatus =
      engagement.taxIntegrationStatuses &&
      engagement.taxIntegrationStatuses
        .filter(x => x.type === 'CCHExport')
        .sort((x, y) => y.id - x.id)[0]

    switch (engagement.phase) {
      case PhaseCode.PBC:
        return phaseComplete
          ? 'Once your engagement is submitted for review you will no longer be able to edit your questions.'
          : null

      case PhaseCode.Review:
        return 'Your information is being reviewed.You will receive an email if any items require your attention.'

      case PhaseCode.CCH:
        return !latestTaxIntegrationStatus ||
          latestTaxIntegrationStatus.status === 'IN PROGRESS' ||
          latestTaxIntegrationStatus.status === 'TSAPROCESSING'
          ? 'ENGAGEMENT SUBMISSION IN PROGRESS...'
          : null

      default:
        return null
    }
  }

  rsmUserMessage() {
    const { engagement } = this.props

    const latestExportTaxIntegrationStatus =
      engagement.taxIntegrationStatuses &&
      engagement.taxIntegrationStatuses
        .filter(x => x.type === 'CCHExport')
        .sort((x, y) => y.id - x.id)[0]
    const latestImportTaxIntegrationStatus =
      engagement.taxIntegrationStatuses &&
      engagement.taxIntegrationStatuses
        .filter(x => x.type === 'CCHImport')
        .sort((x, y) => y.id - x.id)[0]
    switch (engagement.phase) {
      case PhaseCode.CCH:
        if (
          !latestExportTaxIntegrationStatus ||
          (latestExportTaxIntegrationStatus &&
            (latestExportTaxIntegrationStatus.status === 'IN PROGRESS' ||
              latestExportTaxIntegrationStatus.status === 'TSAPROCESSING'))
        ) {
          return (
            <div className='loading'>ENGAGEMENT SUBMISSION IN PROGRESS</div>
          )
        } else if (
          latestExportTaxIntegrationStatus &&
          latestExportTaxIntegrationStatus.status === 'SUCCESS'
        ) {
          return (
            <div className='submission-success'>
              <div className='submission-success-heading'>
                ENGAGEMENT SUBMITTED
              </div>
            </div>
          )
        } else if (
          latestExportTaxIntegrationStatus &&
          latestExportTaxIntegrationStatus.status === 'PARTIAL SUCCESS'
        ) {
          return (
            <div className='submission-failed'>
              <CchErrorLinks
                engagementId={engagement?.id}
                headerMessage={'ENGAGEMENT SUBMITTED WITH ISSUES'}
                message={latestExportTaxIntegrationStatus.message}
                messageClassName='submission-failed-message'
              />
            </div>
          )
        } else if (
          latestExportTaxIntegrationStatus &&
          latestExportTaxIntegrationStatus.status === 'ERROR'
        ) {
          return (
            <div className='submission-failed'>
              <CchErrorLinks
                headerMessage={'ENGAGEMENT SUBMISSION FAILED:'}
                engagementId={engagement?.id}
                message={latestExportTaxIntegrationStatus.message}
                messageClassName='submission-failed-message'
              />
            </div>
          )
        }
        break

      default:
        if (
          latestImportTaxIntegrationStatus &&
          (latestImportTaxIntegrationStatus.status === 'IN PROGRESS' ||
            latestImportTaxIntegrationStatus.status === 'TSAPROCESSING')
        ) {
          return <div className='loading'>RETRIEVING LAST YEAR'S DATA</div>
        }
        if (
          latestImportTaxIntegrationStatus &&
          latestImportTaxIntegrationStatus.status === 'SUCCESS'
        ) {
          return (
            <div className='submission-success'>
              <div className='submission-success-heading'>
                LAST YEAR'S DATA RETRIEVED:
              </div>{' '}
              Data from {engagement.priorYearReturnId} successfully populated
              into all questions.
            </div>
          )
        }
        if (
          latestImportTaxIntegrationStatus &&
          latestImportTaxIntegrationStatus.status === 'PARTIAL SUCCESS'
        ) {
          return (
            <div className='submission-failed'>
              <CchErrorLinks
                engagementId={engagement?.id}
                message={latestImportTaxIntegrationStatus.message}
                headerMessage={"LAST YEAR'S DATA RETRIEVED:"}
                subHeaderMessage={`Data from ${engagement.priorYearReturnId} successfully populated some questions.`}
                messageClassName='submission-failed-message'
              />
            </div>
          )
        }
        if (
          latestImportTaxIntegrationStatus &&
          latestImportTaxIntegrationStatus.status === 'ERROR'
        ) {
          return (
            <div className='submission-failed'>
              <CchErrorLinks
                headerMessage={"UNABLE TO RETRIEVE LAST YEAR'S DATA"}
                engagementId={engagement?.id}
                message={latestImportTaxIntegrationStatus.message}
                messageClassName='submission-failed-message'
              />
            </div>
          )
        }
        break
    }
    return null
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    const message = user.isExternal
      ? this.clientUserMessage()
      : this.rsmUserMessage()

    return message ? (
      <div className='engagement-summary-message'>{message}</div>
    ) : null
  }
}

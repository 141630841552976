import * as React from 'react'
import './assistance.scss'

function AssistanceHeader () {
  return (
    <div className='flag-header flex-wrap'>
      <div className='title'>Request Assistance</div>
    </div>
  )
}

export default AssistanceHeader

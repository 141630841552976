import * as React from 'react'
import { connect } from 'react-redux'
import { downloadTaxFormFile } from '../../actions/fileThunks'
import { TsaDispatch } from '../../store'
import Icon from '../icon/icon'
import './whatsNew.scss'

interface DispatchProperties {
  downloadWhatsNewFile: () => void
}

export const mapDispatchToProps = (
  dispatch: TsaDispatch
): DispatchProperties => {
  return {
    downloadWhatsNewFile: () =>
      dispatch(downloadTaxFormFile('GLOBAL', 9999, 'whatsnew.pdf')),
  }
}

type Properties = DispatchProperties
class WhatsNewBody extends React.Component<Properties> {
  downloadWhatsNew = () => {
    const {
      props: { downloadWhatsNewFile },
    } = this

    downloadWhatsNewFile()
  }

  render() {
    const { downloadWhatsNew } = this
    return (
      <div className='whatsnew-body flex-wrap'>
        Please review this PDF for platform updates and fixes.
        <br />
        <div
          className='download-whatsnew btn btn-primary'
          onClick={downloadWhatsNew}
        >
          <Icon icon='download' /> <div className='text'>What's New (PDF)</div>
        </div>
      </div>
    )
  }
}

export default connect(undefined, mapDispatchToProps)(WhatsNewBody)

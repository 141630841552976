import { PivotConfiguration, EntityMap } from '../../clientModels'

export enum CloseActions {
  None = 0,
  Cancel = 1,
  Save = 2,
  Process = 3,
  ConfirmCancel = 4,
  CancelConfirmed = 5,
}

export interface DataMap extends TemplateSelection {
  entityMap: EntityMap<string>
  taxCodeMap: EntityMap<string>
}

export interface TemplateSelection {
  templateId: number
  newTemplateName: string
}

export interface FormatObject extends TemplateSelection {
  cellToBeginImport: string
  exclude: string
  fileFormat: string
  fileToInput: string // this property is useless
  worksheetToImport: string
}

export interface StructureMap {
  format: FormatObject
  pivotConfig: PivotConfiguration<string>
}

export type NewOrExisting = 'new' | 'existing'

import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { EngagementSection, Section } from '../../clientModels'
import { isDefined } from '../../guards'
import { getEngagementSections, getSections } from '../../reducers/selectors'
import {
  ButtonConfiguration,
  clientUserButtonConfig,
  rsmUserButtonConfig,
} from '../../services/engagementPhaseService'
import { AppState } from '../../store'
import { EngagementPhaseChangeProps } from '../engagementPhaseChange'
import Icon from '../icon/icon'
import './questionListButtons.scss'
import { useEffect, useState } from 'react'
import * as confirmModalActions from '../../actions/confirmModalActions'
import Footer from '../footer/footer'

interface PreviousNextSection {
  previousSectionId?: number
  nextSectionId?: number
}

// tslint:disable-next-line:no-empty-interface - this is just renaming the EngagementPhaseChangeProps
interface QuestionListButtonsOwnProps extends EngagementPhaseChangeProps {}

// tslint:disable-next-line:no-empty-interface - so far this needs no additional properties
interface QuestionListButtonsMappedProps extends PreviousNextSection {}

function getSortedSections() {
  return createSelector(
    (state: AppState, props: QuestionListButtonsOwnProps) => getSections(state),
    (state: AppState, props: QuestionListButtonsOwnProps) =>
      getEngagementSections(state, props.engagement.id) || {},
    (state: AppState, props: QuestionListButtonsOwnProps) =>
      props.engagement.id,
    (sections, engagementSections, engagementId) => {
      const sortedSections = Object.values(sections)
        .filter(isDefined)
        .sort(
          (left: Section, right: Section) =>
            left.displayOrder - right.displayOrder
        )
      const sortedEngagementSections: EngagementSection[] = []
      for (const section of sortedSections) {
        const engagementSection = engagementSections[section.id]

        if (engagementSection) {
          if (!section.isVisible) {
            engagementSection.isVisible = section.isVisible
          }
          sortedEngagementSections.push(engagementSection)
        } else {
          sortedEngagementSections.push({
            id: section.id,
            engagementId,
            isVisible: section.isVisible,
            isVisibleDebug: 0,
          })
        }
      }
      return sortedEngagementSections
    }
  )
}

function getPreviousNextSection() {
  const getSectionsSorted = getSortedSections()
  return createSelector(
    getSectionsSorted,
    (state: AppState, props: QuestionListButtonsOwnProps) => props.sectionId,
    (sections, currentSectionId) => {
      const result: PreviousNextSection = {}

      if (!currentSectionId) {
        return result
      }

      let next = false

      for (const section of sections) {
        if (section.id === currentSectionId) {
          next = true
          continue
        }
        if (!next) {
          if (section.isVisible || section.isVisibleDebug === 1) {
            result.previousSectionId = section.id
          }
        } else {
          if (section.isVisible || section.isVisibleDebug === 1) {
            result.nextSectionId = section.id
            break
          }
        }
      }

      return result
    }
  )
}

type QuestionListButtonsProps = QuestionListButtonsOwnProps &
  QuestionListButtonsMappedProps

const QuestionListButtons = (props: QuestionListButtonsProps) => {
  const { user, engagement, navigateSection, engagementTemplate } = props
  const [config, setConfig] = useState<ButtonConfiguration | undefined>()
  const [modalDisplayed, setModalDisplayed] = useState(false)
  const busy = useSelector((appState: AppState) =>
    appState.http.loading.includes('update-engagement-phase')
  )
  const getPreviousNextSectionSelector = getPreviousNextSection()
  const previousNextSection: PreviousNextSection = useSelector(
    (appState: AppState) => getPreviousNextSectionSelector(appState, props)
  )
  const { previousSectionId, nextSectionId } = previousNextSection
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      const config = user.isExternal
        ? clientUserButtonConfig(engagement)
        : rsmUserButtonConfig(engagement, user, engagementTemplate)

      if (config?.phaseButtonText === 'SUBMIT' && !modalDisplayed && !busy) {
        showModal()
        setModalDisplayed(true)
      } else if (!config?.phaseButtonText && modalDisplayed) {
        setModalDisplayed(false)
      }

      setConfig(config)
    }
    // eslint-disable-next-line
  }, [user, engagement])

  const handlePrevButtonClick = () => {
    if (previousSectionId) {
      navigateSection(engagement.id, previousSectionId)
    }
  }

  const handleNextButtonClick = () => {
    if (nextSectionId) {
      navigateSection(engagement.id, nextSectionId)
    }
  }

  const showModal = () => {
    dispatch(
      confirmModalActions.setConfirmModalAction({
        confirmModal: { phaseChange: 'PBC' },
      })
    )
  }

  if (!user || !engagement) {
    return null
  }

  return (
    <div className='question-list-buttons'>
      <div className='btn'>
        <button
          className='btn navigate'
          disabled={!previousSectionId}
          onClick={handlePrevButtonClick}
        >
          <Icon icon='isClosed' className='lesser' />{' '}
          <span className='d-none d-sm-block'> Previous Task</span>
        </button>
        <button
          className='btn navigate'
          disabled={!nextSectionId}
          onClick={handleNextButtonClick}
        >
          <span className='d-none d-sm-block'>Next Task </span>{' '}
          <Icon icon='isClosed' className='greater' />
        </button>

        {config?.phaseButtonText && !busy && (
          // busy check prevents phase buttons from popping in and out while engagement is being updated
          <button className='btn btn-primary phase' onClick={showModal}>
            {config.phaseButtonText}
          </button>
        )}
      </div>
      <div className='footer2visible'>
        <Footer />
      </div>
    </div>
  )
}

export default QuestionListButtons

import { TsaHubAction } from '../actions/index'
import { EngagementTemplate, EngagementTemplateMap } from '../clientModels'
import { getType } from 'typesafe-actions'
import {
  engagementTemplatesDocEndAction,
  engagementTemplateDoneAction,
} from '../actions/engagementTemplateActions'
import * as engagementActions from '../actions/engagementActions'

const initialState: EngagementTemplateMap = {}

const defaultTemplate: EngagementTemplate = {
  cchReturnType: 'X',
  name: 'default',
  questions: [],
  sections: [],
  taxYear: -1,
  id: -1,
  hasCchPhase: true,
  integrateWithCch: true,
}

export function engagementTemplateReducer(
  state: EngagementTemplateMap = initialState,
  action: TsaHubAction
): EngagementTemplateMap {
  switch (action.type) {
    case getType(engagementTemplatesDocEndAction):
      Object.values(action.payload.payload.templates).forEach(
        t => t && (t.loaded = true)
      )
      return { ...state, ...action.payload.payload.templates }

    case getType(engagementTemplateDoneAction):
    case getType(engagementActions.getEngagementListAction.success): {
      const newState = { ...state }
      const templates = action.payload.templates
      for (const prop in templates) {
        newState[prop] = {
          ...defaultTemplate,
          ...newState[prop],
          ...templates[prop],
        }
      }
      return newState
    }

    default:
      return state
  }
}

import { duoExportApi } from '../services/api'
import { TsaThunkAction } from '../store'
import { duoExportAction } from './engagementActions'

export const submitDuoExportData = (
  engagementId: number,
  fileType: string
): TsaThunkAction => async (dispatch, getState) => {
  const engagement = getState().engagements[engagementId]

  try {
    if (!engagement) {
      throw new Error('Could not find engagement')
    }
    const taxIntegrationStatus = await duoExportApi.apiDuoExportDataRequest(
      engagementId,
      fileType
    )

    return dispatch(
      duoExportAction.success({
        engagementId,
        taxIntegrationStatus: taxIntegrationStatus,
      })
    )
  } catch (error) {
    dispatch(duoExportAction.failure(error))
  }
}

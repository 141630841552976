import React from 'react'
import { RouteComponentProps, Route } from 'react-router'
import { DocLibraryAll } from '../docLibraryAll'
import { NothingToShow } from '../../scenes/engagements'
import DataFileManagementGroup from '../dataFileManagement/dataFileManagementGroup'

const NOTHING_TO_SHOW_PATHS = [
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/all/sections',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/all/comments',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/all/flags',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/all/carryforward',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/all/reports'
]

interface EntityNavHeaderProps extends RouteComponentProps<{}> {}

const EntityEngagementsList = ({ match }: EntityNavHeaderProps) => {
  return (
    <React.Fragment>
      <Route
        key='DataFileManagementGroup'
        path={`${match.path}/documents/upload/questions/:questionId/documenttitle/:documentTitleId`}
        component={DataFileManagementGroup}
        exact
      />
      <Route
        key='lists'
        path={`${match.path}/documents`}
        component={DocLibraryAll}
        exact
      />
      <Route path={NOTHING_TO_SHOW_PATHS} component={NothingToShow} />
    </React.Fragment>
  )
}

export default EntityEngagementsList

import { LastNavigation } from '../clientModels'
import {
  GetAllLastNavigation,
  SaveLastNavigation,
} from '../services/userSettingsService'
import { TsaThunkAction } from '../store'
import {
  lastNavSettingsDoneAction,
  lastNavSettingsUpdateAction,
} from './lastnavActions'

export const getAllLastNav = (): TsaThunkAction => (dispatch, getState) => {
  // read form local storage
  const lastNavigationMap = GetAllLastNavigation()
  // update Store.
  dispatch(lastNavSettingsDoneAction({ lastNavigationMap }))
}
export const updateLastNav = (
  lastNavigation: LastNavigation
): TsaThunkAction => (dispatch, getState) => {
  // save to local storage
  SaveLastNavigation(lastNavigation)
  // update Store
  dispatch(lastNavSettingsUpdateAction({ lastNavigation }))
}

import { TsaHubAction } from '../actions/index'
import { IdMap, OptionListMap } from '../clientModels'
import { getType } from 'typesafe-actions'
import { engagementTemplatesDocEndAction } from '../actions/engagementTemplateActions'

export interface OptionsState {
  codeListIdToOptionsId: IdMap
  options: OptionListMap
}

const initialState = {
  codeListIdToOptionsId: {},
  options: {},
}

export function optionsReducer (
  state: OptionsState = initialState,
  action: TsaHubAction
): OptionsState {
  switch (action.type) {
    default:
      return state

    case getType(engagementTemplatesDocEndAction):
      return {
        codeListIdToOptionsId: {
          ...state.codeListIdToOptionsId,
          ...action.payload.payload.optionsListLookup,
        },
        options: { ...state.options, ...action.payload.payload.optionsLists },
      }
  }
}

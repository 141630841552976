import * as http from '../http'
import { ClientSearch } from '../api/apiModels'
import { buildApiUrl } from './apiUrl'
import { getNewAbort } from '../lastRequests'
import { ClientAccessRequest } from '../../clientModels'

const clientAccessRequestAbort = getNewAbort()

export function getClientsHaveNoAccess(searchText: string): Promise<ClientSearch[]> {
  clientAccessRequestAbort.abort()
  return http.get(
    buildApiUrl('ClientAccessRequest/getClients', { search: searchText, max: 25 }),
    clientAccessRequestAbort.new()
  )
}

export function getAccessRequestById(requestId: number): Promise<ClientAccessRequest> {
  clientAccessRequestAbort.abort()
  return http.get(buildApiUrl('ClientAccessRequest/accessRequestById', { id: requestId }),
    clientAccessRequestAbort.new()
  )
}

export function getAllAccessRequest(): Promise<ClientAccessRequest[]> {
  clientAccessRequestAbort.abort()
  return http.get(buildApiUrl('ClientAccessRequest/allAccessRequest'),
    clientAccessRequestAbort.new()
  )
}

export function submitClientAccessRequest(clientId: number, masterId: number): Promise<ClientAccessRequest> {
  var url = buildApiUrl('ClientAccessRequest/submitAccessRequest', { clientId : clientId, masterId: masterId })
  return http.post(url, {})
}

export function approveAccessRequest(requestId: number): Promise<ClientAccessRequest> {
  
  var url = buildApiUrl('ClientAccessRequest/approveAccessRequest', { requestId })
  return http.post(url, {})
}
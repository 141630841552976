import * as http from '../http'
import { getNewAbort } from '../lastRequests'
import { buildApiUrl } from './apiUrl'
import { Activity } from './apiModels'

const activitiesAbort = getNewAbort()

export const apiDeleteActivity = (engagementId: number, id: number) => {
  return http.remove(buildApiUrl(`Activities/${id}`), activitiesAbort.new())
}

export const apiGetAllActivities = (
  engagementId: number,
  mostRecent?: number
) => {
  const url = buildApiUrl(
    `Engagements/${engagementId}/Activities${
      mostRecent ? `/${mostRecent}` : ''
    }`
  )
  return http.get(url, activitiesAbort.new())
}

export const apiSaveActivity = (activity: Activity) => {
  let url = 'Activities'
  if (activity.id > 0) {
    url += `/${activity.id}`
    return http.put(
      buildApiUrl(url),
      activity,
      undefined,
      activitiesAbort.new()
    )
  } else {
    return http.post(
      buildApiUrl(url),
      activity,
      undefined,
      activitiesAbort.new()
    )
  }
}

export const odataGetFlags = (engagementId: number, questionId?: number) => {
  const params = questionId ? { questionId } : undefined
  const url = buildApiUrl(`Activities/${engagementId}}`, params)
  return http.get(url, activitiesAbort.new())
}

export function odataSaveFlag(flag: Activity) {
  return http.post(buildApiUrl('Activities'), flag)
}

import classNames from 'classnames'
import { uniqueId } from 'lodash'
import * as React from 'react'
import MediaQuery from 'react-responsive'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { MediaMinBreakpoint } from '../../services/MediaQueries'
import { Alerts } from '../forms/alerts/index'
import { FocusableComponent } from '../forms/formComponentsInterfaces'
import { DatasheetProps, DatasheetState } from './datasheet'
import './datasheet.scss'
import {
  buildHeader,
  convertMessages,
  convertValue,
  defaultReadOnlyMaxRows,
  defaultReadOnlyMinRows,
  getColumn,
  getRootObject,
} from './datasheetUtilities'
import { DefaultValueViewer } from './defaultValueViewer'
import { PureCellRenderer } from './pureCellRenderer'
import { PureRowRenderer } from './pureRowRenderer'
import { processSchema, SchemaProperty } from './schemaUtilities'
import { SheetRenderer } from './sheetRenderer'

// tslint:disable:jsx-no-lambda - TODO: refactor to remove jsx lambdas

interface ReadOnlyDatasheetProps
  extends DatasheetProps,
    RouteComponentProps<{}> {
  disableEditMode?: boolean
}

interface ReadOnlyDatasheetState extends DatasheetState {
  navigateToEdit?: boolean
}

class ReadOnlyDataSheet
  extends React.Component<ReadOnlyDatasheetProps, ReadOnlyDatasheetState>
  implements FocusableComponent {
  static isReadOnly: boolean = true

  // tslint:disable-next-line:no-any - required by interface
  static formatValueLastYear = (value: any, props: any): JSX.Element => {
    props.value = value // Setting to last year's value
    props.messages = [] // Clear messages
    return <ReadOnlyDataSheet disableEditMode={true} {...props} />
  }

  state: ReadOnlyDatasheetState

  inputId: string
  labelId: string
  hintId: string
  input?: HTMLDivElement
  gridContentRef?: HTMLDivElement

  constructor(props: ReadOnlyDatasheetProps) {
    super(props)
    const id = uniqueId('textbox_')
    this.inputId = `${id}_input`
    this.labelId = `${id}_label`
    this.hintId = `${id}_hint`
    this.state = {
      columnDefinitionLookup: {},
      columnPathLookup: {},
      columns: [],
      columnsLength: 0,
      grid: [],
      headerWidth: null,
      maxRows: defaultReadOnlyMaxRows,
      messages: {},
      minRows: defaultReadOnlyMinRows,
      pasteError: null,
      pathColumnLookup: {},
      rowHeaders: null,
      scrollLeft: 0,
      scrollTop: 0,
      selectedCol: 0,
      selectedRow: 0,
      currentRows: 0,
      sheetHeader: [],
      selected: { start: { i: 0, j: 0 }, end: { i: 0, j: 0 } },
      selectedRowCount: 1,
    }

    this.focus = this.focus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.setInput = this.setInput.bind(this)
    this.getCellAlignment = this.getCellAlignment.bind(this)
    this.handleClickEdit = this.handleClickEdit.bind(this)
    this.handleKeyboardEdit = this.handleKeyboardEdit.bind(this)
    this.setGridContentRef = this.setGridContentRef.bind(this)

    let nextIndex = 0
    processSchema({
      rootObject: (
        type: 'array' | 'object',
        minItems?: number,
        maxItems?: number,
        headerWidth?: string,
        rowHeaders?: string[]
      ) =>
        getRootObject(
          type,
          minItems,
          maxItems,
          headerWidth,
          rowHeaders,
          this.state
        ),
      property: (column: SchemaProperty) =>
        (nextIndex = getColumn(column, this.state, nextIndex)),
      datasheetProps: props,
    })

    buildHeader(this.state)

    convertMessages(this.state, props.messages)
    convertValue(
      this.state,
      props.value,
      defaultReadOnlyMaxRows,
      props.hideExtraRow
    )
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: DatasheetProps) {
    const {
      state,
      props: { jsonSchema, value, messages },
    } = this
    const nextState = { ...state }
    let updateState = false

    if (
      nextProps.selectedQuestion &&
      nextProps.selectedPath &&
      nextProps.path === nextProps.selectedPath
    ) {
      this.focus()
    }

    if (jsonSchema !== nextProps.jsonSchema) {
      updateState = true
      nextState.columns = []
      nextState.sheetHeader = []
      nextState.columnPathLookup = {}
      nextState.pathColumnLookup = {}
      nextState.columnDefinitionLookup = {}
      nextState.columnsLength = 0
      let nextIndex = 0
      processSchema({
        rootObject: (
          type: 'array' | 'object',
          minItems?: number,
          maxItems?: number,
          headerWidth?: string,
          rowHeaders?: string[]
        ) =>
          getRootObject(
            type,
            minItems,
            maxItems,
            headerWidth,
            rowHeaders,
            nextState
          ),
        property: (column: SchemaProperty) =>
          (nextIndex = getColumn(column, nextState, nextIndex)),
        datasheetProps: nextProps,
      })
      buildHeader(nextState)
    }

    if (messages !== nextProps.messages) {
      updateState = true
      nextState.messages = {}
      convertMessages(nextState, nextProps.messages)
    }

    if (value !== nextProps.value || messages !== nextProps.messages) {
      updateState = true
      nextState.grid = []
      convertValue(
        nextState,
        nextProps.value,
        defaultReadOnlyMaxRows,
        nextProps.hideExtraRow
      )
    }

    if (updateState) {
      this.setState(nextState)
    }
  }

  focus() {
    const input = this.input
    if (input) {
      input.focus()
    }
  }

  handleBlur() {
    const { path, onBlur } = this.props
    if (onBlur) {
      onBlur(path)
    }
  }

  handleFocus() {
    const {
      props: { path, onFocus },
    } = this
    if (onFocus) {
      onFocus(path)
    }
  }

  setGridContentRef(ref: HTMLDivElement) {
    this.gridContentRef = ref
  }

  setInput(ref: HTMLDivElement) {
    this.input = ref
  }

  getCellAlignment(row: number, col: number) {
    const {
      state: { columnDefinitionLookup },
    } = this
    const colDef = columnDefinitionLookup[col]
    let alignment: string | undefined
    if (colDef) {
      alignment = colDef.alignment
    }
    return alignment
  }

  emptyHandler() {
    return {}
  }

  handleClickEdit(hasMinWidth: boolean) {
    const { disabled, disableEditMode } = this.props
    if (!disableEditMode && !disabled && hasMinWidth) {
      this.setState({ navigateToEdit: true })
    }
  }

  handleKeyboardEdit(
    e: React.KeyboardEvent<HTMLElement>,
    hasMinWidth: boolean
  ) {
    if (e.key === ' ' || (e.key === 'Enter' && hasMinWidth)) {
      this.setState({ navigateToEdit: true })
    }
  }

  render() {
    const {
      getCellAlignment,
      emptyHandler,
      handleClickEdit,
      handleFocus,
      handleKeyboardEdit,
      state: {
        sheetHeader,
        rowHeaders,
        headerWidth,
        grid,
        pasteError,
        navigateToEdit,
      },
      props: { disabled, disableEditMode, messages, showAlerts },
    } = this

    return (
      <MediaQuery minWidth={MediaMinBreakpoint.sm}>
        {matches => (
          <div className='link-datasheet-read-mode'>
            <div
              className='table-container'
              onClick={() => handleClickEdit(matches)}
            >
              <span
                className={classNames(
                  'data-grid-container',
                  'read-only-datasheet',
                  {
                    truncated: pasteError !== null && pasteError !== undefined,
                    uneditable: disableEditMode,
                  }
                )}
              >
                <SheetRenderer
                  className={'data-grid'}
                  columns={sheetHeader}
                  headerWidth={headerWidth || undefined}
                  data={grid}
                  setGridContentRef={this.setGridContentRef}
                >
                  {grid.map((rowCells, index) => (
                    <PureRowRenderer
                      key={`row${index}`}
                      rowHeaders={rowHeaders || undefined}
                      headerWidth={headerWidth || undefined}
                      row={index}
                      cells={rowCells}
                    >
                      {rowCells.map((cell, cellIndex) => {
                        const ValueViewer =
                          cell.valueViewer || DefaultValueViewer
                        return (
                          <PureCellRenderer
                            key={`cell${index}-${cellIndex}`}
                            className={classNames(
                              cell.className,
                              'cell',
                              cell.overflow,
                              cell.readOnly && 'read-only'
                            )}
                            alignment={getCellAlignment(index, cellIndex)}
                            gridContentRef={this.gridContentRef}
                            style={{}}
                            selected={false}
                            editing={false}
                            updated={false}
                            attributesRenderer={emptyHandler}
                            onMouseDown={emptyHandler}
                            onDoubleClick={emptyHandler}
                            onContextMenu={emptyHandler}
                            onMouseOver={emptyHandler}
                            row={index}
                            col={cellIndex}
                            cell={cell}
                            columns={
                              sheetHeader.length > 0
                                ? sheetHeader[sheetHeader.length - 1]
                                : []
                            }
                          >
                            <ValueViewer
                              row={index}
                              col={cellIndex}
                              cell={cell}
                              value={cell.value}
                            />
                          </PureCellRenderer>
                        )
                      })}
                    </PureRowRenderer>
                  ))}
                </SheetRenderer>
              </span>
            </div>
            {!disableEditMode && !disabled && matches && (
              <div className='view-edit-datasheet-region'>
                <div
                  className='view-edit-datasheet'
                  onClick={() => handleClickEdit(matches)}
                  onFocus={handleFocus}
                  onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
                    handleKeyboardEdit(e, matches)
                  }
                  tabIndex={0}
                >
                  <div>View/Edit Grid</div>
                </div>
              </div>
            )}
            {showAlerts && <Alerts messages={messages} />}
            {navigateToEdit && (
              <Redirect to={`${this.props.match.url}/edit`} push={true} />
            )}
          </div>
        )}
      </MediaQuery>
    )
  }
}

export default withRouter(ReadOnlyDataSheet)

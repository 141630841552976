import { JsonRule, RuleEvent } from 'json-rules-engine'
import {
  EngagementTemplateField,
  PropertyValues,
  QuestionMap,
} from '../clientModels'
import { perf } from '../services/performance'
import { chain } from '../services/promiseHelpers'
import { buildRules } from '../services/rules/buildRules'
import { createRulesEventHandler } from '../services/rules/createRulesEventHandler'
import {
  createEngines,
  getEngineByEngagementTemplateId,
} from '../services/rules/engines'
import store, { setIsDelayingUiUpdates, TsaThunkAction } from '../store'
import { rulesEventHandlers } from './rulesEventsHandlers'
import {
  runQuestionRulesInternal,
  RuntimeFacts,
  selectEngagement,
  selectEngagementTemplate,
} from './rulesThunksInternal'

let isRunningRules = 0

/** Returns a boolean value indicating whether or not the rules engine is running. */
export function getIsRunningRules() {
  return isRunningRules > 0
}

/** Sets the flags indicating that the rules engine is currently running. */
function setIsRunningRules(val: boolean): void {
  // If this method is called with 'true' 2 or more times, we need to ensure
  //  that the number of calls with 'false' are equal before the condition is *actually* true.
  if (val) {
    isRunningRules++
  } else {
    isRunningRules--
  }

  // Set the dispatcher to suspend or resume UI updates, based
  //  on whether or not rules are just starting to run or just ending.
  if (val && isRunningRules === 1) {
    setIsDelayingUiUpdates(true)
  } else if (!val && isRunningRules < 1) {
    setIsDelayingUiUpdates(false)
  }
}

const performanceQuestionRules = perf('Rules (Question)', true)
const performanceAllRules = perf('Rules (All)', true)

/**
 * Run engagement rules that are not associated with a particular question.
 * e.g.) Engagement phase rules
 * @param engagementId
 */
export const runEngagementLevelRules = (
  engagementId: number
): TsaThunkAction<Promise<RuleEvent[]>> => async (_, getState) => {
  const state = getState()
  const engagement = selectEngagement(state, engagementId)
  const engagementTemplateId = engagement.engagementTemplateId
  const templateEngine = getEngineByEngagementTemplateId(engagementTemplateId)
  const facts: RuntimeFacts = {
    clientId: engagement.clientId,
    engagementId,
    engagementTemplateId,
    rowIndex: -1,
  }

  if (templateEngine) {
    setIsRunningRules(true)
    // Switched this to await rather than just returning the Promise from run()
    // If we don't use await other thunks don't seem to wait until the promise is resolved before continuing.
    return templateEngine.run(facts).then(x => { setIsRunningRules(false); return x; })
  }

  return Promise.resolve([])
}

let uniqueId = 0
/**
 * Run all rules for the specified engagement.
 */
export const runAllEngagementRules = (engagementId: number): TsaThunkAction => (
  dispatch,
  getState
) => {
  setIsRunningRules(true)
  const state = getState()
  const engagement = selectEngagement(state, engagementId)
  const template = selectEngagementTemplate(
    state,
    engagement.engagementTemplateId
  )
  const id = uniqueId++

  performanceAllRules.beginMark(id)

  // Run rules for each question in the engagement followed by engagement level rules
  return chain(template.questions || [], questionId =>
    runQuestionRulesInternal(getState, engagementId, questionId)
  )
    .then(() => dispatch(runEngagementLevelRules(engagementId)))
    .then(() => performanceAllRules.endMark(id))
    .then(() => setIsRunningRules(false))
}

/**
 * Run all rules for the specified question on the specified engagement.
 * @param engagmentId The engagement ID.
 * @param questionId The question ID.
 * @param rowIndexes Property values that were changed if this was the result of a partial answer update.
 */
export const runQuestionRules = (
  engagementId: number | string,
  questionId: number | string,
  properties?: PropertyValues
): TsaThunkAction => (_, getState) => {
  setIsRunningRules(true)
  performanceQuestionRules.beginMark(questionId)
  return runQuestionRulesInternal(
    getState,
    engagementId,
    questionId,
    properties
  ).then(() => performanceQuestionRules.endMark(questionId))
    .then(() => setIsRunningRules(false))
}

const rulesEventHandler = createRulesEventHandler(
  store.dispatch,
  rulesEventHandlers
)

export function loadEngagementTemplateRules(
  engagementTemplateId: number,
  rules: JsonRule[],
  questions: QuestionMap,
  fields: EngagementTemplateField[]
) {
  const includesGeneratedRules = rules.some(
    r => r.event.params.engagementTemplateFieldId
  )
  if (!includesGeneratedRules) {
    // Add client-side generated rules to the ones contained in the engagement template
    rules = rules.concat(buildRules(fields))
  }
  createEngines(engagementTemplateId, rules, rulesEventHandler, questions)
}

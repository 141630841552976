import { compact } from 'lodash'
import { isObject } from '../../guards'

// tslint:disable-next-line:no-any - value can be anything
export function renderComplexAnswerLastYear (...answerParts: any[]) {
  const newAnswerParts: string[] = []
  answerParts.forEach(item => {
    if (item) {
      if (isObject(item)) {
        if (Object.values(item).toString()) {
          newAnswerParts.push(Object.values(item).toString())
        } else {
          newAnswerParts.push(Object.values(item)[0].toString())
        }
      } else {
        newAnswerParts.push(item)
      }
    }
  })
  return compact(newAnswerParts).join(' ● ')
}

/**
 * Chain promises serially.
 */
// tslint:disable-next-line:no-any
export function chain<T> (
  values: T[],
  callback: (value: T) => Promise<any>,
  initial?: Promise<any>
) {
  // tslint:disable-next-line:no-any
  return values.reduce(
    (promise: Promise<any>, value: T) => promise.then(() => callback(value)),
    initial || Promise.resolve()
  )
}

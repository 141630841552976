import { EtlApi } from '../services/api'
import { TsaThunkAction } from '../store'
import * as etlActions from './etlActions'

export const getFileErrors = (
  fileId: number
): TsaThunkAction<Promise<string[]>> => async (dispatch, getState) => {
  try {
    dispatch(etlActions.getFileErrorsActions.request())
    const result = await EtlApi.getErrors(fileId)
    dispatch(etlActions.getFileErrorsActions.success(result))
    return result
  } catch (error) {
    dispatch(etlActions.getFileErrorsActions.failure(error))
  }
  return []
}

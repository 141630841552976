import { Section } from '../clientModels'
import { createAction, ActionType } from 'typesafe-actions'

interface QuestionSection {
  id: number
  sectionId: number
}

export const defaultEngagementQuestionsAction = createAction(
  'DEFAULT_ENGAGEMENT_SECTIONS_QUESTIONS'
)<{ engagementId: number; questions: QuestionSection[]; sections: Section[] }>()

export type DefaultEngagementSectionsQuestionsAction = ActionType<
  typeof defaultEngagementQuestionsAction
>

import {
  ActionType as TypedAction,
  createAsyncAction,
  createAction,
} from 'typesafe-actions'
import {
  ClientLight,
  ClientMap,
  Engagement,
  EngagementMap,
  EngagementQuestionServerData,
  EngagementSectionGroup,
  EngagementTemplate,
  EntityMap,
  EtlFile,
  FileGroup,
  IEngagementQuestionAnswer,
  K1Header,
  QuestionStateSummary,
  TaxIntegrationStatus,
} from '../clientModels'
import { PhaseCode } from '../enums'

export const getEngagementAction = createAsyncAction(
  'GET_ENGAGEMENT_BEGIN',
  'GET_ENGAGEMENT_DONE',
  'GET_ENGAGEMENT_FAIL'
)<
  number,
  {
    engagementId: number
    engagements: EngagementMap
    answers: EntityMap<IEngagementQuestionAnswer>
    fileGroups: FileGroup[]
    clients: ClientMap
    k1s: K1Header[]
    etlFiles: EntityMap<EtlFile>
  },
  Error
>()

export const getEngagementFinishedLoadingAction = createAction(
  'GET_ENGAGEMENT_FINISHED_LOADING'
)<{
  engagementId: number
}>()

export const getEngagementQuestionsDoneAction = createAction(
  'GET_ENGAGEMENT_QUESTIONS_DONE'
)<{
  engagementId: number
  engagementQuestions: EngagementQuestionServerData[]
}>()

export const getEngagementListAction = createAsyncAction(
  'GET_ENGAGEMENT_LIST_BEGIN',
  'GET_ENGAGEMENT_LIST_DONE',
  'GET_ENGAGEMENT_LIST_FAIL'
)<
  void,
  {
    engagements: EngagementMap
    clients: EntityMap<ClientLight>
    templates: EntityMap<EngagementTemplate>
  },
  Error
>()

export const copyLastYearAnswersAction = createAsyncAction(
  'COPY_LAST_YEAR_ANSWERS_BEGIN',
  'COPY_LAST_YEAR_ANSWERS_DONE',
  'COPY_LAST_YEAR_ANSWERS_FAIL'
)<
  void,
  {
    engagementId: string | number
    answers: EntityMap<IEngagementQuestionAnswer>
  },
  Error
>()

export const getLastYearsDataAction = createAsyncAction(
  'GET_LAST_YEARS_DATA_BEGIN',
  'GET_LAST_YEARS_DATA_DONE',
  'GET_LAST_YEARS_DATA_FAIL'
)<
  void,
  {
    engagementId: number | string
  },
  Error
>()

export const updateEngagementLastModifiedAction = createAction(
  'UPDATE_ENGAGEMENT_LAST_MODIFIED'
)<{
  engagementId: number
  lastUpdatedDate: string
}>()

export const updateEngagementMilestoneAction = createAsyncAction(
  'UPDATE_ENGAGEMENT_MILESTONE_BEGIN',
  'UPDATE_ENGAGEMENT_MILESTONE_DONE',
  'UPDATE_ENGAGEMENT_MILESTONE_FAIL'
)<
  void,
  {
    engagementId: number
    engagements: EngagementMap // normalized form of the engagement, only one engagement
  },
  Error
>()

export const updateEngagementPhaseAction = createAsyncAction(
  'UPDATE_ENGAGEMENT_PHASE_BEGIN',
  'UPDATE_ENGAGEMENT_PHASE_DONE',
  'UPDATE_ENGAGEMENT_PHASE_FAIL'
)<
  {
    engagementId: number
    phase: PhaseCode
  },
  {
    engagementId: number
    engagements: EngagementMap // normalized form of the engagement, only one engagement
  },
  Error
>()

export const updateEngagementPhaseRollbackAction = createAction(
  'UPDATE_ENGAGEMENT_PHASE_ROLLBACK'
)<{
  engagementId: number
  phase: PhaseCode
}>()

export const updateEngagementQuestionStateSummaryAction = createAsyncAction(
  'UPDATE_ENGAGEMENT_QUESTION_STATE_SUMMARY_BEGIN',
  'UPDATE_ENGAGEMENT_QUESTION_STATE_SUMMARY_DONE',
  'UPDATE_ENGAGEMENT_QUESTION_STATE_SUMMARY_FAIL'
)<
  void,
  {
    engagementId: number
    engagementSummary: QuestionStateSummary
  },
  Error
>()

export const setEngagementCriticalAction = createAsyncAction(
  'SET_ENGAGEMENT_CRITICAL_BEGIN',
  'SET_ENGAGEMENT_CRITICAL_DONE',
  'SET_ENGAGEMENT_CRITICAL_FAIL'
)<
  void,
  {
    engagementId: number
    engagements: EngagementMap
  },
  Error
>()

export const setEngagementNotCriticalAction = createAsyncAction(
  'SET_ENGAGEMENT_NOT_CRITICAL_BEGIN',
  'SET_ENGAGEMENT_NOT_CRITICAL_DONE',
  'SET_ENGAGEMENT_NOT_CRITICAL_FAIL'
)<
  void,
  {
    engagementId: number
    engagements: EngagementMap
  },
  Error
>()

export const submitEngagementAction = createAsyncAction(
  'SUBMIT_ENGAGEMENT_BEGIN',
  'SUBMIT_ENGAGEMENT_DONE',
  'SUBMIT_ENGAGEMENT_FAIL'
)<
  void,
  {
    engagementId: number | string
  },
  Error
>()

export const toggleFavoriteEngagementAction = createAsyncAction(
  'TOGGLE_FAVORITE_BEGIN',
  'TOGGLE_FAVORITE_DONE',
  'TOGGLE_FAVORITE_FAIL'
)<
  {
    engagementId: number | string
  },
  {
    engagement?: Engagement
  },
  Error
>()

export const toggleLockEngagementAction = createAsyncAction(
  'TOGGLE_LOCK_BEGIN',
  'TOGGLE_LOCK_DONE',
  'TOGGLE_LOCK_FAIL'
)<
  {
    engagementId: number | string
  },
  {
    engagementId: number | string
  },
  Error
>()

export const startLoadAnswersEtlAction = createAsyncAction(
  'START_LOAD_ANSWERS_BEGIN',
  'START_LOAD_ANSWERS_DONE',
  'START_LOAD_ANSWERS_FAIL'
)<
  void,
  {
    engagementId: number
    taxIntegrationStatus: TaxIntegrationStatus
  },
  Error
>()

export const documentArchiveBeginAction = createAction(
  'GET_DOCUMENT_ARCHIVE_BEGIN'
)<{ engagementId: number }>()

export const documentArchiveDoneAction = createAction(
  'GET_DOCUMENT_ARCHIVE_DONE'
)<{ engagementId: number }>()

export const documentArchiveFailAction = createAction(
  'GET_DOCUMENT_ARCHIVE_FAIL'
)<Error, { engagementId: number }>()

export const setEngagementSectionGroupsAction = createAction(
  'SET_ENGAGEMENT_SECTION_GROUPS'
)<{
  taxForm: string
  taxYear: string
  sectionGroups: EngagementSectionGroup[]
}>()

export const duoExportAction = createAsyncAction(
  'DUO_EXPORT_STATUS_BEGIN',
  'DUO_EXPORT_STATUS_DONE',
  'DUO_EXPORT_STATUS_FAIL'
)<
  void,
  {
    engagementId: number
    taxIntegrationStatus: TaxIntegrationStatus
  },
  Error
>()

export type EngagementActions =
  | TypedAction<typeof copyLastYearAnswersAction.request>
  | TypedAction<typeof copyLastYearAnswersAction.success>
  | TypedAction<typeof copyLastYearAnswersAction.failure>
  | TypedAction<typeof documentArchiveBeginAction>
  | TypedAction<typeof documentArchiveDoneAction>
  | TypedAction<typeof documentArchiveFailAction> // created standard actions in order to pass metadata
  | TypedAction<typeof getEngagementFinishedLoadingAction>
  | TypedAction<typeof getEngagementListAction.request>
  | TypedAction<typeof getEngagementListAction.success>
  | TypedAction<typeof getEngagementListAction.failure>
  | TypedAction<typeof getEngagementQuestionsDoneAction>
  | TypedAction<typeof getLastYearsDataAction.request>
  | TypedAction<typeof getLastYearsDataAction.success>
  | TypedAction<typeof getLastYearsDataAction.failure>
  | TypedAction<typeof setEngagementCriticalAction.request>
  | TypedAction<typeof setEngagementCriticalAction.success>
  | TypedAction<typeof setEngagementCriticalAction.failure>
  | TypedAction<typeof setEngagementNotCriticalAction.request>
  | TypedAction<typeof setEngagementNotCriticalAction.success>
  | TypedAction<typeof setEngagementNotCriticalAction.failure>
  | TypedAction<typeof submitEngagementAction.request>
  | TypedAction<typeof submitEngagementAction.success>
  | TypedAction<typeof submitEngagementAction.failure>
  | TypedAction<typeof toggleFavoriteEngagementAction.request>
  | TypedAction<typeof toggleFavoriteEngagementAction.success>
  | TypedAction<typeof toggleFavoriteEngagementAction.failure>
  | TypedAction<typeof toggleLockEngagementAction.request>
  | TypedAction<typeof toggleLockEngagementAction.success>
  | TypedAction<typeof toggleLockEngagementAction.failure>
  | TypedAction<typeof getEngagementAction.request>
  | TypedAction<typeof getEngagementAction.success>
  | TypedAction<typeof getEngagementAction.failure>
  | TypedAction<typeof updateEngagementLastModifiedAction>
  | TypedAction<typeof updateEngagementMilestoneAction.success>
  | TypedAction<typeof updateEngagementMilestoneAction.failure>
  | TypedAction<typeof updateEngagementPhaseAction.request>
  | TypedAction<typeof updateEngagementPhaseAction.success>
  | TypedAction<typeof updateEngagementPhaseAction.failure>
  | TypedAction<typeof updateEngagementPhaseRollbackAction>
  | TypedAction<typeof updateEngagementQuestionStateSummaryAction.success>
  | TypedAction<typeof updateEngagementQuestionStateSummaryAction.failure>
  | TypedAction<typeof startLoadAnswersEtlAction.request>
  | TypedAction<typeof startLoadAnswersEtlAction.success>
  | TypedAction<typeof startLoadAnswersEtlAction.failure>
  | TypedAction<typeof setEngagementSectionGroupsAction>
  | TypedAction<typeof duoExportAction.request>
  | TypedAction<typeof duoExportAction.success>
  | TypedAction<typeof duoExportAction.failure>

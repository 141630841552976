import invariant from 'invariant'
import { get, toPath } from 'lodash'
import ReactDataSheet from 'react-datasheet'
import { EntityMap, Message, Option } from '../../clientModels'
import { isString, isStringOrNullOrUndefined } from '../../guards'
import { convertToISODate, dateInCorrectFormat } from '../forms/formUtilities'
import { GridElement } from './component'
import { DatasheetState } from './datasheet'
import { SchemaProperty } from './schemaUtilities'

export const defaultMaxRows = 300
export const defaultMinRows = 0
export const defaultReadOnlyMaxRows = 10
export const defaultReadOnlyMinRows = 1

export function getRootObject (
  type: 'array' | 'object',
  minItems: number | undefined,
  maxItems: number | undefined,
  headerWidth: string | undefined,
  rowHeaders: string[] | undefined,
  state: DatasheetState
) {
  state.rowHeaders = rowHeaders !== undefined ? rowHeaders : null
  state.maxRows = maxItems || state.maxRows
  state.minRows = minItems || state.minRows
  state.headerWidth = headerWidth !== undefined ? headerWidth : null
}

export function getColumn (
  column: SchemaProperty,
  state: DatasheetState,
  nextIndex: number
) {
  const columns = column.columns
  state.columnsLength += columns
  const paths = buildPath(column)
  const defs = buildDefs(column)
  invariant(paths.length === columns, 'Mismatch in paths and expected columns')
  for (let i = 0; i < paths.length; ++i) {
    state.columnPathLookup[nextIndex] = paths[i]
    state.pathColumnLookup[paths[i]] = nextIndex
    state.columnDefinitionLookup[nextIndex] = defs[i]
    ++nextIndex
  }
  state.columns.push(column)
  return nextIndex
}

export function buildHeader (state: DatasheetState) {
  const { columns } = state
  const columnsLength = columns.length
  let maxDepth = 0
  for (let i = 0; i < columnsLength; ++i) {
    const column = columns[i]
    if (column.depth > maxDepth) {
      maxDepth = column.depth
    }
  }
  for (let i = 0; i < columnsLength; ++i) {
    buildHeaderColumn(state, columns[i], maxDepth, 0)
  }
}

function buildHeaderColumn (
  state: DatasheetState,
  column: SchemaProperty,
  currentDepth: number,
  index: number,
  hasParent?: boolean
) {
  const childDepth = currentDepth - 1
  const childIndex = index + 1
  if (!state.sheetHeader[index]) {
    state.sheetHeader[index] = []
  }
  if (column.depth !== currentDepth) {
    state.sheetHeader[index].push({
      colSpan: column.columns,
      text: hasParent ? '|' : '',
    })
    buildHeaderColumn(state, column, childDepth, childIndex)
  } else {
    state.sheetHeader[index].push({
      colSpan: column.columns,
      text: column.title || column.name,
      displayWidth: column.displayWidth,
      minWidth: column.minWidth,
      maxWidth: column.maxWidth,
    })
    const children = column.children
    if (children) {
      const childrenLength = children.length
      for (let i = 0; i < childrenLength; ++i) {
        buildHeaderColumn(state, children[i], childDepth, childIndex, true)
      }
    }
  }
}

function buildPath (column: SchemaProperty): string[] {
  const result: string[] = []

  if (column.children && column.children.length > 0) {
    const rootPath = column.name + '.'
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < column.children.length; ++i) {
      const paths = buildPath(column.children[i])
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < paths.length; ++j) {
        result.push(rootPath + paths[j])
      }
    }
  } else {
    result.push(column.name)
  }

  return result
}

function buildDefs (column: SchemaProperty): SchemaProperty[] {
  const result: SchemaProperty[] = []

  if (column.children && column.children.length > 0) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < column.children.length; ++i) {
      const defs = buildDefs(column.children[i])
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < defs.length; ++j) {
        result.push(defs[j])
      }
    }
  } else {
    result.push(column)
  }

  return result
}

export function convertValue (
  state: DatasheetState,
  value?: string | object | string[] | object[] | null,
  absoluteMaxRows?: number,
  hideExtraRow?: boolean,
  handleChange?: (
    changes: ReactDataSheet.CellsChangedArgs<GridElement>,
    additions: ReactDataSheet.CellsChangedArgs<GridElement>
  ) => void
) {
  const {
    columnsLength,
    columnDefinitionLookup,
    messages,
    minRows,
    maxRows,
  } = state
  const arrayValue = value == null ? [] : Array.isArray(value) ? value : [value]

  state.currentRows = arrayValue.length

  let mRows = maxRows
  if (absoluteMaxRows && absoluteMaxRows < mRows) {
    mRows = absoluteMaxRows
  }

  state.pasteError = null

  if (arrayValue.length > 0) {
    if (typeof arrayValue[0] === 'string') {
      // not sure what to do with a value of string, no column definition
      invariant(false, 'array of strings not implemented')
    } else {
      // normal situation
      const { columnPathLookup } = state
      let length = arrayValue.length
      if (length > mRows) {
        state.pasteError = {
          startingRow: 1,
          usedRows: mRows,
          truncatedRows: length - mRows,
          isValueSet: true,
        }
        length = mRows
      }
      for (let i = 0; i < length; ++i) {
        const rowValue = arrayValue[i]
        state.grid[i] = []
        for (let j = 0; j < columnsLength; ++j) {
          const lookup = columnPathLookup[j]
          const definition = columnDefinitionLookup[j]
          if (lookup && definition) {
            // if date, check if in correct format, else queue up change
            let cellValue = get(rowValue, lookup)
            if (
              !!cellValue &&
              definition.component === 'date' &&
              isStringOrNullOrUndefined(cellValue)
            ) {
              const correctDate = dateInCorrectFormat(cellValue)
              if (correctDate) {
                cellValue = convertToISODate(correctDate)
                if (handleChange) {
                  setTimeout(
                    (val: string) => {
                      handleChange(
                        [
                          {
                            cell: null,
                            row: i,
                            col: j,
                            value: val,
                          },
                        ],
                        []
                      )
                    },
                    0,
                    cellValue
                  )
                }
              }
            }

            state.grid[i][j] = {
              value: cellValue,
              dataEditor: definition.editor,
              valueViewer: definition.viewer,
              messages,
            }
          }
        }
      }
    }
  }

  if (minRows && state.grid.length < minRows && absoluteMaxRows === undefined) {
    const newRows = minRows - state.grid.length
    const startRow = state.grid.length
    for (let i = 0; i < newRows; ++i) {
      state.grid[i + startRow] = []
      for (let j = 0; j < columnsLength; ++j) {
        const definition = columnDefinitionLookup[j]
        if (definition) {
          state.grid[i + startRow][j] = {
            value: definition.default || '',
            dataEditor: definition.editor,
            valueViewer: definition.viewer,
            messages,
          }
        }
      }
    }
  }

  if (
    state.grid.length < mRows &&
    absoluteMaxRows === undefined &&
    !hideExtraRow
  ) {
    let j = state.grid.length - 1
    let notEmpty = j < 0
    if (!notEmpty) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < state.grid[j].length; ++i) {
        if (state.grid[j][i].value !== '') {
          notEmpty = true
          break
        }
      }
    }
    if (notEmpty) {
      ++j
      state.grid[j] = []
      for (let i = 0; i < columnsLength; ++i) {
        const definition = columnDefinitionLookup[i]
        if (definition) {
          state.grid[j][i] = {
            value: definition.default || '',
            dataEditor: definition.editor,
            valueViewer: definition.viewer,
            messages,
          }
        }
      }
    }
  }
}

export function convertMessages (state: DatasheetState, messages?: Message[]) {
  if (messages) {
    const coordMessages: EntityMap<EntityMap<Message[]>> = {}
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < messages.length; ++i) {
      const message = messages[i]
      if (!message.path) {
        continue
      }
      const parts = toPath(message.path)
      const row = parseInt(parts[0], 10)
      const col = state.pathColumnLookup[parts.slice(1).join('.')]
      if (col !== undefined && !isNaN(row)) {
        let rowMessage = coordMessages[row]
        if (!rowMessage) {
          rowMessage = coordMessages[row] = {}
        }
        let colMessage = rowMessage[col]
        if (!colMessage) {
          colMessage = rowMessage[col] = []
        }
        colMessage.push(message)
      }
    }
    state.messages = coordMessages
  }
}

// tslint:disable-next-line:no-any
export function findOptionValue (options?: Option[], value?: any) {
  if (isString(value)) {
    let option =
      options &&
      // Find an option using an exact match on value or label
      (options.find(o => o.value.toLowerCase() === value.toLowerCase()) ||
        options.find(o => o.label.toLowerCase() === value.toLowerCase()))

    if (option === undefined) {
      option = {
        codeNotInList: true,
        label: value,
        value,
      }
    }

    return option
  }

  return convertValueToOption(value)
}

// tslint:disable-next-line:no-any
function convertValueToOption (value?: any): Option | undefined {
  if (!value || (!!value.label && !!value.value)) {
    return value
  }
  let result: Option | undefined
  for (const prop in value) {
    result = {
      label: value[prop],
      value: prop,
    }
  }
  return result
}

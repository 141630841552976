import { actions } from '.'
import {
  EntityMap,
  InputProcessTemplateMap,
  Option,
  FormatSetup,
  K1Header,
  ClientEntity,
} from '../clientModels'
import { ensureNumber } from '../guards'
import {
  ApiModels,
  AzureApi,
  EngagementsApi,
  EntitiesApi,
  InputProcessApi,
  K1Api,
} from '../services/api'
import {
  ColumnFilters,
  Partner,
  InputProcessTemplate,
  EtlFileProgress,
  NameNumber,
  K1Progress,
} from '../services/api/apiModels'
import { saveBlob } from '../services/http'
import { TsaThunkAction } from '../store'
import {
  updateK1FileProgressAction,
  updateK1FileStatusAction,
  updateK1ProgressAction,
  updateK1StatusAction,
  updateOcrDataAction,
  validateK1FileAction,
} from './inputProcessActions'

export interface InputProcessOptions {
  pivotColumnOptions: Option[]
  pivotRowOptions: Option[]
  rowOptions: Option[]
}

export function NormalizePivotSetupSelection(
  selections?: NameNumber[]
): EntityMap<string> {
  const normalized: EntityMap<string> = {}

  if (selections) {
    selections.forEach(x => {
      normalized[x.number] = x.name
    })
  }

  return normalized
}



function ConvertOptions(serverOptions: { [id: string]: string }) {
  const newOptions = [{ label: 'Clear Selection', value: '' }]
  for (var key in serverOptions) {
    newOptions.push({
      label: serverOptions[key],
      value: key,
    })
  }
  return newOptions
}

export const completeK1FileProgress = (
  fileId: number
): TsaThunkAction<Promise<ApiModels.File | null>> => async (
  dispatch,
  getState
) => {
    try {
      dispatch(actions.inputProcess.completeK1FileProgressActions.request())
      const result = await InputProcessApi.completeK1FileProgress(fileId)
      dispatch(actions.inputProcess.completeK1FileProgressActions.success(result))
      return result
    } catch (error) {
      dispatch(actions.inputProcess.completeK1FileProgressActions.failure(error))
    }
    return null
  }

export const downloadTaxCodeMap = (
  fileId: number,
  excludeMappedData?: boolean,
  k1HeaderId?: number
): TsaThunkAction => async dispatch => {
  try {
    dispatch(actions.inputProcess.downloadTaxCodeMapAction.request())
    InputProcessApi.apiDownloadTaxCodeMap(fileId, excludeMappedData, k1HeaderId)
      .then(saveBlob)
      .then(() =>
        dispatch(actions.inputProcess.downloadTaxCodeMapAction.success())
      )
      .catch(error => {
        dispatch(actions.inputProcess.downloadTaxCodeMapAction.failure(error))
      })
  } catch (error) {
    dispatch(actions.inputProcess.downloadTaxCodeMapAction.failure(error))
  }
  return []
}

export const exportK1FileData = (
  fileId: number
): TsaThunkAction => async dispatch => {
  try {
    dispatch(actions.inputProcess.exportK1FileDataAction.request())
    InputProcessApi.apiExportK1FileData(fileId)
      .then(saveBlob)
      .then(() =>
        dispatch(actions.inputProcess.exportK1FileDataAction.success(fileId))
      )
      .catch(error => {
        dispatch(actions.inputProcess.exportK1FileDataAction.failure(error))
      })
  } catch (error) {
    dispatch(actions.inputProcess.exportK1FileDataAction.failure(error))
  }
  return []
}

export const getDataMappingTemplateForK1 = (
  k1Id: number
): TsaThunkAction<Promise<InputProcessTemplate | null>> => async (
  dispatch,
  getState
) => {
    try {
      dispatch(actions.inputProcess.getDataMappingTemplateForK1Actions.request())
      const result = await InputProcessApi.getDataMappingTemplateForK1(k1Id)
      dispatch(
        actions.inputProcess.getDataMappingTemplateForK1Actions.success(result)
      )
      return result
    } catch (error) {
      dispatch(
        actions.inputProcess.getDataMappingTemplateForK1Actions.failure(error)
      )
    }
    return null
  }

export const getEngagementK1FileStatuses = (
  engagementId: number,
  questionId: number
): TsaThunkAction => async (dispatch, getState) => {
  try {
    dispatch(actions.inputProcess.getEngagementK1FileStatusesActions.request())
    const result = await InputProcessApi.getEngagementK1FileStatuses(
      engagementId,
      questionId
    )
    dispatch(
      actions.inputProcess.getEngagementK1FileStatusesActions.success(result)
    )
  } catch (error) {
    dispatch(
      actions.inputProcess.getEngagementK1FileStatusesActions.failure(error)
    )
  }
}

export const getEngagementK1s = (
  engagementId: number
): TsaThunkAction => async (dispatch, getState) => {
  try {
    dispatch(actions.inputProcess.getEngagementK1sActions.request())

    let k1s: K1Header[] = []
    k1s = await K1Api.getK1sByEngagementId(engagementId)
    dispatch(actions.inputProcess.getEngagementK1sActions.success(k1s))
  } catch (error) {
    dispatch(actions.inputProcess.getEngagementK1sActions.failure(error))
  }
}

export const getK1ById = (
  k1Id: number
): TsaThunkAction<Promise<K1Header | null>> => async (dispatch, getState) => {
  try {
    dispatch(actions.inputProcess.getK1ByIdAction.request())
    const result = await K1Api.getById(k1Id)
    dispatch(actions.inputProcess.getK1ByIdAction.success(result))
    return result
  } catch (error) {
    dispatch(actions.inputProcess.getK1ByIdAction.failure(error))
  }
  return null
}

export const getK1sByFileId = (
  fileId: number
): TsaThunkAction<Promise<K1Header[]>> => async (dispatch, getState) => {
  try {
    dispatch(actions.inputProcess.getK1sByFileIdAction.request())
    const result = await K1Api.getK1sByFileId(fileId)
    dispatch(actions.inputProcess.getK1sByFileIdAction.success(result))
    return result
  } catch (error) {
    dispatch(actions.inputProcess.getK1sByFileIdAction.failure(error))
  }
  return []
}

export const getFilterValues = (
  fileId: number
): TsaThunkAction<Promise<{ [id: string]: string[] }>> => async dispatch => {
  try {
    dispatch(actions.inputProcess.getAvailableColumnFiltersAction.request())
    const filterValues = await InputProcessApi.apiGetFilterValues(fileId)
    dispatch(
      actions.inputProcess.getAvailableColumnFiltersAction.success(filterValues)
    )
    return filterValues
  } catch (error) {
    dispatch(
      actions.inputProcess.getAvailableColumnFiltersAction.failure(error)
    )
  }
  return {}
}

export const getPivotData = (
  fileId: number,
  formatSetup: FormatSetup,
  numberOfRows?: number
): TsaThunkAction<Promise<string[][]>> => async (dispatch, getState) => {
  try {
    dispatch(actions.inputProcess.getPivotDataAction.request())
    // pass an InputProcessWorksheet without a PivotSetup
    // to signal that we are request a preview
    const worksheetData = await InputProcessApi.apiProcessWorksheet(
      ensureNumber(fileId),
      'pivot',
      {
        formatSetup,
        numberOfRows: numberOfRows || 100,
      }
    )
    dispatch(actions.inputProcess.getPivotDataAction.success(worksheetData))
    return worksheetData
  } catch (error) {
    dispatch(actions.inputProcess.getPivotDataAction.failure(error))
  }
  return []
}

export const loadPivotOptions = (): TsaThunkAction => async (
  dispatch,
  getState
) => {
  try {
    dispatch(actions.inputProcess.getPivotOptionsActions.request({}))
    const options = await Promise.all([
      InputProcessApi.apiGetWorksheetPivotColumnOptions(),
      InputProcessApi.apiGetWorksheetPivotRowOptions(),
      InputProcessApi.apiGetWorksheetTabularOptions(),
    ])

    dispatch(
      actions.inputProcess.getPivotOptionsActions.success({
        pivotColumnOptions: ConvertOptions(options[0]),
        pivotRowOptions: ConvertOptions(options[1]),
        rowOptions: ConvertOptions(options[2]),
      })
    )
  } catch (error) {
    dispatch(actions.inputProcess.getPivotOptionsActions.failure(error))
  }
}

export const loadCategoryOptions = (): TsaThunkAction => async (
  dispatch,
  getState
) => {
  try {
    const taxCodes = await InputProcessApi.apiGetWorksheetMappingOptions()
    dispatch(
      actions.inputProcess.getCategoryOptionsActions.success({
        categoryOptions: taxCodes,
      })
    )
  } catch (error) {
    dispatch(actions.inputProcess.getCategoryOptionsActions.failure(error))
  }
}

export const loadTemplates = (): TsaThunkAction<Promise<
  InputProcessTemplateMap
>> => async (dispatch, getState) => {
  try {
    dispatch(actions.inputProcess.getInputProcessTemplatesActions.request({}))
    const templates = await InputProcessApi.apiGetTemplates()
    const templateMap: InputProcessTemplateMap = {}
    templates.forEach(x => {
      templateMap[x.id] = x
    })
    dispatch(
      actions.inputProcess.getInputProcessTemplatesActions.success({
        templates: templateMap,
      })
    )
    return templateMap
  } catch (error) {
    dispatch(
      actions.inputProcess.getInputProcessTemplatesActions.failure(error)
    )
  }
  return {} as InputProcessTemplateMap
}

export const loadWorksheets = (
  fileId: number
): TsaThunkAction<Promise<Option[]>> => async (dispatch, getState) => {
  try {
    dispatch(actions.inputProcess.getWorksheetOptionsAction.request())
    const worksheets = await InputProcessApi.apiGetWorksheets(
      ensureNumber(fileId)
    )
    const worksheetOptions = []
    for (var key in worksheets) {
      worksheetOptions.push({
        value: worksheets[key],
        label: `${key} - ${worksheets[key]}`,
      })
    }
    dispatch(
      actions.inputProcess.getWorksheetOptionsAction.success({
        options: worksheetOptions,
        fileId,
      })
    )
    return worksheetOptions
  } catch (error) {
    dispatch(actions.inputProcess.getWorksheetOptionsAction.failure(error))
  }
  return []
}

export const loadTaxCodes = (
  fileId: number,
  k1Id?: number,
  filters?: ColumnFilters,
  excludeMappedData?: boolean
): TsaThunkAction<Promise<string[][]>> => async (dispatch, getState) => {
  try {
    dispatch(actions.inputProcess.setTaxCodeDataAction.request())
    const taxCodeData = await InputProcessApi.apiGetTaxCodes(
      fileId,
      k1Id,
      filters,
      excludeMappedData
    )
    dispatch(actions.inputProcess.setTaxCodeDataAction.success(taxCodeData))
    return taxCodeData
  } catch (error) {
    dispatch(actions.inputProcess.setTaxCodeDataAction.failure(error))
  }
  return []
}

export const loadEngagementEntities = (
  engagementId: number
): TsaThunkAction<Promise<ClientEntity[]>> => async (dispatch, getState) => {
  try {
    /* This method is being used by the generic ETL entities screen to also pull in entity options
    As well as properly setting the "loading" value on the store. */

    dispatch(actions.inputProcess.getEntityOptionsActions.request({}))

    const results = await Promise.all([
      EntitiesApi.apiGetEngagementEntities(engagementId),
      EngagementsApi.apiGetEngagementClient(engagementId),
    ])

    // Get partner / investment entities linked to engagement
    const entityOptions = results[0] || []
    // Get client entity linked to engagement
    const engagementClientEntity = results[1]

    // Ensure client has an entity and that it isn't already a partner / investment of the engagement
    if (
      engagementClientEntity &&
      !entityOptions.some(x => x.id === engagementClientEntity.id)
    ) {
      // Combine client with partner / investment list
      entityOptions.push(engagementClientEntity)
    }
    dispatch(
      actions.inputProcess.getEntityOptionsActions.success({ entityOptions })
    )

    return entityOptions
  } catch (error) {
    dispatch(actions.inputProcess.getEntityOptionsActions.failure(error))
  }
  return []
}

export const loadReceivingPartners = (
  fileId: number,
  k1Id?: number
): TsaThunkAction<Promise<Partner[]>> => async dispatch => {
  try {
    dispatch(actions.inputProcess.getReceivingPartnersAction.request())
    const partners = await InputProcessApi.apiGetDistinctReceivingPartners(
      fileId,
      k1Id
    )
    dispatch(actions.inputProcess.getReceivingPartnersAction.success(partners))
    return partners
  } catch (error) {
    dispatch(actions.inputProcess.getReceivingPartnersAction.failure(error))
  }
  return []
}

export const processK1Mappings = (
  fileId: number,
  k1Id?: number
): TsaThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  try {
    await InputProcessApi.apiProcessK1Mappings(fileId, k1Id)
    return true
  } catch (error) {
    dispatch(actions.inputProcess.processInputWorksheetFailAction(error))
    return false
  }
}

export const updateK1FileProgress = (
  k1FileProgress: EtlFileProgress
): TsaThunkAction<Promise<ApiModels.File | null>> => async (
  dispatch,
  getState
) => {
    try {
      dispatch(updateK1FileProgressAction.request())

      const result = await InputProcessApi.updateEtlFileProgress(k1FileProgress)

      dispatch(updateK1FileProgressAction.success(result))

      const k1FileStatus = await InputProcessApi.apiInProgressContinue(
        k1FileProgress.FileId
      )

      dispatch(updateK1FileStatusAction.success(k1FileStatus))

      return result
    } catch (error) {
      dispatch(updateK1FileProgressAction.failure(error))
    }
    return null
  }

export const updateK1Progress = (
  k1Progress: K1Progress
): TsaThunkAction<Promise<ApiModels.File | null>> => async (
  dispatch,
  getState
) => {
    try {
      dispatch(updateK1ProgressAction.request())
      const result = await InputProcessApi.updateK1Progress(k1Progress)

      dispatch(updateK1StatusAction.success(result))

      return null
    } catch (error) {
      dispatch(updateK1ProgressAction.failure(error))
    }
    return null
  }

export const downloadBlankTrapezeTemplate = (): TsaThunkAction => async dispatch => {
  try {
    const response = await InputProcessApi.apiDownloadBlankTrapezeTemplate()
    saveBlob(response)
  } catch (error) {
    return null
  }
}

export const updateOcrData = (
  ocrData: any,
  fileId: number
): TsaThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  try {
    dispatch(updateOcrDataAction.request())

    await AzureApi.updateOcrData(ocrData, fileId)

    dispatch(
      updateOcrDataAction.success({
        ocrData,
        fileId,
      })
    )

    return true
  } catch (error) {
    dispatch(updateOcrDataAction.failure(error))
  }
  return false
}

export const updateEtlFileProgress = (
  k1FileProgress: EtlFileProgress
): TsaThunkAction<Promise<ApiModels.File | null>> => async dispatch => {
  try {
    dispatch(updateK1FileProgressAction.request())

    const result = await InputProcessApi.updateEtlFileProgress(k1FileProgress)

    dispatch(updateK1FileProgressAction.success(result))

    const k1FileStatus = await InputProcessApi.apiInProgressContinue(
      k1FileProgress.FileId
    )

    dispatch(updateK1FileStatusAction.success(k1FileStatus))

    return result
  } catch (error) {
    dispatch(updateK1FileProgressAction.failure(error))
  }
  return null
}

export const validateK1File = (fileId: number): TsaThunkAction<Promise<string[]>> => async dispatch => {
  try {
    dispatch(validateK1FileAction.request(fileId))

    const result = await InputProcessApi.apiValidateK1File(fileId)

    dispatch(validateK1FileAction.success(result))

    return result
  } catch (error) {
    dispatch(validateK1FileAction.failure(error))
  }
  return []
}

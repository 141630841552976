import { FileGroup, FileGroupMap } from '../clientModels'
import { getEntityMapValues } from '../components/configureImport/helpers'

export const hasFileGroup = (documentTitleId: number, fileGroups: FileGroupMap | FileGroup[]) => {
  if (Array.isArray(fileGroups)) {
    return fileGroups.some(fg => fg.documentTitleId === documentTitleId)
  }
  const values = getEntityMapValues(fileGroups)
  return Array.isArray(values)
    && values.some(arr => Array.isArray(arr) && arr.some(fg => fg.documentTitleId === documentTitleId))
}

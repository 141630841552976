import * as React from 'react'
import Icon from './icon'
import { IconProperties } from './properties'

const NotificationPending: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <div className={className} onClick={onClick} data-tip={tooltip}>
      <Icon icon='notification' className={className} />
      <Icon icon='circleEllipsis' className={className + ' badge'} />
    </div>
  )
}

export default NotificationPending

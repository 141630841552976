import classNames from 'classnames'
import * as React from 'react'

function alignmentClass (align?: RowAlignment) {
  return align ? `align-items-${align}` : ''
}

function justificationClass (justify?: RowJustification) {
  return justify ? `justify-content-${justify}` : ''
}

type RowAlignment = 'start' | 'center' | 'end'

type RowJustification = 'start' | 'center' | 'end' | 'around' | 'between'

interface RowProps {
  className?: string
  justify?: RowJustification
  align?: RowAlignment
  noGutters?: boolean
}

const Row: React.SFC<RowProps> = props => {
  const { className, align, justify, noGutters, children } = props

  const classes = [
    'row',
    noGutters ? 'no-gutters' : '',
    alignmentClass(align),
    justificationClass(justify),
    className,
  ]
  return <div className={classNames(classes)}>{children}</div>
}

export default Row

// tslint:disable:max-classes-per-file
import { Option, StringLookup } from '../../clientModels'

// #region Dashboard Type Filters
class DashboardTypeFilters implements StringLookup {
  [key: string]: string
  AllTypes = 'All Types'
  NineNinetyEz = '990 Ez'
  NineNinety = '990'
  Partnership = '1065'
  K1Uploads = 'K-1 Uploads'
}

const DefaultDashboardTypeFilter: keyof DashboardTypeFilters = 'AllTypes'
const DashboardTypeFilterOptions: Option[] = []
const dashboardTypeFilters = new DashboardTypeFilters()
Object.keys(dashboardTypeFilters).forEach(property =>
  DashboardTypeFilterOptions.push({
    label: dashboardTypeFilters[property],
    value: property
  })
)

export {
  DashboardTypeFilters,
  DefaultDashboardTypeFilter,
  DashboardTypeFilterOptions
}
// #endregion

// #region Dashboard Status Filters
class DashboardStatusFilters implements StringLookup {
  [key: string]: string
  AllStatuses = 'All Statuses'
  NotStarted = 'Not Started'
  InProgress = 'In-Progress'
  ReviewReady = 'Ready for Review'
  ReviewDone = 'Review Complete'
  Completed = 'Completed'
}

const DefaultDashboardStatusFilter: keyof DashboardStatusFilters = 'AllStatuses'
const DashboardStatusFilterOptions: Option[] = []
const dashboardStatusFilters = new DashboardStatusFilters()
Object.keys(dashboardStatusFilters).forEach(property =>
  DashboardStatusFilterOptions.push({
    label: dashboardStatusFilters[property],
    value: property
  })
)

export {
  DashboardStatusFilters,
  DefaultDashboardStatusFilter,
  DashboardStatusFilterOptions
}
// #endregion

// #region Dashboard Date Filters
class DashboardDateFilters implements StringLookup {
  [key: string]: string
  AllDates = 'All Dates'
  DueThisMonth = 'Due This Month'
  DueNextMonth = 'Due Next Month'
  EnterDueDate = 'Enter a Due Date'
  EnterTaxYear = 'Enter a Tax Year End'
}

const DefaultDashboardDateFilter: keyof DashboardDateFilters = 'AllYears'
const DashboardDateFilterOptions: Option[] = []
const dashboardDateFilters = new DashboardDateFilters()
Object.keys(dashboardDateFilters).forEach(property =>
  DashboardDateFilterOptions.push({
    label: dashboardDateFilters[property],
    value: property
  })
)

export {
  DashboardDateFilters,
  DefaultDashboardDateFilter,
  DashboardDateFilterOptions
}
// #endregion

// #region Dashboard Year Filters
class DashboardYearFilters implements StringLookup {
  [key: string]: string
  AllYears = 'All Years'
  TwentyEighteen = '2018'
  TwentyNineteen = '2019'
}

const DefaultDashboardYearFilter: keyof DashboardYearFilters = 'AllDates'
const DashboardYearFilterOptions: Option[] = []
const dashboardYearFilters = new DashboardYearFilters()
Object.keys(dashboardYearFilters).forEach(property =>
  DashboardYearFilterOptions.push({
    label: dashboardYearFilters[property],
    value: property
  })
)

export {
  DashboardYearFilters,
  DashboardYearFilterOptions,
  DefaultDashboardYearFilter
}
// #endregion

import { ActionType, createAction } from 'typesafe-actions'

export const signalRConnectingAction = createAction('SIGNALR_CONNECTING')()
export const signalRConnectedAction = createAction('SIGNALR_CONNECTED')()
export const signalRDisconnectedAction = createAction('SIGNALR_DISCONNECTED')()

export const signalRMessageReceived = createAction(
  'SIGNALR_SERVER_MESSAGE_RECEIVED'
)<string>()

export const signalRMessageSend = createAction('SIGNALR_SERVER_MESSAGE_SEND')<
  string
>()

export type SignalRActions =
  | ActionType<typeof signalRConnectingAction>
  | ActionType<typeof signalRConnectedAction>
  | ActionType<typeof signalRDisconnectedAction>
  | ActionType<typeof signalRMessageReceived>
  | ActionType<typeof signalRMessageSend>

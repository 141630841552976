import * as React from 'react'
import { useParams, useHistory } from 'react-router'
import { ConnectedEngagementSummary } from '../engagementSummary'
import { useDispatch, useSelector } from 'react-redux'
import { ensureNumber } from '../../guards'
import { getSimpleEngagement } from '../../actions/engagementThunks'
import { AppState } from '../../store'
import Loading from '../loading'

interface RouteParams {
  engagementId: string
}

export function useEngagementParams() {
  const { engagementId } = useParams<RouteParams>()

  return {
    engagementId: ensureNumber(engagementId),
  }
}

const EngagementDashboard = () => {
  const { engagementId } = useEngagementParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const busy = useSelector((appState: AppState) =>
    appState.http.loading.includes('engagement-dashboard')
  )

  React.useEffect(() => {
    dispatch(getSimpleEngagement(engagementId, 'engagement-dashboard'))
  }, [dispatch])

  return (
    <div className='engagement-dashboard'>
      {busy && <Loading />}
      {!busy && (
        <ConnectedEngagementSummary
          engagementId={engagementId}
          history={history}
        />
      )}
    </div>
  )
}

export default EngagementDashboard

import * as React from 'react'
import { IconProperties } from './properties'

const Comments: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <svg
      viewBox='0 0 37.26 33.08'
      height='1em'
      width='1em'
      className={className}
      onClick={onClick}
      focusable='false'
      data-tip={tooltip}
    >
      <path d='M29.61.54H4.3A3.77,3.77,0,0,0,.54,4.3V18.36A3.77,3.77,0,0,0,4.3,22.12H7.45v4.95l4.94-4.95H29.61a3.77,3.77,0,0,0,3.77-3.76V4.3A3.77,3.77,0,0,0,29.61.54Z' />
      <path d='M35.31,16.56a2.3,2.3,0,0,1,1.41,2.12v8.55a2.3,2.3,0,0,1-2.29,2.3H32.52v3l-3-3H19a2.31,2.31,0,0,1-2.3-2.3V24.05' />
      <line x1='6.55' y1='8.53' x2='27.37' y2='8.53' />
      <line x1='6.55' y1='14.13' x2='21.74' y2='14.13' />
    </svg>
  )
}

export default Comments

import { PhaseMap } from '../clientModels'
import { ActionType, createAction } from 'typesafe-actions'

export const phaseBeginAction = createAction('GET_PHASE_BEGIN')<{}>()

export const phaseDoneAction = createAction('GET_PHASE_DONE')<{
  phases: PhaseMap
}>()

export const phaseFailAction = createAction('GET_PHASE_FAIL')<{
  error: Error
}>()

export const phaseListBeginAction = createAction('GET_PHASE_LIST_BEGIN')<{}>()

export const phaseListDoneAction = createAction('GET_PHASE_LIST_DONE')<{
  payload: { phases: PhaseMap }
}>()

export const phaseListFailAction = createAction('GET_PHASE_LIST_FAIL')<{
  error: Error
}>()

export const phaseCacheHitAction = createAction('GET_PHASE_CACHE_HIT')<{}>()

export type PhaseActions =
  | ActionType<typeof phaseBeginAction>
  | ActionType<typeof phaseCacheHitAction>
  | ActionType<typeof phaseDoneAction>
  | ActionType<typeof phaseFailAction>
  | ActionType<typeof phaseListBeginAction>
  | ActionType<typeof phaseListDoneAction>
  | ActionType<typeof phaseListFailAction>

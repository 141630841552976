import * as React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import SmoothCollapse from 'react-smooth-collapse'
import { actions } from '../../actions/index'
import { EngagementMap, IdentityTokenProfile } from '../../clientModels'
import { isDefined } from '../../guards'
import { AppState, TsaDispatch } from '../../store'
import { OpenerInjectedProps, withOpener } from '../opener/opener'

let nextId = 0

interface SelectedLinkProps {
  engagements: EngagementMap
  engagementId: string
}

function SelectedLink (props: SelectedLinkProps) {
  const { engagements, engagementId } = props

  const selected = Object.values(engagements)
    .filter(isDefined)
    .find(engagement => engagement.id.toString() === engagementId)

  return selected ? (
    <div className='dropdown-toggle' title={selected.name}>
      <div className='dropdown-select-text'>{selected.name}</div>
    </div>
  ) : (
    <div />
  )
}

// Props added to the component in JSX or React.createElement
interface EngagementDropdownMenuOwnProps {
  engagementId: string
}

interface EngagementDropdownMenuConnectedProps {
  user?: IdentityTokenProfile
  engagements: EngagementMap
}

const mapStateToProps = (
  state: AppState,
  ownProps: EngagementDropdownMenuOwnProps
): EngagementDropdownMenuConnectedProps => {
  const user = state.auth.user
  const engagements = state.engagements

  return {
    user,
    engagements,
  }
}

interface EngagementsMenuDispatchProps {
  getEngagementList: () => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch
): EngagementsMenuDispatchProps => ({
  getEngagementList: () => dispatch(actions.engagement.getEngagementList()),
})

type EngagementDropdownMenuProps = EngagementDropdownMenuOwnProps &
  EngagementDropdownMenuConnectedProps &
  EngagementsMenuDispatchProps &
  OpenerInjectedProps

export class EngagementDropdownMenu extends React.Component<
  EngagementDropdownMenuProps
  > {
  id: string

  constructor (props: EngagementDropdownMenuProps) {
    super(props)
    nextId += 1
    this.id = `engagementMenuNavbarDropdown${nextId}`
  }

  componentDidMount () {
    this.props.getEngagementList()
  }

  render () {
    const {
      opener: { isOpen, toggle },
      engagements,
      engagementId,
    } = this.props

    if (!engagementId) {
      return null
    }

    return (
      <div className='engagement-dropdown-container d-none d-lg-flex'>
        <button
          className='clickable'
          id={this.id}
          onClick={toggle}
          aria-haspopup='true'
          aria-expanded={isOpen}
        >
          <SelectedLink engagements={engagements} engagementId={engagementId} />
        </button>
        <div
          className='engagement-dropdown-menu-list'
          aria-labelledby={this.id}
        >
          <SmoothCollapse expanded={isOpen}>
            <div style={{ overflowY: 'auto', maxHeight: '90vh' }}>
              {Object.values(engagements)
                .filter(isDefined)
                .map(engagement => {
                  return (
                    <NavLink
                      to={`/engagements/${engagement.id}`}
                      id={`engagement-dropdown-menu-${engagement.id}`}
                      key={engagement.id}
                      className='nav-link'
                      title={engagement.name}
                    >
                      {engagement.name}
                    </NavLink>
                  )
                })}
            </div>
          </SmoothCollapse>
        </div>
      </div>
    )
  }
}

export default withOpener({
  closeOnClickInside: true,
  closeOnClickOutside: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EngagementDropdownMenu)
)

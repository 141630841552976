import React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { Theme } from '@rsmus/react-ui'
import { RoundDropdown } from '@rsmus/react-ui'
import clsx from 'clsx'
import MyApplications from './MyApplications'
import MyAccount from './MyAccount'
import LogOut from './LogOut'
import { SupportedApplication } from './Models'
import { getInitials } from './getInitials'

function styles({ palette, transitions, typogrphaphy }: Theme) {
  return {
    root: {
      height: '100%',
      fontFamily: 'Prelo-Book',
    },
    menu: {
      minWidth: '20rem',
      color: palette.common.white,
    },
    heading: {
      fontSize: '1.75rem',
      lineHeight: '2rem',
      margin: 0,
      padding: '2rem 1.5rem 1.125rem',
      fontFamily: typogrphaphy.normal.fontFamily,
      textTransform: 'uppercase',
    },
    item: {
      display: 'block',
      color: palette.common.white,
      padding: '1rem 1.5rem',
      fontSize: '0.875rem',
      transition: transitions.create('background-color'),
      '&:hover': {
        color: palette.common.white,
        textDecoration: 'none',
      },
    },
    dropdown: {
      height: '4rem',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface ProfileMenuLink {
  text: string
  icon: any
  url: string
}
export interface User {
  profile: {
    given_name?: string
    family_name?: string
  }
}
export interface SupportLink {
  text: string
  url: string
}

export interface AccountMenuLayoutProperties {
  claims?: string[]
  className?: string
  links?: ProfileMenuLink[]
  variant: 'normal' | 'strong'
  user?: User
  support?: SupportLink[]
  accountLinks?: SupportLink[]
  onLogout: () => void
  accessingFrom?: SupportedApplication
}

const AccountMenuLayout = ({
  claims,
  className: componentClassName,
  links,
  variant,
  user,
  support,
  accountLinks,
  accessingFrom,
  onLogout,
}: AccountMenuLayoutProperties) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  if (!user) {
    return null
  }

  const {
    profile: { given_name: givenName, family_name: familyName },
  } = user
  const initials = getInitials(givenName, familyName)

  const customClassName =
    typeof componentClassName === 'string'
      ? componentClassName
      : clsx(componentClassName, classes.root)

  return (
    <RoundDropdown
      buttonText={initials}
      position='right'
      variant={variant}
      className={classes.dropdown}
    >
      <div className={`${classes.menu} ${customClassName}`}>
        <h3 className={classes.heading}>{`${givenName}'s account`}</h3>

        {claims && (
          <MyApplications
            claims={claims}
            links={links}
            accessingFrom={accessingFrom}
          />
        )}

        <MyAccount support={support} accountLinks={accountLinks} />
        <LogOut onLogout={onLogout} />
      </div>
    </RoundDropdown>
  )
}

export default AccountMenuLayout

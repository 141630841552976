import React from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'
import classNames from 'classnames'

interface FileUploadDialogDropZoneProps {
  disabled: boolean
  acceptedFileTypes?: string
  onDrop: (files: FileWithPath[]) => void
}

export function FileUploadDialogDropZone ({
  acceptedFileTypes,
  disabled,
  onDrop,
}: FileUploadDialogDropZoneProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    disabled,
  })
  return (
    <div className='file-upload-dropzone'>
      <div
        className={classNames('dropzone', {
          'dropzone-active': isDragActive,
          'dropzone-disabled': disabled,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <span className='dropzone-header'>Drag and drop file here</span>
        <span className='dropzone-header-info'>
          (or click here to select file)
        </span>
        <span className='dropzone-header-instructional'>
          File must be saved to your computer to be dragged here.
        </span>
      </div>
    </div>
  )
}

import classNames from 'classnames'
import * as React from 'react'
import {
  Engagement,
  EngagementAssignment,
  IdentityTokenProfile,
  StringLookup,
} from '../../clientModels'
import { PhaseCode, RoleCode } from '../../enums'
import {
  reviewIsComplete,
  reviewRoleIsComplete,
} from '../../services/questionReviewUtilities'
import Icon from '../icon/icon'
import './engagementReviewSummary.scss'

const roleNames: StringLookup = {
  [RoleCode.Preparer]: 'Preparer',
  [RoleCode.PrimaryReviewer]: 'Primary',
  [RoleCode.SecondaryReviewer]: 'Secondary',
  [RoleCode.ConcurringReviewer]: 'Concurring',
}

interface ReviewerNameProps {
  assignment: EngagementAssignment
}

const ReviewerName: React.SFC<ReviewerNameProps> = ({
  assignment: { user },
}) => (
  <span className='reviewer-name'>
    {user.firstName || user.lastName
      ? `${user.firstName} ${user.lastName}`.trim()
      : user.userId // If user has never logged in the UserProfiles table will not have a name. Fall back to user ID.
    }
  </span>
)

interface EngagementReviewSummaryProps {
  engagement: Engagement
  user?: IdentityTokenProfile
}

interface EngagementReviewSummaryItemProps
  extends EngagementReviewSummaryProps {
  roleCode: RoleCode
}

const EngagementReviewSummaryItem: React.SFC<
EngagementReviewSummaryItemProps
> = ({ engagement, user, roleCode }) => {
  if (!engagement.assignedRoleCodesByUserId || !engagement.assignments) {
    return null
  }

  const userId = (user && user.uniqueId) || ''
  const userReviewRoles = engagement.assignedRoleCodesByUserId.get(userId)
  const currentUserIsInRole = !!userReviewRoles && userReviewRoles.has(roleCode)
  const userReviewIsComplete = reviewRoleIsComplete(engagement, roleCode)
  const allReviewIsComplete = reviewIsComplete(engagement)
  const roleName = roleNames[roleCode]

  // Find reviewers for this role if the current user is not in this role
  let otherReviewers: EngagementAssignment[] = []
  if (!currentUserIsInRole) {
    otherReviewers = engagement.assignments.filter(a => a.roleCode === roleCode)
  }

  const className = classNames('engagement-review-summary-item', {
    complete: userReviewIsComplete,
    'current-user': currentUserIsInRole,
  })

  const title = `${roleName} Review`
  const message =
    currentUserIsInRole && userReviewIsComplete && !allReviewIsComplete
      ? 'Your review is done; however, additional reviews need to be completed before this engagement can be submitted.'
      : ''

  return (
    <div className={className}>
      <Icon icon='circleBigCheck' />
      <span className='title'>{title}</span>
      {otherReviewers.length > 0 && <span>BY</span>}
      {otherReviewers.map(a => (
        <ReviewerName key={a.id} assignment={a} />
      ))}
      {message && <span className='message'>{message}</span>}
    </div>
  )
}

export const EngagementReviewSummary: React.SFC<
EngagementReviewSummaryProps
> = props => {
  const { engagement } = props
  if (!engagement.assignedRoleCodes || engagement.phase !== PhaseCode.Review) {
    return null
  }

  const roles = [
    RoleCode.Preparer,
    RoleCode.PrimaryReviewer,
    RoleCode.SecondaryReviewer,
    RoleCode.ConcurringReviewer,
  ].filter(
    r => !!engagement.assignedRoleCodes && engagement.assignedRoleCodes.has(r)
  )

  return (
    <div className='engagement-review-summary'>
      {roles.map(roleCode => (
        <EngagementReviewSummaryItem
          {...props}
          key={roleCode}
          roleCode={roleCode}
        />
      ))}
    </div>
  )
}

import { IdentityTokenProfile } from '../clientModels'
import { ActionType, createAction } from 'typesafe-actions'

export const authGetUserAction = createAction('auth/GET_USER')<{
  user?: IdentityTokenProfile
  accessToken?: string
}>()

export const authGetUserFailAction = createAction('auth/GET_USER_FAIL')<{
  errorMessage: string
}>()

export const authGetUserPermissionAction = createAction(
  'AUTH_GET_USER_PERMISSIONS'
)<{
  user?: IdentityTokenProfile
}>()

export const authGetUserPermissionFailAction = createAction(
  'AUTH_GET_USER_PERMISSIONS_FAIL'
)<{ errorMessage: string }>()

export type AuthActions =
  | ActionType<typeof authGetUserAction>
  | ActionType<typeof authGetUserFailAction>
  | ActionType<typeof authGetUserPermissionAction>
  | ActionType<typeof authGetUserPermissionFailAction>

import * as React from 'react'
import {
  NotificationTemplate,
  NotificationTemplateOptions,
  NotificationTemplateReplacement
} from '../../services/api/apiModels'
import { TextBox, FormFieldValue } from '../forms'
import Icon from '../icon/icon'
import Loading from '../loading/index'
import Modal from './modal'

interface CustomEmailDialogProps {
  template: NotificationTemplate
  options: NotificationTemplateOptions
  onSendEmail: (
    options: NotificationTemplateOptions,
    to: string[] | null,
    cc: string[] | null,
    bcc: string[] | null,
    subject: string | null,
    replacementValues: NotificationTemplateReplacement | null
  ) => void
  onCancel: () => void
}

interface CustomEmailDialogState {
  replacementValues: NotificationTemplateReplacement | null
  to: string | null
  cc: string | null
  bcc: string | null
  subject: string | null
  sendingEmail: boolean
}

interface TokenChangeHandlers {
  [token: string]: (value: FormFieldValue) => Promise<void>
}

interface SpecialCharacters {
  [key: string]: RegExp[]
}

const tokenReplacement = /\{\{(.*)\}\}/g
const separators = /[;,]/g
// found at https://stackoverflow.com/questions/31515999/how-to-remove-all-html-tags-from-a-string
const sanitize = /<[^>]*>/g

class CustomEmailDialog extends React.Component<
  CustomEmailDialogProps,
  CustomEmailDialogState
> {
  modalPrimaryButton?: HTMLButtonElement
  state: CustomEmailDialogState = {
    replacementValues: null,
    to: null,
    cc: null,
    bcc: null,
    subject: null,
    sendingEmail: false
  }

  handlers: TokenChangeHandlers = {}

  constructor(props: CustomEmailDialogProps) {
    super(props)

    this.state.replacementValues = props.template.replaceableValues
      ? { ...props.template.replaceableValues }
      : null

    if (props.template.replaceableValues) {
      for (const token in props.template.replaceableValues) {
        this.handlers[token] = async (value: FormFieldValue) => {
          let newState = this.state
          if (newState.replacementValues) {
            newState.replacementValues[token] = (value + '').replace(
              sanitize,
              ''
            )
            newState = { ...newState }
            this.setState(newState)
          }
        }
      }
    }
  }

  componentDidMount() {
    if (this.modalPrimaryButton) {
      this.modalPrimaryButton.focus()
    }
  }

  setModalPrimaryButtonRef = (ref: HTMLButtonElement) =>
    (this.modalPrimaryButton = ref)

  handleSendEmailClick = () => {
    const {
      props: { onSendEmail, options },
      state: {
        replacementValues,
        to: stateTo,
        cc: stateCC,
        bcc: stateBCC,
        subject
      }
    } = this

    this.setState({ sendingEmail: true }, () => {
      const to =
        stateTo != null
          ? stateTo
              .split(separators)
              .map(addr => addr.trim())
              .filter(addr => addr)
          : null
      const cc =
        stateCC != null
          ? stateCC
              .split(separators)
              .map(addr => addr.trim())
              .filter(addr => addr)
          : null
      const bcc =
        stateBCC != null
          ? stateBCC
              .split(separators)
              .map(addr => addr.trim())
              .filter(addr => addr)
          : null

      onSendEmail(options, to, cc, bcc, subject, replacementValues)
    })
  }

  handleStringReplace = (match: string, token: string) => {
    const {
      state: { replacementValues }
    } = this
    return replacementValues ? replacementValues[token] || '' : ''
  }

  handleToChange = async (value: FormFieldValue) => {
    this.setState({ to: value + '' })
  }

  handleCCChange = async (value: FormFieldValue) => {
    this.setState({ cc: value + '' })
  }

  handleBCCChange = async (value: FormFieldValue) => {
    this.setState({ bcc: value + '' })
  }

  handleSubjectChange = async (value: FormFieldValue) => {
    this.setState({ subject: (value + '').replace(sanitize, '') })
  }

  specialCharacters: SpecialCharacters = {
    '&': [/&amp/g],
    "'": [/&apos/g, /&#x27/g, /&#39/g],
    '/': [/&#x2F/g, /&#47/g],
    '<': [/&lt/g],
    '>': [/&gt/g],
    ' ': [/&nbsp/g],
    '"': [/&quot/g],
    '’': [/&rsquo/g, /&#x2019/g, /&#8217;/g, /&#8217/g]
  }

  decodeSpecialCharactersAndSanitize = (text: string | undefined) => {
    if (text) {
      text = text?.replace(sanitize, '')
      for (const key in this.specialCharacters) {
        for (const regex of this.specialCharacters[key]) {
          text = text?.replace(regex, key)
        }
      }
      text = text?.replace(sanitize, '')
      return text
    }
    return null
  }

  render() {
    const {
      props: {
        template: { to, bcc, cc, subject, bodyHTML, bodyText },
        onCancel
      },
      state: {
        replacementValues,
        to: stateTo,
        cc: stateCC,
        bcc: stateBCC,
        subject: stateSubject,
        sendingEmail
      },
      handleSendEmailClick,
      handleStringReplace,
      handleToChange,
      handleCCChange,
      handleBCCChange,
      handleSubjectChange,
      handlers,
      decodeSpecialCharactersAndSanitize
    } = this
    return (
      <Modal>
        <div className='modal critical'>
          <div className='modal-body custom-email'>
            <div className='email-settings'>
              {!sendingEmail ? (
                <div className='email-defined-settings'>
                  <div className='email-header'>Send an Email Notification</div>
                  <button className='cancel-email' onClick={onCancel}>
                    Cancel
                  </button>
                  <button
                    className='btn btn-primary btn-save send-email'
                    onClick={handleSendEmailClick}
                  >
                    <Icon icon='paperPlane' /> Send
                  </button>
                </div>
              ) : (
                <Loading
                  alwaysLoading={true}
                  loadingMessage={'Sending Email...'}
                  showLoadingMessage={true}
                />
              )}
              <div className='email-defined-settings-region'>
                <div className='email-labeled'>
                  <div className='email-label'>To:</div>
                  <div className='email-value'>
                    <TextBox
                      value={stateTo !== null ? stateTo : to && to.join(';')}
                      onChange={handleToChange}
                    />
                  </div>
                </div>
                <div className='email-labeled'>
                  <div className='email-label'>CC:</div>
                  <div className='email-value'>
                    <TextBox
                      value={stateCC !== null ? stateCC : cc && cc.join(';')}
                      onChange={handleCCChange}
                    />
                  </div>
                </div>
                <div className='email-labeled'>
                  <div className='email-label'>BCC:</div>
                  <div className='email-value'>
                    <TextBox
                      value={
                        stateBCC !== null ? stateBCC : bcc && bcc.join(';')
                      }
                      onChange={handleBCCChange}
                    />
                  </div>
                </div>
                <div className='email-labeled'>
                  <div className='email-label'>Subject:</div>
                  <div className='email-value'>
                    <TextBox
                      value={
                        stateSubject !== null
                          ? decodeSpecialCharactersAndSanitize(stateSubject)
                          : decodeSpecialCharactersAndSanitize(subject)
                      }
                      onChange={handleSubjectChange}
                    />
                  </div>
                </div>
              </div>
              <div className='email-body-header'>
                Edit the text in the body of your email below.
              </div>
              {replacementValues &&
                Object.keys(replacementValues).map(token => (
                  <div id={token} key={token} className='email-value'>
                    <TextBox
                      value={decodeSpecialCharactersAndSanitize(
                        replacementValues[token]
                      )}
                      onChange={handlers[token]}
                    />
                  </div>
                ))}
            </div>
            {bodyHTML && (
              <div
                className='preview'
                dangerouslySetInnerHTML={{
                  __html: bodyHTML.replace(
                    tokenReplacement,
                    handleStringReplace
                  )
                }}
              />
            )}
            {!bodyHTML && bodyText && (
              <div className='preview text'>
                {bodyText.replace(tokenReplacement, handleStringReplace)}
              </div>
            )}
            {!sendingEmail && (
              <div className='email-defined-footer'>
                <button className='cancel-email' onClick={onCancel}>
                  Cancel
                </button>
                <button
                  className='btn btn-primary btn-save send-email'
                  onClick={handleSendEmailClick}
                >
                  <Icon icon='paperPlane' /> Send
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }
}

export default CustomEmailDialog

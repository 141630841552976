import * as http from '../http'
import { getNewAbort } from '../lastRequests'
import { ClientSearch } from '../api/apiModels'
import { buildApiUrl } from './apiUrl'

const clientSearchAbort = getNewAbort()

export function clientSearch(searchText: string): Promise<ClientSearch[]> {
  clientSearchAbort.abort()
  return http.get(
    buildApiUrl('Clients', { search: searchText, max: 100 }),
    clientSearchAbort.new()
  )
}
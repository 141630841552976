import { getType } from 'typesafe-actions'
import {
  authGetUserAction,
  authGetUserFailAction,
  authGetUserPermissionAction,
} from '../actions/authenticationActions'
import { TsaHubAction } from '../actions/index'
import { IdentityTokenProfile } from '../clientModels'
import { REACT_APP_NOAUTH } from '../envVariables'

export interface AuthState {
  user?: IdentityTokenProfile
  errorMessage?: string
  accessToken?: string
}

let initialState: AuthState = {}

if (process.env.NODE_ENV !== 'production') {
  if (REACT_APP_NOAUTH) {
    initialState = {
      user: {
        name: 'Mock User',
        firstName: 'Mock',
        lastName: 'User',
        organization: {
          id: 'RSM',
          name: 'RSM',
        },
        isExternal: false,
        uniqueId: 'EEEEEEEE',
        email: 'mock_user_foo_bar@wlefjsdfsljdf.com',
        exp: 0,
        clientId: [],
        applicationId: [],
      },
    }
  }
}

export const authReducer = (
  state: AuthState = initialState,
  action: TsaHubAction
): AuthState => {
  switch (action.type) {
    default:
      break
    case getType(authGetUserAction):
      state = {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        errorMessage: undefined,
      }
      break
    case getType(authGetUserFailAction):
      state = {
        ...state,
        user: undefined,
        accessToken: undefined,
        errorMessage: action.payload.errorMessage,
      }
      break
    case getType(authGetUserPermissionAction):
      if (state.user) {
        state = {
          ...state,
          user: action.payload.user,
        }
      }
      break
  }

  return state
}

import { Theme } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'

function styles(theme: Theme) {
  return {
    root: {
      marginTop: '2rem',
      width: '100%',
    },
    header: {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.grey[100],
      fontWeight: 400,
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    inProgress: {
      backgroundColor: `${theme.palette.blue.dark} !important`,
      color: theme.palette.blue.contrastText,
    },
    cell: {
      lineHeight: '2.25rem',
      padding: '0.25rem 0.5rem',
      whiteSpace: 'nowrap',
      '& p': {
        marginBottom: 0,
        lineHeight: 1.5,
      },
      '&:last-child': {
        width: '100%',
        whiteSpace: 'unset',
      },
    },
    icon: {
      color: theme.palette.blue.main,
      minWidth: 'unset',
      fontSize: '1.75rem',
      '& svg': {
        cursor: 'pointer',
      },
    },
  }
}

type Styles = keyof ReturnType<typeof styles>
export const useTableStyles = createUseStyles<Theme, Styles>(styles)

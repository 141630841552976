import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  formatErrorMessage,
  getCchCodesFromMessage
} from './cchErrorLinksHelpers'
import { CchFieldTranslation } from '../../../clientModels'
import { getQuestionsForCchCodes } from '../../../services/api/engagementsApi'
import SmoothCollapse from 'react-smooth-collapse'
import Icon from '../../icon/icon'

export interface CchErrorLinksProps {
  message: string
  headerMessage?: string
  subHeaderMessage?: string
  engagementId: number
  messageClassName?: string
}

const CchErrorLinks = ({
  message,
  headerMessage,
  subHeaderMessage,
  engagementId,
  messageClassName
}: CchErrorLinksProps) => {
  const [translations, setTranslations] = useState<CchFieldTranslation[]>([])
  const [isLinkContentVisible, setIsLinkContentVisible] = useState(false)

  // Function to get the new translations from the server asynchronously.  This
  //  is defined this way because async methods cannot be defined inside useEffects (compiler error)
  const updateTranslations = useCallback(
    (codes: string[]) => {
      getQuestionsForCchCodes(engagementId, codes).then(newTranslations => {
        // Update the translations locally.
        setTranslations(newTranslations)
      })
    },
    [engagementId]
  )

  useEffect(() => {
    // Clear the links while we update the message.
    setTranslations([])

    // Get the CCH field codes from the message.
    const codes = message ? getCchCodesFromMessage(message) : []

    // If we found some error codes, then get the related questions from the server.
    if (codes.length) {
      updateTranslations(codes)
    }
  }, [message, engagementId, updateTranslations])

  const content = useMemo(
    () => formatErrorMessage(message, engagementId, translations),
    [translations, message, engagementId]
  )

  // Show/hide the error link content depending on the SmoothCollapse state
  const handleVisibilityToggle = () => {
    setIsLinkContentVisible(!isLinkContentVisible)
  }

  const arrowDirection = isLinkContentVisible ? 'caretDown' : 'caretRight'

  return (
    <div>
      <div onClick={handleVisibilityToggle}>
        <div className='submission-failed-heading error-links'>
          <Icon icon={arrowDirection} />
          {headerMessage}
        </div>
        {subHeaderMessage && (
          <>
            <br />
            {subHeaderMessage}
          </>
        )}
      </div>
      <SmoothCollapse expanded={isLinkContentVisible}>
        <div className={messageClassName}>{content}</div>
      </SmoothCollapse>
    </div>
  )
}

export default CchErrorLinks

import classNames from 'classnames'
import * as React from 'react'
import Select, {
  components,
  createFilter,
  MenuProps,
  StylesConfig,
  ActionMeta,
  GroupType,
  MenuPlacement,
} from 'react-select'
import Creatable from 'react-select/creatable'
import { AnswerValue, Option } from '../../clientModels'
import { isObjectNullOrUndefined, isString } from '../../guards'
import {
  getSchemaForPath,
  optionsListLookup,
} from '../../services/answerSchema/index'
import Icon from '../icon/icon'
import { Icons } from '../icon/icons'
import { Alerts } from './alerts/index'
import './combobox.scss'
import {
  FocusableComponent,
  OptionsFormFieldProps,
} from './formComponentsInterfaces'
import { hasError, objectToOptions } from './formUtilities'

const styles: StylesConfig = {
  container: base => {
    return {
      ...base,
      marginTop: '1rem',
    }
  },
  control: base => {
    delete base.borderColor
    return {
      ...base,
      boxShadow: 'none',
    }
  },
  menu: (base, state) => {
    if (state.menuPlacement === 'top') {
      return {
        ...base,
        marginBottom: 1,
      }
    }
    return {
      ...base,
      marginTop: 1,
    }
  },
  menuList: base => {
    return {
      ...base,
      overflowX: 'hidden',
      whiteSpace: 'pre-wrap',
    }
  },
}

// tslint:disable-next-line:no-any
const customTheme = (theme: any) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary: '#009CDE',
      primary25: '#00B2FF',
    },
    spacing: {
      ...theme.spacing,
      controlHeight: 'calc(2.25rem + 2px)',
    },
  }
}

export interface ComboBoxProps extends OptionsFormFieldProps {
  allowUserInput?: boolean
  multiSelect?: boolean
  className?: string
  customStyles?: {}
  icon?: keyof Icons
  isClearable?: boolean
  isSearchable?: boolean
  menuPlacement?: MenuPlacement
  onKeyPress?: (e: React.KeyboardEvent<HTMLElement>) => void
}

export default class ComboBox extends React.Component<ComboBoxProps>
  implements FocusableComponent {
  static defaultProps = {
    isClearable: true,
    isSearchable: true,
    showAlerts: true,
  }

  static valueTypeError =
    'ComboBox expects an object that represents the selected option.'

  static formatValueLastYear = (
    value: AnswerValue,
    props: ComboBoxProps
  ): string => {
    const { optionLists, codeListIdToOptionsId, jsonSchema, prefix } = props
    let path = props.path

    if (prefix && isString(path)) {
      path = prefix + path
    }

    const fieldSchema = getSchemaForPath(jsonSchema, path)
    const fieldOptions =
      optionsListLookup(fieldSchema, codeListIdToOptionsId, optionLists) || []

    if (!isObjectNullOrUndefined(value)) {
      throw new Error(ComboBox.valueTypeError)
    }

    // Assume that there is only one selected option. Multi-select has not been implemented.
    const selectedOption = objectToOptions(value)[0]
    const lastYearsOption = fieldOptions.find(
      o => o.value === selectedOption.value
    )
    return lastYearsOption !== undefined
      ? lastYearsOption.label
      : selectedOption.value
  }

  private select = React.createRef<Select<Option>>()
  private comboboxRef = React.createRef<HTMLDivElement>()
  private filterOption = createFilter({})

  componentDidMount() {
    const { selectedPath, path } = this.props
    if (selectedPath && path && path === selectedPath) {
      this.focus()
    }
  }

  focus() {
    const { current } = this.select
    if (current) {
      current.focus()
    }
  }

  render() {
    const {
      allowUserInput,
      autoFocus,
      className,
      customStyles,
      hint,
      isClearable,
      isSearchable,
      label,
      menuPlacement,
      messages,
      multiSelect,
      onKeyPress,
      options,
      path,
      placeholder,
      showAlerts,
      value,
    } = this.props

    if (!isObjectNullOrUndefined(value)) {
      throw new Error(ComboBox.valueTypeError)
    }

    const isInError = hasError(messages, path)
    const disabled = this.isDisabled

    // tslint:disable-next-line:no-any - https://github.com/Microsoft/TypeScript/issues/28631
    const SelectComponent: any = allowUserInput ? Creatable : Select

    const groupedOptions: Array<GroupType<Option> | Option> = []

    if (options) {
      let currOptions = groupedOptions

      for (const option of options) {
        if (option.group) {
          const tempOption = {
            label: option.label,
            type: 'group',
            options: [],
          }

          currOptions = groupedOptions
          currOptions.push(tempOption)
          currOptions = tempOption.options
        } else {
          currOptions.push(option)
        }
      }
    }

    return (
      <div
        className={classNames(
          className && className.replace('grid-select', ''),
          'form-group nowrap',
          {
            disabled: disabled,
            'is-valid': !isInError,
            'is-invalid': isInError,
          }
        )}
        ref={this.comboboxRef}
      >
        {label && <label>{label}</label>}
        {hint && <div className='hint-text'>{hint}</div>}
        <SelectComponent
          components={{
            Menu: this.renderMenu,
            Option: this.renderOption,
          }}
          autoFocus={autoFocus}
          className='react-select'
          classNamePrefix='react-select'
          filterOption={this.filterOption}
          formatOptionLabel={this.formatOptionsLabel}
          isClearable={isClearable}
          isDisabled={disabled}
          isMulti={multiSelect}
          isSearchable={isSearchable}
          menuPlacement={menuPlacement}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={onKeyPress}
          options={groupedOptions}
          placeholder={placeholder}
          ref={this.select}
          styles={{ ...styles, ...customStyles }}
          theme={customTheme}
          value={value}
        />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }

  private get isDisabled(): boolean {
    const { disabled, jsonSchema } = this.props
    return disabled || !!(jsonSchema && jsonSchema.disabled)
  }

  private handleChange = (value: Option | Option[], { action }: ActionMeta) => {
    const { path, onChange } = this.props
    if (action === 'create-option') {
      if (Array.isArray(value)) {
        for (const val of value) {
          // tslint:disable-next-line:no-string-literal - this is a custom property that indicates if the value is new
          if ((val as any).__isNew__) {
            val.codeNotInList = true
          }
        }
      } else {
        value.codeNotInList = true
      }
    }
    if (onChange) {
      onChange(value, path)
    }
  }

  private handleFocus = () => {
    const { path, onFocus } = this.props
    if (onFocus) {
      onFocus(path)
    }
  }

  private handleBlur = () => {
    const { path, onBlur } = this.props
    if (onBlur) {
      onBlur(path)
    }
  }

  /**
   * The formatOptionsLabel function is used to format
   * the option when it is displayed in the input as well
   * as the drop down menu. We are using this function
   * to format the value that is visible in the input.
   */
  private formatOptionsLabel = (option: Option) => {
    const { icon, jsonSchema } = this.props
    const format = jsonSchema && jsonSchema.format
    if (format === 'value') {
      return option.value
    }
    if (format === 'bothValueLabel') {
      return `${option.value} - ${option.label}`
    }

    if (icon) {
      return (
        <span className='react-select-selected-item'>
          <span>{option.label}</span>
          {icon && <Icon icon={icon} />}
        </span>
      )
    }

    return option.label
  }

  /**
   * We override the Option component so we can display
   * option text in the drop down menu differently than
   * in the input.
   */
  private renderOption = (props: any) => {
    // tslint:disable-line:no-any - Type definitions don't match runtime.
    const { jsonSchema } = this.props
    const format = jsonSchema && jsonSchema.format
    const { children, ...other } = props
    let text
    if (format === 'simple') {
      text = props.label
    } else if (props.label) {
      text = `${props.value} - ${props.label}`
    } else {
      text = props.value
    }
    return <components.Option {...other}>{text}</components.Option>
  }

  private renderMenu = (props: MenuProps<Option>) => {
    return <components.Menu {...props} menuShouldScrollIntoView={true} />
  }
}

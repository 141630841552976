import { SignalRListener } from './middleware'
import { AppState } from '../store'
import { Dispatch } from 'redux'
import { getEngagement } from '../actions/engagementThunks'

const SignalRDuoStatusListener: SignalRListener = {
  message: 'ReReadEngagement',
  type: 'on',
  callback: function(
    getState: () => AppState,
    dispatch: Dispatch<any>,
    engagementId: number
  ) {
    // we will only refresh the engagement if we have the engagment read up once already..

    dispatch(getEngagement(engagementId))
  },
}
export const listeners: SignalRListener[] = [SignalRDuoStatusListener]

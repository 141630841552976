import { Theme } from '@rsmus/react-ui'
import React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { EntityGroup, EntityMap, FileGroup } from '../../clientModels'
import { EntityEngagement } from '../../services/api/apiModels'
import DocLibraryAllItem from './docLibraryAllItem'

function styles({ palette, breakpoints }: Theme) {
  return {
    entityDataContainer: {
      width: '100%',
      padding: '1rem 2rem',
    },
    tableContainer: {
      position: 'relative',
      overflowX: 'auto',
      width: '100%',
      height: '560px',
    },
    table: {
      borderStyle: 'solid',
      borderColor: 'white',
      '& th': {
        color: palette.common.white,
        flexWrap: 'wrap',
        height: '2.5rem',
        alignItems: 'center',
        [breakpoints.md]: {
          backgroundColor: '#009cde',
        },
        fontFamily: ['Arial-BoldMT', 'Arial Bold', 'Arial'].join(','),
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '0.75rem',
      },
      '& th, td': {
        padding: '2px 5px',
        minWidth: '100px',
      },
    },
    tableHeader: {
      backgroundColor: '#027fb6',
    },
    rowNumber: {
      backgroundColor: palette.grey[600] + '!important',
      color: palette.common.white,
      textAlign: 'center',
      minWidth: '20px!important',
    },
    selected: {
      backgroundColor: palette.blue.main,
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const indexByEngagementId = (e: EntityEngagement[]) =>
  e.reduce((m, e) => {
    m[e.engagementId] = e
    return m
  }, {} as EntityMap<EntityEngagement>)

interface DocLibraryAllProps {
  entityGroup: EntityGroup
  fileGroups: FileGroup[]
}

const DocLibraryAllTable = ({
  entityGroup,
  fileGroups,
}: DocLibraryAllProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const engagementsById = indexByEngagementId(entityGroup.entityEngagements)

  return (
    <div className={classes.entityDataContainer}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.tableHeader}>File Name</th>
              <th className={classes.tableHeader}>Added By</th>
              <th className={classes.tableHeader}>Added Date</th>
              <th className={classes.tableHeader}>Entity Name</th>
              <th className={classes.tableHeader}>Type</th>
              <th className={classes.tableHeader}>Status</th>
              <th className={classes.tableHeader}>Engagement Name</th>
              <th className={classes.tableHeader}></th>
            </tr>
          </thead>
          <tbody>
            {fileGroups.map((doc, i) => {
              const engagementId = doc.engagementId || 0
              const engagement = engagementsById[engagementId]
              return (
                <DocLibraryAllItem
                  alt={i % 2 === 0}
                  key={doc.id}
                  fileGroup={doc}
                  engagement={engagement}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DocLibraryAllTable

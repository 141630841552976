import { Almanac, RuleEvent, RuleResult } from 'json-rules-engine'
import { TsaDispatch, TsaThunkAction } from '../../store'
import { Fact } from './facts'

export interface RulesEventPayload {
  almanac: Almanac
  engagementId: number
  event: RuleEvent
  questionId: number
  rowIndex: number
  rule: RuleResult
}

export type RulesEventHandlerThunk = (
  payload: RulesEventPayload
) => TsaThunkAction

export interface RulesEventHandlerRegistry {
  [eventType: string]: RulesEventHandlerThunk
}

export const createRulesEventHandler = (
  dispatch: TsaDispatch,
  handlers: RulesEventHandlerRegistry
) => async (event: RuleEvent, almanac: Almanac, rule: RuleResult) => {
  const engagementId = await almanac.factValue(Fact.EngagementId)
  const questionId = event.params.questionId || -1
  const rowIndex = await almanac.factValue(Fact.RowIndex)
  const payload: RulesEventPayload = {
    almanac,
    engagementId,
    event,
    questionId,
    rowIndex,
    rule,
  }

  const thunk = handlers[event.type]
  if (!thunk) {
    throw new Error(`Unknown rule event type ${event.type}.`)
  }

  await dispatch(thunk(payload))
}

import * as React from 'react'
import { TsaJsonSchema } from '../../services/answerSchema/index'
import { FormFieldValue } from '../forms/formComponentsInterfaces'
import Number from '../forms/number'
import { EditorProps, leadingZeros } from './editors'

export function NumberEditor (property: TsaJsonSchema) {
  return class extends React.PureComponent<EditorProps> {
    constructor (props: EditorProps) {
      super(props)
      this.handleChange = this.handleChange.bind(this)
      this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    async handleChange (value: FormFieldValue) {
      const stringValue = value + ''
      const {
        props: { onChange },
      } = this
      const match = leadingZeros.exec(stringValue)
      if (match) {
        value = match[1] + match[2]
      }
      if (value === '-0') {
        value = '0'
      }
      onChange(stringValue)
    }

    handleKeyDown (e: React.KeyboardEvent<HTMLElement>) {
      this.props.onKeyDown(e)
    }

    render () {
      const {
        handleChange,
        handleKeyDown,
        props: { value },
      } = this
      const numberValue = value + ''
      return (
        <Number
          value={numberValue === '' ? undefined : numberValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          jsonSchema={property}
          autoFocus={true}
        />
      )
    }
  }
}

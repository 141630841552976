import { Theme } from '@rsmus/react-ui'
import React from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles, useTheme } from 'react-jss'
import { AppState } from '../../store'
import { RouteComponentProps } from 'react-router'
import { ensureNumber } from '../../guards'

interface RouteParams {
  engagementId: string
}
function styles({ palette, breakpoints }: Theme) {
  return {
    clientname: {
      display: 'none',
      color: palette.green.dark,
      backgroundColor: palette.common.white,
      marginLeft: '1rem',
      fontSize: '.75rem',
      [breakpoints.md]: {
        display: 'block',
      },
      [breakpoints.xl]: {
        fontSize: '1rem',
      },
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface ClientNameProps extends RouteComponentProps<RouteParams> {}

const ClientName = (props: ClientNameProps): JSX.Element => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const engagement = useSelector(
    (state: AppState) =>
      state.engagements &&
      state.engagements[ensureNumber(props.match.params.engagementId)]
  )
  const client = useSelector(
    (state: AppState) => engagement && state.clients[engagement.clientId]
  )
  return (
    <React.Fragment>
      <div className={classes.clientname}>
        {client && client.name}
        <div>{client?.id}</div>
      </div>
    </React.Fragment>
  )
}
export default ClientName

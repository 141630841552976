import { TasksApi } from '../services/api'
import { TsaThunkAction } from '../store'
import { normalizeTaskArray } from './normalization'
import { taskAction } from './taskActions'

export const getMyTasks = (
  userId: string
): TsaThunkAction => async dispatch => {
  dispatch(taskAction.request({}))
  try {
    const taskArray = await TasksApi.apiGetTasks(userId)
    const { tasks } = normalizeTaskArray(taskArray)
    return dispatch(taskAction.success({ tasks }))
  } catch (error) {
    return dispatch(taskAction.failure(error))
  }
}

import {
  EntityMap,
  Option,
  EtlScreen,
  File,
  FileTagCodes,
  
} from '../../clientModels'
import { isDefinedNotNull,  } from '../../guards'
import { StructureMap, TemplateSelection } from './interface'
import { EtlFileProgress, } from '../../services/api/apiModels'

export function filterOptions(
  options: Option[],
  selectedValues: EntityMap<string>
) {
  const result = []
  const rows = selectedValues
  for (const option of options) {
    let used = false
    if (option.value === '') {
      result.push(option)
      continue
    }
    for (const index in rows) {
      const selectedValue = rows[index]
      if (selectedValue === option.value) {
        used = true
        break
      }
    }
    if (!used) {
      result.push(option)
    }
  }
  return result
}

export interface TemplateSelectionErrors {
  newTemplateName?: string
  templateId?: string
}

export interface ValidationErrors extends TemplateSelectionErrors {
  cellToBeginImport?: string
  companyIdOrName?: string
  dataMappingTemplateErrors?: TemplateSelectionErrors
  etlType?: string
  exclude?: string
  fileContents?: string
  fileFormat?: string
  fileToInput?: string
  k1CodeOrDescription?: string
  k1Value?: string
  partnerIdOrName?: string
  pivot?: string
  worksheetToImport?: string
}

export interface ValidationInfo {
  isValid: boolean
  errors: ValidationErrors
}

export interface StepValidationInfo {
  step: EtlScreen
  validation: EntityMap<ValidationInfo>
}

const validationTests = [
  {
    regex: /^(companyId|companyName)$/,
    error: 'Issuing Partnership Company ID or Name must be selected',
    field: 'partnerIdOrName',
  },
  {
    regex: /^(partnerId|partnerName)$/,
    error: 'Receiving Partner ID or Name must be selected',
    field: 'partnerIdOrName',
  },
  {
    regex: /^(value|start)$/,
    error: 'K-1 Code Value must be selected',
    field: 'k1Value',
  },
  {
    regex: /^(code|description)$/,
    error: 'K1 code or description must be selected',
    field: 'k1CodeOrDescription',
  },
]

export function validate(
  values: StructureMap,
  step: EtlScreen
): ValidationInfo {
  const info = {
    isValid: true,
    errors: {} as ValidationErrors,
  }
  const structureTemplateErrors = validateTemplateSelection(values.format)

  try {
    switch (step) {
      case 'format':
        if (structureTemplateErrors) {
          info.errors = mergeObjects(info.errors, structureTemplateErrors)
        }
        if (!values.format.fileToInput) {
          info.errors.fileToInput = 'File to import is required'
        }
        if (!values.format.worksheetToImport) {
          info.errors.worksheetToImport = 'Worksheet to import is required'
        }
        if (!values.format.cellToBeginImport) {
          info.errors.cellToBeginImport = 'Cell to begin import is required'
        } else if (
          /^[A-Z]+[0-9]+$/i.test(values.format.cellToBeginImport) === false
        ) {
          info.errors.cellToBeginImport =
            'Cell to begin import has an incorrect format'
        }
        if (!values.format.fileFormat) {
          info.errors.fileFormat = 'File format is required'
        }
        break
      case 'pivot': {
        // there must be at least one column that identifies the issuer (companyId || companyName)
        // and at least one column that identifies the receiver (partnerId || partnerName)
        // and one column that identifies the K1 code
        if (
          isDefinedNotNull(values.pivotConfig.columns) === false &&
          isDefinedNotNull(values.pivotConfig.rows) === false
        ) {
          return info
        }
        const pivotConfig = Object.values(values.pivotConfig.columns).concat(
          Object.values(values.pivotConfig.rows)
        )
        for (const validationTest of validationTests) {
          if (
            !pivotConfig.some(
              (columnName?: string) =>
                columnName && validationTest.regex.test(columnName)
            )
          ) {
            info.isValid = false
            // info.errors.pivot = validationTest.error
            switch (validationTest.field) {
              case 'companyIdOrName':
                info.errors.companyIdOrName = validationTest.error
                break
              case 'partnerIdOrName':
                info.errors.partnerIdOrName = validationTest.error
                break
              case 'k1Value':
                info.errors.k1Value =
                  values.format.fileFormat === 'row'
                    ? validationTest.error
                    : 'Start of Values must be selected.'
                break
              case 'k1CodeOrDescription':
                info.errors.k1CodeOrDescription = validationTest.error
                break
              default:
                break
            }
            // return info
          }

          if (!info.errors.k1Value) {
            const dataTypeValidation = validateDataTypes(values)
            if (!dataTypeValidation.isValid) {
              info.errors = mergeObjects(info.errors, dataTypeValidation.errors)
            }
          }
        }
        break
      }
      default:
        break
    }
  } catch (e) {
    console.error(e)
  }

  info.isValid = Object.keys(info.errors).length === 0
  return info
}

export function validateTemplateSelection(
  form: TemplateSelection,
  validateFormat?: boolean
): TemplateSelectionErrors | undefined {
  const errors: TemplateSelectionErrors = {}
  if (!form.newTemplateName && !form.templateId) {
    errors.newTemplateName = errors.templateId =
      'Either a template name or selected template is required'
  }
  if (
    (validateFormat === undefined || validateFormat === true) &&
    form.newTemplateName &&
    /^.{1,10}-[1,2][0-9]{3}-.{1,15}(-.{1,32})?$/.test(form.newTemplateName) ===
    false
  ) {
    errors.newTemplateName = 'Incorrect format'
  }
  if (form.templateId > 0 && !form.newTemplateName) {
    errors.newTemplateName = 'Required'
  }
  if (Object.keys(errors).length === 0) return undefined
  return errors
}

export function mergeObjects<T>(v1: T, v2: T): T {
  const keys = Object.keys(v1).concat(Object.keys(v2))
  for (const key of keys) {
    ; (v1 as any)[key] = (v1 as any)[key] || (v2 as any)[key]
  }

  return v1
}

export function isNumeric(n: any): boolean {
  return /^-?\(?(\d+)(((.|,)\d+)+)?\)?$/.test(n)
}

export function validateDataTypes(values: StructureMap): ValidationInfo {
  const pivot = values.pivotConfig
  const info = {
    isValid: true,
    errors: {} as ValidationErrors,
  }

  try {
    // start of values
    let start1 = getIndexOfColumnSelection(pivot.rows, 'start')
    const start2 = getIndexOfColumnSelection(pivot.columns, 'start')

    if (start1 !== -1 || start2 !== -1) {
      if (start1 * start2 < 0) {
        info.isValid = false
        info.errors.k1Value =
          '"Start of Values" must exist on both row headers and column headers.'
      }
    }
  } catch (e) {
    console.error(e)
    info.isValid = true
    info.errors.k1Value = undefined
  }

  return info
}

export function getIndexOfColumnSelection(
  values: EntityMap<string>,
  name: string
): number {
  for (const val in values) {
    if (values[val] === name) {
      return parseInt(val)
    }
  }
  return -1
}

export function resetValidation(
  validation: ValidationInfo[],
  index: number
): ValidationInfo[] {
  const newState = [...validation]
  newState[index] = {
    isValid: true,
    errors: {} as ValidationErrors,
  }

  return newState
}

export function unique<T>(items: T[], selector?: (item: T) => string): T[] {
  const seen: any = {}
  const s = selector || (item => item)
  for (const row of items) {
    const key = s(row)
    seen[key] = row
  }
  return Object.values(seen)
}

export function genericSearch<T>(
  data: any[],
  filters: any[],
  maxCount?: number
): T[] {
  // filter
  const regexes = filters.map(f => getFilterRegex(f))

  const results = data.filter(item => {
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i]
      if (typeof filter.key === 'string') {
        // and
        const value = item[filter.key as string]
        if (typeof value === 'string') {
          const regex = regexes[i]
          if (filter.value !== '' && !regex.test(value)) return false
        } else {
          if (filter.value !== '' && value !== filter.value) return false
        }
      }
    }

    let result = true

    for (let j = 0; j < filters.length; j++) {
      const filter = filters[j]
      if (Array.isArray(filter.key)) {
        result = false
        // or
        for (const key of filter.key as string[]) {
          const value = item[key]
          if (typeof value === 'string') {
            const regex = regexes[j]
            if (regex.test(value)) {
              result = true
              break
            }
          } else {
            if (value === filter.value) {
              result = true
              break
            }
          }
        }
      }
    }

    // either there were no filters
    // or we got through them all without returning false
    return result
  })

  return maxCount ? results.slice(0, maxCount - 1) : results
}

const getFilterRegex = function (filter: any): RegExp {
  const value = filter.value
    .replace('?', '\\?')
    .replace('(', '\\(')
    .replace(')', '\\)')
    .replace('.', '\\.')
  switch (filter.op) {
    case 'contains':
      return new RegExp(`${value}`, 'i')
    case 'startsWith':
      return new RegExp(`^${value}`, 'i')
    case 'equals':
    default:
      return new RegExp(`^${value}$`, 'i')
  }
}

export const getUniqueOptions = function (
  items: any[],
  valueProp: string,
  labelProp: string
): Option[] {
  const options = items.map(item => {
    return {
      value: item[valueProp],
      label: item[labelProp],
    }
  })

  return unique(options, item => item.value)
}

export function getK1FileProgress(file: File): EtlFileProgress | null {
  let progress: EtlFileProgress | null = null
  const tags = file.tags
  if (tags && tags.length) {
    const tag = tags.find(t => t.tag === FileTagCodes.etlProgress)
    if (tag && tag.value) {
      try {
        progress = JSON.parse(tag.value)
        progress!.FileId = file.id
      } catch {
        progress = null
      }
    }
  }
  return progress
}

export const getEntityMapValues = function <T>(map: EntityMap<T>): T[] {
  const result: T[] = []
  for (const id in map) {
    if (map[id] !== undefined) {
      result.push(map[id]!)
    }
  }
  return result
}

export function optionsContainsValue(
  options: Option[] | null,
  value: string | null
): boolean {
  return options !== null && options.some(o => o.value === value)
}



import { AppInsights } from 'applicationinsights-js'
import { batchedTrackEvent } from './batchedTrackEvent'
import { batchedTrackMetric } from './batchedTrackMetric'
import { REACT_APP_APPINSIGHTSINSTRUMENTATIONKEY } from '../../envVariables'

if (AppInsights.downloadAndSetup !== undefined &&
  REACT_APP_APPINSIGHTSINSTRUMENTATIONKEY) {
  AppInsights.downloadAndSetup({
    instrumentationKey: REACT_APP_APPINSIGHTSINSTRUMENTATIONKEY,
  })
}

export { AppInsights, batchedTrackMetric, batchedTrackEvent }

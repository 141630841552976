export enum DocumentTitleId {
  K1Document = 59,
  MyInformationProvided = 61,
  AuditTrailReport = 62,
  PriorYearCarryForward = 71,
  EtlDocument = 75,
  K1UpLoadDocument = 78,
}

export enum ClientAccessRequestAction {
  Add = 'Add',
  Close = 'Close',
  Search = 'Search'
}

export enum FileStatus {
  Error = 'Error',
  Processing = 'In Progress',
  Uploaded = 'Uploaded',
  Uploading = 'Uploading',
}

export enum PhaseCode {
  CCH = 'cch',
  ClientValidation = 'clientVal',
  Closed = 'closed',
  IRS = 'irs',
  PBC = 'pbc',
  Review = 'review',
  Setup = 'setup',
}

export enum PhaseCodeOrder {
  Setup = 1,
  ClientValidation = 2,
  PBC = 3,
  Review = 4,
  CCH = 5,
  IRS = 6,
  Closed = 7,
}

// these codes need to match those on the server
// see Tsa.EtlMicroservice.Models.StatusCodes
// since these are also being used for K1 they also need to match
// Tsa.Models.K1FileStatusCodes
// https://dev.azure.com/rsmdevops/Tax/_workitems/edit/118814
export enum EtlFileStatus {
  AssignEntities = 'Assign Entities',
  AssignTaxCodes = 'Assign Tax Codes',
  Complete = 'Complete',
  DefineFileFormat = 'Define File Format',
  DefineStructure = 'Define Structure',
  Error = 'Error',
  InProgressContinue = 'ETL in Progress - Continue',
  MissingEntitiesSubmitted = 'Missing Entities Submitted',
  NewEntitiesAssociated = 'New Entities Associated',
  PartialSuccess = 'Submission Partially Successful',
  Processing = 'Processing',
  ReadyToMap = 'Ready to Map',
  ReadyToSubmit = 'Ready to Submit',
  SubmissionComplete = 'Submission Complete',
  SubmissionError = 'Submission Error',
  SubmissionInProgress = 'Submission in Progress',
  SubmittedToTrapeze = 'Submitted to Trapeze',
}

export enum EtlDisplayStatus {
  DataReady = 'Data ready for review',
  EntityMapping = 'Entity Mapping in Progress',
  TaxCodeMapping = 'Tax Code Mapping in Progress',
}

export enum MappingTypes {
  ChildEntityId = 'Child Entity ID',
  ChildEntityName = 'Child Entity Name',
  EntityId = 'Entity ID',
  EntityName = 'Entity Name',
  None = 'None',
  ParentEntityId = 'Parent Entity ID',
  ParentEntityName = 'Parent Entity Name',
  TaxCodeValue = 'Tax Code Value',
  TsaMapCode = 'TSA Map Code',
}

export enum MilestoneCode {
  CCH = 'cch',
  ClientValidation = 'clientVal',
  Closed = 'closed',
  EngagementStarted = 'engagementStarted',
  InviteSent = 'inviteSent',
  IRS = 'irs',
  PBC = 'pbc',
  Released = 'released',
  Review = 'review',
  Setup = 'setup',
}

export enum RoleCode {
  ClientPreparer = 'ClientPreparer',
  ConcurringReviewer = 'ConcurringReviewer',
  Preparer = 'Preparer',
  PrimaryReviewer = 'PrimaryReviewer',
  SecondaryReviewer = 'SecondaryReviewer',
  Signer = 'Signer',
  Viewer = 'Viewer',
}

export type ActivitySubType =
  | 'Add'
  | 'ClientReview'
  | 'ConcurrentReview'
  | 'PreparerReview'
  | 'PrimaryReview'
  | 'Remove'
  | 'SecondaryReview'

export type ActivityType =
  | 'Comment'
  | 'Document'
  | 'Flag'
  | 'Lock'
  | 'LockEngagement'
  | 'Review'
  | 'CarryForward'
  | 'CarryForwardAllComments'
  | 'NA'

export enum UserPermissions {
  CarryForwardCanView = 'CarryForwardCanView',
  CarryForwardCanEdit = 'CarryForwardCanEdit',
  CchCanView = 'CchCanView',
  CchCanEdit = 'CchCanEdit',
  CchImportExportCanEdit = 'CchImportExportCanEdit',
  EngagementTemplatesCanEdit = 'EngagementTemplatesCanEdit',
  EngagementsCanEdit = 'EngagementsCanEdit',
  AuditTrailReportCanView = 'AuditTrailReportCanView',
  SendEmailCanView = 'SendEmailCanView',
  SendEmailCanEdit = 'SendEmailCanEdit',
  K1DocumentsCanEdit = 'K1DocumentsCanEdit',
  K1DocumentsCanView = 'K1DocumentsCanView',
  DataFilesCanEdit = 'DataFilesCanEdit',
  DataFilesCanView = 'DataFilesCanView',
}
export type HttpRequestState =
  | 'InProgress'
  | 'Success'
  | 'Failed'
  | 'Unauthorized'
  | 'Idle'

import classNames from 'classnames'
import { uniqueId } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import Datetime from 'react-datetime'
import { FaCalendar } from 'react-icons/fa'
import { isStringOrNullOrUndefined } from '../../guards'
import { Alerts } from './alerts'
import './dateTime.scss'
import { FormFieldProps } from './formComponentsInterfaces'
import {
  by,
  convertToISODate,
  dateInCorrectFormat,
  hasError,
} from './formUtilities'

// tslint:disable-next-line:no-var-requires
interface DateTimePickerProps extends FormFieldProps {
  open?: boolean
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

interface DateTimeState {
  open: boolean
  value?: string
}

export default class DateTimePicker extends React.Component<
  DateTimePickerProps,
  DateTimeState
> {
  static defaultProps = {
    showAlerts: true,
  }

  // tslint:disable-next-line:no-any - required by interface
  static formatValueLastYear = (value: any, props: any): JSX.Element => {
    let dispValue = value
    if (value) {
      const timeValue = moment(value, [moment.ISO_8601, 'M/D/YYYY'], true)
      if (timeValue.isValid()) {
        dispValue = timeValue.format('MM/DD/YYYY')
      }
    }
    return dispValue
  }

  labelId: string
  hintId: string
  outer?: HTMLDivElement

  constructor(props: DateTimePickerProps) {
    super(props)
    const id = uniqueId('textbox_')
    this.labelId = `${id}_label`
    this.hintId = `${id}_hint`
    this.state = { open: false, value: this.validateDate(props.value) }
  }

  fixValue(value: string) {
    return this.state.value || ''
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: DateTimePickerProps) {
    const { close, state, props } = this
    if (nextProps.value !== props.value && nextProps.value !== state.value) {
      this.setState({ value: this.validateDate(nextProps.value) })
    }

    if (!nextProps.selectedQuestion && state.open) {
      close()
    }
  }

  validateDate = (
    value: string | object | object[] | null | undefined
  ): string | undefined => {
    if (!value) {
      return
    }
    if (!isStringOrNullOrUndefined(value)) {
      throw new Error('DateTime expects a string value.')
    }
    const correctDate = dateInCorrectFormat(value)
    if (correctDate) {
      setTimeout(
        (date: moment.Moment) => {
          this.handleChange(date)
          this.handleBlur()
        },
        0,
        correctDate
      )
      return convertToISODate(correctDate)
    }
    return value
  }

  setOuter = (ref: HTMLDivElement) => (this.outer = ref)

  handleChange = (value: moment.Moment | string) => {
    const { onChange, path } = this.props
    if (onChange) {
      onChange(convertToISODate(value), path)
    }
  }

  handleBlurDiv = () => {
    setTimeout(() => {
      if (this.outer && this.outer.contains(document.activeElement)) {
        return
      }
      this.close()
      const { path, onBlur } = this.props
      if (onBlur) {
        onBlur(path)
      }
    })
  }

  handleBlur = () => {
    this.close()
    const { path, onBlur } = this.props
    if (onBlur) {
      onBlur(path)
    }
  }

  handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
    // Removed auto-open on focus functionality.  This prevents a "flicker" effect where
    // clicking the calendar icon focuses the control then immediately toggles it closed.
    const { path, onFocus } = this.props
    if (onFocus) {
      onFocus(path)
    }
    if (!this.state.open) {
      this.setState({ open: false })
    }
  }

  toggleOpen = () => {
    this.state.open ? this.close() : this.open()
  }

  open = () => {
    if (this.props.disabled) {
      return
    }
    this.setState({ open: true }, this.addEventListeners)
  }

  close = () => this.setState({ open: false }, this.removeEventListeners)

  clickOutside = (e: Event) => {
    const { outer } = this
    if (outer && !outer.contains(e.target as Node)) {
      this.close()
    }
  }

  addEventListeners() {
    document.addEventListener('click', this.clickOutside, true)
  }

  removeEventListeners() {
    document.removeEventListener('click', this.clickOutside, true)
  }

  render() {
    const {
      describedBy,
      disabled,
      hint,
      label,
      labelledBy,
      messages,
      placeholder,
      showAlerts,
      path,
      autoFocus,
      onKeyDown,
      onKeyUp,
    } = this.props
    const { value, open } = this.state

    const momentDate = value && moment(value, moment.ISO_8601, true)
    const formattedValue =
      value && value.length > 0 && momentDate && momentDate.isValid()
        ? momentDate.format('MM/DD/YYYY')
        : value || ''
    const ariaLabelledBy = by(labelledBy, label && this.labelId)
    const ariaDescribedBy = by(describedBy, hint && this.hintId)
    const isInError = hasError(messages, path)
    const inputProps = {
      'aria-labelledby': ariaLabelledBy,
      'aria-describedby': ariaDescribedBy,
      placeholder,
      disabled,
      autoFocus,
      onKeyDown,
      onKeyUp,
    }

    return (
      <div
        className={classNames('form-group', {
          disabled,
          'is-valid': !isInError,
          'is-invalid': isInError,
        })}
        ref={this.setOuter}
        onBlur={this.handleBlurDiv}
      >
        {label && <label id={this.labelId}>{label}</label>}
        {hint && (
          <div id={this.hintId} className='hint-text'>
            {hint}
          </div>
        )}
        <FaCalendar className='calendarIcon' onClick={this.toggleOpen} />
        <Datetime
          value={formattedValue}
          closeOnSelect={true}
          dateFormat='MM/DD/YYYY'
          inputProps={inputProps}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          open={open}
          timeFormat={false}
        />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../../actions/index'
import { EtlFileStatusMap } from '../../clientModels'
import { DocumentTitleId, EtlFileStatus } from '../../enums'
import { ensureNumber } from '../../guards'
import { engagementLockedPhase } from '../../services/engagementPhaseService'
import { latestFilesFromFileGroup } from '../../services/fileHelpers'
import { AppState } from '../../store'
import { ConfirmDeleteFileDialog, InfoDialog, AppErrorDialog } from '../modals'
import DataFileGrid from './dataFileGrid'
import './dataFileManagement.scss'
import FileUploadButtons from './dataFileButtons'
import { getEntityMapValues } from '../configureImport/helpers'
import FileErrorsDialog from './fileErrorsDialog'
import { getProps, selectFileGroup } from './dataFileManagementHelpers'
import { useDocumentTitles } from '../fileUploadAdvanced/useDocumentTitles'
import DataFileUploadProgresss from './dataFileUploadProgress'
import { useConfigureImportParams } from '../configureImport/useConfigureImportParams'
import { getDisplayStatusMap } from './dataFileHelpers'
import { getDisplayStatusMap as getK1StatusMap } from '../../services/fileHelpers'

export interface DataFileManagementOwnProps {
  documentTitleId?: number
  engagementId: number
  questionId?: number
  fileGroupId?: number
}

interface DataFileManagementState {
  showConfirmDelete: boolean
  showSubmitToCchDialog: boolean
  showFileErrorDialog: boolean
  group: boolean
  selectedFileId: number
  visible: boolean
}

// this helps the prop drilling to be less odious
export interface FileEventApi {
  onClickDelete?: (fileId: number) => void
  onClickDownload: (fileId: number) => void
  onClickFileCheckbox: (fileId: number, tag: string) => void
  onClickSelectRow: (fileId: number) => void
  onClickShowError?: (fileId: number) => void
  onClickSubmitToCCH?: (fileId: number) => void
  onClickUpdate?: (fileId: number) => void
}

export interface DataFileManagementProps
  extends DataFileManagementOwnProps,
    DataFileManagementState,
    FileEventApi {}

export default function DataFileManagement() {
  const dispatch = useDispatch()
  const params = useConfigureImportParams()
  const props = getProps(dispatch)
  const { user } = useSelector((state: AppState) => state.auth)
  const etlFiles = useSelector((state: AppState) => state.etlFiles)

  const {
    selectedFiles: stateSelectedFiles,
    showUploadErrorDialog,
    uploadStatus
  } = useSelector((state: AppState) => state.fileUpload)

  const busy = useSelector((state: AppState) =>
    state.http.loading.includes(actions.file.FileUploadBusyIndicator)
  )
  const documentTitles = useDocumentTitles()
  const statusSelected = useSelector(
    (state: AppState) => state.selection.selectedStatusFilter
  )
  const engagement = useSelector(
    (state: AppState) => state.engagements[params.engagementId]
  )
  const etlFileStatuses = {} as EtlFileStatusMap
  const [state, setState] = useState<DataFileManagementState>({
    group: false,
    selectedFileId: 0,
    showConfirmDelete: false,
    showFileErrorDialog: false,
    showSubmitToCchDialog: false,
    visible: true
  })
  const fileGroup = useSelector((state: AppState) =>
    selectFileGroup(state, params)
  )
  const files = latestFilesFromFileGroup(fileGroup)
  const selectedFiles = files.filter(x => stateSelectedFiles[x.id])
  const allFilesSelected =
    files.length > 0 && selectedFiles.length === files.length
  const etlFilesArray = getEntityMapValues(etlFiles)
  const etlStatusMap = getDisplayStatusMap(
    etlFilesArray,
    files,
    user?.isExternal ?? false
  )
  const { k1FileStatuses } = useSelector(
    (state: AppState) => state.inputProcess
  )
  const k1StatusMap = getK1StatusMap(files, k1FileStatuses)

  useEffect(() => {
    if (
      params.engagementId !== 0 &&
      params.questionId !== 0 &&
      (params.documentTitleId === DocumentTitleId.K1Document ||
        params.documentTitleId === DocumentTitleId.K1UpLoadDocument)
    ) {
      dispatch(
        actions.inputProcess.getEngagementK1FileStatuses(
          params.engagementId,
          params.questionId
        )
      )
    }
  }, [params.engagementId, params.questionId, params.documentTitleId, dispatch])

  const groupDownload = () => {
    if (busy) {
      return false
    }

    const selectedFileCount = selectedFiles.length

    if (selectedFileCount === 1) {
      if (props.downloadFile) {
        const selectedFile = selectedFiles[0]
        props.downloadFile(selectedFile.id)
      }
    }

    if (selectedFileCount > 1) {
      const fileIds = selectedFiles.map(file => file.id)
      props.downloadFiles(fileIds)
    }

    unselectAllFiles()
  }

  // Delete a group of selected files
  const handleGroupDeleteClick = () => {
    setState({
      ...state,
      showConfirmDelete: true
    })
  }

  const closeModal = () => {
    setState({
      ...state,
      showConfirmDelete: false
    })
  }

  const groupDelete = () => {
    if (busy) {
      return false
    }
    if (fileGroup && props.softDeleteFiles) {
      const engagementId = ensureNumber(fileGroup.engagementId)
      const questionId = ensureNumber(fileGroup.questionId)
      const selctedFileIds = selectedFiles.map(x => x.id)
      props.softDeleteFiles(
        engagementId,
        questionId,
        fileGroup.id,
        selctedFileIds
      )
    }
    closeModal()
  }

  const handleGroupSubmitToCCH = () => {
    if (busy) {
      return false
    }

    setState({
      ...state,
      group: true,
      showSubmitToCchDialog: true
    })

    const fileIds = selectedFiles.map(f => f.id)

    props.submitMultipleFilesToCch(fileIds)
  }

  const closeSubmitToCchDialog = () => {
    setState({
      ...state,
      group: false,
      showSubmitToCchDialog: false
    })
    unselectAllFiles()
  }

  const closeUploadErrorDialog = () => {
    props.hideUploadErrorDialog()
  }

  const onClickDownload = (fileId: number) => {
    const { downloadFile } = props
    if (downloadFile) {
      downloadFile(fileId)
    }
  }

  //  Delete a single file
  const onClickDelete = (fileId: number) => {
    const { softDeleteFiles } = props
    if (fileGroup && softDeleteFiles) {
      const engagementId = ensureNumber(fileGroup.engagementId)
      const questionId = ensureNumber(fileGroup.questionId)
      softDeleteFiles(engagementId, questionId, fileGroup.id, [fileId])
    }
  }

  const onClickSubmitToCCH = (fileId: number) => {
    if (busy) {
      return false
    }

    props.submitFileToCch(fileId)

    setState({
      ...state,
      selectedFileId: fileId,
      group: false,
      showSubmitToCchDialog: true
    })
  }

  const onClickUpdate = (fileId: number) => {
    const { fileUpload } = props
    if (fileUpload && fileGroup) {
      const documentTitle =
        documentTitles && fileGroup.documentTitleId
          ? documentTitles.find(x => x.id === fileGroup.documentTitleId)
          : undefined
      fileUpload(
        fileGroup.engagementId || 0,
        fileGroup.questionId || 0,
        documentTitle,
        fileGroup,
        fileId
      )
    }
  }

  const onClickSelectRow = (fileId: number) => {
    const { toggleSelectFile } = props
    if (toggleSelectFile) {
      toggleSelectFile(fileId)
    }
  }

  const onClickFileCheckbox = (fileId: number, tag: string) => {
    const { toggleTag } = props
    if (fileGroup && toggleTag) {
      const engagementId = ensureNumber(fileGroup.engagementId)
      const questionId = ensureNumber(fileGroup.questionId)
      toggleTag(tag, engagementId, questionId, fileGroup.id, [fileId])
    }
  }

  const onClickShowError = (fileId: number) => {
    setState({
      ...state,
      selectedFileId: fileId,
      showFileErrorDialog: true
    })
  }

  const closeShowFileErrorDialog = () => {
    setState({
      ...state,
      selectedFileId: 0,
      showFileErrorDialog: false
    })
  }

  const selectAllFiles = () => {
    if (onClickSelectRow && files) {
      if (allFilesSelected) {
        files.map(file => onClickSelectRow(file.id))
      } else {
        files
          .filter(x => !selectedFiles[x.id])
          .map(file => onClickSelectRow(file.id))
      }
    }
  }

  const unselectAllFiles = () => {
    if (onClickSelectRow && files) {
      files
        .filter(x => selectedFiles[x.id])
        .map(file => onClickSelectRow(file.id))
    }
  }

  const filterFilesByStatus = (status: string | undefined) => {
    if (!status || status === 'None') {
      return files
    }
    if (params.documentTitleId === 59) {
      return files.filter(f => k1StatusMap[f.id] === status)
    }
    const etlFilesArray = getEntityMapValues(etlFiles)
    const a = files.filter(function(file) {
      const etlFile = etlFilesArray.find(f => f.fileId === file.id)
      return status === etlStatusMap[etlFile?.id ?? 0]
    })
    return a
  }

  const render = () => {
    const {
      showConfirmDelete,
      showSubmitToCchDialog,
      showFileErrorDialog,
      group,
      selectedFileId
    } = state

    const fileSelected = selectedFiles.length > 0
    // get unique list of all file status
    const filteredStatusList: string[] = files
      .map(file => file.status ?? '')
      .filter(s => s !== '')
      .filter((val: string, index: number, original: string[]) => {
        return original.indexOf(val) === index
      })

    props.setListOfStatus(filteredStatusList)

    const engagementLocked = engagement
      ? engagement.isEnabled !== undefined
        ? engagement.isEnabled
        : engagementLockedPhase(engagement)
      : true

    const disableGroupButtons = engagementLocked || !fileSelected || busy

    const filesToSubmitToCch = group
      ? selectedFiles
      : files.filter(x => x.id === selectedFileId)

    return (
      <div className='file-upload-advanced'>
        <DataFileUploadProgresss files={files} />
        <div className='file-upload-advanced-content'>
          <FileUploadButtons
            busy={busy}
            disableGroupButtons={disableGroupButtons}
            fileGroup={fileGroup}
            etlFileStatuses={etlFileStatuses}
            selectedFiles={stateSelectedFiles}
            onClickDelete={handleGroupDeleteClick}
            onClickSubmitToCch={handleGroupSubmitToCCH}
            onClickDownload={groupDownload}
          />
          <DataFileGrid
            allFilesSelected={allFilesSelected}
            disabled={() => engagementLocked}
            etlFiles={etlFiles}
            files={filterFilesByStatus(statusSelected)}
            handlers={{
              onClickDelete,
              onClickDownload,
              onClickFileCheckbox,
              onClickSelectRow,
              onClickShowError,
              onClickSubmitToCCH,
              onClickUpdate
            }}
            selectAllFiles={selectAllFiles}
            selectedFiles={stateSelectedFiles}
            uploadStatus={uploadStatus}
          />
        </div>
        {showConfirmDelete && (
          <ConfirmDeleteFileDialog
            title='This will permanently delete selected file(s) from your documents list.'
            info=''
            primaryButtonText='Yes Delete File(s)'
            secondaryButtonText='No Keep File(s)'
            onClickPrimary={groupDelete}
            onClickSecondary={closeModal}
            onClose={closeModal}
          />
        )}
        {showSubmitToCchDialog && (
          <InfoDialog
            title='Submit to CCH'
            info='The following files are being submitted to CCH:'
            onClickPrimary={closeSubmitToCchDialog}
            onClose={closeSubmitToCchDialog}
          >
            <table
              style={{
                textAlign: 'left',
                fontSize: '.85rem'
              }}
            >
              {filesToSubmitToCch.map(file => {
                const etlStatus = etlFileStatuses[file.id]
                return (
                  <tr>
                    <td>{file.name}</td>
                    <td style={{ paddingLeft: '1rem' }}>
                      {etlStatus === EtlFileStatus.ReadyToSubmit
                        ? 'Submitting...'
                        : etlStatus}
                    </td>
                  </tr>
                )
              })}
            </table>
          </InfoDialog>
        )}
        {showUploadErrorDialog && (
          <AppErrorDialog
            context=''
            title='Error Uploading File'
            message1='The document(s) you are trying to upload is invalid. Reasons your document is considered invalid are the following: password protected, file corruption, or invalid file type.'
            buttons={[
              {
                autoFocus: true,
                onClick: () => closeUploadErrorDialog(),
                label: 'Close'
              }
            ]}
          ></AppErrorDialog>
        )}
        {showFileErrorDialog && (
          <FileErrorsDialog
            fileId={selectedFileId}
            onClose={closeShowFileErrorDialog}
          />
        )}
      </div>
    )
  }

  return render()
}

import React, { useState } from 'react'
import { useEffect } from 'react'
import SmoothCollapse from 'react-smooth-collapse'
import Icon from '../icon/icon'
import { EngagementSectionNavItem } from '../../clientModels'
import './engagementSectionGroup.scss'

export interface EngagementSectionGroupProps {
  groupLabel: string
  sectionNavItems: (EngagementSectionNavItem | null | undefined)[]
  selectedSectionId: string | undefined
  showAllSections: boolean
}

/*
  Renders an individual engagement section group as a SmoothCollapse
*/

const EngagementSectionGroup = ({
  groupLabel,
  sectionNavItems,
  selectedSectionId,
  showAllSections
}: EngagementSectionGroupProps) => {
  const [isGroupExpanded, setIsGroupExpanded] = useState(false)

  useEffect(() => {
    // Check if the selected section is included in this group.
    const groupContainsSelectedSection =
      selectedSectionId &&
      sectionNavItems.some(
        item => item?.sectionId === parseInt(selectedSectionId)
      )

    // If selected section is in this group or the 'Show All Sections' toggle is enabled, auto-expand the group's SmoothCollapse to expose the section
    if (groupContainsSelectedSection || showAllSections) {
      setIsGroupExpanded(true)
    }

    if (!groupContainsSelectedSection && !showAllSections) {
      setIsGroupExpanded(false)
    }
  }, [selectedSectionId, sectionNavItems, showAllSections])

  const handleExpandCollapse = () => {
    setIsGroupExpanded(!isGroupExpanded)
  }

  const arrowDirection = isGroupExpanded ? 'caretDown' : 'caretRight'
  const isDisabled = !sectionNavItems || !sectionNavItems?.length

  // We only need the actual JSX.Element from these objects
  const navItemContent = sectionNavItems.map(item => item?.navItem)

  return (
    <>
      <div
        onClick={handleExpandCollapse}
        style={isDisabled ? { pointerEvents: 'none', opacity: '0.4' } : {}}
        className='engagement-section-group'
      >
        <Icon icon={arrowDirection} />
        {groupLabel}
      </div>
      <SmoothCollapse expanded={isGroupExpanded}>
        {navItemContent}
      </SmoothCollapse>
    </>
  )
}

export default EngagementSectionGroup

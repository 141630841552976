import { TsaHubAction } from '../actions/index'
import { SectionMap } from '../clientModels'
import { engagementTemplatesDocEndAction } from '../actions/engagementTemplateActions'
import { getType } from 'typesafe-actions'

const initialState: SectionMap = {}

export function sectionsReducer (
  state: SectionMap = initialState,
  action: TsaHubAction
): SectionMap {
  switch (action.type) {
    case getType(engagementTemplatesDocEndAction):
      return { ...state, ...action.payload.payload.sections }
    default:
  }

  return state
}

import * as React from 'react'
import { Link } from 'react-router-dom'
import { REACT_APP_TERMSOFSERVICEID } from '../../envVariables'
import './footer.scss'

const Footer = () => {
  const termsofUseId = REACT_APP_TERMSOFSERVICEID | 1
  return (
    <footer className='page-footer'>
      <div className='page-footer_meta'>
        <p>
          &copy; {new Date().getFullYear()} RSM US LLP. All rights reserved.
          <a
            href='https://rsmus.com/pages/rsm-us-privacy-policy.html'
            target='_blank'
            rel='noreferrer'
          >
            Privacy
          </a>
          <Link to={`/termsofuse/${termsofUseId}`}>Terms of Use</Link>
          <a
            href='https://rsmus.com/pages/cookie-policy.html'
            target='_blank'
            rel='noreferrer'
          >
            Cookies
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer

import { File as TsaFile, FileContentsMap, FileGroup } from '../../clientModels'
import { ensureNumber } from '../../guards'
import * as http from '../http'
import { getNewAbort } from '../lastRequests'
import { buildApiUrl, buildAggregatorUrl } from './apiUrl'
import { azureUploadFile } from './azureApi'
import { REACT_APP_GLOBALFILEDOWNLOADTIMEOUTINSECS } from '../../envVariables'

const fileDownloadTimeout =
  ensureNumber(REACT_APP_GLOBALFILEDOWNLOADTIMEOUTINSECS) || 90
const completeTimeout = 1200
const filesAbort = getNewAbort()

export const apiCompleteFile = (fileId: number) => {
  return http.post(
    buildAggregatorUrl(`Files/${fileId}/Complete`),
    undefined,
    undefined,
    undefined,
    completeTimeout
  )
}

export const apiSoftDeleteFiles = (
  engagementId: number,
  fileIds: number[]
): Promise<TsaFile[]> => {
  return http.post(
    buildAggregatorUrl(`Files/SoftDelete/${engagementId}`),
    fileIds,
    undefined,
    filesAbort.new(),
    fileDownloadTimeout
  )
}

export const apiToggleTag = (
  tag: string,
  engagementId: number,
  fileIds: number[]
): Promise<TsaFile[]> => {
  return http.post(
    buildApiUrl(`Files/ToggleTag/${engagementId}`, { tag }),
    fileIds,
    undefined,
    filesAbort.new(),
    fileDownloadTimeout
  )
}

export const apiDeleteFile = (id: number) => {
  return http.remove(buildApiUrl(`Files/${id}`))
}

export function apiDownloadFile(fileId: number): Promise<http.BlobResponse> {
  return http.post(
    buildApiUrl(`Files/${fileId}/Download`),
    undefined,
    undefined,
    filesAbort.new(),
    fileDownloadTimeout
  )
}

export const apiErrorFile = (fileId: number) => {
  console.log(buildAggregatorUrl(`Files/${fileId}/Error`))
  return http.post(buildAggregatorUrl(`Files/${fileId}/Error`), undefined)
}

export function apiDownloadFiles(
  fileIds: number[]
): Promise<http.BlobResponse> {
  return http.post(
    buildApiUrl('Files/DownloadFiles'),
    fileIds,
    undefined,
    filesAbort.new(),
    fileDownloadTimeout
  )
}

export const apiPrepareUpload = (
  engagementId: number,
  files: TsaFile[]
): Promise<TsaFile[]> => {
  return http.post(
    buildApiUrl(`Files/PrepareUpload/${engagementId}`),
    files,
    undefined,
    filesAbort.new(),
    fileDownloadTimeout
  )
}

export const apiUploadFile = (
  file: TsaFile,
  contentsMap: FileContentsMap,
  onError: (error: Error, file: TsaFile) => void
): Promise<TsaFile> => {
  return new Promise<TsaFile>(resolve => {
    const fileContents = contentsMap[file.name]
    if (fileContents && file.sasUri) {
      azureUploadFile(file, fileContents, file.sasUri)
        .then(() =>
          apiCompleteFile(file.id).then(response => resolve(response))
        )
        .catch(err => {
          onError(err, file)
          apiErrorFile(file.id)
            .then(response => resolve(response))
            .catch(() => resolve({ ...file, status: 'Error' }))
        })
    } else {
      const err = new Error('Invalid file upload')
      onError(err, file)
      resolve({ ...file, status: 'Error' })
    }
  })
}

export function postDownloadExcelTemplate(
  taxForm: string,
  taxYear: number,
  fileName: string,
  clientId: number,
  engagementId: number
): Promise<http.BlobResponse> {
  const url = buildApiUrl('filetemplates/download', {
    clientId,
    engagementId,
    fileName,
    taxForm,
    taxYear
  })
  return http.post(
    url,
    undefined,
    undefined,
    filesAbort.new(),
    fileDownloadTimeout
  )
}

export function apiSetCurrentVersion(fileId: number): Promise<FileGroup> {
  return http.post(buildApiUrl(`files/${fileId}/setcurrentversion`), undefined)
}

import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { EngagementHeader } from '../../components/engagement'
import { EngagementsListHeader } from '../../components/engagementsList'

interface EngagementsHeaderProps extends RouteComponentProps<{}> {}

const EngagementsHeader = ({ match }: EngagementsHeaderProps) => {
  const routePrefix: string = `${match.path}/:engagementId`

  const ROUTE_PATHS = [
    `${routePrefix}`,
    `${routePrefix}/:edit`,
    `${routePrefix}/questions/:questionId/`,
    `${routePrefix}/questions/:questionId/:edit`,
    `${routePrefix}/sections/:sectionId/`,
    `${routePrefix}/sections/:sectionId/:edit`,
    `${routePrefix}/sections/:sectionId/questions/:questionId/`,
    `${routePrefix}/sections/:sectionId/questions/:questionId/:edit`,
  ]

  return (
    <Switch>
      <Route
        key='lists'
        path={match.path}
        component={EngagementsListHeader}
        exact
      />
      <Route key='one' path={ROUTE_PATHS} component={EngagementHeader} />
    </Switch>
  )
}

export default EngagementsHeader

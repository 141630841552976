import React, { useEffect, useState } from 'react'
import Responsive from 'react-responsive'

enum GridBreakpoints {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
}

export enum MediaMaxBreakpoint {
  xs = GridBreakpoints.sm - 1,
  sm = GridBreakpoints.md - 1,
  md = GridBreakpoints.lg - 1,
  lg = GridBreakpoints.xl - 1,
  xl = Number.MAX_SAFE_INTEGER,
}

export enum MediaMinBreakpoint {
  xs = GridBreakpoints.xs,
  sm = GridBreakpoints.sm,
  md = GridBreakpoints.md,
  lg = GridBreakpoints.lg,
  xl = GridBreakpoints.xl,
}

enum MediaUpDown {
  down = 'max',
  up = 'min',
}

const buildMediaQuery = (breakpoint: number, upDown: MediaUpDown) =>
  window.matchMedia('(' + upDown + '-width: ' + breakpoint + 'px)')

export const MediaQueries = {
  xsDown: buildMediaQuery(MediaMaxBreakpoint.xs, MediaUpDown.down),
  xsUp: buildMediaQuery(MediaMinBreakpoint.xs, MediaUpDown.up),
  smDown: buildMediaQuery(MediaMaxBreakpoint.sm, MediaUpDown.down),
  smUp: buildMediaQuery(MediaMinBreakpoint.sm, MediaUpDown.up),
  mdDown: buildMediaQuery(MediaMaxBreakpoint.md, MediaUpDown.down),
  mdUp: buildMediaQuery(MediaMinBreakpoint.md, MediaUpDown.up),
  lgDown: buildMediaQuery(MediaMaxBreakpoint.lg, MediaUpDown.down),
  lgUp: buildMediaQuery(MediaMinBreakpoint.lg, MediaUpDown.up),
  xlDown: buildMediaQuery(MediaMaxBreakpoint.xl, MediaUpDown.down),
  xlUp: buildMediaQuery(MediaMinBreakpoint.xl, MediaUpDown.up),
}

// tslint:disable:no-any
export const MediumDown = (props: any) => (
  <Responsive {...props} maxWidth={MediaMaxBreakpoint.md} />
)
export const LargeUp = (props: any) => (
  <Responsive {...props} minWidth={MediaMinBreakpoint.lg} />
)

export function useMatchMediaQuery (
  query: MediaQueryList,
  defaultState: boolean = false
) {
  const [matches, setMatches] = useState(defaultState)

  function handleQueryResult (result: MediaQueryListEvent) {
    setMatches(result.matches)
  }

  useEffect(() => {
    query.addListener(handleQueryResult)
    return () => {
      query.removeListener(handleQueryResult)
    }
  })

  return matches
}

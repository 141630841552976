import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { actions } from '../../actions'
import {
  Activity,
  Engagement,
  IdentityTokenProfile,
  Question,
} from '../../clientModels'
import { ensureNumber, isDefined } from '../../guards'
import { AppState, TsaDispatch } from '../../store'
import { ActivityList } from './activityList'
import { WarningDialog } from '../modals'
import * as _ from 'lodash'

interface ActivityListContainerOwnProps {
  engagementId: string
  selectedActivityId?: string
  question?: Question
  autoScroll?: boolean
  questionDisabled: boolean
}

interface ActivityListContainerMappedProps {
  activities: Activity[]
  user?: IdentityTokenProfile
  engagement?: Engagement
}
interface ActivityListContainerState {
  showConfirmDelete: boolean
  deleteActivityId: number
}

const createSelectActivities = () =>
  createSelector(
    (state: AppState, props: ActivityListContainerOwnProps) =>
      state.activities[ensureNumber(props.engagementId)] || {},
    (state: AppState, props: ActivityListContainerOwnProps) => props.question,
    (activitiesByQuestionId, question) => {
      const questionId = question && question.id
      const definedActivities = (
        activitiesByQuestionId[questionId || ''] || []
      ).filter(isDefined)

      // Only show the two most recent flags
      let flagCount = 0
      const activities: Activity[] = []
      for (let i = definedActivities.length - 1; i >= 0; --i) {
        const activity = definedActivities[i]
        if (activity.activityTypeCode === 'Flag' && flagCount++ >= 2) {
          continue
        }

        activities.unshift(activity)
      }
      return activities
    }
  )

const createMapStateToProps = () => {
  const selectActivities = createSelectActivities()
  return (
    state: AppState,
    props: ActivityListContainerOwnProps
  ): ActivityListContainerMappedProps => {
    const engagement = state.engagements[ensureNumber(props.engagementId)]
    return {
      activities: selectActivities(state, props),
      engagement,
      user: state.auth.user,
    }
  }
}

interface ActivityListContainerDispatchProps {
  deleteActivity: (activityId: number) => void
  getActivities: (engagementId: number | string, questionId: number) => void
  saveActivity: (Activity: Activity) => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: ActivityListContainerOwnProps
): ActivityListContainerDispatchProps => ({
  deleteActivity: (activityId: number) =>
    dispatch(actions.activity.deleteActivity(props.engagementId, activityId)),
  getActivities: (engagementId: string | number, questionId: number) => {
    engagementId = ensureNumber(engagementId)
    questionId = ensureNumber(questionId)
    if (engagementId > 0 && questionId > 0) {
      dispatch(actions.activity.getActivities(engagementId, questionId))
    }
  },
  saveActivity: (activity: Activity) =>
    dispatch(actions.activity.saveActivity(activity)),
})

type ActivityListContainerProps = ActivityListContainerOwnProps &
  ActivityListContainerMappedProps &
  ActivityListContainerDispatchProps

class ActivityListContainerComponent extends React.Component<
  ActivityListContainerProps
> {
  state: ActivityListContainerState = {
    showConfirmDelete: false,
    deleteActivityId: 0,
  }

  componentDidMount() {
    const { getActivities, engagementId, question } = this.props
    if (question) {
      getActivities(engagementId, question.id)
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: ActivityListContainerProps) {
    const { engagement, engagementId, question, getActivities } = this.props
    const questionId = question && question.id
    const nextQuestionId = nextProps.question && nextProps.question.id

    if (!engagement || !engagement.loaded) {
      return
    }

    // The two engagements are probably not the same instances, and probably won't have
    //  the same values for their last update.  If anything else is different, then
    //  we'll want to consider the two engagements different, and let the following call
    //  update its activities.  (Note: I'm not sure why it would need to update the activities.)
    const { lastUpdatedBy, lastUpdatedDate, ...compare1 } = engagement
    const {
      lastUpdatedBy: lastUpdatedBy1,
      lastUpdatedDate: lastUpdatedDate1,
      ...compare2
    } = nextProps.engagement ?? {}
    // This does a deep compare on the objects' property values (not byRef).
    const isEngagementsSame = _.isEqual(compare1, compare2)

    if (
      nextProps.engagementId !== engagementId ||
      nextQuestionId !== questionId ||
      !isEngagementsSame
    ) {
      if (nextQuestionId) {
        getActivities(nextProps.engagementId, nextQuestionId)
      }
    }
  }

  closeModal = () => {
    this.setState({ showConfirmDelete: false, actvityId: 0 })
  }

  handleCommentDeleteClick = (inActivityId: number) => {
    this.setState({
      showConfirmDelete: true,
      deleteActivityId: inActivityId,
    })
  }

  deleteComment = () => {
    const { deleteActivity } = this.props
    const {
      state: { deleteActivityId },
    } = this
    if (deleteActivity) {
      // alert('Delete comment Here')
      deleteActivity(deleteActivityId)
      this.closeModal()
    }
  }

  render() {
    const {
      activities,
      autoScroll,
      engagement,
      question,
      saveActivity,
      selectedActivityId,
      user,
      questionDisabled,
    } = this.props
    const {
      state: { showConfirmDelete },
    } = this
    return (
      <React.Fragment>
        <ActivityList
          activities={activities}
          autoScroll={autoScroll}
          deleteActivity={this.handleCommentDeleteClick}
          engagement={engagement}
          question={question}
          questionDisabled={questionDisabled}
          saveActivity={saveActivity}
          selectedActivityId={ensureNumber(selectedActivityId)}
          user={user}
        />
        {showConfirmDelete && (
          <WarningDialog
            title='Do you want to delete this comment?'
            info='You will not be able to retrieve the comment once it has been deleted.'
            primaryButtonText='YES, DELETE'
            secondaryButtonText='CANCEL'
            onClickPrimary={this.deleteComment}
            onClickSecondary={this.closeModal}
            onClose={this.closeModal}
          />
        )}
      </React.Fragment>
    )
  }
}

export const ActivityListContainer = connect(
  createMapStateToProps,
  mapDispatchToProps
)(ActivityListContainerComponent)

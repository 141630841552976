// tslint:disable:no-any
import { Engine, Operator } from 'json-rules-engine'
import { isEqual as deepIsEqual } from 'lodash'
import moment from 'moment'
import {
  isObjectNullOrUndefined,
  isReallyEmpty,
  isEmpty,
  isNotReallyEmpty,
  isFalsey,
} from '../../guards'

/*
json-rules-engine built-in operators
===================================================================
contains - fact (an array) must include value
doesNotContain - fact (an array) must not include value
equal - fact must equal value (===)
greaterThan - fact must be greater than value
greaterThanInclusive- fact must be greater than or equal to value
in - fact must be included in value (an array)
lessThan - fact must be less than value
lessThanInclusive- fact must be less than or equal to value
notEqual - fact must not equal value (!==)
notIn - fact must not be included in value (an array)
*/
export const operators = {
  contains: 'contains', // not used
  dateGreaterThanInclusive: 'dateGreaterThanInclusive', // Rsm.RulesEngine.Operators
  dateLessThanInclusive: 'dateLessThanInclusive', // Rsm.RulesEngine.Operators
  deepEmpty: 'deepEmpty', // Tsa.BusinessLogic.Operators
  doesNotContain: 'doesNotContain', // not used
  empty: 'empty', // Tsa.BusinessLogic.Operators
  equal: 'equal', // Tsa.BusinessLogic.Operators
  greaterThan: 'greaterThan', // Rsm.RulesEngine.Operators
  greaterThanInclusive: 'greaterThanInclusive', // Rsm.RulesEngine.Operators
  hasProperty: 'hasProperty', // Tsa.BusinessLogic.Operators
  in: 'in', // not used
  isSubset: 'isSubset', // Tsa.BusinessLogic.Operators
  isSuperset: 'isSuperset',
  lessThan: 'lessThan', // Rsm.RulesEngine.Operators
  lessThanInclusive: 'lessThanInclusive', // Rsm.RulesEngine.Operators
  matches: 'matches', // Rsm.RulesEngine.Operators
  maxLength: 'maxLength', // Rsm.RulesEngine.Operators
  maxLengthInclusive: 'maxLengthInclusive', // Rsm.RulesEngine.Operators
  minLength: 'minLength', // Rsm.RulesEngine.Operators
  minLengthInclusive: 'minLengthInclusive', // Rsm.RulesEngine.Operators
  notDeepEmpty: 'notDeepEmpty', // Tsa.BusinessLogic.Operators
  notEqual: 'notEqual', // Rsm.RulesEngine.Operators
  notHasProperty: 'notHasProperty', // Tsa.BusinessLogic.Operators
  notIn: 'notIn', // not used
  number: 'number',
  required: 'required',
  validDate: 'validDate',
}

export default function addOperators(engine: Engine) {
  engine.addOperator(
    new Operator(operators.dateGreaterThanInclusive, dateGreaterThanInclusive)
  )
  engine.addOperator(
    new Operator(operators.dateLessThanInclusive, dateLessThanInclusive)
  )
  engine.addOperator(new Operator(operators.deepEmpty, isReallyEmpty))
  engine.addOperator(new Operator(operators.empty, isEmpty))
  engine.addOperator(new Operator(operators.equal, isEqual))
  engine.addOperator(
    new Operator(operators.hasProperty, hasProperty, isObjectNullOrUndefined)
  )
  engine.addOperator(new Operator(operators.isSubset, isSubset, Array.isArray))
  engine.addOperator(
    new Operator(operators.isSuperset, isSuperset, Array.isArray)
  )
  engine.addOperator(new Operator(operators.matches, matches, isString))
  engine.addOperator(
    new Operator(operators.maxLength, maxLength, hasLengthProperty)
  )
  engine.addOperator(
    new Operator(
      operators.maxLengthInclusive,
      maxLengthInclusive,
      hasLengthProperty
    )
  )
  engine.addOperator(
    new Operator(operators.minLength, minLength, hasLengthProperty)
  )
  engine.addOperator(
    new Operator(
      operators.minLengthInclusive,
      minLengthInclusive,
      hasLengthProperty
    )
  )
  engine.addOperator(new Operator(operators.notDeepEmpty, isNotReallyEmpty))
  engine.addOperator(new Operator(operators.notEqual, isNotEqual))
  engine.addOperator(
    new Operator(
      operators.notHasProperty,
      notHasProperty,
      isObjectNullOrUndefined
    )
  )
  engine.addOperator(new Operator(operators.number, isNumber))
  engine.addOperator(new Operator(operators.required, required))
  engine.addOperator(new Operator(operators.validDate, isValidDate))
}

export function isValidDate(value: any) {
  return (
    value === null ||
    value === undefined ||
    moment(value, moment.ISO_8601, true).isValid()
  )
}

export function dateGreaterThanInclusive(fact: any, value: any) {
  return (
    fact === undefined ||
    value === undefined ||
    moment(fact).isSameOrAfter(moment(value), 'day')
  )
}

export function dateLessThanInclusive(fact: any, value: any) {
  return (
    fact === undefined ||
    value === undefined ||
    moment(fact).isSameOrBefore(moment(value), 'day')
  )
}

function hasLengthProperty(value: any) {
  return (
    value !== null &&
    value !== undefined &&
    Object.prototype.hasOwnProperty.call(value, 'length')
  )
}

export function isEqual(fact: any, value: any) {
  // Arrays
  if (Array.isArray(fact) || Array.isArray(value)) {
    return (
      Array.isArray(fact) &&
      Array.isArray(value) &&
      fact.length === value.length &&
      fact.every(f => value.includes(f))
    )
  }

  // Objects : primitives
  return typeof value === 'object'
    ? deepIsEqual(fact, value)
    : isFalsey(fact)
    ? isFalsey(value)
    : fact === value
}

function isNotEqual(fact: any, value: any) {
  return !isEqual(fact, value)
}

function isNumber(value: any) {
  return !isNaN(parseFloat(value + ''))
}

function isString(value: any) {
  return typeof value === 'string'
}

const expressions = new Map<string, RegExp>()
function matches(fact: any, matchPattern: any) {
  matchPattern += ''
  let exp = expressions.get(matchPattern)
  if (!exp) {
    exp = new RegExp(matchPattern)
    expressions.set(matchPattern, exp)
  }
  const isMatch = exp.test(fact)
  return isMatch
}

function maxLength(fact: any, length: any) {
  return fact.length < length
}

function maxLengthInclusive(fact: any, length: any) {
  return fact.length <= length
}

function minLength(fact: any, length: any) {
  return fact.length > length
}

function minLengthInclusive(fact: any, length: any) {
  return fact.length >= length
}

export function required(fact: any) {
  return (
    fact !== undefined &&
    fact !== null &&
    (typeof fact !== 'string' || fact.trim() !== '')
  )
}

/**
 * Performs a strict equal (===) comparison of array elements by index.
 */
export function arrayEqual(fact: any, value: any) {
  if (!Array.isArray(fact) || !Array.isArray(value)) {
    return false
  }
  if (fact.length !== value.length) {
    return false
  }
  return fact.every(f => value.includes(f))
}

export function arrayNotEqual(fact: any, value: any) {
  return !arrayEqual(fact, value)
}

/**
 * Checks the fact for the existence of a property. If value
 * is an array the operator will return true if any of the specified
 * properties exist.
 */
export function hasProperty(fact: any, value: any): boolean {
  if (fact === undefined || fact === null) {
    return false
  }
  if (Array.isArray(value)) {
    return value.some(v => hasProperty(fact, v))
  }
  return {}.hasOwnProperty.call(fact, value)
}

/**
 * Checks the fact for the absence of a property. If value
 * is an array the operator will return true if the fact
 * has none of the specified properties.
 */
export function notHasProperty(fact: any, value: any) {
  return !hasProperty(fact, value)
}

/**
 * Returns true if all items in fact are contained
 * in value.
 */
export function isSubset(fact: any, value: any) {
  if (!Array.isArray(value)) {
    return false
  }
  return fact.every((f: any) => value.includes(f))
}

/**
 * Returns true if all items in value are contained
 * in fact.
 */
export function isSuperset(fact: any, value: any) {
  if (!Array.isArray(value)) {
    return false
  }
  return value.every((v: any) => fact.includes(v))
}

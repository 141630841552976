import { createReducer } from 'typesafe-actions'
import {
  DashboardEngagementActions,
  setDashboardEngagements,
} from '../actions/dashboardEngagementsActions'
import { Engagement } from '../clientModels'

const initialState: Engagement[] = []

export const dashboardEngagementReducer = createReducer<
  Engagement[],
  DashboardEngagementActions
>(initialState).handleAction(setDashboardEngagements, (state, action) => {
  return action.payload
})

import { get, groupBy, set } from 'lodash'
import { QuestionMap, StringLookup } from '../../clientModels'
import { sortByDisplayOrder } from '../../sorting'
import { EngagementTemplateField } from '../api/apiModels'
import { TsaJsonSchema } from './jsonSchema'

interface AnswerSchemaMap {
  [questionId: string]: TsaJsonSchema
  [questionId: number]: TsaJsonSchema
}

export function setAnswerSchemas (
  questions: QuestionMap,
  engagementTemplateFields: EngagementTemplateField[]
) {
  const answerSchemas = generateAnswerSchemas(engagementTemplateFields)

  for (const questionId in questions) {
    const question = questions[questionId]
    if (question) {
      question.answerSchema = answerSchemas[questionId] || {}
    }
  }
}

export function generateAnswerSchemas (
  fields: EngagementTemplateField[]
): AnswerSchemaMap {
  const schemas: AnswerSchemaMap = {}
  Object.entries(groupBy(fields, 'questionId')).forEach(
    ([questionId, questionFields]) => {
      schemas[questionId] = generateSchema(questionFields)
    }
  )
  return schemas
}

export function generateSchema (
  fields: EngagementTemplateField[]
): TsaJsonSchema {
  return fields.sort(sortByDisplayOrder).reduce(setSchema, {})
}

function setSchema (
  rootSchema: TsaJsonSchema,
  field: EngagementTemplateField
): TsaJsonSchema {
  const schemaPath = field.schemaPath || ''
  const fieldSchema = fieldToSchema(field)

  if (schemaPath) {
    const schema = get(rootSchema, schemaPath, {})
    Object.assign(schema, fieldSchema)
    return set(rootSchema, schemaPath, schema)
  }

  return Object.assign(rootSchema, fieldSchema)
}

const fieldToSchemaMap: StringLookup = {
  alignment: 'alignment',
  codeListId: 'codeListId',
  component: 'component',
  dataType: 'type',
  displayOrder: 'displayOrder',
  format: 'format',
  headerWidth: 'headerWidth',
  maxItems: 'maxItems',
  maxLength: 'maxLength',
  maxWidth: 'maxWidth',
  minItems: 'minItems',
  minValue: 'minValue',
  minWidth: 'minWidth',
  required: 'required',
  title: 'title',
  width: 'width',
}

// tslint:disable:no-any
function fieldToSchema (field: EngagementTemplateField): TsaJsonSchema {
  const schema: TsaJsonSchema = {}

  for (const fieldProperty in fieldToSchemaMap) {
    const propertyValue = field[fieldProperty]
    if (propertyValue !== null && propertyValue !== undefined) {
      const schemaProperty = fieldToSchemaMap[fieldProperty]
      schema[schemaProperty] = propertyValue
    }
  }

  if (field.itemLabels.length > 0) {
    schema.itemLabels = field.itemLabels
      .sort(sortByDisplayOrder)
      .map(i => i.label)
  }

  return schema
}

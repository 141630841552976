/* eslint-disable no-loop-func */
import React from 'react'
import { Link } from 'react-router-dom'
import { CchFieldTranslation } from '../../../clientModels'

/** Given an error message from submission to CCH, returns all CCH field values found in the message. */
export function getCchCodesFromMessage(message: string): string[] {
  // Pattern to detect the links.
  const cchFieldPattern = /[A-Z0-9]{6,}(\.\d+)?/g

  // Ultimate return value.
  const result = new Array<string>()

  // Get the first match to start the recognition.
  let curResult = cchFieldPattern.exec(message)
  while (curResult) {
    // Add this to the results.
    result.push(curResult[0])

    // Attempt the next match.
    curResult = cchFieldPattern.exec(message)
  }

  // Return the results.
  return result
}

/** Returns the paths to questions, for a specified field translation. */
export function createPathsFromFieldTranslation(
  field: CchFieldTranslation,
  engagementId: number
): { link: string; questionNumber: string }[] {
  return field.questionTranslations.map(q => ({
    link: `/engagements/${engagementId}/sections/${q.sectionId}/questions/${q.questionId}`,
    questionNumber: q.questionNumber
  }))
}

/** Given a CCH error message, parses the CCH fields and returns a react component wrapping the text and links for the fields. */
export function formatErrorMessage(
  message: string,
  engagementId: number,
  translations: CchFieldTranslation[]
) {
  // Return early, if we're missing anything.
  if (!message || !translations?.length) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: (message && message) || ''
        }}
      ></div>
    )
  }

  // Return list.
  const result = []

  // Pattern to find the CCH fields, and line breaks (<br/>).
  const fieldPattern = /((?<field>[A-Z0-9]{6,}(\.\d+)?)|(?<break><br\s*\/>))/g

  // Variables to hold the state of our parsing.
  let match: RegExpMatchArray | null = null
  let lastMatch: RegExpMatchArray | null = null
  while ((match = fieldPattern.exec(message)) !== null) {
    // Get the position of the end of the last match, if there was one.
    const lastMatchEnd = lastMatch ? lastMatch!.index! + lastMatch[0].length : 0

    // Get the location parameters of the current match, since we know we have them.
    //  (This simplifies compiler errors).
    const matchIndex = match!.index!

    // If the current match does not end at the last match, then we need
    //  to crete a span for the area between the last match and the current match.
    if (lastMatchEnd !== matchIndex) {
      result.push(
        <span data-testid='message-part' key={result.length + ''}>
          {message.substring(lastMatchEnd, matchIndex)}
        </span>
      )
    }

    if (match!.groups!['field']) {
      // Get the field translation for this cch field.
      const fieldTranslation = translations.find(
        t => t.cchFieldId === match![0]
      )

      // Skip this if we don't have a translation - we should, but who knows!
      if (fieldTranslation) {
        // Get the links for this field - if there are any.
        const links = createPathsFromFieldTranslation(
          fieldTranslation,
          engagementId
        )

        // Add a link for each question in the translation.  If we don't have any
        //  then we just want to add the CCH code back, so it looks right.
        if (links.length) {
          links.forEach(t => {
            result.push(
              <Link to={t.link} key={result.length + ''}>
                Question {t.questionNumber}
              </Link>
            )
          })
        } else {
          result.push(
            <span data-testid='message-part' key={result.length + ''}>
              CCH Field {fieldTranslation.cchFieldId}
            </span>
          )
        }
      } else {
        // Since we don't have a field translation, add the field as a span so we don't omit it from the message.
        result.push(
          <span data-testid='message-part' key={result.length + ''}>
            {match[0]}
          </span>
        )
      }
    } else {
      // Line break:
      result.push(<br key={result.length + ''} />)
    }

    lastMatch = match
  }

  // If we had no matches, or we had content after the last match, then we need to wrap the content.
  if (lastMatch == null) {
    result.push(
      <div data-testid='whole-message' key={result.length + ''}>
        {message}
      </div>
    )
  } else {
    // We only do this if the last match did NOT occur at the end of the message.
    if (lastMatch.index! + lastMatch[0].length !== message.length) {
      result.push(
        <span data-testid='message-part' key={result.length + ''}>
          {message.substr(lastMatch!.index! + lastMatch[0].length)}
        </span>
      )
    }
  }

  // Return the result, wrapped in a fragment.
  return <>{result}</>
}

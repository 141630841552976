import { Option, StringLookup } from '../../clientModels'

// tslint:disable-next-line:max-classes-per-file - should probably be an enum
class DocumentFilters implements StringLookup {
  [key: string]: string
  AllDocuments = 'None'
  AlreadyUploaded = 'Uploaded'
  NeedUpload = 'Need to Upload'
}

// tslint:disable-next-line:max-classes-per-file - should probably be an enum
class DocumentSorts implements StringLookup {
  [key: string]: string
  NonRequiredDocs = 'Not Required First'
  RequiredDocs = 'Required First'
  SectionTask = 'Section/Task'
}

const DefaultDocumentFilter: keyof DocumentFilters = 'AllDocuments'
const DocumentFilterOptions: Option[] = []
const docFilters = new DocumentFilters()

for (const prop in docFilters) {
  DocumentFilterOptions.push({
    value: prop,
    label: docFilters[prop],
  })
}

const DefaultDocumentSort: keyof DocumentSorts = 'SectionTask'
const DocumentSortOptions: Option[] = []
const docSorts = new DocumentSorts()

for (const prop in docSorts) {
  DocumentSortOptions.push({
    value: prop,
    label: docSorts[prop],
  })
}

export {
  DefaultDocumentFilter,
  DefaultDocumentSort,
  DocumentFilterOptions,
  DocumentFilters,
  DocumentSortOptions,
  DocumentSorts,
}

import { useEffect } from 'react'

function matchesSelector (target: any, cssSelector?: string) {
  return (
    cssSelector === undefined || (target.matches && target.matches(cssSelector))
  )
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOnClickOutside (
  ref: any,
  callback: () => void,
  cssSelector?: string
) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside (event: any) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      matchesSelector(event.target, cssSelector)
    ) {
      callback()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { actions } from '../../actions'
import {
  setFileUploadFileGroup,
  setFileUploadDialogClose,
} from '../../actions/fileActions'
import {
  DocumentTitle,
  File,
  FileGroup,
  IdentityTokenProfile,
  Message,
} from '../../clientModels'
import { AppState, TsaDispatch } from '../../store'
import './fileUploadDialog.scss'
import { FileUploadDialogFirstPhase } from './fileUploadDialogFirstPhase'
import {
  addHandler,
  checkDisabled,
  CheckValid,
  deleteHandler,
  dropHandler,
  processFileGroupFiles,
} from './fileUploadDialogHelpers'
import { FileUploadDialogSecondPhase } from './fileUploadDialogSecondPhase'
import { defaultTagsbyDocumentTitle } from '../../services/fileHelpers'

const MAX_FILES = 100
const MAX_FILE_SIZE = 100 * 1024 * 1024

interface FileUploadDialogOwnProps {
  maxFileSize?: number
  questionId: number
  engagementId: number
}

interface FileUploadDialogMappedProps {
  maxFileCount?: number
  acceptedFileTypes?: string
  acceptedFileTypeExtensions?: string
  disabled: boolean
  documentTitle?: DocumentTitle
  fileGroup?: FileGroup
  isTemplate?: boolean
  openDialog?: boolean
  user?: IdentityTokenProfile
  replace?: number
}

interface FileUploadDialogDispatchProps {
  addOrUpdateFileGroup: (group: FileGroup, isTemplate?: boolean) => void
  onClose: () => void
  setFileUploadFileGroup: (fileGroup: FileGroup) => void
}

type FileUploadDialogProps = FileUploadDialogOwnProps &
  FileUploadDialogMappedProps &
  FileUploadDialogDispatchProps

function mapStateToProps (state: AppState): FileUploadDialogMappedProps {
  const isTemplate = state.fileUpload.isTemplate
  const user = state.auth.user
  return {
    acceptedFileTypes: isTemplate
      ? state.general.approvedTemplateMimeTypes
      : state.general.approvedMimeTypes,
    acceptedFileTypeExtensions: isTemplate
      ? state.general.approvedTemplateMimeTypeExtensions
      : state.general.approvedMimeTypeExtensions,
    disabled: state.http.loading.includes(actions.file.FileUploadBusyIndicator),
    ...state.fileUpload,
    user,
  }
}

function mapDispatchToProps (
  dispatch: TsaDispatch,
  props: FileUploadDialogOwnProps
): FileUploadDialogDispatchProps {
  return {
    addOrUpdateFileGroup: (group: FileGroup, isTemplate?: boolean) =>
      dispatch(
        actions.file.addOrUpdateFileGroup(
          props.engagementId,
          props.questionId,
          group,
          isTemplate
        )
      ),
    onClose: () => dispatch(setFileUploadDialogClose({ open: false })),
    setFileUploadFileGroup: (fileGroup: FileGroup) =>
      dispatch(setFileUploadFileGroup({ fileGroup })),
  }
}

function FileUploadDialog ({
  engagementId,
  questionId,
  maxFileCount = MAX_FILES,
  maxFileSize = MAX_FILE_SIZE,
  acceptedFileTypes,
  acceptedFileTypeExtensions,
  disabled,
  documentTitle,
  fileGroup,
  isTemplate,
  user,
  addOrUpdateFileGroup,
  openDialog,
  onClose,
  setFileUploadFileGroup,
  replace,
}: FileUploadDialogProps) {
  const [files, setFiles] = useState([] as File[])
  const [displayFiles, setDisplayFiles] = useState([] as File[])
  const [disabledUpload, setDisabledUpload] = useState(disabled)
  const [messages, setMessages] = useState([] as Message[])
  const [needsSecondPhase, setNeedsSecondPhase] = useState(false)
  const [phase, setPhase] = useState(1)

  const propFiles = fileGroup && fileGroup.files
  const replacing = replace !== undefined && replace !== -1
  const defaultTags =
    documentTitle && defaultTagsbyDocumentTitle(documentTitle.id)

  if (user && !user.isExternal) {
    maxFileSize = 1024 * 1024 * 1024
  }

  useEffect(() => {
    setFiles(processFileGroupFiles(propFiles, replace))
  }, [propFiles, replace])

  useEffect(() => {
    setDisplayFiles(
      files.filter(f => (f.id < 0 || f.isUpdated) && !f.isDeleted)
    )
  }, [files])

  useEffect(() => {
    setDisabledUpload(checkDisabled(files, disabled, maxFileCount))
  }, [files, disabled, maxFileCount])

  const handleDrop = useMemo(
    () =>
      dropHandler(
        maxFileSize,
        maxFileCount,
        acceptedFileTypes,
        files,
        user,
        setMessages,
        setFiles,
        setNeedsSecondPhase,
        replace,
        defaultTags
      ),
    [
      maxFileSize,
      maxFileCount,
      acceptedFileTypes,
      files,
      user,
      replace,
      defaultTags,
    ]
  )

  const handleDelete = useMemo(() => deleteHandler(files, setFiles), [files])

  const handleClick = useMemo(
    () =>
      addHandler(
        engagementId,
        questionId,
        isTemplate,
        documentTitle,
        fileGroup,
        files,
        phase,
        needsSecondPhase,
        setPhase,
        setNeedsSecondPhase,
        setMessages,
        addOrUpdateFileGroup,
        setFileUploadFileGroup
      ),
    [
      engagementId,
      questionId,
      isTemplate,
      documentTitle,
      fileGroup,
      files,
      phase,
      needsSecondPhase,
      setPhase,
      setNeedsSecondPhase,
      setMessages,
      addOrUpdateFileGroup,
      setFileUploadFileGroup,
    ]
  )

  function handleClose () {
    setFiles(processFileGroupFiles(propFiles, replace))
    setPhase(1)
    setNeedsSecondPhase(false)
    setMessages([])
    onClose()
  }

  if (!CheckValid(engagementId, questionId, documentTitle, openDialog)) {
    return <div />
  }

  switch (phase) {
    case 2:
      return (
        <FileUploadDialogSecondPhase
          disabledButton={disabled || displayFiles.length <= 0}
          files={displayFiles}
          handleAdd={handleClick}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      )
    default:
      return (
        <FileUploadDialogFirstPhase
          maxFileCount={maxFileCount}
          maxFileSize={maxFileSize}
          acceptedFileTypeExtensions={acceptedFileTypeExtensions}
          acceptedFileTypes={acceptedFileTypes}
          disabledUpload={disabledUpload}
          disabledButton={disabled || displayFiles.length <= 0}
          files={displayFiles}
          messages={messages}
          handleAdd={handleClick}
          handleClose={handleClose}
          handleDelete={handleDelete}
          handleUpload={handleDrop}
          replacing={replacing}
        />
      )
  }
}

export const ConnectedFileUploadDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadDialog)

import { TsaHubAction } from '../actions'
import * as etlActions from '../actions/etlActions'
import {
  EntityMap,
  EtlScreen,
  EtlTemplate,
  Option,
  LoadStatus,
  EtlScreenMap,
  ClientEntity,
} from '../clientModels'
import { getType } from 'typesafe-actions'
import { ValidationInfo } from '../components/configureImport/helpers'
import { ColumnFilters, EntityMapping } from '../services/api/apiModels'

const defaultEtlScreenMap: EtlScreenMap<LoadStatus> = {
  categories: LoadStatus.notLoaded,
  define: LoadStatus.notLoaded,
  entities: LoadStatus.notLoaded,
  format: LoadStatus.notLoaded,
  initial: LoadStatus.notLoaded,
  pivot: LoadStatus.notLoaded,
  review: LoadStatus.notLoaded,
  scan: LoadStatus.notLoaded,
  structure: LoadStatus.notLoaded,
  taxCodes: LoadStatus.notLoaded,
}

export interface EtlProcessState {
  entityMappings: EntityMapping[]
  entityOptions: ClientEntity[]
  etlScreenLoaded: EtlScreenMap<LoadStatus>
  etlTemplate: EtlTemplate | null
  excludeMappedData: boolean
  filterValues: ColumnFilters | null
  loadingTaxCodeOptions: boolean
  pivotColumnOptions: Option[]
  pivotData: string[][]
  pivotRowOptions: Option[]
  step: EtlScreen
  taxCodeFilters: ColumnFilters
  validation: EntityMap<ValidationInfo>
}

const initialState: EtlProcessState = {
  entityMappings: [],
  entityOptions: [],
  etlScreenLoaded: defaultEtlScreenMap,
  etlTemplate: null,
  excludeMappedData: false,
  filterValues: {},
  loadingTaxCodeOptions: false,
  pivotColumnOptions: [],
  pivotData: [],
  pivotRowOptions: [],
  step: 'structure',
  taxCodeFilters: {},
  validation: {},
}

export function etlProcessReducer(
  state: EtlProcessState = initialState,
  action: TsaHubAction
): EtlProcessState {
  switch (action.type) {
    case getType(etlActions.getEntityOptionsActions.request): {
      return {
        ...state,
      }
    }

    case getType(etlActions.getEntityOptionsActions.success): {
      return {
        ...state,
        entityOptions: action.payload.entityOptions,
      }
    }

    case getType(etlActions.getEntityOptionsActions.failure): {
      return {
        ...state,
      }
    }

    case getType(etlActions.setEntityMapAction): {
      return {
        ...state,
        entityMappings: action.payload,
      }
    }

    default:
      return state
  }
}

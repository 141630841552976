import * as React from 'react'
import { Engagement, IdentityTokenProfile } from '../../clientModels'

interface EngagementSummaryMessageProps {
  engagement: Engagement
  user?: IdentityTokenProfile
}

export const EngagementEtlMessage = ({
  engagement,
  user,
  ...props
}: EngagementSummaryMessageProps) => {
  const EtlIntegrationStatus =
    engagement.taxIntegrationStatuses &&
    engagement.taxIntegrationStatuses
      .filter(x => x.type === 'ETLImport')
      .sort((x, y) => y.id - x.id)[0]

  if (!user?.isExternal) {
    if (EtlIntegrationStatus && EtlIntegrationStatus.status === 'IN PROGRESS') {
      return <div className='loading'>ETL IMPORT IN PROGRESS</div>
    } else if (
      EtlIntegrationStatus &&
      EtlIntegrationStatus.status === 'SUCCESS'
    ) {
      return (
        <div className='submission-success'>
          <div className='submission-success-heading'>
            ORGANIZER DATA RETRIEVED
          </div>
        </div>
      )
    } else if (
      EtlIntegrationStatus &&
      EtlIntegrationStatus.status === 'ERROR'
    ) {
      return (
        <div className='submission-failed'>
          <div className='submission-failed-heading'>
            UNABLE TO RETRIEVE ORGANIZER DATA
          </div>
          <div
            className='submission-failed-message'
            dangerouslySetInnerHTML={{
              __html:
                (EtlIntegrationStatus && EtlIntegrationStatus.message) || '',
            }}
          />
        </div>
      )
    }
  }
  return null
}

import {
  EngagementTemplate,
  Question,
  SearchResult,
  Section,
  Option,
} from '../clientModels'
import { isDefined, ensureNumber } from '../guards'
import { sortByDisplayOrder } from '../sorting'
import { AppState } from '../store'

/**
 * Matches basic markdown:
 *   - Tags e.g. <a href="">  </a>
 *   - Markdown hyperlinks
 *   - Bullets
 *   - Block quotes
 *   - Bold
 *   - Italics
 */
const markdown = /((<([^>]+)>)|(]\([^)]+\))|\[|_|\*\s?|>)/g

export function searchResults(
  searchString: string,
  template: EngagementTemplate,
  state: AppState,
  engagementId: number
) {
  const engagementSections = state.engagementSections[engagementId]
  if (!engagementSections) {
    throw new Error('Engagement sections not found.')
  }

  const engagementQuestions = state.engagementQuestions[engagementId]
  if (!engagementQuestions) {
    throw new Error('Engagement questions not found.')
  }

  const options = state.options

  // Match the search string with up to 50 characters before and after
  searchString = `([\\s\\S]{1,50})?(${searchString.replace(
    /[#-.]|[[-^]|[?|{}]/g,
    '\\$&'
  )})([\\s\\S]{1,50})?`
  const expr = new RegExp(searchString, 'im')

  const results: SearchResult[] = []
  const search = (
    entity: Section | Question,
    property: string,
    stripMarkdown = false
  ) => {
    let value = entity[property]
    if (stripMarkdown && value) {
      value = value.replace(markdown, '')
    }
    if (value) {
      const match = expr.exec(value)
      if (match) {
        const type = isSection(entity) ? 'section' : 'question'
        results.push({
          type,
          id: entity.id,
          property,
          match,
          displayOrder: entity.displayOrder,
        })
      }
    }
  }

  const searchOption = (
    entity: Option,
    question: Question,
    stripMarkdown = false
  ) => {
    let value = entity.label
    if (stripMarkdown && value) {
      value = value.replace(markdown, '')
    }
    if (value) {
      const match = expr.exec(value)
      if (match) {
        const type = 'question'
        const property = 'text' // use same as a question
        results.push({
          type,
          id: question.id,
          property,
          match,
          displayOrder: question.displayOrder,
        })
      }
    }
  }
  
  const user = state.auth.user
  const isExternalUser = state.permissions.isExternalUser
  
  // Search section titles
  template.sections
    .map(sectionId => state.sections[sectionId])
    .filter(isDefined)
    .forEach(s => {
      if (s.isInternalVisibleOnly) {
        if (user?.isExternal || isExternalUser) {
          return
        }
      }
      const es = engagementSections[s.id]
      if (es && !es.isVisible) {
        return
      }
      search(s, 'number')
      search(s, 'title')
    })

  // Search question text and question help text
  template.questions
    .map(questionId => state.questions[questionId])
    .filter(isDefined)
    .forEach(q => {
      if (user?.isExternal || isExternalUser) {
        if (q.isInternalVisibleOnly) {
            return
          }
        else if(state.sections[q.sectionId]?.isInternalVisibleOnly){
              return
          }
      }
      const engagementQuestion = engagementQuestions[q.id]
      if (engagementQuestion && !engagementQuestion.isVisible) {
        return
      }
      if (q.answerSchema.codeListId) {
        // get the options for the codelist
        const codeList =
          options.options[
            options.codeListIdToOptionsId[
              ensureNumber(q.answerSchema.codeListId)
            ]
          ]
        if (codeList) {
          codeList.forEach(o => searchOption(o, q))
        }
      }

      search(q, 'displayNumber')
      search(q, 'text')
      search(q, 'help', true)
    })

  return results.sort(sortByDisplayOrder)
}

function isSection(entity: Section | Question): entity is Section {
  // tslint:disable-next-line:no-string-literal
  return !!entity.questions
}

import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { downloadTaxFormFile } from '../../actions/fileThunks'
import { actions } from '../../actions/index'
import {
  EngagementUploadHistory,
  EngagementUploadHistoryStatus,
} from '../../clientModels'
import { Datasheet } from '../../components/datasheet/index'
import { FileUploadQuestionless } from '../../components/fileUpload/index'
import Icon from '../../components/icon/icon'
import { TsaJsonSchema } from '../../services/answerSchema/index'
import { AppState, TsaDispatch } from '../../store'
import './setup.scss'

interface SetupState {
  isLoading: boolean
  showFileUpload: boolean
}

interface SetupMappedProps {
  histories: EngagementUploadHistory[]
}

interface SetupDispatchProps {
  downloadFile: (fileId: number) => void
  downloadSetupTemplate: () => void
  getEngagementUploadHistories: () => Promise<void>
}

const mapDispatchToProps = (dispatch: TsaDispatch): SetupDispatchProps => ({
  downloadFile: (fileId: number) => dispatch(actions.file.downloadFile(fileId)),
  downloadSetupTemplate: () =>
    dispatch(
      downloadTaxFormFile('GLOBAL', 9999, 'EngagementManagementTemplate.xlsx')
    ),
  getEngagementUploadHistories: () =>
    dispatch(actions.engagementUploadHistory.getEngagementUploadHistories()),
})

const mapStateToProps = (state: AppState): SetupMappedProps => {
  return { histories: state.engagementUploadHistory }
}

type SetupProps = SetupDispatchProps & SetupMappedProps & SetupState

class Setup extends React.Component<SetupProps> {
  createdBy?: string
  createdDate?: Date
  datasheetSchema: TsaJsonSchema = {
    items: {
      properties: {
        engagementId: {
          minWidth: '5rem',
          title: 'ID',
          type: 'string',
        },
        engagementTaxForm: {
          minWidth: '8rem',
          title: 'Engagement',
          type: 'string',
        },
        message: {
          minWidth: '30rem',
          title: 'Message',
          type: 'string',
        },
        rowNumber: {
          minWidth: '5rem',
          title: 'Row #',
          type: 'string',
        },
        status: {
          minWidth: '10rem',
          title: 'Status',
          type: 'string',
        },
      },
      type: 'object',
    },
    type: 'array',
  }

  fileId?: number
  fileName?: string
  lastChangesDescription?: string
  state: SetupState = { isLoading: true, showFileUpload: false }
  statuses: EngagementUploadHistoryStatus[] = []

  constructor(props: SetupProps) {
    super(props)
    this.datasheetSchema.maxItems = 10000 // Arbitrary to avoid random errors
    this.props.getEngagementUploadHistories.bind(this)
    this.props.getEngagementUploadHistories().then(() =>
      this.setState({
        isLoading: false,
        showFileUpload: this.state.showFileUpload,
      })
    )
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: SetupProps) {
    const firstHistory = nextProps.histories[0]
    if (!firstHistory) {
      return
    }

    this.createdBy = firstHistory.createdBy
    this.createdDate = firstHistory.createdDate
    this.fileName = firstHistory.file ? firstHistory.file.name : ''
    this.lastChangesDescription = firstHistory.file
      ? firstHistory.file.description || ''
      : ''

    if (firstHistory.fileId !== this.fileId) {
      this.fileId = firstHistory.fileId
    }
    this.statuses = firstHistory.engagementUploadHistoryStatuses
    // this.datasheetSchema.maxItems = (this.statuses = firstHistory.engagementUploadHistoryStatuses).length
  }

  onCloseFileUpload = () => {
    this.setState({ showFileUpload: false })
  }

  onDownloadClick = () => {
    this.props.downloadFile(this.fileId || 0)
  }

  onDownloadTemplateClick = () => {
    this.props.downloadSetupTemplate()
  }

  onUploadClick = () => {
    this.setState({ showFileUpload: !this.state.showFileUpload })
  }

  render() {
    return (
      <div className='d-flex flex-row setup'>
        <div className='content-container d-flex flex-column'>
          {this.state.isLoading && <Icon icon='loading' />}
          {!this.state.isLoading && (
            <div className='content d-flex flex-column'>
              <span className='heading'>Current engagements template:</span>
              <div className='summary-header flex-grow-0 flex-shrink-0'>
                <div className='summary-header-text d-flex flex-column'>
                  <span className='header-text-blue'>
                    <button
                      className='engagement-management-link'
                      onClick={this.onDownloadTemplateClick}
                    >
                      {' '}
                      Engagement Management Template
                    </button>
                  </span>
                  {this.fileName && (
                    <span>
                      <button
                        className='summary-link'
                        onClick={this.onDownloadClick}
                      >
                        {this.fileName}
                      </button>
                    </span>
                  )}
                  {this.lastChangesDescription && (
                    <span>{this.lastChangesDescription}</span>
                  )}
                  {this.createdBy && this.createdDate && (
                    <span>
                      Last edited by {this.createdBy} on{' '}
                      {moment(this.createdDate).format('M/D/YY') +
                        ' at ' +
                        moment(this.createdDate).format('h:mm a')}
                    </span>
                  )}
                </div>
                <div className='summary-header-icons'>
                  <Icon
                    icon='download'
                    onClick={this.onDownloadTemplateClick}
                    tooltip='Download template and fill out your information'
                  />
                  <Icon
                    icon='upload'
                    onClick={this.onUploadClick}
                    className='summary-icon'
                    tooltip='Upload document'
                  />
                </div>
              </div>
              {!!this.statuses.length && (
                <Datasheet
                  jsonSchema={this.datasheetSchema}
                  value={this.statuses}
                  hideExtraRow={true}
                />
              )}
            </div>
          )}
        </div>
        {this.state.showFileUpload && (
          <div className='sidebar'>
            <FileUploadQuestionless onClose={this.onCloseFileUpload} />
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Setup)

/*
 * Removes the first appearance of the item to remove from an array. Similar to lodash's "without,"
 * except that it removes just the first instance of the item from the array instead of all instances of the item from the array,
 * which is not always desired
 */
// tslint:disable-next-line:no-any
export function withoutOnce<T>(array: T[], replace?: T): T[] {
  let newArray = [...array]
  const index = newArray.findIndex(i => i === replace)
  if (index > -1) {
    newArray.splice(index, 1)
  }

  return [...newArray]
}

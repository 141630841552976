import * as React from 'react'
import { OptionsFormFieldProps } from './formComponentsInterfaces'

/**
 * Used as a filler for a questions that don't require any form inputs.
 * e.g.) File template upload may not require any form inputs.
 */
export class EmptyForm extends React.Component<OptionsFormFieldProps> {
  componentDidMount () {
    const { value, onChange, onBlur, disabled } = this.props

    // Since the empty form component doesn't have any user interface
    // we'll just create an empty placeholder answer when we mount
    if (onChange && onBlur && value === null) {
      if (!disabled) {
        onChange({})
        onBlur()
      }
    }
  }

  render () {
    return null
  }
}

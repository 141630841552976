import { Engagement, IdentityTokenProfile } from '../../clientModels'
import { MilestoneCode } from '../../enums'

export interface MilestoneInfo {
  code: string
  caption: string
  doneCaption: string | null
  displayOrder: number
  clientVisible: boolean
}

export const isExternalUser = (user?: IdentityTokenProfile) => {
  return !!user && user.isExternal
}

export const getNextMilestoneDue = (
  milestoneDetails: MilestoneInfo[],
  engagement?: Engagement,
  user?: IdentityTokenProfile
) => {
  const engagementMilestones = (engagement && engagement.milestones) || []
  const dueMilestones = engagementMilestones.filter(x => {
    const m = milestoneDetails.find(y => y.code === x.milestone)
    return (
      x.dueDate &&
      !x.completedDate &&
      (!isExternalUser(user) || (m && m.clientVisible))
    )
  })
  const nextMilestoneDue = dueMilestones.reduce((em1, em2) => {
    const m1 = milestoneDetails.find(x => x.code === em1.milestone)
    const m2 = milestoneDetails.find(x => x.code === em2.milestone)
    return m1 && m2 && m2.displayOrder < m1.displayOrder ? em2 : em1
  }, dueMilestones[0])

  return nextMilestoneDue
}

export const getClientDueDatePhrase = (
  milestones: MilestoneInfo[],
  engagement?: Engagement,
  user?: IdentityTokenProfile
) => {
  const nextMilestoneDue = getNextMilestoneDue(milestones, engagement, user)
  let phrase = ''

  if (engagement && nextMilestoneDue) {
    const milestone = nextMilestoneDue.milestone

    switch (milestone) {
      case MilestoneCode.Review:
        phrase = 'REVIEW DUE'
        break
      case MilestoneCode.CCH:
        phrase = 'SUBMISSION DUE'
        break
      case MilestoneCode.IRS:
        phrase = 'FILING DUE'
        break
      default:
        phrase = 'TASKS DUE'
        break
    }
  }

  return phrase
}

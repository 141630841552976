import React from 'react'
import { createStyles, Theme } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'
import clsx from 'clsx'

const styles = ({ palette }: Theme) =>
  createStyles({
    background: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      zIndex: 10000,
      paddingTop: '100px',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: palette.common.black,
      opacity: 0.4,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    modal: {
      backgroundColor: palette.common.white,
      position: 'fixed',
      borderRadius: '15px',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      padding: '24px',
      border: '1px solid #888',
      width: '85%',
      maxWidth: '600px',
      justifyContent: 'space-between',
      opacity: 1.0,
      zIndex: 10001,
      top: '35%',
    },
    modalCentered: {
      alignItems: 'center',
      textAlign: 'center',
    },
    dialog: {
      fontSize: '28.8px',
      lineHeight: 1.4,
      whiteSpace: 'pre-wrap',
      color: palette.common.black,
    },
    dialogCentered: {
      maxWidth: '90%',
      textAlign: 'center',
    },
    close: {
      color: palette.blue.main,
      position: 'absolute',
      right: '16px',
      top: '-6px',
      fontSize: '38px',
      fontWeight: 'bold',
      '&:hover': {
        color: '#000',
        textDecoration: 'none',
        cursor: 'pointer',
      },
      '&:focus': {
        color: '#000',
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
  })

export interface ModalProps {
  children: React.ReactNode
  shown: boolean
  onClose: () => void
  alignment?: 'left' | 'center'
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ComponentContent = ({
  children,
  shown,
  onClose,
  alignment,
}: ModalProps) => {
  const classes = useStyles()

  const modalClassName =
    alignment === 'left'
      ? classes.modal
      : clsx(classes.modal, classes.modalCentered)
  const dialogClassName =
    alignment === 'left'
      ? classes.dialog
      : clsx(classes.dialog, classes.dialogCentered)

  if (shown) {
    return (
      <div className={classes.container}>
        <div className={classes.background} />
        <div className={modalClassName}>
          <span className={classes.close} onClick={onClose}>
            &times;
          </span>
          <div className={dialogClassName}>{children}</div>
        </div>
      </div>
    )
  }
  return null
}

export const Modal = ComponentContent

import * as React from 'react'
import ComboBox, { ComboBoxProps } from './combobox'
import './combobox.scss'

type ComboBoxCreateableProps = Omit<ComboBoxProps, 'allowUserInput'>

const ComboBoxCreateable = (props: ComboBoxCreateableProps) => {
  return <ComboBox {...props} allowUserInput={true} />
}
export default ComboBoxCreateable

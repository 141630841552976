import { Moment } from 'moment'
import { SidebarNames, K1Header } from '../clientModels'
import { CommentFilters, CommentSorts } from '../components/commentLibrary'
import {
  DocumentFilters,
  DocumentSorts,
} from '../components/documentLibrary/index'
import {
  DashboardDateFilters,
  DashboardStatusFilters,
  DashboardTypeFilters,
} from '../components/engagementsListHeader'
import { createAction, ActionType } from 'typesafe-actions'
import { DashboardYearFilters } from '../components/engagementsListHeader/dashboardFilters'

export const selectionSidebarCollapseAction = createAction(
  'SIDEBAR_COLLAPSE'
)<{}>()

export const selectionSidebarRestoreAction = createAction(
  'SIDEBAR_RESTORE'
)<{}>()

export const selectionSidebarSelectAction = createAction('SIDEBAR_SELECT')<{
  selectedSidebarName: SidebarNames
  toggle: boolean
}>()

export const selectionHelpToggleShowMoreAction = createAction(
  'HELP_TOGGLE_SHOW_MORE'
)<{}>()

export const selectionSelectDocumentFilterAction = createAction(
  'SET_DOCUMENT_FILTER'
)<{ filter?: keyof DocumentFilters }>()

export const selectionSelectStatusFilterAction = createAction(
  'SET_STATUS_FILTER'
)<{ filter?: string }>()

export const selectionSelectStateFilterAction = createAction(
  'SET_STATE_FILTER'
)<{ filter?: string }>()

export const selectionGetFilteredK1sAction = createAction('GET_FILTERED_K1S')<{
  k1s: K1Header[]
}>()

export const selectionSelectStatusFilterListAction = createAction(
  'GET_STATUS_FILTER_LIST'
)<{ list?: string[] }>()

export const selectionSelectK1sListAction = createAction(
  'SET_K1S_SELECTED_LIST'
)<{ list?: number[] }>()

export const selectionSelectDashboardSortAction = createAction(
  'SET_DASHBOARD_SORT'
)<{ sort?: string | number }>()

export const selectionSelectDocumentSortAction = createAction(
  'SET_DOCUMENT_SORT'
)<{ sort?: keyof DocumentSorts }>()

export const selectionSelectCommentFilterAction = createAction(
  'SET_COMMENT_FILTER'
)<{ filter: keyof CommentFilters }>()

export const selectionSelectCommentSortAction = createAction(
  'SET_COMMENT_SORT'
)<{ sort: keyof CommentSorts }>()

export const selectionAddDashboardDateFilterAction = createAction(
  'ADD_DASHBOARD_DATE_FILTER'
)<{ filter: keyof DashboardDateFilters; date?: Moment }>()

export const selectionAddDashboardYearFilterAction = createAction(
  'ADD_DASHBOARD_YEAR_FILTER'
)<{ filter: keyof DashboardYearFilters }>()

export const selectionAddDashboardStatusFilterAction = createAction(
  'ADD_DASHBOARD_STATUS_FILTER'
)<{ filter: keyof DashboardStatusFilters }>()

export const selectionAddDashboardTypeFilterAction = createAction(
  'ADD_DASHBOARD_TYPE_FILTER'
)<{ filter: keyof DashboardTypeFilters }>()

export const selectionClearDashboardDateFilterAction = createAction(
  'CLEAR_DASHBOARD_DATE_FILTER'
)<{ filter: keyof DashboardDateFilters }>()

export const selectionClearDashboardYearFilterAction = createAction(
  'CLEAR_DASHBOARD_YEAR_FILTER'
)<{ filter: keyof DashboardYearFilters }>()

export const selectionClearDashboardStatusFilterAction = createAction(
  'CLEAR_DASHBOARD_STATUS_FILTER'
)<{ filter: keyof DashboardStatusFilters }>()

export const selectionClearDashboardTypeFilterAction = createAction(
  'CLEAR_DASHBOARD_TYPE_FILTER'
)<{ filter: keyof DashboardTypeFilters }>()

export const selectionClearAllDashboardFiltersAction = createAction(
  'CLEAR_ALL_DASHBOARD_FILTERS'
)<{}>()

export type SelectionActions =
  | ActionType<typeof selectionSidebarCollapseAction>
  | ActionType<typeof selectionSidebarRestoreAction>
  | ActionType<typeof selectionSidebarSelectAction>
  | ActionType<typeof selectionHelpToggleShowMoreAction>
  | ActionType<typeof selectionSelectDocumentFilterAction>
  | ActionType<typeof selectionSelectStatusFilterAction>
  | ActionType<typeof selectionSelectStateFilterAction>
  | ActionType<typeof selectionGetFilteredK1sAction>
  | ActionType<typeof selectionSelectStatusFilterListAction>
  | ActionType<typeof selectionSelectK1sListAction>
  | ActionType<typeof selectionSelectDashboardSortAction>
  | ActionType<typeof selectionSelectDocumentSortAction>
  | ActionType<typeof selectionSelectCommentFilterAction>
  | ActionType<typeof selectionSelectCommentSortAction>
  | ActionType<typeof selectionAddDashboardDateFilterAction>
  | ActionType<typeof selectionAddDashboardYearFilterAction>
  | ActionType<typeof selectionAddDashboardStatusFilterAction>
  | ActionType<typeof selectionAddDashboardTypeFilterAction>
  | ActionType<typeof selectionClearDashboardDateFilterAction>
  | ActionType<typeof selectionClearDashboardYearFilterAction>
  | ActionType<typeof selectionClearDashboardStatusFilterAction>
  | ActionType<typeof selectionClearDashboardTypeFilterAction>
  | ActionType<typeof selectionClearAllDashboardFiltersAction>

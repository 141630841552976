import * as React from 'react'
import { Question } from '../../clientModels'
import Icon from '../icon/icon'
import Modal from './modal'

interface CriticalErrorDialogProps {
  question: Question
  onClickOk: () => void
}

class CriticalErrorDialog extends React.Component<CriticalErrorDialogProps> {
  modalPrimaryButton?: HTMLButtonElement

  componentDidMount () {
    if (this.modalPrimaryButton) {
      this.modalPrimaryButton.focus()
    }
  }

  setModalPrimaryButtonRef = (ref: HTMLButtonElement) =>
    (this.modalPrimaryButton = ref)

  render () {
    return (
      <Modal>
        <div className='modal critical'>
          <div className='modal-body'>
            <div className='modal-icon'>
              <Icon icon='timesCircle' />
            </div>
            <div className='modal-title'>{`You provided an answer to question ${
              this.props.question.displayNumber
            } that impacts your entire engagement.`}</div>
            <div className='modal-info'>
              We will send an email to your RSM contact to contact you regarding
              next steps. It is not recommended to continue filling information
              out on this form.
            </div>
            <div className='modal-buttons'>
              <button
                id='modal-primary-button'
                ref={this.setModalPrimaryButtonRef}
                className='btn btn-primary'
                onClick={this.props.onClickOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default CriticalErrorDialog

import { get, isEqual } from 'lodash'
import { IEngagementQuestionAnswer, PropertyValues } from '../clientModels'
import { ensureNumber, safeAnswerLength } from '../guards'
import * as Selectors from '../reducers/selectors'
import { AnswersApi } from '../services/api'
import { trackSave } from '../services/track'
import { TsaThunkAction } from '../store'
import {
  buildClientAnswer,
  registerFlagChange,
  registerValueChange,
  updateServerAnswer,
} from './answerActionHelpers'
import {
  answerGetAction,
  answerSaveAction,
  answerSetNotApplicableAction,
  answerUpdateAction,
  answerUpdatePartialAnswerAction,
} from './answerActions'
import { answerUpdateClientReview } from './reviewThunks'
import { ruleClearMessagesAction } from './rulesActions'
import { runEngagementLevelRules, runQuestionRules } from './rulesThunks'

export function setNotApplicable(
  engagementId: number | string,
  questionId: number | string,
  notApplicable: boolean
): TsaThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const question = Selectors.getQuestionByQuestionId(state, questionId)
    if (!question || !question.allowNotApplicable) {
      throw new Error(`Question (${questionId}) does not allow not applicable`)
    }
    dispatch(
      answerSetNotApplicableAction({ engagementId, questionId, notApplicable })
    )
    // Now we need to check and set client review to be true.
    dispatch(
      answerUpdateClientReview(
        ensureNumber(engagementId),
        ensureNumber(questionId)
      )
    )
    await dispatch(runQuestionRules(engagementId, questionId))
    await dispatch(runEngagementLevelRules(ensureNumber(engagementId)))

    return dispatch(
      updateMetadata(
        ensureNumber(engagementId),
        ensureNumber(questionId),
        'NotApplicable',
        notApplicable
      )
    )
  }
}

export const saveAnswer = (
  engagementId: number | string,
  questionId: number | string,
  id?: string,
  skipRules: boolean = false
): TsaThunkAction => async (dispatch, getState) => {
  engagementId = ensureNumber(engagementId)

  const state = getState()
  const engagementQuestions = state.engagementQuestions
  const engagementQuestion = engagementQuestions[engagementId] || {}
  const answer = engagementQuestion[questionId]
  const engagement = state.engagements[engagementId]

  if (!answer) {
    throw new Error(
      `Answer (${engagementId} - ${questionId}) not found. You must have an existing answer, before it can be saved.`
    )
  }

  if (!engagement) {
    throw new Error(
      `Engagement (${engagementId}) not found. You must load the engagement before you attempt to add or update an answer.`
    )
  }

  registerValueChange(engagementId, answer.questionId, !skipRules)

  await trackSave(
    updateServerAnswer,
    dispatch,
    id || Math.random().toString(),
    dispatch,
    getState
  )

  dispatch(answerSaveAction.request({ engagementId, answer }))
}

export const answerPartialChanged = (
  engagementId: number,
  questionId: number,
  properties: PropertyValues,
  skipRules?: boolean,
  runTableRules?: boolean
): TsaThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState()

  const engagement = state.engagements[engagementId]
  if (!engagement) {
    throw new Error(
      `Engagement (${engagementId}) not found. You must load the engagement before you attempt to add or update an answer.`
    )
  }

  const question = state.questions[questionId]
  const answerSchema = question && question.answerSchema
  const objectType = answerSchema && answerSchema.type

  if (objectType !== 'array' && objectType !== 'object') {
    throw new Error(
      "Cannot partially update answer that's not an object or an array"
    )
  }

  const answer = Selectors.selectAnswer(state, engagementId, questionId)

  if (answer && answer.notApplicable) {
    throw new Error(
      'Cannot change the value of an answer that is not applicable'
    )
  }

  dispatch(
    answerUpdatePartialAnswerAction({
      engagementId,
      questionId,
      valueType: objectType,
      properties,
      updateDirtyFlag: true,
    })
  )
  dispatch(answerUpdateClientReview(engagementId, questionId))

  if (!skipRules) {
    await dispatch(runQuestionRules(engagementId, questionId, properties))
  }

  if (runTableRules) {
    const tableProperty: PropertyValues = {
      '[-1]': null,
    }
    await dispatch(runQuestionRules(engagementId, questionId, tableProperty))
  }
}

// tslint:disable-next-line:no-any - answer value is not typed
export const answerChanged = (
  engagementId: number,
  questionId: number,
  value: any
): TsaThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState()
  const answer = Selectors.selectAnswer(state, engagementId, questionId)
  if (answer && answer.notApplicable) {
    throw new Error(
      'Cannot change the value of an answer that is not applicable'
    )
  }

  dispatch(
    answerUpdateAction({
      engagementId,
      questionId,
      value,
      updateDirtyFlag: true,
    })
  )
  dispatch(answerUpdateClientReview(engagementId, questionId))
  await dispatch(runQuestionRules(engagementId, questionId))
}

export const getAnswer = (
  engagementId: number,
  questionId: number
): TsaThunkAction => async (dispatch, getState) => {
  dispatch(answerGetAction.request({ engagementId, questionId }))
  const state = getState()
  const engagementAnswers = state.engagementQuestions[engagementId]
  const previousAnswer = engagementAnswers
    ? engagementAnswers[questionId]
    : undefined
  const answer = await AnswersApi.apiGetAnswer(engagementId, questionId)
  const clientAnswer = answer
    ? buildClientAnswer(answer)
    : defaultClientAnswer(engagementId, questionId)

  dispatch(
    answerGetAction.success({ answer: clientAnswer, engagementId, questionId })
  )

  const array: number[] = []
  const previousLength = safeAnswerLength(previousAnswer)
  const currentLength = safeAnswerLength(clientAnswer)
  for (let i = currentLength; i < previousLength; ++i) {
    array.push(i)
  }

  dispatch(ruleClearMessagesAction({ engagementId, questionId, rows: array }))
  await dispatch(runQuestionRules(engagementId, questionId))
}

// tslint:disable-next-line:no-any - answer value is not typed
export const setDefaultAnswer = (
  engagementId: number,
  questionId: number,
  path: string,
  value: any
): TsaThunkAction => async (dispatch, getState) => {
  const state = getState()
  const question = state.questions[questionId]
  const answerSchema = question && question.answerSchema
  const objectType = answerSchema && answerSchema.type
  const answers = state.engagementQuestions[engagementId]
  const answer = answers && answers[questionId]
  const currentValue = answer && answer.answerValue

  if (objectType === 'array' || objectType === 'object') {
    if (path && !get(currentValue, path)) {
      // This is a partial answer update. Set one property at the specified path if
      // it is not already set.
      dispatch(
        answerUpdatePartialAnswerAction({
          engagementId,
          questionId,
          valueType: objectType,
          properties: { [path]: value },
          updateDirtyFlag: false,
        })
      )
    } else if (!currentValue) {
      // This is a full answer value for the specified question. Set the value if
      // it is not already set.
      dispatch(
        answerUpdateAction({
          engagementId,
          questionId,
          value,
          updateDirtyFlag: false,
        })
      )
    }
  } else {
    if (path) {
      throw new Error(
        `Attempting to set default value for path '${path}' on question ${questionId} that is not expecting an object or array. Is your answer schema invalid?`
      )
    }
    if (!answer || !isEqual(answer.answerValue, value)) {
      dispatch(
        answerUpdateAction({
          engagementId,
          questionId,
          value,
          updateDirtyFlag: false,
        })
      )
    }
  }
}

function defaultClientAnswer(
  engagementId: number,
  questionId: number
): IEngagementQuestionAnswer {
  const defaultAns: IEngagementQuestionAnswer = {
    answerId: -1,
    answerValue: null,
    answerValueLastYear: null,
    engagementId,
    flagged: undefined,
    isDirty: undefined,
    isPristine: undefined,
    notApplicable: undefined,
    active: true,
    questionId,
    reviewRolesComplete: new Set(),
    clientVersion: 0,
    clientFlagVersions: {
      flagged: 0,
      notApplicable: 0,
      reviewRoles: {},
    },
    userId: undefined,
    answerVersion: 0,
  }

  return defaultAns
}

export const updateMetadata = (
  engagementId: number,
  questionId: number,
  flagType: string,
  value: boolean
): TsaThunkAction => async (dispatch, getState) => {
  registerFlagChange(engagementId, questionId, flagType, value)
  return updateServerAnswer(dispatch, getState)
}

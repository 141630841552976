import { usePermissionManager } from '../../hooks'
import { UserPermissions } from '../../enums'

interface PermissionContentProps {
  children: JSX.Element | undefined | null | false
  permission: UserPermissions
}

function PermissionContent({ children, permission }: PermissionContentProps) {
  const { hasPermission } = usePermissionManager()

  if (!children) {
    children = null
  }
  return hasPermission(permission) ? (children as JSX.Element | null) : null
}

export default PermissionContent

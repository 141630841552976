import { Dispatch } from 'redux'
import {
  addUpdateFileGroupTemplateFailure,
  updateFile,
} from '../actions/fileActions'
import { getK1StatusActions } from '../actions/inputProcessActions'
import { File, K1FileStatus } from '../clientModels'
import { AppState } from '../store'
import { SignalRListener } from './middleware'

const SignalRMessageListener: SignalRListener = {
  message: 'UploadFileCompleteCallback',
  type: 'on',
  callback: function (
    getState: () => AppState,
    dispatch: Dispatch<any>,
    file: File
  ) {
    dispatch(updateFile.success(file))
    if (file.status === 'Error') {
      dispatch(addUpdateFileGroupTemplateFailure(new Error(file.errorStatus)))
    }
  },
}

const SignalRK1StatusListener: SignalRListener = {
  message: 'UpdateStatusesCompleteCallback',
  type: 'on',
  callback: function (
    getState: () => AppState,
    dispatch: Dispatch<any>,
    status: K1FileStatus
  ) {
    dispatch(getK1StatusActions.success(status))
  },
}

export const listeners: SignalRListener[] = [
  SignalRMessageListener,
  SignalRK1StatusListener
]

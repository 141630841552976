import classNames from 'classnames'
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnswerWatch } from '../../actions/answerActions'
import { CircularProgressIndicator } from '../../components/circularProgressIndicator'
import { PhaseCode } from '../../enums'
import { ensureNumber } from '../../guards'
import { useForceUpdate } from '../../hooks'
import { engagementLockedPhase } from '../../services/engagementPhaseService'
import { formatDateForDisplay } from '../../services/formatters/formatters'
import { AppState } from '../../store'
import Icon from '../icon/icon'
import Lock from '../icon/Lock'
import './progressSave.scss'

interface ProgressSaveOwnProps {
  engagementId: string
}

export const ProgressSave = (props: ProgressSaveOwnProps) => {
  const forceUpdate = useForceUpdate()
  const engagement = useSelector(
    (state: AppState) => state.engagements[ensureNumber(props.engagementId)]
  )
  const totalQuestions = (engagement && engagement.totalVisibleQuestions) || 0
  const successfulForUserQuestions =
    (engagement && engagement.successfulForUserQuestions) || 0
  const lastSaved = useSelector((state: AppState) => state.http.lastSaved)
  const isSaving = useSelector((state: AppState) =>
    state.http.loading.includes(AnswerWatch)
  )
  const { isExternalUser } = useSelector((state: AppState) => state.permissions)
  const [refreshInterval, setRefreshInterval] = useState<any>()

  const calculatePercentComplete = () => {
    return Math.floor((successfulForUserQuestions / totalQuestions) * 100)
  }

  const [percentComplete, setPercentComplete] = useState<number>(
    calculatePercentComplete()
  )

  useEffect(() => {
    // Mount
    setRefreshInterval(setInterval(() => forceUpdate(), 15000))
    // Unmount
    return () => {
      clearInterval(refreshInterval)
    }
  }, [])

  useEffect(() => {
    setPercentComplete(calculatePercentComplete())
  }, [totalQuestions, successfulForUserQuestions])

  const engagementComplete = percentComplete >= 100
  const engagementInReview = engagement && engagement.phase === PhaseCode.Review
  const showDoneButton = engagementComplete && !engagementInReview

  const progressIndicator = () => {
    if (isExternalUser && engagementInReview) {
      return <Icon icon='checkedCircle' className='under-review' />
    } else if (engagementComplete) {
      return <Icon icon='checkedCircle' />
    }

    return <CircularProgressIndicator percentage={percentComplete} />
  }

  const progressMessage = () => {
    if (isExternalUser && engagementInReview) {
      return 'Project submitted & under review'
    } else if (engagementComplete) {
      return 'All questions complete'
    }
    return `${successfulForUserQuestions} of ${totalQuestions} questions completed`
  }
  const lastSavedMessage = () => {
    const lastSavedMessage = lastSaved
      ? `Auto-saved ${formatDateForDisplay(lastSaved)}.`
      : 'Progress will save automatically'
    return lastSavedMessage
  }
  const dashboardUrl = `/engagements/${engagement && engagement.id}`

  if (!engagement || !engagement.loaded) {
    return null
  }

  const locked =
    engagement.isEnabled !== undefined
      ? engagement.isEnabled
      : engagementLockedPhase(engagement)
  const hideLock = !locked && isExternalUser

  return (
    <div className='progress-save d-none d-lg-flex'>
      <div className='progress-save-icon'>
        {progressIndicator()}
        <span className={classNames('badge', { hide: hideLock })}>
          <Lock
            active={locked}
            tooltip={locked ? 'Engagement locked' : 'Engagement unlocked'}
          />
        </span>
      </div>
      {isSaving && <Icon icon='saving' />}
      {!isSaving && (
        <div>
          <div className='last-saved-message'>{lastSavedMessage()}</div>
          {totalQuestions > 0 && (
            <div className='progress-message'>
              {progressMessage()} &nbsp;{' '}
              {showDoneButton && (
                <Link
                  to={dashboardUrl}
                  key={dashboardUrl}
                  className='progress-done-button'
                >
                  Done
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ProgressSave

import { ClientSearch } from '../clientModels'
import { createAction, ActionType as TypedAction } from 'typesafe-actions'

export const clientSearchAction = createAction('CLIENT_SEARCH')<{
  searchString: string
  results: ClientSearch[]
}>()

export const clientSearchFailAction = createAction('CLIENT_SEARCH_FAIL')<{
  error: Error
}>()

export const clientSearchClearAction = createAction('CLIENT_SEARCH_CLEAR')<{}>()

export const clientSearchClearResultsAction = createAction(
  'CLIENT_SEARCH_CLEAR_RESULTS'
)<{ searchString: string }>()

export const clientSearchSelectResultAction = createAction(
  'CLIENT_SEARCH_SELECT_RESULT'
)<{ selectedSearchResult: ClientSearch }>()

export type ClientSearchActions =
  | TypedAction<typeof clientSearchAction>
  | TypedAction<typeof clientSearchFailAction>
  | TypedAction<typeof clientSearchClearAction>
  | TypedAction<typeof clientSearchClearResultsAction>
  | TypedAction<typeof clientSearchSelectResultAction>
import moment from 'moment'

export function formatDateForDisplay (
  dateToFormat: string | Date | moment.Moment
): string {
  const startOfDay = moment().startOf('day')
  let dateMoment: moment.Moment

  if (typeof dateToFormat === 'string') {
    dateMoment = moment(dateToFormat, moment.ISO_8601)
  } else if (moment.isDate(dateToFormat)) {
    dateMoment = moment(dateToFormat)
  } else {
    dateMoment = dateToFormat
  }

  if (startOfDay.isBefore(dateMoment)) {
    return dateMoment.fromNow()
  }

  const startOfYesterday = moment()
    .subtract(1, 'day')
    .startOf('day')
  if (startOfYesterday.isBefore(dateMoment)) {
    return 'Yesterday ' + dateMoment.format('h:mm a')
  }

  return dateMoment.format('M/D/YY h:mm a')
}

export function formatNumber (value: string) {
  // Strip commas, currency symbols and fractions
  value =
    value &&
    value
      .replace(/,/g, '')
      .replace(/^\$(.*)/, '$1')
      .replace(/\.\d{2}$/, '')
  return /^[+-]?\d+$/.test(value)
    ? value.replace(/,/g, '').replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    : value
}

import {
  Activity,
  EngagementQuestion,
  EntityMap,
  Question,
  Section,
} from '../../clientModels'
import { ensureNumber } from '../../guards'
import { EngagementQuestionsState } from '../../reducers/engagementQuestionsReducer'
import { Moment } from 'moment'

// #region Interfaces
// #region Private Interfaces
interface StateSnapshot {
  questions: EntityMap<Question>
  sections: EntityMap<Section>
  engagementQuestions: EngagementQuestionsState
}

interface CommentResponseRequired {
  responseRequiredByRSM: boolean
  responseRequiredByClient: boolean
}

interface CommentActivities {
  [rootActivityId: number]: CommentActivity | undefined
}
// #endregion

// #region Public Interfaces
export interface CommentActivity extends CommentResponseRequired {
  carriedForward: boolean
  displayOrder: number
  engagementQuestion?: EngagementQuestion
  newestChild: Activity
  parent: Activity
  question?: Question
  section?: Section
  createdByInternalUser: boolean
  createdDate: Moment
}
// #endregion
// #endregion

// #region Selectors
function getQuestionByQuestionId(
  state: StateSnapshot,
  questionId: number
): Question | undefined {
  return state.questions[questionId]
}

function getSectionBySectionId(
  state: StateSnapshot,
  sectionId: number
): Section | undefined {
  return state.sections[sectionId]
}

function getEngagementQuestion(
  state: StateSnapshot,
  engagementId: number,
  questionId: number
): EngagementQuestion | undefined {
  return (
    state.engagementQuestions[engagementId] &&
    state.engagementQuestions[engagementId]![questionId]
  )
}
// #endregion

function checkMentions(
  activity: Activity,
  current: CommentResponseRequired
): CommentResponseRequired {
  const result: CommentResponseRequired = { ...current }

  if (activity.mentions) {
    for (const mention of activity.mentions) {
      let isInternal = false
      let value = mention.value || ''
      if (value.match(/[A-Za-z]/) && !value.match(/-/)) {
        isInternal = true
      }
      if (isInternal) {
        result.responseRequiredByRSM =
          result.responseRequiredByRSM || !mention.resolved
      } else {
        result.responseRequiredByClient =
          result.responseRequiredByClient || !mention.resolved
      }
    }
  }

  return result
}

function initializeCommentActivity(
  state: StateSnapshot,
  activity: Activity,
  result: CommentActivities,
  currentPosition: number
) {
  if (activity.questionId) {
    const newActivity: CommentActivity = {
      newestChild: activity,
      parent: activity,
      question: getQuestionByQuestionId(state, activity.questionId),
      engagementQuestion: getEngagementQuestion(
        state,
        activity.engagementId,
        activity.questionId
      ),
      carriedForward: false,
      displayOrder: currentPosition,
      ...checkMentions(activity, {
        responseRequiredByRSM: false,
        responseRequiredByClient: false,
      }),
      createdByInternalUser: activity.createdByInternalUser ? true : false,
      createdDate: activity.createdDate,
    }

    if (newActivity.question) {
      newActivity.section = getSectionBySectionId(
        state,
        newActivity.question.sectionId
      )
    }

    result[activity.id] = newActivity
  }
}

function updateChildActivity(
  activity: Activity,
  result: CommentActivities,
  currentPosition: number
) {
  if (!activity.parentId) {
    // invalid call for a root comment
    return
  }
  const root = result[activity.parentId]
  if (!root) {
    // parent doesn't exist
    return
  }

  root.newestChild = activity
  root.displayOrder = currentPosition
}

function markAllCarryForward(result: CommentActivities) {
  for (const rootActivityId in result) {
    const commentActivity = result[rootActivityId] as CommentActivity
    commentActivity.carriedForward = true
  }
}

function markCarryForward(activity: Activity, result: CommentActivities): void {
  if (!activity.parentId) {
    // carrying nothing forward
    return
  }
  const commentActivity = result[activity.parentId]
  if (!commentActivity) {
    // not carrying forward a comment
    return
  }
  commentActivity.carriedForward = true
}

function processQuestion(
  state: StateSnapshot,
  activities: Activity[],
  result: CommentActivities
): void {
  let carryAllForward = false
  let currentPosition = 0
  for (const activity of activities) {
    if (activity.activityTypeCode === 'CarryForwardAllComments') {
      carryAllForward = true
      continue
    }

    if (activity.activityTypeCode === 'CarryForward') {
      markCarryForward(activity, result)
      continue
    }

    if (activity.activityTypeCode !== 'Comment') {
      // only interested in comments
      continue
    }

    if (!activity.parentId) {
      initializeCommentActivity(state, activity, result, currentPosition)
    } else {
      updateChildActivity(activity, result, currentPosition)
    }

    ++currentPosition
  }

  if (carryAllForward) {
    markAllCarryForward(result)
  }
}

export function retrieveCommentActivities(
  activities: EntityMap<Activity[]>,
  questions: EntityMap<Question>,
  sections: EntityMap<Section>,
  engagementQuestions: EngagementQuestionsState
): CommentActivity[] {
  const lookup: CommentActivities = {}
  const stateSnapshot: StateSnapshot = {
    questions,
    sections,
    engagementQuestions,
  }

  for (const questionIdString in activities) {
    const questionId = ensureNumber(questionIdString)
    const questionActivities = activities[questionIdString] as Activity[]

    if (questionId === 0 || questionActivities.length === 0) {
      // activities are misconfigured, skip invalid question id
      continue
    }
    const engagementQuestion = engagementQuestions[
      questionActivities[0].engagementId
    ]![questionActivities[0].questionId || 0]
    if (engagementQuestion === undefined || !engagementQuestion!.isVisible) {
      // engagementQuestion is properly defined, then check for isVisible attribute.
      // engagementQuestion may not be properly defined when Activity includes BypasPBCPhase.
      continue
    }
    processQuestion(stateSnapshot, questionActivities, lookup)
  }

  // convert the lookup into an array of comment activities
  const result: CommentActivity[] = []
  for (const questionId in lookup) {
    // no need to actually check if it exists, because we are only retrieving properties that actually exist on the object
    const commentActivity = lookup[questionId] as CommentActivity
    result.push(commentActivity)
  }

  return result
}

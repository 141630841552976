/** 
  Code within this shim comes from the README within the node package for react-onclickoutside (node_modules\react-onclickoutside\README.md), adapted for tsx.
  The third-party React component react-onclickoutside was not written to be wholly compatible with IE11. node_modules\react-onclickoutside\README.md
  
  From the README:
  "Note that this HOC [react-onclickoutside] relies on the `.classList` property, which is supported by
  all modern browsers, but not by deprecated and obsolete browsers like IE (noting
  that Microsoft Edge is not Microsoft Internet Explorer. Edge does not have any
  problems with the `classList` property for SVG elements)."

*/

export function IE11_react_onclickoutside() {
  if (!('classList' in SVGElement.prototype)) {
    Object.defineProperty(SVGElement.prototype, 'classList', {
      get() {
        return {
          contains: (className: any) => {
            return this.className.baseVal.split(' ').indexOf(className) !== -1
          },
        }
      },
    })
  }
}

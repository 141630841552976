import * as React from 'react'
import FAIcons from './FAIcon'
import Icon from './icon'
import { IconProperties } from './properties'

const Warning = FAIcons('Warning')

const NotificationSent: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <div className={className} onClick={onClick} data-tip={tooltip}>
      <Icon icon='notification' className={className} />
      <svg
        viewBox='0 0 31 31'
        height='1em'
        width='1em'
        className={className + ' badge exclamationColor'}
        focusable='false'
      >
        <circle cx='15.5' cy='15.5' r='15.5' />
      </svg>
      <Warning className={className + ' badge'} />
    </div>
  )
}

export default NotificationSent

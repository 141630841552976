import { useParams } from 'react-router'
import { ensureNumber } from '../guards'

export interface RouteParams {
  masterClientId?: number
  formName?: string
  taxYear?: string
  engagementId?: number
}

// The RouteParams type, with all values a string (which is what comes from the params).
type RouteParamsInternal = Record<keyof RouteParams, string>

export const useRouteParams = () => {
  // Get the results from the URL.
  const internalResults = useParams<RouteParamsInternal>()

  // Reconstruct with proper typings.  Set the values from the
  //  internalResults, and update the properties that need it:
  const result = { ...(internalResults as any) } as RouteParams

  if (internalResults.engagementId) {
    result.engagementId = ensureNumber(internalResults.engagementId)
  }

  if (internalResults.masterClientId) {
    result.masterClientId = ensureNumber(internalResults.masterClientId)
  }

  // Return the results.
  return result
}

import { Activity, EngagementQuestionServerData } from '../clientModels'
import { PhaseCode } from '../enums'
import { ensureNumber } from '../guards'
import { selectAnswer } from '../reducers/selectors'
import { ActivitiesApi } from '../services/api'
import { mapApiActivity, mapClientActivity } from '../services/map'
import { trackGet, trackSave } from '../services/track'
import { TsaThunkAction } from '../store'
import {
  deleteActivityAction,
  getActivitiesAction,
  getAllActivitiesAction,
  saveActivityAction,
} from './activityActions'
import { saveAnswer } from './answerThunks'
import { getEngagementQuestionsDoneAction } from './engagementActions'
import { toggleFlagged } from './flagsThunks'
import { normalizeActivities } from './normalization'
import { runQuestionRules } from './rulesThunks'
import { saveEngagement } from './engagementThunks'

export const getAllActivities = (
  engagementId: number,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  const state = getState()

  try {
    dispatch(getAllActivitiesAction.request(engagementId))
    let mostRecent: number | undefined =
      state.activities.mostRecentDeletedActivity
    const engagementActivities = state.activities[engagementId]
    if (engagementActivities) {
      for (const questionId in engagementActivities) {
        const activities = engagementActivities[questionId]
        if (!activities) {
          continue
        }
        for (const activity of activities) {
          if (
            activity.id > 0 &&
            (!mostRecent || mostRecent < activity.activityVersion)
          ) {
            mostRecent = activity.activityVersion
          }
        }
      }
    }
    const apiActivities = await trackGet(
      ActivitiesApi.apiGetAllActivities,
      dispatch,
      id,
      engagementId,
      mostRecent ? mostRecent.toString() : undefined
    )
    const activities = normalizeActivities(apiActivities)
    const actionToReturn = await dispatch(
      getAllActivitiesAction.success({
        activities,
        engagementId,
        user: state.auth.user,
      })
    )
    if (apiActivities.length > 0) {
      await dispatch(generateCommentsCount(engagementId))
    }
    return actionToReturn
  } catch (error) {
    return dispatch(getAllActivitiesAction.failure(error))
  }
}

export const generateCommentsCount = (
  engagementId: number
): TsaThunkAction => async (dispatch, getState) => {
  const state = getState()
  const activities = state.activities[engagementId]
  const user = state.auth.user
  const engagementQuestionComments: EngagementQuestionServerData[] = []
  if (activities && user) {
    for (const questionId in activities) {
      const questionActivities = activities[questionId]
      const questionComments = {
        questionId: ensureNumber(questionId),
        commentsCount: 0,
      }
      if (questionActivities) {
        for (const activity of questionActivities) {
          if (activity.activityTypeCode !== 'Comment' || !activity.mentions) {
            continue
          }
          for (const mention of activity.mentions) {
            if (mention.resolved) {
              continue
            }
            const atRSM = mention.label === 'RSM'
            if ((user.isExternal && !atRSM) || (!user.isExternal && atRSM)) {
              ++questionComments.commentsCount
            }
          }
        }
      }
      engagementQuestionComments.push(questionComments)
    }
  }
  return dispatch(
    getEngagementQuestionsDoneAction({
      engagementId,
      engagementQuestions: engagementQuestionComments,
    })
  )
}

export const getActivities = (
  engagementId: number,
  questionId: number,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  const state = getState()
  if (!state.engagements[engagementId]) {
    // Wait for the engagement to load before getting activities
    return
  }

  try {
    dispatch(getActivitiesAction.request())
    const actionToReturn = await dispatch(getAllActivities(engagementId, id))

    const engagementAnswers = state.engagementQuestions[engagementId]
    if (engagementAnswers) {
      const currentAnswer = engagementAnswers[questionId]
      const currentQuestion = state.questions[questionId]
      if (currentAnswer && currentQuestion && currentAnswer.answerValue) {
        dispatch(runQuestionRules(engagementId, questionId))
      }
    }

    return actionToReturn
  } catch (error) {
    return dispatch(getActivitiesAction.failure(error))
  }
}

export const deleteActivity = (
  engagementId: number | string | undefined,
  activityId: number,
  id?: string
): TsaThunkAction => async dispatch => {
  try {
    const eId = ensureNumber(engagementId)
    dispatch(deleteActivityAction.request(activityId))

    await ActivitiesApi.apiDeleteActivity(eId, activityId)

    await dispatch(getAllActivities(eId, id))
    dispatch(saveEngagement(eId))

    return dispatch(deleteActivityAction.success(activityId))
  } catch (error) {
    return dispatch(getActivitiesAction.failure(error))
  }
}

const questionLockedPhases = [
  PhaseCode.Review,
  PhaseCode.CCH,
  PhaseCode.IRS,
  undefined,
]

export const saveActivity = (
  activity: Activity,
  saveId?: string
): TsaThunkAction => async (dispatch, getState) => {
  const state = getState()

  try {
    const user = state.auth.user
    const engagementId = activity.engagementId
    const questionId = activity.questionId

    const engagement = state.engagements[engagementId]
    if (!engagement) {
      throw new Error('Engagement not found.')
    }

    const apiActivity = mapClientActivity(activity)
    apiActivity.firstName = user && user.firstName
    apiActivity.lastName = user && user.lastName

    dispatch(saveActivityAction.request(apiActivity))

    const savedActivity = await trackSave(
      ActivitiesApi.apiSaveActivity,
      dispatch,
      saveId,
      apiActivity
    )

    dispatch(saveActivityAction.success(mapApiActivity(savedActivity)))
    dispatch(saveEngagement(engagementId))

    if (questionId && apiActivity.questionId) {
      const lockedByPhase = questionLockedPhases.includes(engagement.phase)
      const mentionsClient =
        activity.mentions && activity.mentions.some(m => m.label !== 'RSM')
      const answer = selectAnswer(state, engagementId, questionId)

      if (lockedByPhase && mentionsClient && answer) {
        // If this question is currently disabled for external customers and we mention the client
        // we must clear all review tickmarks and flag the question.

        if (answer.reviewRolesComplete.size > 0) {
          answer.reviewRolesComplete.clear()
          await dispatch(saveAnswer(engagementId, answer.questionId, saveId))
        }

        if (!answer.flagged) {
          await dispatch(
            toggleFlagged(activity.engagementId, activity.questionId)
          )
        } else {
          dispatch(
            getActivities(apiActivity.engagementId, apiActivity.questionId)
          )
        }
      } else {
        dispatch(
          getActivities(apiActivity.engagementId, apiActivity.questionId)
        )
      }
    }
  } catch (error) {
    dispatch(saveActivityAction.failure(error))
  }
}

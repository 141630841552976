import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'abortcontroller-polyfill'
import 'element-remove'
import './localDebug'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import AppContainer from './appContainer'

// Styles for primereact.
import 'primereact/resources/themes/arya-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './prime-styles.scss'

ReactDOM.render(<AppContainer />, document.getElementById('root'))

import * as React from 'react'
import { Route, Switch } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { AssistanceHeader } from '../../components/HelpAssistance'
import { GlossaryHeader } from '../../components/HelpGlossary'
import { WhatsNewHeader } from '../../components/whatsNew'
import './help.scss'

interface OwnProperties extends RouteComponentProps<{}> {}

class HelpHeader extends React.Component<OwnProperties> {
  render() {
    if (window.__SIMULATE_ERROR__ === 'content') {
      throw new Error('Content (help) simulated error')
    }

    return (
      <div className='help-header'>
        <Switch>
          <Route
            key='glossary'
            path='/help/glossary'
            component={GlossaryHeader}
          />
          <Route
            key='assistance'
            path='/help'
            component={AssistanceHeader}
            exact={true}
          />
          <Route
            key='whatsnew'
            path='/help/whatsnew'
            component={WhatsNewHeader}
            exact={true}
          />
        </Switch>
      </div>
    )
  }
}

export default HelpHeader

import './engagementSectionList.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { EngagementSectionNavItem } from '../../clientModels'
import { EngagementSectionGroup } from '../engagementSectionGroup'
import { SectionDisplayOrderMultiplier } from '../../actions/normalization'

export interface EngagementSectionListProps {
  sectionNavItems: (EngagementSectionNavItem | null)[]
  selectedSectionId: string | undefined
  showAllSections: boolean
}

/*
  Displays engagement sections either as a simple list of links, or a list of section 'groups' with nested links
*/

const EngagementSectionList = ({
  sectionNavItems,
  selectedSectionId,
  showAllSections
}: EngagementSectionListProps) => {
  const { sectionGroups } = useSelector(
    (state: AppState) => state.engagementSectionGroups
  )

  let listContent = sectionNavItems.map(item => item && item.navItem)

  // If section groups exist, create groups with nested sections
  if (sectionGroups?.length) {
    const groups = sectionGroups.map(group => {
      // Get the nav items/sections for this group
      let sectionGroupNavItems = sectionNavItems.filter(navItem => {
        if (navItem) {
          /**
            For some reason the display order property on each section has been mutated to be the original value * X - see normalization.ts, normalizeEngagementTemplate fn.
            We need the original values in order to determine which sections are in each group
          */
          const actualOrder =
            navItem.displayOrder / SectionDisplayOrderMultiplier
          return (
            actualOrder >= group.startSectionIndex &&
            actualOrder <= group.endSectionIndex
          )
        }
      })

      return (
        <div key={group.id} className='engagement-section-list'>
          <EngagementSectionGroup
            groupLabel={group.label}
            sectionNavItems={sectionGroupNavItems}
            selectedSectionId={selectedSectionId}
            showAllSections={showAllSections}
          />
        </div>
      )
    })
    listContent = groups
  }

  return <>{listContent}</>
}

export default EngagementSectionList

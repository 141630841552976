import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { actions } from '../../actions'
import { ensureNumber } from '../../guards'
import './engagementsList.scss'

interface RouteParams {
  entityGroupId?: string
}

const useRouteParams = () => {
  const { entityGroupId } = useParams<RouteParams>()
  return {
    entityGroupId: entityGroupId ? ensureNumber(entityGroupId) : undefined
  }
}

function EngagementsListHeader() {
  const location = useLocation()
  const isClientId = location.pathname.indexOf('clientresults') > -1
  const { entityGroupId } = useRouteParams()
  const dispatch = useDispatch()

  useEffect(() => {
    // this was moved from engagement list and supports scenarios in which
    // the /engagement route is rendered with no client group parameter
    // or engagement parameter
    if (!entityGroupId) {
      dispatch(actions.engagement.getEngagementList(entityGroupId, isClientId))
    }
  }, [entityGroupId, dispatch])

  return (
    <div className='engagements-list-header flex-wrap'>
      <div className='title'>My Engagements</div>
    </div>
  )
}

export default EngagementsListHeader

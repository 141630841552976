import * as http from '../http'
import { buildApiUrl } from './apiUrl'
import { IdentityTokenProfile } from '../../clientModels'

export function apiUpdateAssignments(userProfile: IdentityTokenProfile) {
  const payload = {
    userFirstName: userProfile.firstName || '',
    userLastName: userProfile.lastName || '',
    userEmail: userProfile.email || '',
  }

  return http.post(
    buildApiUrl('general/UpdateAssignments'),
    payload,
    undefined,
    undefined,
    300
  )
}

import CheckedCircle from './CheckedCircle'
import CircleEllipsis from './CircleEllipsis'
import Comments from './Comments'
import Dashboard from './Dashboard'
import Documents from './Documents'
import DownArrow from './DownArrow'
import faIconHOC from './FAIcon'
import Flags from './Flags'
import ioIconHOC from './IoIcon'
import Loading from './Loading'
import { Logo } from './logo'
import mdIconHOC from './MdIcon'
import NA from './NA'
import None from './None'
import NotificationError from './NotificationError'
import NotificationPending from './NotificationPending'
import NotificationSent from './NotificationSent'
import CCH from './cch'

export class Icons {
  asterisk = faIconHOC('Asterisk')
  attachment = mdIconHOC('Attachment')
  bell = faIconHOC('Bell')
  carryForward = ioIconHOC('CarryForward')
  caretDown = faIconHOC('CaretDown')
  caretRight = faIconHOC('CaretRight')
  carryForwardAll = faIconHOC('CarryForwardAll')
  cch = CCH
  check = faIconHOC('Check')
  checkedCircle = CheckedCircle
  circleBigCheck = faIconHOC('CheckCircle')
  circleEllipsis = CircleEllipsis
  circleLeft = faIconHOC('CircleLeft')
  circleNotch = faIconHOC('CircleNotch')
  circleRight = faIconHOC('CircleRight')
  clock = faIconHOC('Clock')
  close = ioIconHOC('Close')
  cog = faIconHOC('Cog')
  comment = faIconHOC('Comment')
  comments = Comments
  copy = faIconHOC('Copy')
  dashboard = Dashboard
  database = faIconHOC('Database')
  delete = faIconHOC('Delete')
  deleteAlt = faIconHOC('DeleteAlt')
  document = Documents
  documents = Documents
  downArrow = DownArrow
  download = faIconHOC('Download')
  edit = ioIconHOC('Edit')
  editSquare = faIconHOC('Edit')
  error = faIconHOC('Error')
  externalLink = faIconHOC('ExternalLink')
  eye = faIconHOC('Eye')
  fileDownload = faIconHOC('FileDownload')
  fileText = ioIconHOC('FileText')
  fileAlt = faIconHOC('FileAlt')
  filter = faIconHOC('Filter')
  flag = faIconHOC('Flag')
  flags = Flags
  heart = ioIconHOC('Heart')
  infoCircle = faIconHOC('InfoCircle')
  isClosed = faIconHOC('IsClosed')
  isOpened = faIconHOC('IsOpened')
  loading = Loading
  lock = faIconHOC('Lock')
  logo = Logo
  na = NA
  none = None
  notification = faIconHOC('Notification')
  notificationError = NotificationError
  notificationPending = NotificationPending
  notificationSent = NotificationSent
  openContext = faIconHOC('OpenContext')
  paperPlane = ioIconHOC('PaperAirplaine')
  plus = faIconHOC('Plus')
  plusCircle = faIconHOC('PlusCircle')
  questionCircle = faIconHOC('QuestionCircle')
  rule = faIconHOC('Rule')
  saving = faIconHOC('Saving')
  search = faIconHOC('Search')
  send = mdIconHOC('Send')
  signOut = faIconHOC('SignOut')
  success = faIconHOC('Success')
  tasks = faIconHOC('Tasks')
  timesCircle = faIconHOC('TimesCircle')
  unlock = faIconHOC('Unlock')
  upload = faIconHOC('Upload')
  visibilityOff = mdIconHOC('VisibilityOff')
  warning = faIconHOC('Warning')
}

import * as H from 'history'
import * as React from 'react'
import {
  Engagement,
  IdentityTokenProfile,
  PhaseMap,
  LastNavigation,
  EngagementReportType,
  EngagementReportRequestResponse,
  EngagementTemplate,
} from '../../clientModels'
import { PhaseCode } from '../../enums'
import { isDefined, ensureNumber } from '../../guards'
import { sortByDisplayOrder } from '../../sorting'
import { ConfirmDialogConfig } from '../engagementSummary/confirmDialogConfig'
import { WarningDialog, ReportDialog } from '../modals'
import { joinPaths } from '../relativeLink'
import { Guid } from '../../services/guid'
import { getNewAbort, RequestAbort } from '../../services/lastRequests'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../actions'
import { AppState, TsaDispatch } from '../../store'
import { safeJsonParse } from '../../services/jsonHelpers'
import * as confirmModalActions from '../../actions/confirmModalActions'

export interface EngagementPhaseChangeProps {
  engagement: Engagement
  engagementTemplate: EngagementTemplate
  history: H.History
  user?: IdentityTokenProfile
  handlePhaseButtonClick: () => void
  markReviewDone: (engagmentId: string | number) => void
  closeConfirm: () => void
  getLastYearsData: (engagmentId: string | number) => void
  copyLastYearAnswers: (engagmentId: string | number) => void
  setConfirm: (confirm: ConfirmDialogConfig) => void
  navigateSection: (engagementId: number, sectionId: number) => void
  sectionId?: number
  lastNavigation?: LastNavigation
}

export interface EngagementPhaseChangeWrapper {
  childComponent: React.ComponentType<EngagementPhaseChangeProps>
  engagement: Engagement
  engagementTemplate: EngagementTemplate
  submitEngagement: (engagmentId: string | number) => void
  phases?: PhaseMap
  updateEngagementPhase: (
    engagementId: string | number,
    phase: PhaseCode
  ) => void
  markReviewDone?: (engagmentId: string | number) => void
  getLastYearsData?: (engagmentId: string | number) => void
  getLastYearAnswers?: (engagmentId: string | number) => void
  copyLastYearAnswers: (engagmentId: string | number) => void
  history: H.History
  sectionId?: number
  user?: IdentityTokenProfile
  lastNavigation?: LastNavigation
}

export const EngagementPhaseChange = (props: EngagementPhaseChangeWrapper) => {
  const {
    engagement,
    engagementTemplate,
    phases,
    history,
    user,
    getLastYearsData,
    copyLastYearAnswers,
    markReviewDone,
    sectionId,
    lastNavigation,
    childComponent: Child,
    submitEngagement,
    updateEngagementPhase,
  } = props
  const dispatch: TsaDispatch = useDispatch()
  const asyncOperations = useSelector((state: AppState) => {
    return state.async.operations
  })
  const [confirm, setConfirm] = useState<ConfirmDialogConfig | null>(null)
  const [asyncOperationId, setAsyncOperationId] = useState<number>()
  const [showRequestDialog, setShowRequestDialog] = useState<boolean>(false)
  const [token, setToken] = useState<string>()
  const [requestAbort] = useState<RequestAbort>(getNewAbort())
  const [reportType] = useState<EngagementReportType>('phaseChangePbc')
  const reportTitle = 'My Information'
  const errorText =
    'An error prevented your request from processing. Please refresh the page and try again. If you continue to encounter a problem contact RSM for assistance.'
  const getAsyncOperation = () => {
    return asyncOperationId ? asyncOperations[asyncOperationId] : undefined
  }
  const asyncOperation = getAsyncOperation()
  const isError = !!asyncOperation && asyncOperation.status === 'Errored'
  const confirmModalConfig = useSelector(
    (appState: AppState) => appState.confirmModal
  )

  useEffect(() => {
    if (token !== undefined) {
      dispatch(
        actions.engagementReport.requestReport(
          ensureNumber(engagement.id),
          reportType,
          token,
          requestAbort.new(),
          new Array('pbc-review')
        )
      ).then((response: EngagementReportRequestResponse) => {
        handleRequestResponse(response)
      })
    }
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    const nextPhaseCode = getNextPhaseCode()
    if (nextPhaseCode && asyncOperation?.status === 'Complete') {
      updateEngagementPhase(engagement.id, nextPhaseCode)
    }
    // eslint-disable-next-line
  }, [asyncOperation])

  useEffect(() => {
    if (confirmModalConfig.phaseChange) {
      handlePhaseButtonClick()
    }
    // eslint-disable-next-line
  }, [confirmModalConfig.phaseChange])

  const getNextPhaseCode = useCallback(() => {
    if (!phases) {
      return undefined
    }

    const orderedPhases = Object.values(phases)
      .filter(isDefined)
      .sort(sortByDisplayOrder)
    const currentPhaseIndex = orderedPhases.findIndex(
      p => p.code === engagement.phase
    )
    const nextPhaseIndex = Math.min(
      orderedPhases.length - 1,
      currentPhaseIndex + 1
    )
    const nextPhaseCode = orderedPhases[nextPhaseIndex]?.code
    return nextPhaseCode
  }, [engagement.phase, phases])

  const closeConfirm = useCallback(() => {
    setConfirm(null)
    dispatch(confirmModalActions.clearConfirmModalAction())
  }, [dispatch])

  const doPhaseChange = useCallback(() => {
    closeConfirm()
    let nextPhaseCode = getNextPhaseCode()
    if (nextPhaseCode) {
      if (nextPhaseCode === PhaseCode.Review) {
        requestPhaseChangePbcReport()
      } else if (
        nextPhaseCode === PhaseCode.CCH &&
        !engagementTemplate?.hasCchPhase
      ) {
        nextPhaseCode = PhaseCode.IRS
        updateEngagementPhase(engagement.id, nextPhaseCode)
      } else {
        updateEngagementPhase(engagement.id, nextPhaseCode)
      }
    }
  }, [engagement.id, getNextPhaseCode, updateEngagementPhase, closeConfirm])

  const submitPhaseChangeEngagement = useCallback(() => {
    closeConfirm()
    if (submitEngagement) {
      submitEngagement(engagement.id)
    }
  }, [engagement.id, submitEngagement, closeConfirm])

  const handlePhaseButtonClick = useCallback(() => {
    const {
      engagement: {
        phase,
        successfulQuestions,
        totalQuestions,
        taxIntegrationStatuses,
      },
    } = props
    const phaseComplete = successfulQuestions >= totalQuestions
    let confirm: ConfirmDialogConfig | null = null

    switch (phase) {
      case PhaseCode.Setup:
        confirm = {
          title: 'Send to Client',
          info:
            'This engagement will become available for client validation. Proceed?',
          primaryButtonText: 'Yes',
          onClickPrimary: doPhaseChange,
        }
        break

      case PhaseCode.PBC:
        confirm = {
          title: 'Submit for Review',
          additional:
            'Once you submit your engagement you will no longer be able to edit your answers. A report of the information you have provided will be available in the reports section shortly.',
          info:
            'A notification will be sent to your RSM representative that your engagement is ready to review. Proceed?',
          primaryButtonText: 'Yes',
          onClickPrimary: doPhaseChange,
        }
        break

      case PhaseCode.Review:
        if (engagementTemplate?.hasCchPhase) {
          confirm = {
            title: 'Submit to CCH',
            info: 'Are you sure you want to submit this engagement to CCH?',
            primaryButtonText: 'YES, SUBMIT TO CCH',
            onClickPrimary: doPhaseChange,
          }
        } else {
          confirm = {
            title: 'Mark As Filed',
            info:
              "Has the engagement been successfully filed?\nIf so, select 'Yes' and your 90 day closure period begins.\n\n If you have not received confirmation, select 'No' and come back when the engagement has successfully been filed.",
            primaryButtonText: 'YES',
            secondaryButtonText: 'NO',
            onClickPrimary: doPhaseChange,
          }
        }
        break

      case PhaseCode.CCH:
        if (!phaseComplete) {
          const latestExportTaxIntegrationStatus = taxIntegrationStatuses
            ?.filter(x => x.type === 'CCHExport')
            .sort((x, y) => y.id - x.id)[0]
          let title: string
          let info: string
          if (latestExportTaxIntegrationStatus?.status === 'SUCCESS') {
            title = 'Re-submit to CCH'
            info =
              'This engagement has been submitted to CCH. Are you sure you want to re-submit?'
          } else {
            title = 'Submit to CCH'
            info = 'Are you sure you want to submit this engagement to CCH?'
          }
          confirm = {
            title,
            info,
            primaryButtonText: 'YES, SUBMIT TO CCH',
            onClickPrimary: submitPhaseChangeEngagement,
          }
        } else {
          confirm = {
            title: 'Mark As Filed',
            info:
              "Has the engagement been successfully filed?\nIf so, select 'Yes' and your 90 day closure period begins.\n\n If you have not received confirmation, select 'No' and come back when the engagement has successfully been filed.",
            primaryButtonText: 'YES',
            secondaryButtonText: 'NO',
            onClickPrimary: doPhaseChange,
          }
        }
        break
      default:
        break
    }
    setConfirm(confirm)
  }, [props, doPhaseChange, submitPhaseChangeEngagement])

  const closeModal = () => {
    if (asyncOperationId) {
      // Stop polling for status updates on current async operation
      dispatch(actions.async.removeWatch(asyncOperationId))
    } else {
      // Abort request for report -- this reduces the amount of error dialogs the user
      // will see if cancelling out of the download during a long-running request
      requestAbort.abort()
    }
    setAsyncOperationId(undefined)
    setShowRequestDialog(false)
    setToken(undefined)
  }

  const handleRequestResponse = (response: EngagementReportRequestResponse) => {
    if (token === response.token) {
      if (response.asyncOperationId) {
        // Start polling for status updates on current async operation
        dispatch(actions.async.addWatch(response.asyncOperationId))
        setAsyncOperationId(response.asyncOperationId)
        setToken(undefined)
      } else {
        closeModal()
      }
    }
  }

  const requestPhaseChangePbcReport = () => {
    //generate new guid token
    const newToken = Guid.newGuid()
    //set token state to trigger the report request
    setToken(newToken)
    setAsyncOperationId(undefined)
    setShowRequestDialog(true)
  }

  const setPhaseChangeConfirm = (confirm: ConfirmDialogConfig) => {
    setConfirm(confirm)
  }

  const navigateSection = (engagementId: number, sectionId: number) => {
    const path = joinPaths(
      window.location.pathname,
      `engagements/${engagementId}/sections/${sectionId}`
    )
    history.push(path)
  }

  const downloadReport = () => {
    if (asyncOperation) {
      const propertyBag = safeJsonParse(asyncOperation.propertyBag)
      if (propertyBag.FileId) {
        dispatch(actions.file.downloadFile(propertyBag.FileId))
      }
    }
    closeModal()
  }

  return (
    <React.Fragment>
      <Child
        engagement={engagement}
        engagementTemplate={engagementTemplate}
        setConfirm={setPhaseChangeConfirm}
        handlePhaseButtonClick={handlePhaseButtonClick}
        markReviewDone={markReviewDone!}
        getLastYearsData={getLastYearsData!}
        copyLastYearAnswers={copyLastYearAnswers}
        history={history}
        user={user}
        closeConfirm={closeConfirm}
        sectionId={sectionId}
        navigateSection={navigateSection}
        lastNavigation={lastNavigation}
      />
      {confirm && (
        <WarningDialog
          {...confirm}
          onClickSecondary={closeConfirm}
          onClose={closeConfirm}
        />
      )}
      {showRequestDialog && (
        <ReportDialog
          downloadReady={
            !!asyncOperation && asyncOperation.status === 'Complete'
          }
          isError={isError}
          loadingTitle={`${reportTitle} Requested`}
          onClose={closeModal}
          onDownload={downloadReport}
          readyTitle={`${reportTitle} Ready for Download`}
          errorTitle={`Error Occured`}
          errorText={isError ? errorText : undefined}
        />
      )}
    </React.Fragment>
  )
}

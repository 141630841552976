import { ClientFilterNode } from '../clientModels'
import { ensureNumber } from '../guards'
import { AppState } from '../store'
import {
  validationResultOptions,
  BannerFilterValidationResult
} from './filterHelperConstants'

/** Given a set of ClientFilterNode objects, represents an entire
 *   filter set, and provides convenience properties based on those nodes. */
export class FilterNodeTree {
  constructor(
    public readonly masterClientId: ClientFilterNode,
    public readonly taxForm: ClientFilterNode,
    public readonly taxYear: ClientFilterNode
  ) { }

  get filterIds(): number[] {
    return this.taxYear.ids ?? []
  }

  get masterId(): number {
    return this.masterClientId.id!
  }

  get year(): string {
    return this.taxYear.label
  }

  get taxFormName(): string {
    return this.taxForm.label
  }

  /** Returns the URL path to navigate to, to show a list of the filtered
   *   engagements for this node tree. */
  get filterPath(): string {
    return encodeURI(
      `/masterclient/${this.masterId}/${this.taxFormName}/${this.year}`
    )
  }

  private _filterList: ClientFilterNode[] | undefined
  /** Returns an array of the ClientFilterNode objects in the order of their definition. */
  get filterList(): ClientFilterNode[] {
    if (!this._filterList) {
      this._filterList = [this.masterClientId, this.taxForm, this.taxYear]
    }
    return this._filterList
  }

  /** Given a specified array of exactly 3 ClientFilterNode objects, returns
   *   the URL path to navigate to filter to this list of engagements. */
  static getPath(nodes: ClientFilterNode[]): string {
    // The node list must have 3 elements, or we can't do this.
    if (nodes.length !== 3) {
      throw new Error(
        `getPath requires an array of exactly 3 ClientFilterNode objects.`
      )
    }

    return new FilterNodeTree(nodes[0], nodes[1], nodes[2]).filterPath
  }

  /** Returns a FilterNodeTree from a set of exactly 3 ClientFilterNode objects,
   *   in their "tree order". */
  static createFromTreeElements(elements: ClientFilterNode[]): FilterNodeTree {
    if (elements.length !== 3) {
      throw new Error(
        `Cannot create a FilterNodeTree without exactly 3 element.`
      )
    }
    return new FilterNodeTree(elements[0], elements[1], elements[2])
  }

  /** Returns a boolean value indicating whether or not this tree is equivalent
   *   to a specified ClientFilterNode[] or FilterTreeNode.   */
  compare(otherElement: ClientFilterNode[] | FilterNodeTree | null): boolean {
    // If the value is null, then it's simply not a match.
    if (!otherElement) {
      return false
    }

    // Make sure we have an array of the filter nodes to compare against.
    if (otherElement instanceof FilterNodeTree) {
      otherElement = otherElement.filterList
    }

    // Return the comparison of the values.
    return (
      otherElement[0].id === this.masterId &&
      otherElement[1].label === this.taxFormName &&
      otherElement[2].label === this.year
    )
  }
}

/** Returns sets of FilterNodeTree objects, built from all trees of a
 *   specified set of ClientFilterNode.  A FilterNodeTree should exist
 *   for each root ClientFilterNode, and all of its combinations of children.
 */
export function getFilterNodeTrees(
  nodes: ClientFilterNode[]
): FilterNodeTree[] {
  // Return value.
  const result = [] as FilterNodeTree[]

  // There should only be 3 tiers.  Construct the result from each parent node.
  // Tier 1:
  nodes.forEach(m => {
    // Tier 2:
    m.children!.forEach(f => {
      // Tier 3:
      f.children!.forEach(y => {
        // Add this set to the results.
        result.push(new FilterNodeTree(m, f, y))
      })
    })
  })

  // Return the results.
  return result
}

/** Returns a state object with a boolean value indicating whether or not the filter in the URL
 *   is correct, based on the current selections. */
export function isBannerFilterUrlCorrect(
  url: string,
  state: AppState
): BannerFilterValidationResult {
  // Get the filter portion of the URL.
  const match = /(?<filter>\/masterclient\/(?<masterId>\d+)\/(?<form>[\w-]+)\/(?<year>\d{4})(\/|$))?.*?(\/?\bengagements\/(?<engagement>\d+))?/.exec(
    url
  )

  // If there's no pattern match, including the engagement ID, then we'll assume everything's fine.
  //  This is probably impossible since the regex pattern includes a portion that matches everything.
  if (!match) {
    return validationResultOptions.valid
  }

  const engagementId = match.groups!['engagement']
  // All 3 of these will either exist or not due to how regex does matching.
  const masterClientId = match.groups!['masterId']
  const form = match.groups!['form']
  const year = match.groups!['year']

  // If we have an engagement, but no filter, then it's a mismatch.
  if (engagementId && !masterClientId) {
    return validationResultOptions.mismatch
  }

  // Get the engagement, if we have one.
  const engagement =
    engagementId && state.engagements[ensureNumber(engagementId)]

  // Compare the engagement to the filter.
  if (engagement) {
    const engagementMasterId = state.clients[engagement.clientId]?.masterId
    if (!engagementMasterId) {
      console.error(
        `masterId does not exist for engagement: ${engagementId} - clientId: ${engagement.clientId}`
      )

      return validationResultOptions.mismatch
    }
    if (engagementMasterId.toString() !== masterClientId) {
      return validationResultOptions.mismatch
    }
    if (engagement.engagementTaxForm !== form || engagement.taxYear !== year) {
      return validationResultOptions.mismatch
    }

    // Get the filter we expect to match the current URL.
    const expectedSelection = getMatchingFiltersFor(
      masterClientId?.toString() ?? '',
      form,
      year,
      state.bannerFilter.filters
    )

    // If we can't find an expected selection, then
    //  we must indicate that it's invalid.
    if (!expectedSelection) {
      return validationResultOptions.invalid
    }

    // In this case, even if we don't have a matching filter
    //  for the one in the URL, it must always match the engagement data.  So we're good.
    if (isUrlMatchedToFilter(url, state.bannerFilter.selectedFilter)) {
      return validationResultOptions.valid
    } else {
      return validationResultOptions.selectedFilterNotSetToUrlFilter
    }
  } else if (engagementId) {
    // If we have an engagement ID, and no engagement, it's probably not loaded.
    //  We should ignore this condition, and consider it valid until a later check.
    return validationResultOptions.inconclusive
  }

  // If the filters aren't loaded from the server, then we must assume the filter is valid.
  //  Otherwise the error handlers will change it before we really know it's valid.
  if (state.bannerFilter.isLoading) {
    return validationResultOptions.inconclusive
  }

  // Find a filter that matches this, in the filter list.
  const expectedSelection = getMatchingFiltersFor(
    masterClientId?.toString() ?? '',
    form,
    year,
    state.bannerFilter.filters
  )

  // If we can't find an expected selection, then
  //  we must indicate that it's invalid.
  if (!expectedSelection) {
    return validationResultOptions.invalid
  }

  // Check that this is the current selection.
  if (expectedSelection.compare(state.bannerFilter.selectedFilter)) {
    return validationResultOptions.valid
  } else {
    return validationResultOptions.selectedFilterNotSetToUrlFilter
  }
}

/** Attempts to find a matching FilterNodeTree for a specified set of criteria in a specified set of node trees or client filters. */
export function getMatchingFiltersFor(
  masterId: string,
  formName: string,
  taxYear: string,
  filters: FilterNodeTree[] | ClientFilterNode[]
): FilterNodeTree | undefined {
  // If we have no filters, then we can't find a match.
  if (filters.length < 1) {
    return undefined
  }

  // If we don't have FilterNodeTrees, then create them.
  if (!(filters[0] instanceof FilterNodeTree)) {
    filters = getFilterNodeTrees(filters as ClientFilterNode[])
  }

  // Return the filter that matches this, or undefined if none do.
  return (filters as FilterNodeTree[]).find(
    f =>
      f.masterId.toString() === masterId &&
      f.taxFormName === formName &&
      f.year === taxYear
  )
}

/** Returns a boolean value indicating whether or not specified URL fragments match
 *   a specified FilterNodeTree. */
function isUrlMatchedToFilter(
  url: string,
  filter: FilterNodeTree | ClientFilterNode[] | null | undefined
): boolean {
  // Ensure the filter is a FilterNodeTree or unset.
  if (Array.isArray(filter)) {
    filter = FilterNodeTree.createFromTreeElements(filter)
  }

  const filterInfo = getBannerFilterInfoFromUrl(url)

  // If there's no filter and no URL filter, then its a match.
  if (!filterInfo?.masterClientId && !filter) {
    return true
  }

  // Handle if there's no URL filter, but there is an actual filter.
  if (filterInfo?.masterClientId && !filter) {
    return false
  }

  // We should have a URL filter, but just in case.
  if (!filterInfo && filter) {
    return false
  }

  // We have a URL filter and a filter.  Compare the two.
  return (
    filterInfo?.masterClientId === filter?.masterId.toString() &&
    filterInfo?.year === filter?.year &&
    filterInfo?.form === filter?.taxFormName
  )
}

export interface BannerFilterInfo {
  masterClientId: string
  form: string
  year: string
  engagementId: string
  /** The filter portion of the URL path. */
  filterPath: string
}

/** Returns filter information from the URL, if it exists. */
export function getBannerFilterInfoFromUrl(
  url: string
): BannerFilterInfo | undefined {
  // Parse the url for the info.
  const match = /(https?:\/\/[\w.-]+)?(?<filter>\/masterclient\/(?<masterId>\d+)\/(?<form>[\w-]+)\/(?<year>\d{4})(\/|$))?.*?(\/?\bengagements\/(?<engagement>\d+))?/.exec(
    url
  )

  if (!match) {
    return undefined
  }

  // Return the info.
  return {
    engagementId: match.groups!['engagement'],
    masterClientId: match.groups!['masterId'],
    form: match.groups!['form'],
    year: match.groups!['year'],
    filterPath: match.groups!['filter']
  }
}

/** This returns the portion of the URL that contains the filters of the page.  If none
 *   exists, then an empty string is returned. This helper is used to identify and/or
 *   preserve this part of a URL when navigating, when it's present. */
export const getClientUrlPortion = (url: string): string => {
  const match = /\/masterclient\/\d+\/[\w-]+\/\d{4}/.exec(url)
  return match?.[0] ?? ''
}

import React, {
  ChangeEventHandler,
  FormEventHandler,
  FunctionComponent,
  useState,
  useRef,
} from 'react'
import { post } from '../../services/http'
import { buildApiUrl } from '../../services/api'
import { ExtError } from '../../services/error'

export const ClientAssignmentsScene: FunctionComponent = () => {
  const [error, setError] = useState<any>()
  const [success, setSuccess] = useState<any>()
  const [file, setFile] = useState<any>()
  const [busy, setBusy] = useState(false)
  const fileInput = useRef<HTMLInputElement>(null)

  const clearState = () => {
    setError(null)
    setSuccess(null)
    setFile(null)
  }

  const clearInput = () => {
    setBusy(false)
    if (fileInput.current) {
      fileInput.current.value = ''
    }
  }

  const handleFileChanged: ChangeEventHandler<HTMLInputElement> = e => {
    const files = e.target.files
    const file = files && files[0]
    setError(null)
    setFile(file)
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    try {
      const url = buildApiUrl('/clientassignments/bulk')
      const data = new FormData()
      data.append('file', file)

      clearState()
      setBusy(true)

      await post(url, data, { headers: undefined })

      clearInput()
      setSuccess('Successfully uploaded new client assignments.')
      setTimeout(() => setSuccess(null), 4000)
    } catch (error) {
      clearInput()
      setError(processError(error))
    }
  }

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Upload Client Assignments Spreadsheet</h1>
      <form onSubmit={handleSubmit}>
        <input type='file' onChange={handleFileChanged} ref={fileInput} />
        <button type='submit' className='btn btn-primary' disabled={!file}>
          Upload
        </button>
      </form>
      {error && <h3 style={{ color: '#e31e26' }}>{error}</h3>}
      {success && <h3>{success}</h3>}
      {busy && <h3>Uploading file...</h3>}
    </div>
  )
}

function processError(error: ExtError) {
  let modelState: any
  if (error.body) {
    try {
      modelState = JSON.parse(error.body)
    } catch {}
  }

  return modelState ? modelState.file && modelState.file[0] : error.message
}

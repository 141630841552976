import * as React from 'react'
import { IconProperties } from './properties'

const DownArrow: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <svg
      viewBox='0 0 16 16'
      height='1em'
      width='1em'
      className={className}
      onClick={onClick}
      focusable='false'
      data-tip={tooltip}
    >
      <path d='M 12.886719 2 C 7.9854386 2 4 5.9854386 4 10.886719 L 4 12.025391 L 1.7285156 9.7539062 L 1.0214844 10.460938 L 4.5 13.939453 L 7.9785156 10.460938 L 7.2714844 9.7539062 L 5 12.025391 L 5 10.886719 C 5 6.5259985 8.5259985 3 12.886719 3 L 14 3 L 14 2 L 12.886719 2 z M 4.4746094 12.5 L 4.5253906 12.5 L 4.5 12.525391 L 4.4746094 12.5 z' />
    </svg>
  )
}

export default DownArrow

import React, { useCallback, useEffect, useState } from 'react'
import { ClientFilterNode } from '../../clientModels'
import { MenuItem } from 'primereact/menuitem'
import './ClientGroupDropdown.scss'
import {
  FilterNodeTree,
  getBannerFilterInfoFromUrl
} from '../../services/filterHelpers'
import { getSelectedBannerFilter } from '../../reducers/selectors'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Sidebar } from 'primereact/sidebar'
import { SlideMenu } from 'primereact/slidemenu'
import { InputText } from 'primereact/inputtext'

export interface ClientGroupDropdownProps {
  /** Set of filters the user can select from. */
  filters: ClientFilterNode[]
  /** Boolean value indicating whether or not the filters are being loaded. */
  isLoading: boolean
  /** Called when the user selects a new filter.  Should update
   *   the selectedFilter by the parent. */
  setSelectedFilter: (newFilter: FilterNodeTree | null) => void
}

/** This will find the scrollable panel in the menu, and set the scroll to the top of the window.
 *   When the user selects a sub-menu, the scroll does not move to the top automatically, and will
 *   appear empty without this. */
function resetMenuScroll() {
  // Find the element with the scroll bar we need to fix.
  const element = document.getElementsByClassName('p-slidemenu-content')[0]

  // If found, then reset the scroll.
  if (element) {
    element.scrollTo({ top: 0 })
  }
}

const ClientGroupDropdown = ({
  filters,
  isLoading,
  setSelectedFilter
}: ClientGroupDropdownProps) => {
  // Menu generated from the filters.
  const [menuItems, setMenuItems] = useState([] as MenuItem[])
  const selectedFilter = useSelector(getSelectedBannerFilter)
  const history = useHistory()
  const filterParams = getBannerFilterInfoFromUrl(history.location.pathname)
  const [isSideBarVisible, setIsSidebarVisible] = useState(false)
  const [clientFilterName, setClientFilterName] = useState('')

  /** Converts a ClientFilterNode to a MenuItem. */
  const filterToMenuItem = useCallback(
    (filter: ClientFilterNode, parents: ClientFilterNode[] = []): MenuItem => {
      // Start the result value.
      const result = {
        label: filter.label,
        items: filter.children?.map(c =>
          filterToMenuItem(c, [...parents, filter])
        )
      } as MenuItem

      // If this is an leaf node, then create the command.
      if (parents?.length === 2) {
        result.command = () => {
          // Create a FilterNodeTree to work with.
          const filterTree = FilterNodeTree.createFromTreeElements([
            ...parents,
            filter
          ])

          // Inform the parent of the selection.
          setSelectedFilter(filterTree)

          // Clear the search, since the user has made a selection.
          setClientFilterName('')

          // Close the menu, since it won't close on its own.
          // Not sure how to trigger this any other way.  The `hide` method described
          //  in the documentation does not seem to exist.  We don't have an event object
          //  to pass to the menu item, so passing `undefined` is all we got, and it seems to work (?).
          // menuRef.current!.toggle(undefined as any)
          setIsSidebarVisible(false)
        }
      }

      if (!parents || parents.length === 0) {
        // If this is the first item, then set the scroll to the top, so
        //  that the form list is present.
        result.command = () => resetMenuScroll()
      }

      // Return the result.
      return result
    },
    [setSelectedFilter]
  )

  // This is done for linting issues in the following useEffect dependencies.
  const pathFilter = filterParams?.filterPath
  const currentPath = history.location?.pathname
  useEffect(
    () => {
      // Get the filter name in lower case letters and its whitespace trimmed.
      const lookupName = clientFilterName.toLocaleLowerCase().trim()

      // Create the menu items from the filters.  This must include
      //  a reference to the menu HTML element, so we can toggle it
      //  visibility when it is interacted with.
      // Construct the menu items, which should include a selection for "none",
      //  as well as a selection for all filter items.
      let menuItems = [
        {
          label: 'None',
          command: () => {
            // Make the change in the URL.
            if (filterParams?.filterPath) {
              history.push(
                history.location.pathname.replace(filterParams?.filterPath, '')
              )
            }

            // Make the change in the state.  We must change the
            //  history path to '/' or the old filter will come back.
            setSelectedFilter(null)
            history.push('/')

            // Clear the search, since the user has made a selection.
            setClientFilterName('')

            // Without this, the menu won't close.  It's expecting an event object
            //  to be passed, but we simply don't have one.
            // menuRef.current!.toggle(false as any)
            setIsSidebarVisible(false)
          }
        },
        ...filters.map(f => filterToMenuItem(f))
      ] as MenuItem[]

      // Filter the item list on the lookupName (coming from the search dialog).
      //  If there is no value, then essentially, we won't filter the list at all.
      menuItems = menuItems.filter(
        x =>
          lookupName === '' || x.label!.toLocaleLowerCase().includes(lookupName)
      )

      // Set the new menu.
      setMenuItems(menuItems)
    },
    // Some items, such as filterParams, change every call.  This linting warning is bad.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filters,
      filterToMenuItem,
      setSelectedFilter,
      pathFilter,
      currentPath,
      clientFilterName
    ]
  )

  // Text for the menu button.
  let buttonText = 'Select Client Group'
  let filterTree: FilterNodeTree | undefined
  if (selectedFilter) {
    filterTree = FilterNodeTree.createFromTreeElements(selectedFilter)
    buttonText = filterTree.masterClientId.label
  }

  return (
    <div className='ClientGroupDropdown'>
      <Sidebar
        className='client-group-dropdown-sidebar'
        visible={isSideBarVisible}
        onHide={() => setIsSidebarVisible(false)}
        style={{ width: '300px' }}
        // 20000 because very high z-index values are used for other elements
        //  found in the _tsavariables.scss file.  Those values must be coordinated
        //  with this value to achieve the proper layering.
        baseZIndex={20000}
      >
        <div className='client-group-dropdown-menu'>
          <div className='client-side-bar-label'>Search:</div>
          <InputText
            id='name-filter'
            value={clientFilterName}
            onChange={e => setClientFilterName(e.target.value)}
          />
          <div className='client-side-bar-label'>
            Select Client Group Filter:
          </div>
          <SlideMenu model={menuItems} baseZIndex={25000} menuWidth={260} />
        </div>
      </Sidebar>
      {!isLoading ? (
        <div
          className='menu-button'
          onClick={event => setIsSidebarVisible(true)}
        >
          <span className='filter-text'>{buttonText}</span>
          <span className='pi pi-chevron-down'></span>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
}

export default ClientGroupDropdown

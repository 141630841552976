import { ApiModels } from '.'
import { TaxCode, K1Header } from '../../clientModels'
import { ensureNumber } from '../../guards'
import * as http from '../http'
import { getNewAbort } from '../lastRequests'
import {
  ColumnFilters,
  EtlFileProgress,
  K1FileStatus,
  Partner
} from './apiModels'
import { buildApiUrl } from './apiUrl'
import { REACT_APP_GLOBALFILEDOWNLOADTIMEOUTINSECS } from '../../envVariables'

const fileDownloadTimeout =
  ensureNumber(REACT_APP_GLOBALFILEDOWNLOADTIMEOUTINSECS) || 90
const filesAbort = getNewAbort()

export function apiGetWorksheetMappingOptions(): Promise<TaxCode[]> {
  return http.get(buildApiUrl('InputProcess/WorksheetMappingOptions'))
}

export function apiGetWorksheetTabularOptions(): Promise<{
  [id: string]: string
}> {
  return http.get(buildApiUrl('InputProcess/TabularOptions'))
}

export function apiGetWorksheetPivotRowOptions(): Promise<{
  [id: string]: string
}> {
  return http.get(buildApiUrl('InputProcess/PivotRowOptions'))
}

export function apiGetWorksheetPivotColumnOptions(): Promise<{
  [id: string]: string
}> {
  return http.get(buildApiUrl('InputProcess/PivotColumnOptions'))
}

export function apiGetTemplates(): Promise<ApiModels.InputProcessTemplate[]> {
  return http.get(buildApiUrl('InputProcess/Template'))
}

export function apiSaveTemplate(
  template: ApiModels.InputProcessTemplate,
  k1Id?: number
): Promise<ApiModels.InputProcessTemplate> {
  const k1Path = k1Id ? `/K1/${k1Id}` : ''
  return http.post(buildApiUrl(`InputProcess/Template${k1Path}`), template)
}

export function apiSendFailedK1sNotification(
  engagementId: number,
  fileId: number
): Promise<ApiModels.InputProcessTemplate> {
  return http.post(
    buildApiUrl(
      `InputProcess/${engagementId}/SendFailedK1sNotification/${fileId}`
    ),
    undefined
  )
}

export function apiUpdateTemplate(
  template: ApiModels.InputProcessTemplate
): Promise<ApiModels.InputProcessTemplate> {
  return http.put(buildApiUrl('InputProcess/Template'), template)
}

export function updateEtlFileProgress(
  etlFileProgress: EtlFileProgress
): Promise<ApiModels.File> {
  return http.post(
    buildApiUrl(`InputProcess/FileProgress/${etlFileProgress.FileId}`),
    etlFileProgress
  )
}

export function completeK1FileProgress(
  fileId: number
): Promise<ApiModels.File> {
  return http.remove(buildApiUrl(`InputProcess/FileProgress/${fileId}`))
}

export function getDataMappingTemplateForK1(
  k1Id: number
): Promise<ApiModels.InputProcessTemplate> {
  return http.get(buildApiUrl(`InputProcess/Template/K1/${k1Id}`))
}

export function getEngagementK1FileStatuses(
  engagementId: number,
  questionId: number
): Promise<ApiModels.K1FileStatus[]> {
  return http.get(
    buildApiUrl(
      `InputProcess/K1FileStatuses/Engagement/${engagementId}/Question/${questionId}`
    )
  )
}

// Update ind K1 status
export function updateK1Progress(
  k1FileProgress: ApiModels.K1Progress
): Promise<K1Header> {
  return http.post(
    buildApiUrl(`InputProcess/K1Status/Update/${k1FileProgress.k1Id}`),
    k1FileProgress.status
  )
}

export function apiInProgressContinue(fileId: number): Promise<K1FileStatus> {
  return http.post(
    buildApiUrl(`InputProcess/${fileId}/InProgressContinue`),
    undefined
  )
}

export function apiDownloadTaxCodeMap(
  fileId: number,
  excludeMappedData?: boolean,
  k1HeaderId?: number
): Promise<http.BlobResponse> {
  const k1Param = k1HeaderId ? `/${k1HeaderId}` : ''
  return http.post(
    buildApiUrl(`InputProcess/${fileId}/Worksheets/TaxCodeMap${k1Param}`),
    {
      excludeMappedData: excludeMappedData || false,
    }
  )
}

export function apiExportK1FileData(
  fileId: number
): Promise<http.BlobResponse> {
  return http.get(buildApiUrl(`InputProcess/${fileId}/Export`))
}

export function apiGetTaxCodes(
  fileId: number,
  k1Id?: number,
  filters?: ColumnFilters,
  excludeMappedData?: boolean
): Promise<string[][]> {
  const k1Param = k1Id ? `/${k1Id}` : ''
  const inputProcessFilters = {
    ...filters,
    excludeMappedData,
  }
  return http.post(
    buildApiUrl(`InputProcess/${fileId}/TaxCodes/${k1Param}`),
    inputProcessFilters
  )
}

export function apiGetDistinctReceivingPartners(
  fileId: number,
  k1Id?: number
): Promise<Partner[]> {
  return http.get(
    buildApiUrl(`InputProcess/${fileId}/ReceivingPartners/${k1Id || ''}`)
  )
}

export function apiGetFilterValues(
  fileId: number
): Promise<{ [id: string]: string[] }> {
  return http.get(buildApiUrl(`InputProcess/${fileId}/FilterValues`))
}

export function apiDownloadWorksheetMappingOptions(): Promise<
  { id: number; name: string }[]
> {
  return http.post(
    buildApiUrl('WorksheetMappingOptions'),
    undefined,
    undefined,
    filesAbort.new(),
    fileDownloadTimeout
  )
}

export function apiGetWorksheets(
  fileId: number
): Promise<{ [id: string]: string }> {
  return http.get(buildApiUrl(`InputProcess/${fileId}/Worksheets`))
}

export function apiProcessK1Mappings(
  fileId: number,
  k1Id?: number
): Promise<{ [id: string]: string }> {
  const k1Param = k1Id ? `/${k1Id}` : ''
  return http.post(
    buildApiUrl(`InputProcess/${fileId}/ProcessK1Mappings${k1Param}`),
    undefined
  )
}

export function apiProcessWorksheet(
  fileId: number,
  step: string,
  worksheet: ApiModels.InputProcessWorksheet
): Promise<string[][]> {
  return http.post(
    buildApiUrl(`InputProcess/${fileId}/Worksheets/Process/${step}`),
    worksheet
  )
}

export function apiSaveWorksheet(
  fileId: number,
  worksheet: ApiModels.InputProcessWorksheet
): Promise<null> {
  return http.post(
    buildApiUrl(`InputProcess/${fileId}/Worksheets/Save`),
    worksheet
  )
}

export function apiDownloadBlankTrapezeTemplate(): Promise<http.BlobResponse> {
  return http.post(
    buildApiUrl(`InputProcess/UserTemplates/Trapeze`),
    undefined,
    undefined,
    filesAbort.new(),
    fileDownloadTimeout
  )
}

export function apiGetFileErrors(fileId: number): Promise<string[] | null> {
  return http.get(buildApiUrl(`InputProcess/${fileId}/Errors`))
}

export function apiValidateK1File(fileId: number): Promise<string[]> {
  return http.get(buildApiUrl(`InputProcess/${fileId}/Validate`))
}

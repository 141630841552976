import React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { Theme } from '@rsmus/react-ui'
import { SupportedApplication } from './Models'
import { WarningDialog } from '../../modals'
import { usePermissionManager } from '../../../hooks'

function styles({ palette, transitions, typogrphaphy }: Theme) {
  return {
    myApplications: {
      margin: '1rem 0',
    },
    appHeading: {
      paddingLeft: '1.5rem',
      fontFamily: typogrphaphy.bold.fontFamily,
    },
    appLink: {
      display: 'block',
      color: palette.common.white,
      padding: '0 1.5rem 0.25rem',
      fontSize: '1.5rem',
      transition: transitions.create('background-color'),
      '&:hover': {
        backgroundColor: palette.blue.light,
        color: palette.common.white,
        textDecoration: 'none',
      },
    },
    appText: {
      fontSize: '.8rem',
      paddingLeft: '0.5rem',
      position: 'relative',
      top: '-1px',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface ProfileMenuLink {
  text: string
  icon: any
  url: string
}
export interface MyApplicationsProps {
  claims: string[]
  links: ProfileMenuLink[]
  accessingFrom: SupportedApplication
}

export interface AppClaimPermission {
  text: SupportedApplication
  claims: string[]
}

export const appClaimPermission: AppClaimPermission[] = [
  {
    text: 'TSA Hub',
    claims: ['RSMAdmin', 'RSMUser', 'ClientAdmin', 'ClientUser'],
  },
  {
    text: 'Engagement Manager',
    claims: ['RSMAdmin', 'RSMUser'],
  },
]

const MyApplications = ({
  claims,
  links,
  accessingFrom,
}: MyApplicationsProps) => {
  const theme = useTheme()
  const permissions = usePermissionManager()
  const classes = useStyles(theme)
  const [appsToDisplay, setAppsToDisplay] = React.useState<string[]>([])
  const [dialog, setDialog] = React.useState(false)
  const [engagementManagerLink, setEngagementManagerLink] = React.useState<
    string
  >('')

  const handleClick = (e: any, url: string) => {
    if (permissions.impersonateUser) {
      setEngagementManagerLink(url)
      e.stopPropagation()
      e.preventDefault()
      setDialog(true)
    }
  }

  const onDialogClose = () => {
    permissions.toggleUserView(false)
    setDialog(false)
    window.open(engagementManagerLink, '_blank')
    window.location.reload(false)
  }

  React.useEffect(() => {
    if (claims.length > 0) {
      const allowedApps: string[] = []
      appClaimPermission.forEach((app: AppClaimPermission) => {
        claims.forEach((c: string) => {
          if (app.claims.includes(c) && app.text) {
            if (!allowedApps.includes(app.text)) {
              if (app.text !== accessingFrom) {
                allowedApps.push(app.text)
              }
            }
          }
        })
      })
      setAppsToDisplay(allowedApps)
    }
  }, [claims, accessingFrom])

  return (
    <div className={classes.myApplications}>
      {appsToDisplay.length > 0 && (
        <>
          <h3 className={classes.appHeading}>My Applications</h3>
          {links.map(
            (link: ProfileMenuLink, i: number) =>
              appsToDisplay.includes(link.text) && (
                <React.Fragment key={i}>
                  <a
                    href={link.url}
                    key={i}
                    className={classes.appLink}
                    target={
                      link.url.indexOf(window.location.origin) > -1
                        ? '_self'
                        : '_blank'
                    }
                    onClick={e =>
                      link.text === 'Engagement Manager'
                        ? handleClick(e, link.url)
                        : null
                    }
                  >
                    <>
                      {link.icon}
                      <span className={classes.appText}>{link.text}</span>
                    </>
                  </a>
                </React.Fragment>
              )
          )}
          {dialog && (
            <WarningDialog
              info={`Once you navigate away from the client view your view will default back to your RSM View`}
              onClickPrimary={onDialogClose}
              primaryButtonText={'YES, PROCEED'}
              onClickSecondary={() => setDialog(false)}
              onClose={() => setDialog(false)}
              title='You are about to leave Client View Mode'
            />
          )}
        </>
      )}
    </div>
  )
}

MyApplications.defaultProps = {
  links: [] as ProfileMenuLink[],
} as const

export default MyApplications

import { getType } from 'typesafe-actions'
import {
  showAllQuestionsAction,
  showIncompleteOnlyAction
} from '../actions/engagementQuestionActions'
import {
  defaultEngagementQuestionsAction,
  DefaultEngagementSectionsQuestionsAction
} from '../actions/engagementSectionsQuestionsActions'
import { TsaHubAction } from '../actions/index'
import { ruleSetSectionVisiblityAction } from '../actions/rulesActions'
import { EngagementSection, EngagementSectionMap } from '../clientModels'
import { isDefined } from '../guards'

/**
 * An EngagementSection holds data related to a section on a particular enagagement.
 */
export interface EngagementSectionsState {
  [engagementId: string]: EngagementSectionMap | undefined
  [engagementId: number]: EngagementSectionMap | undefined
}

const initialState: EngagementSectionsState = {}

export const defaultEngagementSection = (id: number, engagementId: number) => ({
  id,
  engagementId,
  isVisible: true,
  isVisibleDebug: 0
})

/**
 * This is for the storage of data that is specific to a particular section in an engagement
 */
export function engagementSectionsReducer(
  state: EngagementSectionsState = initialState,
  action: TsaHubAction
): EngagementSectionsState {
  switch (action.type) {
    case getType(defaultEngagementQuestionsAction):
      state = {
        ...state,
        [action.payload.engagementId]: getDefaultMap(action)
      }
      break

    case getType(ruleSetSectionVisiblityAction): {
      const isVisible = action.payload.isVisible
      const section = getEngagementSection(state, action.payload)
      if (section && section.isVisible !== isVisible) {
        state = updateEngagementSection(state, section, { isVisible })
      }
      break
    }

    case getType(showAllQuestionsAction):
      state = {
        ...state,
        [action.payload.engagementId]: toggleShowAll(
          state[action.payload.engagementId]
        )
      }
      break

    case getType(showIncompleteOnlyAction):
      state = {
        ...state,
        [action.payload.engagementId]: clearShowAll(
          state[action.payload.engagementId]
        )
      }
      break

    default:
      break
  }

  return state
}

interface EngagementSectionPayload {
  engagementId: number
  sectionId: number
}

function getEngagementSection(
  state: EngagementSectionsState,
  { engagementId, sectionId }: EngagementSectionPayload
) {
  const engagementSections = state[engagementId]
  return engagementSections && engagementSections[sectionId]
}

/**
 * Update an EngagementSection on the state. This creates shallow copies of the
 * EngagementSectionMap and EngagementSectionsState objects that contain the modified
 * section.
 */
function updateEngagementSection(
  state: EngagementSectionsState,
  engagementSection: EngagementSection,
  updates: Partial<EngagementSection>
): EngagementSectionsState {
  const engagementSections: EngagementSectionMap =
    state[engagementSection.engagementId] || {}

  return {
    ...state,
    [engagementSection.engagementId]: {
      ...engagementSections,
      [engagementSection.id]: { ...engagementSection, ...updates }
    }
  }
}

function getDefaultMap({
  payload: { engagementId, sections }
}: DefaultEngagementSectionsQuestionsAction): EngagementSectionMap {
  return sections.reduce((map, s) => {
    map[s.id] = defaultEngagementSection(s.id, engagementId)
    return map
  }, {} as EngagementSectionMap)
}

function toggleShowAll(state?: EngagementSectionMap) {
  let nextState = state

  if (state) {
    nextState = Object.values(state)
      .filter(isDefined)
      .reduce((next, section) => {
        next[section.id] = {
          ...section,
          isVisibleDebug: section.isVisibleDebug ? 0 : 1
        }
        return next
      }, {} as EngagementSectionMap)
  }

  return nextState
}

function clearShowAll(state?: EngagementSectionMap) {
  if (!state) {
    return
  }
  return Object.values(state)
    .filter(isDefined)
    .reduce((next, section) => {
      next[section.id] = { ...section, isVisibleDebug: 0 }
      return next
    }, {} as EngagementSectionMap)
}

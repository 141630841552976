import * as React from 'react'
import { ClientSearch } from '../../clientModels'
import ClientRequestConfirmModal from './clientRequestConfirmModal'
import FindClientModal from './FindClientModal'

export interface ClientAccessRequestModalOwnProps {
  showModal: boolean
  clients?: ClientSearch[]
  displayResults: boolean
  showConfirmationModal: boolean
  searchString: string
  clientAccessSearchStatus: string
  setActions: (action: string) => void
  submitClientAccessRequest: (clientId: number, masterId: number) => void
  handleChange: (searchString: string) => void
}

const ClientAccessRequestModal = ({
  showModal,
  clients,
  displayResults,
  showConfirmationModal,
  searchString,
  clientAccessSearchStatus,
  setActions,
  submitClientAccessRequest,
  handleChange,
}: ClientAccessRequestModalOwnProps) => {
  return (
    <div>
      <div>
        {showModal && (
          <FindClientModal
            clientDialogAdditional={'Enter in the 7-digit Client ID'}
            clientDialogTitle={'Request Client Access'}
            clients={clients}
            displayResults={!displayResults}
            searchString={searchString}
            clientAccessSearchStatus={clientAccessSearchStatus}
            setActions={setActions}
            submitClientAccessRequest={submitClientAccessRequest}
            handleChange={handleChange}
          ></FindClientModal>
        )}
      </div>
      <div>
        <ClientRequestConfirmModal
          showConfirmationModal={showConfirmationModal}
          setActions={setActions}
        ></ClientRequestConfirmModal>
      </div>
    </div>
  )
}

export default ClientAccessRequestModal

import classNames from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { ensureNumber } from '../../guards'
import { AppState } from '../../store'
import Icon from '../icon/icon'
import './unsentComments.scss'
import { joinPaths } from '../relativeLink'
import PermissionContent from '../internal/permissionContent'
import { UserPermissions } from '../../enums'

let nextId = 0

interface RouteParams {
  engagementId: string
}

interface UnsentCommentsOwnProps extends RouteComponentProps<RouteParams> {
  engagementId: string | number
}

interface UnsentCommentsMappedProps {
  unsentNotifications: number
  engagementId: string
}

const mapStateToProps = (
  state: AppState,
  props: UnsentCommentsOwnProps
): UnsentCommentsMappedProps => {
  let unsentNotifications = 0
  const engagementId = ensureNumber(props.engagementId)
  const engagementActivities = state.activities[engagementId] || {}
  const isInternal = state.auth.user ? !state.auth.user.isExternal : false
  for (const questionId in engagementActivities) {
    const questionActivities = engagementActivities[questionId]
    if (!questionActivities) {
      continue
    }
    for (const activity of questionActivities) {
      if (!activity.mentions) {
        continue
      }
      if (activity.sentDate) {
        continue
      }
      for (const mention of activity.mentions) {
        if (mention.resolved) {
          continue
        }
        if (isInternal && mention.label !== 'RSM') {
          unsentNotifications++
          break
        }
        if (!isInternal && mention.label === 'RSM') {
          unsentNotifications++
          break
        }
      }
    }
  }

  return {
    unsentNotifications,
    engagementId: props.match.params.engagementId,
  }
}

type UnsentCommentsProps = UnsentCommentsOwnProps & UnsentCommentsMappedProps

export class UnsentComments extends React.Component<UnsentCommentsProps> {
  id: string
  container?: HTMLDivElement

  constructor(props: UnsentCommentsProps) {
    super(props)
    nextId += 1
    this.id = `engagementEmail${nextId}`
  }

  sendEmail = () => {
    const {
      props: { engagementId, history, match },
    } = this
    history.push(
      joinPaths(match.url, `engagements/${engagementId}/comments/unsent`)
    )
  }

  render() {
    const {
      sendEmail,
      props: { unsentNotifications },
    } = this
    return (
      <div className='email-send-menu'>
        <PermissionContent permission={UserPermissions.SendEmailCanView}>
          <button
            className='btn email-send-menu-button'
            id={this.id}
            onClick={sendEmail}
            aria-haspopup='true'
            aria-label='Send unsent emails'
          >
            <Icon icon='notification' />
            {unsentNotifications > 0 ? (
              <span
                className={classNames('badge', {
                  ten: unsentNotifications > 9 && unsentNotifications < 100,
                  over: unsentNotifications > 99,
                })}
              >
                {unsentNotifications}
              </span>
            ) : (
              <span />
            )}
          </button>
        </PermissionContent>
      </div>
    )
  }
}

export default connect(mapStateToProps)(UnsentComments)

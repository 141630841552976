import moment from 'moment'
import * as React from 'react'
import { FaClipboard } from 'react-icons/fa'
import { IdentityTokenProfile } from '../../clientModels'

interface UserInfoProps {
  user?: IdentityTokenProfile
  accessToken?: string
}

/**
 * This is just a throwaway component for developer testing.
 */
class UserInfo extends React.Component<UserInfoProps> {
  // tslint:disable-next-line:no-any - debug use only
  refresh: any
  tokenArea?: HTMLTextAreaElement

  componentDidMount() {
    this.refresh = setInterval(() => this.forceUpdate(), 5000)
  }

  componentWillUnmount() {
    clearInterval(this.refresh)
    delete this.refresh
  }

  handleCopyTokenClick = () => {
    if (this.tokenArea) {
      this.tokenArea.select()
      try {
        document.execCommand('copy')
        const selection = document.getSelection()
        if (selection) {
          selection.removeAllRanges()
        }
      } catch (err) {
        alert(err.message)
      }
    }
  }

  textAreaRef = (ref: HTMLTextAreaElement) => (this.tokenArea = ref)
  render() {
    const { user, accessToken } = this.props
    return (
      <div>
        <h1>User Info</h1>
        {!user && <div>No user info found.</div>}
        {user && (
          <dl className='row'>
            <dd className='col-sm-4'>Username</dd>
            <dt className='col-sm-8'>{user.uniqueId}</dt>
            <dd className='col-sm-4'>Name</dd>
            <dt className='col-sm-8'>{user.name}</dt>
            <dd className='col-sm-4'>Token Expiration</dd>
            <dt className='col-sm-8'>
              {moment(user.exp * 1000).format('llll')}
            </dt>
            <dd className='col-sm-4'>Time Remaining</dd>
            <dt className='col-sm-8'>
              {moment
                .duration(moment(user.exp * 1000).diff(moment()))
                .humanize()}
            </dt>
            <dd className='col-sm-4'>Access Token</dd>
            <dt className='col-sm-6'>
              <textarea
                ref={this.textAreaRef}
                defaultValue={accessToken}
                style={{ width: '100%' }}
              />
            </dt>
            <dt className='col-sm-2'>
              <FaClipboard
                onClick={this.handleCopyTokenClick}
                style={{ height: 24, width: 24, cursor: 'pointer' }}
              />
            </dt>
          </dl>
        )}
      </div>
    )
  }
}

export default UserInfo

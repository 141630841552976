import React, { useState, useRef } from 'react'
import { Option } from '../../clientModels'
import './FilterSortDropdown.scss'
import SmoothCollapse from 'react-smooth-collapse'
import classNames from 'classnames'
import { useOnClickOutside } from '../../hooks'

interface FilterSortDropdownProps {
  options: Option[]
  onSelect: (value: string) => void
  value?: string | number
  label?: string
}

const FilterSortDropdown = (props: FilterSortDropdownProps) => {
  const { options, onSelect, value, label } = props
  const [isOpen, setisOpen] = useState(false)
  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    close()
  })

  const open = () => {
    setisOpen(true)
  }

  const close = () => {
    setisOpen(false)
  }

  const optionSelected = (e: React.MouseEvent<HTMLDivElement>) => {
    onSelect(e.currentTarget.id)
    close()
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.charCode === 13 || e.charCode === 32) {
      onSelect(e.currentTarget.id)
      close()
    }
  }

  return (
    <div ref={ref} className='filter-sort-dropdown'>
      <div className='label'>{label}</div>
      <div className='filter-sort-button' onClick={isOpen ? close : open}>
        <div className='button-label'>
          <div className='value'>
            {value && options.map(o => (o.value === value ? o.label : null))}
          </div>
        </div>
        <div className='divider' />
        <div className='arrow'>
          <div className='triangle-down' />
        </div>
      </div>
      <div className='filter-sort-items'>
        <SmoothCollapse expanded={isOpen}>
          <div className='tcont'>
            {options.map((o, i) => {
              const selected = o.value === value
              return (
                <div
                  key={i}
                  id={o.value}
                  onClick={!selected ? optionSelected : undefined}
                  onKeyPress={
                    !selected
                      ? (e: React.KeyboardEvent<HTMLDivElement>) => {
                          close()
                          onKeyPress(e)
                        }
                      : undefined
                  }
                  className={classNames({ selected })}
                  tabIndex={!selected ? 0 : undefined}
                >
                  {o.label}
                </div>
              )
            })}
          </div>
        </SmoothCollapse>
      </div>
    </div>
  )
}

export default FilterSortDropdown

import { EngagementSectionGroup } from '../clientModels'
import { TsaHubAction } from '../actions/index'
import * as engagementActions from '../actions/engagementActions'
import { getType } from 'typesafe-actions'

/**
 * Holds data related to the groups of engagement sections
 */
export interface EngagementSectionGroupsState {
  taxYear: string
  taxForm: string
  sectionGroups: EngagementSectionGroup[]
}

const initialState: EngagementSectionGroupsState = {
  sectionGroups: [],
  taxForm: '',
  taxYear: ''
}

export function engagementSectionGroupsReducer(
  state: EngagementSectionGroupsState = initialState,
  action: TsaHubAction
): EngagementSectionGroupsState {
  switch (action.type) {
    case getType(engagementActions.setEngagementSectionGroupsAction):
      return action.payload
    default:
      return state
  }
}

import * as React from 'react'
import { AppInsights } from '../../services/appInsights/index'

interface PageViewProps {
  pathname?: string
}

/**
 * Tracks page views in App Insights.
 */
class PageView extends React.Component<PageViewProps> {
  componentDidMount () {
    const pathname = this.props.pathname || window.location.pathname
    AppInsights.trackPageView(pathname)
  }

  render () {
    return null
  }
}

export default PageView

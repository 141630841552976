import { HttpRequestState } from '../enums'
import { REACT_APP_IDMAUTHENTICATIONEULASERVICEURL } from '../envVariables'
import * as http from '../services/http'
import { useCallback, useState } from 'react'

export const useTermsOfUse = () => {
  const baseUrl = REACT_APP_IDMAUTHENTICATIONEULASERVICEURL

  const [requestStatus, setRequestStatus] = useState<HttpRequestState>('Idle')
  const [termOfUse, setTermOfUse] = useState('')

  const getTermsOfUse = useCallback(
    async (termsOfUseId: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        const response = await http.get(
          `${baseUrl}/api/eulas/${termsOfUseId}/content`
        )
        if (response) {
          setTermOfUse(response)
          setRequestStatus('Success')
        } else {
          setRequestStatus('Failed')
        }
      } catch (error) {
        setRequestStatus('Failed')
      }
    },
    // eslint-disable-next-line
    [baseUrl]
  )

  return { getTermsOfUse, requestStatus, termOfUse }
}

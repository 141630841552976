import * as React from 'react'
import { IconProperties } from './properties'

const Lock: React.SFC<IconProperties> = ({
  className,
  active,
  onClick,
  tooltip,
}) => {
  if (active) {
    return (
      <svg
        viewBox='0 -20 448 542'
        height='1em'
        width='1em'
        className={'locked ' + className}
        onClick={onClick}
        focusable='false'
        data-tip={tooltip}
      >
        <path d='M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z' />
      </svg>
    )
  } else {
    return (
      <svg
        viewBox='0 -20 448 542'
        height='1em'
        width='1em'
        className={'unlocked ' + className}
        onClick={onClick}
        focusable='false'
        data-tip={tooltip}
      >
        <path d='M400 256H152V152.9c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v16c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-16C376 68 307.5-.3 223.5 0 139.5.3 72 69.5 72 153.5V256H48c-26.5 0-48 21.5-48 48v160c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z' />
      </svg>
    )
  }
}

export default Lock

import classNames from 'classnames'
import * as React from 'react'
import { QuestionTuple } from '../../clientModels'
import { sortByDisplayOrder } from '../../sorting'
import { QuestionContainer } from '../question/questionContainer'
import './questionList.scss'

interface QuestionListProps {
  engagementId: number
  onSelectQuestion: (questionId: number) => void
  questions: QuestionTuple[]
  selectedQuestionHasError: boolean
  selectedQuestionId: number
}

class QuestionList extends React.Component<QuestionListProps> {
  render () {
    const {
      engagementId,
      questions,
      onSelectQuestion,
      selectedQuestionId,
      selectedQuestionHasError,
    } = this.props
    return (
      <div
        className={classNames('question-list', {
          error: selectedQuestionHasError,
        })}
      >
        {questions.sort(sortTupleByDisplayOrder).map(t => (
          <QuestionContainer
            engagementId={engagementId}
            engagementQuestion={t.engagementQuestion}
            key={t.question.id}
            onSelectQuestion={onSelectQuestion}
            question={t.question}
            selected={t.question.id === selectedQuestionId}
          />
        ))}
      </div>
    )
  }
}

function sortTupleByDisplayOrder (t1: QuestionTuple, t2: QuestionTuple) {
  return sortByDisplayOrder(t1.question, t2.question)
}

// export default connect(mapStateToProps)(QuestionList)
export default QuestionList

import classNames from 'classnames'
import { Location } from 'history'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { EntityMap } from '../../clientModels'
import displayName from '../../displayName'
import store, { AppState, TsaDispatch } from '../../store'
import * as openerAction from './openerActions'
import { OpenState } from './openerReducer'
import Swipe from './swipe'

type OpenDirection = 'down' | 'up' | 'left' | 'right'

export interface OpenerOwnProps extends RouteComponentProps<{}> {
  onOpen?: () => void
  onClose?: () => void
  onTransitionEnd?: () => void
  id?: number | string
  className?: string
  openPath?: string
}

export interface OpenerMappedProps {
  openers?: EntityMap<OpenState>
}

export function isDisabled(openState: OpenState | undefined): boolean {
  return !!openState && openState.isDisabled
}

export function isOpen(openState: OpenState | undefined): boolean {
  return !!openState && openState.isOpen
}

export interface OpenerDispatchProps {
  dispatchDisable?: (openerId: string | number) => void
  dispatchEnable?: (openerId: string | number) => void
  dispatchOpen?: (openerId: string | number) => void
  dispatchClose?: (openerId: string | number) => void
  dispatchToggle?: (openerId: string | number) => void
  location?: Location
}

export interface Opener {
  isOpen: boolean
  open: () => void
  close: () => void
  disable: () => void
  enable: () => void
  toggle: () => void
  handleKeyPress: (e: React.KeyboardEvent<HTMLElement>) => void
}

export interface OpenerInjectedProps {
  opener: Opener
}

interface OpenerState {
  isOpen: boolean
  isDisabled: boolean
}

interface OpenerOptions {
  closeOnClickOutside?: boolean
  closeOnClickInside?: boolean
  openOnClickInside?: boolean
  enableKeyboardNavigation?: boolean
  openDirection?: OpenDirection
  groupName?: string
  onlyOneOpen?: boolean
  disableSwipe?: boolean
  openOnMouseOver?: boolean
  closeOnMouseOut?: boolean
  closeOnNavigate?: boolean
}

const KEYCODES = {
  Enter: 13,
  Space: 32,
  UpArrow: 38,
  DownArrow: 40,
  LeftArrow: 37,
  RightArrow: 39,
}

const SWIPEDIRECTION = {
  Up: 'up',
  Down: 'down',
  Left: 'left',
  Right: 'right',
}

let currentGroupId = 0

/**
 * The withOpener function is a higher order component that adds open/closed state.
 * The wrapped component will receive an "opener" prop that contains
 * the isOpen state variable as well as callbacks for changing that state.
 */
export const withOpener = ({
  openOnClickInside = true,
  enableKeyboardNavigation = true,
  openDirection = 'down',
  onlyOneOpen = false,
  groupName,
  closeOnClickInside,
  closeOnClickOutside,
  disableSwipe,
  openOnMouseOver,
  closeOnMouseOut,
  closeOnNavigate,
}: OpenerOptions = {}) => {
  return <TWrappedComponentProps extends OpenerInjectedProps>(
    WrappedComponent: React.ComponentType<TWrappedComponentProps>
  ) => {
    const myGroupName: string = groupName || `OpenerGroup${++currentGroupId}`

    let currentOpenerId = 0

    type OpenerInternalClassProps = Subtract<
      TWrappedComponentProps,
      OpenerInjectedProps
    > &
      OpenerOwnProps &
      OpenerDispatchProps &
      OpenerMappedProps

    class OpenerInternal extends React.Component<
      OpenerInternalClassProps,
      OpenerState
    > {
      static defaultProps = {}
      static displayName = displayName('Opener', WrappedComponent)

      isTouch?: boolean
      wrapper?: HTMLDivElement
      keyPress?: Map<number, () => void>
      swipeDirection?: Map<string, () => void>
      swipe: Swipe

      myOpenerId: string | number = ++currentOpenerId

      state: OpenerState = {
        isDisabled: false,
        isOpen: false,
      }

      constructor(props: OpenerInternalClassProps) {
        super(props)

        if (props.id) {
          this.myOpenerId = props.id
        }

        this.updateKeyActions()
        const openers = props.openers
        if (openers) {
          const openerIsOpen = isOpen(openers[this.myOpenerId])
          this.state.isOpen = openerIsOpen
          openerIsOpen ? this._bindEvents() : this._unbindEvents()
        }

        this.swipeLeft = this.swipeLeft.bind(this)
        this.swipeRight = this.swipeRight.bind(this)
        this.swipeUp = this.swipeUp.bind(this)
        this.swipeDown = this.swipeDown.bind(this)
        this.swipe = new Swipe(
          this.swipeLeft,
          this.swipeRight,
          this.swipeUp,
          this.swipeDown
        )

        this.checkPath(props)
      }

      componentDidMount() {
        store.dispatch(openerAction.setAllowOnlyOne(myGroupName, onlyOneOpen))
      }

      // eslint-disable-next-line
      UNSAFE_componentWillReceiveProps(nextProps: OpenerInternalClassProps) {
        const openers = nextProps.openers
        if (openers) {
          const newIsOpen = isOpen(openers[this.myOpenerId])
          if (this.state.isOpen !== newIsOpen) {
            this.setState({ isOpen: newIsOpen }, () => {
              this.state.isOpen ? this._bindEvents() : this._unbindEvents()
            })

            const { onOpen, onClose } = nextProps
            if (this.state.isOpen && onClose) {
              onClose()
            }
            if (!this.state.isOpen && onOpen) {
              onOpen()
            }
          }
        }
        const currentLocation = this.props.location
        const newLocation = nextProps.location

        const locationChanged = currentLocation !== newLocation
        const openPathChanged =
          !!nextProps.openPath &&
          (!this.props.openPath || this.props.openPath !== nextProps.openPath)

        if (locationChanged && closeOnNavigate) {
          this.close()
        }

        if (nextProps.openPath) {
          if (locationChanged || openPathChanged) {
            this.checkPath(nextProps)
          }
        }
      }

      checkPath(props: OpenerInternalClassProps) {
        if (!props.openPath || !props.location) {
          return
        }
        if (props.openPath === props.location.pathname) {
          if (!this.state.isOpen) {
            this.open()
          }
        } else {
          if (this.state.isOpen) {
            this.close()
          }
        }
      }

      updateKeyActions() {
        /* eslint-disable func-call-spacing */
        this.keyPress = new Map<number, () => void>()
        this.swipeDirection = new Map<string, () => void>()
        this.keyPress.set(KEYCODES.Enter, this.toggle)
        this.keyPress.set(KEYCODES.Space, this.toggle)
        switch (openDirection) {
          case 'left':
            this.swipeDirection.set(SWIPEDIRECTION.Up, () => {
              /**/
            })
            this.swipeDirection.set(SWIPEDIRECTION.Down, () => {
              /**/
            })
            this.swipeDirection.set(SWIPEDIRECTION.Left, this.open)
            this.swipeDirection.set(SWIPEDIRECTION.Right, this.close)
            this.keyPress.set(KEYCODES.LeftArrow, this.open)
            this.keyPress.set(KEYCODES.RightArrow, this.close)
            break
          case 'right':
            this.swipeDirection.set(SWIPEDIRECTION.Up, () => {
              /**/
            })
            this.swipeDirection.set(SWIPEDIRECTION.Down, () => {
              /**/
            })
            this.swipeDirection.set(SWIPEDIRECTION.Left, this.close)
            this.swipeDirection.set(SWIPEDIRECTION.Right, this.open)
            this.keyPress.set(KEYCODES.LeftArrow, this.close)
            this.keyPress.set(KEYCODES.RightArrow, this.open)
            break
          case 'up':
            this.swipeDirection.set(SWIPEDIRECTION.Up, this.open)
            this.swipeDirection.set(SWIPEDIRECTION.Down, this.close)
            this.swipeDirection.set(SWIPEDIRECTION.Left, () => {
              /**/
            })
            this.swipeDirection.set(SWIPEDIRECTION.Right, () => {
              /**/
            })
            this.keyPress.set(KEYCODES.UpArrow, this.open)
            this.keyPress.set(KEYCODES.DownArrow, this.close)
            break
          case 'down':
          default:
            this.swipeDirection.set(SWIPEDIRECTION.Up, this.close)
            this.swipeDirection.set(SWIPEDIRECTION.Down, this.open)
            this.swipeDirection.set(SWIPEDIRECTION.Left, () => {
              /**/
            })
            this.swipeDirection.set(SWIPEDIRECTION.Right, () => {
              /**/
            })
            this.keyPress.set(KEYCODES.UpArrow, this.close)
            this.keyPress.set(KEYCODES.DownArrow, this.open)
        }
      }

      disable = () => {
        const dispatchDisable = this.props.dispatchDisable
        if (dispatchDisable) {
          dispatchDisable(this.myOpenerId)
        }
      }

      enable = () => {
        const dispatchEnable = this.props.dispatchEnable
        if (dispatchEnable) {
          dispatchEnable(this.myOpenerId)
        }
      }

      open = () => {
        const dispatchOpen = this.props.dispatchOpen

        if (dispatchOpen) {
          dispatchOpen(this.myOpenerId)
        }
      }

      close = () => {
        const dispatchClose = this.props.dispatchClose
        if (dispatchClose) {
          dispatchClose(this.myOpenerId)
        }
      }

      toggle = () => {
        const dispatchToggle = this.props.dispatchToggle
        if (dispatchToggle) {
          dispatchToggle(this.myOpenerId)
        }
      }

      handleMyKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.target === this.wrapper) {
          this.handleKeyPress(e)
        }
      }

      handleMyClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!this.state.isOpen) {
          this.open()
        }
      }

      handleMouseOver = () => {
        setTimeout(() => {
          this.open()
        }, 10)
      }

      handleMouseOut = () => {
        this.close()
      }

      handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        const { keyPress } = this
        const action = keyPress && keyPress.get(e.keyCode)
        if (action) {
          action()
          e.preventDefault()
        }
      }

      // This could be refactored to use react-onclickoutside. We wrote this before it was added.
      handleClickOutside = (e: MouseEvent | TouchEvent) => {
        if (e.type === 'touchend') {
          this.isTouch = true
        }
        if (e.type === 'click' && this.isTouch) {
          return
        }

        if (e.target instanceof Element) {
          const wrapper = this.wrapper
          const inside = wrapper && wrapper.contains(e.target)
          if (
            (inside && closeOnClickInside) ||
            (!inside && closeOnClickOutside)
          ) {
            setTimeout(() => {
              this.close()
            }, 10)
          }
        }
      }

      setWrapperRef = (ref: HTMLDivElement) => {
        this.wrapper = ref
      }

      // tslint:disable:no-any - <WrappedComponent {...chilrenProps as any} /> https://github.com/Microsoft/TypeScript/issues/28748
      render() {
        const {
          setWrapperRef,
          open,
          close,
          toggle,
          handleKeyPress,
          disable,
          enable,
        } = this
        const openerProp = {
          close,
          handleKeyPress,
          isDisabled: this.state.isDisabled,
          isOpen: this.state.isOpen,
          open,
          toggle,
          disable,
          enable,
        }
        const chilrenProps = Object.assign({}, this.props, {
          opener: openerProp,
        })
        const { className, onTransitionEnd } = this.props
        return (
          <div
            ref={setWrapperRef}
            className={classNames(className, { open: this.state.isOpen })}
            onClick={
              !this.state.isDisabled && openOnClickInside
                ? this.handleMyClick
                : undefined
            }
            onKeyDown={
              enableKeyboardNavigation ? this.handleMyKeyPress : undefined
            }
            tabIndex={enableKeyboardNavigation ? 0 : undefined}
            onTouchStart={
              !disableSwipe ? this.swipe.handleTouchStart : undefined
            }
            onTouchMove={!disableSwipe ? this.swipe.handleTouchMove : undefined}
            onTouchEnd={!disableSwipe ? this.swipe.handleTouchEnd : undefined}
            onMouseOver={openOnMouseOver ? this.handleMouseOver : undefined}
            onMouseOut={closeOnMouseOut ? this.handleMouseOut : undefined}
            onTransitionEnd={onTransitionEnd}
          >
            <WrappedComponent {...(chilrenProps as any)} />
          </div>
        )
      }

      _bindEvents() {
        if (closeOnClickInside || closeOnClickOutside) {
          // We are using a capturing event handler so that our callback gets called before the
          // event is sent to the React delegating handler
          document.addEventListener('click', this.handleClickOutside, true)
        }
      }

      _unbindEvents() {
        document.removeEventListener('click', this.handleClickOutside, true)
      }

      swipeLeft() {
        const action =
          this.swipeDirection && this.swipeDirection.get(SWIPEDIRECTION.Left)
        if (action) {
          action()
        }
      }

      swipeRight() {
        const action =
          this.swipeDirection && this.swipeDirection.get(SWIPEDIRECTION.Right)
        if (action) {
          action()
        }
      }

      swipeUp() {
        const action =
          this.swipeDirection && this.swipeDirection.get(SWIPEDIRECTION.Up)
        if (action) {
          action()
        }
      }

      swipeDown() {
        const action =
          this.swipeDirection && this.swipeDirection.get(SWIPEDIRECTION.Down)
        if (action) {
          action()
        }
      }
    }

    const mapStateToProps = (
      state: AppState,
      props: OpenerOwnProps
    ): OpenerMappedProps => {
      return {
        openers: ((state.opener || {})[myGroupName] || {}).openers,
      }
    }

    const mapDispatchToProps = (
      dispatch: TsaDispatch,
      props: OpenerOwnProps
    ): OpenerDispatchProps => {
      return {
        dispatchDisable: (openerId: string | number) =>
          dispatch(openerAction.disable(myGroupName, openerId)),
        dispatchEnable: (openerId: string | number) =>
          dispatch(openerAction.enable(myGroupName, openerId)),
        dispatchOpen: (openerId: string | number) =>
          dispatch(openerAction.open(myGroupName, openerId)),
        dispatchClose: (openerId: string | number) =>
          dispatch(openerAction.close(myGroupName, openerId)),
        dispatchToggle: (openerId: string | number) =>
          dispatch(openerAction.toggle(myGroupName, openerId)),
      }
    }

    return withRouter(
      connect(mapStateToProps, mapDispatchToProps)(OpenerInternal as any)
    )
  }
}

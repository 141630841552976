import classNames from 'classnames'
import moment from 'moment'
import * as React from 'react'
import { Engagement, IdentityTokenProfile } from '../../clientModels'
import { PhaseCode } from '../../enums'
import {
  getClientDueDatePhrase,
  isExternalUser,
  MilestoneInfo,
} from './utilities'

export interface DueDateHeaderProps {
  dueDate: moment.Moment
  engagement?: Engagement
  milestoneDetails: MilestoneInfo[]
  user?: IdentityTokenProfile
}

export const DueDateHeader: React.SFC<DueDateHeaderProps> = ({
  dueDate,
  engagement,
  milestoneDetails,
  user,
}) => {
  const engagementPhase = (engagement && engagement.phase) || undefined
  const milestones = (engagement && engagement.milestones) || []
  const irsMilestone = milestones.find(x => x.milestone === PhaseCode.IRS)
  const irsMsInfo = milestoneDetails.find(
    x => x.code === (irsMilestone && irsMilestone.milestone)
  )
  const remainingMessage = dueDate.fromNow(true)
  const now = moment.utc(new Date())
  const duration = moment.duration(now.diff(dueDate))
  const remainingDays = -duration.asDays()
  const dueDateClasses = {
    warning: remainingDays < 6 && remainingDays >= 4,
    error: remainingDays < 4,
    'dropdown-toggle': !isExternalUser(user),
  }
  const irsCaption =
    irsMilestone &&
    irsMsInfo &&
    irsMilestone.completedDate &&
    irsMsInfo.doneCaption
      ? irsMsInfo.doneCaption
      : irsMsInfo && irsMsInfo.caption

  return (
    <div
      className={classNames(
        'd-none d-xl-inline-flex align-items-center',
        dueDateClasses
      )}
    >
      {engagementPhase === PhaseCode.IRS ||
      engagementPhase === PhaseCode.Closed ? (
          <div className={'navbar-due-date'}>
            {irsMilestone &&
            irsCaption +
              ' ' +
              (irsMilestone.completedDate
                ? moment
                  .utc(irsMilestone.completedDate, moment.ISO_8601)
                  .format('l')
                : '')}
          </div>
        ) : (
          <div
            className={classNames('navbar-due-date', {
              warning: remainingDays < 6 && remainingDays >= 4,
              error: remainingDays < 4,
            })}
          >
            {remainingMessage} {remainingDays >= 0 ? 'REMAINING' : 'OVERDUE'}
            <span
              className={classNames('navbar-due-date-divider', {
                warning: remainingDays < 6 && remainingDays >= 4,
                error: remainingDays < 4,
              })}
            >
            |
            </span>
            {getClientDueDatePhrase(milestoneDetails, engagement, user)}{' '}
            {dueDate.format('l')}
          </div>
        )}
    </div>
  )
}

import classNames from 'classnames'
import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { NavLink } from 'react-router-dom'
import { actions } from '../../actions'
import { Client, Option, OptionWithDate } from '../../clientModels'
import { useRouteParams } from '../../hooks'
import {
  DashboardDateFilter,
  SelectionDashboardFilters
} from '../../reducers/selectionReducer'
import { AppState } from '../../store'
import { SubHeaderDropdown, SubHeaderDropdownWithDate } from '../dropdown'
import { FilterSortDropdown } from '../filterSortDropdown'
import InternalContent from '../internal/internalContent'
import { Pills } from '../pills'
import {
  DashboardDateFilterOptions,
  DashboardDateFilters,
  DashboardStatusFilterOptions,
  DashboardStatusFilters,
  DashboardTypeFilterOptions,
  DashboardTypeFilters,
  DashboardYearFilterOptions,
  DashboardYearFilters
} from './dashboardFilters'
import {
  DashboardSortOptions,
  DashboardSorts,
  DefaultDashboardSortExternal,
  DefaultDashboardSortInternal
} from './dashboardSorts'
import './engagementsListHeader.scss'

interface FilterOptions {
  typeOptions: Option[]
  statusOptions: Option[]
  dateOptions: Option[]
  yearOptions: Option[]
}

const addOptions = (
  optionsArray: Option[],
  fullOptions: Option[],
  selectedOptions: Array<string | number>
) => {
  for (const option of fullOptions) {
    if (selectedOptions.indexOf(option.value) > -1) {
      optionsArray.push({
        ...option,
        isDisabled: true
      })
    } else {
      optionsArray.push(option)
    }
  }
}

const findWithValue = (value: string) => {
  return (option: DashboardDateFilter) => {
    return !option.date && option.filter === value
  }
}

const addDateOptions = (
  optionsArray: Option[],
  fullOptions: Option[],
  selectedOptions: DashboardDateFilter[]
) => {
  for (const option of fullOptions) {
    if (selectedOptions.findIndex(findWithValue(option.value)) > -1) {
      optionsArray.push({
        ...option,
        isDisabled: true
      })
    } else {
      optionsArray.push(option)
    }
  }
}

const generateOptions = (
  selectedDashboardFilters?: SelectionDashboardFilters
): FilterOptions => {
  if (!selectedDashboardFilters) {
    return {
      typeOptions: DashboardTypeFilterOptions,
      statusOptions: DashboardStatusFilterOptions,
      dateOptions: DashboardDateFilterOptions,
      yearOptions: DashboardYearFilterOptions
    }
  }
  const {
    DateFilters,
    StatusFilters,
    TypeFilters,
    YearFilters
  } = selectedDashboardFilters
  const result: FilterOptions = {
    typeOptions: [],
    statusOptions: [],
    dateOptions: [],
    yearOptions: []
  }

  if (TypeFilters) {
    addOptions(result.typeOptions, DashboardTypeFilterOptions, TypeFilters)
  } else {
    result.typeOptions = DashboardTypeFilterOptions
  }

  if (YearFilters) {
    addOptions(result.yearOptions, DashboardYearFilterOptions, YearFilters)
  } else {
    result.yearOptions = DashboardYearFilterOptions
  }

  if (StatusFilters) {
    addOptions(
      result.statusOptions,
      DashboardStatusFilterOptions,
      StatusFilters
    )
  } else {
    result.statusOptions = DashboardStatusFilterOptions
  }

  if (DateFilters) {
    addDateOptions(result.dateOptions, DashboardDateFilterOptions, DateFilters)
  } else {
    result.dateOptions = DashboardDateFilterOptions
  }

  return result
}

const EngagementsListHeader = () => {
  const route = useRouteMatch<{ engagementId: string; entityGroupId: string }>()
  const entityGroupId = route.params.entityGroupId
  const dispatch = useDispatch()
  // Get the route parameters, so we can use them for filters if they exist.
  const { formName, taxYear } = useRouteParams()

  // Flags indicating whether or not the year and/or the form filters are disabled.
  const disableYearAndFormFilters = !!taxYear || !!formName

  const {
    engagementCount,
    selectedSort,
    selectedDashboardFilters,
    engagementTaxYears,
    engagementResourceTypes,
    clients,
    internal
  } = useSelector((state: AppState) => {
    const engagementCount = Object.keys(state.engagements).length
    const selectedSort = state.selection.selectedDashboardSort
      ? state.selection.selectedDashboardSort
      : state.auth.user!.isExternal
      ? DefaultDashboardSortExternal
      : DefaultDashboardSortInternal
    const selectedDashboardFilters = state.selection.selectedDashboardFilters
    const engagements = state.engagements
    const clients = state.clients
    const internal = !!state.auth.user && !state.auth.user.isExternal

    let engagementTaxYears: string[] = []
    let engagementResourceTypes: string[] = []

    for (const e in engagements) {
      const engagement = engagements[e]
      if (!engagement) continue

      const taxYear = engagement.taxYear
      if (taxYear && !engagementTaxYears.includes(taxYear)) {
        engagementTaxYears.push(taxYear)
      }

      const resourceType = engagement.engagementTaxForm
      if (resourceType && !engagementResourceTypes.includes(resourceType)) {
        engagementResourceTypes.push(resourceType)
      }
    }

    return {
      engagementCount,
      selectedSort,
      selectedDashboardFilters,
      engagements,
      engagementTaxYears,
      engagementResourceTypes,
      clients,
      internal
    }
  })

  const { statusOptions, dateOptions } = generateOptions(
    selectedDashboardFilters
  )

  useEffect(() => {
    dispatch(
      actions.selection.selectionSelectDashboardSortAction({
        sort: DefaultDashboardSortInternal
      })
    )
  }, [dispatch])

  // const filterTypes: StringLookup = {
  //   AllTypes: 'All Types',
  //   NineNinetyEz: '990 Ez',
  //   NineNinety: '990',
  //   Partnership: '1065',
  // }

  const filterStatus = new DashboardStatusFilters()

  const filterDates = new DashboardDateFilters()

  // const filterYears: StringLookup = {
  //   AllYears: 'All Years',
  //   TwentyEighteen: '2018',
  //   TwentyNineteen: '2019',
  // }

  const onSortChange = (sort: keyof DashboardSorts) => {
    dispatch(actions.selection.selectionSelectDashboardSortAction({ sort }))
  }

  const getClientHeader = (client?: Client) => {
    if (selectedDashboardFilters) {
      return undefined
    }

    return (
      <div className='title'>
        {client
          ? `${client.name} Engagements`
          : `My Engagement${
              engagementCount && engagementCount === 1 ? '' : 's'
            }`}
      </div>
    )
  }

  const onTypeFilterChange = (value: Option) => {
    dispatch(
      actions.selection.selectionAddDashboardTypeFilterAction({
        filter: value.value as keyof DashboardTypeFilters
      })
    )
  }

  const onStatusFilterChange = (value: Option) => {
    dispatch(
      actions.selection.selectionAddDashboardStatusFilterAction({
        filter: value.value as keyof DashboardStatusFilters
      })
    )
  }

  const onYearFilterChange = (value: Option) => {
    dispatch(
      actions.selection.selectionAddDashboardYearFilterAction({
        filter: value.value as keyof DashboardYearFilters
      })
    )
  }

  const onDateFilterChange = (value: OptionWithDate) => {
    dispatch(
      actions.selection.selectionAddDashboardDateFilterAction({
        filter: value.value as keyof DashboardDateFilters,
        date: value.selectedDate
      })
    )
  }

  const onTypeFilterClear = (value: Option) => {
    dispatch(
      actions.selection.selectionClearDashboardTypeFilterAction({
        filter: value.value as keyof DashboardTypeFilters
      })
    )
  }

  const onStatusFilterClear = (value: Option) => {
    dispatch(
      actions.selection.selectionClearDashboardStatusFilterAction({
        filter: value.value as keyof DashboardStatusFilters
      })
    )
  }

  const onYearFilterClear = (value: Option) => {
    dispatch(
      actions.selection.selectionClearDashboardYearFilterAction({
        filter: value.value as keyof DashboardYearFilters
      })
    )
  }

  const onDateFilterClear = (value: Option) => {
    dispatch(
      actions.selection.selectionClearDashboardDateFilterAction({
        filter: value.value as keyof DashboardDateFilters
      })
    )
  }

  const onAllClear = () => {
    dispatch(actions.selection.selectionClearAllDashboardFiltersAction({}))
  }

  const getFilters = () => {
    const typeFilters = selectedDashboardFilters?.TypeFilters
    const typeOptions: Option[] = [
      { label: 'All Types', value: 'AllTypes' }
    ].concat(
      engagementResourceTypes.map(x => ({
        label: x,
        value: x,
        isDisabled: typeFilters?.includes(x)
      }))
    )

    const yearFilters = selectedDashboardFilters?.YearFilters
    const yearOptions: Option[] = [
      { label: 'All Years', value: 'AllYears' }
    ].concat(
      engagementTaxYears.map(x => ({
        label: x,
        value: x,
        isDisabled: yearFilters?.includes(x)
      }))
    )

    return (
      <div className='filters'>
        <div className='filters-header'>Filter By:</div>
        <div className='filters-drops'>
          {!disableYearAndFormFilters && (
            <SubHeaderDropdown
              options={typeOptions}
              onChange={onTypeFilterChange}
              value={{ label: 'All Types', value: '' }}
            />
          )}
          <SubHeaderDropdown
            options={statusOptions}
            onChange={onStatusFilterChange}
            value={{ label: filterStatus.AllStatuses, value: '' }}
          />
          <SubHeaderDropdownWithDate
            options={dateOptions}
            onChange={onDateFilterChange}
            value={{ label: filterDates.AllDates, value: '' }}
            valuesToGrabDate={['EnterDueDate', 'EnterTaxYear']}
          />
          {!disableYearAndFormFilters && (
            <SubHeaderDropdown
              options={yearOptions}
              onChange={onYearFilterChange}
              value={{ label: 'All Years', value: '' }}
              disabled={disableYearAndFormFilters}
            />
          )}
        </div>
      </div>
    )
  }

  const getSorts = () => {
    return (
      <div className='sorts'>
        <FilterSortDropdown
          label='Sort By:'
          onSelect={onSortChange}
          options={DashboardSortOptions}
          value={selectedSort}
        />
      </div>
    )
  }

  const client = clients && entityGroupId ? clients[entityGroupId] : undefined
  let pillFilters:
    | Array<
        Array<{
          option: Option
          clear: (value: Option) => void
        }>
      >
    | undefined

  if (selectedDashboardFilters) {
    pillFilters = []
    if (selectedDashboardFilters.TypeFilters) {
      pillFilters.push([])
      for (const typeFilter of selectedDashboardFilters.TypeFilters) {
        pillFilters[pillFilters.length - 1].push({
          option: {
            value: `${typeFilter}`,
            label: `${typeFilter}`
          },
          clear: onTypeFilterClear
        })
      }
    }
    if (selectedDashboardFilters.StatusFilters) {
      pillFilters.push([])
      for (const statusFilter of selectedDashboardFilters.StatusFilters) {
        pillFilters[pillFilters.length - 1].push({
          option: {
            value: statusFilter + '',
            label: filterStatus[statusFilter] || statusFilter + ''
          },
          clear: onStatusFilterClear
        })
      }
    }

    if (selectedDashboardFilters.YearFilters) {
      pillFilters.push([])
      for (const yearFilter of selectedDashboardFilters.YearFilters) {
        pillFilters[pillFilters.length - 1].push({
          option: {
            value: `${yearFilter}`,
            label: `${yearFilter}`
          },
          clear: onYearFilterClear
        })
      }
    }

    if (selectedDashboardFilters.DateFilters) {
      pillFilters.push([])
      for (const dateFilter of selectedDashboardFilters.DateFilters) {
        let label: string | undefined
        if (dateFilter.date) {
          switch (dateFilter.filter) {
            case 'EnterDueDate':
              label = `Due before ${dateFilter.date.format('M/D/YYYY')}`
              break
            case 'EnterTaxYear':
              label = `Tax Year ending before ${dateFilter.date.format(
                'M/D/YYYY'
              )}`
              break
            default:
              break
          }
        }
        pillFilters[pillFilters.length - 1].push({
          option: {
            value: dateFilter.filter + '',
            label:
              label || filterDates[dateFilter.filter] || dateFilter.filter + ''
          },
          clear: onDateFilterClear
        })
      }
    }
  }

  return (
    <div
      className={classNames('engagements-list-header d-flex', {
        'has-filters': internal
      })}
    >
      <div className='title-container'>
        {client && (
          <div>
            <NavLink to='/'>&lt; Back To My Engagements</NavLink>
          </div>
        )}
        <div className='title-main-row'>
          {getClientHeader(client)}
          <InternalContent>{getFilters()}</InternalContent>
          <InternalContent>{getSorts()}</InternalContent>
        </div>
        <InternalContent>
          {pillFilters ? (
            <div className='engagements-list-header-pills-container'>
              <Pills values={pillFilters} />
              <div>
                <div onClick={onAllClear} className='clear-all-filters'>
                  Clear all filters
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </InternalContent>
      </div>
    </div>
  )
  // }
}

export default EngagementsListHeader

import classNames from 'classnames'
import { filter, flatten, map } from 'lodash'
import * as React from 'react'
import { Activity, IdentityTokenProfile, Mention } from '../../clientModels'
import { ActivityType } from '../../enums'
import * as ActivityFactory from '../../services/activityFactory'
import { Anchor } from '../anchor'
import { InlineComment } from '../comment'
import { Alert } from '../forms'
import Icon from '../icon/icon'
import { Icons } from '../icon/icons'
import { ActivityListItem } from './activityListItem'
import { safeJsonParse } from '../../services/jsonHelpers'
import ActivityGutter from './activityGutter'

const activityIcons: { [type in ActivityType]: keyof Icons } = {
  CarryForward: 'carryForward',
  CarryForwardAllComments: 'carryForwardAll',
  Comment: 'comments',
  Document: 'documents',
  Flag: 'flag',
  Lock: 'lock',
  LockEngagement: 'lock',
  Review: 'checkedCircle',
  NA: 'checkedCircle',
}

type CommentState = 'none' | 'reply' | 'carryForward'

export interface ActivityListGroupProps {
  childActivities?: Activity[]
  deleteActivity: (activityId: number) => void
  innerRef?: (ref: HTMLDivElement) => void
  isExternal: boolean
  parentActivity: Activity
  saveActivity: (activity: Activity) => void
  selected?: boolean
  user?: IdentityTokenProfile
  hasCarryAllCommentsForward: boolean
  isInSetupPhase: boolean
}

interface ActivityListGroupState {
  commentBox: CommentState
  commentValue: string
  displayError: boolean
}

const defaultState: ActivityListGroupState = {
  commentBox: 'none',
  commentValue: '',
  displayError: false,
}

export class ActivityListGroup extends React.Component<
  ActivityListGroupProps,
  ActivityListGroupState
> {
  handleResolve: () => void
  handleUnresolve: () => void

  constructor(props: ActivityListGroupProps) {
    super(props)
    this.state = { ...defaultState }
    this.handleResolve = this.handleMetions.bind(this, true)
    this.handleUnresolve = this.handleMetions.bind(this, false)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: ActivityListGroupProps) {
    const { childActivities } = this.props
    const currentCount = length(childActivities)
    const nextCount = length(nextProps.childActivities)
    if (currentCount !== nextCount) {
      this.closeComment()
    }
  }

  openComment = () => this.setState({ commentBox: 'reply' })

  handleToggleLockComment = () => {
    const { parentActivity, saveActivity } = this.props
    const propertyBag = safeJsonParse(parentActivity.propertyBag)
    if (propertyBag) {
      propertyBag.isLocked = !propertyBag.isLocked
      parentActivity.propertyBag = JSON.stringify(propertyBag)
      saveActivity(parentActivity)
    }
  }

  openCarryForward = () => this.setState({ commentBox: 'carryForward' })

  closeComment = () => this.setState({ ...defaultState })

  handleDelete = () => {
    const { parentActivity, deleteActivity } = this.props
    deleteActivity(parentActivity.id)
  }

  handleChildDelete = (activityId: number) => {
    const { deleteActivity } = this.props
    deleteActivity(activityId)
  }

  handleMetions(resolved: boolean) {
    const { parentActivity, saveActivity } = this.props
    if (parentActivity && parentActivity.mentions) {
      parentActivity.mentions.forEach(m => (m.resolved = resolved))
      saveActivity(parentActivity)
    }
  }

  handleCarryFoward = (comment: string) => {
    const { parentActivity, saveActivity } = this.props
    const carryFowardActivity = ActivityFactory.CreateCarryForwardCommentActivity(
      parentActivity,
      comment,
      true
    )
    saveActivity(carryFowardActivity)
  }

  handleReply = (comment: string) => {
    const { parentActivity, saveActivity } = this.props
    const commentActivity = ActivityFactory.CreateCommentActivity(
      parentActivity,
      comment
    )
    saveActivity(commentActivity)
  }

  handleRemoveCarryFoward = () => {
    const { parentActivity, saveActivity } = this.props
    const unCarryForwardActivity = ActivityFactory.CreateCarryForwardCommentActivity(
      parentActivity,
      '',
      false
    )
    saveActivity(unCarryForwardActivity)
  }

  handleCommentSave = (comment: string) => {
    const { commentBox } = this.state

    if (!comment) {
      this.setState({ displayError: true })
      return
    }

    if (commentBox === 'carryForward') {
      this.handleCarryFoward(comment)
    } else if (commentBox === 'reply') {
      this.handleReply(comment)
    }
    this.closeComment()
  }

  handleCommentChange = (commentValue: string) => {
    this.setState({
      commentValue,
      displayError: !commentValue,
    })
  }

  groupIcon() {
    const { parentActivity } = this.props
    if (parentActivity.fileGroupId) {
      return 'documents'
    }
    return activityIcons[parentActivity.activityTypeCode]
  }

  canDelete(activity: Activity) {
    const { user } = this.props
    return (
      (activity.activityTypeCode === 'Comment' &&
        activity.userId === (user && user.uniqueId)) ||
      ''
    )
  }

  // will look at the child actvities and determine if the actvity can be deleted.
  // only the last comment can be deleted.
  isLastComment(activity: Activity, childActivities: Activity[] | undefined) {
    // const { user } = this.props
    if (activity.activityTypeCode === 'Comment' && childActivities) {
      const lastActvity = childActivities[childActivities.length - 1]
      if (lastActvity) {
        if (lastActvity.id === activity.id) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
    return false
  }

  getLocked(parentActivity: Activity, mentions: Mention[]) {
    const obj = safeJsonParse(parentActivity.propertyBag)
    if (obj && obj.isLocked) {
      return obj.isLocked
    }
    return !this.canLockComment(parentActivity, mentions)
  }

  canLockComment(parentActivity: Activity, mentions: Mention[]) {
    return (
      !parentActivity.isExternal &&
      (mentions.length === 0 ||
        mentions.some(
          m =>
            m.value === 'RSM' ||
            (m.value?.match(/[A-Za-z]/) && !m.value?.match(/-/))
        ))
    )
  }

  render() {
    const {
      childActivities,
      innerRef,
      isExternal,
      parentActivity,
      selected,
      user,
      hasCarryAllCommentsForward,
      isInSetupPhase,
    } = this.props
    const { commentBox, displayError } = this.state

    // Mentions
    const mentions = [
      ...(parentActivity.mentions || []),
      ...flatten(
        map(
          filter(
            childActivities,
            (childActivity: Activity) =>
              !!childActivity.mentions && childActivity.mentions.length > 0
          ),
          childActivity => childActivity.mentions!
        )
      ),
    ]
    const hasUnresolvedMention = mentions.some(m => !m.resolved)
    const hasResolvedMention = mentions.some(m => m.resolved)
    const canChangeResolved =
      parentActivity.createdByInternalUser === !isExternal

    // Carry forward
    const isCarriedForward =
      isCarryForward(parentActivity) ||
      (childActivities && childActivities.some(isCarryForward))
    const canCarryForward =
      !isExternal && parentActivity.activityTypeCode === 'Comment'

    // Locked activity
    // An activity is locked if its parent has been carried forward
    // We have a separate flag for this because carry forward activities are never
    // sent to external users by the API
    const isCarryForwardLocked = parentActivity.isCarryForwardLocked

    const isLocked = this.getLocked(parentActivity, mentions)

    const showNotClientVisbile = !isExternal && !parentActivity.isExternal
    const showCarryForward =
      !isExternal &&
      ((isCarriedForward && !parentActivity.fileGroupId) ||
        (hasCarryAllCommentsForward && canCarryForward))
    const showLocked = !isExternal && (isCarryForwardLocked || isLocked)
    const showResolved = hasResolvedMention && !hasUnresolvedMention
    const placeholder = commentBox === 'carryForward' ? 'Comment required' : ''

    return (
      <div
        className={classNames('activity-list-group', { selected })}
        ref={innerRef}
      >
        <div className='activity-list-group-icon'>
          <Icon icon={this.groupIcon()} />
        </div>
        <div className='activity-list-group-content'>
          <ActivityListItem activity={parentActivity} user={user} />

          {childActivities &&
            childActivities.map(a => {
              const canDeleteChild =
                !isCarryForwardLocked &&
                this.canDelete(a) &&
                this.isLastComment(a, childActivities)

              // Child activity
              return (
                <ActivityListItem
                  key={a.id}
                  activity={a}
                  user={user}
                  onDelete={canDeleteChild ? this.handleChildDelete : undefined}
                />
              )
            })}

          <div>
            {commentBox === 'none' && (
              <Anchor onClick={this.openComment}>reply</Anchor>
            )}
            {commentBox === 'none' &&
              parentActivity.activityTypeCode === 'Comment' &&
              this.canLockComment(parentActivity, mentions) && (
                <Anchor onClick={this.handleToggleLockComment}>
                  {!isLocked ? 'lock' : 'unlock'}
                </Anchor>
              )}
            {commentBox !== 'none' && (
              <Anchor onClick={this.closeComment}>cancel</Anchor>
            )}
            {this.canDelete(parentActivity) &&
              !isCarryForwardLocked &&
              (!isLocked || isInSetupPhase) && (
                <Anchor onClick={this.handleDelete}>delete conversation</Anchor>
              )}
            {canChangeResolved && hasUnresolvedMention && (
              <Anchor onClick={this.handleResolve}>mark as resolved</Anchor>
            )}
            {canChangeResolved &&
              !hasUnresolvedMention &&
              hasResolvedMention && (
                <Anchor onClick={this.handleUnresolve}>
                  remove resolved status
                </Anchor>
              )}
            {!hasCarryAllCommentsForward &&
              canCarryForward &&
              !isCarriedForward && (
                <Anchor
                  className={classNames('', {
                    disabled: commentBox !== 'none',
                  })}
                  onClick={
                    commentBox === 'none' ? this.openCarryForward : undefined
                  }
                >
                  carry forward
                </Anchor>
              )}
            {!hasCarryAllCommentsForward && !isExternal && isCarriedForward && (
              <Anchor onClick={this.handleRemoveCarryFoward}>
                remove carry forward
              </Anchor>
            )}
          </div>

          {commentBox !== 'none' && (
            <InlineComment
              onCancel={this.closeComment}
              onChange={this.handleCommentChange}
              onSave={this.handleCommentSave}
              placeholder={placeholder}
            />
          )}

          {commentBox === 'carryForward' && displayError && (
            <Alert
              messages='A comment is required to carry this forward.'
              severity='error'
              type='msg'
            />
          )}
        </div>
        <ActivityGutter
          showResolved={showResolved}
          showCarryForward={showCarryForward}
          showLocked={showLocked}
          showNotClientVisbile={showNotClientVisbile}
        />
      </div>
    )
  }
}

// tslint:disable-next-line:no-any
function length(array?: any[]): number {
  if (!array) {
    return 0
  }
  return array.length
}

function isCarryForward(activity: Activity) {
  return (
    activity.activityTypeCode === 'CarryForward' ||
    activity.activityTypeCode === 'CarryForwardAllComments'
  )
}

import { AppInsights } from './appInsights/index'

export function safeJsonParse (json: string, context: string = '') {
  try {
    return JSON.parse(json)
  } catch (error) {
    AppInsights.trackException(error, undefined, { context, json })
    json = json.trim()
    if (json.startsWith('"') && json.endsWith('"')) {
      return json.substring(1, json.length - 1)
    } else {
      return json
    }
  }
}

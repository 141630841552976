import { batchedTrackEvent } from './appInsights/index'
import { getNameGenerator } from './performanceHelpers'

const supportsUserTiming =
  typeof performance !== 'undefined' &&
  typeof performance.mark === 'function' &&
  typeof performance.clearMarks === 'function' &&
  typeof performance.measure === 'function' &&
  typeof performance.clearMeasures === 'function'

export function perf (name: string, appInsights: boolean = false) {
  const { getMarkName, getMeasureName } = getNameGenerator(name)
  return {
    beginMark: (id?: string | number) => {
      if (supportsUserTiming) {
        performance.mark(getMarkName(id))
      }
    },
    endMark: (id?: string | number) => {
      if (supportsUserTiming) {
        const { markName, measureName } = getMeasureName(id)
        performance.measure(measureName, markName)
        if (appInsights) {
          const entries = performance.getEntriesByName(measureName)
          const entry = entries[0]
          if (entry) {
            batchedTrackEvent(measureName, entry.duration)
          }
        }
        performance.clearMarks(markName)
        performance.clearMeasures(measureName)
      }
    },
  }
}

import { IdentityTokenProfile } from '../clientModels'

export const toggleUserView = () => {
  // Saves the current state of impersonation to the session storage
  // so that when we make any http calls it knows what the current state is
  const impersonateUser =
    sessionStorage.getItem('ImpersonationUserId') === 'E000000'

  impersonateUser
    ? sessionStorage.setItem('ImpersonationUserId', '')
    : sessionStorage.setItem('ImpersonationUserId', 'E000000')
  // Reload the page so any api calls that were done before updating the state are refreshed
  window.location.reload(false)
}

export const hasPermission = (
  user: IdentityTokenProfile | undefined,
  permissions: string[],
  permission: string
): boolean => {
  // If permissions aren't enabled in this environment then always return true
  if (user?.permissionsEnabled === false) {
    return true
  }

  return permissions.includes(permission)
}

export const impersonatingUser =
  sessionStorage.getItem('ImpersonationUserId') === 'E000000'

import { AppState } from '../store'
import { Dispatch } from 'redux'
import { HubConnection } from '@microsoft/signalr'
import { SignalRListener, SignalRReducer } from './middleware'
import { getType } from 'typesafe-actions'
import { TsaHubAction } from '../actions'
import {
  signalRMessageReceived,
  signalRMessageSend
} from '../actions/signalRActions'

async function SignalRMessageReducer(
  state: () => AppState,
  dispatch: Dispatch<any>,
  connection: HubConnection,
  action: TsaHubAction
) {
  switch (action.type) {
    case getType(signalRMessageSend): {
      await connection.send('send', action.payload)
      break
    }
  }
}

const SignalRMessageListener: SignalRListener = {
  message: 'MessageCallback',
  type: 'on',
  callback: function(
    getState: () => AppState,
    dispatch: Dispatch<any>,
    message: string
  ) {
    dispatch(signalRMessageReceived(message))
  }
}

export const reducers: SignalRReducer[] = [SignalRMessageReducer]

export const listeners: SignalRListener[] = [SignalRMessageListener]

import moment from 'moment'
import {
  EngagementQuestion,
  FileGroup,
  IEngagementQuestionAnswer,
  Message,
  Option,
  StringLookup,
} from '../../clientModels'
import { isDefinedNotNull, isNull, isString } from '../../guards'

interface MessageSeverities {
  [severity: string]: boolean
}

export function messageSeverities(messages?: Message[]): MessageSeverities {
  const severities: MessageSeverities = {}
  if (!messages || messages.length === 0) {
    return severities
  }
  messages.forEach(m => (severities[m.severity] = true))
  return severities
}

function isError(message: Message) {
  const severity = message.severity
  return severity === 'error' || severity === 'critical'
}

export function isComplete(
  question?: EngagementQuestion,
  answer?: IEngagementQuestionAnswer
) {
  if (!question) {
    return false
  }

  return !question.messages.some(isError)
}

/**
 * Determines whether a question has an error that should be displayed to the user.
 * An error may exist due to a business rule but we don't display the error until
 * the user has answered the question.
 */
export function hasVisibleError(
  question?: EngagementQuestion,
  path?: string | string[]
) {
  const hasSavedAnswer =
    !!question &&
    question.answerId > 0 &&
    isDefinedNotNull(question.answerValue)
  return !!question && hasSavedAnswer && hasError(question.messages, path)
}

export function hasError(messages?: Message[], path?: string | string[]) {
  if (!messages || messages.length === 0) {
    return false
  }

  if (isString(path)) {
    return messages.some(m => isError(m) && m.path === path)
  } else if (path) {
    return messages.some(m => isError(m) && path.some(p => m.path === p))
  }
  return messages.some(isError)
}

function fileGroupIsEmpty(fileGroup?: FileGroup) {
  if (!fileGroup) {
    return true
  }
  if (fileGroup.notApplicable) {
    return false
  }
  return !fileGroup.files || fileGroup.files.length === 0
}

export function findErrors(
  fileGroups?: FileGroup[],
  docTitleId?: number,
  fileGroupId?: number,
  answer?: IEngagementQuestionAnswer,
  selected?: boolean,
  engagementQuestion?: EngagementQuestion
) {
  const result = {
    allMessages: [] as Message[],
    compMessages: [] as Message[],
    success: false as boolean,
  }

  if (!selected || !engagementQuestion) {
    return result
  }

  const messages = [...(engagementQuestion.messages || [])]

  // Don't show error messages until a user has answered the question
  if (
    !answer ||
    isNull(answer.answerValue) ||
    answer.notApplicable ||
    (answer.answerId <= 0 && !answer.isDirty)
  ) {
    return result
  }

  result.success = messages.length === 0

  let showDocErrors: boolean | undefined = !fileGroupId && !docTitleId

  if (!showDocErrors) {
    showDocErrors = !fileGroupId && !!docTitleId
    if (showDocErrors && fileGroups) {
      const fileGroup = fileGroups.find(f => f.documentTitleId === docTitleId)

      showDocErrors =
        !fileGroupId && !!docTitleId && fileGroupIsEmpty(fileGroup)
    }
  }

  for (const message of messages) {
    switch (message.type) {
      case 'docs':
        if (showDocErrors) {
          result.allMessages.push(message)
        }
        break
      default:
        result.compMessages.push(message)
        result.allMessages.push(message)
    }
  }

  return result
}

const severityWeights = {
  critical: 0,
  error: 1,
  warning: 2,
  success: 3,
}

export function sortMessages(lhs: Message, rhs: Message) {
  const m1 = severityWeights[lhs.severity]
  const m2 = severityWeights[rhs.severity]
  if (m1 < m2) {
    return -1
  } else if (m1 > m2) {
    return 1
  }
  return 0
}

// tslint:disable-next-line:no-any
export function by(...elements: Array<string | string[] | undefined>): string {
  // tslint:disable-next-line:no-any
  let all: any[] = []
  for (const element of elements) {
    if (element) {
      all = all.concat(element)
    }
  }
  return all.join(' ').trim()
}

export function charactersRemaining(value?: string, maxLength?: number) {
  if (maxLength && maxLength > 0) {
    const length = (value && value.length) || 0
    const remaining = Math.max(0, maxLength - length)
    return 'Characters remaining: ' + remaining
  }
  return ''
}

export const CodeNotInList = 'code-not-in-list'

/**
 * Convert an option to an object in which the value is
 * the property name and the label is the property value.
 */
export function optionToObject(option: Option | null) {
  if (!option) {
    return null
  }
  if (option.codeNotInList) {
    return { [CodeNotInList]: option.label }
  } else {
    return { [option.value]: option.label }
  }
}

/**
 * Convert a series of options to an object in which the value is
 * the property name and the label is the property value.
 */
export function optionsToObject(options: Option[] | null) {
  if (!options) {
    return null
  }
  const result: StringLookup = {}
  let newCodes = 0
  for (const option of options) {
    if (option.codeNotInList) {
      result[`${CodeNotInList}${newCodes === 0 ? '' : `-${newCodes}`}`] =
        option.label
      ++newCodes
    } else {
      result[option.value] = option.label
    }
  }
  return result
}

/**
 * Convert an object into an array of options. Each property on
 * the object will create an option in which the property name
 * is the option value and the property value is the option label.
 * @param object
 */
export function objectToOptions(object?: any) {
  const options: Option[] = []
  if (!object) {
    return options
  }
  for (const key in object) {
    if (key.startsWith(CodeNotInList)) {
      options.push({
        value: object[key],
        label: object[key],
        codeNotInList: true,
      })
    } else {
      options.push({
        value: key,
        label: object[key],
      })
    }
  }
  return options
}

const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export function convertToISODate(value: string | moment.Moment): string {
  if (!value) {
    return value as any
  }

  if (moment.isMoment(value)) {
    return value.format(ISO_DATE_FORMAT)
  }

  const date = moment(value, 'M/D/YYYY', true)

  return date.isValid() ? date.format(ISO_DATE_FORMAT) : value
}

export function dateInCorrectFormat(
  value: string | null | undefined
): moment.Moment | undefined {
  if (!value) {
    return
  }
  if (moment(value, moment.ISO_8601).isValid()) {
    return
  }
  const date = moment(value, 'M/D/YYYY', true)
  if (!date.isValid()) {
    // Can't figure out what it is, so ignore
    return
  }
  return date
}

import { History, Location } from 'history'
import * as React from 'react'
import { connect } from 'react-redux'
import { match, RouteComponentProps } from 'react-router'
import { actions } from '../../actions'
import {
  Activity,
  EngagementQuestionMap,
  EntityMap,
  QuestionMap,
} from '../../clientModels'
import {
  getActivitiesByEngagementId,
  getEngagementQuestions,
} from '../../reducers/selectors'
import { getTrackingId } from '../../services/track'
import { AppState, TsaDispatch } from '../../store'
import { joinPaths } from '../relativeLink'
import './carryForwardLibrary.scss'
import CommentSummary from './commentSummary'
import DocumentSummary from './documentSummary'
import { SummaryProps } from './misc'
interface OwnProperties extends RouteComponentProps<RouteProperties> {}

interface RouteProperties {
  engagementId: string
  questionId?: string
}

interface MappedProperties {
  questions: QuestionMap
  engagementQuestions: EngagementQuestionMap | undefined
  history: History
  location: Location
  match: match<RouteProperties>
  activities?: EntityMap<Activity[]>
}

interface DispatchProperties {
  getAllActivities: (engagementId: number) => string
}

type Properties = OwnProperties & MappedProperties & DispatchProperties

function mapStateToProps(
  state: AppState,
  props: OwnProperties
): MappedProperties {
  const engagementId = props.match.params.engagementId

  const result: MappedProperties = {
    history: props.history,
    match: props.match,
    location: props.location,
    questions: state.questions,
    engagementQuestions: getEngagementQuestions(state, engagementId),
    activities: getActivitiesByProps(state, props),
  }

  return result
}

function mapDispatchToProps(
  dispatch: TsaDispatch,
  props: OwnProperties
): DispatchProperties {
  return {
    getAllActivities: (engagementId: number) =>
      getTrackingId(dispatch, actions.activity.getAllActivities, engagementId),
  }
}

interface CarryForwardItem {
  questionId: number
  unitId: number
  displayOrder: number
  openPath: string
  activityOrder: number
  component: React.ComponentType<SummaryProps>
}

interface State {
  engagementId: number
  questionId?: number
  carryForwardItems: CarryForwardItem[]
  openItems: number
}

function getActivitiesByProps(
  state: AppState,
  props: RouteComponentProps<RouteProperties>
) {
  let engagementId = parseInt(props.match.params.engagementId, 10)
  if (isNaN(engagementId)) {
    engagementId = 0
  }
  return getActivitiesByEngagementId(state, engagementId)
}

class CarryForwardLibrary extends React.Component<Properties, State> {
  state: State
  getActivitiesId?: string

  constructor(props: Properties) {
    super(props)

    this.parseProps = this.parseProps.bind(this)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onViewContext = this.onViewContext.bind(this)
    this.openAddItems = this.openAddItems.bind(this)
    this.closeRemoveItems = this.closeRemoveItems.bind(this)
    this.afterClose = this.afterClose.bind(this)

    const {
      match: {
        params: { engagementId, questionId },
      },
    } = props

    this.state = {
      engagementId: parseInt(engagementId, 10),
      questionId: questionId ? parseInt(questionId, 10) : undefined,
      carryForwardItems: [],
      openItems: 0,
    }

    this.parseProps(this.state, props)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: Properties) {
    const { props, state } = this
    const nextState = { ...state }

    const selectedActivities = (nextProps.match.params.questionId &&
      nextProps.activities &&
      nextProps.activities[nextProps.match.params.questionId]) as Activity[]

    if (
      nextProps.match.params.questionId &&
      (!selectedActivities ||
        !selectedActivities.find(activity =>
          activity.activityTypeCode.startsWith('CarryForward')
        ))
    ) {
      this.props.history.push(
        joinPaths(
          this.props.match.url,
          `engagements/${state.engagementId}/carryforward`
        )
      )
    }

    let updated = false

    if (
      props.match.params.engagementId !== nextProps.match.params.engagementId
    ) {
      nextState.engagementId = parseInt(nextProps.match.params.engagementId, 10)
      updated = true
    }

    if (props.match.params.questionId !== nextProps.match.params.questionId) {
      const nextQuestionId = nextProps.match.params.questionId
      nextState.questionId = nextQuestionId
        ? parseInt(nextQuestionId, 10)
        : undefined
      updated = true
    }

    if (
      props.engagementQuestions !== nextProps.engagementQuestions ||
      props.questions !== nextProps.questions ||
      state.engagementId !== nextState.engagementId ||
      props.activities !== nextProps.activities
    ) {
      this.parseProps(nextState, nextProps)
      updated = true
    }

    if (updated) {
      this.setState(nextState)
    }
  }

  componentDidMount() {
    this.getActivitiesId = this.props.getAllActivities(
      Number(this.props.match.params.engagementId)
    )
  }

  parseProps(state: State, props: Properties) {
    if (!props.engagementQuestions) {
      state.carryForwardItems = []
      return
    }
    const carryForwardItems: CarryForwardItem[] = []
    let activityOrder = 0
    for (const questionId in props.engagementQuestions) {
      const eq = props.engagementQuestions[questionId]
      const q = props.questions[questionId]
      const a = q && props.activities && props.activities[q.id]
      if (!eq || !q || !eq.isVisible || !a || a.length === 0) {
        continue
      }
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < a.length; ++i) {
        const activity = a[i]
        ++activityOrder
        if (
          activity.activityTypeCode !== 'CarryForward' &&
          activity.activityTypeCode !== 'CarryForwardAllComments'
        ) {
          continue
        }

        const activityId = activity.parentId ? activity.parentId : activity.id

        const commentedQuestion: CarryForwardItem = {
          openPath: joinPaths(
            this.props.match.url,
            `carryforward/questions/${questionId}/comments/${activityId}`
          ),
          questionId: q.id,
          displayOrder: q.displayOrder,
          unitId: activityId,
          activityOrder,
          component: activity.fileGroupId ? DocumentSummary : CommentSummary,
        }
        carryForwardItems.push(commentedQuestion)
      }
    }
    state.carryForwardItems = carryForwardItems.sort(this.sort)
  }

  sort(lhs: CarryForwardItem, rhs: CarryForwardItem) {
    let result = lhs.displayOrder - rhs.displayOrder // order by question display order, asc
    if (result === 0) {
      result = rhs.activityOrder - lhs.activityOrder // order by creation date, desc
    }
    return result
  }

  onOpen(questionId: number, commentId: number) {
    const {
      openAddItems,
      props: { history, match },
    } = this
    history.replace(
      joinPaths(
        match.url,
        `carryforward/questions/${questionId}/comments/${commentId}`
      )
    )
    this.setState(openAddItems)
  }

  openAddItems({ openItems }: State) {
    this.setState({ openItems: openItems + 1 })
  }

  onClose(questionId: number, commentId: number) {
    const { closeRemoveItems, afterClose } = this
    setTimeout(() => {
      this.setState(closeRemoveItems, afterClose)
    }, 0)
  }

  closeRemoveItems({ openItems }: State) {
    this.setState({ openItems: openItems > 0 ? openItems - 1 : 0 })
  }

  afterClose() {
    const {
      state: { openItems },
      props: { history, match },
    } = this
    if (openItems === 0) {
      history.replace(
        joinPaths(
          match.url,
          `engagements/${match.params.engagementId}/carryforward`
        )
      )
    }
  }

  onViewContext(questionId: number, sectionId: number) {
    const {
      state: { engagementId },
      props: { history, match },
    } = this
    history.push(
      joinPaths(
        match.url,
        `engagements/${engagementId}/sections/${sectionId}/questions/${questionId}`
      )
    )
  }

  render() {
    const {
      onOpen,
      onClose,
      onViewContext,
      state: { carryForwardItems, engagementId },
    } = this

    if (carryForwardItems.length === 0) {
      return (
        <div className='carry-forward-library-no-content'>
          You have no items marked carry forward.
        </div>
      )
    }

    return (
      <div className='carry-forward-library'>
        {carryForwardItems.map((fq, i) => {
          const Component = fq.component
          return (
            <Component
              key={i}
              engagementId={engagementId}
              questionId={fq.questionId}
              unitId={fq.unitId}
              openPath={fq.openPath}
              onOpen={onOpen}
              onClose={onClose}
              onViewContext={onViewContext}
            />
          )
        })}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarryForwardLibrary)

import classNames from 'classnames'
import * as React from 'react'
import * as ReactTooltip from 'react-tooltip'
import './icon.scss'
import { Icons } from './icons'

export interface IconMap {
  [id: number]: keyof Icons
  [id: string]: keyof Icons
}

const iconLookup = new Icons()

export type IconClickHandler = (name: string, e: React.MouseEvent) => void

export interface IconProps {
  icon: keyof Icons
  name?: string
  className?: string
  /** If active is truthy then the icon will have the 'active' CSS class. */
  active?: any // tslint:disable-line:no-any
  onClick?: IconClickHandler
  children?: string
  tooltip?: string
}

class Icon extends React.Component<IconProps> {
  componentDidMount() {
    ReactTooltip.rebuild()
  }

  onClick = (e: React.MouseEvent) => {
    const { name, icon, onClick } = this.props
    if (onClick) {
      onClick(name || icon, e)
    }
  }

  componentWillUnmount() {
    // can't rely on mouseout to hide the tooltip
    // if the component is removed before mouseout
    // it will be stuck on the screen
    ReactTooltip.hide()
  }

  render() {
    const { active, className, icon, tooltip } = this.props

    const IconComponent = iconLookup[icon]
    const iconClassName = classNames('icon', { active }, className, icon)

    return (
      <IconComponent
        active={active}
        className={iconClassName}
        onClick={this.onClick}
        tooltip={tooltip}
        focusable={false}
      />
    )
  }
}

export default Icon

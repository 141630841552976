import { IdMap, Option, OptionListMap } from '../../clientModels'
import { TsaJsonSchema } from './jsonSchema'

export function optionsListLookup (
  fieldSchema?: TsaJsonSchema,
  codeListIdToOptionsId?: IdMap,
  optionLists?: OptionListMap
) {
  const codeListId = fieldSchema && fieldSchema.codeListId

  // If a codeListId was provided in the schema then find the options list for that code list
  let fieldOptions: Option[] | undefined
  if (codeListId && codeListIdToOptionsId && optionLists) {
    const optionsListId = codeListIdToOptionsId[codeListId] || -1
    fieldOptions = optionLists[optionsListId]
    if (!fieldOptions) {
      throw new Error(`Options list not found for field '${codeListId}'.`)
    }
  }

  return fieldOptions
}

import * as React from 'react'
import { Message, MessageSeverity } from '../../../clientModels'
import { sortMessages } from '../formUtilities'
import Alert, { AlertProps } from './alert'

export interface AlertsProps {
  messages?: Message | Message[]
  filter?: (message: Message) => boolean
}

class Alerts extends React.Component<AlertsProps> {
  render () {
    const { messages, filter } = this.props
    if (!messages) {
      return null
    }

    let all: Message[] = []
    all = all.concat(messages)

    const sorted = all.sort(sortMessages)
    const filtered = filter ? sorted.filter(filter) : sorted
    const alerts: React.ReactNode[] = []
    let alertProps: AlertProps
    let lastSeverity: MessageSeverity
    let strings: string[] = []
    filtered.forEach((m, i) => {
      if (lastSeverity !== m.severity) {
        lastSeverity = m.severity
        strings = [m.message || '']
        alertProps = { severity: m.severity, messages: strings, type: m.type }
        alerts.push(<Alert key={i} {...alertProps} />)
      } else {
        strings.push(m.message || '')
      }
    })

    return alerts
  }
}

export default Alerts

import classNames from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { Prompt, Redirect, RouteComponentProps } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { createSelector } from 'reselect'
import { actions } from '../../actions'
import * as Normalized from '../../clientModels'
import { ensureNumber, isDefined } from '../../guards'
import { handleLeavePage } from '../../services/eventListeners'
import { getTrackingId } from '../../services/track'
import { sortByDisplayOrder } from '../../sorting'
import { AppState, TsaDispatch } from '../../store'
import { CarryForwardLibrary } from '../carryForwardLibrary'
import { CommentLibrary } from '../commentLibrary'
import { DocLibrary } from '../docLibrary'
import { EngagementReports } from '../engagementReports'
import {
  EngagementSection,
  EngagementSectionQuestion,
} from '../engagementSection'
import { FlagLibrary } from '../flagLibrary'
import InternalRoute from '../internal/internalRoute'
import Loading from '../loading'
import { CriticalErrorDialog } from '../modals'
import './engagement.scss'
import { joinPaths } from '../relativeLink'
import DataFileManagement from '../dataFileManagement/dataFileManagement'
import { UserPermissions } from '../../enums'
import EngagementDashboard from './engagementDashboard'
import { CanAccessEngagement } from './canAccessEngagement'
import { RouteParams } from '../../hooks/useRouteParams'
import { getClientUrlPortion } from '../../services/filterHelpers'

interface EngagementRouteParams extends Record<keyof RouteParams, string> {
  engagementId: string
  entityGroupId?: string
  sectionId?: string
  questionId?: string
  edit?: string
}

interface EngagementOwnProps
  extends RouteComponentProps<EngagementRouteParams> {}

export interface EngagementDispatchProps {
  getEngagement: (engagementId: string) => string
  acknowledgeCriticalErrorAction: (
    engagementId: number,
    questionId: number
  ) => void
  setEngagementCritical: (engagementId: number, questionId: number) => void
  setEngagementNotCritical: (engagementId: number) => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch
): EngagementDispatchProps => ({
  getEngagement: (engagementId: string) =>
    getTrackingId(dispatch, actions.engagement.getEngagement, engagementId),
  setEngagementCritical: (engagementId: number, questionId: number) =>
    dispatch(
      actions.engagement.setEngagementCritical(engagementId, questionId)
    ),
  setEngagementNotCritical: (engagementId: number) =>
    dispatch(actions.engagement.setEngagementNotCritical(engagementId)),
  acknowledgeCriticalErrorAction: (engagementId: number, questionId: number) =>
    dispatch(
      actions.engagementQuestion.acknowledgeCriticalErrorAction({
        engagementId,
        questionId,
      })
    ),
})

// We aren't worrying about creating unique selectors for each instance of the component
// since we expect only one Engagement to be rendered in the app at one time.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const hasDirtyAnswerSelector = createSelector(
  (state: AppState, props: EngagementOwnProps) =>
    state.engagementQuestions[props.match.params.engagementId] || {},
  answers => {
    const dirtyAnswer = Object.values(answers)
      .filter(isDefined)
      .find(a => !!a.isDirty)
    return !!dirtyAnswer
  }
)

const hasACriticalError = (engagementQuestion: Normalized.EngagementQuestion) =>
  engagementQuestion.messages.some(m => m.severity === 'critical')
const hasUnacknowledgedCriticalError = (
  engagementQuestion: Normalized.EngagementQuestion
) =>
  engagementQuestion.messages.some(
    m => m.severity === 'critical' && !m.acknowledged
  )

const hasCriticalErrorSelector = createSelector(
  (state: AppState, props: EngagementOwnProps) =>
    state.engagementQuestions[props.match.params.engagementId] || {},
  (state: AppState) => state.questions,
  (engagementQuestions, questions) => {
    const engagementQuestion = Object.values(engagementQuestions)
      .filter(isDefined)
      .find(q => q.isVisible && hasACriticalError(q))

    // If the Engagment Question has an unacknowledged critical error then select
    // the question as criticalErrorQuestion. This will cause the critical error dialog to be displayed.
    return (
      (engagementQuestion && questions[engagementQuestion.questionId]) !==
      undefined
    )
  }
)

const unacknowledgedCriticalErrorQuestionSelector = createSelector(
  (state: AppState, props: EngagementOwnProps) =>
    state.engagementQuestions[props.match.params.engagementId] || {},
  (state: AppState) => state.questions,
  (engagementQuestions, questions) => {
    const engagementQuestion = Object.values(engagementQuestions)
      .filter(isDefined)
      .find(q => q.isVisible && hasUnacknowledgedCriticalError(q))

    // If the Engagment Question has an unacknowledged critical error then select
    // the question as criticalErrorQuestion. This will cause the critical error dialog to be displayed.
    return engagementQuestion && questions[engagementQuestion.questionId]
  }
)

interface EngagementMappedProps {
  activeSection?: Normalized.Section
  criticalErrorQuestion?: Normalized.Question
  hasCriticalError: boolean
  engagement?: Normalized.Engagement
  hasUnsavedData: boolean
  firstSectionId?: number
  firstQuestionId?: number
  activitiesDirty: boolean
  templates: Normalized.EngagementTemplateMap
  lastNavigation?: Normalized.LastNavigation
}

const mapStateToProps = (
  state: AppState,
  ownProps: EngagementOwnProps
): EngagementMappedProps => {
  const activeSection = state.sections[ownProps.match.params.sectionId || '']
  const activities = state.activities
  const engagement =
    state.engagements[ensureNumber(ownProps.match.params.engagementId)]
  const engagementSections =
    engagement && state.engagementSections[engagement.id]
  const sections = Object.values(state.sections)
    .filter(
      s =>
        s &&
        engagement &&
        engagementSections &&
        engagementSections[s.id] &&
        (engagementSections[s.id] as Normalized.EngagementSection).isVisible &&
        s.engagementTemplateId === engagement.engagementTemplateId
    )
    .sort(sortByDisplayOrder)
    .map(s => (s ? s.id : undefined))
  const firstSectionId = sections.length > 0 ? sections[0] : undefined
  const engagementQuestions =
    engagement && state.engagementQuestions[engagement.id]
  const questions = Object.values(state.questions)
    .filter(
      q =>
        q &&
        engagementQuestions &&
        engagementQuestions[q.id] &&
        (engagementQuestions[q.id] as Normalized.EngagementQuestion)
          .isVisible &&
        q.sectionId === firstSectionId
    )
    .sort(sortByDisplayOrder)
    .map(q => (q ? q.id : undefined))
  const firstQuestionId = questions.length > 0 ? questions[0] : undefined
  const lastNavigation =
    state.userSettings.lastNavigationMap[
      ensureNumber(ownProps.match.params.engagementId)
    ]
  return {
    activeSection,
    criticalErrorQuestion: unacknowledgedCriticalErrorQuestionSelector(
      state,
      ownProps
    ),
    hasCriticalError: hasCriticalErrorSelector(state, ownProps),
    engagement,
    firstQuestionId,
    firstSectionId,
    hasUnsavedData: hasDirtyAnswerSelector(state, ownProps),
    activitiesDirty: !!activities.commentText || activities.fileUploadIsDirty,
    templates: state.engagementTemplates,
    lastNavigation: lastNavigation,
  }
}

export type EngagementProps = EngagementOwnProps &
  EngagementMappedProps &
  EngagementDispatchProps &
  RouteComponentProps<{}>

class Engagement extends React.Component<EngagementProps> {
  engagementPromiseId?: string

  componentDidMount() {
    this.engagementPromiseId = this.props.getEngagement(
      this.props.match.params.engagementId
    )
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', handleLeavePage)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: EngagementProps) {
    const {
      engagement,
      hasCriticalError,
      match: { params },
    } = this.props
    const nextEngagement = nextProps.engagement
    const nextParams = nextProps.match.params

    if (params.engagementId !== nextParams.engagementId) {
      this.engagementPromiseId = this.props.getEngagement(
        nextParams.engagementId
      )
    }

    // Remove potential duplicates
    window.removeEventListener('beforeunload', handleLeavePage)

    if (nextProps.hasUnsavedData) {
      window.addEventListener('beforeunload', handleLeavePage)
    }

    if (
      engagement &&
      nextEngagement &&
      engagement.criticalError &&
      hasCriticalError &&
      !nextProps.hasCriticalError &&
      engagement.loaded &&
      nextEngagement.loaded
    ) {
      nextProps.setEngagementNotCritical(engagement.id)
    }
  }

  ackCriticalError = () => {
    const {
      engagement,
      criticalErrorQuestion,
      acknowledgeCriticalErrorAction,
      setEngagementCritical,
      history,
      match,
    } = this.props
    if (engagement && criticalErrorQuestion) {
      setEngagementCritical(engagement.id, criticalErrorQuestion.id)

      acknowledgeCriticalErrorAction(engagement.id, criticalErrorQuestion.id)
      history.replace(
        joinPaths(
          match.url,
          `engagements/${engagement.id}/sections/${criticalErrorQuestion.sectionId}/questions/${criticalErrorQuestion.id}`
        )
      )
    }
  }

  // tslint:disable-next-line:no-any - props specified by the react router, we don't actually care what they are
  commentsLibraryUnsent = (props: any) => {
    return <CommentLibrary {...props} selectUnsent={true} />
  }

  render() {
    const {
      engagement,
      activitiesDirty,
      templates,
      criticalErrorQuestion,
      match,
    } = this.props

    const engagementTemplateId =
      (engagement && engagement.engagementTemplateId) || -1
    const engagementTemplate = templates[engagementTemplateId]

    if (!engagement || !engagementTemplate) {
      return (
        <Loading watch={this.engagementPromiseId} showLoadingMessage={true} />
      )
    }

    return (
      <CanAccessEngagement phase={engagement.phase}>
        <div
          className={classNames('engagement', {
            'engagement-edit': match.params.edit,
          })}
        >
          {criticalErrorQuestion && (
            <CriticalErrorDialog
              question={criticalErrorQuestion}
              onClickOk={this.ackCriticalError}
            />
          )}
          <Prompt message='Are you sure?' when={activitiesDirty} />
          <div className='engagement-content'>
            <Switch>
              <Route
                path={`${match.path}/first`}
                render={this.renderFirstEngagement}
              />
              <Route
                path={`${match.path}/sections/:sectionId/questions/:questionId/edit`}
                component={EngagementSectionQuestion}
              />
              <Route
                path={[
                  `${match.path}/sections/:sectionId/questions/:questionId`,
                  `${match.path}/sections/:sectionId/`,
                ]}
                component={EngagementSection}
              />
              <Route
                path={`${match.path}/sections`}
                render={this.renderLastQuestionAnswered}
              />
              <Route
                path={[
                  `${match.path}/documents/upload/documenttitle/:documentTitleId`,
                  `${match.path}/documents/upload/filegroup/:fileGroupId/documenttitle/:documentTitleId`,
                  `${match.path}/documents/upload/filegroup/:fileGroupId`,
                  `${match.path}/documents/upload/questions/:questionId/filegroup/:fileGroupId/documenttitle/:documentTitleId`,
                  `${match.path}/documents/upload/questions/:questionId/documenttitle/:documentTitleId`,
                  `${match.path}/documents/upload/questions/:questionId/filegroup/:fileGroupId`,
                  `${match.path}/documents/upload/questions/:questionId`,
                  `${match.path}/documents/upload`,
                ]}
                component={DataFileManagement}
              />

              <Route
                path={[
                  `${match.path}/documents`,
                  `${match.path}/documents/documenttitle/:documentTitleId`,
                  `${match.path}/documents/filegroup/:fileGroupId`,
                  `${match.path}/documents/filegroup/:fileGroupId/documenttitle/:documentTitleId`,
                  `${match.path}/documents/questions/:questionId`,
                  `${match.path}/documents/questions/:questionId/documenttitle/:documentTitleId`,
                  `${match.path}/documents/questions/:questionId/filegroup/:fileGroupId`,
                  `${match.path}/documents/questions/:questionId/filegroup/:fileGroupId/documenttitle/:documentTitleId`,
                ]}
                component={DocLibrary}
              />
              <Route path={`${match.path}/flags`} component={FlagLibrary} />
              <Route
                path={`${match.path}/flags/questions/:questionId`}
                component={FlagLibrary}
              />

              <Route
                path={`${match.path}/comments/unsent`}
                render={this.commentsLibraryUnsent}
              />
              <Route
                path={[
                  `${match.path}/comments`,
                  `${match.path}/comments/questions/:questionId/comments/:commentId`,
                  `${match.path}/comments/questions/:questionId`,
                  `${match.path}/comments/comments/:commentId`,
                ]}
                component={CommentLibrary}
              />
              <InternalRoute
                path={[
                  `${match.path}/carryForward`,
                  `${match.path}/carryForward/comments/:commentId`,
                  `${match.path}/carryForward/questions/:questionId`,
                  `${match.path}/carryForward/questions/:questionId/comments/:commentId`,
                ]}
                component={CarryForwardLibrary}
                permission={UserPermissions.CarryForwardCanView}
              />
              <Route
                path={`${match.path}/reports`}
                component={EngagementReports}
              />
              <Route path={match.path} exact component={EngagementDashboard} />
            </Switch>
          </div>
        </div>
      </CanAccessEngagement>
    )
  }

  renderFirstEngagement = () => {
    const { engagement, firstSectionId, firstQuestionId, match } = this.props
    if (engagement && firstSectionId) {
      let path =
        getClientUrlPortion(match.url) +
        `/engagements/${engagement.id}/sections/${firstSectionId}`

      if (firstQuestionId) {
        path += `/questions/${firstQuestionId}`
      }
      return <Redirect to={path} />
    }
    return <div />
  }

  // this gets called when there is no section defined in the path
  // we will attempt to redirect to the last section and last question
  renderLastQuestionAnswered = () => {
    const {
      lastNavigation,
      engagement,
      firstSectionId,
      firstQuestionId,
      match,
    } = this.props
    if (engagement) {
      let path =
        getClientUrlPortion(match.url) + `/engagements/${engagement.id}`
      if (lastNavigation) {
        path += `/sections/${lastNavigation.lastSectionId}`
        path += `/questions/${lastNavigation.lastQuestionId}`
      } else {
        // if no last nav then we redirect to the first section and question
        path += `/sections/${firstSectionId}`
        if (firstQuestionId) {
          path += `/questions/${firstQuestionId}`
        }
      }
      return <Redirect to={path} />
    }
    return <div />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Engagement)

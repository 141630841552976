import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { createSelector } from 'reselect'
import {
  Engagement,
  EngagementQuestion,
  Question,
  Section,
} from '../../clientModels'
import { ensureNumber } from '../../guards'
import { AppState } from '../../store'
import { QuestionEditComponentLookup } from '../questionEdit/index'

export interface EngagementSectionQuestionRouteParams {
  engagementId: string
  questionId: string
  sectionId: string
}

interface EngagementSectionQuestionOwnProps
  extends RouteComponentProps<EngagementSectionQuestionRouteParams> {}

interface EngagementSectionQuestionMappedProps {
  engagement?: Engagement
  questionId: number
  question?: Question
  section?: Section
  engagementQuestion?: EngagementQuestion
}

const engagementsSelector = (state: AppState) => state.engagements
const sectionsSelector = (state: AppState) => state.sections
const questionsSelector = (state: AppState) => state.questions
const engagementQuestionsSelector = (state: AppState) =>
  state.engagementQuestions
const routeParamsSelector = (
  state: AppState,
  ownParams: EngagementSectionQuestionOwnProps
) => ({
  engagementId: ensureNumber(ownParams.match.params.engagementId),
  questionId: ensureNumber(ownParams.match.params.questionId),
  sectionId: ensureNumber(ownParams.match.params.sectionId),
})
// We aren't worrying about creating unique selectors for each instance of the component
// since we expect only one EngagementSectionQuestion to be rendered in the app at one time.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const engagementSelector = createSelector(
  engagementsSelector,
  routeParamsSelector,
  (engagements, params) => engagements[params.engagementId]
)
const sectionSelector = createSelector(
  sectionsSelector,
  routeParamsSelector,
  (sections, params) => sections[params.sectionId]
)
const questionSelector = createSelector(
  questionsSelector,
  routeParamsSelector,
  (questions, params) => questions[params.questionId]
)
const engagementQuestionSelector = createSelector(
  engagementQuestionsSelector,
  routeParamsSelector,
  (engagementQuestions, params) =>
    engagementQuestions[params.engagementId]![params.questionId]
)

const mapStateToProps = (
  state: AppState,
  props: EngagementSectionQuestionOwnProps
): EngagementSectionQuestionMappedProps => {
  return {
    engagement: engagementSelector(state, props),
    questionId: ensureNumber(props.match.params.questionId || 0),
    question: questionSelector(state, props),
    section: sectionSelector(state, props),
    engagementQuestion: engagementQuestionSelector(state, props),
  }
}

type EngagementSectionQuestionProps = EngagementSectionQuestionOwnProps &
  EngagementSectionQuestionMappedProps

class EngagementSectionQuestion extends React.Component<
  EngagementSectionQuestionProps
  > {
  render () {
    const {
      engagement,
      question,
      section,
      questionId,
      engagementQuestion,
    } = this.props

    if (!section || !engagement || !question) {
      return null
    }

    return (
      <QuestionEditComponentLookup
        question={question}
        engagementId={engagement.id}
        engagementQuestion={engagementQuestion}
        selected={question.id === questionId}
      />
    )
  }
}

export default connect(mapStateToProps)(EngagementSectionQuestion)

import classNames from 'classnames'
import * as React from 'react'
import ReactDataSheet from 'react-datasheet'
import { GridElement } from './component'

export interface RowRendererProperties
  extends ReactDataSheet.RowRendererProps<GridElement> {
  rowClicked?: (
    rowNumber: number
  ) => (e: React.MouseEvent<HTMLTableDataCellElement>) => void
  rowHeaders?: string[]
  headerWidth?: string
}

export class PureRowRenderer extends React.PureComponent<
  RowRendererProperties
  > {
  render () {
    const {
      props: { children, row, rowClicked, rowHeaders, headerWidth },
    } = this
    let rowHeader: string | number
    let customHeader: boolean = false
    let style:
    | undefined
    | {
    minWidth?: string
    maxWidth?: string
    }
    if (rowHeaders && rowHeaders.length && rowHeaders[row]) {
      rowHeader = rowHeaders[row]
      customHeader = true
    } else {
      rowHeader = row + 1
    }
    if (row === 0 && headerWidth) {
      style = {
        minWidth: headerWidth,
        maxWidth: headerWidth,
      }
    }
    return (
      <tr className='data-row'>
        <td
          className={classNames('cell read-only row-header', {
            'custom-header': customHeader,
          })}
          onClick={rowClicked ? rowClicked(Number(rowHeader)) : undefined}
          style={style}
        >
          {rowHeader}
        </td>
        {children}
      </tr>
    )
  }
}

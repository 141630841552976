import classNames from 'classnames'
import * as React from 'react'
import Icon from '../icon/icon'
import './bottomDrawer.scss'

interface BottomDrawerProps {
  className?: string
  onClose?: () => void
  open: boolean
  renderContent?: () => React.ReactNode
}

export class BottomDrawer extends React.Component<BottomDrawerProps> {
  static defaultProps = {
    open: false,
  }

  render () {
    const { children, className, onClose, open, renderContent } = this.props
    const drawerClassName = classNames('bottom-drawer', className, {
      'drawer-open': open,
    })
    const contentClassName = classNames('bottom-drawer-content', className, {
      'drawer-open': open,
    })
    return (
      <React.Fragment>
        <div className={drawerClassName}>{children}</div>
        <div className={contentClassName}>
          <div className='bottom-drawer-close' onClick={onClose}>
            <Icon icon='isClosed' />
          </div>
          {renderContent && renderContent()}
        </div>
      </React.Fragment>
    )
  }
}

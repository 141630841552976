import classNames from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import SmoothCollapse from 'react-smooth-collapse'
import { actions } from '../../actions'
import { downloadEmptyExcelTemplate } from '../../actions/fileTemplateActions'
import {
  DocumentTitle,
  Engagement,
  EngagementQuestion,
  FileGroup,
  Question,
} from '../../clientModels'
import { ensureNumber } from '../../guards'
import { latestFilesFromFileGroup } from '../../services/fileHelpers'
import { questionDisabled } from '../../services/question'
import { AppState, TsaDispatch } from '../../store'
import { Anchor } from '../anchor'
import Icon, { IconClickHandler } from '../icon/icon'
import { NotApplicableCheckBox } from '../notApplicableCheckBox'
import { OpenerInjectedProps, withOpener } from '../opener/opener'
import { QuestionContainer } from '../question/questionContainer'
import { joinPaths } from '../relativeLink'
import './docLibraryItem.scss'
import DocLibraryItemIcons from './docLibraryItemIcons'
import {
  getDocLibraryEntryTitle,
  missingRequiredDoc,
} from './docLibraryUtilities'

export interface DocLibraryEntry {
  allowNotApplicable: boolean
  /** Only provide a document title if the file is required by the business rules. */
  documentTitle?: DocumentTitle
  fileGroup?: FileGroup
  question: Question
}

interface DocLibraryItemOwnProps
  extends DocLibraryEntry,
    RouteComponentProps<{}>,
    OpenerInjectedProps {
  engagementId: string
  onSelectItem: (entry: DocLibraryEntry, short: boolean) => void
  afterClose: () => void
}

interface DocLibraryItemMappedProps {
  engagementQuestion?: EngagementQuestion
  engagement?: Engagement
  disabled: boolean
}

function mapStateToProps(
  state: AppState,
  props: DocLibraryItemOwnProps
): DocLibraryItemMappedProps {
  const { engagementId, question } = props
  const { engagements, activities, auth, engagementQuestions } = state
  const engagementQuestion = engagementQuestions[engagementId]
  const result: DocLibraryItemMappedProps = {
    engagementQuestion: engagementQuestion && engagementQuestion[question.id],
    engagement: engagements[ensureNumber(engagementId)],
    disabled: questionDisabled(
      ensureNumber(engagementId),
      question.id,
      engagements,
      activities,
      engagementQuestion,
      auth.user
    ),
  }

  return result
}

interface DocLibraryItemDispatchProps {
  deleteFileGroup: (fileGroupId: number) => void
  downloadFile: (fileId: number) => void
  downloadExcelTemplate: (fileName: string, engagementId: number) => void
  openFileUpload: (afterClose: () => void) => void
  toggleCarryForwardFileGroup: (fileGroup: FileGroup) => void
  toggleFileGroupNA: (
    documentTitle: DocumentTitle,
    fileGroupId?: number
  ) => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: DocLibraryItemOwnProps
): DocLibraryItemDispatchProps => ({
  deleteFileGroup: (fileGroupId: number) =>
    dispatch(
      actions.file.deleteFileGroup(
        props.engagementId,
        props.question.id,
        fileGroupId
      )
    ),
  downloadFile: (fileId: number) => dispatch(actions.file.downloadFile(fileId)),
  downloadExcelTemplate: (fileName: string, engagementId: number) =>
    dispatch(downloadEmptyExcelTemplate(fileName, engagementId)),
  openFileUpload: (afterClose: () => void) =>
    dispatch(
      actions.file.openFileUpload(
        props.engagementId,
        props.question.id,
        props.documentTitle,
        props.history,
        props.fileGroup,
        afterClose
      )
    ),
  toggleCarryForwardFileGroup: (fileGroup: FileGroup) =>
    dispatch(actions.file.toggleCarryFowardFileGroup(fileGroup)),
  toggleFileGroupNA: (documentTitle: DocumentTitle, fileGroupId?: number) =>
    dispatch(
      actions.file.toggleFileGroupNotApplicable(
        props.engagementId,
        props.question.id,
        documentTitle,
        fileGroupId
      )
    ),
})

type DocLibraryProps = DocLibraryItemOwnProps &
  DocLibraryItemMappedProps &
  DocLibraryItemDispatchProps

class DocLibraryItem extends React.Component<DocLibraryProps> {
  files() {
    return latestFilesFromFileGroup(this.props.fileGroup)
  }

  render() {
    const {
      allowNotApplicable,
      disabled,
      documentTitle,
      engagement,
      engagementId,
      engagementQuestion,
      fileGroup,
      opener,
      question,
    } = this.props

    const title = getDocLibraryEntryTitle(this.props)

    const className = classNames('doc-library-item', {
      'missing-required-doc': missingRequiredDoc(documentTitle, fileGroup),
      open: opener.isOpen,
    })

    const documentTitleId = documentTitle && documentTitle.id
    const fileGroupId = documentTitleId ? undefined : fileGroup && fileGroup.id
    const showNotApplicable =
      documentTitle && // N/A only applies to required files
      !documentTitle.templateFileName && // N/A doesn't apply to templates. We use the question level N/A for that.
      (!fileGroup || fileGroup.notApplicable) && // Don't show the checkbox if it is already marked as N/A
      allowNotApplicable

    const fileCount = this.files().length

    return (
      <div>
        {question.isVisible &&
          <div className={className}>
            <div className='doc-library-item-header'>
              <div
                className='doc-library-item-expander'
                onClick={this.handleHeaderClick}
              >
                <Icon icon='isClosed' />
                <div className='doc-library-required-icon'>
                  {documentTitle && !documentTitle.optional && (
                    <Icon icon='asterisk' />
                  )}
                </div>
                <div className='doc-library-item-content'>
                  {question.displayNumber} | {title}
                  {fileCount > 0 && (
                    <div className='doc-library-item-count'>
                      {documentTitleId === 59 ? (
                        <Anchor onClick={this.handleViewFilesClick}>
                          {`${fileCount} documents imported (view)`}
                        </Anchor>
                      ) : (
                        `${fileCount} files uploaded`
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='doc-library-item-actions'>
                {showNotApplicable && (
                  <NotApplicableCheckBox
                    mode='file'
                    checked={fileGroup && fileGroup.notApplicable}
                    onChange={this.handleNAChanged}
                  />
                )}
                <DocLibraryItemIcons
                  allowNotApplicable={allowNotApplicable}
                  disabled={disabled}
                  documentTitle={documentTitle}
                  engagement={engagement}
                  fileGroup={fileGroup}
                  isOpen={opener.isOpen}
                  onClickIcon={this.handleIconClick}
                  question={question}
                />
              </div>
            </div>
            <SmoothCollapse
              expanded={opener.isOpen}
              className='doc-library-item-details'
            >
              <QuestionContainer
                documentTitleId={documentTitleId}
                engagementId={ensureNumber(engagementId)}
                engagementQuestion={engagementQuestion}
                fileGroupId={fileGroupId}
                question={question}
                selected={true}
                calledFromSummary={true}
              />
            </SmoothCollapse>
          </div>
        }
      </div>
    )
  }

  private selectItem = (short: boolean) => {
    const {
      allowNotApplicable,
      onSelectItem,
      question,
      documentTitle,
      fileGroup,
    } = this.props
    onSelectItem(
      { allowNotApplicable, question, documentTitle, fileGroup },
      short
    )
  }

  private handleHeaderClick = () => {
    const {
      props: {
        opener: { close, isOpen },
      },
      selectItem,
    } = this
    if (isOpen) {
      close()
    } else {
      selectItem(false)
    }
  }

  private handleNAChanged = () => {
    const { documentTitle, fileGroup, toggleFileGroupNA } = this.props
    if (documentTitle) {
      toggleFileGroupNA(documentTitle, fileGroup && fileGroup.id)
    }
  }

  private handleClick = (
    name:
      | 'carryForward'
      | 'delete'
      | 'download'
      | 'edit'
      | 'openContext'
      | 'upload'
      | string
  ) => {
    const {
      afterClose,
      deleteFileGroup,
      documentTitle,
      downloadExcelTemplate,
      engagementId,
      fileGroup,
      history,
      match,
      opener: { isOpen },
      openFileUpload,
      question,
      toggleCarryForwardFileGroup,
    } = this.props

    switch (name) {
      case 'openContext':
        history.push(
          joinPaths(
            match.url,
            `engagements/${engagementId}/sections/${question.sectionId}/questions/${question.id}`
          )
        )
        break

      case 'upload':
      case 'edit':
        this.selectItem(!isOpen)
        openFileUpload(afterClose)
        break

      case 'carryForward':
        if (fileGroup) {
          toggleCarryForwardFileGroup(fileGroup)
        }
        break

      case 'delete':
        if (fileGroup) {
          deleteFileGroup(fileGroup.id)
        }
        break

      case 'download':
        if (documentTitle && documentTitle.templateFileName) {
          downloadExcelTemplate(
            documentTitle.templateFileName,
            ensureNumber(engagementId)
          )
        }
        break

      default:
        break
    }
  }

  private handleViewFilesClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.handleClick('edit')
  }

  private handleIconClick: IconClickHandler = (name, e) => {
    e.stopPropagation()

    this.handleClick(name)
  }
}

export default withOpener({
  groupName: 'DocLibrary',
  onlyOneOpen: true,
  openOnClickInside: false,
})(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DocLibraryItem)
    // tslint:disable-next-line:no-any https://github.com/Microsoft/TypeScript/issues/28748
  )
) as any

import React from 'react'
import { useTheme } from 'react-jss'
import { CchRequest } from '.'
import { CchRequestsRow } from './cchRequestsRow'
import { useTableStyles } from './tableStyles'

type CchRequestsTableProps = {
  requests: CchRequest[]
}

export const CchRequestsTable = ({ requests }: CchRequestsTableProps) => {
  const theme = useTheme()
  const classes = useTableStyles(theme)

  if (requests.length === 0) return null

  return (
    <table className={classes.root}>
      <thead>
        <tr className={classes.header}>
          <th className={classes.cell}>Action</th>
          <th className={classes.cell}>Return ID</th>
          <th className={classes.cell}>Created</th>
          <th className={classes.cell}>State</th>
          <th className={classes.cell} />
          <th className={classes.cell} />
        </tr>
      </thead>
      <tbody>
        {requests.map((request, i) => (
          <CchRequestsRow request={request} key={i} />
        ))}
      </tbody>
    </table>
  )
}

import { useState, useEffect } from 'react'

export interface AdminCenterClaims {
  [key: string]: string | string[]
}

export interface UseAdminCenterProps {
  host?: string
  tokenFactory?: () => Promise<string>
}
//component gets a list of claims from EID with the authority and a token
export const useAdminCenter = ({ host, tokenFactory }: UseAdminCenterProps) => {
  const [claims, setClaims] = useState<AdminCenterClaims>({})

  useEffect(() => {
    const getAdminCenterClaims = async () => {
      if (host === undefined || tokenFactory === undefined) {
        setClaims({})
        return
      }

      const token = await tokenFactory()

      const response = await fetch(`${host}/openid/userinfo`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        var result = (await response.json()) as AdminCenterClaims
        setClaims(result)
      } else {
        setClaims({})
      }
    }
    getAdminCenterClaims()
    // Please do not include in depedency list  [host, tokenFactory]
    // will cause an infinite call to userinfo. Should only run once.
    // eslint-disable-next-line
  }, [])

  return claims
}

import { Theme } from '@rsmus/react-ui'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { EntityEngagement, FileGroup } from '../../clientModels'
import Icon from '../icon/icon'

function styles({ palette }: Theme) {
  return {
    icon: {
      cursor: 'pointer',
      color: palette.blue.main,
      '&:hover': {
        color: palette.blue.dark,
      },
      fontSize: '1.2rem',
      margin: '0.25rem',
    },
    row: {
      '& td': {
        backgroundColor: palette.grey[100],
        borderBottom: '1px solid ' + palette.common.white,
        fontSize: 'small',
      },
      '&:hover': {
        '& td': { backgroundColor: palette.grey[300] },
      },
    },
    alt: {
      '& td': {
        backgroundColor: palette.grey[200],
      },
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface DocLibraryAllItemProps {
  alt: boolean
  fileGroup: FileGroup
  engagement?: EntityEngagement
}

const DocLibraryAllItem = ({
  alt,
  fileGroup,
  engagement,
}: DocLibraryAllItemProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const deleteButtonClicked = () => {
    console.log(`Delete button clicked for ${fileGroup.title}`)
  }

  const downloadButtonClicked = () => {
    console.log(`downloadButtonClicked for ${fileGroup.title}`)
  }

  const uploadButtonClicked = () => {
    console.log(`uploadButtonClicked for ${fileGroup.title}`)
  }

  const editButtonClicked = () => {
    console.log(`editButtonClicked for ${fileGroup.title}`)
  }

  const weirdCircleArrowButtonClicked = () => {
    console.log(`weirdCircleArrowButtonClicked for ${fileGroup.title}`)
  }
  const altClassName = alt ? classes.alt : ''
  return (
    <tr className={classNames(classes.row, altClassName)}>
      <td>{fileGroup.title}</td>
      <td>{fileGroup.userId}</td>
      <td>{moment(fileGroup.createdDate, moment.ISO_8601).format('ll')}</td>
      <td>{engagement && engagement.entityShortName}</td>
      <td></td>
      <td></td>
      <td>{engagement && engagement.engagementName}</td>
      <td>
        <Icon
          className={classes.icon}
          icon='delete'
          onClick={deleteButtonClicked}
        />
        &nbsp;&nbsp;
        <Icon
          className={classes.icon}
          icon='download'
          onClick={downloadButtonClicked}
        />
        &nbsp;&nbsp;
        <Icon
          className={classes.icon}
          icon='upload'
          onClick={uploadButtonClicked}
        />
        &nbsp;&nbsp;
        <Icon
          className={classes.icon}
          icon='edit'
          onClick={editButtonClicked}
        />
        &nbsp;&nbsp;
        <Icon
          className={classes.icon}
          icon='questionCircle'
          onClick={weirdCircleArrowButtonClicked}
        />
      </td>
    </tr>
  )
}

export default DocLibraryAllItem

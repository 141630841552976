import { TsaHubAction } from '../actions'
import { ActivityMap } from '../clientModels'
import {
  clearDirtyActivity,
  deleteActivityDone,
  getActivitiesDone,
  getAllActivitiesDone,
  setComment,
  setFileUploadIsDirty,
  sidebarSelect,
} from './activitiesReducerHelpers'
import { getType } from 'typesafe-actions'
import { commentSetAction } from '../actions/commentActions'
import { selectionSidebarSelectAction } from '../actions/selectionActions'

export interface ActivityState {
  commentText: string
  currentQuestionId: string | number
  mentionLabel: string
  mentionValue: string
  fileUploadIsDirty: boolean
  mostRecentDeletedActivity?: number
  [engagementId: number]: ActivityMap | undefined
}

const initialState: ActivityState = {
  currentQuestionId: 0,
  commentText: '',
  mentionLabel: '',
  mentionValue: 'no-tag',
  fileUploadIsDirty: false,
}

const reducerActionLookup: any = {
  DELETE_ACTIVITY_DONE: deleteActivityDone,
  GET_ALL_ACTIVITIES_DONE: getAllActivitiesDone,
  GET_ACTIVITIES_DONE: getActivitiesDone,
  [getType(commentSetAction)]: setComment,
  SET_FILE_UPLOAD_IS_DIRTY: setFileUploadIsDirty,
  [getType(selectionSidebarSelectAction)]: sidebarSelect,
  CLEAR_DIRTY_ACTIVITY: clearDirtyActivity,
}

export function activitiesReducer(
  state: ActivityState = initialState,
  action: TsaHubAction
): ActivityState {
  const reducerAction = reducerActionLookup[action.type]

  if (reducerAction) {
    return reducerAction(state, action)
  }

  return state
}

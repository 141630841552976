import * as React from 'react'
import { IconProperties } from './properties'

const CheckedCircle: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <svg
      viewBox='0 0 31 31'
      height='1em'
      width='1em'
      className={className}
      onClick={onClick}
      focusable='false'
      data-tip={tooltip}
    >
      <circle cx='15.5' cy='15.5' r='14.5' />
      <polyline points='7.15 16.56 12.01 21.42 23.85 9.58' />
    </svg>
  )
}

export default CheckedCircle

import { uniq } from 'lodash'
import * as React from 'react'
import { Message } from '../../../clientModels'
import Alert from './alert'
import Alerts from './alerts'

interface PathAlertsProps {
  messages?: Message[]
  selectedPath?: string
  onSelectPath?: (path?: string) => void
}

interface PathAlertsState {
  selectedMessages: Message[]
  pathMessages: Message[]
  paths: string[]
}

function hasPath(path?: string): path is string {
  return typeof path === 'string' && path !== ''
}

class PathAlerts extends React.Component<PathAlertsProps, PathAlertsState> {
  constructor(props: PathAlertsProps) {
    super(props)
    this.state = this.getState(props)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: PathAlertsProps) {
    this.setState(this.getState(nextProps))
  }

  getState(props: PathAlertsProps) {
    const messages = props.messages || []
    const pathMessages = messages.filter(m => !!m.path)
    const selectedMessages = pathMessages.filter(
      m => m.path === props.selectedPath
    )
    const paths = uniq(messages.map(m => m.path).filter(hasPath))

    return {
      pathMessages,
      selectedMessages,
      paths,
    }
  }

  handleClickNext = () => {
    const { onSelectPath, selectedPath } = this.props
    const { paths } = this.state
    if (onSelectPath) {
      const selectedIndex = paths.findIndex(p => p === selectedPath)
      const nextIndex = selectedIndex < paths.length - 1 ? selectedIndex + 1 : 0
      onSelectPath(paths[nextIndex])
    }
  }

  render() {
    const { onSelectPath } = this.props
    const { selectedMessages, paths, pathMessages } = this.state
    if (paths.length === 0) {
      return null
    }

    let alert

    if (paths.length === 1 && pathMessages && pathMessages.length > 0) {
      alert = (
        <Alert
          messages={pathMessages[0].message}
          severity={pathMessages[0].severity}
          type={pathMessages[0].type}
        />
      )
    } else if (selectedMessages.length === 0) {
      alert = (
        <Alert
          messages={`There are (${paths.length}) fields with errors.  Please correct these fields.`}
          severity='error'
          type='msg'
        />
      )
    } else {
      alert = <Alerts messages={selectedMessages} />
    }

    return (
      <div className='tsa-path-alerts'>
        {alert}
        {paths.length > 1 && onSelectPath && (
          // eslint-disable-next-line no-script-url
          <button onClick={this.handleClickNext} className='path-alert-link'>
            next
          </button>
        )}
      </div>
    )
  }
}

export default PathAlerts

import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { createSelector } from 'reselect'
import { copyLastYearAnswers } from '../../actions/engagementThunks'
import { actions } from '../../actions/index'
import { updateLastNav } from '../../actions/lastnavThunks'
import {
  Engagement,
  EngagementTemplate,
  IdentityTokenProfile,
  LastNavigation,
  PhaseMap,
  QuestionTuple,
  Section,
} from '../../clientModels'
import { PhaseCode } from '../../enums'
import { ensureNumber, isNull } from '../../guards'
import { getPhases } from '../../reducers/selectors'
import { questionNeedsReview } from '../../services/questionReviewUtilities'
import { AppState, TsaDispatch } from '../../store'
import { EngagementPhaseChange } from '../engagementPhaseChange/engagementPhaseChange'
import { hasError } from '../forms/formUtilities'
import Icon from '../icon/icon'
import { QuestionList } from '../questionList/index'
import QuestionListButtons from '../questionList/questionListButtons'
import { joinPaths } from '../relativeLink'
import './engagementSection.scss'

export interface EngagementSectionRouteParams {
  engagementId: string
  questionId: string
  sectionId: string
}

interface EngagementSectionOwnProps
  extends RouteComponentProps<EngagementSectionRouteParams> {}

interface EngagementSectionMappedProps {
  engagement?: Engagement
  phases: PhaseMap
  questionId: number
  questions: QuestionTuple[]
  section?: Section
  selectedQuestionHasError: boolean
  showIncompleteOnlyAction: boolean
  user?: IdentityTokenProfile
  engagementTemplate?: EngagementTemplate
}

interface EngagementSectionDispatchProps {
  submitEngagement: (engagmentId: string | number, clientId?: number) => void
  updateEngagementPhase: (
    engagementId: string | number,
    phase: PhaseCode
  ) => void
  updateLastNavigation: (lastNavigation: LastNavigation) => void
  copyLastYearAnswers: (engagementId: string | number) => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch
): EngagementSectionDispatchProps => ({
  submitEngagement: (engagementId: string | number, clientId?: number) =>
    dispatch(actions.engagement.submitEngagement(engagementId, clientId)),
  updateEngagementPhase: (engagementId: string | number, phase: PhaseCode) =>
    dispatch(actions.engagement.updateEngagementPhase(engagementId, phase)),
  updateLastNavigation: (lastNavigation: LastNavigation) =>
    dispatch(updateLastNav(lastNavigation)),
  copyLastYearAnswers: (engagementId: string | number) =>
    dispatch(copyLastYearAnswers(engagementId)),
})

const selectEngagements = (state: AppState) => state.engagements
const selectQuestions = (state: AppState) => state.questions
const selectSections = (state: AppState) => state.sections
const selectUser = (state: AppState) => state.auth.user
const selectEngagementTemplates = (state: AppState) => state.engagementTemplates
const selectRouteParams = (
  state: AppState,
  ownParams: EngagementSectionOwnProps
) => ({
  engagementId: ensureNumber(ownParams.match.params.engagementId),
  questionId: ensureNumber(ownParams.match.params.questionId),
  sectionId: ensureNumber(ownParams.match.params.sectionId),
})

// We aren't worrying about creating unique selectors for each instance of the component
// since we expect only one EngagementSection to be rendered in the app at any time.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const selectEngagement = createSelector(
  selectEngagements,
  selectRouteParams,
  (engagements, params) => engagements[params.engagementId]
)
const selectEngagemenTemplateLocal = createSelector(
  selectEngagements,
  selectRouteParams,
  selectEngagementTemplates,
  (engagements, params, engagementTemplates) => {
    const engagement = engagements[params.engagementId]
    return (
      engagement &&
      engagementTemplates[ensureNumber(engagement.engagementTemplateId)]
    )
  }
)
const selectSection = createSelector(
  selectSections,
  selectRouteParams,
  (sections, params) => sections[params.sectionId]
)

const selectVisibility = createSelector(
  selectRouteParams,
  (state: AppState) => state.visibility,
  (params, visibility) => visibility[params.engagementId]
)

const selectEngagementQuestions = createSelector(
  selectRouteParams,
  (state: AppState) => state.engagementQuestions,
  (params, engagementQuestionsMap) =>
    engagementQuestionsMap[params.engagementId]
)

const questionTuplesSelector = createSelector(
  selectSection,
  selectQuestions,
  selectEngagementQuestions,
  selectVisibility,
  selectEngagement,
  selectUser,
  (
    section,
    questionMap,
    engagementQuestionsMap,
    visibility,
    engagement,
    user
  ) => {
    const showAllQuestions = !!visibility && visibility.showAllQuestions
    const showIncompleteOnly = !!visibility && visibility.showIncompleteOnly
    const tuples: QuestionTuple[] = []

    if (!engagement || !section || !engagementQuestionsMap || !user) {
      return tuples
    }

    for (const id of section.questions) {
      const question = questionMap[id]
      const engagementQuestion = engagementQuestionsMap[id]
      if (!question || !engagementQuestion) {
        continue
      }

      // Filter out questions that should not be visibile
      if (showAllQuestions || engagementQuestion.isVisible) {
        const questionIsIncomplete =
          engagementQuestion.isDirty || // Question has unsaved changes
          hasError(engagementQuestion.messages) || // Question has a validation error
          questionNeedsReview(engagement, question, engagementQuestion, user) // Question requires review by the current user

        if (showIncompleteOnly && !questionIsIncomplete) {
          continue
        }
        if (question.isVisible) {
          tuples.push({ question, engagementQuestion })
        }
      }
    }

    return tuples
  }
)

const selectedQuestionHasErrorSelector = createSelector(
  selectRouteParams,
  (state: AppState) => state.engagementQuestions,
  (params, engagementQuestionMap) => {
    const engagementQuestions = engagementQuestionMap[params.engagementId] || {}
    const selectedQuestion = engagementQuestions[params.questionId]
    const selectedQuestionHasError =
      !!selectedQuestion &&
      hasError(selectedQuestion.messages) &&
      !isNull(selectedQuestion.answerValue) &&
      !selectedQuestion.notApplicable &&
      (selectedQuestion.answerId > 0 || selectedQuestion.isDirty)
    return !!selectedQuestionHasError
  }
)

const mapStateToProps = (
  state: AppState,
  props: EngagementSectionOwnProps
): EngagementSectionMappedProps => {
  const visibility = selectVisibility(state, props)
  return {
    engagement: selectEngagement(state, props),
    phases: getPhases(state),
    questionId: ensureNumber(props.match.params.questionId || 0),
    questions: questionTuplesSelector(state, props),
    section: selectSection(state, props),
    selectedQuestionHasError: selectedQuestionHasErrorSelector(state, props),
    showIncompleteOnlyAction: !!visibility && visibility.showIncompleteOnly,
    user: state.auth.user,
    engagementTemplate: selectEngagemenTemplateLocal(state, props),
  }
}

type EngagementSectionProps = EngagementSectionOwnProps &
  EngagementSectionMappedProps &
  EngagementSectionDispatchProps

class EngagementSection extends React.Component<EngagementSectionProps> {
  // If there is not selected question, select the first question in this section
  checkSelected = () => {
    const { questionId, questions } = this.props

    if (questionId) {
      return
    }

    const firstQuestion = questions[0]
    if (!firstQuestion) {
      return
    }

    this.handleSelectQuestion(firstQuestion.question.id)
  }

  componentDidMount() {
    this.checkSelected()
  }

  componentDidUpdate() {
    this.checkSelected()
  }

  handleSelectQuestion = (questionId: number) => {
    const { history, match, updateLastNavigation, engagement } = this.props
    const { engagementId, sectionId } = match.params
    const url = joinPaths(
      match.url,
      `engagements/${engagementId}/sections/${sectionId}/questions/${questionId}`
    )

    history.replace(url)

    if (engagement) {
      const lastNavigation: LastNavigation = {
        id: engagement.id,
        engagementId: engagement.id,
        lastQuestionId: questionId,
        lastSectionId: ensureNumber(match.params.sectionId),
      }
      // dispatch to save local storage and update the store.
      updateLastNavigation(lastNavigation)
    }
  }

  render() {
    const {
      engagement,
      engagementTemplate,
      history,
      phases,
      questionId,
      questions,
      section,
      selectedQuestionHasError,
      showIncompleteOnlyAction,
      submitEngagement,
      updateEngagementPhase,
      copyLastYearAnswers,
      user,
    } = this.props

    if (!section || !engagement || !engagementTemplate) {
      return null
    }

    return (
      <div className='engagement-section'>
        {showIncompleteOnlyAction && questions.length === 0 && (
          <h3>
            <Icon icon='check' /> All Questions Complete
          </h3>
        )}
        <QuestionList
          engagementId={engagement.id}
          onSelectQuestion={this.handleSelectQuestion}
          questions={questions}
          selectedQuestionHasError={selectedQuestionHasError}
          selectedQuestionId={questionId}
        />
        <EngagementPhaseChange
          engagement={engagement}
          engagementTemplate={engagementTemplate}
          childComponent={QuestionListButtons}
          updateEngagementPhase={updateEngagementPhase}
          copyLastYearAnswers={copyLastYearAnswers}
          submitEngagement={submitEngagement}
          user={user}
          history={history}
          sectionId={section.id}
          phases={phases}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementSection)

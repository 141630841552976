import React, { useState } from 'react'
import Modal from './modal'
import { Theme } from '@rsmus/react-ui'
import { useTheme, createUseStyles } from 'react-jss'

function styles({ palette }: Theme) {
  return {
    copyContainer: {
      maxWidth: '90%',
      textAlign: 'right',
      width: '100%',
    },
    copyButton: {
      textAlign: 'right',
    },
    modalChildren: {
      marginTop: '1rem',
      maxHeight: '12rem',
      overflowY: 'auto',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface InfoDialogOwnProps {
  enableCopy?: boolean
  copyText?: string
  info: string
  onClickPrimary: () => void
  onClose: () => void
  primaryButtonText?: string
  title: string
}

interface InfoDialogProps extends InfoDialogOwnProps {}

const InfoDialog = ({
  enableCopy,
  copyText,
  children,
  info,
  onClickPrimary,
  onClose,
  primaryButtonText,
  title,
}: React.PropsWithChildren<InfoDialogProps>) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [copySuccess, setCopySuccess] = useState<boolean>(false)

  const copyToClipboard = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    var input = document.createElement('textarea')
    document.body.appendChild(input)
    input.value = copyText || ''
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    setCopySuccess(true)
  }

  return (
    <Modal>
      <div className='modal warning'>
        <div className='modal-body'>
          <button
            id='modal-button-close'
            type='button'
            className='close'
            aria-label='Close'
            onClick={onClose}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
          <div className='modal-title'>{title}</div>
          {enableCopy && document.queryCommandSupported('copy') && (
            <div className={classes.copyContainer}>
              <button
                onClick={copyToClipboard}
                className={`simple-link ${classes.copyButton}`}
              >
                Copy
              </button>
              {copySuccess && 'Copied!'}
            </div>
          )}
          <div className='modal-info'>{info}</div>
          <div className='modal-info'>
            <div className={classes.modalChildren}>{children}</div>
          </div>
          <div className='modal-buttons'>
            <button
              id='modal-primary-button'
              className='btn btn-primary'
              autoFocus={true}
              onClick={onClickPrimary}
            >
              {primaryButtonText}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

InfoDialog.defaultProps = {
  primaryButtonText: 'OK',
}

export default InfoDialog

import * as React from 'react'
import Icon from './icon'
import { IconProperties } from './properties'

const NotificationSent: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <div className={className} onClick={onClick} data-tip={tooltip}>
      <Icon icon='notification' className={className} />
      <svg
        viewBox='0 0 31 31'
        height='1em'
        width='1em'
        className={className + ' badge'}
        focusable='false'
      >
        <circle cx='15.5' cy='15.5' r='15.5' />
        <polyline points='7.15 16.56 12.01 21.42 23.85 9.58' />
      </svg>
    </div>
  )
}

export default NotificationSent

import { find } from 'lodash'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { ThemeProvider } from 'react-jss'
import * as Icons from 'react-icons'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { actions } from './actions/index'
import App from './app'
import ErrorBoundary from './components/errorBoundary/errorBoundary2'
import { WarningDialog } from './components/modals/index'
import OidcLoginManager from './oidc-auth/oidcLoginManager'
import './scss/index.scss'
import store from './store'
import { theme } from '@rsmus/react-ui/dist/theme'
import { clearDirtyActivityAction } from './actions/activityActions'
import { 
          REACT_APP_IDMAUTHENTICATIONCLIENTID,
          REACT_APP_IDMAUTHENTICATIONAUTHORITY,
          REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS
       } from './envVariables'

const AppContainer = () => {
  const findElement = (
    parent: HTMLElement,
    condition: (el: HTMLElement) => boolean
  ): any => {
    return condition(parent)
      ? parent
      : find(
          parent.children,
          (child: HTMLElement) => !!findElement(child, condition)
        )
  }

  const closeModal = () => {
    const modalRoot = findElement(
      document.body,
      (el: HTMLElement) => el.id === 'modal-root'
    )
    if (modalRoot) {
      ReactDOM.render(<div />, modalRoot)
    }
  }

  const buildCloseModal = (callback: (answer: boolean) => void) => {
    return () => {
      closeModal()
      callback(false)
    }
  }

  const buildOkClicked = (callback: (answer: boolean) => void) => {
    return () => {
      closeModal()
      callback(true)
    }
  }

  // tslint:disable-next-line:no-any - get around nonsensical compiler errors
  const getUserConfirmation: any = (
    message: string,
    callback: (allowNavigation: boolean) => void
  ) => {
    if (!message) {
      callback(true)
      return
    }

    const modalRoot = findElement(
      document.body,
      (el: HTMLElement) => el.id === 'modal-root'
    )

    if (!modalRoot) {
      return
    }

    let helperMethod = callback

    const infoMessage = message.startsWith('Discard Answer?')
      ? 'You have unsaved answers. If you proceed, you will lose your unsaved data. Are you sure you want to navigate away?'
      : 'You have unsaved files or comments. If you proceed, you will lose your unsaved data. Are you sure you want to navigate away?'

    if (message.startsWith('Discard Answer?')) {
      const parts = message.substring(15).split('-')
      if (parts.length === 2) {
        const engagementId = parseInt(parts[0], 10)
        const questionId = parseInt(parts[1], 10)

        helperMethod = (answer: boolean) => {
          store.dispatch(actions.answer.getAnswer(engagementId, questionId))
          callback(answer)
        }
      }
    } else {
      helperMethod = (answer: boolean) => {
        store.dispatch(clearDirtyActivityAction())
        callback(answer)
      }
    }

    ReactDOM.render(
      // Make sure there is no unsaved activity
      <WarningDialog
        info={infoMessage}
        onClickPrimary={buildOkClicked(helperMethod)}
        onClickSecondary={buildCloseModal(callback)}
        onClose={buildCloseModal(callback)}
        title='Unsaved Data'
      />,
      modalRoot
    )
  }

  let LoginManager: React.ComponentType<any> = OidcLoginManager

  if (process.env.NODE_ENV !== 'production') {
    // if (config['Env.NoAuth']) {
    //   // tslint:disable-next-line:no-any
    //   LoginManager = (props: any) => props.children
    // }
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <LoginManager
            authority={REACT_APP_IDMAUTHENTICATIONAUTHORITY || ''}
            clientId={REACT_APP_IDMAUTHENTICATIONCLIENTID || ''}
            silentRefreshOffsetTime={
              REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS
                ? parseInt(
                  REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS,
                    10
                  )
                : 1800
            }
            autoLogin={true}
          >
            <Router getUserConfirmation={getUserConfirmation}>
              <Icons.IconContext.Provider value={{ className: 'react-icons' }}>
                <App />
              </Icons.IconContext.Provider>
            </Router>
          </LoginManager>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default AppContainer

import { Dropdown, Theme } from '@rsmus/react-ui'
import clsx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa'
import { createUseStyles, useTheme } from 'react-jss'
import { matchPath, RouteComponentProps, withRouter } from 'react-router'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import NavTabsIcon from './NavTabsIcon'
import NavTabsTabList from './NavTabsTabList'

export const TAB_WIDTH = 160

function styles(theme: Theme) {
  return {
    navTabsContainer: {
      backgroundColor: theme.palette.grey[600],
      display: 'flex',
      flex: '1 1 auto',
      width: '1rem',
    },
    navTabsRoot: {
      display: 'flex',
      alignItems: 'flex-end',
      width: '100%',
    },
    container: {
      flex: '1 1 auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      height: '2rem',
    },
    pinned: {
      position: 'relative',
      zIndex: 1,
    },
    ellipsis: {
      '& button': {
        height: '2rem',
        backgroundColor: 'transparent',
        padding: 0,
        outline: 'none',
      },
      '& div[class^="menu"]': {
        maxHeight: '15rem',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginTop: '0rem',
        '&> div': {
          width: '6.25rem',
          paddingRight: '1.5rem',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
    },
    disabled: {
      cursor: 'default',
      '& div': {
        cursor: 'default',
      },
      '& svg': {
        cursor: 'default',
        color: theme.palette.grey[500],
      },
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface Tab {
  title: string
  path: string
  pinned?: boolean
}

export interface NavTabsProps {
  className?: string
  tabs: Tab[]
}

type Properties = NavTabsProps & RouteComponentProps

const NavTabs = ({ className, tabs, history }: Properties) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [offset, setOffset] = useState(0)
  const [canScrollRight, setCanScrollRight] = useState(true)
  const [showTabList, setShowTabList] = useState(true)
  const windowWidth = useWindowWidth()
  const containerRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current && listRef.current) {
        const container = containerRef.current.getBoundingClientRect()
        const list = listRef.current.getBoundingClientRect()
        setCanScrollRight(list.right > container.right)
      }
    }, 150) // allow for translate transition to complete
  }, [containerRef, listRef, offset, tabs, windowWidth])

  const activeTabIndex = tabs.findIndex(x =>
    matchPath(window.location.pathname, { path: x.path })
  )

  // When the active tab is partially hidden, shift it so it's all visible
  useEffect(() => {
    if (activeTabIndex < 1 || !containerRef.current) {
      setOffset(0)
      return
    }

    const tabContainer = containerRef.current.getBoundingClientRect()
    const leftSideOfTabContainer = tabContainer.left
    const rightSideOfTabContainer = tabContainer.right

    const updatedOffset = TAB_WIDTH * activeTabIndex
    const maxNumberOfFullyVisibleTabs = Math.floor(
      tabContainer.width / TAB_WIDTH
    )

    const leftPositionOfActiveTab = leftSideOfTabContainer + updatedOffset
    const rightPositionOfActiveTab = leftPositionOfActiveTab + TAB_WIDTH

    const allTabsFitInTabContainer = maxNumberOfFullyVisibleTabs >= tabs.length

    if (allTabsFitInTabContainer) {
      setOffset(0)
      return
    }

    const moveTabToLeftOfContainer = -updatedOffset
    const moveTabToRightOfContainer =
      -updatedOffset + tabContainer.width - TAB_WIDTH

    if (
      maxNumberOfFullyVisibleTabs < 1 ||
      leftPositionOfActiveTab < leftSideOfTabContainer
    ) {
      setOffset(moveTabToLeftOfContainer)
    } else if (rightPositionOfActiveTab > rightSideOfTabContainer) {
      setOffset(moveTabToRightOfContainer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex, tabs, windowWidth])

  const handleClickRight = () => {
    if (canScrollRight) {
      let distanceToMove = TAB_WIDTH
      if (containerRef.current && listRef.current) {
        const container = containerRef.current.getBoundingClientRect()
        const list = listRef.current.getBoundingClientRect()
        if (list.right - container.right < TAB_WIDTH) {
          distanceToMove = list.right - container.right
        }
      }
      setOffset(offset - distanceToMove)
    }
  }

  const handleClickLeft = () => {
    if (offset < 0) {
      let distanceToMove = TAB_WIDTH
      if (containerRef.current && listRef.current) {
        const container = containerRef.current.getBoundingClientRect()
        const list = listRef.current.getBoundingClientRect()
        if (container.left - list.left < TAB_WIDTH) {
          distanceToMove = container.left - list.left
        }
      }
      setOffset(offset + distanceToMove)
    }
  }

  const handleClickElipsis = () => {
    showEllipsis && setShowTabList(!showTabList)
  }

  const showEllipsis = offset < 0 || canScrollRight
  const rootClassName = clsx(classes.navTabsRoot, className)
  const unpinned = tabs.filter(t => !t.pinned)

  const ellipsis = (
    <NavTabsIcon
      icon={FaEllipsisH}
      onClick={handleClickElipsis}
      disabled={!showEllipsis}
    />
  )

  return (
    <div className={classes.navTabsContainer}>
      <div className={rootClassName}>
        <div className={classes.container} ref={containerRef}>
          <NavTabsTabList
            offset={offset}
            ref={listRef}
            tabs={unpinned}
            tabWidth={TAB_WIDTH}
          />
        </div>
        <NavTabsIcon
          icon={FaChevronLeft}
          onClick={handleClickLeft}
          disabled={offset >= 0}
        />
        <NavTabsIcon
          icon={FaChevronRight}
          onClick={handleClickRight}
          disabled={!canScrollRight}
        />
        <Dropdown
          buttonContent={ellipsis}
          className={clsx(classes.ellipsis, {
            [classes.disabled]: !showEllipsis,
          })}
          getLabel={(t: any) => t.title}
          onSelectOption={(t: any) => history.push(t.path)}
          options={showEllipsis ? (unpinned as any) : []}
          position='right'
        />
      </div>
    </div>
  )
}

NavTabs.defaultProps = {} as const

export default withRouter(NavTabs)

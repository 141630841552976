import { createReducer } from 'typesafe-actions'
import { getEngagementAction } from '../actions/engagementActions'
import { TsaHubAction } from '../actions/index'
import { EntityMap, EtlFile } from '../clientModels'

export const etlFilesReducer = createReducer<EntityMap<EtlFile>, TsaHubAction>(
  {}
)
  .handleAction([getEngagementAction.success], (state, action) => {
    const { etlFiles } = action.payload
    return {
      ...state,
      ...etlFiles,
    }
  })

import classNames from 'classnames'
import * as React from 'react'
import {
  ClientSearch,
  QuestionMap,
  SectionMap,
  StringMatch,
} from '../../clientModels'
import { padLeadingZeros } from '../../services/formatters/padLeadingZeros'

interface ClientSearchBoxResultProps {
  sections: SectionMap
  questions: QuestionMap
  result: ClientSearch
  searchString: string
  selectedSearchResult?: ClientSearch
  onSelectSearchResult: (result: ClientSearch) => void
}

export class ClientSearchBoxResult extends React.Component<
  ClientSearchBoxResultProps
  > {
  // tslint:disable-next-line:no-any
  me: any
  setMe = (ref: HTMLDivElement) => (this.me = ref)

  handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button === 0) {
      const { onSelectSearchResult, result } = this.props
      onSelectSearchResult(result)
    }
  }

  componentDidUpdate () {
    const {
      props: { result, selectedSearchResult },
      me,
    } = this
    if (result === selectedSearchResult) {
      me.scrollIntoView(me, { block: 'nearest', scrollMode: 'if-needed' })
    }
  }

  createResultText (result: ClientSearch) {
    return <span>{result.name}</span>
  }

  createBasicText (text: string) {
    return text.length > 100 ? text.substr(0, 100) + '...' : text
  }

  render () {
    const { result, selectedSearchResult } = this.props
    // If we add many more result types it would be cleaner
    // to create individual components for each result type
    // and just have the components share the createResultText
    // and createBasicText utility functions.
    const selected = result === selectedSearchResult
    const nameText = formatMatch(result.nameMatch)
    const masterId = formatText(padLeadingZeros(result.masterId), result.matchMaster, '-')
    const clientId = formatText(padLeadingZeros(result.clientId), result.matchClient)
    if (!result.name) {
      return null
    }
    return (
      <div
        ref={this.setMe}
        id={`client-search-box-results-${result.clientId}`}
        className={classNames('client-search-box-result', { selected })}
        onMouseDown={this.handleClick}
      >
        <span id={`client-search-result-${result.clientId}`}>
          {nameText} {masterId} {clientId}
        </span>
      </div>
    )
  }
}

function formatMatch (values: StringMatch[]) {
  return (
    <span>
      {values.map((value, i) => (
        <span key={i}>{formatText(value.value, value.isMatch)}</span>
      ))}
    </span>
  )
}

function formatText (
  value: string | number | undefined,
  isSearchText: boolean,
  ifDefined: string = ''
) {
  if (!value) {
    return
  }
  if (isSearchText) {
    return (
      <span>
        <span className='client-search-text'>{value}</span>
        {ifDefined}
      </span>
    )
  }
  return value + ifDefined
}

import * as React from 'react'
import { TsaJsonSchema } from '../../services/answerSchema/index'
import { FormFieldValue } from '../forms/formComponentsInterfaces'
import { EditorProps } from './editors'
import TextAreaAuto from '../forms/textareaAuto'

export function TextAreaEditor (property: TsaJsonSchema) {
  return class extends React.PureComponent<EditorProps> {
    altDown: boolean = false

    constructor (props: EditorProps) {
      super(props)
      this.handleChange = this.handleChange.bind(this)
      this.handleKeyDown = this.handleKeyDown.bind(this)
      this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    async handleChange (value: FormFieldValue) {
      const {
        props: { onChange },
      } = this
      onChange(value + '')
    }

    handleKeyDown (e: React.KeyboardEvent<HTMLElement>) {
      if (e.key === 'Alt') {
        this.altDown = true
      }
      if (!this.altDown || e.key !== 'Enter') {
        this.props.onKeyDown(e)
      }
    }

    handleKeyUp (e: React.KeyboardEvent<HTMLElement>) {
      if (e.key === 'Alt') {
        this.altDown = false
      }
    }

    render () {
      const {
        handleChange,
        handleKeyDown,
        handleKeyUp,
        props: { value },
      } = this
      return (
        <TextAreaAuto
          allowAltEnterNewLine={true}
          autoFocus={true}
          jsonSchema={property}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          value={value + ''}
        />
      )
    }
  }
}

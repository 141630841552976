import Mousetrap from 'mousetrap'
import * as React from 'react'
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside'
import * as shim from '../../utilities/shims/IE11_reactOnclickoutside'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import {
  ClientAccessRequest,
  ClientSearch,
  QuestionMap,
  SectionMap,
} from '../../clientModels'
import { AppState, TsaDispatch } from '../../store'
import Icon from '../icon/icon'
import './clientSearch.scss'
import ClientSearchBoxResults from './clientSearchBoxResults'
import { searchClients } from '../../actions/clientSearchThunks'
import { getClientsHaveNoAccess } from '../../actions/clientAccessRequestThunks'
import * as clientSearchActions from '../../actions/clientSearchActions'
import * as clientAccessRequestSearchActions from '../../actions/clientAccessRequestSearchActions'
import { Button } from '@rsmus/react-ui'
import { submitAccessRequest } from '../../actions/clientAccessRequestThunks'
import ClientAccessRequestModal from '../clientAccessRequest/clientAccessRequestModal'
import { ClientAccessRequestAction } from '../../enums'

const debounceSearchDelay = 200

interface ClientSearchBoxOwnProps extends RouteComponentProps<{}> {}

type ClientSearchBoxExternalProps = InjectedOnClickOutProps &
  ClientSearchBoxOwnProps

interface ClientSearchBoxConnectedProps {
  sections: SectionMap
  questions: QuestionMap
  results?: ClientSearch[]
  selectedSearchResult?: ClientSearch
  clientResults?: ClientSearch[]
  clientAccessSearchStatus: string
  submitResult?: ClientAccessRequest
}

const mapStateToProps = (
  state: AppState,
  props: ClientSearchBoxExternalProps
): ClientSearchBoxConnectedProps => {
  return {
    sections: state.sections,
    questions: state.questions,
    results: state.clientSearch.results,
    selectedSearchResult: state.clientSearch.selectedSearchResult,
    clientResults: state.clientAccessRequestSearch.results,
    clientAccessSearchStatus:
      state.clientAccessRequestSearch.clientAccessSearchStatus,
    submitResult: state.clientAccessRequestSearch.submitResult,
  }
}

interface ClientSearchBoxDispatchProps {
  search: (searchString: string) => void
  selectSearchResult: (result: ClientSearch) => void
  searchClientsToRequestAccess: (searchString: string) => void
  clientsAccessRequestSelectSearchResult: (result: ClientSearch) => void
  submitClientAccessRequest: (clientId: number, masterId: number) => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: ClientSearchBoxExternalProps
): ClientSearchBoxDispatchProps => {
  return {
    search: (searchString: string) => dispatch(searchClients(searchString)),
    selectSearchResult: (result: ClientSearch) =>
      dispatch(
        clientSearchActions.clientSearchSelectResultAction({
          selectedSearchResult: result,
        })
      ),
    //Client request search Props
    searchClientsToRequestAccess: (searchString: string) =>
      dispatch(getClientsHaveNoAccess(searchString)),
    clientsAccessRequestSelectSearchResult: (result: ClientSearch) =>
      dispatch(
        clientAccessRequestSearchActions.clientSearchSelectResultAction({
          selectedSearchResult: result,
        })
      ),
    submitClientAccessRequest: (clientId: number, masterId: number) =>
      dispatch(submitAccessRequest(clientId, masterId)),
  }
}

type ClientSearchBoxProps = ClientSearchBoxExternalProps &
  ClientSearchBoxConnectedProps &
  ClientSearchBoxDispatchProps

interface ClientSearchBoxState {
  showResults: boolean
  searchString: string
  clientAccessRequestsearchString: string
  clientAccessRequestDialog: boolean
  clientAccessRequestConfirmationDialog: boolean
  clientResultsDisplay: boolean
}

const shortcut = 'ctrl+/'
Mousetrap.prototype.stopCallback = (
  e: KeyboardEvent,
  element: HTMLElement,
  combo: string
) => {
  if (combo === 'ctrl+/') {
    return false
  }

  // if the element has the class "mousetrap" then no need to stop
  if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
    return false
  }

  // stop for input, select, and textarea
  return (
    element.tagName === 'INPUT' ||
    element.tagName === 'SELECT' ||
    element.tagName === 'TEXTAREA' ||
    (element.contentEditable && element.contentEditable === 'true')
  )
}

export class ClientSearchBox extends React.Component<
  ClientSearchBoxProps,
  ClientSearchBoxState
> {
  state = {
    showResults: false,
    searchString: '',
    clientAccessRequestsearchString: '',
    clientAccessRequestDialog: false,
    clientAccessRequestConfirmationDialog: false,
    clientResultsDisplay: false,
  }

  private input = React.createRef<HTMLInputElement>()
  private timeoutId?: number

  componentDidMount() {
    Mousetrap.bind(shortcut, this.focusInput)
  }

  componentWillUnmount() {
    Mousetrap.unbind(shortcut)
  }

  focusInput = () => {
    if (this.input.current) {
      this.input.current.focus()
    }
  }

  performSearch = (searchString: string) => {
    const { search } = this.props
    this.timeoutId = undefined
    search(searchString)
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.showResults()

    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
    }

    this.setState({ searchString: e.currentTarget.value })

    this.timeoutId = window.setTimeout(
      this.performSearch,
      debounceSearchDelay,
      e.currentTarget.value
    )
  }

  performSearchForClientAccessRequest = (searchString: string) => {
    const { searchClientsToRequestAccess } = this.props
    this.timeoutId = undefined
    searchClientsToRequestAccess(searchString)
  }

  handleChangeForClientAccessRequest = (searchString: string) => {
    this.setStateForClientAccessRequestActions(ClientAccessRequestAction.Search)

    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
    }

    this.setState({ clientAccessRequestsearchString: searchString })

    this.timeoutId = window.setTimeout(
      this.performSearchForClientAccessRequest,
      debounceSearchDelay,
      searchString
    )
  }

  handleClickOutside = () => this.hideResults()

  handleSelectSearchResult = (result?: ClientSearch) => {
    const { results, selectSearchResult } = this.props
    if (!result) {
      result = results && results[0]
    }
    if (result) {
      selectSearchResult(result)
      this.navigateToResult(result)
      if (this.input.current) {
        this.input.current.blur()
      }
      this.hideResults()
    }
  }

  navigateToResult(result: ClientSearch) {
    const {
      props: { history },
    } = this

    history.push(`/clientresults/${result.clientId}`)
  }

  showResults = () => {
    // this.props.enableOnClickOutside()
    this.setState({ showResults: true })
  }

  hideResults = () => {
    // this.props.disableOnClickOutside()
    this.setState({ showResults: false })
  }

  showClientAccessRequestDialog = () => {
    this.setState({
      clientAccessRequestDialog: true,
      clientResultsDisplay: true,
    })
  }

  setStateForClientAccessRequestActions = (action: string) => {
    if (action === ClientAccessRequestAction.Add) {
      const { submitResult } = this.props
      if (submitResult !== undefined) {
        this.setState({
          clientAccessRequestConfirmationDialog: true,
        })
      }

      this.setState({
        clientAccessRequestDialog: false,
        clientAccessRequestsearchString: '',
      })
    } else if (action === ClientAccessRequestAction.Close) {
      this.setState({
        clientAccessRequestDialog: false,
        clientResultsDisplay: false,
        clientAccessRequestConfirmationDialog: false,
        clientAccessRequestsearchString: '',
      })
    } else if (action === ClientAccessRequestAction.Search) {
      this.setState({
        clientResultsDisplay: false,
      })
    }
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        this.nextResult()
        break
      case 'ArrowUp':
        e.stopPropagation()
        e.preventDefault()
        this.previousResult()
        break
      case 'Enter':
        e.stopPropagation()
        e.preventDefault()
        this.handleSelectSearchResult(this.props.selectedSearchResult)
        break
      case 'Escape':
        this.hideResults()
        break
      default:
        break
    }
  }

  selectedIndex() {
    const { selectedSearchResult, results } = this.props
    return results && selectedSearchResult
      ? results.indexOf(selectedSearchResult)
      : -1
  }

  previousResult() {
    const { results, selectSearchResult } = this.props
    if (!results) {
      return
    }
    const selectedIndex = this.selectedIndex()
    const previousIndex =
      selectedIndex <= 0 ? results.length - 1 : selectedIndex - 1
    selectSearchResult(results[previousIndex])
  }

  nextResult() {
    const { results, selectSearchResult } = this.props
    if (!results) {
      return
    }
    const selectedIndex = this.selectedIndex()
    const nextIndex =
      selectedIndex >= results.length - 1 ? 0 : selectedIndex + 1
    selectSearchResult(results[nextIndex])
  }

  render() {
    const {
      showResults,
      searchString,
      clientAccessRequestsearchString,
      clientAccessRequestDialog,
      clientAccessRequestConfirmationDialog,
      clientResultsDisplay,
    } = this.state
    const {
      questions,
      results,
      sections,
      selectedSearchResult,
      clientResults,
      clientAccessSearchStatus,
      submitClientAccessRequest,
    } = this.props

    const isDisabled =
      clientAccessRequestDialog && !clientAccessRequestConfirmationDialog

    shim.IE11_react_onclickoutside()

    return (
      <div>
        <div>
          <div className='client-search-box'>
            <form className='form-inline'>
              <input
                className='form-control'
                id='client-search-box'
                onChange={this.handleChange}
                onFocus={this.showResults}
                onKeyDown={this.handleKeyDown}
                placeholder='Search (Ctrl + ?)'
                ref={this.input}
                type='text'
                value={searchString}
                disabled={isDisabled}
              />
              <div>
                <Icon icon='search' />
              </div>
              <Button
                variant={'primary'}
                disabled={isDisabled}
                color='primary'
                onClick={this.showClientAccessRequestDialog}
              >
                REQUEST CLIENT ACCESS
              </Button>
            </form>

            {showResults &&
              searchString &&
              !clientAccessRequestDialog &&
              !clientAccessRequestConfirmationDialog && (
                <ClientSearchBoxResults
                  onSelectSearchResult={this.handleSelectSearchResult}
                  questions={questions}
                  results={results}
                  searchString={searchString}
                  sections={sections}
                  selectedSearchResult={selectedSearchResult}
                />
              )}
          </div>
        </div>
        <ClientAccessRequestModal
          showModal={clientAccessRequestDialog}
          clients={clientResults}
          displayResults={clientResultsDisplay}
          showConfirmationModal={clientAccessRequestConfirmationDialog}
          searchString={clientAccessRequestsearchString}
          clientAccessSearchStatus={clientAccessSearchStatus}
          setActions={this.setStateForClientAccessRequestActions}
          submitClientAccessRequest={submitClientAccessRequest}
          handleChange={this.handleChangeForClientAccessRequest}
        ></ClientAccessRequestModal>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(ClientSearchBox))

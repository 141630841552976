import * as React from 'react'
import ExternalContent from '../external/externalContent'
import InternalContent from '../internal/internalContent'
import './assistance.scss'
import { REACT_APP_GLOBALMAJORMINORBUILDREVISION } from '../../envVariables'

function AssistanceBody() {

  return (
    <div className='assistance'>
      <div className='assistance-group'>
        <div className='title'>Phone Assistance:</div>
        <ExternalContent>
          <div>
            Call <a href='tel:+18335183394'>833-518-3394</a> to get assistance
          </div>
        </ExternalContent>
        <InternalContent>
          <div>
            Call <a href='tel:+18774627573'>877-462-7573</a>
          </div>
        </InternalContent>
      </div>
      <div className='assistance-group'>
        <div className='title'>Version of the app you are using:</div>
        {REACT_APP_GLOBALMAJORMINORBUILDREVISION}
      </div>
    </div>
  )
}

export default AssistanceBody

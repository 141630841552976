import GenerateSorts, { SortUndefinedToEnd } from './sorting'
import { File, UserProfile, FileTag } from '../../clientModels'
import moment from 'moment'

const FileSorts = {
  AddedBy: function (a: File, b: File) {
    return SortUndefinedToEnd(a.userProfile, b.userProfile, function (
      a: UserProfile,
      b: UserProfile
    ) {
      const aName = `${a.lastName} ${a.firstName}`
      const bName = `${b.lastName} ${b.firstName}`

      return aName.localeCompare(bName)
    })
  },
  AddedDate: function (a: File, b: File) {
    return SortUndefinedToEnd(a.createdDate, b.createdDate, function (
      a: string,
      b: string
    ) {
      const aParsed = moment.utc(a, moment.ISO_8601)
      const bParsed = moment.utc(b, moment.ISO_8601)

      return aParsed.diff(bParsed)
    })
  },
  FileName: function (a: File, b: File) {
    return a.name.localeCompare(b.name)
  },
  Id: function (a: File, b: File) {
    return a.id - b.id
  },
  Status: function (a: File, b: File) {
    return SortUndefinedToEnd(a.status, b.status, function (
      a: string,
      b: string
    ) {
      return a.localeCompare(b)
    })
  },
  Draft: function (a: File, b: File) {
    return SortUndefinedToEnd(a.tags, b.tags, function (
      a: FileTag[],
      b: FileTag[]
    ) {
      const aDraft = containsFileTag(a, 'draft')
      const bDraft = containsFileTag(b, 'draft')
      return aDraft === bDraft ? 0 : !aDraft ? 1 : -1
    })
  },
}

const DefaultFileSorts = {
  AddedBy: ['AddedBy', 'Id'],
  AddedDate: ['AddedDate', 'Id'],
  FileName: ['FileName', 'Id'],
  Status: ['Status', 'Id'],
  Draft: ['Draft', 'Id'],
}

const [DefaultSort, CustomSort] = GenerateSorts(FileSorts, DefaultFileSorts)

function containsFileTag(tags: FileTag[] | undefined, tag: string) {
  return !!(tags && tags.some(x => x.tag === tag))
}

export {
  DefaultSort,
  CustomSort,
  DefaultFileSorts,
  FileSorts,
  containsFileTag,
}

import classNames from 'classnames'
import * as H from 'history'
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  Client,
  Engagement,
  EngagementTemplate,
  IdentityTokenProfile,
  LastNavigation,
  PhaseMap,
} from '../../clientModels'
import { MilestoneCode, PhaseCode } from '../../enums'
import {
  canUnlockEngagment,
  engagementLockedPhase,
} from '../../services/engagementPhaseService'
import { padLeadingZeros } from '../../services/formatters/padLeadingZeros'
import { EngagementPhaseChange } from '../engagementPhaseChange/engagementPhaseChange'
import Icon from '../icon/icon'
import Lock from '../icon/Lock'
import InternalContent from '../internal/internalContent'
import { EngagementReviewSummary } from './engagementReviewSummary'
import './engagementSummary.scss'
import { EngagementSummaryButtons } from './engagementSummaryButtons'
import { EngagementSummaryMessage } from './engagementSummaryMessage'
import { EngagementEtlMessage } from './engagementEtlMessage'
import ClientLastModifiedMessage from './clientLastModifiedMessage'
import { getClientUrlPortion } from '../../services/filterHelpers'
import { REACT_APP_ENGAGEMENTMANAGERURL } from '../../envVariables'

export interface EngagementSummaryProps {
  client?: Client
  engagement?: Engagement
  engagementTemplate?: EngagementTemplate
  history: H.History
  markReviewDone: (engagementId: string | number) => void
  phases: PhaseMap
  updateEngagementPhase: (
    engagementId: string | number,
    phase: PhaseCode
  ) => void
  submitEngagement: (engagementId: string | number) => void
  getLastYearsData: (engagementId: string | number) => void
  copyLastYearAnswers: (engagementId: string | number) => void
  toggleFavorite: (engagementId: string | number) => void
  toggleLock: (engagementId: string | number, clientId?: number) => void
  user?: IdentityTokenProfile
  lastNavigation?: LastNavigation
}

export class EngagementSummary extends React.Component<EngagementSummaryProps> {
  launchEngagementManager = () => {
    const {
      props: { engagement },
    } = this
    const engagmentManagerUrl =
      `${REACT_APP_ENGAGEMENTMANAGERURL}/engagements/${engagement?.id + ''}`

    window.open(engagmentManagerUrl, '_blank')
  }

  toggleFavorite = () => {
    const {
      props: { toggleFavorite, engagement },
    } = this

    if (toggleFavorite && engagement) {
      toggleFavorite(engagement.id)
    }
  }

  toggleLock = () => {
    const {
      props: { toggleLock, engagement },
    } = this

    if (toggleLock && engagement) {
      toggleLock(engagement.id, engagement.clientId)
    }
  }

  render() {
    const {
      client,
      engagement,
      history,
      markReviewDone,
      phases,
      updateEngagementPhase,
      submitEngagement,
      getLastYearsData,
      copyLastYearAnswers,
      user,
      engagementTemplate,
      lastNavigation,
    } = this.props

    if (!engagement || !user || !engagementTemplate) {
      return null
    }

    if (user.isExternal && engagement.phase === PhaseCode.Setup) {
      // Hide this engagement from external users during the setup phase
      return null
    }

    const milestone =
      engagement.milestones &&
      engagement.milestones.filter(x => x.milestone === MilestoneCode.CCH)[0]
    const dueDate = moment(milestone && milestone.dueDate, moment.ISO_8601)
    const lastModified = moment(engagement.lastUpdatedDate, moment.ISO_8601)
    const lastModifiedBy = engagement.lastUpdatedBy

    const nameLinkTo = `${getClientUrlPortion(
      history.location.pathname
    )}/engagements/${engagement.id}`

    const shouldLinkName = nameLinkTo !== history.location.pathname

    const unlockable = canUnlockEngagment(engagement, user)

    const locked =
      engagement.isEnabled !== undefined
        ? engagement.isEnabled
        : engagementLockedPhase(engagement)
    const hideLock = !locked && user.isExternal

    return (
      <div className='engagement-summary'>
        <div className='engagement-summary-info1'>
          <div className='summary-label'>CLIENT: </div>
          <div className='client-name'>{client && client.name} </div>
          <InternalContent>
            <div className='clientid'>
              {client && (
                <span>
                  {padLeadingZeros(engagement.clientId, client.masterId)}
                </span>
              )}
            </div>
          </InternalContent>
          <div className='engagement-summary-buttons'>
            <InternalContent>
              <div>
                <Icon
                  icon='edit'
                  onClick={this.launchEngagementManager}
                  className={classNames('clickable', {})}
                  tooltip={'Edit engagement'}
                />
              </div>
            </InternalContent>
            <InternalContent>
              <div className='favorite'>
                <Icon
                  icon='heart'
                  onClick={this.toggleFavorite}
                  className={classNames('clickable', {
                    favorited: engagement.favorited,
                  })}
                  tooltip={
                    engagement.favorited
                      ? 'Unfavorite this engagement.'
                      : 'Favorite this engagement.'
                  }
                />
              </div>
            </InternalContent>
            <InternalContent>
              <div className='lock'>
                <Lock
                  active={locked}
                  onClick={unlockable ? this.toggleLock : undefined}
                  className={classNames(
                    { clickable: unlockable },
                    { disabled: !unlockable },
                    { hide: hideLock }
                  )}
                  tooltip={
                    !unlockable
                      ? 'Engagement locked'
                      : locked
                      ? 'Unlock this engagement.'
                      : 'Lock this engagement.'
                  }
                />
              </div>
            </InternalContent>
          </div>
        </div>
        {shouldLinkName ? (
          <Link to={nameLinkTo} className='engagement-name'>
            {engagement.name}
          </Link>
        ) : (
          <div className='engagement-name'>{engagement.name}</div>
        )}
        <div className='engagement-summary-info'>
          {dueDate.isValid() && (
            <div className='engagementInfo'>
              {' '}
              <div className='summary-label'>DUE: </div>{' '}
              <div className='duedate'>{dueDate.format('L')}</div>
            </div>
          )}
          {lastModified.isValid() && (
            <div className='engagementInfo'>
              <div className='date summary-label'>Last Modified:</div>{' '}
              <div> {lastModified.format('L')}</div>
            </div>
          )}
          {lastModifiedBy && (
            <div className='engagementInfo'>
              <div className='modified-by'>
                <div>&nbsp;by</div>
                <div>
                  {' '}
                  {lastModifiedBy.isInternal ? '(RSM) ' : ''}
                  {lastModifiedBy.email}
                </div>
              </div>
            </div>
          )}
          {engagement.taxYear && (
            <div className='engagementInfo'>
              <div className='taxyear summary-label'>TAX YEAR:</div>{' '}
              <div> {engagement.taxYear}</div>
            </div>
          )}
          {!user.isExternal &&
            engagementTemplate &&
            engagementTemplate.cchReturnType && (
              <div className='engagementInfo'>
                {' '}
                <div className='cchtype summary-label'>TYPE: </div>
                <div>{engagementTemplate.cchReturnType}</div>
              </div>
            )}
          {!user.isExternal && engagement.cchVersion && (
            <div className='engagementInfo'>
              <div className='cchversion summary-label'>VERSION: </div>
              <div>{engagement.cchVersion}</div>
            </div>
          )}
          {!user.isExternal && engagement.cchVersion && (
            <div className='engagementInfo'>
              <div className='cchversion summary-label'>RETURN TYPE: </div>
              <div>{engagement.engagementTaxForm}</div>
            </div>
          )}
        </div>

        <ClientLastModifiedMessage
          clientsLastModified={engagement.clientsLastModified}
        />
        <EngagementSummaryMessage engagement={engagement} user={user} />
        <EngagementEtlMessage engagement={engagement} user={user} />
        <EngagementReviewSummary engagement={engagement} user={user} />
        <EngagementPhaseChange
          engagement={engagement}
          engagementTemplate={engagementTemplate}
          childComponent={EngagementSummaryButtons}
          submitEngagement={submitEngagement}
          phases={phases}
          updateEngagementPhase={updateEngagementPhase}
          getLastYearsData={getLastYearsData}
          copyLastYearAnswers={copyLastYearAnswers}
          user={user}
          history={history}
          markReviewDone={markReviewDone}
          lastNavigation={lastNavigation}
        />
      </div>
    )
  }
}

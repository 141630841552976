import { TsaHubAction } from '../actions/index'
import { UserSettings } from '../clientModels'
import { getType } from 'typesafe-actions'
import {
  lastNavSettingsDoneAction,
  lastNavSettingsUpdateAction,
} from '../actions/lastnavActions'
const initialState: UserSettings = {
  lastNavigationMap: {},
}

export function userSettingsReducer (
  state: UserSettings = initialState,
  action: TsaHubAction
): UserSettings {
  switch (action.type) {
    case getType(lastNavSettingsUpdateAction): {
      const { lastNavigation } = action.payload
      // walk down the object with spread at each level
      return {
        ...state,
        lastNavigationMap: {
          ...state.lastNavigationMap,
          [lastNavigation.engagementId]: lastNavigation,
        },
      }
    }
    case getType(lastNavSettingsDoneAction): {
      const { lastNavigationMap } = action.payload
      state = { ...state, lastNavigationMap }
      return state
    }
    default:
      break
  }
  return state
}

import { getType } from 'typesafe-actions'
import { TsaHubAction } from '../actions/index'
import { EntityMap, K1Header } from '../clientModels'
import * as k1Actions from '../actions/k1Actions'
import { getEngagementAction } from '../actions/engagementActions'
import { normalizeK1s } from '../actions/normalization'
import {
  updateK1StatusAction,
  getEngagementK1sActions,
} from '../actions/inputProcessActions'
import { unique } from '../components/configureImport/helpers'
export interface k1HeaderState {
  k1s: EntityMap<K1Header>
  filteredK1s: K1Header[]
  issuerFilterList: string[]
  receiverFilterList: string[]
  searchFilter: string | null
  selectedIssuerFilter: string | null
  selectedReceiverFilter: string | null
  selectedStatusFilter: string | null
  sortAscending: boolean
  sortField: string | null
  statusFilterList: string[]
}

const initialState: k1HeaderState = {
  k1s: {} as EntityMap<K1Header>,
  filteredK1s: [],
  issuerFilterList: [],
  receiverFilterList: [],
  searchFilter: null,
  selectedIssuerFilter: null,
  selectedReceiverFilter: null,
  selectedStatusFilter: null,
  sortAscending: true,
  sortField: null,
  statusFilterList: [],
}

export const k1HeaderReducer = function (
  state = initialState,
  action: TsaHubAction
): k1HeaderState {
  switch (action.type) {
    // sorting doesn't require updating filtering or filter options
    case getType(k1Actions.setIntialSortAction): {
      let newState = sort(state, action.payload)
      newState.sortAscending = true
      return newState
    }
    case getType(k1Actions.setSortAction): {
      return sort(state, action.payload)
    }
    default: {
      // the rest of the actions require updating filtering and filter options
      let newState = reduce(state, action)
      newState = setFilteredK1s(newState)
      return setFilterOptions(newState)
    }
  }
}

const reduce = function(
  state = initialState,
  action: TsaHubAction
): k1HeaderState {
  switch (action.type) {
    case getType(k1Actions.clearFilterAction): {
      let newState: k1HeaderState = {
        ...state,
        searchFilter: null,
        selectedIssuerFilter: null,
        selectedReceiverFilter: null,
        selectedStatusFilter: null,
      }
      return newState
    }

    case getType(k1Actions.deleteK1HeadersAction.success): {
      const deletedK1s = action.payload
      const newK1s = {
        ...state.k1s,
      }
      deletedK1s.forEach((x: number) => delete newK1s[x])
      return {
        ...state,
        k1s: newK1s,
      }
    }

    case getType(getEngagementAction.success): {
      const { k1s } = action.payload
      return {
        ...state,
        k1s: normalizeK1s(k1s) || {},
      }
    }

    case getType(updateK1StatusAction.success): {
      const updatedK1 = action.payload
      const newK1s = {
        ...state.k1s,
      }
      newK1s[updatedK1.id] = updatedK1
      return {
        ...state,
        k1s: newK1s,
      }
    }

    case getType(getEngagementK1sActions.success): {
      const updatedK1s = action.payload
      return {
        ...state,
        k1s: normalizeK1s(updatedK1s) || {},
      }
    }

    case getType(k1Actions.setIssuerFilterAction): {
      return {
        ...state,
        selectedIssuerFilter: action.payload,
      }
    }

    case getType(k1Actions.setReceiverFilterAction): {
      return {
        ...state,
        selectedReceiverFilter: action.payload,
      }
    }

    case getType(k1Actions.setSearchFilterAction): {
      return {
        ...state,
        searchFilter: action.payload,
      }
    }

    case getType(k1Actions.setStatusFilterAction): {
      return {
        ...state,
        selectedStatusFilter: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

const setFilterOptions = (state: k1HeaderState): k1HeaderState => {
  const k1s = state.filteredK1s || []

  const filters = {
    issuerFilterList: unique(k1s.map((k1h) => k1h.issuingName)),
    receiverFilterList: unique(k1s.map((k1h) => k1h.receivingName)),
    statusFilterList: unique(k1s.map((k1h) => k1h.status)),
  }

  return {
    ...state,
    ...filters,
  }
}


const denormalize = function<T>(map: EntityMap<T>): T[] {
  const denormalized: T[] = []

  for (const index in map) {
    var item = map[index]
    if (item) {
      denormalized.push(item)
    }
  }

  return denormalized
}

const setFilteredK1s = (state: k1HeaderState): k1HeaderState => {
  let filteredK1s = denormalize(state.k1s)

  if (state.selectedStatusFilter) {
    filteredK1s = filteredK1s.filter(
      (k1) => k1.status === state.selectedStatusFilter
    )
  }

  if (state.selectedReceiverFilter) {
    filteredK1s = filteredK1s.filter(
      (k1) => k1.receivingName === state.selectedReceiverFilter
    )
  }

  if (state.selectedIssuerFilter) {
    filteredK1s = filteredK1s.filter(
      (k1) => k1.issuingName === state.selectedIssuerFilter
    )
  }

  filteredK1s = textFilter(filteredK1s, state.searchFilter)

  return {
    ...state,
    filteredK1s,
  }
}

const textFilter = (k1s: K1Header[], value: string | null) => {
  if (!value) return k1s
  const encoded = value
    .replace('?', '\\?')
    .replace('(', '\\(')
    .replace(')', '\\)')
    .replace('.', '\\.')

  const regex = new RegExp(encoded, 'i')
  return k1s.filter((k1) => {
    const text = `${k1.issuingName}|${k1.receivingName} ??
      ''}|${k1.state || ''}|${k1.status ?? ''}`
    const include = regex.test(text)
    return include
  })
}

const sort = (state: k1HeaderState, sortName: string) => {
  const newState = {
    ...state,
    sortField: sortName,
  }

  if (state.sortField !== sortName) {
    newState.sortAscending = true
  } else {
    newState.sortAscending = !state.sortAscending
  }

  let result = state.filteredK1s
 
  newState.filteredK1s = result

  return newState
}

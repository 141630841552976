import { History } from 'history'
import { compact, filter, map } from 'lodash'
import { ThunkDispatch } from 'redux-thunk'
import {
  DocumentTitle,
  File,
  File as TsaFile,
  FileContentsMap,
  FileGroup
} from '../clientModels'
import { joinPaths } from '../components/relativeLink'
import { ensureNumber } from '../guards'
import { selectQuestionFileGroups } from '../reducers/selectors'
import * as ActivityFactory from '../services/activityFactory'
import {
  buildApiUrl,
  EngagementUploadHistoriesApi,
  FileGroupsApi,
  FilesApi,
  K1Api,
  TaxFormContentApi
} from '../services/api'
import * as ApiModels from '../services/api/apiModels'
import { Guid } from '../services/guid'
import { post, saveBlob } from '../services/http'
import { trackGet } from '../services/track'
import { AppState, TsaDispatch, TsaThunkAction } from '../store'
import { saveActivity } from './activityThunks'
import { getEngagementK1s } from './inputProcessThunks'
import * as actions from './fileActions'
import {
  httpBeginAction as startBusy,
  httpEndAction as stopBusy,
  httpSaveBeginAction,
  httpSaveEndAction
} from './httpActions'
import { TsaHubAction } from './index'
import { runAllEngagementRules, runQuestionRules } from './rulesThunks'
import { selectionSidebarSelectAction } from './selectionActions'
import { DocumentTitleId } from '../enums'
import { REACT_APP_GLOBALFILEDOWNLOADTIMEOUTINSECS } from '../envVariables'

let customFileClose:
  | ((
    dispatch: ThunkDispatch<AppState, undefined, TsaHubAction>,
    getState: () => AppState
  ) => void)
  | undefined

export const fileUploadDone = (): TsaThunkAction => (dispatch, getState) => {
  if (customFileClose) {
    customFileClose(dispatch, getState)
    customFileClose = undefined
  }

  // Closes the file upload component if it isn't already chosen
  if (getState().selection.selectedSidebarName !== 'activity') {
    dispatch(
      selectionSidebarSelectAction({
        selectedSidebarName: 'activity',
        toggle: false
      })
    )
  }
}

export function clearCustomFileClose() {
  customFileClose = undefined
}

function postEngagementSetup(file: ApiModels.File): Promise<TsaFile> {
  const data = new FormData()
  for (const key in file) {
    data.append(key, file[key])
  }

  return post(
    buildApiUrl('EngagementFileUploads'),
    data,
    { headers: undefined },
    undefined,
    ensureNumber(REACT_APP_GLOBALFILEDOWNLOADTIMEOUTINSECS) || 90
  )
}

function buildActivityValue(
  added: string[],
  updated: string[],
  deleted: string[],
  fileGroupName: string | undefined
) {
  const addedLength = added.length
  const updatedLength = updated.length
  const deletedLength = deleted.length
  let activityValue = 'Updated ' + fileGroupName
  if (addedLength > 0 && updatedLength === 0 && deletedLength === 0) {
    // Files were only added
    activityValue = 'Added to ' + fileGroupName + ' - ' + added.join(', ')
  } else if (deletedLength > 0 && updatedLength === 0 && addedLength === 0) {
    // Files were only deleted
    activityValue = 'Deleted from ' + fileGroupName + ' - ' + deleted.join(', ')
  } else if (addedLength > 0 || updatedLength > 0 || deletedLength > 0) {
    // At least one file was added, updated or deleted, but not all changes were additions and not all changes were deletions
    activityValue +=
      ' - ' +
      compact([
        listModifiedFiles(added, 'Added'),
        listModifiedFiles(updated, 'Updated'),
        listModifiedFiles(deleted, 'Deleted')
      ]).join('; ')
  }

  return activityValue
}

function buildActivityPropertyBag(
  added: TsaFile[],
  updated: TsaFile[],
  deleted: TsaFile[],
  fileGroupName: string | undefined
) {
  const addedLength = added.length
  const updatedLength = updated.length
  const deletedLength = deleted.length

  const propertyBagList: Array<{
    FileGroupName: string | undefined
    Files: Array<{
      FileName: string
      Id: number
    }>
    ActionType: string
  }> = []

  const addedFileList: Array<{
    FileName: string
    Id: number
  }> = []

  const updatedFileList: Array<{
    FileName: string
    Id: number
  }> = []

  const deletedFileList: Array<{
    FileName: string
    Id: number
  }> = []

  added.map(x =>
    addedFileList.push({
      FileName: x.name,
      Id: x.id
    })
  )

  updated.map(x =>
    updatedFileList.push({
      FileName: x.name,
      Id: x.id
    })
  )

  deleted.map(x =>
    deletedFileList.push({
      FileName: x.name,
      Id: x.id
    })
  )

  if (addedLength > 0) {
    propertyBagList.push({
      FileGroupName: fileGroupName,
      Files: addedFileList,
      ActionType: 'Added'
    })
  }

  if (deletedLength > 0) {
    propertyBagList.push({
      FileGroupName: fileGroupName,
      Files: deletedFileList,
      ActionType: 'Deleted'
    })
  }

  if (updatedLength > 0) {
    propertyBagList.push({
      FileGroupName: fileGroupName,
      Files: updatedFileList,
      ActionType: 'Updated'
    })
  }

  return JSON.stringify(propertyBagList)
}

const listModifiedFiles = (modified: string[], separator: string) =>
  modified.map(s => `${separator} ${s}`).join(', ')

export const FileUploadBusyIndicator = 'fileupload-busy'

export const uploadEngagementSetup = (
  file: TsaFile
): TsaThunkAction<Promise<void>> => async dispatch => {
  // Start the file upload busy indicator
  dispatch(startBusy({ id: FileUploadBusyIndicator }))

  try {
    if (file) {
      dispatch(actions.uploadEngagementSetup.request())
      await postEngagementSetup(file)
      const results = await EngagementUploadHistoriesApi.odataGetEngagementUploadHistories()
      dispatch(actions.uploadEngagementSetup.success(results))
    }
  } catch (error) {
    dispatch(actions.uploadEngagementSetup.failure(error))
  }

  // Stop the file upload busy indicator
  dispatch(stopBusy({ id: FileUploadBusyIndicator }))
}

export const addOrUpdateFileGroup = (
  engagementId: string | number,
  questionId: string | number,
  fileGroup: FileGroup,
  isTemplate?: boolean
): TsaThunkAction => async (dispatch, getState) => {
  engagementId = ensureNumber(engagementId)
  questionId = ensureNumber(questionId)
  fileGroup.engagementId = engagementId
  fileGroup.questionId = questionId

  dispatch(actions.addUpdateFileGroup.request())

  // Start the file upload busy indicator
  dispatch(startBusy({ id: FileUploadBusyIndicator }))

  try {
    const state = getState()
    const engagement = state.engagements[engagementId]
    const clientId = engagement && engagement.clientId

    // Get a mutable copy of the file group and files array to work with inside the action
    // We can't send files along with the group to the OData call because it will fail
    const { files, ...rest } = fileGroup
    rest.clientId = clientId
    if (files) {
      files.forEach(f => {
        if (f.id < 0 || f.isUpdated) {
          f.status = 'Uploading'
          dispatch(
            actions.setFileUploadStatus({
              updates: { name: f.name, status: 'Uploading' }
            })
          )
        }
      })
    }
    const group = await FileGroupsApi.apiSaveFileGroup(rest)
    let errors: string = ''
    let addedFiles: TsaFile[] = []

    if (files) {
      // Make sure all IDs in files match the file group.
      // We have to do this after odataSaveFileGroup so we have FileGroup ID set by the database.
      files.forEach(f => {
        f.fileGroupId = group.id
        f.clientId = rest.clientId
        if (isTemplate && f.id >= 0) {
          f.isDeleted = !f.isUpdated
        }
      })

      addedFiles = files.filter(f => f.id < 0)
      const deletedFiles = filter(files, 'isDeleted')
      let updatedFiles = filter(files, 'isUpdated')
      const filesToAddOrUpdate = [...addedFiles, ...updatedFiles]
      const fileContentsMap: FileContentsMap = {}
      const filesToPrepare = filesToAddOrUpdate.map(file => {
        // Need to remove contents from files before they are sent to TSA
        const { contents, ...fileProps } = file
        // Add contents to file contents map for use in file uploads to Azure
        fileContentsMap[file.name] = contents
        return fileProps
      })

      // Get SAS uris for files to add or update
      const preparedFiles =
        filesToPrepare.length > 0
          ? await FilesApi.apiPrepareUpload(engagementId, filesToPrepare)
          : []

      // remove the added and updated files and substitue with prepared files
      group.files = files
        .filter(f => !f.isDeleted && !f.isUpdated && f.id !== -1)
        .concat(preparedFiles)

      dispatch(
        actions.addUpdateFileGroup.success({ engagementId, questionId, group })
      )
      // Delete files in parallel before continuing
      await Promise.all(map(deletedFiles, 'id').map(FilesApi.apiDeleteFile))

      const filesUpdatedAfterUpload = await Promise.all(
        preparedFiles.map(updates => {
          dispatch(actions.setFileUploadStatus({ updates }))
          return FilesApi.apiUploadFile(
            updates,
            fileContentsMap,
            (error, file: TsaFile) => {
              // remove file from add/delete/update
              // convert error to the appropriate context
              dispatch(actions.setFileUploadStatus({ updates }))
              if (file.isUpdated) {
                updatedFiles = filter(
                  updatedFiles,
                  (value: TsaFile) => value.name !== file.name
                )
                errors += `Could not update file ${file.name}\r\n`
              } else {
                addedFiles = filter(
                  addedFiles,
                  (value: TsaFile) => value.name !== file.name
                )
                errors += `Could not add file ${file.name}\r\n`
              }
              if (error) {
                errors += `${error.name} - ${error.message}\r\n`
              }
            }
          ).then((updates: TsaFile) => {
            dispatch(actions.setFileUploadStatus({ updates }))
            return updates
          })
        })
      )

      // Set files on the group again.
      const filteredFiles = files
        .filter(
          f => !deletedFiles.includes(f) && !filesToAddOrUpdate.includes(f)
        )
        .concat(filesUpdatedAfterUpload.filter(f => !!f))

      group.files = filteredFiles

      if (
        addedFiles.length > 0 ||
        updatedFiles.length > 0 ||
        deletedFiles.length > 0
      ) {
        // Changed something, so create the activity
        const activityValue = buildActivityValue(
          map(addedFiles, 'name'),
          map(updatedFiles, 'name'),
          map(deletedFiles, 'name'),
          fileGroup.title
        )
        const propertyBag = buildActivityPropertyBag(
          addedFiles,
          updatedFiles,
          deletedFiles,
          fileGroup.title
        )
        const activity = ActivityFactory.CreateDocumentActivity(
          activityValue,
          propertyBag,
          engagementId,
          questionId,
          clientId
        )

        dispatch(saveActivity(activity))
      }
    }

    if (addedFiles.length === 0 && fileGroup.id < 0) {
      // delete file group that was just created and none of the files could be uploaded
      await deleteFileGroupAsync(dispatch, engagementId, questionId, group.id)
    } else {
      await dispatch(runAllEngagementRules(engagementId))
    }

    if (errors !== '') {
      // found at least one error
      throw errors
    }
  } catch (error) {
    if (isTemplate) {
      dispatch(actions.addUpdateFileGroupTemplateFailure(error))
    } else {
      dispatch(actions.addUpdateFileGroup.failure(error))
    }
  }

  // Stop the file upload busy indicator
  dispatch(stopBusy({ id: FileUploadBusyIndicator }))

  dispatch(fileUploadDone())
}

const deleteFileGroupAsync = async (
  dispatch: TsaDispatch,
  engagementId: number,
  questionId: number,
  fileGroupId: number
) => {
  dispatch(actions.deleteFileGroup.request())
  try {
    await FileGroupsApi.apiDeleteFileGroup(fileGroupId)
    dispatch(
      actions.deleteFileGroup.success({ engagementId, questionId, fileGroupId })
    )
  } catch (error) {
    dispatch(actions.deleteFileGroup.failure(error))
  }
}

export const toggleTag = (
  tag: string,
  engagementId: string | number,
  questionId: string | number,
  fileGroupId: number,
  fileIds: number[]
): TsaThunkAction => async (dispatch, getState) => {
  // Start the file upload busy indicator
  dispatch(startBusy({ id: FileUploadBusyIndicator }))
  engagementId = ensureNumber(engagementId)
  questionId = ensureNumber(questionId)
  try {
    dispatch(
      actions.toggleTag.request({
        tag,
        engagementId,
        questionId,
        fileGroupId,
        fileIds
      })
    )

    const files =
      (await FilesApi.apiToggleTag(tag, engagementId, fileIds)) || []

    dispatch(
      actions.toggleTag.success({
        tag,
        engagementId,
        questionId,
        fileGroupId,
        files
      })
    )
  } catch (error) {
    dispatch(actions.toggleTag.failure(error))
  }
  // Stop the file upload busy indicator
  dispatch(stopBusy({ id: FileUploadBusyIndicator }))
}

export const softDeleteFiles = (
  engagementId: string | number,
  questionId: string | number,
  fileGroupId: number,
  fileIds: number[]
): TsaThunkAction => async (dispatch, getState) => {
  // Start the file upload busy indicator
  dispatch(startBusy({ id: FileUploadBusyIndicator }))
  engagementId = ensureNumber(engagementId)
  questionId = ensureNumber(questionId)
  const state = getState()
  const engagement = state.engagements[engagementId]
  const clientId = engagement && engagement.clientId

  let targetFileGroupName: string | undefined = ''
  let groupFiles: TsaFile[] = []
  const fileGroup = state.fileGroups[fileGroupId]
  if (fileGroup) {
    targetFileGroupName = fileGroup.title
    if (fileGroup.files) {
      groupFiles = fileGroup.files
    }
  }

  // get the deleted files from the list of files.
  const deletedFiles = groupFiles.filter(f => fileIds.includes(f.id))

  try {
    dispatch(
      actions.softDeleteFilesStatus.request({
        engagementId,
        questionId,
        fileGroupId,
        fileIds
      })
    )

    await FilesApi.apiSoftDeleteFiles(engagementId, fileIds)
    dispatch(getEngagementK1s(engagementId))
    dispatch(
      actions.softDeleteFilesStatus.success({
        engagementId,
        questionId,
        fileGroupId,
        fileIds
      })
    )
    const activityValue = buildActivityValue(
      [],
      [],
      map(deletedFiles, 'name'),
      targetFileGroupName
    )
    // Add activity to the  delete of files
    const propertyBag = buildActivityPropertyBag(
      [],
      [],
      deletedFiles,
      targetFileGroupName
    )
    const activity = ActivityFactory.CreateDocumentActivity(
      activityValue,
      propertyBag,
      engagementId,
      questionId,
      clientId
    )

    dispatch(saveActivity(activity))
  } catch (error) {
    dispatch(actions.softDeleteFilesStatus.failure(error))
  }
  // Stop the file upload busy indicator
  dispatch(stopBusy({ id: FileUploadBusyIndicator }))
}

export const submitFileToCch = (
  fileId: number
): TsaThunkAction<Promise<ApiModels.File | undefined>> => async dispatch => {
  dispatch(actions.submitFileToCchAction.request())
  try {
    const file = await K1Api.submitFileToCch(fileId)
    dispatch(actions.submitFileToCchAction.success(file))
    return file
  } catch (error) {
    dispatch(actions.submitFileToCchAction.failure(error))
  }
}

export const submitMultipleFilesToCch = (
  fileIds: number[]
): TsaThunkAction<Promise<ApiModels.File[] | undefined>> => async dispatch => {
  dispatch(actions.submitFileToCchAction.request())
  try {
    const files = await K1Api.submitMultipleFilesToCch(fileIds)
    if (files && files.length) {
      files.forEach(file =>
        dispatch(actions.submitFileToCchAction.success(file))
      )
    }
    return files
  } catch (error) {
    dispatch(actions.submitFileToCchAction.failure(error))
  }
}

export const hideUploadErrorDialog = (): TsaThunkAction => dispatch => {
  dispatch(actions.hideUploadErrorDialog())
}

export const deleteFileGroup = (
  engagementId: string | number,
  questionId: string | number,
  fileGroupId: number
): TsaThunkAction => async (dispatch, getState) => {
  engagementId = ensureNumber(engagementId)
  questionId = ensureNumber(questionId)
  const state = getState()
  const operationId = Guid.newGuid()
  const engagement = state.engagements[engagementId]
  const clientId = engagement && engagement.clientId

  dispatch(httpSaveBeginAction({ id: operationId }))

  try {
    await deleteFileGroupAsync(dispatch, engagementId, questionId, fileGroupId)

    let activityValue = 'Deleted '
    let targetFileGroupName: string | undefined = ''
    const fileGroup = state.fileGroups[fileGroupId]
    if (fileGroup) {
      targetFileGroupName = fileGroup.title
      activityValue += targetFileGroupName
    }

    const propertyBag = buildActivityPropertyBag(
      [],
      [],
      [],
      targetFileGroupName
    )
    const activity = ActivityFactory.CreateDocumentActivity(
      activityValue,
      propertyBag,
      engagementId,
      questionId,
      clientId
    )

    dispatch(saveActivity(activity))
    await dispatch(runAllEngagementRules(engagementId))
  } catch (error) {
    dispatch(actions.deleteFileGroup.failure(error))
  }

  dispatch(httpSaveEndAction({ id: operationId }))

  dispatch(fileUploadDone())
}

export const downloadFile = (
  fileId: number
): TsaThunkAction => async dispatch => {
  dispatch(actions.downloadFile.request())
  try {
    const response = await trackGet(
      FilesApi.apiDownloadFile,
      dispatch,
      `downloadFile${fileId}`,
      fileId
    )
    saveBlob(response)
    return dispatch(actions.downloadFile.success())
  } catch (error) {
    return dispatch(actions.downloadFile.failure(error))
  }
}

/**
 * Download a file from the tax form. These are files that come
 * from the PBC Editor.
 */
export const downloadTaxFormFile = (
  taxForm: string,
  taxYear: number,
  fileName: string
): TsaThunkAction => async dispatch => {
  const blob = await TaxFormContentApi.apiDownloadTaxFormFile(
    taxForm,
    taxYear,
    fileName
  )
  saveBlob(blob)
}

export const downloadFiles = (
  fileIds: number[]
): TsaThunkAction => async dispatch => {
  dispatch(actions.downloadFiles.request(fileIds))

  try {
    const response = await trackGet(
      FilesApi.apiDownloadFiles,
      dispatch,
      'downloadFiles',
      fileIds
    )
    saveBlob(response)
    return dispatch(actions.downloadFiles.success(fileIds))
  } catch (error) {
    return dispatch(actions.downloadFiles.failure(error))
  }
}

export const openFileUpload = (
  engagementId?: string | number,
  questionId?: string | number,
  documentTitle?: DocumentTitle,
  history?: History,
  fileGroup?: FileGroup,
  onClose?: (
    dispatch: ThunkDispatch<AppState, undefined, TsaHubAction>,
    getState: () => AppState
  ) => void
): TsaThunkAction => (dispatch, getState) => {
  engagementId = ensureNumber(engagementId)
  questionId = ensureNumber(questionId)
  customFileClose = onClose

  const isTemplate = documentTitle && !!documentTitle.templateFileName
  const maxFileCount = isTemplate ? 1 : undefined
  dispatch(
    actions.openFileUploadAction({
      engagementId,
      questionId,
      documentTitle,
      isTemplate,
      maxFileCount,
      fileGroup
    })
  )

  if (
    documentTitle &&
    (documentTitle.id === DocumentTitleId.K1Document ||
      documentTitle.id === DocumentTitleId.K1UpLoadDocument) &&
    history &&
    engagementId !== 0
  ) {
    const pathname = window.location.pathname

    if (!fileGroup || !fileGroup.files || fileGroup.files.length === 0) {
      dispatch(actions.setFileUploadDialogClose({ open: true }))
    }
    let url: string = ''
    if (documentTitle) {
      url = joinPaths(
        pathname,
        `engagements/${engagementId}/documents/upload/questions/${questionId}/documenttitle/${documentTitle.id}`
      )
    } else if (fileGroup) {
      if (fileGroup.documentTitleId) {
        url = joinPaths(
          pathname,
          `engagements/${engagementId}/documents/upload/questions/${questionId}/documenttitle/${fileGroup.documentTitleId}`
        )
      } else {
        url = joinPaths(
          pathname,
          `engagements/${engagementId}/documents/upload/questions/${questionId}/filegroup/${fileGroup.id}`
        )
      }
    }
    history.push(url)
    clearCustomFileClose()
  } else if (
    documentTitle &&
    documentTitle.id === DocumentTitleId.EtlDocument &&
    history &&
    engagementId !== 0
  ) {
    let url: string = ''
    const pathname = window.location.pathname

    if (documentTitle) {
      url = joinPaths(
        pathname,
        `engagements/${engagementId}/documents/upload/questions/${questionId}/documenttitle/${documentTitle.id}`
      )
    } else if (fileGroup) {
      if (fileGroup.documentTitleId) {
        url = joinPaths(
          pathname,
          `engagements/${engagementId}/documents/upload/questions/${questionId}/documenttitle/${fileGroup.documentTitleId}`
        )
      } else {
        url = joinPaths(
          pathname,
          `engagements/${engagementId}/documents/upload/filegroup/${fileGroup.id}`
        )
      }
    }

    history.push(url)
  } else {
    // If not K-1 document type then open in sidebar

    // HACK: This is a big ugly sledgehammer that allows the file upload to be opened from the doc library.
    // We're just forcing the old component to get removed and render a new one. Fixing this correctly
    // will require a little overhaul to the sidebar management as a whole.
    dispatch(
      selectionSidebarSelectAction({ selectedSidebarName: '', toggle: false })
    )
    setTimeout(() =>
      dispatch(
        selectionSidebarSelectAction({
          selectedSidebarName: 'file',
          toggle: false
        })
      )
    )
  }
}

/**
 * Track the status of a file group that has been marked as "Nothing to upload".
 */
export const toggleFileGroupNotApplicable = (
  engagementId: number | string,
  questionId: number,
  documentTitle: DocumentTitle,
  fileGroupId?: number
): TsaThunkAction => async dispatch => {
  engagementId = ensureNumber(engagementId)
  if (fileGroupId) {
    await dispatch(deleteNAFileGroup(engagementId, questionId, fileGroupId))
  } else {
    await dispatch(createNAFileGroup(engagementId, questionId, documentTitle))
  }
}

/**
 * Delete the empty placeholder file group.
 */
export const deleteNAFileGroup = (
  engagementId: number,
  questionId: number,
  fileGroupId: number
): TsaThunkAction => async (dispatch, getState) => {
  const state = getState()
  const fileGroups = selectQuestionFileGroups(state.fileGroups, questionId)
  const group = fileGroups.find(g => g.id === fileGroupId)
  if (!group) {
    throw new Error(`File group ${fileGroupId} not found.`)
  }

  try {
    // Do an eager update of the Redux store.
    dispatch(
      actions.deleteFileGroup.success({ engagementId, questionId, fileGroupId })
    )
    await FileGroupsApi.apiDeleteFileGroup(group.id)
    await dispatch(runQuestionRules(engagementId, questionId))
  } catch (error) {
    // If the API call to delete failed then undo the eager delete from the store that we did above
    dispatch(
      actions.addUpdateFileGroup.success({
        engagementId,
        questionId,
        group
      })
    )
    dispatch(actions.deleteFileGroup.failure(error))
  }
}

let nextFileGroupId = 0

/**
 * Create an empty file group as a placeholder. This placeholder indicates that the user clicked the "Nothing to upload" checkbox.
 */
export const createNAFileGroup = (
  engagementId: number,
  questionId: number,
  documentTitle: DocumentTitle
): TsaThunkAction => async (dispatch, getState) => {
  const engagement = getState().engagements[engagementId]
  if (!engagement) {
    throw new Error(`Engagement ${engagementId} not found.`)
  }

  const tempId = --nextFileGroupId
  const fileGroup: FileGroup = {
    id: tempId,
    clientId: engagement.clientId,
    documentTitleId: documentTitle.id,
    engagementId,
    notApplicable: true,
    questionId,
    title: documentTitle.title
  }

  try {
    // Do an eager update of the Redux store.
    dispatch(
      actions.addUpdateFileGroup.success({
        engagementId,
        questionId,
        group: fileGroup
      })
    )
    const inserted = await FileGroupsApi.apiSaveFileGroup(fileGroup)
    dispatch(
      actions.addUpdateFileGroup.success({
        engagementId,
        questionId,
        group: inserted,
        replaceId: tempId
      })
    )
    await dispatch(runQuestionRules(engagementId, questionId))
  } catch (error) {
    // If the API call to create the file group failed then undo the eager add to the store.
    dispatch(
      actions.deleteFileGroup.success({
        engagementId,
        questionId,
        fileGroupId: tempId
      })
    )
    dispatch(actions.addUpdateFileGroup.failure(error))
  }
}

export const toggleCarryFowardFileGroup = (
  fileGroup: FileGroup
): TsaThunkAction => dispatch => {
  const activity = ActivityFactory.CreateCarryForwardFileGroupActivity(
    fileGroup,
    !fileGroup.carryForwardActivityId
  )
  dispatch(saveActivity(activity))
}

export const toggleSelectFile = (fileId: number): TsaThunkAction => dispatch =>
  dispatch(actions.toggleSelectFileAction({ fileId }))

export const getFileGroupsForEngagement = (
  engagementId: number
): TsaThunkAction<Promise<FileGroup[]>> => async (dispatch, getState) => {
  try {
    dispatch(actions.getFileGroupsForEngagementActions.request())
    const result = await FileGroupsApi.apiGetFileGroups(engagementId)
    dispatch(
      actions.getFileGroupsForEngagementActions.success({ fileGroups: result })
    )
    return result
  } catch (error) {
    dispatch(actions.getFileGroupsForEngagementActions.failure(error))
  }
  return []
}

export const getFileGroupsForEntityGroup = (
  entityGroupId: number | string
): TsaThunkAction => async dispatch => {
  dispatch(actions.getFileGroupsForEntityGroup.request())

  try {
    const fileGroups = await FileGroupsApi.apiGetEntityGroupFileGroups(
      entityGroupId
    )
    dispatch(
      actions.getFileGroupsForEntityGroup.success({
        fileGroups
      })
    )
  } catch (error) {
    dispatch(actions.getFileGroupsForEntityGroup.failure(error))
  }
}

export const setCurrentVersion = (
  fileId: number
): TsaThunkAction => async dispatch => {
  dispatch(actions.setCurrentVersion.request({ fileId }))

  try {
    const response = await FilesApi.apiSetCurrentVersion(fileId)

    if (response && response.engagementId && response.questionId) {
      dispatch(actions.setCurrentVersion.success({ fileId }))
      return dispatch(
        actions.addUpdateFileGroup.success({
          engagementId: response.engagementId,
          questionId: response.questionId,
          group: response
        })
      )
    }
  } catch (error) {
    return dispatch(actions.setCurrentVersion.failure({ fileId, error }))
  }
}

export const updateFile = (file: File): TsaThunkAction => async dispatch => {
  dispatch(actions.updateFile.request())

  try {
    dispatch(actions.updateFile.success(file))
  } catch (error) {
    dispatch(actions.updateFile.failure(error))
  }
}

export const uploadTrapezeTemplateFile = (
  file: ApiModels.File
): Promise<ApiModels.InputProcessTemplate> => {
  return uploadFile(file, 'InputProcess/UserTemplates/Trapeze/Upload')
}

const uploadFile = (
  file: ApiModels.File,
  url: string
): Promise<ApiModels.InputProcessTemplate> => {
  const data = new FormData()
  for (const key in file) {
    data.append(key, file[key])
  }

  return post(
    buildApiUrl(url),
    data,
    { headers: undefined },
    undefined,
    ensureNumber(REACT_APP_GLOBALFILEDOWNLOADTIMEOUTINSECS) || 90
  )
}

export const uploadEtlTemplateFile = (
  file: ApiModels.File,
  etlType: number
): Promise<ApiModels.InputProcessTemplate> => {
  return uploadFile(file, `Etl/Templates/${etlType}`)
}

import classNames from 'classnames'
import * as React from 'react'

interface GridProps {
  baseClassName?: string
  isFluid?: boolean
}

const Grid: React.SFC<GridProps> = ({ isFluid, baseClassName, children }) => (
  <div
    className={classNames(`container${isFluid ? '-fluid' : ''}`, baseClassName)}
  >
    {children}
  </div>
)

Grid.defaultProps = {
  isFluid: true,
}

export default Grid

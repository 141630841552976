import { NumberFormatProps } from 'react-number-format'
import { FieldFormat } from '../../clientModels'
import { TsaJsonSchema } from '../answerSchema/index'

type NumberFormats = { [key in FieldFormat]?: Partial<NumberFormatProps> }

/**
 * Number formats are subsets of props that are passed to the react-number-format component.
 */
const formats: NumberFormats = {
  ein: {
    format: '##-#######',
    mask: '_',
    thousandSeparator: '',
  },
  einOrSsn: {
    format: '#########',
    mask: '_',
    thousandSeparator: '',
  },
  gen: {
    format: '####',
    mask: '_',
    thousandSeparator: '',
  },
  integer: {
    decimalScale: 0,
    thousandSeparator: ',',
  },
  number: {
    thousandSeparator: ',',
  },
  ssn: {
    format: '###-##-####',
    mask: '_',
    thousandSeparator: '',
  },
}

export function getNumberFormat (
  jsonSchema?: TsaJsonSchema
): Partial<NumberFormatProps> {
  const format = (jsonSchema && jsonSchema.format) || ''
  return (formats as any)[format] || {}
}

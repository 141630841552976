import { IdentityTokenProfile } from '../clientModels'
import { GeneralApi } from '../services/api'
import { TsaThunkAction } from '../store'
import * as authActions from './authenticationActions'
import { generalAction } from './generalActions'

export const updateAssignments = (
  user: IdentityTokenProfile,
  throwError?: boolean
): TsaThunkAction => async dispatch => {
  try {
    // send beginning action
    dispatch(generalAction.request({}))

    // update assignments
    const generalSettings = await GeneralApi.apiUpdateAssignments(user)

    user.permissions = generalSettings.permissions
    user.permissionsEnabled = generalSettings.userPermissionsEnabled
    user.impersonationPermissions = generalSettings.impersonationPermissions

    dispatch(authActions.authGetUserPermissionAction({ user }))

    // send updated action with acceptable types and return
    return dispatch(generalAction.success(generalSettings))
  } catch (error) {
    if (throwError) {
      throw error
    }

    return dispatch(generalAction.failure(error))
  }
}

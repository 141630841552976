import { toPath } from 'lodash'
import { TsaJsonSchema } from './jsonSchema'

/**
 * Get the schema for a particular property path.
 * NOTE: This does not deal with JSON paths that include an array index. e.g.) [0].mypath
 * @param parentSchema The JSON Schema from the parent component
 * @param path A JSON path
 */
export function getSchemaForPath (
  parentSchema?: TsaJsonSchema,
  path?: string
): TsaJsonSchema | undefined {
  if (!path || !parentSchema) {
    return parentSchema
  }

  const segments = toPath(path)
  return segments.reduce(getPropertySchema, parentSchema)
}

function getPropertySchema (parentSchema: TsaJsonSchema, path: string) {
  const properties = parentSchema.properties
  if (!properties) {
    return {}
  }
  return properties[path] || {}
}

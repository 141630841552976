import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { createSelector } from 'reselect'
import { actions } from '../../actions/index'
import {
  Activity,
  EngagementQuestion,
  Question,
  Section,
} from '../../clientModels'
import { getActivitiesByEngagementIdQuestionId } from '../../reducers/selectors'
import { formatDateForDisplay } from '../../services/formatters/formatters'
import { getTrackingId } from '../../services/track'
import { AppState, TsaDispatch } from '../../store'
import { QuestionContainer } from '../question/questionContainer'
import asSummary from '../summary/summary'

const SummaryQuestion = asSummary({ groupName: 'FlagLibrary' })(
  QuestionContainer
)

interface OwnProperties extends RouteComponentProps<{}> {
  engagementId: number
  questionId: number
  openPath?: string
  onOpen: (questionId: number) => void
  onClose: (questionId: number) => void
  onViewContext: (questionId: number, sectionId: number) => void
}

interface MappedProperties {
  question?: Question
  section?: Section
  engagementQuestion?: EngagementQuestion
  flagActivity?: Activity
  commentActivity?: Activity
  userId?: string
  engagementHasLoaded: boolean
}

interface DispatchProperties {
  getActivities: (engagementId: number, questionId: number) => void
}

type Properties = OwnProperties & MappedProperties & DispatchProperties

function getActivities (state: AppState, props: OwnProperties) {
  return getActivitiesByEngagementIdQuestionId(
    state,
    props.engagementId,
    props.questionId
  )
}

const makeFlagActivitySelector = () => {
  return createSelector(
    getActivities,
    activities => {
      if (activities === undefined || activities.length === 0) {
        return undefined
      }
      for (let i = activities.length - 1; i >= 0; --i) {
        const activity = activities[i]
        if (activity.activityTypeCode === 'Flag' && !activity.parentId) {
          return activity
        }
      }
      return undefined
    }
  )
}

const makeChildCommentActivitySelector = () => {
  const flagActivitySelector = makeFlagActivitySelector()
  return createSelector(
    getActivities,
    flagActivitySelector,
    (activities, flagActivity) => {
      if (
        activities === undefined ||
        activities.length === 0 ||
        !flagActivity
      ) {
        return undefined
      }
      for (let i = activities.length - 1; i >= 0; --i) {
        const activity = activities[i]
        if (
          activity.activityTypeCode === 'Comment' &&
          activity.parentId === flagActivity.id
        ) {
          return activity
        }
      }
      return undefined
    }
  )
}

export const makeMapStateToProps = () => {
  const flagActivitySelector = makeFlagActivitySelector()
  const childCommentActivitySelector = makeChildCommentActivitySelector()

  return (state: AppState, props: OwnProperties): MappedProperties => {
    const eqs = state.engagementQuestions[props.engagementId]
    const question = state.questions[props.questionId]
    const section = question && state.sections[question.sectionId]
    const result: MappedProperties = {
      engagementHasLoaded: !!state.engagements[props.engagementId],
      question,
      section,
      flagActivity: flagActivitySelector(state, props),
      commentActivity: childCommentActivitySelector(state, props),
      engagementQuestion: eqs ? eqs[props.questionId] : undefined,
      userId: state.auth.user ? state.auth.user.uniqueId || '' : '',
    }
    return result
  }
}

function mapDispatchToProps (
  dispatch: TsaDispatch,
  props: OwnProperties
): DispatchProperties {
  return {
    getActivities: (engagementId: number, questionId: number) =>
      getTrackingId(
        dispatch,
        actions.activity.getActivities,
        engagementId,
        questionId
      ),
  }
}

class FlagSummary extends React.Component<Properties> {
  constructor (props: Properties) {
    super(props)

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onViewContext = this.onViewContext.bind(this)
    this.formatAuthor = this.formatAuthor.bind(this)
  }

  onOpen () {
    const {
      props: { question, onOpen },
    } = this
    if (!question) {
      return
    }
    onOpen(question.id)
  }

  onClose () {
    const {
      props: { question, onClose },
    } = this
    if (!question) {
      return
    }
    onClose(question.id)
  }

  onViewContext () {
    const {
      props: { question, onViewContext },
    } = this
    if (!question) {
      return
    }
    onViewContext(question.id, question.sectionId)
  }

  formatAuthor (activity: Activity): string {
    const {
      props: { userId },
    } = this
    return activity.userId === userId
      ? 'You'
      : `${activity.createdByInternalUser ? '(RSM) ' : ''}${activity.author}`
  }

  render () {
    const {
      onOpen,
      onClose,
      onViewContext,
      formatAuthor,
      props: {
        openPath,
        engagementQuestion,
        question,
        section,
        engagementId,
        flagActivity,
        commentActivity,
      },
    } = this
    if (!question || !engagementQuestion || !section) {
      return <div />
    }
    let text = ''
    if (flagActivity) {
      text = `Flagged ${formatDateForDisplay(
        flagActivity.createdDate
      )} by ${formatAuthor(flagActivity)}`
    }
    return (
      <div>
       {question.isVisible &&
        <SummaryQuestion
          openPath={openPath}
          label={question.displayNumber}
          secondLabel={section.title}
          hint={text}
          labelDetails={commentActivity && commentActivity.value}
          engagementId={engagementId}
          question={question}
          engagementQuestion={engagementQuestion}
          hasDocuments={false}
          showFileIcons={false}
          requiresAttention={true}
          hasFlags={engagementQuestion.flagged}
          hasComments={engagementQuestion.commentsCount > 0}
          required={false}
          onOpen={onOpen}
          onClose={onClose}
          onViewContext={onViewContext}
          selected={true}
        />
       }
      </div>
    )
  }
}

export default withRouter(
  connect(
    makeMapStateToProps,
    mapDispatchToProps
  )(FlagSummary)
)

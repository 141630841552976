import { Message } from '../clientModels'
import { ensureNumber } from '../guards'
import { selectAnswer } from '../reducers/selectors'
import { TsaJsonSchema } from '../services/answerSchema'
import {
  RulesEventHandlerRegistry,
  RulesEventHandlerThunk,
  RulesEventPayload,
} from '../services/rules/createRulesEventHandler'
import { Fact } from '../services/rules/facts'
import {
  answerPartialChanged,
  setDefaultAnswer,
  updateMetadata,
} from './answerThunks'
import { completeEngagementMilestone } from './engagementMilestoneThunks'
import {
  updateEngagementPhase,
  updateEngagementQuestionStateSummary,
} from './engagementThunks'
import {
  ruleClearMessageAction,
  rulesClearDocsRequiredAction,
  ruleSetDocsRequiredAction,
  ruleSetMessageAction,
  ruleSetQuestionEnabledAction,
  ruleSetQuestionVisiblityAction,
  ruleSetSectionVisiblityAction,
  ruleSetDocsOptionalAction,
  rulesClearDocsOptionalAction,
} from './rulesActions'

function getFullPath(payload: RulesEventPayload) {
  const {
    rowIndex,
    event: { params },
  } = payload
  return rowIndex < 0 ? params.path : `[${rowIndex}].${params.path}`
}

function setDisplayOrder(message: Message, property?: TsaJsonSchema) {
  if (property) {
    message.displayOrder = property.displayOrder
  }
}

function getProperties(schema?: TsaJsonSchema) {
  if (!schema) {
    return
  }
  if (schema.items) {
    if (Array.isArray(schema.items)) {
      // JSON Schema supports having a different schema for each item in an array but we don't use that variation.
      // We expect a grid to use the same schema for all items in the array.
      throw new Error('Unknown items schema type.')
    } else {
      return schema.items.properties
    }
  }
  return schema.properties
}

/**
 * Handle a phase-change rules event.
 */
export const handlePhaseChangeEvent: RulesEventHandlerThunk = payload => async dispatch => {
  const {
    almanac,
    engagementId,
    event: { params },
    rule,
  } = payload
  const engagementSummary = await almanac.factValue(Fact.EngagementSummary)

  // The phase change rule also does the calculation of question state. This gets dispatched
  // every time regardless of whether there is a phase change.
  dispatch(
    updateEngagementQuestionStateSummary(engagementId, engagementSummary)
  )

  if (rule.result) {
    // rule was successful so change the phase
    dispatch(updateEngagementPhase(engagementId, params.phase))
  }
}

/**
 * Handle the section-visibility rules event.
 */
export const handleSectionVisibilityEvent: RulesEventHandlerThunk = ({
  engagementId,
  event: { params },
  rule,
}) => (dispatch, getState) => {
  const state = getState()
  const questions = state.engagementQuestions[engagementId] || {}
  for (const qId in questions) {
    const questionId = ensureNumber(qId)
    const question = questions[questionId]
    if (!question || question.sectionId !== params.sectionId) {
      continue
    }
    if (rule.result) {
      dispatch(
        updateMetadata(
          engagementId,
          questionId,
          'Active',
          question.isVisibleMemory
        )
      )
      dispatch(
        ruleSetQuestionVisiblityAction({
          engagementId,
          questionId,
          isVisible: question.isVisibleMemory,
          updateMemory: false,
        })
      )
    } else {
      dispatch(updateMetadata(engagementId, questionId, 'Active', rule.result))
      dispatch(
        ruleSetQuestionVisiblityAction({
          engagementId,
          questionId,
          isVisible: rule.result,
          updateMemory: false,
        })
      )
    }
  }
  dispatch(
    ruleSetSectionVisiblityAction({
      engagementId,
      sectionId: params.sectionId,
      isVisible: rule.result,
    })
  )
}

/**
 * Handle the question-enabled rules event.
 */
export const handleQuestionEnabledEvent: RulesEventHandlerThunk = ({
  engagementId,
  questionId,
  rule,
}) => (dispatch, getState) => {
  dispatch(
    ruleSetQuestionEnabledAction({
      engagementId,
      questionId,
      isEnabled: rule.result,
    })
  )
}

/**
 * Handle the question-visibility rules event.
 */
export const handleQuestionVisiblityEvent: RulesEventHandlerThunk = ({
  engagementId,
  questionId,
  rule,
}) => (dispatch, getState) => {
  const state = getState()
  const questions = state.engagementQuestions[engagementId] || {}
  const question = questions[questionId]
  let sectionActive = true
  if (question) {
    const sections = state.engagementSections[engagementId] || {}
    const section = sections[question.sectionId]
    if (section) {
      sectionActive = section.isVisible
    }
  }
  dispatch(
    updateMetadata(
      engagementId,
      questionId,
      'Active',
      rule.result && sectionActive
    )
  )
  dispatch(
    ruleSetQuestionVisiblityAction({
      engagementId,
      questionId,
      isVisible: rule.result,
      updateMemory: true,
    })
  )
  if (!sectionActive) {
    dispatch(
      ruleSetQuestionVisiblityAction({
        engagementId,
        questionId,
        isVisible: false,
        updateMemory: false,
      })
    )
  }
}

/**
 * Handle the docs-required rules event.
 */
export const handleDocsRequiredEvent: RulesEventHandlerThunk = ({
  engagementId,
  questionId,
  event: { params },
  rule,
}) => dispatch => {
  if (rule.result) {
    dispatch(
      ruleSetDocsRequiredAction({
        engagementId,
        questionId,
        requiredDocumentTitleIds: params.requiredDocumentTitleIds,
      })
    )
  } else {
    dispatch(
      rulesClearDocsRequiredAction({
        engagementId,
        questionId,
        requiredDocumentTitleIds: params.requiredDocumentTitleIds,
      })
    )
  }
}

/**
 * Handle the docs-optional rules event.
 */
export const handleDocsOptionalEvent: RulesEventHandlerThunk = ({
  engagementId,
  questionId,
  event: { params },
  rule,
}) => dispatch => {
  const documentTitleIds = params.documentTitleIds
  if (rule.result) {
    dispatch(
      ruleSetDocsOptionalAction({ engagementId, questionId, documentTitleIds })
    )
  } else {
    dispatch(
      rulesClearDocsOptionalAction({
        engagementId,
        questionId,
        documentTitleIds,
      })
    )
  }
}

/**
 * Handle a default-value rules event.
 */
export const handleDefaultValueEvent: RulesEventHandlerThunk = payload => dispatch => {
  const path = getFullPath(payload)
  const {
    engagementId,
    questionId,
    event: { params },
    rule,
  } = payload
  if (rule.result) {
    dispatch(setDefaultAnswer(engagementId, questionId, path, params.value))
  }
}

/**
 * Handle a value rules event.
 */
export const handleValueEvent: RulesEventHandlerThunk = payload => dispatch => {
  const path = getFullPath(payload)
  const {
    engagementId,
    questionId,
    event: { params },
    rule,
  } = payload
  if (rule.result) {
    const propertyValues = { [path]: { ...params.value } }
    dispatch(
      answerPartialChanged(engagementId, questionId, propertyValues, true)
    )
  }
}

/**
 * Handle a message rules event.
 */
export const handleMessageEvent: RulesEventHandlerThunk = payload => (
  dispatch,
  getState
) => {
  const {
    engagementId,
    questionId,
    event: { params },
    rowIndex,
    rule,
  } = payload

  // The full path includes an array index for grid questions
  const fullPath = getFullPath(payload)

  const message: Message = {
    message: params.message,
    path: fullPath,
    rowIndex,
    severity: params.severity || 'error',
    type: params.messageType,
  }

  const state = getState()
  const question = state.questions[questionId]
  const answer = selectAnswer(state, engagementId, questionId)
  const notApplicable =
    question && question.allowNotApplicable && answer && answer.notApplicable

  if (rule.result || notApplicable) {
    // Either the rule was successful or the question has been marked as not applicable.
    // In either case we clear the error message.
    dispatch(ruleClearMessageAction({ engagementId, questionId, message }))
    return
  }

  if (!message.path) {
    dispatch(ruleSetMessageAction({ engagementId, questionId, message }))
    return
  }

  const properties = getProperties(question && question.answerSchema)

  // If the message has a property path we need to set the displayOrder using the JSON Schema for the question.
  // This allows the reducer to sort messages so that the "next error" link in the question component
  // progresses through errors in a predictable way.
  if (properties) {
    // Walk the JSON Schema looking for the property definition
    const property = params.path // params.path is the property path without an array index
      .split('.')
      .reduce(
        (schema: TsaJsonSchema, propertyName: string) =>
          schema?.properties?.[propertyName],
        { properties }
      )

    setDisplayOrder(message, property)
  }

  dispatch(ruleSetMessageAction({ engagementId, questionId, message }))
}

export const handleCompleteMilestoneEvent: RulesEventHandlerThunk = payload => dispatch => {
  const {
    engagementId,
    event: { params },
    rule,
  } = payload

  if (rule.result) {
    dispatch(completeEngagementMilestone(engagementId, params.milestone))
  }
}

export const rulesEventHandlers: RulesEventHandlerRegistry = {
  'complete-milestone': handleCompleteMilestoneEvent,
  'default-value': handleDefaultValueEvent,
  'docs-required': handleDocsRequiredEvent,
  'docs-optional': handleDocsOptionalEvent,
  message: handleMessageEvent,
  'phase-change': handlePhaseChangeEvent,
  'question-enabled': handleQuestionEnabledEvent,
  'question-visibility': handleQuestionVisiblityEvent,
  'section-visibility': handleSectionVisibilityEvent,
  value: handleValueEvent,
}

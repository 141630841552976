import classNames from 'classnames'
import * as React from 'react'
import { IconProperties } from './properties'

const CircleEllipsis: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <svg
      viewBox='0 0 31 31'
      height='1em'
      width='1em'
      className={className}
      onClick={onClick}
      focusable='false'
      data-tip={tooltip}
    >
      <circle
        cx='15.5'
        cy='15.5'
        r='14.5'
        className={classNames(className, 'circle')}
      />
      {/* Ellipsis */}
      <circle
        cx='8'
        cy='15.5'
        r='2'
        className={classNames(className, 'ellipsis')}
      />
      <circle
        cx='15.5'
        cy='15.5'
        r='2'
        className={classNames(className, 'ellipsis')}
      />
      <circle
        cx='23'
        cy='15.5'
        r='2'
        className={classNames(className, 'ellipsis')}
      />
    </svg>
  )
}

export default CircleEllipsis

import * as React from 'react'
import { IconProperties } from './properties'

const Loading: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  const classes = 'circular-loader ' + className

  return (
    <span className='loader'>
      <svg
        className={classes}
        viewBox='25 25 50 50'
        focusable='false'
        onClick={onClick}
        data-tip={tooltip}
      >
        <circle
          className='loader-path'
          cx='50'
          cy='50'
          r='10'
          fill='none'
          stroke='#70c542'
          strokeWidth='2'
        />
      </svg>
    </span>
  )
}

export default Loading

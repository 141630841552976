import * as React from 'react'
import { IconProperties } from './properties'

const Documents: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <svg
      viewBox='0 0 27.75 35.125'
      height='1em'
      width='1em'
      className={className}
      onClick={onClick}
      focusable='false'
      data-tip={tooltip}
    >
      <path
        d='M28.6,32.943S28.229,34.3,27.234,34.3c-5.4.027-21.106,0-21.106,0,0,1.125.914,1.019,2.043,1.019h19.4a2.04,2.04,0,0,0,2.043-2.038V11.547c0-1.125.107-2.038-1.021-2.038'
        transform='translate(-1.875 -0.188)'
      />
      <path
        d='M4.426,0.679H20.467l6.767,6.455V31.245a2.04,2.04,0,0,1-2.043,2.038H4.426a2.04,2.04,0,0,1-2.043-2.038V2.717A2.04,2.04,0,0,1,4.426.679Z'
        transform='translate(-1.875 -0.188)'
      />
      <path
        d='M19.745,6.792s0.366,1.354,1.362,1.358c5.4,0.027,4.085,0,4.085,0,0,1.125-.915,1.019-2.043,1.019H20.766a2.04,2.04,0,0,1-2.043-2.038V5.774c0-1.125-.107-2.038,1.021-2.038'
        transform='translate(-1.875 -0.188)'
      />
      <path
        d='M7.828,12.906H21.789a0.679,0.679,0,1,1,0,1.359H7.828A0.679,0.679,0,0,1,7.828,12.906Z'
        transform='translate(-1.875 -0.188)'
      />
      <path
        d='M7.828,16.642H21.789a0.679,0.679,0,1,1,0,1.358H7.828A0.679,0.679,0,0,1,7.828,16.642Z'
        transform='translate(-1.875 -0.188)'
      />
      <path
        d='M7.828,20.377h8.854a0.679,0.679,0,0,1,0,1.358H7.828A0.679,0.679,0,1,1,7.828,20.377Z'
        transform='translate(-1.875 -0.188)'
      />
    </svg>
  )
}

export default Documents

import React from 'react'
import { File } from '../../clientModels'
import Icon from '../icon/icon'

interface FileUploadDialogFileProps {
  file: File
  duplicate?: boolean
  onDelete: (file: File) => void
}

export function FileUploadDialogFile ({
  file,
  duplicate,
  onDelete,
}: FileUploadDialogFileProps) {
  return (
    <div className='file-upload-file' id={`file-upload-${file.name}`}>
      <Icon icon='deleteAlt' onClick={() => onDelete(file)} />
      {duplicate && (
        <span className='file-upload-file-duplicate'>Duplicate</span>
      )}
      <span title={file.name} className='file-upload-file-name'>
        {file.name}
      </span>
    </div>
  )
}

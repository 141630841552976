import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { DueDate } from '../dueDate'
import { ProgressSave } from '../progressSave'
import UnsentComments from './unsentComments'

interface EngagementRouteParams {
  engagementId: string
}

interface EngagementNavItemsProps
  extends RouteComponentProps<EngagementRouteParams> {}

const EngagementNavItems: React.SFC<EngagementNavItemsProps> = ({
  match,
  location,
  history,
}) => (
  <React.Fragment>
    <ProgressSave engagementId={match.params.engagementId} />
    <DueDate engagementId={match.params.engagementId} />
    <UnsentComments
      engagementId={match.params.engagementId}
      match={match}
      location={location}
      history={history}
    />
  </React.Fragment>
)

export default EngagementNavItems

import * as http from '../http'
import { getNewAbort } from '../lastRequests'
import { AsyncOperation } from '../api/apiModels'
import { buildApiUrl } from './apiUrl'

const abort = getNewAbort()

export function apiGetAsyncOperations(): Promise<AsyncOperation[]> {
  return http.get(buildApiUrl('AsyncOperations'), abort.new())
}

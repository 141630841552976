import ReactDataSheet from 'react-datasheet'
import '../../../node_modules/react-datasheet/lib/react-datasheet.css'
import { EntityMap, Message } from '../../clientModels'
import { getTextFormat } from '../../services/formatters'
import { FocusableComponent } from '../forms/formComponentsInterfaces'
import { convertToISODate } from '../forms/formUtilities'

// tslint:disable:no-any only-arrow-functions variable-name

export interface GridElement extends ReactDataSheet.Cell<GridElement, string> {
  messages: EntityMap<EntityMap<Message[]>>
  value: string | null
}

function isEmpty(obj: object) {
  return Object.keys(obj).length === 0
}

function defaultParsePaste(str: string) {
  return str.split(/\r\n|\n|\r/).map((row: string) => {
    return row.split(/\t/)
  })
}

function getPaste(e: any) {
  const win: any = window

  if (e && e.clipboardData && e.clipboardData.getData) {
    return e.clipboardData.getData('text/plain')
  } else if (win && win.clipboardData && win.clipboardData.getData) {
    return win.clipboardData.getData('Text')
  }

  return ''
}

const KeyCodes = {
  Control: 17,
  V: 86,
}

export class ComponentReactDataSheet extends ReactDataSheet<GridElement, string>
  implements FocusableComponent {
  ctrlDown: boolean = false
  pasteElement?: HTMLInputElement

  constructor(props: GridElement) {
    super(props as any)

    const datasheet = this as any
    datasheet.state.start = {
      i: 0,
      j: 0,
    }
    datasheet.state.end = {
      i: 0,
      j: 0,
    }

    this.onKeyDown = this.onKeyDown.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    if (super.UNSAFE_componentWillMount) {
      super.UNSAFE_componentWillMount()
    }

    const win = window as any

    if (win.clipboardData) {
      this.pasteElement = document.createElement('input')
      this.pasteElement.style.position = 'fixed'
      this.pasteElement.style.top = '-10000px'
      this.pasteElement.style.left = '-10000px'
      document.body.appendChild(this.pasteElement)
      document.addEventListener('keydown', this.onKeyDown)
      document.addEventListener('keyup', this.onKeyUp)
    }
  }

  componentWillUnmount() {
    if (super.componentWillUnmount) {
      super.componentWillUnmount()
    }
    if (this.pasteElement) {
      document.body.removeChild(this.pasteElement)
    }
  }

  focus() {
    const datasheet = this as any
    if (datasheet.dgDom) {
      if (datasheet.dgDom.focus) {
        datasheet.dgDom.focus()
      }
      if (datasheet.dgDom.select) {
        datasheet.dgDom.select()
      }
    }
  }

  selectCell(row: number, col: number) {
    const datasheet = this as any
    datasheet.state.start = {
      i: row,
      j: col,
    }
    datasheet.state.end = {
      i: row,
      j: col,
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.keyCode === KeyCodes.Control) {
      this.ctrlDown = true
    }

    if (this.ctrlDown && e.keyCode === KeyCodes.V) {
      const datasheet = this as any
      if (datasheet && datasheet.state && !isEmpty(datasheet.state.editing)) {
        // Paste in edit mode
        if (datasheet.dgDom) {
          setTimeout(() => {
            if (datasheet.dgDom.focus) {
              datasheet.dgDom.focus()
            }
            if (datasheet.dgDom.select) {
              datasheet.dgDom.select()
            }
          }, 10)
        }
      } else {
        // Paste in view mode
        const { pasteElement } = this
        if (pasteElement) {
          pasteElement.focus()
          pasteElement.select()
        }
      }
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode === KeyCodes.Control) {
      this.ctrlDown = false
    }
  }

  handlePaste = (e: any) => {
    const datasheet = this as any
    if (isEmpty(datasheet.state.editing)) {
      const _getState2 = datasheet.getState()
      const start = _getState2.start

      const parse = this.props.parsePaste || defaultParsePaste
      const changes: ReactDataSheet.CellsChangedArgs<GridElement> = []
      const pasteData = parse(getPaste(e))
      e.preventDefault()
      // in order of preference
      const _props3 = this.props
      const data = _props3.data
      const onCellsChanged = _props3.onCellsChanged

      let end: any = {}
      if (onCellsChanged) {
        const additions: ReactDataSheet.CellsChangedArgs<GridElement> = []
        pasteData.forEach(function(row: string[], i: number) {
          row.forEach(function(value: string, j: number) {
            end = { i: start.i + i, j: start.j + j }
            const cell = data[end.i] && data[end.i][end.j]

            const schema =
              datasheet.props.schema && datasheet.props.schema[end.j]

            if (schema) {
              if (
                schema.component === 'select' ||
                schema.component === 'multiselect' ||
                schema.component === 'combobox' ||
                schema.component === 'multicombobox'
              ) {
                value = (value || '').trim()
              }

              if (schema.component === 'date') {
                value = convertToISODate(value)
              }

              // If schema format is number OR component is number but format is null,
              // then the value should be parsed. This way, commas are left out of the
              // Answer value. If schema format is ein then the value should be parsed
              // in order to remove dashes from the Answer value.
              if (schema.format) {
                value =
                  schema.format === 'number' ||
                  schema.format === 'ein' ||
                  schema.format === 'einOrSsn'
                    ? getTextFormat(schema).parse(value)
                    : getTextFormat(schema).format(value)
              } else if (schema.component === 'number') {
                value = getTextFormat(schema).parse(value)
              }
            }

            if (!cell) {
              additions.push({ cell: null, row: end.i, col: end.j, value })
            } else if (!cell.readOnly) {
              changes.push({ cell, row: end.i, col: end.j, value })
            }
          })
        })
        if (additions.length) {
          onCellsChanged(changes, additions)
        } else {
          onCellsChanged(changes)
        }
      }

      datasheet._setState({ end })
    }
  }
}

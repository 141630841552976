import classNames from 'classnames'
import computeScrollIntoView from 'compute-scroll-into-view'
import * as React from 'react'
import ReactDataSheet from 'react-datasheet'
import { GridElement } from './component'

const OFFSET_NUDGE = 10

export interface SheetHeader {
  text: string
  colSpan?: number
  displayWidth?: string
  minWidth?: string
  maxWidth?: string
}

export interface CellRendererProps
  extends ReactDataSheet.CellRendererProps<GridElement> {
  columns: SheetHeader[]
  alignment?: string
  gridContentRef?: HTMLDivElement
}

export function getStyle(header: SheetHeader): React.CSSProperties | undefined {
  let result:
    | undefined
    | { width?: string; minWidth?: string; maxWidth?: string }
  if (header.minWidth) {
    result = {
      maxWidth: header.minWidth,
      minWidth: header.minWidth,
    }
  }
  return result
}

export class PureCellRenderer extends React.PureComponent<CellRendererProps> {
  tdRef: HTMLTableDataCellElement | null = null
  constructor(props: CellRendererProps) {
    super(props)
    this.onRef = this.onRef.bind(this)
  }

  componentDidMount() {
    if (this.props.selected) {
      this.scrollIntoView()
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: CellRendererProps) {
    if (nextProps.selected) {
      this.scrollIntoView()
    }
  }

  scrollIntoView() {
    const width = window.innerWidth
    const td = this.tdRef
    if (!td) {
      return
    }
    const actions = computeScrollIntoView(td, {
      block: 'nearest',
      scrollMode: 'if-needed',
    })
    actions.forEach(({ el, top, left }) => {
      const leftDelta = left - el.scrollLeft
      const leftOffset = Math.sign(leftDelta) * OFFSET_NUDGE + left
      const topDelta = top - el.scrollTop
      const topOffset = Math.sign(topDelta) * OFFSET_NUDGE + top
      el.scrollTop = topOffset
      el.scrollLeft = leftOffset
    })
    if (td.parentNode && td.parentNode.parentElement) {
      const parentWidth = td.parentNode.parentElement.clientWidth
      const rect = td.getBoundingClientRect()
      const scrollParent = this.props.gridContentRef
      if (scrollParent) {
        // values 40 and 400 are hard coded to represent the width of the sidebar.
        if (
          (width - rect.right - 40 < 5 || width - rect.right - 400 < 5) &&
          parentWidth - td.offsetLeft - td.offsetWidth > 10
        ) {
          scrollParent.scrollLeft += OFFSET_NUDGE
        } else if (rect.left < 5) {
          scrollParent.scrollLeft -= OFFSET_NUDGE
        }
      }
    }
  }

  onRef(td: HTMLTableDataCellElement) {
    this.tdRef = td
  }

  render() {
    const {
      onRef,
      props: {
        alignment,
        attributesRenderer,
        cell,
        children,
        className,
        col,
        columns,
        editing,
        gridContentRef,
        row,
        selected,
        style,
        updated,
        ...rest
      },
    } = this
    const colSpan = this.props.cell && this.props.cell.colSpan
    const rowSpan = this.props.cell && this.props.cell.rowSpan
    let invalid: boolean | undefined
    let valid: boolean | undefined
    const rowMessage = cell.messages && cell.messages[row]
    const colMessage = rowMessage && rowMessage[col]
    let styling: React.CSSProperties | undefined
    if (colMessage && colMessage.length > 0) {
      for (let i = 0; !invalid && i < colMessage.length; ++i) {
        const message = colMessage[i]
        switch (message.severity) {
          case 'error':
          case 'critical':
            invalid = true
            valid = false
            break
          case 'success':
          default:
            if (!invalid) {
              valid = true
            }
        }
      }
    }
    styling = getStyle(columns[col])
    if (alignment !== 'left' && !!alignment) {
      if (!styling) {
        styling = {}
      }
      // tslint:disable-next-line:no-any
      styling.textAlign = alignment as any
    }
    return (
      <td
        ref={onRef}
        className={classNames(className, { invalid, valid })}
        {...rest}
        style={styling}
        colSpan={colSpan}
        rowSpan={rowSpan}
      >
        {children}
      </td>
    )
  }
}

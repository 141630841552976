/**
 * Default priorities for rule event types.
 *
 * Note: Higher numbers run first! It seems backwards but
 * lower numbers run later which actually gives them
 * higher priority.
 */
export const rulePriorities: { [eventType: string]: number } = {
  'default-value': 1000,
  value: 900,
  'complete-milestone': 400,
  'docs-optional': 300,
  'docs-required': 300,
  'question-visibility': 200,
  'section-visibility': 100,
  message: 50,
  'phase-change': 10,
}

// tslint:disable:jsx-no-lambda - TODO: refactor to remove jsx lambdas
import classNames from 'classnames'
import * as React from 'react'
import SmoothCollapse from 'react-smooth-collapse'
import { EngagementQuestion, Message } from '../../clientModels'
import displayName from '../../displayName'
import { messageSeverities, optionToObject } from '../forms/formUtilities'
import { CheckBoxes } from '../forms/index'
import Icon from '../icon/icon'
import { Icons } from '../icon/icons'
import { Opener, OpenerInjectedProps, withOpener } from '../opener/opener'
import './summary.scss'
import SummaryIcons from './summaryIcons'

export interface DetailHandlers {
  label: string
  handler: () => void
}

export interface SummaryAction {
  active?: boolean
  icon: keyof Icons
  handler: () => void
  tooltip?: string
}

interface SummaryProps extends OpenerInjectedProps {
  calledFromSummary?: boolean
  detailsHint?: string
  engagementQuestion?: EngagementQuestion
  hasCheckbox?: boolean
  hasComments?: boolean
  hasDocuments?: boolean
  hasError?: boolean
  hasFlags?: boolean
  hasTemplate?: boolean
  hint?: string
  isCarryForward?: boolean
  isChecked?: boolean
  isDisabled?: boolean
  isExternal?: boolean
  isHovered?: boolean
  isSelected?: boolean
  label?: string
  labelDetails?: string | DetailHandlers[]
  labelDetailsClickHandler?: () => void
  messages?: Message[]
  onCarryForwardActivity?: () => void
  onDelete?: (opener: Opener) => void
  onDownloadTemplate?: () => void
  onEdit?: (opener: Opener) => void
  onSelect?: () => void
  onUpload?: (opener: Opener) => void
  onViewContext?: (opener: Opener) => void
  required?: boolean
  requiresAttention?: boolean
  reserveCheckboxSpace?: boolean
  secondLabel?: string
  showFileIcons?: boolean
  summaryActions?: SummaryAction[]
}

interface SummaryConfiguration {
  maxWidth?: number
  openOnClickInside?: boolean
  onlyOneOpen?: boolean
  groupName?: string
  scrollIntoViewOnOpen?: boolean
  closeOnNavigate?: boolean
}

const asSummary = ({
  maxWidth,
  openOnClickInside = false,
  onlyOneOpen = true,
  groupName,
  scrollIntoViewOnOpen,
  closeOnNavigate,
}: SummaryConfiguration = {}) => <TWrappedComponentProps extends object>(
  WrappedComponent?: React.ComponentType<TWrappedComponentProps>
) => {
  type SummaryInternalClassProps = SummaryProps & TWrappedComponentProps

  class SummaryInternal extends React.Component<SummaryInternalClassProps> {
    static displayName = displayName('SummaryHOC', WrappedComponent)

    containingDiv: HTMLDivElement | undefined

    onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      const { labelDetailsClickHandler } = this.props
      if (labelDetailsClickHandler && (e.key === 'Enter' || e.key === ' ')) {
        labelDetailsClickHandler()
      }
    }

    onArrayKeyDown = (index: number, e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== 'Enter' && e.key !== ' ') {
        return
      }
      const ld = (this.props.labelDetails as DetailHandlers[])[
        index
      ] as DetailHandlers
      ld.handler()
    }

    onSummaryClick = () => {
      const {
        opener: { toggle },
      } = this.props

      if (toggle) {
        toggle()
      }
    }

    onDetailsClick = (e: React.FormEvent<HTMLElement>) => {
      const { labelDetailsClickHandler } = this.props
      if (labelDetailsClickHandler) {
        labelDetailsClickHandler()
      }
      e.stopPropagation()
    }

    onArrayDetailsClick = (index: number, e: React.FormEvent<HTMLElement>) => {
      const ld = (this.props.labelDetails as DetailHandlers[])[
        index
      ] as DetailHandlers
      ld.handler()
      e.stopPropagation()
    }

    handleDeleteKeyboard = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== 'Enter' && e.key !== ' ') {
        return
      }
      const { onDelete, opener, isCarryForward } = this.props

      if (onDelete && !isCarryForward) {
        onDelete(opener)
      }
    }

    handleDownloadTemplateKeyboard = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== 'Enter' && e.key !== ' ') {
        return
      }
      const { onDownloadTemplate } = this.props

      if (onDownloadTemplate) {
        onDownloadTemplate()
      }
    }

    handleEditKeyboard = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== 'Enter' && e.key !== ' ') {
        return
      }
      const { onEdit, opener } = this.props

      if (onEdit) {
        onEdit(opener)
      }
    }

    handleUploadKeyboard = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== 'Enter' && e.key !== ' ') {
        return
      }
      const { onUpload, opener } = this.props

      if (onUpload) {
        onUpload(opener)
      }
    }

    handleViewContextKeyboard = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== 'Enter' && e.key !== ' ') {
        return
      }
      const { onViewContext, opener } = this.props

      if (onViewContext) {
        onViewContext(opener)
      }
    }

    handleCarryForwardKeyboard = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== 'Enter' && e.key !== ' ') {
        return
      }
      const { onCarryForwardActivity } = this.props

      if (onCarryForwardActivity) {
        onCarryForwardActivity()
      }
    }

    handleCarryForwardClick = (e: React.FormEvent<HTMLElement>) => {
      const { onCarryForwardActivity } = this.props

      if (onCarryForwardActivity) {
        onCarryForwardActivity()
      }

      e.stopPropagation()
    }

    handleDownloadTemplate = (e: React.MouseEvent<HTMLElement>) => {
      const { onDownloadTemplate } = this.props

      if (onDownloadTemplate) {
        onDownloadTemplate()
      }

      e.stopPropagation()
    }

    handleDelete = (e: React.FormEvent<HTMLElement>) => {
      const { onDelete, opener, isCarryForward } = this.props

      if (onDelete && !isCarryForward) {
        onDelete(opener)
      }

      e.stopPropagation()
    }

    handleEdit = (e: React.FormEvent<HTMLElement>) => {
      const { onEdit, opener } = this.props

      if (onEdit) {
        onEdit(opener)
      }

      e.stopPropagation()
    }

    handleUpload = (e: React.FormEvent<HTMLElement>) => {
      const { onUpload, opener } = this.props

      if (onUpload) {
        onUpload(opener)
      }

      e.stopPropagation()
    }

    handleViewContext = (e: React.FormEvent<HTMLElement>) => {
      const { onViewContext, opener } = this.props

      if (onViewContext) {
        onViewContext(opener)
      }

      e.stopPropagation()
    }

    setDiv = (div: HTMLDivElement) => {
      this.containingDiv = div
    }

    handleChangeEnd = () => {
      const {
        containingDiv,
        props: {
          opener: { isOpen },
        },
      } = this
      if (scrollIntoViewOnOpen && isOpen && containingDiv) {
        containingDiv.scrollIntoView(false)
      }
    }

    handleOnChange = () => {
      const { onSelect } = this.props
      if (onSelect) {
        onSelect()
      }

      return Promise.resolve()
    }

    render() {
      const {
        handleCarryForwardClick,
        handleCarryForwardKeyboard,
        handleChangeEnd,
        handleDelete,
        handleDeleteKeyboard,
        handleDownloadTemplate,
        handleDownloadTemplateKeyboard,
        handleEdit,
        handleEditKeyboard,
        handleUpload,
        handleUploadKeyboard,
        handleViewContext,
        handleViewContextKeyboard,
        onArrayDetailsClick,
        onArrayKeyDown,
        setDiv,
        props: {
          detailsHint,
          engagementQuestion,
          hasCheckbox,
          hasDocuments,
          hasTemplate,
          hint,
          isCarryForward,
          isChecked,
          isDisabled,
          isExternal,
          isHovered,
          isSelected,
          label,
          labelDetails,
          labelDetailsClickHandler,
          messages,
          onDelete,
          onDownloadTemplate,
          onEdit,
          onSelect,
          onUpload,
          onViewContext,
          opener: { isOpen },
          required,
          requiresAttention,
          reserveCheckboxSpace,
          secondLabel,
          showFileIcons,
          summaryActions,
        },
      } = this

      let hasError: boolean | undefined = false

      if (engagementQuestion && engagementQuestion.messages) {
        const severities = messageSeverities(engagementQuestion.messages)

        hasError = severities.error || severities.critical
      } else if (messages) {
        const severities = messageSeverities(messages)

        hasError = severities.error || severities.critical
      } else {
        hasError = this.props.hasError
      }

      const componentProps = Object.assign({}, this.props, {
        calledFromSummary: true,
        hasComments: false,
        hasDocuments: false,
        hasError,
        hasFlags: false,
        hasTemplate: false,
        selected: true,
      })

      const options = [{ value: 'selected', label: '' }]
      const value = isChecked ? optionToObject(options[0]) : null

      return (
        <div>
          <div className='select-container'>
            {hasCheckbox && onSelect && (
              <CheckBoxes
                className='summary-main-select'
                onChange={this.handleOnChange}
                options={options}
                value={value}
              />
            )}
          </div>
          <div
            className={classNames('summary-wrapper', {
              'is-open': isOpen,
              'has-checkboxes': reserveCheckboxSpace,
            })}
            ref={setDiv}
          >
            <div
              className={classNames('summary', {
                'is-disabled': isDisabled,
                'is-hovered': isHovered,
                'is-open': isOpen,
                'is-selected': isSelected,
                'requires-attention': requiresAttention,
              })}
            >
              <div className='summary-label' onClick={this.onSummaryClick}>
                <div className='summary-main'>
                  <div className={classNames('open-close-container')}>
                    {isOpen ? (
                      <Icon icon='isOpened' className='open-icon' />
                    ) : (
                      <Icon icon='isClosed' className='close-icon' />
                    )}
                  </div>
                  <div className='summary-icon-container'>
                    <SummaryIcons
                      required={required}
                      requiresAttenion={requiresAttention}
                    />
                  </div>
                  <div className='summary-center-wrapper'>
                    <div className='summary-label-wrapper'>
                      {label && (
                        <div className='summary-label-content'>
                          {label}
                          {required && (
                            <span className='summary-label-content-required'>
                              {' '}
                              (req)
                            </span>
                          )}
                        </div>
                      )}
                      {secondLabel && secondLabel !== '' && (
                        <div className='summary-label-divider'>|</div>
                      )}
                      {secondLabel && secondLabel !== '' && (
                        <div className='summary-label-secondary-label'>
                          {secondLabel}
                        </div>
                      )}
                      {hint && hint !== '' && (
                        <div className='summary-label-divider second'>|</div>
                      )}
                      {hint && hint !== '' && (
                        <div className='summary-label-hint'>{hint}</div>
                      )}
                    </div>
                    {labelDetails && typeof labelDetails === 'string' && (
                      <div
                        className={classNames('summary-detail-wrapper', {
                          click: labelDetailsClickHandler != null,
                        })}
                      >
                        <span
                          onClick={
                            labelDetailsClickHandler
                              ? this.onDetailsClick
                              : undefined
                          }
                          onKeyDown={
                            labelDetailsClickHandler
                              ? this.onKeyDown
                              : undefined
                          }
                          tabIndex={
                            labelDetailsClickHandler != null ? 0 : undefined
                          }
                        >
                          {labelDetails}
                        </span>
                      </div>
                    )}
                    {labelDetails && Array.isArray(labelDetails) && (
                      <div className={classNames('summary-detail-wrapper', {})}>
                        {labelDetails.map((ld, i) => (
                          <div
                            key={i}
                            className='click'
                            tabIndex={0}
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLDivElement>
                            ) => {
                              onArrayKeyDown(i, e)
                            }}
                            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                              onArrayDetailsClick(i, e)
                            }}
                          >
                            {ld.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className='summary-action-icons'>
                  {!isOpen && showFileIcons && hasDocuments && !isExternal && (
                    <span
                      className={classNames('icon-container', {
                        click: !!onDownloadTemplate,
                      })}
                      tabIndex={onDownloadTemplate ? 0 : undefined}
                      onKeyDown={
                        onDownloadTemplate
                          ? handleCarryForwardKeyboard
                          : undefined
                      }
                      onClick={
                        onDownloadTemplate ? handleCarryForwardClick : undefined
                      }
                    >
                      <Icon
                        icon='carryForward'
                        active={isCarryForward}
                        className={classNames('summary-icon')}
                        tooltip={
                          isCarryForward
                            ? 'Item(s) marked as Carry Forward'
                            : 'Mark item(s) as Carry Forward'
                        }
                      />
                    </span>
                  )}
                  {!isOpen && showFileIcons && hasTemplate && (
                    <span
                      className={classNames('icon-container', {
                        click: !!onDownloadTemplate,
                      })}
                      tabIndex={onDownloadTemplate ? 0 : undefined}
                      onKeyDown={
                        onDownloadTemplate
                          ? handleDownloadTemplateKeyboard
                          : undefined
                      }
                      onClick={
                        onDownloadTemplate ? handleDownloadTemplate : undefined
                      }
                    >
                      <Icon
                        className={classNames('summary-icon')}
                        icon='download'
                        tooltip='Download template and fill out your information'
                      />
                    </span>
                  )}
                  {!isOpen && showFileIcons && hasDocuments && (
                    <span
                      className={classNames('icon-container', {
                        click: !!onDelete,
                      })}
                      tabIndex={onDelete && !isCarryForward ? 0 : undefined}
                      onKeyDown={
                        onDelete && !isCarryForward
                          ? handleDeleteKeyboard
                          : undefined
                      }
                      onClick={
                        onDelete && !isCarryForward ? handleDelete : undefined
                      }
                    >
                      <Icon
                        icon='delete'
                        active={!isCarryForward || isExternal}
                        className={classNames('summary-icon')}
                        tooltip={
                          isCarryForward && !isExternal
                            ? 'You cannot delete a document that has been carried forward'
                            : isCarryForward
                            ? 'Remove document(s) disabled'
                            : 'Remove document(s)'
                        }
                      />
                    </span>
                  )}
                  {!isOpen && showFileIcons && !hasDocuments && (
                    <span
                      className={classNames('icon-container', {
                        click: !!onUpload,
                      })}
                      tabIndex={onUpload ? 0 : undefined}
                      onKeyDown={onUpload ? handleUploadKeyboard : undefined}
                      onClick={onUpload ? handleUpload : undefined}
                    >
                      <Icon
                        icon='upload'
                        className='summary-icon'
                        tooltip='Upload document(s)'
                      />
                    </span>
                  )}
                  {!isOpen && showFileIcons && hasDocuments && (
                    <span
                      className={classNames('icon-container', {
                        click: !!onEdit,
                      })}
                      tabIndex={onUpload && !isCarryForward ? 0 : undefined}
                      onKeyDown={
                        onEdit && !isCarryForward
                          ? handleEditKeyboard
                          : undefined
                      }
                      onClick={
                        onEdit && !isCarryForward ? handleEdit : undefined
                      }
                    >
                      <Icon
                        icon='edit'
                        active={!isCarryForward || isExternal}
                        className='summary-icon'
                        tooltip={
                          isCarryForward && !isExternal
                            ? 'You cannot modify a document that has been carried forward'
                            : isCarryForward
                            ? 'Manage document(s) disabled'
                            : 'Manage document(s)'
                        }
                      />
                    </span>
                  )}
                  {summaryActions &&
                    summaryActions.map((action, index) => (
                      <div
                        key={`summary-context-${action.icon}-${index}-tooltip`}
                      >
                        <Icon
                          icon={action.icon}
                          active={action.active ?? true}
                          onClick={action.handler}
                          tooltip={action.tooltip}
                        />
                      </div>
                    ))}
                  <span
                    className={classNames('icon-container', {
                      click: !!onViewContext,
                    })}
                    tabIndex={onViewContext ? 0 : undefined}
                    onKeyDown={
                      onViewContext ? handleViewContextKeyboard : undefined
                    }
                    onClick={onViewContext ? handleViewContext : undefined}
                  >
                    <Icon
                      icon='openContext'
                      className='summary-icon'
                      tooltip='Go to this question'
                    />
                  </span>
                </div>
              </div>
              {(WrappedComponent || detailsHint) && (
                <SmoothCollapse
                  expanded={isOpen}
                  onChangeEnd={handleChangeEnd}
                  heightTransition='0.5s ease'
                >
                  <div className='summary-details'>
                    <div className='summary-details-border' />
                    <div className='summary-details-content-container'>
                      {detailsHint && (
                        <div className='summary-details-hint'>
                          {detailsHint}
                        </div>
                      )}
                      {WrappedComponent && (
                        <div
                          className='summary-details-content'
                          style={{ maxWidth }}
                        >
                          <WrappedComponent {...componentProps} />
                        </div>
                      )}
                    </div>
                  </div>
                </SmoothCollapse>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  return withOpener({
    openOnClickInside,
    onlyOneOpen,
    groupName,
    closeOnNavigate,
  })<SummaryInternalClassProps>(SummaryInternal)
}

// tslint:disable-next-line:no-any https://github.com/Microsoft/TypeScript/issues/28748
export default asSummary as any

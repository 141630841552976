import * as React from 'react'
import './circularProgressIndicator.scss'

export interface CircularProgressIndicatorProps {
  percentage: number
  size?: number
}

function polarToCartesian (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

// https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function describeArc (
  cx: number,
  cy: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  const start = polarToCartesian(cx, cy, radius, endAngle)
  const end = polarToCartesian(cx, cy, radius, startAngle)
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  const d = [
    'M',
    cx,
    cy,
    'L',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
    'z',
  ].join(' ')

  return d
}

const CircularProgressIndicator: React.SFC<CircularProgressIndicatorProps> = ({
  percentage,
  size,
}) => {
  size = size || 48

  const thickness = size / 6
  const strokeWidth = thickness / 5
  const half = size / 2
  const fontSize = size / 4
  const endAngle = (360 * percentage) / 100
  const done = percentage > 99.9

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (size - thickness) / 2

  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${size} ${size}`

  return (
    <svg
      width={size + thickness}
      height={size}
      viewBox={viewBox}
      className='circular-progress'
    >
      <circle
        className='circle-background'
        cx={half}
        cy={half}
        r={radius}
        strokeWidth={strokeWidth}
      />
      {!done && percentage > 0 && (
        <path
          className='arc-outer'
          d={describeArc(half, half, half, 0, endAngle)}
        />
      )}
      {done && <circle className='arc-outer' cx={half} cy={half} r={half} />}
      <circle
        className='circle-inner'
        cx={half}
        cy={half}
        r={half - thickness}
      />
      {fontSize >= 12 && !isNaN(percentage) && (
        <text
          className='circle-text'
          x='50%'
          y='50%'
          dy='.3em'
          dx='.1em'
          textAnchor='middle'
          style={{ fontSize }}
        >
          {`${percentage}%`}
        </text>
      )}
    </svg>
  )
}

export default CircularProgressIndicator

import React, { PropsWithChildren } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { joinPaths } from './joinPaths'

interface RelativeLinkProps extends RouteComponentProps {
  to: string
  replace?: boolean
}

const RelativeLink = ({
  to,
  replace,
  match: { url },
  children,
}: PropsWithChildren<RelativeLinkProps>) => {
  return (
    <Link to={joinPaths(url, to)} replace={replace}>
      {children}
    </Link>
  )
}

export default withRouter(RelativeLink)

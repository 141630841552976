import { EntityGroup, EntityMap } from '../clientModels'
import { ActionType, createAsyncAction } from 'typesafe-actions'

export const entityAction = createAsyncAction(
  'GET_PARENT_ENTITY_BEGIN',
  'GET_PARENT_ENTITY_END',
  'GET_PARENT_ENTITY_ERROR'
)<void, EntityGroup, Error>()

export const allEntitiesAction = createAsyncAction(
  'GET_ALL_ENTITIES_BEGIN',
  'GET_ALL_ENTITIES_END',
  'GET_ALL_ENTITIES_ERROR'
)<void, EntityMap<EntityGroup>, Error>()

export type EntityActions =
  | ActionType<typeof entityAction.request>
  | ActionType<typeof entityAction.success>
  | ActionType<typeof entityAction.failure>
  | ActionType<typeof allEntitiesAction.request>
  | ActionType<typeof allEntitiesAction.success>
  | ActionType<typeof allEntitiesAction.failure>

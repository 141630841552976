import * as React from 'react'
import './entityEngagementsList.scss'

function EntityEngagementsListHeader () {
  return (
    <div className='engagements-list-header flex-wrap'>
      <div className='title'>My Engagements By Entity</div>
    </div>
  )
}

export default EntityEngagementsListHeader

import * as React from 'react'
import { TsaJsonSchema } from '../../services/answerSchema/index'
import { getTextFormat } from '../../services/formatters/index'
import { ViewerProps } from './viewers'

/**
 * TextViewer is used to view text and number fields. We use
 * this for viewing numbers because there is no react-number-format
 * component being rendered that can do the formatting
 * of the number for us.
 */
export function TextViewer (propertySchema: TsaJsonSchema) {
  const formatter = getTextFormat(propertySchema)
  return class extends React.PureComponent<ViewerProps> {
    render () {
      const { value } = this.props
      const formatted = value
        ? formatter.format(value.toString(), { maskOutput: true })
        : value
      return <span>{formatted}</span>
    }
  }
}

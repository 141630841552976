import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFileUploadDialogOpen } from '../../actions/fileActions'
import { DocumentTitle, Engagement, FileGroup } from '../../clientModels'
import { AppState } from '../../store'
import { ConnectedFileUploadDialog } from '../fileUploadDialog/fileUploadDialog'
import Icon from '../icon/icon'
import './dataFileManagement.scss'
import { ensureNumber } from '../../guards'
import { engagementLockedPhase } from '../../services/engagementPhaseService'
import { actions } from '../../actions'
import { getEntityMapValues, unique } from '../configureImport/helpers'
import { getDisplayStatusMap } from './dataFileHelpers'
import { FilterSortDropdown } from '../filterSortDropdown'
import { DataFileManagementOwnProps } from './dataFileManagement'
import { useConfigureImportParams } from '../configureImport/useConfigureImportParams'
import { selectFileGroup } from './dataFileManagementHelpers'
import { useEngagement } from '../../hooks/useEngagement'

interface DataFileManagementHeaderMappedProps {
  engagement?: Engagement
  engagementId?: number
  questionId?: number
  documentTitle?: DocumentTitle
  fileGroup?: FileGroup
  currentlyUploading: boolean
  statusFilter?: string
  statusFilterList?: any[]
}

const mapStateToProps = (
  state: AppState,
  props: DataFileManagementOwnProps
): DataFileManagementHeaderMappedProps => {
  const fileGroup = selectFileGroup(state, props) || state.fileUpload.fileGroup || state.fileGroups[props.fileGroupId || -1]
  const engagementId = ensureNumber(props.engagementId)
  const questionId = ensureNumber(props.questionId)
  const documentTitleId = props.documentTitleId
    ? ensureNumber(props.documentTitleId)
    : fileGroup
      ? fileGroup.documentTitleId
      : undefined
  const documentTitles =
    documentTitleId !== undefined
      ? state.documentTitles.filter(dt => dt.id === documentTitleId)
      : undefined
  const documentTitle =
    documentTitles !== undefined && documentTitles.length > 0
      ? documentTitles[0]
      : undefined
  const fileStatus = state.fileUpload.uploadStatus
  const etlFiles = getEntityMapValues(state.etlFiles)
  const isExternal = state.auth.user?.isExternal ?? false
  const displayStatusMap = getDisplayStatusMap(
    etlFiles,
    fileGroup?.files || [],
    isExternal
  )
  let currentlyUploading = false
  const statusFilter = state.selection.selectedStatusFilter
  const statusFilterList: string[] = unique(
    getEntityMapValues(displayStatusMap)
  ).sort()

  for (const fileName in fileStatus) {
    const status = fileStatus[fileName]
    if (!status) {
      continue
    }
    if (status !== 'Uploaded' && status !== 'Ready to Map') {
      currentlyUploading = true
    }
    if (status !== 'Uploading') {
      currentlyUploading = false
    }
  }
  return {
    engagementId,
    questionId,
    documentTitle,
    fileGroup,
    currentlyUploading,
    statusFilter,
    statusFilterList,
  }
}

export default function DataFileManagementHeader() {
  const dispatch = useDispatch()
  const state = useSelector((state: AppState) => state)
  const engagement = useEngagement()?.engagement
  const params = useConfigureImportParams()
  const stateProps = mapStateToProps(state, {
    ...params,
  })
  const {
    engagementId,
    questionId,
    documentTitle,
    fileGroup,
  } = stateProps

  const selectFilter = (filter: string) => {
    dispatch(actions.selection.selectionSelectStatusFilterAction({ filter }))
  }

  const openUploadDialog = () => {
    dispatch(
      setFileUploadDialogOpen({
        open: true,
        engagementId: engagementId || 0,
        questionId: questionId || 0,
        documentTitle,
        fileGroup,
      })
    )
  }

  const render = () => {
    const {
      currentlyUploading,
      statusFilter,
      statusFilterList,
    } = stateProps

    // declare a new array... save the values from redux into the label value format for use with status dropdown
    const statusList: { value: string; label: string }[] = []
    const obj = { value: 'None', label: 'None' }
    statusList.push(obj)
    statusFilterList &&
      statusFilterList.forEach(item => {
        const obj = { value: item, label: item }
        statusList.push(obj)
      })

    const engagementLocked = engagement
      ? engagement.isEnabled !== undefined
        ? engagement.isEnabled
        : engagementLockedPhase(engagement)
      : true

    return (
      <div className='file-upload-advanced-header'>
        <div className='file-upload-advanced-header-text'>
          <div className='file-upload-advanced-header-title'>
            Data File Management
          </div>
        </div>
        <AddDocumentsComponent
          onClick={openUploadDialog}
          disabled={currentlyUploading || engagementLocked}
          engagementId={params.engagementId}
        />
        <div className='file-upload-advanced-header-status'>
          <p>Filter by: Status</p>
          <div className='file-upload-advanced-header-status-dropdown'>
            <FilterSortDropdown
              options={statusList}
              value={statusFilter}
              onSelect={selectFilter}
            />
          </div>
        </div>
        <ConnectedFileUploadDialog
          engagementId={engagementId || -1}
          questionId={questionId || -1}
        />
      </div>
    )
  }

  return render()
}

interface AddDocumentsComponentProps {
  disabled?: boolean
  onClick: () => void
  engagementId?: number | 'all'
}

function AddDocumentsComponent({
  disabled,
  onClick,
  engagementId,
}: AddDocumentsComponentProps) {
  if (engagementId === 'all') {
    return <div />
  }
  return (
    <div className='file-upload-advanced-header-buttons'>
      <button
        className='file-upload-advanced-buttons-add-documents btn'
        onClick={onClick}
        disabled={disabled}
      >
        <Icon icon='plus' /> Add Documents
      </button>
    </div>
  )
}

import { CssBaseline, IE11Banner } from '@rsmus/react-ui'
import * as React from 'react'
import { Route, Switch } from 'react-router'
import ReactTooltip from 'react-tooltip'
import { AppError } from './components/appError'
import { BottomDrawer } from './components/bottomDrawer'
import DomRemover from './components/domRemover'
import { EngagementBottomDrawer } from './components/engagementBottomDrawer'
import { EngagementSidebar } from './components/engagementSidebar'
import { EngagementsLeftNav } from './components/engagementsLeftNav'
import { EngagementsListHeader } from './components/engagementsListHeader'
import { MasterClientNavHeader } from './components/masterClientNavHeader'
import ErrorBoundary from './components/errorBoundary/errorBoundary2'
import { LeftNav } from './components/leftNav'
import { Navbar } from './components/navbar'
import PageView from './components/pageView'
import { CchTestScene } from './scenes/cchtest'
import { ClientAssignmentsScene } from './scenes/clientassignments/clientassignments'
import EngagementsListDashboard from './scenes/dashboard'
import { DebugHeader, DebugScene } from './scenes/debug'
import { Engagements, EngagementsHeader } from './scenes/engagements'
import { Help, HelpHeader } from './scenes/help'
import { Setup, SetupHeader } from './scenes/setup'
import { UpdateTemplateScene } from './scenes/updateTemplate/updateTemplate'
import { LargeUp, MediumDown } from './services/MediaQueries'
import { acquireToken } from './oidc-auth/oidcHelper'
import { Eula } from '@rsmus/react-pingidentity/'
import { REACT_APP_IDMAUTHENTICATIONEULASERVICEURL } from './envVariables'
import { Footer } from './components/footer'
import TermsOfUse from './components/termsOfUse/termsOfUse'

const SIDEBAR_PATHS = [
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId/carryForward/questions/:questionId',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId/carryForward/questions/:questionId/comments/:activityId',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId/comments/questions/:questionId',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId/comments/questions/:questionId/comments/:activityId',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId/documents/questions/:questionId',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId/flags/questions/:questionId',
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId/sections/:sectionId/questions/:questionId/:edit?',
  '/engagements/:engagementId/carryForward/questions/:questionId',
  '/engagements/:engagementId/carryForward/questions/:questionId/comments/:activityId',
  '/engagements/:engagementId/comments/questions/:questionId',
  '/engagements/:engagementId/comments/questions/:questionId/comments/:activityId',
  '/engagements/:engagementId/documents/questions/:questionId',
  '/engagements/:engagementId/flags/questions/:questionId',
  '/engagements/:engagementId/sections/:sectionId/questions/:questionId/:edit?',
]

const EntityNavHeaderRender = (props: any) => (
  <MasterClientNavHeader className='entity-nav-header' {...props} />
)

const App = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <DomRemover elementId='loading-overlay' />
      <PageView />
      <Eula
        host={REACT_APP_IDMAUTHENTICATIONEULASERVICEURL}
        tokenFactory={acquireToken}
      >
        <Navbar />
        <Switch>
          {/* do not render EntityNavHeaderRender header here when rendering the terms of use.*/}
          <Route path='/termsofuse/:Id' render={() => null} />
          <Route path='/clientresults' />
          <Route render={EntityNavHeaderRender} />
        </Switch>
        <div className='main-bar'>
          <Switch>
            <Route
              path={[
                '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId/sections/:sectionId/questions/:questionId/edit',
                '/engagements/:engagementId/sections/:sectionId/questions/:questionId/edit',
              ]}
            />
            <Route
              path={[
                '/engagements/:engagementId',
                '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId',
                '/masterclient/:masterClientId/:formName/:taxYear',
              ]}
              component={EngagementsLeftNav}
            />
            <Route component={LeftNav} />
          </Switch>
          <div className='page-content'>
            <IE11Banner />
            <div className='page-content-header'>
              <ErrorBoundary>
                <Switch>
                  <Route path='/updatetemplate' />
                  <Route
                    path={[
                      '/masterclient/:masterClientId/:formName/:taxYear/engagements',
                      '/engagements',
                    ]}
                    component={EngagementsHeader}
                  />
                  <Route path='/setup' component={SetupHeader} />
                  <Route path='/help' component={HelpHeader} />
                  <Route path={['/cch', '/cchtest']} render={() => null} />
                  {window.DEBUG && (
                    <Route path='/debug' component={DebugHeader} />
                  )}
                  <Route
                    path={[
                      '/clientresults/:entityGroupId',
                      '/masterclient/:masterClientId/:formName/:taxYear',
                    ]}
                    component={EngagementsListHeader}
                  />
                  {/* do not render EngagementsListHeader header here when rendering the terms of use.*/}
                  <Route path='/termsofuse/:Id' render={() => null} />
                  {/* always shwos up in the page-content-header */}
                  <Route component={EngagementsListHeader} />
                </Switch>
              </ErrorBoundary>
            </div>
            <div className='page-content-body'>
              <ErrorBoundary>
                <Switch>
                  <Route
                    path={[
                      '/masterclient/:masterClientId/:formName/:taxYear/engagements',
                      '/engagements',
                    ]}
                    component={Engagements}
                  />
                  <Route path='/setup' component={Setup} />
                  <Route path='/help' component={Help} />
                  <Route path={['/cch', '/cchtest']} component={CchTestScene} />
                  <Route
                    path='/clientassignments'
                    component={ClientAssignmentsScene}
                  />
                  <Route
                    path='/updatetemplate'
                    component={UpdateTemplateScene}
                  />
                  {window.DEBUG && (
                    <Route path='/debug' component={DebugScene} />
                  )}
                  <Route
                    path={[
                      '/clientresults/:entityGroupId',
                      '/masterclient/:masterClientId/:formName/:taxYear',
                    ]}
                    component={EngagementsListDashboard}
                  />
                  <Route path='/termsofuse/:Id' component={TermsOfUse} />
                  {/* default empty route. */}

                  <Route component={EngagementsListDashboard} />
                </Switch>
              </ErrorBoundary>
            </div>
            <Footer />
          </div>
          <LargeUp>
            <Switch>
              <Route path={SIDEBAR_PATHS} component={EngagementSidebar} />
            </Switch>
          </LargeUp>
        </div>
        <MediumDown>
          <Switch>
            <Route path={SIDEBAR_PATHS} component={EngagementBottomDrawer} />
            <BottomDrawer />
          </Switch>
        </MediumDown>
        <AppError />
        <ReactTooltip
          className='tooltip'
          delayShow={500}
          effect='solid'
          place='top'
        />
      </Eula>
    </React.Fragment>
  )
}

export default App

import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { DocumentTitle, File, FileGroup } from '../clientModels'
import { ApiModels } from '../services/api'
import { EngagementUploadHistory } from '../services/api/apiModels'

export const getFileGroupsForEngagementActions = createAsyncAction(
  'GET_FILE_GROUPS_FOR_ENGAGEMENT_BEGIN',
  'GET_FILE_GROUPS_FOR_ENGAGEMENT_DONE',
  'GET_FILE_GROUPS_FOR_ENGAGEMENT_FAIL'
)<
  void,
  {
    fileGroups: FileGroup[]
  },
  Error
>()

export const getFileGroupsForEntityGroup = createAsyncAction(
  'GET_ENTITY_GROUP_FILE_GROUPS_BEGIN',
  'GET_ENTITY_GROUP_FILE_GROUPS_DONE',
  'GET_ENTITY_GROUP_FILE_GROUPS_FAIL'
)<
  void,
  {
    fileGroups: FileGroup[]
  },
  Error
>()

export const addUpdateFileGroup = createAsyncAction(
  'ADD_UPDATE_FILE_GROUP_BEGIN',
  'ADD_UPDATE_FILE_GROUP_DONE',
  'ADD_UPDATE_FILE_GROUP_FAIL'
)<
  void,
  {
    engagementId: number
    questionId: number
    group: FileGroup
    replaceId?: number
  },
  Error
>()

export const updateFile = createAsyncAction(
  'UPDATE_FILE_BEGIN',
  'UPDATE_FILE_DONE',
  'UPDATE_FILE_FAIL'
)<void, File, Error>()

export const hideUploadErrorDialog = createAction(
  'UPDATE_FILE_HIDE_UPLOAD_ERROR_DIALOG'
)()

export const addUpdateFileGroupTemplateFailure = createAction(
  'ADD_UPDATE_FILE_GROUP_TEMPLATE_FAIL'
)<Error>()

export const uploadEngagementSetup = createAsyncAction(
  'UPLOAD_ENGAGEMENT_SETUP_BEGIN',
  'UPLOAD_ENGAGEMENT_SETUP_DONE',
  'UPLOAD_ENGAGEMENT_SETUP_FAIL'
)<void, EngagementUploadHistory[], Error>()

export const deleteFileGroup = createAsyncAction(
  'DELETE_FILE_GROUP_BEGIN',
  'DELETE_FILE_GROUP_DONE',
  'DELETE_FILE_GROUP_FAIL'
)<
  void,
  {
    engagementId: number
    questionId: number
    fileGroupId: number
  },
  Error
>()

export const downloadFile = createAsyncAction(
  'DOWNLOAD_FILE_BEGIN',
  'DOWNLOAD_FILE_DONE',
  'DOWNLOAD_FILE_FAIL'
)<void, void, Error>()

export const downloadFiles = createAsyncAction(
  'DOWNLOAD_FILES_BEGIN',
  'DOWNLOAD_FILES_DONE',
  'DOWNLOAD_FILES_FAIL'
)<number[], number[], Error>()

export const openFileUploadAction = createAction('OPEN_FILE_UPLOAD')<{
  documentTitle?: DocumentTitle
  engagementId: number
  fileGroup?: FileGroup
  isTemplate?: boolean
  maxFileCount?: number
  questionId: number
}>()

export const setFileUploadFileGroup = createAction('SET_FILE_UPLOAD_GROUP')<{
  fileGroup: FileGroup
}>()

export const setFileUploadDialogOpen = createAction(
  'SET_FILE_UPLOAD_DIALOG_OPEN'
)<{
  documentTitle?: DocumentTitle
  engagementId: number
  fileGroup?: FileGroup
  isTemplate?: boolean
  maxFileCount?: number
  questionId: number
  open: boolean
  replace?: number
}>()

export const setFileUploadDialogClose = createAction(
  'SET_FILE_UPLOAD_DIALOG_CLOSE'
)<{
  open: boolean
}>()

export const setFileUploadStatus = createAction('SET_FILE_UPLOAD_STATUS')<{
  updates: {
    status?: string
    name: string
  }
}>()

export const clearFileUploadStatus = createAction('CLEAR_FILE_UPLOAD_STATUS')<
  void
>()

export const toggleSelectFileAction = createAction('TOGGLE_SELECT_FILE')<{
  fileId: number
}>()

export const toggleTag = createAsyncAction(
  'TOGGLE_TAG_BEGIN',
  'TOGGLE_TAG_DONE',
  'TOGGLE_TAG_FAIL'
)<
  {
    tag: string
    engagementId: number
    fileGroupId: number
    questionId: number
    fileIds: number[]
  },
  {
    tag: string
    engagementId: number
    fileGroupId: number
    questionId: number
    files: ApiModels.File[]
  },
  Error
>()

export const softDeleteFilesStatus = createAsyncAction(
  'SOFT_DELETE_FILES_STATUS_BEGIN',
  'SOFT_DELETE_FILES_STATUS_DONE',
  'SOFT_DELETE_FILES_STATUS_FAIL'
)<
  {
    engagementId: number
    fileGroupId: number
    questionId: number
    fileIds: number[]
  },
  {
    engagementId: number
    fileGroupId: number
    questionId: number
    fileIds: number[]
  },
  Error
>()

export const submitFileToCchAction = createAsyncAction(
  'SUBMIT_FILE_TO_CCH_BEGIN',
  'SUBMIT_FILE_TO_CCH_DONE',
  'SUBMIT_FILE_TO_CCH_FAIL'
)<void, ApiModels.File, Error>()

export const setCurrentVersion = createAsyncAction(
  'SET_CURRENT_VERSION_BEGIN',
  'SET_CURRENT_VERSION_DONE',
  'SET_CURRENT_VERSION_FAIL'
)<
  {
    fileId: number
  },
  {
    fileId: number
  },
  {
    fileId: number
    error: Error
  }
>()

export type FileActions =
  | ActionType<typeof addUpdateFileGroup.failure>
  | ActionType<typeof addUpdateFileGroup.request>
  | ActionType<typeof addUpdateFileGroup.success>
  | ActionType<typeof addUpdateFileGroupTemplateFailure>
  | ActionType<typeof clearFileUploadStatus>
  | ActionType<typeof deleteFileGroup.failure>
  | ActionType<typeof deleteFileGroup.request>
  | ActionType<typeof deleteFileGroup.success>
  | ActionType<typeof downloadFile.failure>
  | ActionType<typeof downloadFile.request>
  | ActionType<typeof downloadFile.success>
  | ActionType<typeof downloadFiles.failure>
  | ActionType<typeof downloadFiles.request>
  | ActionType<typeof downloadFiles.success>
  | ActionType<typeof getFileGroupsForEngagementActions.failure>
  | ActionType<typeof getFileGroupsForEngagementActions.request>
  | ActionType<typeof getFileGroupsForEngagementActions.success>
  | ActionType<typeof getFileGroupsForEntityGroup.failure>
  | ActionType<typeof getFileGroupsForEntityGroup.request>
  | ActionType<typeof getFileGroupsForEntityGroup.success>
  | ActionType<typeof hideUploadErrorDialog>
  | ActionType<typeof openFileUploadAction>
  | ActionType<typeof setFileUploadDialogOpen>
  | ActionType<typeof setFileUploadDialogClose>
  | ActionType<typeof setFileUploadFileGroup>
  | ActionType<typeof setFileUploadStatus>
  | ActionType<typeof softDeleteFilesStatus.failure>
  | ActionType<typeof softDeleteFilesStatus.request>
  | ActionType<typeof softDeleteFilesStatus.success>
  | ActionType<typeof submitFileToCchAction.failure>
  | ActionType<typeof submitFileToCchAction.request>
  | ActionType<typeof submitFileToCchAction.success>
  | ActionType<typeof toggleTag.failure>
  | ActionType<typeof toggleTag.request>
  | ActionType<typeof toggleTag.success>
  | ActionType<typeof toggleSelectFileAction>
  | ActionType<typeof uploadEngagementSetup.failure>
  | ActionType<typeof uploadEngagementSetup.request>
  | ActionType<typeof uploadEngagementSetup.success>
  | ActionType<typeof setCurrentVersion.success>
  | ActionType<typeof setCurrentVersion.failure>
  | ActionType<typeof setCurrentVersion.request>
  | ActionType<typeof updateFile.failure>
  | ActionType<typeof updateFile.request>
  | ActionType<typeof updateFile.success>

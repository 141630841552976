export type SortFunction<T> = (a: T, b: T) => number
export type DirectionSortFunction<T> = (ascending: boolean) => SortFunction<T>

export interface Sorts<T> {
  [key: string]: SortFunction<T>
}

export interface Sort {
  name: string
  ascending: boolean
}

export interface DefaultSorts {
  [key: string]: (string | Sort)[]
}

interface DefaultSortFunctions<T> {
  readonly [key: string]: DirectionSortFunction<T> | undefined
}

export default function<T> (
  sorts: Sorts<T>,
  defaultSorts?: DefaultSorts
): [
  DefaultSortFunctions<T>,
  (...sorts: (string | Sort)[]) => DirectionSortFunction<T>
] {
  const defaultSortFunctions = (function (): DefaultSortFunctions<T> {
    if (!defaultSorts) {
      return {}
    }
    const result: {
      [key: string]: DirectionSortFunction<T>
    } = {}
    for (const key in defaultSorts) {
      var ds = defaultSorts[key]
      if (!ds) {
        continue
      }
      result[key] = createSort(convertNameToFunctions(ds))
    }
    return { ...result }
  })()

  function isSort (sort: string | Sort): sort is Sort {
    return (sort as Sort).name !== undefined
  }

  function retrieveNameDirection (
    sort: string | Sort
  ): [SortFunction<T>, boolean] {
    let sortName: string
    let ascending = true

    if (isSort(sort)) {
      sortName = sort.name
      ascending = sort.ascending
    } else {
      sortName = sort
    }

    return [sorts[sortName], !!ascending]
  }

  function convertNameToFunctions (sortNames: (string | Sort)[]) {
    const sortFunctions: SortFunction<T>[] = []
    for (const sortObj of sortNames) {
      const [sort, ascending] = retrieveNameDirection(sortObj)

      if (!sort) {
        throw new Error('invalid configuration')
      }

      sortFunctions.push(ascending ? sort : descending(sort))
    }
    return sortFunctions
  }

  function descending (sort: SortFunction<T>): SortFunction<T> {
    return function (a: T, b: T) {
      return -1 * sort(a, b)
    }
  }

  function createSort (sorts: SortFunction<T>[]): DirectionSortFunction<T> {
    return function (ascending: boolean) {
      function sort (a: T, b: T) {
        let i = 0
        let result = 0
        while (result === 0 && sorts.length > i) {
          result = sorts[i++](a, b)
        }
        return result
      }

      return ascending ? sort : descending(sort)
    }
  }

  function customSort (...sorts: (string | Sort)[]): DirectionSortFunction<T> {
    return createSort(convertNameToFunctions(sorts))
  }

  return [defaultSortFunctions, customSort]
}

export function SortUndefinedToEnd<T> (
  a: T | undefined,
  b: T | undefined,
  definedSort: (a: T, b: T) => number
) {
  if (a === undefined || b === undefined) {
    return a === undefined && b === undefined ? 0 : a === undefined ? 1 : -1
  }
  return definedSort(a, b)
}

export function SortUndefinedToStart<T> (
  a: T | undefined,
  b: T | undefined,
  definedSort: (a: T, b: T) => number
) {
  if (a === undefined || b === undefined) {
    return a === undefined && b === undefined ? 0 : a === undefined ? -1 : 1
  }
  return definedSort(a, b)
}

import { TsaThunkAction } from '../store'
import { areDocumentTitlesLoaded } from '../reducers/selectors'
import * as actions from './documentTitleActions'
import { DocumentTitlesApi } from '../services/api'
import { trackGet } from '../services/track'
import { DocumentTitle } from '../services/api/apiModels'

export function getDocumentTitles(): TsaThunkAction {
  return async function(dispatch, getState) {
    if (areDocumentTitlesLoaded(getState())) {
      return
    }

    try {
      dispatch(actions.documentTitlesGetBeginAction({}))

      const documentTitles: DocumentTitle[] = await trackGet(
        DocumentTitlesApi.apiGetDocumentTitles,
        dispatch,
        undefined
      )

      dispatch(actions.documentTitlesGetEndAction({ payload: documentTitles }))
    } catch (error) {
      dispatch(actions.documentTitlesGetErrorAction({ error }))
      throw error
    }
  }
}

import classNames from 'classnames'
import React, { useState } from 'react'
import { Activity, DocumentTitle, FileGroup } from '../../clientModels'
import * as ActivityFactory from '../../services/activityFactory'
import Icon from '../icon/icon'
import { WarningDialog } from '../modals/index'
import { History } from 'history'
import { latestFilesFromFileGroup } from '../../services/fileHelpers'
import { usePermissionManager } from '../../hooks'
import { UserPermissions } from '../../enums'

interface TemplateSummaryHeaderProps {
  clientId: number
  disabled?: boolean
  documentTitle?: DocumentTitle
  engagementId: number
  questionId: number
  fileGroup?: FileGroup
  onDelete?: (fileGroupId: number) => void
  onDownloadFile?: (fileId: number) => void
  onDownloadTemplate?: (
    fileId: number,
    clientId: number,
    engagementId: number
  ) => void
  onDownloadExcelTemplate?: (fileName: string, engagementId: number) => void
  openFileUpload: (
    engagementId: string | number,
    questionId: string | number,
    documentTitle?: DocumentTitle,
    history?: History,
    fileGroup?: FileGroup
  ) => void
  saveActivity: (activity: Activity) => void
}

const TemplateSummaryHeader = (props: TemplateSummaryHeaderProps) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const { hasPermission } = usePermissionManager()

  const handleDeleteClick = () => {
    if (files.length <= 1) {
      deleteFileGroup()
    } else {
      setShowConfirmDelete(true)
    }
  }

  const handleEditClick = () => {
    const {
      engagementId,
      documentTitle,
      fileGroup,
      openFileUpload,
      questionId,
    } = props
    openFileUpload(
      engagementId,
      questionId,
      documentTitle,
      undefined,
      fileGroup
    )
  }

  const handleUploadClick = () => {
    const { engagementId, documentTitle, openFileUpload, questionId } = props
    openFileUpload(engagementId, questionId, documentTitle)
  }

  const onFileClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onDownloadFile } = props
    if (onDownloadFile) {
      const id = parseInt(e.currentTarget.dataset.fileId || '', 10)
      onDownloadFile(id)
    }
  }

  const onDownloadTemplateClick = () => {
    const { documentTitle, onDownloadExcelTemplate, engagementId } = props
    if (
      onDownloadExcelTemplate &&
      documentTitle &&
      documentTitle.templateFileName
    ) {
      onDownloadExcelTemplate(documentTitle.templateFileName, engagementId)
    }
  }

  const handleCarryForwardClick = () => {
    const { saveActivity, fileGroup } = props
    if (fileGroup) {
      const activity = ActivityFactory.CreateCarryForwardFileGroupActivity(
        fileGroup,
        !fileGroup.carryForwardActivityId
      )
      saveActivity(activity)
    }
  }

  const deleteFileGroup = () => {
    const { fileGroup, onDelete } = props
    if (fileGroup && onDelete) {
      onDelete(fileGroup.id)
    }
    setShowConfirmDelete(false)
  }

  const files = latestFilesFromFileGroup(props.fileGroup)

  const { fileGroup, documentTitle, disabled } = props
  const title = documentTitle
    ? documentTitle.title
    : fileGroup && fileGroup.title

  const isCarryForward =
    (files.length > 0 &&
      fileGroup &&
      fileGroup.carryForwardActivityId &&
      fileGroup.carryForwardActivityId !== null) ||
    false
  const isRequired = !!documentTitle && !documentTitle.optional
  const hasFileErrors = files.some(x => x.status === 'Error')
  const isInvalid = isRequired && (files.length === 0 || hasFileErrors)
  const isValid = isRequired && files.length > 0 && !hasFileErrors
  const canEditCarryForward = hasPermission(UserPermissions.CarryForwardCanEdit)

  return (
    <div
      className={classNames('summary-header template', {
        'is-invalid': isInvalid,
        'is-valid': isValid,
        disabled,
      })}
    >
      <div className='summary-header-text'>
        {documentTitle && !documentTitle.optional && (
          <Icon
            className={classNames('required-document', {
              'requires-attention': isInvalid,
            })}
            icon='asterisk'
          />
        )}
        <span className={files.length <= 0 ? 'header-text-blue' : ''}>
          <button
            className='file-summary-link'
            onClick={onDownloadTemplateClick}
          >
            {' '}
            {title}
          </button>
        </span>
        {files.length > 0 && (
          <div>
            {files.map(f =>
              f.status === 'Uploaded' ||
              f.status === 'Complete' ||
              f.status === undefined ? (
                <button
                  key={f.id}
                  id={`summary-files-${f.id}`}
                  className='file-summary-link'
                  data-file-id={f.id}
                  onClick={onFileClick}
                  title={f.name}
                >
                  {f.name}
                </button>
              ) : (
                <span key={f.id} id={`summary-files-${f.id}`}>
                  {f.name + ` (${f.status})`}
                </span>
              )
            )}
          </div>
        )}
      </div>
      {files.length > 0 && fileGroup && !disabled && (
        <div className='summary-header-icons'>
          {canEditCarryForward && (
            <Icon
              active={isCarryForward}
              icon='carryForward'
              onClick={handleCarryForwardClick}
              tooltip={
                isCarryForward
                  ? 'Item(s) marked as carry forward'
                  : 'Mark item(s) as carry forward'
              }
            />
          )}
          <Icon
            active={!isCarryForward}
            icon='delete'
            onClick={!isCarryForward ? handleDeleteClick : undefined}
            tooltip={
              isCarryForward && canEditCarryForward
                ? 'You cannot delete a document that has been carried forward.'
                : isCarryForward
                ? 'Remove document(s) disabled'
                : 'Remove document(s)'
            }
          />
          <Icon
            icon='download'
            onClick={onDownloadTemplateClick}
            tooltip='Download template and fill out your information'
          />
          <Icon
            icon='upload'
            active={!isCarryForward}
            onClick={!isCarryForward ? handleEditClick : undefined}
            tooltip={
              isCarryForward && canEditCarryForward
                ? 'You cannot replace a file that has been carried forward.'
                : isCarryForward
                ? 'Replace file disabled'
                : 'Replace File'
            }
          />
        </div>
      )}
      {!files.length && fileGroup && !disabled && (
        <div className='summary-header-icons'>
          <Icon
            icon='delete'
            onClick={handleDeleteClick}
            tooltip='Remove document(s)'
          />
          <Icon
            icon='download'
            onClick={onDownloadTemplateClick}
            tooltip='Download template and fill out your information'
          />
          <Icon
            icon='upload'
            onClick={handleUploadClick}
            className='summary-icon'
            tooltip='Upload document(s)'
          />
        </div>
      )}
      {!files.length && !fileGroup && !disabled && (
        <div className='summary-header-icons'>
          <Icon
            icon='download'
            onClick={onDownloadTemplateClick}
            tooltip='Download template and fill out your information'
          />
          <Icon
            icon='upload'
            onClick={handleUploadClick}
            className='summary-icon'
            tooltip='Upload document(s)'
          />
        </div>
      )}
      {showConfirmDelete && (
        <WarningDialog
          title='Confirm Delete'
          info='This will delete multiple files. Are you sure? If you want to remove individual files, please select edit.'
          onClickPrimary={deleteFileGroup}
          onClickSecondary={() => setShowConfirmDelete(false)}
          onClose={() => setShowConfirmDelete(false)}
        />
      )}
    </div>
  )
}

export default TemplateSummaryHeader

import * as React from 'react'
import { ClientSearch, QuestionMap, SectionMap } from '../../clientModels'
import './clientSearch.scss'
import { ClientSearchBoxResult } from './clientSearchBoxResult'

interface ClientSearchBoxResultsProps {
  sections: SectionMap
  questions: QuestionMap
  results?: ClientSearch[]
  searchString: string
  selectedSearchResult?: ClientSearch
  onSelectSearchResult: (result: ClientSearch) => void
}

export class ClientSearchBoxResults extends React.Component<
  ClientSearchBoxResultsProps
  > {
  render () {
    const {
      onSelectSearchResult,
      questions,
      results,
      searchString,
      sections,
      selectedSearchResult,
    } = this.props

    if (!results) {
      return null
    }

    return (
      <div className='client-search-box-results'>
        {results.length === 0 && (
          <div className='client-search-box-result client-search-result-none'>
            No results found.
          </div>
        )}
        {results.map((r, i) => (
          <ClientSearchBoxResult
            key={i}
            onSelectSearchResult={onSelectSearchResult}
            questions={questions}
            result={r}
            searchString={searchString}
            sections={sections}
            selectedSearchResult={selectedSearchResult}
          />
        ))}
      </div>
    )
  }
}

export default ClientSearchBoxResults

import React, { useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import Icon from '../icon/icon'
import './inlineComment.scss'

interface InlineCommentProps {
  placeholder?: string
  onCancel: () => void
  onChange?: (value: string) => void
  onSave: (value: string) => void
}

export const InlineComment = (props: InlineCommentProps): JSX.Element => {
  const [value, setValue] = useState('')
  const textareaElement = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaElement.current !== null) {
      textareaElement.current.focus()
    }
  }, [])

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value
    setValue(value)
    const { onChange } = props
    if (onChange) {
      onChange(value)
    }
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSave()
    }
    if (e.key === 'Escape') {
      e.preventDefault()
      props.onCancel()
    }
  }

  const handleSave = () => {
    props.onSave(value)
  }

  return (
    <div className='inline-comment'>
      <TextareaAutosize
        ref={textareaElement}
        id='inline-comment-content'
        maxLength={1000}
        maxRows={10}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        rows={1}
        value={value}
        placeholder={props.placeholder}
      />
      <div onClick={handleSave}>
        <Icon icon='send' />
      </div>
    </div>
  )
}

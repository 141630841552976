export enum OpenerActionTypes {
  OPENER_ALLOW_ONLY_ONE = 'OPENER_ALLOW_ONLY_ONE',
  OPENER_CLOSE = 'OPENER_CLOSE',
  OPENER_CLOSE_GROUP = 'OPENER_CLOSE_GROUP',
  OPENER_DISABLE = 'OPENER_DISABLE',
  OPENER_ENABLE = 'OPENER_ENABLE',
  OPENER_OPEN = 'OPENER_OPEN',
  OPENER_TOGGLE = 'OPENER_TOGGLE',
}

export interface AllowOnlyOneAction {
  type: OpenerActionTypes.OPENER_ALLOW_ONLY_ONE
  payload: {
    group: string
    allowOnlyOne: boolean
  }
}

export interface DisableAction {
  type: OpenerActionTypes.OPENER_DISABLE
  payload: {
    group: string
    openerId: string | number
  }
}

export interface EnableAction {
  type: OpenerActionTypes.OPENER_ENABLE
  payload: {
    group: string
    openerId: string | number
  }
}

export interface OpenAction {
  type: OpenerActionTypes.OPENER_OPEN
  payload: {
    group: string
    keepOpen?: boolean
    openerId: string | number
  }
}

export interface CloseAction {
  type: OpenerActionTypes.OPENER_CLOSE
  payload: {
    group: string
    openerId: string | number
  }
}

export interface CloseGroupAction {
  type: OpenerActionTypes.OPENER_CLOSE_GROUP
  payload: {
    group: string
  }
}

export interface ToggleAction {
  type: OpenerActionTypes.OPENER_TOGGLE
  payload: {
    group: string
    keepOpen?: boolean
    openerId: string | number
  }
}

export type OpenerActions =
  | AllowOnlyOneAction
  | CloseAction
  | CloseGroupAction
  | DisableAction
  | EnableAction
  | OpenAction
  | ToggleAction

export const disable = (
  group: string,
  openerId: string | number
): DisableAction => ({
  type: OpenerActionTypes.OPENER_DISABLE,
  payload: { group, openerId },
})

export const enable = (
  group: string,
  openerId: string | number
): EnableAction => ({
  type: OpenerActionTypes.OPENER_ENABLE,
  payload: { group, openerId },
})

export const open = (
  group: string,
  openerId: string | number,
  keepOpen = false
): OpenAction => ({
  type: OpenerActionTypes.OPENER_OPEN,
  payload: { group, keepOpen, openerId },
})

export const close = (
  group: string,
  openerId?: string | number
): CloseAction | CloseGroupAction =>
  openerId
    ? {
      type: OpenerActionTypes.OPENER_CLOSE,
      payload: { group, openerId },
    }
    : {
      type: OpenerActionTypes.OPENER_CLOSE_GROUP,
      payload: { group },
    }

export const toggle = (
  group: string,
  openerId: string | number
): ToggleAction => ({
  type: OpenerActionTypes.OPENER_TOGGLE,
  payload: { group, openerId },
})

export const setAllowOnlyOne = (
  group: string,
  allowOnlyOne: boolean
): AllowOnlyOneAction => ({
  type: OpenerActionTypes.OPENER_ALLOW_ONLY_ONE,
  payload: { group, allowOnlyOne },
})

import { TsaHubAction } from '../actions/index'
import { AsyncOperationMap } from '../clientModels'
import { getType } from 'typesafe-actions'
import * as asyncOperationActions from '../actions/asyncOperationsActions'

export interface AsyncOperationsState {
  isPolling: boolean
  operations: AsyncOperationMap
  watchList: number[]
}

const initialState: AsyncOperationsState = {
  isPolling: false,
  operations: {},
  watchList: [],
}

export const asyncOperationsReducer = (
  state: AsyncOperationsState = initialState,
  action: TsaHubAction
): AsyncOperationsState => {
  switch (action.type) {
    case getType(asyncOperationActions.addAsyncOperationWatch):
      state = {
        ...state,
        watchList: [...state.watchList, action.payload.asyncOperationId],
      }
      break
    case getType(asyncOperationActions.getAsyncOperationsAction.success):
      state = { ...state, operations: { ...action.payload.asyncOperations } }
      break
    case getType(asyncOperationActions.removeAsyncOperationWatch): {
      const watchList = [...state.watchList]
      const index = watchList.findIndex(
        x => x === action.payload.asyncOperationId
      )
      if (index > -1) {
        watchList.splice(index, 1)
        state = { ...state, watchList }
      }
      break
    }
    case getType(asyncOperationActions.updateAsyncOperationPolling):
      state = { ...state, isPolling: action.payload.isPolling }
      break
    default:
      break
  }
  return state
}

import { AsyncOperationMap } from '../clientModels'
import {
  createAction,
  ActionType as TypedAction,
  createAsyncAction,
} from 'typesafe-actions'

export const addAsyncOperationWatch = createAction(
  'ADD_ASYNC_OPERATION_WATCH'
)<{
  asyncOperationId: number
}>()

export const getAsyncOperationsAction = createAsyncAction(
  'GET_ASYNC_OPERATIONS_BEGIN',
  'GET_ASYNC_OPERATIONS_DONE',
  'GET_ASYNC_OPERATIONS_FAIL'
)<
  {},
  {
    asyncOperations: AsyncOperationMap
  },
  Error
>()

export const removeAsyncOperationWatch = createAction(
  'REMOVE_ASYNC_OPERATION_WATCH'
)<{
  asyncOperationId: number
}>()

export const updateAsyncOperationPolling = createAction(
  'UPDATE_ASYNC_OPERATION_POLLING'
)<{
  isPolling: boolean
}>()

export type AsyncOperationsActions =
  | TypedAction<typeof addAsyncOperationWatch>
  | TypedAction<typeof getAsyncOperationsAction.success>
  | TypedAction<typeof getAsyncOperationsAction.failure>
  | TypedAction<typeof removeAsyncOperationWatch>
  | TypedAction<typeof updateAsyncOperationPolling>

import * as React from 'react'
import { Route } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import Engagement from '../../components/engagement/engagement'
import { EngagementsList } from '../../components/engagementsList/index'
import { EntityEngagementsList } from '../../components/entityEngagementList'

interface EngagementsProps extends RouteComponentProps<{}> {}

class Engagements extends React.Component<EngagementsProps> {
  render() {
    if (window.__SIMULATE_ERROR__ === 'content') {
      throw new Error('Content (engagement) simulated error')
    }

    const { match } = this.props

    return (
      <React.Fragment>
        <Route
          key='lists'
          path='/engagements'
          component={EngagementsList}
          exact
        />
        <Route
          key='lists-with-entity'
          path={`${match.path}/all`}
          component={EntityEngagementsList}
        />
        <Route
          key='one-with-entity'
          path={`${match.path}/:engagementId`}
          component={Engagement}
        />
      </React.Fragment>
    )
  }
}

export default Engagements

import { useParams } from 'react-router'
import { ensureNumber } from '../../guards'

export function useConfigureImportParams() {
  const params = useParams() as any

  return {
    documentTitleId: ensureNumber(params.documentTitleId),
    engagementId: ensureNumber(params.engagementId),
    fileGroupId: ensureNumber(params.fileGroupId),
    fileId: ensureNumber(params.fileId),
    k1Id: ensureNumber(params.k1Id),
    k1Page: ensureNumber(params.k1Page),
    questionId: ensureNumber(params.questionId),
  }
}

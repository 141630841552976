import { useEffect, useReducer, useState } from 'react'
import { CchProxyResponse, CchTestHarnessResult } from '.'
import { buildApiUrl } from '../../services/api'
import * as http from '../../services/http'
import { safeJsonParse } from '../../services/jsonHelpers'
import { CchResponseMessageListener } from '../../signalr/cchResponseMessageListener'
import {
  cchRequestsReducer,
  cchRequestAction,
  requestTaxDocAction,
  submitTaxDocAction,
} from './cchRequestsReducer'
import { ExtError } from '../../services/error'

export function useCchTestHarness() {
  const [busy, setBusy] = useState(false)
  const [requests, dispatch] = useReducer(cchRequestsReducer, [])

  useEffect(() => {
    // Register for SignalR notifications
    var unregister = CchResponseMessageListener.register(
      (response: CchProxyResponse) => {
        dispatch(cchRequestAction(response))
      }
    )
    return unregister
  }, [])

  const exportCchReturn = async (cchReturnId: string) => {
    const url = buildApiUrl('cchtestharness/requesttaxdocument', {
      cchReturnId,
    })
    setBusy(true)
    try {
      dispatch(requestTaxDocAction.request())
      const result: CchTestHarnessResult = await http.post(url, undefined)
      dispatch(requestTaxDocAction.success(result))
    } catch (err) {
      const result = getResult(err)
      dispatch(requestTaxDocAction.failure(result))
    }
    setBusy(false)
  }

  const submitCchReturn = async (file: File) => {
    const url = buildApiUrl('cchtestharness/submittaxdocument')
    setBusy(true)
    try {
      dispatch(submitTaxDocAction.request())
      const data = new FormData()
      data.append('file', file)
      const result: CchTestHarnessResult = await http.post(
        url,
        data,
        {
          headers: undefined,
        },
        undefined,
        240 // setting this to 4 minutes (4*60) for large file submissions to CCH
      )
      dispatch(submitTaxDocAction.success(result))
    } catch (err) {
      const result = getResult(err)
      dispatch(submitTaxDocAction.failure(result))
    }
    setBusy(false)
  }

  return {
    exportCchReturn,
    submitCchReturn,
    requests,
    busy,
  }
}

function getResult(err: Error) {
  let result: any
  if (err instanceof ExtError) {
    result = safeJsonParse(err.body ?? '""')
    if (typeof result === 'string') {
      result = { message: result }
    }
  } else {
    result = { message: err.message }
  }
  return result
}

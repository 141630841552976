import classNames from 'classnames'
import * as React from 'react'
import ReactDataSheet from 'react-datasheet'
import { GridElement } from './component'
import { getStyle, SheetHeader } from './pureCellRenderer'

export interface SheetRowProps
  extends ReactDataSheet.RowRendererProps<GridElement> {
  rowClicked?: (
    rowNumber: number
  ) => (e: React.MouseEvent<HTMLTableDataCellElement>) => void
}

export interface SheetRendererProperties
  extends ReactDataSheet.SheetRendererProps<GridElement> {
  columns: SheetHeader[][]
  headerWidth?: string
  setGridContentRef: (ref: HTMLDivElement) => void
}

export interface SheetRendererState {
  paddingRight?: number
}

export class SheetRenderer extends React.PureComponent<
  SheetRendererProperties,
  SheetRendererState
> {
  header?: HTMLDivElement
  content?: HTMLDivElement
  ready: boolean = false
  state: SheetRendererState = {}
  constructor (props: SheetRendererProperties) {
    super(props)
    this.setContentRef = this.setContentRef.bind(this)
    this.setHeaderRef = this.setHeaderRef.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount () {
    this.ready = true
  }

  setHeaderRef (ref: HTMLDivElement) {
    this.header = ref
  }

  setContentRef (ref: HTMLDivElement) {
    this.content = ref
    this.props.setGridContentRef(ref)
  }

  handleScroll () {
    if (!this.ready) {
      return
    }
    const {
      content,
      header,
      state: { paddingRight },
    } = this
    if (content && header) {
      const scrollSize = content.offsetWidth - content.clientWidth
      header.scrollLeft = content.scrollLeft
      if (paddingRight !== scrollSize) {
        this.setState({ paddingRight: scrollSize })
      }
    }
  }

  render () {
    const {
      setHeaderRef,
      setContentRef,
      handleScroll,
      props: { className, columns, headerWidth, children },
      state: { paddingRight },
    } = this

    return (
      <div className='data-grid-holder'>
        <div className='data-grid-header' ref={setHeaderRef}>
          <table
            className={className}
            cellSpacing={0}
            style={
              paddingRight ? { paddingRight: paddingRight + 'px' } : undefined
            }
          >
            <thead className='data-header'>
              {columns.map((r, i) => (
                <tr key={i}>
                  <th
                    className='cell read-only rows-header'
                    style={
                      headerWidth !== undefined
                        ? { minWidth: headerWidth, maxWidth: headerWidth }
                        : undefined
                    }
                  />
                  {r.map((c, j) => (
                    <th
                      key={i + '.' + j}
                      colSpan={c.colSpan}
                      className={classNames('cell read-only', {
                        column: i === columns.length - 1,
                        empty: c.text === '',
                        content: c.text !== '',
                        linking: c.text === '|',
                      })}
                      style={getStyle(c)}
                    >
                      {c.text}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          </table>
        </div>
        <div
          className='data-grid-content'
          ref={setContentRef}
          onScroll={handleScroll}
        >
          <table className={className} cellSpacing={0}>
            <tbody>{children}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

import * as React from 'react'
import Icon from '../icon/icon'
import Modal from '../modals/modal'

interface ExportDialogProps {
  asyncComplete: boolean
  isError: boolean
  loadingTitle: string
  onClose: () => void
  onExport: () => void
  readyTitle: string
  errorTitle: string
}

const ExportDialog: React.SFC<ExportDialogProps> = ({
  asyncComplete,
  isError,
  loadingTitle,
  onClose,
  onExport,
  readyTitle,
  errorTitle,
}) => (
  <Modal>
    <div className='modal report'>
      <div className='modal-body'>
        <button
          id='modal-button-close'
          type='button'
          className='close'
          aria-label='Close'
          onClick={onClose}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
        <div className='modal-icon'>
          <Icon
            icon={
              asyncComplete ? 'fileDownload' : isError ? 'error' : 'circleNotch'
            }
          />
        </div>
        <div className='modal-title'>
          {asyncComplete ? readyTitle : isError ? errorTitle : loadingTitle}
        </div>
        <div className='modal-info'>
          {asyncComplete
            ? 'The documents are ready to export.'
            : isError
            ? 'We encountered an error. This necessary documents can not be processed at this time.'
            : 'The documents for your export are being prepared. This may take several minutes.'}
        </div>
        <div className='modal-buttons'>
          <button
            id='modal-primary-button'
            className='btn btn-primary'
            autoFocus={true}
            onClick={asyncComplete || !isError ? onExport : onClose}
          >
            {asyncComplete ? 'EXPORT DOCUMENTS' : isError ? 'OK' : 'CANCEL'}
          </button>
        </div>
      </div>
    </div>
  </Modal>
)

export default ExportDialog

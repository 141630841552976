import { ActionType, createAction } from 'typesafe-actions'
import { SearchResult } from '../clientModels'

export const searchActionSearch = createAction('SEARCH')<{
  searchString: string
  results: SearchResult[]
}>()

export const searchActionClear = createAction('SEARCH_CLEAR')<undefined>()

export const searchActionClearResults = createAction('SEARCH_CLEAR_RESULTS')<{
  searchString: string
}>()

export const searchActionSelectResult = createAction('SEARCH_SELECT_RESULT')<{
  selectedSearchResult: SearchResult
}>()

export type SearchActions =
  | ActionType<typeof searchActionSearch>
  | ActionType<typeof searchActionClear>
  | ActionType<typeof searchActionClearResults>
  | ActionType<typeof searchActionSelectResult>

import * as React from 'react'
import {
  clientUserButtonConfig,
  rsmUserButtonConfig
} from '../../services/engagementPhaseService'
import { EngagementPhaseChangeProps } from '../engagementPhaseChange/index'
import { PhaseCode } from '../../enums'
import { AppState, TsaDispatch } from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { actions } from '../../actions'
import { engagementLockedPhase } from '../../services/engagementPhaseService'
import { getClientUrlPortion } from '../../services/filterHelpers'
import { REACT_APP_ENGAGEMENTMANAGERURL } from '../../envVariables'

interface EngagementSummaryButtonsProps extends EngagementPhaseChangeProps {
  markReviewDone: (engagementId: string | number) => void
  getLastYearsData: (engagmentId: string | number) => void
  copyLastYearAnswers: (engagmentId: string | number) => void
}

export const EngagementSummaryButtons = (
  props: EngagementSummaryButtonsProps
) => {
  function handleNavButtonClick() {
    const { engagement, history } = props
    const { id } = engagement
    const { lastNavigation } = props // Need Last Navvigation here.

    const url = history.location.pathname
    const entity = getClientUrlPortion(url)

    const path = lastNavigation
      ? `${entity}/engagements/${id}/sections/${lastNavigation.lastSectionId}/questions/${lastNavigation.lastQuestionId}`
      : `${entity}/engagements/${id}/first`
    history.push(path)
  }
  const dispatch: TsaDispatch = useDispatch()
  const busy = useSelector((state: AppState) => {
    return state.http.loading.includes('update-engagement-phase')
  })
  const [rollForwardInProgress, setRollForwardInProgress] = useState<boolean>(
    false
  )

  function handleActionButtonClick() {
    const {
      setConfirm,
      engagement: { id }
    } = props
    if (engagement.phase === PhaseCode.Review) {
      setConfirm({
        title: 'Confirm Review is Complete',
        info:
          'This will confirm that you have completed all necessary review tasks for this engagement. Continue?',
        primaryButtonText: 'Yes',
        onClickPrimary: completeReview
      })
    } else if (engagement.phase === PhaseCode.Closed) {
      setRollForwardInProgress(true)
      window.open(
        `${REACT_APP_ENGAGEMENTMANAGERURL}/engagements/${id}/rollforward/overview`,
        '_blank'
      )
    }
  }

  function handlePrepopulateDataClick() {
    dispatch(actions.engagement.startLoadAnswersEtl(engagement.id))
  }

  function handleCopyLastYearAnswersButtonClick() {
    const { setConfirm } = props
    setConfirm({
      title: "Use Last Year's Answers?",
      info:
        'This will replace all answers with values from last year for this engagement. Continue?',
      primaryButtonText: 'Yes',
      onClickPrimary: copyLastYearAnswers
    })
  }

  function handlePhaseButtonClick() {
    const { handlePhaseButtonClick } = props
    handlePhaseButtonClick()
  }

  function getLastYearsData() {
    const { engagement, getLastYearsData, closeConfirm } = props
    getLastYearsData(engagement.id)
    closeConfirm()
  }

  function copyLastYearAnswers() {
    const { engagement, copyLastYearAnswers, closeConfirm } = props
    copyLastYearAnswers(engagement.id)
    closeConfirm()
  }

  function completeReview() {
    const { engagement, markReviewDone, closeConfirm } = props
    markReviewDone(engagement.id)
    closeConfirm()
  }

  function HaveWeRequestedPriorYearReturn(): boolean {
    const { engagement } = props
    const latestImportTaxIntegrationStatus =
      engagement.taxIntegrationStatuses &&
      engagement.taxIntegrationStatuses
        .filter(x => x.type === 'CCHImport')
        .sort((x, y) => y.id - x.id)[0]
    if (
      latestImportTaxIntegrationStatus &&
      latestImportTaxIntegrationStatus.status !== 'ERROR'
    ) {
      // IN PROGRESS or SUCCESS status.
      return true
    } else {
      return false
    }
  }

  const { user, engagement, engagementTemplate } = props

  function IsETLImportInProgress(): boolean {
    const { engagement } = props
    const EtlIntegrationStatus =
      engagement.taxIntegrationStatuses &&
      engagement.taxIntegrationStatuses
        .filter(x => x.type === 'ETLImport')
        .sort((x, y) => y.id - x.id)[0]
    if (EtlIntegrationStatus && EtlIntegrationStatus.status === 'IN PROGRESS') {
      // IN PROGRESS status
      return true
    } else {
      return false
    }
  }

  let ShowPriorYearButton: boolean = false
  let ShowUseLastYearsAnswers: boolean = false
  let ShowDataIsReadyToPrepopulateButton: boolean = false

  if (!user) {
    return null
  }
  // Check if we can present the Get last years tax return button
  // 1) rsm user
  // 2) we have a prior year id
  // 3) we have not already requested last year
  if (
    engagement.priorYearReturnId &&
    !user.isExternal &&
    !HaveWeRequestedPriorYearReturn()
  ) {
    ShowPriorYearButton = true
  }

  if (
    engagement.priorYearReturnId &&
    engagement.phase === PhaseCode.Setup &&
    !engagement.usedLastYearsAnswers &&
    !user.isExternal &&
    HaveWeRequestedPriorYearReturn()
  ) {
    ShowUseLastYearsAnswers = true
  }

  if (
    user &&
    engagement.hasEtlData === true &&
    !IsETLImportInProgress() &&
    !engagementLockedPhase(engagement) &&
    !user.isExternal
  ) {
    ShowDataIsReadyToPrepopulateButton = true
  }

  const config = user.isExternal
    ? clientUserButtonConfig(engagement)
    : rsmUserButtonConfig(engagement, user, engagementTemplate)

  return (
    <div className='engagement-summary-buttons flex-wrap'>
      {config.navButtonText && (
        <button
          className='btn btn-primary nav-button'
          onClick={handleNavButtonClick}
        >
          {config.navButtonText}
        </button>
      )}
      {config.actionButtonText && !rollForwardInProgress && (
        <button
          className='btn btn-primary action-button'
          onClick={handleActionButtonClick}
        >
          {config.actionButtonText}
        </button>
      )}
      {config.phaseButtonText && !busy && (
        <button
          className='btn btn-primary phase-button'
          onClick={handlePhaseButtonClick}
        >
          {config.phaseButtonText}
        </button>
      )}
      <span>{!busy && config.statusText}</span>
      {ShowPriorYearButton && (
        <button
          className='btn btn-primary ml-auto'
          style={{
            color: '#159BD7',
            borderColor: '#f5f6f6',
            backgroundColor: '#f5f6f6',
            fontWeight: 'bold'
          }}
          onClick={getLastYearsData}
        >
          Retrieve Last Year's Data
        </button>
      )}

      {ShowDataIsReadyToPrepopulateButton && (
        <button
          className='btn btn-primary ml-auto'
          style={{
            color: '#159BD7',
            borderColor: '#f5f6f6',
            backgroundColor: '#f5f6f6',
            fontWeight: 'bold'
          }}
          onClick={handlePrepopulateDataClick}
        >
          Retrieve Organizer Data
        </button>
      )}

      {ShowUseLastYearsAnswers && (
        <button
          className='btn btn-primary ml-auto copy-last-years-answers'
          style={{
            color: '#159BD7',
            borderColor: '#f5f6f6',
            backgroundColor: '#f5f6f6',
            fontWeight: 'bold'
          }}
          onClick={handleCopyLastYearAnswersButtonClick}
        >
          Use Last Year's Answers
        </button>
      )}
    </div>
  )
}

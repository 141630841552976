import { Engagement } from '../services/api/apiModels'
import { TsaThunkAction } from '../store'
import { EngagementsApi } from '../services/api'
import { setEngagementSectionGroupsAction } from './engagementActions'

export const getEngagementSectionGroups = (
  engagement: Engagement
): TsaThunkAction => async (dispatch, getState) => {
  if (engagement?.taxYear && engagement.engagementTaxForm) {
    const state = getState()
    // Check to see if we already have section groups in state for a given tax form and year combination
    const { taxYear, taxForm } = state.engagementSectionGroups
    const areGroupsLoaded =
      engagement.taxYear === taxYear && engagement.engagementTaxForm === taxForm
    if (!areGroupsLoaded) {
      // Clear any existing section group state
      dispatch(
        setEngagementSectionGroupsAction({
          taxYear: '',
          taxForm: '',
          sectionGroups: []
        })
      )
      const sectionGroups = await EngagementsApi.getEngagementSectionGroups(
        engagement.taxYear,
        engagement.engagementTaxForm
      )
      // Set redux state
      dispatch(
        setEngagementSectionGroupsAction({
          taxYear: engagement.taxYear,
          taxForm: engagement.engagementTaxForm,
          sectionGroups
        })
      )
    }
  }
}

export const clearEngagementSectionGroups = (): TsaThunkAction => async (
  dispatch,
  getState
) => {
  dispatch(
    setEngagementSectionGroupsAction({
      taxYear: '',
      taxForm: '',
      sectionGroups: []
    })
  )
}

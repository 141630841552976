import moment from 'moment'
import { Activity as ClientActivity } from '../clientModels'
import { ensureNumber } from '../guards'
import { TsaThunkAction } from '../store'
import { saveActivity } from './activityThunks'

export const saveComment = (
  commentText: string,
  engagementId: number,
  questionId: string | number,
  firstName: string,
  lastName: string,
  userId: string,
  isInternal: boolean,
  mentionLabel?: string,
  mentionValue?: string,
  parentId?: number,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  questionId = ensureNumber(questionId)

  const state = getState()
  const engagement = state.engagements[engagementId]
  if (!engagement) {
    throw new Error(
      `Engagement (${engagementId}) not found. You must load the engagement before you attempt to add or update a comment.`
    )
  }

  const propertyBag = buildCommentPropertyBag(isInternal, mentionValue)

  const comment: ClientActivity = {
    activityTypeCode: 'Comment',
    author: '',
    clientId: (engagement && engagement.clientId) || 0,
    createdDate: moment(new Date()),
    engagementId,
    id: -1,
    parentId,
    questionId,
    userId,
    value: commentText,
    propertyBag: propertyBag,
    isDeleted: false,
    activityVersion: 0,
  }

  if (mentionLabel && mentionValue) {
    comment.mentions = [
      {
        id: 0,
        activityId: 0,
        label: mentionLabel,
        value: mentionValue,
        questionId: isNaN(questionId) ? undefined : questionId,
        resolved: false,
      },
    ]
  }

  await dispatch(saveActivity(comment, id))
}

function buildCommentPropertyBag(isInternal: boolean, mentionValue?: string) {
  const propertyBag: { isLocked?: boolean } = {}

  propertyBag.isLocked =
    !isInternal || (mentionValue !== undefined && mentionValue !== 'RSM' && !(mentionValue.match(/[A-Za-z]/) && !mentionValue.match(/-/)))

  return JSON.stringify(propertyBag)
}

import { TsaHubAction } from '../actions/index'
import { PhaseMap } from '../clientModels'
import { engagementTemplatesDocEndAction } from '../actions/engagementTemplateActions'
import { getType } from 'typesafe-actions'
import { phaseListDoneAction } from '../actions/phaseActions'

const initialState: PhaseMap = {}

export function phasesReducer (
  state: PhaseMap = initialState,
  action: TsaHubAction
): PhaseMap {
  switch (action.type) {
    case getType(phaseListDoneAction):
    case getType(engagementTemplatesDocEndAction):
      return { ...state, ...action.payload.payload.phases }
    default:
      return state
  }
}

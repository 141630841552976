import { useSelector } from 'react-redux'
import { AppState } from '../store'
import { isObjectEmpty } from '../guards'
import { useConfigureImportParams } from '../components/configureImport/useConfigureImportParams'

export function useEngagement(engagementIdNbr?: number) {
  const { engagementId } = useConfigureImportParams()
  const { engagements, clients } = useSelector(
    (state: AppState) => state
  )

  if (!engagements || isObjectEmpty(engagements)) return null

  const engagementNbr = engagementIdNbr || engagementId
  const engagement = engagements[engagementNbr]

  if (!engagement) return null

  const client = clients[engagement.clientId]!

  return {
    engagement, client, engagementId: engagementNbr
  }
}

import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, TsaDispatch } from '../../store'
import { SidebarNames } from '../../clientModels'
import { actions } from '../../actions'
import Icon from '../icon/icon'
import { Sidebar } from '../sidebar'
import { FileUpload } from '../fileUpload'
import { ensureNumber } from '../../guards'
import { Help } from '../help'
import { ActivityListContainer } from '../activity/activityListContainer'
import { useQuestionDisabled } from '../../services/question/useQuestionDisabled'
import Comment from '../comment/comment'
import './engagementSidebar.scss'

interface RouteParams {
  activityId?: string
  engagementId: string
  questionId: string
  sectionId?: string
}

interface EngagementSidebarProps extends RouteComponentProps<RouteParams> {
  questionDisabled: boolean
}

export const EngagementSidebar = (props: EngagementSidebarProps) => {
  const {
    match: {
      params: { questionId, sectionId, engagementId, activityId },
    },
  } = props
  const question = useSelector((state: AppState) => {
    return state.questions[questionId || '']
  })
  const selectedSidebar = useSelector((state: AppState) => {
    return state.selection.selectedSidebarName
  })
  const showMoreHelp = useSelector((state: AppState) => {
    return state.selection.showMoreHelp
  })
  const dispatch: TsaDispatch = useDispatch()

  const selectSidebar = (item: SidebarNames) => {
    dispatch(
      actions.selection.selectionSidebarSelectAction({
        selectedSidebarName: item,
        toggle: false,
      })
    )
  }

  const toggleShowMoreHelp = () => {
    dispatch(actions.selection.selectionHelpToggleShowMoreAction({}))
  }

  const questionDisabled = useQuestionDisabled(engagementId, questionId)

  useEffect(() => {
    if (selectedSidebar === 'file') {
      selectSidebar('activity')
    }
    // eslint-disable-next-line
  }, [questionId, sectionId])

  const handleFileClick = (_: string, e: React.MouseEvent) => {
    e.stopPropagation()
    selectSidebar('file')
  }

  const handleHelpClick = (_: string, e: React.MouseEvent) => {
    e.stopPropagation()
    selectSidebar('help')
  }

  const handleToggleOpen = () => {
    selectSidebar(selectedSidebar ? '' : 'activity')
  }

  const renderContent = () => {
    if (!question) {
      return null
    }

    if (selectedSidebar === 'file') {
      return (
        <FileUpload
          engagementId={ensureNumber(engagementId)}
          question={question}
        />
      )
    }

    return (
      <React.Fragment>
        <Help
          onToggleReadMore={toggleShowMoreHelp}
          question={question}
          readMore={showMoreHelp}
        />
        <ActivityListContainer
          engagementId={engagementId}
          question={question}
          selectedActivityId={activityId}
          questionDisabled={questionDisabled}
        />
        <Comment
          engagementId={engagementId}
          questionId={questionId}
          showMention={true}
          questionDisabled={questionDisabled}
        />
      </React.Fragment>
    )
  }

  const renderTray = () => {
    if (!question) {
      return null
    }

    return (
      <React.Fragment>
        {question.help && (
          <Icon
            icon='questionCircle'
            tooltip='View Help'
            onClick={handleHelpClick}
          />
        )}
        <Icon icon='comment' tooltip='View/Add Comments' />
        {!questionDisabled && (
          <Icon
            icon='fileText'
            onClick={handleFileClick}
            tooltip='Add Document'
          />
        )}
      </React.Fragment>
    )
  }

  return (
    <Sidebar
      className='engagement-sidebar'
      onToggleOpen={handleToggleOpen}
      open={!!selectedSidebar}
      renderTray={renderTray}
    >
      {renderContent()}
    </Sidebar>
  )
}

import * as React from 'react'
import Icon from '../icon/icon'
import Modal from '../modals/modal'
import { useTheme, Theme, createStyles } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'

const styles = ({ palette }: Theme) =>
  createStyles({
    reportError: {
      '& .modal-icon': {
        color: palette.informational.red
      }
    }
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface ReportProps {
  downloadReady: boolean
  isError: boolean
  loadingTitle: string
  onClose: () => void
  onDownload: () => void
  readyTitle: string
  errorTitle: string
  errorText?: string
}

const ReportDialog: React.SFC<ReportProps> = ({
  downloadReady,
  isError,
  loadingTitle,
  onClose,
  onDownload,
  readyTitle,
  errorTitle,
  errorText
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  let rootClasses = errorText ? `modal report ${classes.reportError}` : 'modal report'

  return (
    <Modal>
      <div className={rootClasses}>
        <div className='modal-body'>
          <button
            id='modal-button-close'
            type='button'
            className='close'
            aria-label='Close'
            onClick={onClose}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
          <div className='modal-icon'>
            <Icon
              icon={
                downloadReady ? 'fileDownload' : isError ? errorText ? 'timesCircle' : 'error' : 'circleNotch'
              }
            />
          </div>
          <div className='modal-title'>
            {downloadReady ? readyTitle : isError ? errorTitle : loadingTitle}
          </div>
          <div className='modal-info'>
            {downloadReady
              ? 'Your requested report is ready to download.'
              : isError
                ? errorText
                  ? errorText
                  : 'Your report ran into an error. This report can not be processed at this time.'
                : 'Your report is being prepared. This may take several minutes.'}
          </div>
          <div className='modal-buttons'>
            <button
              id='modal-primary-button'
              className='btn btn-primary'
              autoFocus={true}
              onClick={downloadReady || !isError ? onDownload : onClose}
            >
              {downloadReady ? 'DOWNLOAD' : isError ? 'OK' : 'CANCEL'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ReportDialog

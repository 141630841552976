import { Engine } from 'json-rules-engine'

type EngineTuple = [number, Engine]

/**
 * Wraps a Map for easy mocking.
 */
export class EngineCache {
  map: Map<number, Engine>

  constructor (entries?: EngineTuple[]) {
    this.map = new Map(entries)
  }

  set (id: number, engine: Engine) {
    return this.map.set(id, engine)
  }

  get (id: number) {
    return this.map.get(id)
  }

  clear () {
    this.map.clear()
  }

  delete (id: number) {
    return this.map.delete(id)
  }

  has (id: number) {
    return this.map.has(id)
  }

  get size (): number {
    return this.map.size
  }

  forEach (
    callbackfn: (value: Engine, key: number, map: Map<number, Engine>) => void
  ) {
    this.map.forEach(callbackfn)
  }
}

import {
  Condition,
  Engine,
  JsonRule,
  RuleOptions,
  RulesEngineEventHandler,
} from 'json-rules-engine'
import { Question } from '../../clientModels'
import { EngineCache } from './engineCache'
import setFacts from './facts'
import setOperators from './operators'
import { rulePriorities } from './rulePriorities'

export function configureEngine(
  engine: Engine,
  engagementTemplateId: number,
  handler: RulesEngineEventHandler,
  question: Question | undefined
) {
  const answerSchema = question && question.answerSchema
  const objectType = answerSchema && answerSchema.type
  engine.answerType = objectType ? objectType.toString() : undefined
  engine.engagementTemplateId = engagementTemplateId
  setOperators(engine)
  setFacts(engine)
  engine.on('success', handler)
  engine.on('failure', handler)
  return engine
}

export function clearCache(cache: EngineCache, engagementTemplateId: number) {
  const remove = [] as number[]
  cache.forEach((engine, id) => {
    if (engine.engagementTemplateId === engagementTemplateId) {
      remove.push(id)
      engine.removeAllListeners()
    }
  })
  remove.forEach(id => cache.delete(id))
}

export function usesQuestionId(condition: Condition) {
  const params = condition.params || {}
  return !!params.questionId
}

/**
 * Finds the question ID of answers that are used as input facts to the rule.
 * Performs a breadth first traversal of the rule looking for conditions
 * that use the answer fact and have a question ID.
 * @param rule
 */
export function findQuestionIds(rule: RuleOptions): number[] {
  // tslint:disable-next-line:no-any
  const rootConditions: any = rule.conditions
  const conditions = [...(rootConditions.all || rootConditions.any)]
  const questionIds: number[] = []
  while (conditions.length) {
    const condition = conditions.shift()
    const children = condition.all || condition.any
    if (children) {
      // tslint:disable-next-line:no-any
      children.forEach((n: any) => conditions.push(n))
    } else {
      if (
        condition.value &&
        typeof condition.value === 'object' &&
        {}.hasOwnProperty.call(condition.value, 'params')
      ) {
        conditions.push(condition.value)
      }
      const questionId = condition.params && condition.params.questionId
      // tslint:disable-next-line:no-any
      if (questionId && !questionIds.includes(questionId)) {
        questionIds.push(questionId)
      }
    }
  }

  if (questionIds.length === 0) {
    const eventQuestionId = rule?.event?.params.questionId
    if (eventQuestionId) {
      questionIds.push(eventQuestionId)
    }
  }

  return questionIds
}

/**
 * Determines whether the specified question ID is used as input to one of the
 * rule conditions or as the target of the rule event.
 * @param rule
 * @param questionId
 */
export function questionIsInRule(rule: JsonRule, questionId: number): boolean {
  return (
    rule.event.params.questionId === questionId ||
    findQuestionIds(rule).includes(questionId)
  )
}

/**
 * Processes the Rule object from the API during the normalization process.
 * @param rule
 */
export function ruleProcessStrategy(rule: any) {
  const ruleEvent = rule && rule.event
  const eventType = ruleEvent && ruleEvent.type
  const priority = rule && rule.priority

  if (rule && eventType && !priority) {
    rule.priority = rulePriorities[eventType]
  }

  return rule
}

import React, { useState, useEffect } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Theme, Button } from '@rsmus/react-ui'
import { FaInfoCircle } from 'react-icons/fa'
import { usePermissionManager } from '../../hooks'

function styles({ palette }: Theme) {
  return {
    root: {
      backgroundColor: palette.informational.teal,
      color: palette.common.white,
      flex: '0 0 auto',
    },
    messageWrap: {
      marginLeft: '5%',
      marginRight: '5%',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '90%',
    },
    icon: {
      height: '1.5rem',
      width: '1.5rem',
      margin: '0.5rem',
    },
    blueX: {
      position: 'absolute',
      right: 12,
      color: palette.blue.dark,
      height: '1.5rem',
      width: '1.5rem',
      margin: '0.5rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    messageText: {
      display: 'inline',
      fontWeight: 'Bold',
      paddingRight: '40px',
      color: 'white',
    },

    changeLook: {
      cursor: 'pointer',
      color: palette.blue.main,
      textDecoration: 'underline',
    },
    simpleLink: {
      display: 'flex',
      height: '2rem',
    },
    buttonStyle: {
      display: 'inline',
      fontWeight: 'bold',
      paddingRight: '1rem',
      color: palette.common.white,
      marginRight: '0.5rem',
      backgroundColor: palette.informational.teal,
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ProxyBanner = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { toggleUserView, impersonateUser } = usePermissionManager()
  const [timer, setTimer] = useState<NodeJS.Timeout | null>()

  useEffect(() => {
    return () => {
      if (impersonateUser) {
        toggleUserView()
      }
    }
    // eslint-disable-next-line
  }, [])

  const toggleUser = () => {
    const tmr = setTimeout(() => {
      toggleUserView()
    }, 3000)
    setTimer(tmr)
  }

  const clearTimer = () => {
    if (timer) {
      clearInterval(timer)
      setTimer(null)
    }
  }

  if (!impersonateUser) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.messageWrap}>
        <FaInfoCircle className={classes.icon}></FaInfoCircle>
        <p className={classes.messageText}>You are viewing as the client</p>
        <Button onClick={toggleUser} className={classes.buttonStyle}>
          Switch back to RSM user
        </Button>
        <p className={classes.messageText}>
          {timer && (
            <>
              Refreshing...
              <Button onClick={clearTimer} className={classes.buttonStyle}>
                Cancel
              </Button>
            </>
          )}
        </p>
      </div>
    </div>
  )
}

export default ProxyBanner

import { ActionType, createAsyncAction, createAction } from 'typesafe-actions'
import {
  ClientEntity,
  EtlScreen,
  InputProcessTemplateMap,
  LoadStatus,
  Option,
  TaxCode,
  EntityMap,
  K1Header,
  StructureTemplate,
} from '../clientModels'
import { StructureMap, DataMap } from '../components/configureImport/interface'
import {
  ColumnFilters,
  Partner,
  InputProcessTemplate,
} from '../services/api/apiModels'
import { ApiModels } from '../services/api'
import { ValidationInfo } from '../components/configureImport/helpers'

export const closeEtlAction = createAction('CLOSE_ETL')()

export const completeK1FileProgressActions = createAsyncAction(
  'COMPLETE_K1_FILE_PROGRESS_BEGIN',
  'COMPLETE_K1_FILE_PROGRESS_DONE',
  'COMPLETE_K1_FILE_PROGRESS_FAIL'
)<void, ApiModels.File, Error>()

export const exportK1FileDataAction = createAsyncAction(
  'EXPORT_K1_FILE_DATA_BEGIN',
  'EXPORT_K1_FILE_DATA_DONE',
  'EXPORT_K1_FILE_DATA_FAIL'
)<void, number, Error>()

export const getDataMappingTemplateForK1Actions = createAsyncAction(
  'GET_DATA_MAPPING_TEMPLATE_FOR_K1_BEGIN',
  'GET_DATA_MAPPING_TEMPLATE_FOR_K1_DONE',
  'GET_DATA_MAPPING_TEMPLATE_FOR_K1_FAIL'
)<void, InputProcessTemplate, Error>()

export const getEngagementK1FileStatusesActions = createAsyncAction(
  'GET_ENGAGEMENT_K1_FILE_STATUSES_BEGIN',
  'GET_ENGAGEMENT_K1_FILE_STATUSES_DONE',
  'GET_ENGAGEMENT_K1_FILE_STATUSES_FAIL'
)<void, ApiModels.K1FileStatus[], Error>()

export const getEngagementK1sActions = createAsyncAction(
  'GET_ENGAGEMENT_K1_STATUS_BEGIN',
  'GET_ENGAGEMENT_K1_STATUS_DONE',
  'GET_ENGAGEMENT_K1_STATUS_FAIL'
)<void, K1Header[], Error>()

export const getK1StatusActions = createAsyncAction(
  'GET_K1_FILE_STATUS_BEGIN',
  'GET_K1_FILE_STATUS_DONE',
  'GET_K1_FILE_STATUS_FAIL'
)<void, ApiModels.K1FileStatus, Error>()

export const getCategoryOptionsActions = createAsyncAction(
  'GET_CATEGORY_OPTIONS_BEGIN',
  'GET_CATEGORY_OPTIONS_DONE',
  'GET_CATEGORY_OPTIONS_FAIL'
)<void, { categoryOptions: TaxCode[] }, Error>()

export const getEntityOptionsActions = createAsyncAction(
  'GET_ENTITY_OPTIONS_BEGIN',
  'GET_ENTITY_OPTIONS_DONE',
  'GET_ENTITY_OPTIONS_FAIL'
)<{}, { entityOptions: ClientEntity[] }, Error>()

export const getK1ByIdAction = createAsyncAction(
  'GET_K1_BY_ID_BEGIN',
  'GET_K1_BY_ID_DONE',
  'GET_K1_BY_ID_FAIL'
)<void, K1Header, Error>()

export const getK1sByFileIdAction = createAsyncAction(
  'GET_K1S_BY_FILEID_BEGIN',
  'GET_K1S_BY_FILEID_DONE',
  'GET_K1S_BY_FILEID_FAIL'
)<void, K1Header[], Error>()

export const getInputProcessTemplatesActions = createAsyncAction(
  'GET_INPUT_PROCESS_TEMPLATES_BEGIN',
  'GET_INPUT_PROCESS_TEMPLATES_DONE',
  'GET_INPUT_PROCESS_TEMPLATES_FAIL'
)<{}, { templates: InputProcessTemplateMap }, Error>()

export const getPivotOptionsActions = createAsyncAction(
  'GET_PIVOT_OPTIONS_BEGIN',
  'GET_PIVOT_OPTIONS_DONE',
  'GET_PIVOT_OPTIONS_FAIL'
)<
  {},
  {
    pivotColumnOptions: Option[]
    pivotRowOptions: Option[]
    rowOptions: Option[]
  },
  Error
>()

export const getReceivingPartnersAction = createAsyncAction(
  'GET_RECEIVING_PARTNERS_BEGIN',
  'GET_RECEIVING_PARTNERS_DONE',
  'GET_RECEIVING_PARTNERS_FAIL'
)<void, Partner[], Error>()

export const getWorksheetOptionsFailAction = createAction(
  'GET_WORKSHEET_OPTIONS_FAIL'
)<Error>()

export const processK1MappingsFailAction = createAction(
  'PROCESS_K1_MAPPINGS_FAIL'
)<Error>()

export const processInputWorksheetFailAction = createAction(
  'PROCESS_INPUT_WORKSHEET_FAIL'
)<Error>()

export const resetEtlScreensAction = createAction('RESET_ETL_SCREENS')()

export const setDataMapAction = createAction('SET_DATA_MAP')<DataMap>()

export const setEtlScreenLoadedAction = createAction('SET_ETL_SCREEN_LOADED')<{
  screen: EtlScreen
  status: LoadStatus
}>()

export const setExcludeMappedDataAction = createAction(
  'SET_EXCLUDE_MAPPED_DATA'
)<boolean>()

export const setFileIdAction = createAction('SET_FILE_ID')<number>()

export const setFileMappingValues = createAction('SET_FILE_MAPPING_VALUES')<
  StructureMap
>()

export const setColumnFiltersAction = createAction('SET_COLUMN_FILTERS')<
  ColumnFilters
>()

export const setOcrDataAction = createAction('SET_OCR_DATA')<any>()

export const setStructureTemplateAction = createAction(
  'SET_STRUCTURE_TEMPLATE'
)<StructureTemplate>()

export const setTaxCodeDataAction = createAsyncAction(
  'SET_TAX_CODE_DATA_BEGIN',
  'SET_TAX_CODE_DATA_DONE',
  'SET_TAX_CODE_DATA_FAIL'
)<void, string[][], Error>()

export const setTaxCodeMappingTemplate = createAction(
  'SET_TAX_CODE_MAPPING_TEMPLATE'
)<EntityMap<string>>()

export const setValidationAndStepAction = createAction(
  'SET_VALIDATION_AND_STEP'
)<{
  step: EtlScreen
  validation: EntityMap<ValidationInfo>
}>()

export const downloadTaxCodeMapAction = createAsyncAction(
  'DOWNLOAD_TAXCODEMAP_BEGIN',
  'DOWNLOAD_TAXCODEMAP_DONE',
  'DOWNLOAD_TAXCODEMAP_FAIL'
)<void, void, Error>()

export const getAvailableColumnFiltersAction = createAsyncAction(
  'GET_FILTER_VALUES_BEGIN',
  'GET_FILTER_VALUES_DONE',
  'GET_FILTER_VALUES_FAIL'
)<void, { [id: string]: string[] }, Error>()

export const getPivotDataAction = createAsyncAction(
  'GET_PIVOT_DATA_BEGIN',
  'GET_PIVOT_DATA_DONE',
  'GET_PIVOT_DATA_FAIL'
)<void, string[][], Error>()

interface Worksheets {
  options: Option[]
  fileId: number
}

export const getStructureTemplateAction = createAsyncAction(
  'GET_STRUCTURE_TEMPLATE_BEGIN',
  'GET_STRUCTURE_TEMPLATE_DONE',
  'GET_STRUCTURE_TEMPLATE_FAIL'
)<void, StructureTemplate, Error>()

export const getWorksheetOptionsAction = createAsyncAction(
  'GET_WORKSHEET_OPTIONS_BEGIN',
  'GET_WORKSHEET_OPTIONS_DONE',
  'GET_WORKSHEET_OPTIONS_FAIL'
)<void, Worksheets, Error>()

export const updateK1FileProgressAction = createAsyncAction(
  'UPDATE_K1_FILE_PROGRESS_BEGIN',
  'UPDATE_K1_FILE_PROGRESS_DONE',
  'UPDATE_K1_FILE_PROGRESS_FAIL'
)<void, ApiModels.File, Error>()

export const updateK1ProgressAction = createAsyncAction(
  'UPDATE_K1_PROGRESS_BEGIN',
  'UPDATE_K1_PROGRESS_DONE',
  'UPDATE_K1_PROGRESS_FAIL'
)<void, ApiModels.File, Error>()

export const updateK1StatusAction = createAsyncAction(
  'UPDATE_K1_STATUS_BEGIN',
  'UPDATE_K1_STATUS_DONE',
  'UPDATE_K1_FAIL'
)<void, K1Header, Error>()

export const updateK1FileStatusAction = createAsyncAction(
  'UPDATE_K1_FILE_STATUS_BEGIN',
  'UPDATE_K1_FILE_STATUS_DONE',
  'UPDATE_K1_FILE_STATUS_FAIL'
)<void, ApiModels.K1FileStatus, Error>()

export const updateOcrDataAction = createAsyncAction(
  'UPDATE_OCR_DATA_BEGIN',
  'UPDATE_OCR_DATA_DONE',
  'UPDATE_OCR_DATA_FAIL'
)<void, { ocrData: any; fileId: number }, Error>()

export const validateK1FileAction = createAsyncAction(
  'VALIDATE_K1_FILE_BEGIN',
  'VALIDATE_K1_FILE_DONE',
  'VALIDATE_K1_FILE_FAIL'
)<number, string[], Error>()

export type InputProcessActions =
  | ActionType<typeof closeEtlAction>
  | ActionType<typeof completeK1FileProgressActions.failure>
  | ActionType<typeof completeK1FileProgressActions.request>
  | ActionType<typeof completeK1FileProgressActions.success>
  | ActionType<typeof downloadTaxCodeMapAction.failure>
  | ActionType<typeof downloadTaxCodeMapAction.request>
  | ActionType<typeof downloadTaxCodeMapAction.success>
  | ActionType<typeof exportK1FileDataAction.failure>
  | ActionType<typeof exportK1FileDataAction.request>
  | ActionType<typeof exportK1FileDataAction.success>
  | ActionType<typeof getAvailableColumnFiltersAction.failure>
  | ActionType<typeof getAvailableColumnFiltersAction.request>
  | ActionType<typeof getAvailableColumnFiltersAction.success>
  | ActionType<typeof getCategoryOptionsActions.failure>
  | ActionType<typeof getCategoryOptionsActions.request>
  | ActionType<typeof getCategoryOptionsActions.success>
  | ActionType<typeof getDataMappingTemplateForK1Actions.failure>
  | ActionType<typeof getDataMappingTemplateForK1Actions.request>
  | ActionType<typeof getDataMappingTemplateForK1Actions.success>
  | ActionType<typeof getEngagementK1FileStatusesActions.failure>
  | ActionType<typeof getEngagementK1FileStatusesActions.request>
  | ActionType<typeof getEngagementK1FileStatusesActions.success>
  | ActionType<typeof getEngagementK1sActions.failure>
  | ActionType<typeof getEngagementK1sActions.request>
  | ActionType<typeof getEngagementK1sActions.success>
  | ActionType<typeof getEntityOptionsActions.failure>
  | ActionType<typeof getEntityOptionsActions.request>
  | ActionType<typeof getEntityOptionsActions.success>
  | ActionType<typeof getInputProcessTemplatesActions.failure>
  | ActionType<typeof getInputProcessTemplatesActions.request>
  | ActionType<typeof getInputProcessTemplatesActions.success>
  | ActionType<typeof getK1ByIdAction.failure>
  | ActionType<typeof getK1ByIdAction.request>
  | ActionType<typeof getK1ByIdAction.success>
  | ActionType<typeof getK1sByFileIdAction.failure>
  | ActionType<typeof getK1sByFileIdAction.request>
  | ActionType<typeof getK1sByFileIdAction.success>
  | ActionType<typeof getK1StatusActions.failure>
  | ActionType<typeof getK1StatusActions.request>
  | ActionType<typeof getK1StatusActions.success>
  | ActionType<typeof getPivotDataAction.failure>
  | ActionType<typeof getPivotDataAction.request>
  | ActionType<typeof getPivotDataAction.success>
  | ActionType<typeof getPivotOptionsActions.failure>
  | ActionType<typeof getPivotOptionsActions.request>
  | ActionType<typeof getPivotOptionsActions.success>
  | ActionType<typeof getReceivingPartnersAction.failure>
  | ActionType<typeof getReceivingPartnersAction.request>
  | ActionType<typeof getReceivingPartnersAction.success>
  | ActionType<typeof getStructureTemplateAction.failure>
  | ActionType<typeof getStructureTemplateAction.request>
  | ActionType<typeof getStructureTemplateAction.success>
  | ActionType<typeof getWorksheetOptionsAction.failure>
  | ActionType<typeof getWorksheetOptionsAction.request>
  | ActionType<typeof getWorksheetOptionsAction.success>
  | ActionType<typeof getWorksheetOptionsFailAction>
  | ActionType<typeof processK1MappingsFailAction>
  | ActionType<typeof processInputWorksheetFailAction>
  | ActionType<typeof resetEtlScreensAction>
  | ActionType<typeof setColumnFiltersAction>
  | ActionType<typeof setDataMapAction>
  | ActionType<typeof setEtlScreenLoadedAction>
  | ActionType<typeof setExcludeMappedDataAction>
  | ActionType<typeof setFileIdAction>
  | ActionType<typeof setFileMappingValues>
  | ActionType<typeof setOcrDataAction>
  | ActionType<typeof setStructureTemplateAction>
  | ActionType<typeof setTaxCodeDataAction.failure>
  | ActionType<typeof setTaxCodeDataAction.request>
  | ActionType<typeof setTaxCodeDataAction.success>
  | ActionType<typeof setTaxCodeMappingTemplate>
  | ActionType<typeof setValidationAndStepAction>
  | ActionType<typeof updateK1FileProgressAction.failure>
  | ActionType<typeof updateK1FileProgressAction.request>
  | ActionType<typeof updateK1FileProgressAction.success>
  | ActionType<typeof updateK1FileStatusAction.failure>
  | ActionType<typeof updateK1FileStatusAction.request>
  | ActionType<typeof updateK1FileStatusAction.success>
  | ActionType<typeof updateK1StatusAction.failure>
  | ActionType<typeof updateK1StatusAction.request>
  | ActionType<typeof updateK1StatusAction.success>
  | ActionType<typeof updateK1ProgressAction.failure>
  | ActionType<typeof updateK1ProgressAction.request>
  | ActionType<typeof updateK1ProgressAction.success>
  | ActionType<typeof updateOcrDataAction.failure>
  | ActionType<typeof updateOcrDataAction.request>
  | ActionType<typeof updateOcrDataAction.success>
  | ActionType<typeof validateK1FileAction.failure>
  | ActionType<typeof validateK1FileAction.request>
  | ActionType<typeof validateK1FileAction.success>

import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { ensureNumber } from '../../guards'
import { AppState } from '../../store'
import { getClientUrlPortion } from '../../services/filterHelpers'

interface RouteParams {
  engagementId: string
  sectionId?: string
  entityGroupId?: string
}

const useRouteParams = () => {
  const { engagementId, sectionId, entityGroupId } = useParams<RouteParams>()
  return { engagementId: ensureNumber(engagementId), sectionId, entityGroupId }
}

const EngagementHeader = () => {
  const { engagementId, sectionId } = useRouteParams()
  const history = useHistory()
  const entityGroupUrl = getClientUrlPortion(history.location.pathname)

  const engagement = useSelector(
    (state: AppState) => state.engagements[engagementId]
  )
  const section = useSelector(
    (state: AppState) => state.sections[sectionId || '']
  )

  return section ? (
    <div className='engagement-header'>
      <div className='engagement-header-main'>
        <div className='engagement-header-section'>{section.title}</div>
        <div className='engagement-header-description'>
          {section.description}
        </div>
      </div>
    </div>
  ) : (
    <div className='engagement-header'>
      <div className='engagement-header-no-section'>
        <Link to={`${entityGroupUrl}/engagements/${engagementId}`}>
          {engagement && engagement.name}
        </Link>
      </div>
    </div>
  )
}

export default EngagementHeader

import { Option } from '../../clientModels'
import { CommentActivity } from './selectComments'

class CommentSorts {
  QuestionOrder = 'Question Order'
  MostRecentOrder = 'Most Recent'
}

interface ICommentSortMethods {
  [sort: string]: (lhs: CommentActivity, rhs: CommentActivity) => number
}

const DefaultCommentSort: keyof CommentSorts = 'QuestionOrder'
const CommentSortOptions: Option[] = []
const commentSorts: any = new CommentSorts()

for (const prop in commentSorts) {
  CommentSortOptions.push({
    value: prop,
    label: commentSorts[prop],
  })
}

function sortByQuestionOrder(
  lhs: CommentActivity,
  rhs: CommentActivity
): number {
  const lhsQuestion = lhs.question
    ? lhs.question.displayOrder
    : Number.MAX_SAFE_INTEGER
  const rhsQuestion = rhs.question
    ? rhs.question.displayOrder
    : Number.MAX_SAFE_INTEGER
  let result = lhsQuestion - rhsQuestion // order by question display order, asc
  if (result === 0) {
    result = rhs.displayOrder - lhs.displayOrder // order by creation date, desc
  }
  return result
}

function sortByMostRecent(lhs: CommentActivity, rhs: CommentActivity): number {
  if (rhs.newestChild.createdDate > rhs.createdDate) {
    return rhs.newestChild.createdDate.diff(lhs.createdDate)
  }
  return rhs.createdDate.diff(lhs.createdDate)
}

const CommentSortMethods: ICommentSortMethods = {
  QuestionOrder: sortByQuestionOrder,
  MostRecentOrder: sortByMostRecent,
  __default: sortByQuestionOrder,
}

export {
  CommentSorts,
  CommentSortMethods,
  CommentSortOptions,
  DefaultCommentSort,
}

import * as React from 'react'
import {
  Route,
  Switch,
  //NavLink,
  //RouteComponentProps,
  useHistory
} from 'react-router-dom'
import '../icon/icon.scss'
import Icon from '../icon/icon'
import { SearchBox } from '../search/index'
import EngagementNavItems from './engagementNavItems'
import './navbar.scss'
import ClientName from '../clientName/clientName'
import AccountMenu, { AccountMenuProperties } from './AccountMenu/AccountMenu'
import * as PropTypes from 'prop-types'
//import { Anchor } from '../anchor'
import * as permissions from '../../services/permissionManagerHelpers'
import { WarningDialog } from '../modals'
//import { History } from 'history'

const NavbarInternal = (props: AccountMenuProperties) => {
  var history = useHistory()
  const [dialog, setDialog] = React.useState(false)

  const handleClick = (e: any) => {
    if (permissions.impersonatingUser) {
      e.stopPropagation()
      e.preventDefault()
      setDialog(true)
    }
  }

  const onDialogClose = () => {
    setDialog(false)
    history.push('/')
  }

  const onDialogCancel = () => {
    setDialog(false)
  }

  return (
    <div className='navbar'>
      <a className='navbar-logo' onClick={handleClick} href='/'>
        <Icon icon='logo' />
      </a>
      <div className='navbar-content'>
        <div className='navbar-content-left'>
          <Switch>
            <Route path={ROUTE_PATH} component={SearchBox} />
          </Switch>
          <Route path={ROUTE_PATH} component={ClientName} />
        </div>
        <div className='navbar-content-right'>
          <Route path={ROUTE_PATH} component={EngagementNavItems} />
          <AccountMenu onLogout={props.onLogout} />
        </div>
      </div>
      {dialog && (
        <WarningDialog
          info={`Once you navigate away from the client view your view will default back to your RSM View`}
          onClickPrimary={onDialogClose}
          primaryButtonText={'YES, PROCEED'}
          onClickSecondary={onDialogCancel}
          onClose={onDialogCancel}
          title='You are about to leave Client View Mode'
        />
      )}
    </div>
  )
}

let nextId = 0
const ROUTE_PATH = [
  '/masterclient/:masterClientId/:formName/:taxYear/engagements/:engagementId',
  '/engagements/:engagementId'
]

interface NavBarState {
  dialog: boolean
}

//interface NavBarProps extends RouteComponentProps<{}> {}

class Navbar extends React.Component<{}, NavBarState> {
  constructor(props: any) {
    super(props)
    this.state = {
      dialog: false
    }
  }

  id = `tsaHubNavbar${++nextId}`

  static contextTypes = {
    logOut: PropTypes.func
  }

  handleLogOut = () => {
    const logOut = this.context && this.context.logOut
    if (logOut) {
      logOut()
    }
  }

  render() {
    if (window.__SIMULATE_ERROR__ === 'navbar') {
      throw new Error('Navbar simulated error')
    }

    return <NavbarInternal onLogout={this.handleLogOut} />
  }
}

export default Navbar

/** Set of "reasons" why a URL banner filter is or is no valid. */
export type BannerFilterValidationStates =
  | 'valid'
  | 'inconclusive'
  | 'engagement-mismatch-filter'
  | 'invalid-filter'
  | 'selection-mismatch'

/** Returned by the isBannerFilterUrlCorrect method.  Provides a boolean value
 *   indicating whether or not the URL filter is valid, and a reason why it is or is not. */
export interface BannerFilterValidationResult {
  state: BannerFilterValidationStates
  isValid: boolean
}

/** Constant values for the isBannerFilterUrlCorrect return values. */
export const validationResultOptions = {
  valid: { state: 'valid', isValid: true } as BannerFilterValidationResult,
  inconclusive: {
    state: 'inconclusive',
    isValid: true
  } as BannerFilterValidationResult,
  mismatch: {
    state: 'engagement-mismatch-filter',
    isValid: false
  } as BannerFilterValidationResult,
  invalid: {
    state: 'invalid-filter',
    isValid: false
  } as BannerFilterValidationResult,
  selectedFilterNotSetToUrlFilter: {
    state: 'selection-mismatch',
    isValid: false
  } as BannerFilterValidationResult
}

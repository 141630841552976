import * as React from 'react'
import Icon from '../icon/icon'
import Modal from './modal'

interface WarningProps {
  additional?: string
  info: string
  onClickPrimary: () => void
  onClickSecondary?: () => void
  onClose: () => void
  primaryButtonText?: string
  secondaryButtonText?: string
  title: string
}

const WarningDialog: React.SFC<WarningProps> = ({
  additional,
  info,
  onClickPrimary,
  onClickSecondary,
  onClose,
  primaryButtonText,
  secondaryButtonText,
  title,
}) => (
  <Modal>
    <div className='modal warning'>
      <div className='modal-body'>
        <button
          id='modal-button-close'
          type='button'
          className='close'
          aria-label='Close'
          onClick={onClose}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
        <div className='modal-icon'>
          <Icon icon='warning' />
        </div>
        <div className='modal-title'>{title}</div>
        <div className='modal-info'>{info}</div>
        <div className='modal-additional'>{additional}</div>
        <div className='modal-buttons'>
          {onClickSecondary && (
            <button
              id='modal-secondary-button'
              className='btn'
              onClick={onClickSecondary}
            >
              {secondaryButtonText}
            </button>
          )}
          <button
            id='modal-primary-button'
            className='btn btn-primary'
            autoFocus={true}
            onClick={onClickPrimary}
          >
            {primaryButtonText}
          </button>
        </div>
      </div>
    </div>
  </Modal>
)

WarningDialog.defaultProps = {
  primaryButtonText: 'OK',
  secondaryButtonText: 'Cancel',
}

export default WarningDialog

import { Theme } from '@rsmus/react-ui'
import clsx from 'clsx'
import React from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import { createUseStyles, useTheme } from 'react-jss'

function styles({ palette, typogrphaphy, transitions }: Theme) {
  return {
    item: {
      display: 'block',
      color: palette.common.white,
      padding: '1rem 1.5rem',
      fontSize: '0.875rem',
      transition: transitions.create('background-color'),
      '&:hover': {
        color: palette.common.white,
        textDecoration: 'none',
      },
    },
    logout: {
      display: 'block',
      backgroundColor: palette.grey[600],
      fontSize: '1.375rem',
      textTransform: 'uppercase',
      textAlign: 'center',
      ...typogrphaphy.bold,
      '&:hover': {
        color: palette.common.white,
        backgroundColor: palette.grey[700],
      },
    },
    logoutText: {
      fontSize: '0.875rem',
      marginLeft: '.75rem',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface LogOutProperties {
  onLogout: () => void
}

const LogOut = ({ onLogout }: LogOutProperties) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const logoutClassName = clsx(classes.item, classes.logout)

  const handleLogout = () => {
    onLogout()
  }

  return (
    <a
      href='#logout'
      className={logoutClassName}
      id='log-out-button'
      onClick={handleLogout}
    >
      <FaSignOutAlt />
      <span className={classes.logoutText}>Log Out</span>
    </a>
  )
}

export default LogOut

import { TsaHubAction } from '../actions/index'
import { getType } from 'typesafe-actions'
import {
  setIsExternalAction,
  setPermissionsAction,
} from '../actions/permissionActions'

export interface PermissionsState {
  isExternalUser: boolean
  permissions: string[]
}

const initialState = {
  isExternalUser: true,
  permissions: [],
}

export function permissionsReducer(
  state: PermissionsState = initialState,
  action: TsaHubAction
): PermissionsState {
  switch (action.type) {
    default:
      return state

    case getType(setIsExternalAction):
      return { ...state, isExternalUser: action.payload }
    case getType(setPermissionsAction):
      return { ...state, permissions: action.payload }
  }
}

import { History, Location } from 'history'
import * as React from 'react'
import { connect } from 'react-redux'
import { match, RouteComponentProps } from 'react-router'
import { EngagementQuestionMap, QuestionMap } from '../../clientModels'
import { getEngagementQuestions } from '../../reducers/selectors'
import { AppState } from '../../store'
import { joinPaths } from '../relativeLink'
import './flagLibrary.scss'
import FlagSummary from './flagSummary'

interface FlagLibraryOwnProps extends RouteComponentProps<RouteProperties> {}

interface RouteProperties {
  engagementId: string
  questionId?: string
}

interface FlagLibraryMappedProperties {
  questions: QuestionMap
  engagementQuestions: EngagementQuestionMap | undefined
  history: History
  location: Location
  match: match<RouteProperties>
}

type Properties = FlagLibraryOwnProps & FlagLibraryMappedProperties

function mapStateToProps(
  state: AppState,
  props: FlagLibraryOwnProps & RouteComponentProps<RouteProperties>
): FlagLibraryMappedProperties {
  const engagementId = props.match.params.engagementId

  const result: FlagLibraryMappedProperties = {
    history: props.history,
    match: props.match,
    location: props.location,
    questions: state.questions,
    engagementQuestions: getEngagementQuestions(state, engagementId),
  }

  return result
}

interface FlaggedQuestions {
  questionId: number
  displayOrder: number
  openPath: string
}

interface State {
  engagementId: number
  questionId?: number
  flaggedQuestions: FlaggedQuestions[]
  openItems: number
}

class FlagLibrary extends React.Component<Properties, State> {
  state: State

  constructor(props: Properties) {
    super(props)

    this.parseProps = this.parseProps.bind(this)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onViewContext = this.onViewContext.bind(this)
    this.openAddItem = this.openAddItem.bind(this)
    this.closeRemoveItem = this.closeRemoveItem.bind(this)
    this.afterClose = this.afterClose.bind(this)

    const {
      match: {
        params: { engagementId, questionId },
      },
    } = props

    this.state = {
      engagementId: parseInt(engagementId, 10),
      questionId: questionId ? parseInt(questionId, 10) : undefined,
      flaggedQuestions: [],
      openItems: 0,
    }

    this.parseProps(this.state, props)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: Properties) {
    const { props, state } = this
    const nextState = { ...state }
    let updated = false

    if (
      props.match.params.engagementId !== nextProps.match.params.engagementId
    ) {
      nextState.engagementId = parseInt(nextProps.match.params.engagementId, 10)
      updated = true
    }

    if (props.match.params.questionId !== nextProps.match.params.questionId) {
      const nextQuestionId = nextProps.match.params.questionId
      nextState.questionId = nextQuestionId
        ? parseInt(nextQuestionId, 10)
        : undefined
      updated = true
    }

    if (
      props.engagementQuestions !== nextProps.engagementQuestions ||
      props.questions !== nextProps.questions ||
      state.engagementId !== nextState.engagementId
    ) {
      this.parseProps(nextState, nextProps)
      updated = true
    }

    if (updated) {
      this.setState(nextState)
    }
  }

  parseProps(state: State, props: Properties) {
    if (!props.engagementQuestions) {
      state.flaggedQuestions = []
      return
    }
    const { match } = props
    const flaggedQuestions: FlaggedQuestions[] = []
    for (const questionId in props.engagementQuestions) {
      const eq = props.engagementQuestions[questionId]
      const q = props.questions[questionId]
      if (!eq || !q || !eq.flagged || !eq.isVisible) {
        continue
      }
      flaggedQuestions.push({
        openPath: joinPaths(match.url, `flags/questions/${questionId}`),
        questionId: q.id,
        displayOrder: q.displayOrder,
      })
    }
    state.flaggedQuestions = flaggedQuestions.sort(
      (a, b) => a.displayOrder - b.displayOrder
    )
  }

  onOpen(questionId: number) {
    const {
      openAddItem,
      props: { history, match },
    } = this
    history.replace(joinPaths(match.url, `flags/questions/${questionId}`))
    this.setState(openAddItem)
  }

  openAddItem({ openItems }: State) {
    this.setState({ openItems: openItems + 1 })
  }

  onClose(questionId: number) {
    const { closeRemoveItem, afterClose } = this
    setTimeout(() => {
      this.setState(closeRemoveItem, afterClose)
    }, 0)
  }

  closeRemoveItem({ openItems }: State) {
    this.setState({ openItems: openItems > 0 ? openItems - 1 : 0 })
  }

  afterClose() {
    const {
      state: { openItems },
      props: { history, match },
    } = this
    if (openItems === 0) {
      history.replace(
        joinPaths(match.url, `engagements/${match.params.engagementId}/flags`)
      )
    }
  }

  onViewContext(questionId: number, sectionId: number) {
    const {
      state: { engagementId },
      props: { history, match },
    } = this
    const url = joinPaths(
      match.url,
      `engagements/${engagementId}/sections/${sectionId}/questions/${questionId}`
    )
    history.push(url)
  }

  render() {
    const {
      onOpen,
      onClose,
      onViewContext,
      state: { flaggedQuestions, engagementId },
    } = this

    if (flaggedQuestions.length === 0) {
      return (
        <div className='flag-library-no-flags'>You have no flagged items.</div>
      )
    }

    return (
      <div className='flag-library'>
        {flaggedQuestions.map((fq, i) => (
          <FlagSummary
            key={i}
            engagementId={engagementId}
            questionId={fq.questionId}
            openPath={fq.openPath}
            onOpen={onOpen}
            onClose={onClose}
            onViewContext={onViewContext}
          />
        ))}
      </div>
    )
  }
}

export default connect(mapStateToProps)(FlagLibrary)

/**
 * A module for basic URL utilities
 */

function joinWithSlash(url: string, segment: string | number) {
  segment = segment + ''
  if (!segment) {
    return url
  }
  const last = url.substr(-1)
  const next = segment.substr(0, 1)
  if (last === '/' && next === '/') {
    url = url.substr(0, url.length - 1) + segment
  } else if (last !== '' && last !== '/' && next !== '/') {
    url = url + '/' + segment
  } else {
    url += segment
  }
  return url
}

export function joinSegments(...segments: Array<string | number>) {
  return segments.reduce(joinWithSlash, '') + ''
}

export interface QueryStringParameters {
  [key: string]: string | number
}

export function queryString(params: QueryStringParameters) {
  let query = '?'
  Object.keys(params)
    .sort()
    .forEach(key => {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        if (query !== '?') {
          query += '&'
        }
        query += `${key}=${params[key]}`
      }
    })
  return query
}

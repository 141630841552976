import * as React from 'react'
import { Opener, OpenerInjectedProps } from './opener'

type OpenerContentRenderer = (props: Opener) => React.ReactChild

interface OpenerContentProps extends OpenerInjectedProps {
  children?: OpenerContentRenderer | React.ReactNode
}

class OpenerContent extends React.Component<OpenerContentProps> {
  render () {
    const { opener } = this.props
    // tslint:disable-next-line:no-any
    const children: any = this.props.children
    if (typeof children === 'function') {
      return children(opener)
    }
    return children || <div />
  }
}

export default OpenerContent

import { TsaHubAction } from '../actions/index'
import { getType } from 'typesafe-actions'
import { generalAction } from '../actions/generalActions'

export interface GeneralState {
  approvedMimeTypes: string
  approvedMimeTypeExtensions: string
  approvedTemplateMimeTypes: string
  approvedTemplateMimeTypeExtensions: string
}

const initialState: GeneralState = {
  approvedMimeTypes: '',
  approvedMimeTypeExtensions: '',
  approvedTemplateMimeTypes: '',
  approvedTemplateMimeTypeExtensions: '',
}

export function generalReducer (
  state: GeneralState = initialState,
  action: TsaHubAction
): GeneralState {
  switch (action.type) {
    default:
      break

    case getType(generalAction.success):
      state = {
        approvedMimeTypes: action.payload.approvedMimeTypes.join(','),
        approvedMimeTypeExtensions: action.payload.approvedMimeTypeExtensions.join(
          ','
        ),
        approvedTemplateMimeTypes: action.payload.approvedTemplateMimeTypes.join(
          ','
        ),
        approvedTemplateMimeTypeExtensions: action.payload.approvedTemplateMimeTypeExtensions.join(
          ','
        ),
      }
      break
  }

  return state
}

import * as React from 'react'
import Modal from '../modals/modal'
import { useTheme, Theme, createStyles } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'
import { FaExclamationCircle } from 'react-icons/fa'

const styles = ({ palette }: Theme) =>
  createStyles({
    reportError: {
      '& .modal-icon': {
        color: palette.informational.red,
      },
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface ExportProps {
  onClose: () => void
  onSubmit: () => void
  errorText?: string
}

const DuoExportDialog: React.SFC<ExportProps> = ({
  onClose,
  onSubmit,
  errorText,
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  let rootClasses = errorText
    ? `modal report ${classes.reportError}`
    : 'modal report'

  return (
    <Modal>
      <div className={rootClasses}>
        <div className='modal-body'>
          <button
            id='modal-button-close'
            type='button'
            className='close'
            aria-label='Close'
            onClick={onClose}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
          <div className='modal-icon'>
            <FaExclamationCircle fontSize={'4rem'} color={'#f0b533'} />
          </div>
          <div className='modal-title'>Submit to DUO</div>
          <div className='modal-info'>
            Are you sure you want to submit this engagement data to DUO?
          </div>
          <div className='modal-buttons'>
            <button
              id='modal-cancel-button'
              className='btn btn-primary'
              autoFocus={true}
              onClick={onClose}
            >
              CANCEL
            </button>
            <button
              id='modal-submit-button'
              className='btn btn-primary'
              autoFocus={true}
              onClick={onSubmit}
            >
              YES, SUBMIT TO DUO
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DuoExportDialog

import moment from 'moment'
import React, { useRef } from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { ApiModels } from '../../services/api/index'
import { Grid } from '../layout'
import Column from '../layout/column'
import Row from '../layout/row'
import { Theme } from '@rsmus/react-ui'
import Icon from '../icon/icon'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

function styles({ palette }: Theme) {
  return {
    fileVersionList: {
      '& a': {
        color: '#FFFFFF !important',
      },
    },
    createdDate: {
      whiteSpace: 'nowrap',
    },
    icon: {
      fill: palette.common.white,
      stroke: palette.common.white,
      strokeWidth: '3rem',
      marginBottom: '0.3rem',
      position: 'relative',
      right: '-0.5rem',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface FileVersionListProps {
  files: ApiModels.File[]
  handleChange: (file: ApiModels.File) => void
  onClickDownload?: (id: number) => void
  displayMenu: (display: boolean) => void
}

const addedDatePhrase = (dt?: string): string => {
  const added = moment(dt, moment.ISO_8601)
  return added.format('MM/DD/YYYY LT')
}

const max = (numbers: number[]): number => {
  return numbers.reduce((a, b) => Math.max(a, b))
}

const FileVersionList: React.FunctionComponent<FileVersionListProps> = ({
  files,
  handleChange,
  onClickDownload,
  displayMenu,
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => {
    displayMenu(false)
  })
  const filesFound = files.length > 0

  const currentVersionId = max(files.map(x => x.versionId || 0))

  function getFileListRow(file: ApiModels.File) {
    const handleClick = (e: any) => {
      e.preventDefault()
      onClickDownload && onClickDownload(file.id)
    }

    return file ? (
      <Row key={file.id}>
        <Column>
          <a href={`/api/Files/${file.id}/Download`} onClick={handleClick}>
            {file.name}
          </a>
        </Column>
        <Column className={classes.createdDate}>
          {file.userProfile
            ? `by ${file.userProfile.lastName}, ${file.userProfile.firstName}`
            : ''}
        </Column>
        <Column className={classes.createdDate}>
          {addedDatePhrase(file.updatedDate || file.createdDate)}
        </Column>
        <Column>
          <label>
            <input
              type='radio'
              name={`current_${file.parentId}`}
              value={file.versionId}
              defaultChecked={file.versionId === currentVersionId}
              onChange={() => handleChange(file)}
            />
            Make Current
          </label>
        </Column>
      </Row>
    ) : (
      <React.Fragment />
    )
  }

  return (
    <div ref={wrapperRef} className={classes.fileVersionList}>
      <Grid>
        <Row>
          <Column>
            <Icon
              className={classes.icon}
              icon='close'
              active={true}
              tooltip=''
              onClick={() => displayMenu(false)}
            />
          </Column>
        </Row>
        {filesFound ? (
          <div className='file-version-rows'>
            {files.map(file => {
              return getFileListRow(file)
            })}
          </div>
        ) : (
          <span className='no-files-found'>No files found!</span>
        )}
      </Grid>
    </div>
  )
}

export default FileVersionList

import { getType } from 'typesafe-actions'
import { uploadEngagementSetup } from '../actions/fileActions'
import { TsaHubAction } from '../actions/index'
import { EngagementUploadHistory } from '../clientModels'

const initialState: EngagementUploadHistory[] = []

export function engagementUploadHistoryReducer (
  state: EngagementUploadHistory[] = initialState,
  action: TsaHubAction
): EngagementUploadHistory[] {
  switch (action.type) {
    case getType(uploadEngagementSetup.success):
      return { ...state, ...action.payload }

    default:
      return state
  }
}

import { K1Api } from '../services/api'
import { TsaThunkAction } from '../store'
import * as actions from './k1Actions'
import {
  httpBeginAction as startBusy,
  httpEndAction as stopBusy,
} from './httpActions'

export const K1HeaderBusyIndicator = 'k1header-busy'

export const deleteK1Headers = (
  k1HeaderIds: number[]
): TsaThunkAction => async dispatch => {
  try {
    dispatch(startBusy({ id: K1HeaderBusyIndicator }))

    dispatch(actions.deleteK1HeadersAction.request())
    await K1Api.deleteK1Headers(k1HeaderIds)
    dispatch(actions.deleteK1HeadersAction.success(k1HeaderIds))
  } catch (error) {
    dispatch(actions.deleteK1HeadersAction.failure())
  }
  dispatch(stopBusy({ id: K1HeaderBusyIndicator }))
}

export const submitK1sToCch = (
  engagementId: number,
  k1Ids: number[],
): TsaThunkAction<Promise<any>> => async dispatch => {
  dispatch(actions.submitK1ToCchAction.request())
  try {
    await K1Api.submitK1ToCch(engagementId, k1Ids)
    dispatch(actions.submitK1ToCchAction.success())
  } catch (error) {
    dispatch(actions.submitK1ToCchAction.failure(error))
  }
}

import { Theme } from '@rsmus/react-ui'
import clsx from 'classnames'
import React, { useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { getEngagementList } from '../../actions/engagementThunks'
import { Engagement } from '../../clientModels'
import { useRouteParams } from '../../hooks'
import { AppState } from '../../store'
import { engagementsSelector } from '../engagementsList/engagementSelector'
import { NavTabs } from '../navTabs'
import { Tab } from '../navTabs/NavTabs'
import ClientGroupDropdown from './ClientGroupDropdown'
import {
  FilterNodeTree,
  getFilterNodeTrees
} from '../../services/filterHelpers'
import { getBannerFilters } from '../../reducers/selectors'
import {
  updateBannerFilters,
  fixSelectedFilter
} from '../../actions/bannerFilterThunks'
import * as actions from '../../actions/bannerFilterActions'
import { PhaseCode } from '../../enums'

function styles(theme: Theme) {
  return {
    entityNavHeaderRoot: {
      display: 'flex',
      backgroundColor: theme.palette.grey[600],
      paddingTop: '0.5rem',
      paddingLeft: '3rem',
      [theme.breakpoints.lg]: {
        paddingLeft: '4rem'
      },
      [theme.breakpoints.xl]: {
        paddingLeft: '5rem'
      }
    }
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface EntityNavHeaderProps {
  className?: string
}

const MasterClientNavHeader = ({ className }: EntityNavHeaderProps) => {
  const location = useLocation()
  const engagements = useSelector((state: AppState) =>
    engagementsSelector(state)
  )
  const history = useHistory()
  const { masterClientId, formName, taxYear } = useRouteParams()
  const bannerFilter = useSelector((state: AppState) => state.bannerFilter)
  const clients = useSelector((state: AppState) => state.clients)
  const [viewableEngagements, setViewableEngagements] = useState<Engagement[]>(
    engagements
  )
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()

  // Boolean value indicating whether or not the filters are loading from the server.
  const isLoadingFilters = useSelector(
    (state: AppState) => state.bannerFilter.isLoading
  )

  // Determine if user is an external client user,
  // This will affect the rendered content
  const isExternalUser = useSelector(
    (state: AppState) => !!state.auth.user?.isExternal
  )

  // Filters from the server.
  const filters = useSelector(getBannerFilters)

  // Load the engagement filters from the server:
  useEffect(() => {
    dispatch(updateBannerFilters())
  }, [dispatch])

  // Set the selectedFilter, and ensure the engagements
  //  are up-to-date for the selected filter:
  useEffect(() => {
    // We can only do this if we have filters.
    if (filters) {
      // If we're missing part of the filter path, we can't do this.
      if (masterClientId && formName && taxYear) {
        // Set the active filter to a specific filter, based on the route parameters:
        // Get the logical tree for the filters, so we can do a simple search.
        const trees = getFilterNodeTrees(filters)

        // Find a match between the URL and the filters.
        const filterMatch =
          trees.find(
            t =>
              t.masterClientId.id === masterClientId &&
              t.taxForm.label === formName &&
              t.taxYear.label === taxYear
          ) ?? null

        // Set the selected filter, based on our findings.
        dispatch(actions.selectBannerFilter(filterMatch?.filterList ?? null))
      } else {
        // We're missing part of the filter defined in the URL.  We can't find a selection without all parts.
        dispatch(actions.selectBannerFilter(null))
      }
    } else {
      // We don't have any filters - so no selected filter at the moment.
      dispatch(actions.selectBannerFilter(null))
    }

    // Ensure the engagements are up-to-date for this filter.
    dispatch(getEngagementList(masterClientId, false))
  }, [dispatch, filters, masterClientId, formName, taxYear])

  // Ensure the existing filter is valid for the selected engagement and/or
  //  the available filters for this user.
  useEffect(() => {
    dispatch(fixSelectedFilter(history))
  }, [dispatch, location.pathname, history, clients, engagements])

  // Set the visibleEngagements:
  useEffect(() => {
    if (bannerFilter.selectedFilter) {
      // Get around a faulty TS issue.
      const selectedFilter = bannerFilter.selectedFilter!
      // We can only filter the engagements if we have a selected filter.
      setViewableEngagements(
        engagements.filter(
          // The selected filter is the array of 3 nodes (master Id, form, year).  The 3rd node
          //  has the IDs of the engagements that match the filter.  They come from the server this way.
          e => e.visible && selectedFilter[2].ids!.includes(e.id)
        )
      )
    } else {
      setViewableEngagements(engagements)
    }
  }, [bannerFilter.selectedFilter, engagements])

  // Function to update the filter when the user clicks a new filter.
  const userClickedFilter = (filter: FilterNodeTree | null) => {
    if (filter) {
      // Set the url to reflect this new filter.
      history.push(filter.filterPath)
    } else {
      // Remove the filter from the path - if there is one.
      const newPath = location.pathname.replace(
        /masterclient\/\d+\/[\w-]+\/\d{4}\//,
        ''
      )

      // If the paths are different, then navigate to the new path.
      //  Otherwise, we exit without doing anything.  (no location difference)
      if (newPath !== location.pathname) {
        history.push(newPath)
      }
    }
  }

  const rootClassName = clsx(className, classes.entityNavHeaderRoot)
  return (
    <div className={rootClassName}>
      <ClientGroupDropdown
        filters={filters}
        isLoading={isLoadingFilters}
        setSelectedFilter={userClickedFilter}
      />
      {bannerFilter.selectedFilter && (
        <NavTabs
          tabs={getEngagementNavTabs(
            location.pathname,
            isExternalUser,
            viewableEngagements
          )}
        />
      )}
    </div>
  )
}

export default MasterClientNavHeader

function getEngagementNavTabs(
  url: string,
  isExternalUser: boolean,
  engagements?: Engagement[]
) {
  const urlContainsEngagement = url.search('engagements/') >= 0
  let tabs: Tab[] = []

  if (!engagements) {
    return tabs
  }

  engagements.forEach((engagement: Engagement) => {
    const eId = engagement.id
    const updatedUrl = urlContainsEngagement
      ? url.replace(/\/engagements\/[^/]+/i, '/engagements/' + eId)
      : `${url}/engagements/${eId}`

    // Ensure nav tabs are not created for external client users when in the setup phase
    // They do not have access at this point
    if (
      isExternalUser &&
      engagement.phase &&
      engagement.phase === PhaseCode.Setup
    ) {
      return
    }

    tabs.push({
      title: `${engagement.name || ''}`,
      path: updatedUrl
    })
  })

  tabs = tabs.sort((a, b) => (a.title > b.title ? 1 : -1))

  return tabs
}

import { QuestionContainer } from '../question/questionContainer'
import asSummary from '../summary/summary'

export interface SummaryProps {
  engagementId: number
  questionId: number
  unitId: number
  openPath?: string
  onOpen: (questionId: number, commentId: number) => void
  onClose: (questionId: number, commentId: number) => void
  onViewContext: (questionId: number, sectionId: number) => void
}

export const SummaryQuestion = asSummary({ groupName: 'CarryForwardLibrary' })(
  QuestionContainer
)

import { QueryStringParameters, joinSegments, queryString } from '../url'
import { REACT_APP_HOSTURL, REACT_APP_TSAAGGREGATORURL, REACT_APP_TSAAPIBASEURL } from '../../envVariables'

const DEFAULT_API_VERSION = '1.0'
const API_ROUTE = ''

let apiVersion = DEFAULT_API_VERSION

export function setApiVersion(version: string) {
  apiVersion = version
}

export function getApiVersion() {
  return apiVersion
}

export function buildApiUrl(path: string, params?: QueryStringParameters) {
  return buildUrl(path, REACT_APP_TSAAPIBASEURL ? REACT_APP_TSAAPIBASEURL : '', params)
}

export function buildAggregatorUrl(
  path: string,
  params?: QueryStringParameters
) {
  return buildUrl(path, REACT_APP_TSAAGGREGATORURL ? REACT_APP_TSAAGGREGATORURL : '', params)
}

function buildUrl(path: string, route: string, params?: QueryStringParameters) {
  if (path.indexOf('?') > -1) {
    throw new Error('Search parameters must be passed in the params parameter.')
  }

  let url = isSignalRPath(path)
    ? joinSegments(route, path)
    : joinSegments(route, `v${apiVersion}`, path)

  if (params) {
    url += queryString(params)
  }

  return url
}

function isSignalRPath(path: string) {
  return /^\/?hub/i.test(path)
}

import * as http from '../http'
import { buildApiUrl } from './apiUrl'

export function odataGetEngagementUploadHistories() {
  return http.get(buildApiUrl('EngagementUploadHistories/latest'))
}

/*
// implement filtering server side
export const odataGetEngagementUploadHistories = () =>
  odata
    .engagementUploadHistories()
    .orderBy({ property: 'fileId', sortOrder: 'desc' })
    .top(1)
    .expand('engagementUploadHistoryStatuses')
    .expand('file', file => file.select('createdDate', 'description', 'name'))
    .all()

*/

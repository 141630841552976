import * as React from 'react'
import ReactDataSheet from 'react-datasheet'
import {
  optionsListLookup,
  TsaJsonSchema,
} from '../../services/answerSchema/index'
import {
  objectToOptions,
  optionsToObject,
  optionToObject,
} from '../forms/formUtilities'
import { ComboBox } from '../forms/index'
import {
  EditCellComponentFactory,
  EditCellComponentFactoryLookup,
} from './cellComponentFactory'
import { GridElement } from './component'
import { DatasheetProps } from './datasheet'
import { findOptionValue } from './datasheetUtilities'
import { DateEditor } from './dateEditor'
import { NumberEditor } from './numberEditor'
import { TextAreaEditor } from './textAreaEditor'
import { TextBoxEditor } from './textBoxEditor'

export const leadingZeros = new RegExp('^([+-]?)0+([^0].*|0)$')
export type EditorProps = ReactDataSheet.DataEditorProps<GridElement>

function selectOrCombo (allowUserInput: boolean, allowMultiInput: boolean) {
  return (propertySchema: TsaJsonSchema, datasheetProps: DatasheetProps) => {
    return class extends React.PureComponent<EditorProps> {
      keyEvent?: React.KeyboardEvent<HTMLElement>
      // tslint:disable-next-line:no-any
      objectValue: any

      constructor (props: EditorProps) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
      }

      async handleChange (value: any) {
        const {
          keyEvent,
          props: { onCommit },
        } = this
        if (!onCommit) {
          this.keyEvent = undefined
          return
        }
        if (Array.isArray(value)) {
          this.objectValue = optionsToObject(value)
        } else {
          this.objectValue = optionToObject(value)
        }
        onCommit(this.objectValue, keyEvent)
        this.keyEvent = undefined
      }

      handleKeyDown (e: React.KeyboardEvent<HTMLElement>) {
        switch (e.key) {
          case 'Enter':
          case 'Tab':
            e.persist()
            this.keyEvent = e
            break
          case 'Escape':
            this.props.onRevert()
            break
          default:
            this.keyEvent = undefined
        }
      }

      handleBlur () {
        const {
          keyEvent,
          objectValue,
          props: { onCommit },
        } = this
        if (keyEvent && objectValue) {
          onCommit(objectValue, keyEvent)
        } else {
          this.props.onRevert()
        }
      }

      render () {
        const {
          handleBlur,
          handleChange,
          handleKeyDown,
          props: { value },
        } = this
        const { codeListIdToOptionsId, optionLists } = datasheetProps
        const options = optionsListLookup(
          propertySchema,
          codeListIdToOptionsId,
          optionLists
        )
        // tslint:disable-next-line:no-any
        const val =
          typeof value === 'string'
            ? findOptionValue(options, value)
            : allowMultiInput
              ? objectToOptions(value as any)
              : objectToOptions(value as any)[0]
        return (
          <ComboBox
            className='grid-select'
            value={val}
            onChange={handleChange}
            onKeyPress={handleKeyDown}
            onBlur={handleBlur}
            autoFocus={true}
            jsonSchema={propertySchema}
            options={options}
            allowUserInput={allowUserInput}
            multiSelect={allowMultiInput}
          />
        )
      }
    }
  }
}

const CellEditorComponentFactories: EditCellComponentFactoryLookup = {
  date: DateEditor,
  number: NumberEditor,
  select: selectOrCombo(false, false),
  multiselect: selectOrCombo(false, true),
  combobox: selectOrCombo(true, false),
  multicombobox: selectOrCombo(true, true),
  textarea: TextAreaEditor,
  text: TextBoxEditor,
}

export function getCellEditorComponentClassFactory (
  component: string = ''
): EditCellComponentFactory {
  return CellEditorComponentFactories[component] || TextBoxEditor
}

import { assign, compact, get } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import displayName from '../../displayName'
import { isObject, isString } from '../../guards'
import {
  getSchemaForPath,
  optionsListLookup,
} from '../../services/answerSchema/index'
import { OptionsFormFieldProps } from './formComponentsInterfaces'

export const formField = <TProps extends OptionsFormFieldProps>(
  WrappedComponent: React.ComponentType<TProps>
) => {
  return class OptionsLookup extends React.Component<TProps> {
    static displayName = displayName('Field', WrappedComponent)

    // tslint:disable-next-line:no-any - value can be anything
    static formatValueLastYear = (value: any, props: any): JSX.Element => {
      // tslint:disable-next-line:no-any - value can be anything
      const component = WrappedComponent as any
      if (component.formatValueLastYear) {
        return component.formatValueLastYear(value, props)
      }
      // this is a temp fix because the combobox specific method is not getting called. because it is being wrapped.
      if (isObject(value)) {
        let result
        if (Object.values(value).toString()) {
          result = compact(Object.values(value)).join(' ● ') // this is present the display.
        } else {
          result = Object.getOwnPropertyNames(value).toString()
        }
        return <span>{result}</span>
      }

      // For dates
      const stringValue = value as string
      const dateValue = moment(new Date(stringValue))
      if (
        stringValue &&
        dateValue.isValid() &&
        stringValue.indexOf(':00:00.000Z') > -1
      ) {
        return <span>{dateValue.format('MM/DD/YYYY')}</span>
      }
      return value
    }

    render () {
      const {
        options,
        optionLists,
        codeListIdToOptionsId,
        jsonSchema,
        prefix,
        value,
      } = this.props
      let path = this.props.path

      if (prefix && isString(path)) {
        path = prefix + path
      }

      const fieldSchema = getSchemaForPath(jsonSchema, path)
      const fieldOptions = optionsListLookup(
        fieldSchema,
        codeListIdToOptionsId,
        optionLists
      )

      const fieldLabel = (fieldSchema && fieldSchema.title) || this.props.label

      let fieldValue = value
      if (isString(path) && isObject(value)) {
        fieldValue = get(value, path)
      }

      return (
        <WrappedComponent
          {...assign({}, this.props, { path })} // this.props is immutable
          jsonSchema={fieldSchema}
          label={fieldLabel}
          options={fieldOptions || options}
          value={fieldValue}
        />
      )
    }
  }
}

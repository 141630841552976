import { TsaHubAction } from '../actions'
import { getType } from 'typesafe-actions'
import {
  signalRConnectingAction,
  signalRConnectedAction,
  signalRDisconnectedAction,
  signalRMessageReceived,
} from '../actions/signalRActions'

export interface SignalRState {
  connecting: boolean
  connected: boolean
  message?: string
}

const initialState: SignalRState = {
  connected: false,
  connecting: false,
}

export function signalRReducer(
  state: SignalRState = initialState,
  action: TsaHubAction
): SignalRState {
  switch (action.type) {
    case getType(signalRConnectingAction): {
      return {
        ...state,
        connecting: true,
        connected: false,
      }
    }
    case getType(signalRConnectedAction): {
      return {
        ...state,
        connecting: false,
        connected: true,
      }
    }
    case getType(signalRDisconnectedAction): {
      return {
        ...state,
        connecting: false,
        connected: false,
      }
    }
    case getType(signalRMessageReceived): {
      return {
        ...state,
        message: action.payload,
      }
    }
    default:
      break
  }
  return state
}

import * as React from 'react'
import { Route, Switch } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { AssistanceBody } from '../../components/HelpAssistance'
import { GlossaryBody } from '../../components/HelpGlossary'
import { WhatsNewBody } from '../../components/whatsNew'
import './help.scss'

interface OwnProperties extends RouteComponentProps<{}> {}

class Help extends React.Component<OwnProperties> {
  render() {
    if (window.__SIMULATE_ERROR__ === 'content') {
      throw new Error('Content (help) simulated error')
    }

    return (
      <div className='help'>
        <Switch>
          <Route
            key='glossary'
            path={[
              '/help/glossary/formName/:formName/taxYear/:taxYear',
              '/help/glossary',
            ]}
            component={GlossaryBody}
          />
          <Route
            key='assistance'
            path='/help'
            component={AssistanceBody}
            exact={true}
          />
          <Route
            key='whatsnew'
            path='/help/whatsnew'
            component={WhatsNewBody}
            exact={true}
          />
        </Switch>
      </div>
    )
  }
}

export default Help

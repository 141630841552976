import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { Theme, useTheme, Button } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'

function styles({ palette }: Theme) {
  return {
    permissions: {
      width: '100%',
    },
    applyButton: {
      marginRight: '1rem',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const DebugUserPermissions = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const user = useSelector((state: AppState) => state.auth.user)
  const [userPermissions, setUserPermissions] = useState<string>(
    user?.permissions?.join(', ') ?? ''
  )
  const [impersonationPermissions, setImpersonationPermissions] = useState<
    string
  >(user?.impersonationPermissions?.join(', ') ?? '')

  const applyPermissions = () => {
    sessionStorage.setItem('DebugPermissions', 'true')
    sessionStorage.setItem('UserPermissions', userPermissions)
    sessionStorage.setItem('ImpersonationPermissions', impersonationPermissions)

    window.location.reload(false)
  }

  const resetPermissions = () => {
    sessionStorage.setItem('DebugPermissions', 'false')
    sessionStorage.removeItem('UserPermissions')
    sessionStorage.removeItem('ImpersonationPermissions')

    window.location.reload(false)
  }

  const onUserPermissionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setUserPermissions(event?.target.value ?? '')
  }

  const onImpersonationPermissionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setImpersonationPermissions(event?.target.value ?? '')
  }

  return (
    <>
      <h1>Permissions</h1>
      <dl className='row'>
        <dd className='col-sm-4'>Your permissions</dd>
        <dt className='col-sm-8'>
          <textarea
            className={classes.permissions}
            onChange={onUserPermissionChange}
            value={userPermissions}
          ></textarea>
        </dt>
      </dl>
      <dl className='row'>
        <dd className='col-sm-4'>Impersonation permissions</dd>
        <dt className='col-sm-8'>
          <textarea
            className={classes.permissions}
            onChange={onImpersonationPermissionChange}
            value={impersonationPermissions}
          ></textarea>
        </dt>
      </dl>
      <div>
        <Button
          title='Apply Permissions'
          className={classes.applyButton}
          onClick={applyPermissions}
        >
          Apply Permissions
        </Button>
        <Button title='Reset' onClick={resetPermissions}>
          Reset
        </Button>
      </div>
    </>
  )
}
export default DebugUserPermissions

import { ActionType, createAsyncAction, createAction } from 'typesafe-actions'

export const clearFilterAction = createAction(
  'CLEAR_K1_FILTERS'
)<void>()

export const deleteK1HeadersAction = createAsyncAction(
  'DELETE_K1HEADERS_BEGIN',
  'DELETE_K1HEADERS_END',
  'DELETE_K1HEADERS_ERROR'
)<void, number[], void>()

export const setIssuerFilterAction = createAction(
  'SET_ISSUER_FILTER'
)<string | null>()

export const setReceiverFilterAction = createAction(
  'SET_RECEIVER_FILTER'
)<string | null>()

export const setSearchFilterAction = createAction(
  'SET_K1_SEARCH_FILTER'
)<string | null>()

export const setSortAction = createAction(
  'SET_K1_SORT'
)<string>()

export const setIntialSortAction = createAction(
  'SET_K1_INITIAL_SORT'
)<string>()

export const setStatusFilterAction = createAction(
  'SET_K1_STATUS_FILTER'
)<string | null>()

export const submitK1ToCchAction = createAsyncAction(
  'SUBMIT_K1_TO_CCH_BEGIN',
  'SUBMIT_K1_TO_CCH_DONE',
  'SUBMIT_K1_TO_CCH_FAIL'
)<void, void, Error>()

export type K1Actions =
  | ActionType<typeof clearFilterAction>
  | ActionType<typeof deleteK1HeadersAction.request>
  | ActionType<typeof deleteK1HeadersAction.success>
  | ActionType<typeof deleteK1HeadersAction.failure>
  | ActionType<typeof setIssuerFilterAction>
  | ActionType<typeof setReceiverFilterAction>
  | ActionType<typeof setSearchFilterAction>
  | ActionType<typeof setSortAction>
  | ActionType<typeof setIntialSortAction>
  | ActionType<typeof setStatusFilterAction>
  | ActionType<typeof submitK1ToCchAction.request>
  | ActionType<typeof submitK1ToCchAction.success>
  | ActionType<typeof submitK1ToCchAction.failure>

import * as React from 'react'
import { TaxIntegrationStatus } from '../../clientModels'
import { getState } from '../../services/rules/getStateProxy'
import Icon from '../icon/icon'
import { duoIntegrationStatusMessage } from './duoExportStatusMessage'

export interface EngagementReportItemProps {
  buttonText: string
  description: string
  disabled?: boolean
  hidden?: boolean
  onClick: () => void
  engagementid: number
  reportType: string
  duoFileType?: string
}

export const EngagementReportItem: React.SFC<EngagementReportItemProps> = ({
  buttonText,
  description,
  disabled,
  hidden,
  onClick,
  engagementid,
  reportType,
  duoFileType,
}) => {
  if (hidden) {
    return null
  }

  const state = getState()
  const engagement = state.engagements[engagementid]

  const latestTaxIntegrationStatus =
    engagement!.taxIntegrationStatuses &&
    engagement!.taxIntegrationStatuses
      .filter(x => x.type === 'DuoExport' && x.duoFileType === duoFileType)
      .sort((x, y) => y.id - x.id)[0]

  disabled =
    disabled ||
    latestTaxIntegrationStatus?.status === 'REQUESTED' ||
    latestTaxIntegrationStatus?.status === 'IN PROGRESS'

  return (
    <div className='engagement-report-item'>
      <span>{description}</span>
      <div className='engagement-report-item-button'>
        <button
          className='btn btn-primary'
          onClick={onClick}
          disabled={!!disabled}
        >
          <Icon icon='download' />
          <span>{buttonText}</span>
        </button>
      </div>
      {reportType === 'duoExport' && (
        <div>
          {duoIntegrationStatusMessage(
            engagementid,
            latestTaxIntegrationStatus
          )}
        </div>
      )}
    </div>
  )
}

import * as React from 'react'
import { Option } from '../../clientModels'

export const RadioButtonIcon = () => (
  <svg
    viewBox='0 0 22 22'
    preserveAspectRatio='xMidYMid meet'
    height='22px'
    width='22px'
    className='radio-button-circle'
    focusable='false'
  >
    <circle cx='11' cy='11' r='10' className='border' />
    <circle cx='11' cy='11' r='5' className='center' />
  </svg>
)

export interface RadioButtonLabelProps {
  ariaDescribedBy?: string
  ariaLabelledBy?: string
  disabled?: boolean
  id: string
  labelId: string
  name: string
  onBlur: () => void
  onChange: (option: Option) => Promise<void>
  onFocus: () => void
  option: Option
  value?: string
}

export class RadioButton extends React.Component<RadioButtonLabelProps> {
  handleChange = async () => {
    const { onChange, option, onBlur, disabled } = this.props
    if (!disabled) {
      await onChange(option)
      onBlur()
    }
  }

  render () {
    const {
      ariaDescribedBy,
      ariaLabelledBy,
      disabled,
      id,
      labelId,
      name,
      onBlur,
      onFocus,
      option,
      value,
    } = this.props
    return (
      <div key={option.value} className='form-check form-check-inline'>
        <input
          aria-describedby={ariaDescribedBy}
          aria-labelledby={ariaLabelledBy}
          checked={option.value === value}
          className='form-check-input'
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={this.handleChange}
          onFocus={onFocus}
          type='radio'
          value={option.value}
        />
        <label
          className='form-check-label'
          id={labelId}
          htmlFor={id}
          onClick={this.handleChange}
        >
          <RadioButtonIcon />
          {option.label}
        </label>
      </div>
    )
  }
}

import { Option } from '../../clientModels'
import { CommentActivity } from './selectComments'

class CommentFilters {
  AllComments = 'None'
  ResponseNeeded = 'Response Needed'
  AwaitingClientReply = 'Awaiting Client Reply'
  CarryForward = 'Carry Forward Comments'
  ClientComments = 'Client Comments'
}

interface ICommentFilterMethods {
  [sort: string]: (activity: CommentActivity) => boolean
}

const DefaultCommentFilter: keyof CommentFilters = 'AllComments'
const CommentFilterOptions: Option[] = []
const commentFilters: any = new CommentFilters()

for (const prop in commentFilters) {
  CommentFilterOptions.push({
    value: prop,
    label: commentFilters[prop],
  })
}

function showAllComments() {
  return true
}

function showResponseNeeded(activity: CommentActivity) {
  return activity.responseRequiredByRSM
}

function showAwaitingClientReply(activity: CommentActivity) {
  return activity.responseRequiredByClient
}

function showCarryForward(activity: CommentActivity) {
  return activity.carriedForward
}

function showClientComments(activity: CommentActivity) {
  return (
    !activity.createdByInternalUser ||
    !activity.newestChild.createdByInternalUser
  )
}

const CommentFilterMethods: ICommentFilterMethods = {
  AllComments: showAllComments,
  ResponseNeeded: showResponseNeeded,
  AwaitingClientReply: showAwaitingClientReply,
  CarryForward: showCarryForward,
  ClientComments: showClientComments,
  __default: showAllComments,
}

export {
  CommentFilters,
  CommentFilterMethods,
  CommentFilterOptions,
  DefaultCommentFilter,
}

import * as H from 'history'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { actions } from '../../actions'
import { PhaseCode } from '../../enums'
import { ensureNumber } from '../../guards'
import {
  getEngagements,
  getLastNavigations,
  getEngagementTemplates,
} from '../../reducers/selectors'
import { AppState, TsaDispatch } from '../../store'
import { EngagementSummary, EngagementSummaryProps } from './engagementSummary'
import { copyLastYearAnswers } from '../../actions/engagementThunks'

interface ConnectedEngagementSummaryOwnProps {
  engagementId: string | number
  history: H.History
}

type ConnectedEngagementSummaryMappedProps = Pick<
  EngagementSummaryProps,
  | 'client'
  | 'engagement'
  | 'engagementTemplate'
  | 'history'
  | 'phases'
  | 'user'
  | 'lastNavigation'
>

type ConnectedEngagementSummaryDispatchProps = Pick<
  EngagementSummaryProps,
  | 'updateEngagementPhase'
  | 'markReviewDone'
  | 'submitEngagement'
  | 'getLastYearsData'
  | 'copyLastYearAnswers'
  | 'toggleFavorite'
  | 'toggleLock'
>

const selectEngagementId = (
  state: AppState,
  ownProps: ConnectedEngagementSummaryOwnProps
) => ensureNumber(ownProps.engagementId)

const makeSelectEngagement = () => {
  return createSelector(
    getEngagements,
    selectEngagementId,
    (engagements, engagementId) => engagements[engagementId]
  )
}
const makeSelectEngagementTemplate = () => {
  return createSelector(
    getEngagementTemplates,
    getEngagements,
    selectEngagementId,
    (engagementTemplates, engagements, engagementId) => {
      const engagement = engagements[engagementId]
      return (
        engagement &&
        engagementTemplates[ensureNumber(engagement.engagementTemplateId)]
      )
    }
  )
}
const makeSelectLastNavigation = () => {
  return createSelector(
    getLastNavigations,
    selectEngagementId,
    (lastNavigations, engagementId) => lastNavigations[engagementId]
  )
}
const makeSelectClient = () => {
  return createSelector(
    (state: AppState) => state.clients,
    getEngagements,
    selectEngagementId,
    (clients, engagements, engagementId) => {
      const engagement = engagements[engagementId]
      return engagement && clients[engagement.clientId]
    }
  )
}

const makeMapStateToProps = () => {
  const selectEngagement = makeSelectEngagement()
  const selectEngagementTemplate = makeSelectEngagementTemplate()
  const selectClient = makeSelectClient()
  const selectLastNavigation = makeSelectLastNavigation()
  return (
    state: AppState,
    ownProps: ConnectedEngagementSummaryOwnProps
  ): ConnectedEngagementSummaryMappedProps => ({
    client: selectClient(state, ownProps),
    engagement: selectEngagement(state, ownProps),
    engagementTemplate: selectEngagementTemplate(state, ownProps),
    history: ownProps.history,
    phases: state.phases,
    user: state.auth.user,
    lastNavigation: selectLastNavigation(state, ownProps),
  })
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  ownProps: ConnectedEngagementSummaryOwnProps
): ConnectedEngagementSummaryDispatchProps => ({
  markReviewDone: (engagementId: string | number) =>
    dispatch(actions.review.markReviewDone(engagementId)),
  updateEngagementPhase: (engagementId: string | number, phase: PhaseCode) =>
    dispatch(
      actions.engagement.updateEngagementPhase(ownProps.engagementId, phase)
    ),
  submitEngagement: (engagementId: string | number) =>
    dispatch(actions.engagement.submitEngagement(engagementId)),
  getLastYearsData: (engagementId: string | number) =>
    dispatch(actions.engagement.getLastYearsData(engagementId)),
  copyLastYearAnswers: (engagementId: string | number) =>
    dispatch(copyLastYearAnswers(engagementId)),
  toggleFavorite: (engagementId: string | number) =>
    dispatch(actions.engagement.toggleFavoriteEngagement(engagementId)),
  toggleLock: (engagementId: string | number) =>
    dispatch(actions.engagement.toggleLockEngagement(engagementId)),
})

export const ConnectedEngagementSummary = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EngagementSummary)

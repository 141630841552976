import { createAsyncAction, ActionType } from 'typesafe-actions'

export const getUserInfoAsync = createAsyncAction(
  'GET_USER_INFO_BEGIN',
  'GET_USER_INFO_DONE',
  'GET_USER_INFO_FAIL'
)<void, any, Error>()

export type AdminCenterActions =
  | ActionType<typeof getUserInfoAsync.failure>
  | ActionType<typeof getUserInfoAsync.request>
  | ActionType<typeof getUserInfoAsync.success>

import { unionBy } from 'lodash'
import { TsaHubAction } from '../actions/index'
import { DocumentTitle } from '../clientModels'
import { engagementTemplatesDocEndAction } from '../actions/engagementTemplateActions'
import { getType } from 'typesafe-actions'
import { documentTitlesGetEndAction } from '../actions/documentTitleActions'

const initialState: DocumentTitle[] = []

export function documentTitleReducer (
  state: DocumentTitle[] = initialState,
  action: TsaHubAction
): DocumentTitle[] {
  switch (action.type) {
    case getType(engagementTemplatesDocEndAction):
      return unionBy(state, action.payload.payload.documentTitles, d => d.id)
    case getType(documentTitlesGetEndAction):
      return unionBy(state, action.payload.payload, d => d.id)
    default:
      return state
  }
}

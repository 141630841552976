export function padLeadingZeros (
  clientId: string | number | undefined,
  masterId?: string | number | undefined
) {
  if (!clientId) {
    return
  }
  let content: string = ''
  const paddedClientId = clientId.toString().padStart(7, '0')

  if (masterId) {
    const paddedMasterId = masterId.toString().padStart(7, '0')
    content += paddedMasterId + '-'
  }
  content += paddedClientId
  return content
}

import { AppState, TsaDispatch } from '../../store'
import { selectEngagementFileGroups } from '../../reducers/selectors'
import { ensureNumber } from '../../guards'
import { DataFileManagementOwnProps } from './dataFileManagement'
import { actions } from '../../actions'
import { setFileUploadDialogOpen } from '../../actions/fileActions'
import { getFileGroupsForEngagement } from '../../actions/fileThunks'
import { DocumentTitle, FileGroup } from '../../clientModels'

export const selectFileGroup = (state: AppState, props: DataFileManagementOwnProps) => {
  const { fileGroups } = state
  const {
    engagementId, questionId, fileGroupId, documentTitleId
  } = props

  if (
    !engagementId ||
    !questionId ||
    (!fileGroupId && !documentTitleId) ||
    !fileGroups
  ) {
    return
  }
  const engagementFileGroups = selectEngagementFileGroups(
    fileGroups,
    engagementId
  )
  if (!engagementFileGroups) {
    return
  }
  const questionFileGroups = engagementFileGroups[questionId]
  if (!questionFileGroups) {
    return
  }
  if (fileGroupId) {
    const fgi = ensureNumber(fileGroupId)
    const matchingFileGroups = questionFileGroups.filter(fg => fg.id === fgi)
    if (!matchingFileGroups || matchingFileGroups.length === 0) {
      return
    }
    return matchingFileGroups[0]
  }
  const dti = ensureNumber(documentTitleId)
  const matchingFileGroups = questionFileGroups.filter(
    fg => fg.documentTitleId === dti
  )
  if (!matchingFileGroups || matchingFileGroups.length === 0) {
    return
  }
  return matchingFileGroups[0]
}

export function getProps(
  dispatch: TsaDispatch,
) {
  return {
    downloadFile: (fileId: number) => dispatch(actions.file.downloadFile(fileId)),
    downloadFiles: (files: number[]) =>
      dispatch(actions.file.downloadFiles(files)),
    openFileUpload: (
      engagementId?: number,
      questionId?: number,
      documentTitle?: DocumentTitle,
      fileGroup?: FileGroup
    ) =>
      dispatch(
        actions.file.openFileUpload(
          engagementId,
          questionId,
          documentTitle,
          undefined,
          fileGroup
        )
      ),
    toggleSelectFile: (fileId: number) =>
      dispatch(actions.file.toggleSelectFile(fileId)),
    fileUpload: (
      engagementId: number,
      questionId: number,
      documentTitle?: DocumentTitle,
      fileGroup?: FileGroup,
      fileId?: number,
      isTemplate?: boolean,
      maxFileCount?: number
    ) =>
      dispatch(
        setFileUploadDialogOpen({
          open: true,
          replace: fileId,
          engagementId,
          questionId,
          documentTitle,
          fileGroup,
          isTemplate,
          maxFileCount,
        })
      ),
    toggleTag: (
      tag: string,
      engagementId: number,
      questionId: number,
      fileGroupId: number,
      fileIds: number[]
    ) =>
      dispatch(
        actions.file.toggleTag(
          tag,
          engagementId,
          questionId,
          fileGroupId,
          fileIds
        )
      ),
    softDeleteFiles: (
      engagementId: number,
      questionId: number,
      fileGroupId: number,
      fileIds: number[]
    ) =>
      dispatch(
        actions.file.softDeleteFiles(
          engagementId,
          questionId,
          fileGroupId,
          fileIds
        )
      ),
    submitFileToCch: (fileId: number) => dispatch(actions.file.submitFileToCch(fileId)),
    submitMultipleFilesToCch: (fileIds: number[]) =>
      dispatch(actions.file.submitMultipleFilesToCch(fileIds)),
    refreshFiles: (engagementId: number) =>
      dispatch(getFileGroupsForEngagement(ensureNumber(engagementId))),
    hideUploadErrorDialog: () => dispatch(actions.file.hideUploadErrorDialog()),
    setListOfStatus: (list: string[]) =>
      dispatch(actions.selection.selectionSelectStatusFilterListAction({ list })),
  }
}

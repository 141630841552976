import classNames from 'classnames'
import * as React from 'react'
import SmoothCollapse from 'react-smooth-collapse'
import { Option } from '../../clientModels'
import Icon from '../icon/icon'
import { Icons } from '../icon/icons'
import { Opener, withOpener } from '../opener/opener'
import OpenerContent from '../opener/openerContent'
import './addRow.scss'

const openerId = 'AddRow(s)'
const openerButton = withOpener({
  groupName: openerId,
  closeOnClickInside: true,
  closeOnClickOutside: true,
  enableKeyboardNavigation: true,
  openOnClickInside: true,
})
const openerMenu = withOpener({
  groupName: openerId,
  closeOnClickInside: true,
  enableKeyboardNavigation: false,
})

const ButtonOpenerContent = openerButton(OpenerContent)

const MenuOpenerContent = openerMenu(OpenerContent)

interface Properties {
  className?: string
  icon?: keyof Icons
  multipleRowsSelected?: boolean
  onChange?: (value: string) => void
  value?: string
}

// TODO: Components in contextMenu.tsx, documentFilter.tsx, documentSort.tsx, headerSelector.tsx, addrow.tsx look an awful lot alike. Can we extract a component?
class QuestionEditAddRow extends React.Component<Properties> {
  constructor (props: Properties) {
    super(props)

    this.optionSelected = this.optionSelected.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
  }

  optionSelected (e: React.MouseEvent<HTMLDivElement>) {
    const { onChange } = this.props
    if (onChange) {
      onChange(e.currentTarget.id)
    }
  }

  onKeyPress (e: React.KeyboardEvent<HTMLDivElement>) {
    const { onChange } = this.props
    if (!onChange) {
      return
    }
    if (e.charCode === 13 || e.charCode === 32) {
      onChange(e.currentTarget.id)
    }
  }

  render () {
    const {
      optionSelected,
      onKeyPress,
      props: { className, icon, multipleRowsSelected, value },
    } = this

    const singleOrPlural = multipleRowsSelected ? 's' : ''
    const labelBase = 'Add row' + singleOrPlural

    const options: Option[] = [
      {
        label: labelBase,
        value: 'above',
      },
      {
        label: labelBase,
        value: 'below',
      },
    ]

    return (
      <div className={classNames('question-edit-add-rows', className)}>
        <ButtonOpenerContent
          className='question-edit-add-rows-button'
          id={openerId}
        >
          <div className='question-edit-add-rows-button-label'>
            {icon && <Icon icon={icon} />}
            <div>Row{singleOrPlural}</div>
          </div>
        </ButtonOpenerContent>
        <MenuOpenerContent
          className='question-edit-add-rows-items'
          id={openerId}
        >
          {(opener: Opener) => {
            return (
              <SmoothCollapse expanded={opener.isOpen}>
                <div className='tcont'>
                  {options.map((o, i) => {
                    const selected = o.value === value
                    return (
                      <div
                        key={i}
                        id={o.value}
                        onClick={!selected ? optionSelected : undefined}
                        onKeyPress={
                          !selected
                            ? (e: React.KeyboardEvent<HTMLDivElement>) => {
                              opener.close()
                              onKeyPress(e)
                            }
                            : undefined
                        }
                        className={classNames({ selected })}
                        tabIndex={!selected ? 0 : undefined}
                      >
                        {o.label + ' ' + o.value}
                      </div>
                    )
                  })}
                </div>
              </SmoothCollapse>
            )
          }}
        </MenuOpenerContent>
      </div>
    )
  }
}

export default QuestionEditAddRow

import { DocumentTitle, FileGroup } from '../../clientModels'
import { DocLibraryEntry } from './docLibraryItem'

export function missingRequiredDoc (
  documentTitle?: DocumentTitle,
  fileGroup?: FileGroup
) {
  if (documentTitle && !!documentTitle.optional) {
    return false
  }
  return fileGroupIsEmpty(fileGroup)
}

export function fileGroupIsEmpty (fileGroup?: FileGroup) {
  if (!fileGroup) {
    return true
  }
  if (fileGroup.notApplicable) {
    return false
  }
  return !fileGroup.files || fileGroup.files.length === 0
}

export function isCarriedForward (fileGroup?: FileGroup) {
  return !!fileGroup && !!fileGroup.carryForwardActivityId
}

export const getDocLibraryEntryTitle = ({
  documentTitle,
  fileGroup,
}: DocLibraryEntry) =>
  (fileGroup && fileGroup.title) || (documentTitle && documentTitle.title) || ''

// We have an API restriction that won't allow file groups with the same name on the same question.
// Document titles must be unique in the database. File groups created for required files always use
// the title from the DocumentTitle object. Since all of these are true this function
// generates a determinate, unique key for a DocLibraryEntry.
export const docLibraryEntryKey = (e: DocLibraryEntry) =>
  `q${e.question.id}-${getDocLibraryEntryTitle(e)}`

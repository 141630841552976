import { remove } from 'lodash'
import { ActionType } from 'typesafe-actions'
import {
  deleteActivityAction,
  getActivitiesAction,
  getAllActivitiesAction,
  setFileUploadIsDirtyAction,
} from '../actions/activityActions'
import { commentSetAction } from '../actions/commentActions'
import { selectionSidebarSelectAction } from '../actions/selectionActions'
import { ActivityState } from './activitiesReducer'

export function deleteActivityDone(
  state: ActivityState,
  action: ActionType<typeof deleteActivityAction.success>
): ActivityState {
  const stateToReturn: any = { ...state }
  Object.keys(state).forEach(engagementId => {
    const engagementIdNumber = Number(engagementId)
    if (isNaN(engagementIdNumber)) {
      return
    }

    const currentEngagement = state[engagementIdNumber]
    stateToReturn[engagementId] = {}
    if (!currentEngagement) {
      return
    }

    Object.keys(currentEngagement).forEach(questionId => {
      let questionActivities = currentEngagement![questionId] || []
      questionActivities = [...questionActivities]
      remove(questionActivities, activity => activity.id === action.payload)
      stateToReturn[engagementId][questionId] = questionActivities
    })
  })

  return stateToReturn
}

export function getAllActivitiesDone(
  state: ActivityState,
  action: ActionType<typeof getAllActivitiesAction.success>
): ActivityState {
  let stateActivities = state[action.payload.engagementId] || {}
  let changed = false
  let mostRecentDeletedActivity: number | undefined

  for (const questionId in action.payload.activities) {
    const newQuestionActivities = action.payload.activities[questionId]
    if (!newQuestionActivities) {
      continue
    }
    let stateQuestionActivities = stateActivities[questionId] || []
    let changedQuestion = false
    if (stateQuestionActivities.find(a => a.id <= 0)) {
      // There were added activities, remove the temporary activities to be replaced by the actual saved version
      if (!changed) {
        stateActivities = { ...stateActivities }
        changed = true
      }
      stateQuestionActivities = stateActivities[
        questionId
      ] = stateQuestionActivities.filter(a => a.id > 0)
      changedQuestion = true
    }

    for (const newActivity of newQuestionActivities) {
      if (newActivity.isDeleted) {
        // Activity was deleted, so remove the memory version
        if (
          !mostRecentDeletedActivity ||
          mostRecentDeletedActivity < newActivity.activityVersion
        ) {
          mostRecentDeletedActivity = newActivity.activityVersion
        }
        if (!changed) {
          stateActivities = { ...stateActivities }
          changed = true
        }
        stateQuestionActivities = stateActivities[
          questionId
        ] = stateQuestionActivities.filter(a => a.id !== newActivity.id)
        changedQuestion = true
        continue
      }
      const location = stateQuestionActivities.findIndex(
        a => a.id === newActivity.id
      )
      if (
        location < 0 ||
        stateQuestionActivities[location].activityVersion !==
          newActivity.activityVersion
      ) {
        // This is either a new activity or the activity was changed on the server
        if (!changed) {
          stateActivities = { ...stateActivities }
          changed = true
        }
        stateQuestionActivities = stateActivities[questionId] = [
          ...stateQuestionActivities,
        ]
        changedQuestion = true
        if (location < 0) {
          stateQuestionActivities.push(newActivity)
        } else {
          stateQuestionActivities[location] = newActivity
        }
      }
    }

    if (changedQuestion) {
      // This question changed, so put the chronological order back in place
      stateQuestionActivities = stateActivities[
        questionId
      ] = stateQuestionActivities.sort((firstElement, secondElement) =>
        firstElement.createdDate.diff(secondElement.createdDate)
      )

      const carryForwardsCheck: {
        [commentId: number]: {
          remove: boolean
          carryForwards: number[]
        }
      } = {}
      const carryForwardsFileGroupCheck: {
        [fileGroupId: number]: {
          remove: boolean
          carryForwards: number[]
        }
      } = {}
      const carryForwardAll: number[] = []
      let removeCarryForwardAll = false

      // Look through all the carry forwards and remove the superfluous ones
      for (const questionActivity of stateQuestionActivities) {
        switch (questionActivity.activityTypeCode) {
          case 'CarryForward': {
            if (questionActivity.parentId) {
              let carryForwardCheck =
                carryForwardsCheck[questionActivity.parentId]
              if (!carryForwardCheck) {
                carryForwardCheck = carryForwardsCheck[
                  questionActivity.parentId
                ] = {
                  remove: false,
                  carryForwards: [],
                }
              }
              carryForwardCheck.carryForwards.push(questionActivity.id)
              carryForwardCheck.remove =
                questionActivity.activitySubTypeCode === 'Remove'
              if (
                !mostRecentDeletedActivity ||
                questionActivity.activityVersion > mostRecentDeletedActivity
              ) {
                mostRecentDeletedActivity = questionActivity.activityVersion
              }
            }
            if (questionActivity.fileGroupId) {
              let carryForwardCheck =
                carryForwardsFileGroupCheck[questionActivity.fileGroupId]
              if (!carryForwardCheck) {
                carryForwardCheck = carryForwardsFileGroupCheck[
                  questionActivity.fileGroupId
                ] = {
                  remove: false,
                  carryForwards: [],
                }
              }
              carryForwardCheck.carryForwards.push(questionActivity.id)
              carryForwardCheck.remove =
                questionActivity.activitySubTypeCode === 'Remove'
              if (
                !mostRecentDeletedActivity ||
                questionActivity.activityVersion > mostRecentDeletedActivity
              ) {
                mostRecentDeletedActivity = questionActivity.activityVersion
              }
            }
            break
          }
          case 'CarryForwardAllComments': {
            carryForwardAll.push(questionActivity.id)
            removeCarryForwardAll =
              questionActivity.activitySubTypeCode === 'Remove'
            if (
              !mostRecentDeletedActivity ||
              questionActivity.activityVersion > mostRecentDeletedActivity
            ) {
              mostRecentDeletedActivity = questionActivity.activityVersion
            }
            break
          }
          default:
        }
      }

      // Remove the found superfluous activities
      const toRemove: {
        [activityId: number]: true
      } = {}
      let needRemove = false
      if (!removeCarryForwardAll && carryForwardAll.length > 0) {
        carryForwardAll.pop()
      }
      for (const activityId of carryForwardAll) {
        toRemove[activityId] = true
        needRemove = true
      }
      for (const carryForwardCommentId in carryForwardsCheck) {
        const carryForwardCheck = carryForwardsCheck[carryForwardCommentId]
        if (!carryForwardCheck.remove) {
          carryForwardCheck.carryForwards.pop()
        }
        for (const activityId of carryForwardCheck.carryForwards) {
          toRemove[activityId] = true
          needRemove = true
        }
      }
      for (const fileGroupId in carryForwardsFileGroupCheck) {
        const carryForwardCheck = carryForwardsFileGroupCheck[fileGroupId]
        if (!carryForwardCheck.remove) {
          carryForwardCheck.carryForwards.pop()
        }
        for (const activityId of carryForwardCheck.carryForwards) {
          toRemove[activityId] = true
          needRemove = true
        }
      }
      if (needRemove) {
        stateQuestionActivities = stateActivities[
          questionId
        ] = stateQuestionActivities.filter(a => !toRemove[a.id])
      }
    }
  }
  if (changed) {
    // only update the state if there were changes
    return {
      ...state,
      mostRecentDeletedActivity,
      [action.payload.engagementId]: stateActivities,
    }
  }
  return state
}

export function getActivitiesDone(
  state: ActivityState,
  action: ActionType<typeof getActivitiesAction.success>
): ActivityState {
  const engagementActivities = state[action.payload.engagementId] || {}
  return {
    ...state,
    [action.payload.engagementId]: {
      ...engagementActivities,
      [action.payload.questionId]: [...action.payload.activities],
    },
  }
}

export function setComment(
  state: ActivityState,
  action: ActionType<typeof commentSetAction>
): ActivityState {
  return {
    ...state,
    commentText: action.payload.commentText,
    mentionLabel: action.payload.mentionLabel,
    mentionValue: action.payload.mentionValue,
    currentQuestionId: action.payload.questionId,
  }
}

export function setFileUploadIsDirty(
  state: ActivityState,
  action: ActionType<typeof setFileUploadIsDirtyAction>
): ActivityState {
  return {
    ...state,
    fileUploadIsDirty: action.payload,
  }
}

export function sidebarSelect(
  state: ActivityState,
  action: ActionType<typeof selectionSidebarSelectAction>
): ActivityState {
  return {
    ...state,
    fileUploadIsDirty: false,
  }
}

export function clearDirtyActivity(
  state: ActivityState,
  _: any
): ActivityState {
  return {
    ...state,
    fileUploadIsDirty: false,
    commentText: '',
    mentionValue: 'no-tag',
  }
}

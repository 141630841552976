import classNames from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import Icon from '../icon/icon'
import './loading.scss'

interface LoadingOwnProps {
  classes?: string[]
  loadingMessage?: string
  noData?: boolean
  noDataMessage?: string
  showLoadingMessage?: boolean
  watch?: string
  alwaysLoading?: boolean
}

interface LoadingMappedProps {
  loading: string[]
}

const mapStateToProps = (state: AppState): LoadingMappedProps => {
  return {
    loading: state.http.loading,
  }
}

type LoadingProps = LoadingOwnProps & LoadingMappedProps

class Loading extends React.Component<LoadingProps> {
  static defaultProps = {
    noData: false,
    loadingMessage: 'Loading... ',
    noDataMessage: 'No data found',
  }

  render () {
    const {
      classes,
      loading,
      loadingMessage,
      noData,
      noDataMessage,
      showLoadingMessage,
      watch,
      alwaysLoading,
    } = this.props

    const isLoading = watch ? loading.indexOf(watch) > -1 : loading.length > 0

    const showNoDataMessage = watch && noData

    if (!isLoading && !showNoDataMessage && !alwaysLoading) {
      return null
    }

    return (
      <div className={classNames('loading-indicator', classes)}>
        {(isLoading || alwaysLoading) && showLoadingMessage && (
          <span>{loadingMessage}</span>
        )}
        {(isLoading || alwaysLoading) && <Icon icon='loading' />}
        {!isLoading && !alwaysLoading && showNoDataMessage && (
          <div className='text-secondary'>{noDataMessage}</div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps)(Loading)

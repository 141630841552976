import { Button, Theme, createStyles } from '@rsmus/react-ui'
import * as React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { ClientAccessRequestAction } from '../../enums'
import { Modal } from '../shared/Modal'
import { createUseStyles } from 'react-jss'

export interface ClientRequestConfirmOwnProps {
  showConfirmationModal: boolean
  setActions: (action: string) => void
}

const styles = ({ palette }: Theme) =>
  createStyles({
    success: {
      width: '100%',
      fontSize: '300%',
      marginBottom: '10px',
      color: `${palette.green.light}`,
    },
    modalButton: {
      display: 'inline-block !important',
      backgroundColor: palette.blue.main,
      color: palette.common.white,
      minWidth: '100px',
    },
    modalText: {
      fontSize: '1.25rem',
    },
  })

const useStyles = createUseStyles(styles)

const ClientRequestConfirmModal = ({
  showConfirmationModal,
  setActions,
}: ClientRequestConfirmOwnProps) => {
  const classes = useStyles()
  return (
    <Modal
      shown={showConfirmationModal}
      onClose={() => setActions(ClientAccessRequestAction.Close)}
    >
      <div className={classes.modalText}>
        <FaCheckCircle className={classes.success} />
        <p>
          Client Access Request submitted successfully. You will receive an
          email when your request has been approved.
        </p>
      </div>
      <Button
        className={classes.modalButton}
        onClick={() => setActions(ClientAccessRequestAction.Close)}
      >
        OK
      </Button>
    </Modal>
  )
}

export default ClientRequestConfirmModal

import {
  EngagementReportRequestResponse,
  EngagementReportType
} from '../clientModels'
import { Abort } from '../services/abort'
import { ReportsApi } from '../services/api'
import { trackSave } from '../services/track'
import { TsaThunkAction } from '../store'
import * as actions from './engagementReportActions'

export const requestReport = (
  engagementId: number,
  reportType: EngagementReportType,
  token: string,
  abort: Abort,
  tags?: string[],
  id?: string
): TsaThunkAction<
Promise<EngagementReportRequestResponse>
> => async dispatch => {
  try {
    // Request PBC report and receive an async operation ID
    const asyncOperation = await trackSave(
      ReportsApi.apiSubmitReportRequest,
      dispatch,
      id,
      engagementId,
      reportType,
      abort,
      tags
    )

  // Return async operation id and unique token
  return {
    asyncOperationId: asyncOperation.id,
    token,
    }
  } catch (error) {
    dispatch(actions.requestEngagementReportFailAction(error))
    return { token }
  }
}
import { TsaHubAction } from '../actions/index'
import { EngagementTemplateFieldMap } from '../clientModels'
import { getType } from 'typesafe-actions'
import { engagementTemplatesDocEndAction } from '../actions/engagementTemplateActions'

const initialState: EngagementTemplateFieldMap = {}

export function engagementTemplateFieldsReducer (
  state: EngagementTemplateFieldMap = initialState,
  action: TsaHubAction
): EngagementTemplateFieldMap {
  switch (action.type) {
    case getType(engagementTemplatesDocEndAction):
      return { ...state, ...action.payload.payload.fields }
    default:
      return state
  }
}

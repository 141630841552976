import { ensureNumber } from '../guards'
import { AsyncOperationsApi } from '../services/api'
import { TsaThunkAction } from '../store'
import * as actions from './asyncOperationsActions'
import { normalizeAsyncOperationArray } from './normalization'
import { REACT_APP_ASYNCOPERATIONPOLLINGTIMEOUTINSECS } from '../envVariables'

export const getAsyncOperations = (): TsaThunkAction => async (dispatch) => {
  try {
    // Get all async operations
    const asyncOperations = await AsyncOperationsApi.apiGetAsyncOperations()

    // Store all async operations
    const asyncOperationMap = normalizeAsyncOperationArray(asyncOperations)
    return dispatch(
      actions.getAsyncOperationsAction.success({
        asyncOperations: asyncOperationMap,
      })
    )
  } catch (error) {
    return dispatch(actions.getAsyncOperationsAction.failure(error))
  }
}

export const addWatch = (asyncOperationId: number): TsaThunkAction => async (
  dispatch,
  getState
) => {
  const { isPolling, watchList } = getState().async

  if (!watchList.some((x) => x === asyncOperationId)) {
    // Add to watch list if not already there
    dispatch(actions.addAsyncOperationWatch({ asyncOperationId }))
  }

  if (isPolling) {
    // Return early since watch list is already polling
    return
  }

  await dispatch(actions.updateAsyncOperationPolling({ isPolling: true }))
  // Start polling watch list
  dispatch(pollAsyncOperations())
}

export const removeWatch = (asyncOperationId: number): TsaThunkAction => async (
  dispatch,
  getState
) => {
  // Remove from watch list
  await dispatch(actions.removeAsyncOperationWatch({ asyncOperationId }))
  const { watchList } = getState().async

  if (watchList.length === 0) {
    // If watch list is empty, then stop polling
    dispatch(actions.updateAsyncOperationPolling({ isPolling: false }))
  }
}

export const pollAsyncOperations = (): TsaThunkAction => async (
  dispatch,
  getState
) => {
  const { isPolling } = getState().async
  if (!isPolling) {
    return
  }

  // Get async operations
  await dispatch(getAsyncOperations())

  const { operations, watchList } = getState().async

  for (const asyncOperationId of watchList) {
    const asyncOperation = operations[asyncOperationId]
    if (
      !asyncOperation ||
      asyncOperation.status === 'Complete' ||
      asyncOperation.status === 'Errored'
    ) {
      // Remove from watch list if op is complete or absent
      await dispatch(actions.removeAsyncOperationWatch({ asyncOperationId }))
    }
  }

  if (getState().async.watchList.length > 0) {
    // Poll async operations if watch list has ops
    const timeout =
      ensureNumber(
        REACT_APP_ASYNCOPERATIONPOLLINGTIMEOUTINSECS
      ) || 5
    setTimeout(() => dispatch(pollAsyncOperations()), timeout * 1000)
  } else {
    // Stop polling
    dispatch(actions.updateAsyncOperationPolling({ isPolling: false }))
  }
}

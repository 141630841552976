import { Message } from '../clientModels'
import { ActionType, createAction } from 'typesafe-actions'

export const runAllRulesAction = createAction('')<{
  [key: string]: any
}>()

export const runAllRulesDoneAction = createAction('RUN_ALL_RULES_DONE')<{
  [key: string]: any
}>()

export const ruleClearMessageAction = createAction('RULE_CLEAR_MESSAGE')<{
  engagementId: number
  questionId: number
  message: Message
}>()

export const ruleClearMessagesAction = createAction('RULE_CLEAR_MESSAGES')<{
  engagementId: number
  questionId: number
  rows?: number[]
}>()

export const ruleSetMessageAction = createAction('RULE_SET_MESSAGE')<{
  engagementId: number
  questionId: number
  message: Message
}>()

export const ruleSetQuestionEnabledAction = createAction(
  'RULE_SET_QUESTION_ENABLED'
)<{ engagementId: number; questionId: number; isEnabled: boolean }>()

export const ruleSetQuestionVisiblityAction = createAction(
  'RULE_SET_QUESTION_VISIBILITY'
)<{
  engagementId: number
  questionId: number
  isVisible: boolean
  updateMemory: boolean
}>()

export const ruleSetSectionVisiblityAction = createAction(
  'RULE_SET_SECTION_VISIBILITY'
)<{ engagementId: number; sectionId: number; isVisible: boolean }>()

export const ruleSetDocsRequiredAction = createAction(
  'RULE_SET_DOCS_REQUIRED'
)<{
  engagementId: number
  questionId: number
  requiredDocumentTitleIds: number[]
}>()

export const rulesClearDocsRequiredAction = createAction(
  'RULE_CLEAR_DOCS_REQUIRED'
)<{
  engagementId: number
  questionId: number
  requiredDocumentTitleIds: number[]
}>()

export const ruleSetDocsOptionalAction = createAction(
  'RULE_SET_DOCS_OPTIONAL'
)<{ engagementId: number; questionId: number; documentTitleIds: number[] }>()

export const rulesClearDocsOptionalAction = createAction(
  'RULE_CLEAR_DOCS_OPTIONAL'
)<{ engagementId: number; questionId: number; documentTitleIds: number[] }>()

export type RulesActions =
  | ActionType<typeof runAllRulesAction>
  | ActionType<typeof runAllRulesDoneAction>
  | ActionType<typeof ruleClearMessageAction>
  | ActionType<typeof ruleClearMessagesAction>
  | ActionType<typeof ruleSetMessageAction>
  | ActionType<typeof ruleSetQuestionEnabledAction>
  | ActionType<typeof ruleSetQuestionVisiblityAction>
  | ActionType<typeof ruleSetSectionVisiblityAction>
  | ActionType<typeof ruleSetDocsRequiredAction>
  | ActionType<typeof rulesClearDocsRequiredAction>
  | ActionType<typeof ruleSetDocsOptionalAction>
  | ActionType<typeof rulesClearDocsOptionalAction>

import { User } from 'oidc-client'
import { REACT_APP_INTERNALUSERGROUPS } from '../envVariables'
import { acquireToken } from '../oidc-auth/oidcHelper'

export function parseToken(token: string) {
  const splitToken = token.split('.')
  let payload = splitToken[1].replace('-', '+').replace('_', '/')
  payload = payload.padEnd(
    payload.length + ((4 - (payload.length % 4)) % 4),
    '='
  )
  return JSON.parse(window.atob(payload))
}

export const userIsExternal = (user: User) => {
  const accessToken = parseToken(user.access_token)
  return checkIfExternal(accessToken.groups)
}
// we shoudl use config.json setting instead of react app setting
const INTERNAL_USERS_GROUPS_VALUE =
REACT_APP_INTERNALUSERGROUPS || 'Internal Users'

const checkIfExternal = (userGroupsClaim: string | string[]) => {
  var allGroups = INTERNAL_USERS_GROUPS_VALUE.split('|')

  if (Array.isArray(userGroupsClaim)) {
    var result = true
    for (var i in userGroupsClaim) {
      var claim = userGroupsClaim[i]
      var isInternal = allGroups.indexOf(claim) !== -1
      if (isInternal) {
        result = false
        break
      }
    }
    return result
  } else {
    return allGroups.indexOf(userGroupsClaim) === -1
  }
}
/** Function to check whether toke has value or null */
export const hasToken = async (): Promise<boolean> => {
  const token = await acquireToken()
  return token !== null
}
/** Function to check whether the access token is expired or not */
export const isAccessTokenExpiredAsync = async (): Promise<any> => {
  let sessionExpired = true
  // Get access token
  const token = await acquireToken()
  if (token) {
    // Parse token to get the expiration time
    const accessToken = parseToken(token)
    if (accessToken && accessToken.exp) {
      // Compare expiration time with current date
      sessionExpired = accessToken.exp * 1000 - new Date().valueOf() <= 0
    }
  }
  return sessionExpired
}
/** Function to check whether the access token is expired or not */
export const isAccessTokenExpired =(token:string) => {
  let sessionExpired = true
  // Get access token
  
  if (token) {
    // Parse token to get the expiration time
    const accessToken = parseToken(token)
    if (accessToken && accessToken.exp) {
      // Compare expiration time with current date
      sessionExpired = accessToken.exp * 1000 - new Date().valueOf() <= 0
    }
  }
  return sessionExpired
}

import classNames from 'classnames'
import * as React from 'react'
import { Option } from '../../clientModels'
import Icon from '../icon/icon'

interface PillExternalProps {
  className?: string
  onClear: (value: Option) => void
  value: Option
}

type PillProps = PillExternalProps

class Pill extends React.Component<PillProps> {
  constructor(props: PillProps) {
    super(props)

    // #region binding
    this.onClear = this.onClear.bind(this)
    // #endregion
  }

  onClear() {
    const {
      props: { onClear, value },
    } = this
    onClear(value)
  }

  render() {
    const {
      props: { className, value },
      onClear,
    } = this

    const dropdownClassName = classNames('pill', className)

    return (
      <div className={dropdownClassName}>
        <div className='pill-label'>{value.label}</div>
        <Icon icon='close' className='pill-close' onClick={onClear} />
      </div>
    )
  }
}

export default Pill

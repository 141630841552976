import { Option, StringLookup } from '../../clientModels'

class DashboardSorts implements StringLookup {
  [key: string]: string
  Status = 'Status'
  ClientName = 'Client Name'
  DueDate = 'Due Date'
  MasterClientID = 'Master Client ID'
  LastUpdatedDate = 'Last Modified Date'
}

const dashboardSorts = new DashboardSorts()
const DefaultDashboardSortInternal: keyof DashboardSorts = 'LastUpdatedDate'
const DefaultDashboardSortExternal: keyof DashboardSorts = 'DueDate'
const DashboardSortOptions: Option[] = []
Object.keys(dashboardSorts).forEach(property =>
  DashboardSortOptions.push({
    label: dashboardSorts[property],
    value: property,
  })
)

export {
  DashboardSorts,
  DefaultDashboardSortInternal,
  DefaultDashboardSortExternal,
  DashboardSortOptions,
}

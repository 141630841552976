import * as React from 'react'
import * as ReactDOM from 'react-dom'
import './modal.scss'

let modalRoot = document.getElementById('modal-root') as HTMLElement

interface ModalProps {
  children?: React.ReactNode
}

class Modal extends React.Component<ModalProps> {
  el: HTMLDivElement

  constructor (props: ModalProps) {
    super(props)

    this.el = document.createElement('div')

    if (modalRoot === undefined || modalRoot === null) {
      modalRoot = document.getElementById('modal-root') as HTMLElement
    }
  }

  componentDidMount () {
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount () {
    modalRoot.removeChild(this.el)
  }

  render () {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default Modal

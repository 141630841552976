// tslint:disable:max-classes-per-file
import * as React from 'react'
import { Option } from '../../clientModels'
import displayName from '../../displayName'
import { isObjectNullOrUndefined } from '../../guards'
import { OptionsFormFieldProps } from './formComponentsInterfaces'
import {
  objectToOptions,
  optionsToObject,
  optionToObject,
} from './formUtilities'

/**
 * Converts a selected option from the format that we use in the Answers table to the Option format
 * used by the an options-based (ComboBox, RadioButtons, etc.) component.
 *
 * e.g.)
 *
 * answer.value = { 'X': 'Some Label' }
 *
 * converted to:
 *
 * selectedOption = { value: 'X', label: 'Some Label' }
 */
export const optionsValueAdaptor = <TProps extends OptionsFormFieldProps>(
  WrappedComponent: React.ComponentType<TProps>
) => {
  return class extends React.Component<TProps> {
    static displayName = displayName('OptionsValueAdaptor', WrappedComponent)

    handleOnChange = (value: Option) => {
      const { onChange, path } = this.props
      if (onChange) {
        onChange(optionToObject(value), path)
      }
    }

    render () {
      if (!isObjectNullOrUndefined(this.props.value)) {
        throw new Error(
          'comboboxValueAdaptor expects an object that represents the selected option.'
        )
      }
      // Assume that there is only one selected option. Multi-select has not been implemented
      const selectedOption = objectToOptions(this.props.value)[0]
      return (
        <WrappedComponent
          {...this.props}
          value={selectedOption}
          onChange={this.handleOnChange}
        />
      )
    }
  }
}

/**
 * Converts a selected option(s) from the format that we use in the Answers table to the Option format
 * used by the an options-based (ComboBox, RadioButtons, etc.) component.
 *
 * e.g.)
 *
 * answer.value = { 'X': 'Some Label' }
 *
 * converted to:
 *
 * selectedOption = [{ value: 'X', label: 'Some Label' }]
 */
export const optionsValuesAdaptor = <TProps extends OptionsFormFieldProps>(
  WrappedComponent: React.ComponentType<TProps>
) => {
  return class extends React.Component<TProps> {
    static displayName = displayName('OptionsValueAdaptor', WrappedComponent)

    handleOnChange = (value: Option[]) => {
      const { onChange, path } = this.props
      if (onChange) {
        onChange(optionsToObject(value), path)
      }
    }

    render () {
      if (!isObjectNullOrUndefined(this.props.value)) {
        throw new Error(
          'comboboxValueAdaptor expects an object that represents the selected option.'
        )
      }
      const selectedOption = objectToOptions(this.props.value)
      return (
        <WrappedComponent
          {...this.props}
          value={selectedOption}
          onChange={this.handleOnChange}
        />
      )
    }
  }
}

import { isObject } from '../../guards'
import { GridElement } from './component'

export function ValueRenderer (
  { value }: GridElement,
  row: number,
  column: number
) {
  return isObject(value) ? Object.values(value).join(', ') : value
}

import * as React from 'react'
import { File as TsaFile } from '../../clientModels'
import Icon from '../icon/icon'

interface FileUploadFileProps {
  file: TsaFile
  onDelete: (name: string) => void
}

class FileUploadFile extends React.Component<FileUploadFileProps> {
  handleClick = () => {
    const { onDelete, file } = this.props
    onDelete(file.name)
  }

  render () {
    const { file } = this.props
    const isUpdated = file.isUpdated && '(updated)'
    const isNew = file.id < 0 && '(new)'
    const status =
      file.status !== undefined &&
      file.status !== 'Complete' &&
      file.status !== 'Uploaded' &&
      `(${file.status})`
    return (
      <div className='file-upload-file' id={`file-upload-${file.name}`}>
        <Icon icon='delete' onClick={this.handleClick} />
        {file.name} {isUpdated} {isNew} {status}
      </div>
    )
  }
}

export default FileUploadFile

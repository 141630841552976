import {
  Engagement,
  IdentityTokenProfile,
  IEngagementQuestionAnswer,
  Question,
} from '../../clientModels'
import * as http from '../http'
import { getNewAbort } from '../lastRequests'
import {
  ClientUserProfile,
  CurrentAnswer,
  LastYearAnswer,
  QuestionStateSummary,
  UpdateFlag,
} from '../api/apiModels'
import { buildApiUrl } from './apiUrl'

const answersAbort = getNewAbort()

/**
 * Get answers for an engagement. We are fetching these
 * separately because expanding answers on an engagement
 * denormalizes a lot of data and makes a very inefficient
 * query.
 * @param engagementId
 */
export function apiGetAnswers(engagementId: number): Promise<CurrentAnswer[]> {
  var url = buildApiUrl('/answers', { engagementId })
  return http.get(url, answersAbort.new())
}

export function apiGetAnswer(
  engagementId: number,
  questionId: number
): Promise<CurrentAnswer | undefined> {
  var url = buildApiUrl('/answers', { engagementId, questionId })
  return (
    http
      .get(url, answersAbort.new())
      // We can assume a single element because there is a unique index on the Answers table by engagementId, questionId
      .then(a => a && a[0])
  )
}

export function apiGetLastYearValues(
  engagementId: number
): Promise<LastYearAnswer[]> {
  var url = buildApiUrl('/lastyearanswers', { engagementId })
  return http.get(url, answersAbort.new())
}

export function apiCopyLastYearAnswers(
  engagementId: number
): Promise<CurrentAnswer[]> {
  var url = buildApiUrl('/lastyearanswers/copy', { engagementId })
  return http.post(url, {})
}

export function apiSaveAnswer(
  question: Question,
  clientAnswers: IEngagementQuestionAnswer[],
  flags: UpdateFlag[],
  engagement: Engagement,
  clientUserProfile: IdentityTokenProfile,
  mostRecentVersion: number,
  mostRecentMetaVersion: number
): Promise<CurrentAnswer[]> {
  const answers: CurrentAnswer[] = []

  for (const clientAnswer of clientAnswers) {
    answers.push({
      // ...reviewFlags,
      clientId: engagement.clientId,
      answersMetadataId: clientAnswer.answerMetadataId,
      engagementId: engagement.id,
      id: clientAnswer.answerId,
      questionId: clientAnswer.questionId,
      userId: clientAnswer.userId,
      value: clientAnswer.answerValue,
      answerVersion: clientAnswer.answerVersion.toString(),
    })
  }

  const questionStateSummary: QuestionStateSummary = {
    totalQuestions: engagement.totalQuestions,
    successfulQuestions: engagement.successfulQuestions,
    unansweredQuestions: -1,
    errorQuestions: -1,
    needsReviewQuestions: -1,
    lastQuestionId: question.id,
    lastSectionId: question.sectionId,
  }

  const userProfile: ClientUserProfile = {
    name: clientUserProfile.name,
    firstName: clientUserProfile.firstName,
    lastName: clientUserProfile.lastName,
    email: clientUserProfile.email,
  }

  return http.post(
    buildApiUrl('/Answers', { engagementId: engagement.id }),
    {
      answers,
      flags,
      questionStateSummary,
      userProfile,
      mostRecentVersion: mostRecentVersion,
      mostRecentMetadataVersion: mostRecentMetaVersion,
    },
    { headers: { 'Content-Type': 'application/json' } },
    answersAbort.new()
  )
}

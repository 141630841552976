import * as React from 'react'
import { IconProperties } from './properties'

const Flags: React.SFC<IconProperties> = ({
  className,
  active,
  onClick,
  tooltip,
}) => {
  if (active) {
    return (
      <svg
        viewBox='0 0 66.03 85.99'
        height='1em'
        width='1em'
        className={className}
        onClick={onClick}
        focusable='false'
        data-tip={tooltip}
      >
        <path
          d='M75,148V203.5a1.5,1.5,0,0,0,1.5,1.49h1a1.49,1.49,0,0,0,1.39-.93,1.45,1.45,0,0,0,.15-.62V148Zm62-11v21H111.29l13.22-10H125V137Zm-16,7H75V123h46m-11,25h7.88L110,154Z'
          transform='translate(-74.98 -123.02)'
        />
        <rect x='4.03' width='42' height='21' />
        <rect
          x='1'
          y='27'
          width='2px'
          height='54px'
          className='icon-rectangle'
        />
        <polygon points='35.03 30.96 35.03 25 42.91 25 35.03 30.96' />
        <polygon points='36.3 35 49.52 24.99 50.03 24.99 50.03 14 62.03 14 62.03 35 36.3 35' />
      </svg>
    )
  } else {
    return (
      <svg
        viewBox='0 0 66.03 85.99'
        height='1em'
        width='1em'
        className={className}
        onClick={onClick}
        focusable='false'
        data-tip={tooltip}
      >
        <path
          className='cls-1'
          d='M141,133h-4v0H125V119h-4v0H75v1.34a.59.59,0,0,0,0,.14v83a1.5,1.5,0,0,0,1.5,1.49h1a1.49,1.49,0,0,0,1.39-.93,1.45,1.45,0,0,0,.15-.62V148h27v9l0,0v5h35Zm-4,4v21H111.29l13.22-10H125V137ZM79,123h42v10h0v4h0v7H79Zm31,25h7.88L110,154Z'
          transform='translate(-74.98 -119)'
        />
      </svg>
    )
  }
}

export default Flags

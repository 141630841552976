import React, { Fragment } from 'react'
import { FileUploadAdvancedHeader } from '../fileUploadAdvanced/fileUploadAdvancedHeader'
import DataFileManagementHeader from './dataFileManagementHeader'
import { useConfigureImportParams } from '../configureImport/useConfigureImportParams'
import { DocumentTitleId } from '../../enums'

/**
 * DataFileManagementHeaderContainer is a function component that uses
 * the document title ID to decide which header commponent to use.
 */
const DataFileManagementHeaderContainer = () => {
  const { documentTitleId } = useConfigureImportParams()
  // show advance header if k1 documents or k1 upload documents
  const showAdvancedFileUpload =
    documentTitleId === DocumentTitleId.K1UpLoadDocument ||
    documentTitleId === DocumentTitleId.K1Document
  return (
    <Fragment>
      {showAdvancedFileUpload && <FileUploadAdvancedHeader />}
      {!showAdvancedFileUpload && <DataFileManagementHeader />}
    </Fragment>
  )
}

export default DataFileManagementHeaderContainer

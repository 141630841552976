import * as React from 'react'
import { isObject } from '../../guards'
import { TsaJsonSchema } from '../../services/answerSchema'
import { CodeNotInList } from '../forms/formUtilities'
import { DatasheetProps } from './datasheet'
import { ViewerProps } from './viewers'

export function SelectViewer (
  allowUserInput: boolean,
  allowMultiSelect: boolean
) {
  return (propertySchema: TsaJsonSchema, datasheetProps: DatasheetProps) => {
    return class extends React.PureComponent<ViewerProps> {
      keyEvent?: React.KeyboardEvent<HTMLElement>
      render () {
        const {
          props: { value },
        } = this
        const optionValue: any = this.props.cell.value
        const stringValue =
          allowUserInput === false && optionValue && optionValue[CodeNotInList]
            ? null
            : isObject(value)
              ? Object.values(value).join(', ')
              : value

        return <div className='value-viewer'>{stringValue}</div>
      }
    }
  }
}

import {
  Engagement,
  IdentityTokenProfile,
  IEngagementQuestionAnswer,
  Question,
  ReviewSummary,
} from '../clientModels'
import { PhaseCode, RoleCode } from '../enums'
import { ApiModels } from './api'
import * as helpers from '../services/permissionManagerHelpers'

const reviewRolesByPhase = new Map([
  [PhaseCode.Setup, new Set()],
  [PhaseCode.ClientValidation, new Set([RoleCode.ClientPreparer])],
  [PhaseCode.PBC, new Set([RoleCode.ClientPreparer])],
  [
    PhaseCode.Review,
    new Set([
      RoleCode.Preparer,
      RoleCode.PrimaryReviewer,
      RoleCode.SecondaryReviewer,
      RoleCode.ConcurringReviewer,
    ]),
  ],
  [
    PhaseCode.CCH,
    new Set([
      RoleCode.Preparer,
      RoleCode.PrimaryReviewer,
      RoleCode.SecondaryReviewer,
      RoleCode.ConcurringReviewer,
    ]),
  ],
  [PhaseCode.IRS, new Set()],
])

export function reviewRolesForUser(
  engagement?: Engagement,
  user?: IdentityTokenProfile
): RoleCode[] {
  if (!user || !engagement || !engagement.assignedRoleCodesByUserId) {
    return []
  }
  const needClientReview =
    engagement &&
    engagement.phase &&
    engagement.phase === PhaseCode.ClientValidation
  const userId = toUpperCase(user.uniqueId)
  const rolesFromDb = engagement.assignedRoleCodesByUserId.get(userId)
  const roles = rolesFromDb ? Array.from(rolesFromDb) : []
  const impersonatingRoles: RoleCode[] = needClientReview
    ? [RoleCode.ClientPreparer]
    : []

  if (helpers.impersonatingUser) {
    return impersonatingRoles
  } else {
    if (user.isExternal) {
      // Client review role is not stored in the database
      roles.push(RoleCode.ClientPreparer)
    }
    return roles
  }
}

/**
 * Gets the reviewer roles on the specified question for the specified user during the current engagement phase.
 */
export function reviewRolesForQuestion(
  engagement?: Engagement,
  question?: Question,
  user?: IdentityTokenProfile,
  excludeCompleteRoles?: boolean
): RoleCode[] {
  if (!engagement || !question || !user) {
    return []
  }

  return question.reviewRolesRequired.filter(
    r =>
      roleIsValidInPhase(r, engagement.phase) &&
      roleIsAssignedToUser(r, engagement, user) &&
      (!excludeCompleteRoles || roleIsNotComplete(r, engagement))
  )
}

function roleIsNotComplete(roleCode: RoleCode, engagement?: Engagement) {
  if (!engagement) {
    return true
  }
  switch (roleCode) {
    case RoleCode.ConcurringReviewer:
      return !engagement.concurringReviewDone
    case RoleCode.Preparer:
      return !engagement.preparerReviewDone
    case RoleCode.PrimaryReviewer:
      return !engagement.primaryReviewDone
    case RoleCode.SecondaryReviewer:
      return !engagement.secondaryReviewDone
    default:
      return true
  }
}

/**
 * Determines whether the specified question needs to be reviewed by at least one review role
 * during the current engagement phase.
 * @param userId Limits the review check to the specified user ID.
 */
export function questionNeedsReview(
  engagement?: Engagement,
  question?: Question,
  answer?: IEngagementQuestionAnswer,
  user?: IdentityTokenProfile
): boolean {
  if (!engagement || !question) {
    return false
  }

  for (const roleCode of question.reviewRolesRequired) {
    if (roleNeedsReviewOnQuestion(roleCode, engagement, answer, user)) {
      return true
    }
  }

  return false
}

function roleNeedsReviewOnQuestion(
  roleCode: RoleCode,
  engagement: Engagement,
  answer?: IEngagementQuestionAnswer,
  user?: IdentityTokenProfile
): boolean {
  if (!engagement.assignedRoleCodes) {
    return false
  }

  return (
    engagement.assignedRoleCodes.has(roleCode) && // There is at least one user assigned to the specified role on this engagement
    roleIsValidInPhase(roleCode, engagement.phase) && // The role is valid in the current phase
    notReviewedOnAnswer(roleCode, answer) && // The review tickmark for the specified role is not set
    notReviewedOnEngagement(roleCode, engagement) && // A user has not clicked done with review for the entire engagement
    roleIsAssignedToUser(roleCode, engagement, user)
  ) // A user was specified and the role is assingned to that user) {
}

function roleIsValidInPhase(roleCode: RoleCode, phase?: PhaseCode): boolean {
  if (!phase) {
    return false
  }
  const phaseRoles = reviewRolesByPhase.get(phase)
  if (!phaseRoles) {
    return false
  }
  return phaseRoles.has(roleCode)
}

function notReviewedOnAnswer(
  roleCode: RoleCode,
  answer?: IEngagementQuestionAnswer
): boolean {
  if (!answer || !answer.reviewRolesComplete) {
    return true
  }
  return !answer.reviewRolesComplete.has(roleCode)
}

function notReviewedOnEngagement(
  roleCode: RoleCode,
  engagement: Engagement
): boolean {
  if (!roleCode || !engagement) {
    return true
  }
  switch (roleCode) {
    case RoleCode.Preparer:
      return !engagement.preparerReviewDone
    case RoleCode.PrimaryReviewer:
      return !engagement.primaryReviewDone
    case RoleCode.SecondaryReviewer:
      return !engagement.secondaryReviewDone
    case RoleCode.ConcurringReviewer:
      return !engagement.concurringReviewDone
    default:
      return true
  }
}

function roleIsAssignedToUser(
  roleCode: RoleCode,
  engagement: Engagement,
  user?: IdentityTokenProfile
): boolean {
  if (!user || !engagement.assignedRoleCodesByUserId) {
    return true
  }
  if (
    (user.isExternal || helpers.impersonatingUser) &&
    roleCode === RoleCode.ClientPreparer
  ) {
    return true
  }

  const userId = toUpperCase(user.uniqueId)
  const userRoleCodes = engagement.assignedRoleCodesByUserId.get(userId)
  return !!userRoleCodes && userRoleCodes.has(roleCode)
}

/**
 * Translates the storage format of the reviewer roles into the client-side model.
 */
export function getReviewRequiredRoleCodes(
  question: ApiModels.Question
): RoleCode[] {
  const roles: RoleCode[] = []
  if (question.clientReviewRequired) {
    roles.push(RoleCode.ClientPreparer)
  }
  if (question.preparerReviewRequired) {
    roles.push(RoleCode.Preparer)
  }
  if (question.primaryReviewRequired) {
    roles.push(RoleCode.PrimaryReviewer)
  }
  if (question.secondaryReviewRequired) {
    roles.push(RoleCode.SecondaryReviewer)
  }
  if (question.concurringReviewRequired) {
    roles.push(RoleCode.ConcurringReviewer)
  }
  return roles
}

/**
 * Translates the storage format of the reviewer roles into the client-side model.
 */
export function getReviewsComplete(
  answer: ApiModels.CurrentAnswer
): Set<RoleCode> {
  // We started storing the review flags in the Answer table before
  // we normalized the roles into the Roles table. This translates
  // to the underlying storage format.
  const reviews = new Set<RoleCode>()
  const metadata = answer.answersMetadata
  if (metadata) {
    if (metadata.clientReviewed) {
      reviews.add(RoleCode.ClientPreparer)
    }
    if (metadata.preparerReviewed) {
      reviews.add(RoleCode.Preparer)
    }
    if (metadata.primaryReviewed) {
      reviews.add(RoleCode.PrimaryReviewer)
    }
    if (metadata.secondaryReviewed) {
      reviews.add(RoleCode.SecondaryReviewer)
    }
    if (metadata.concurringReviewed) {
      reviews.add(RoleCode.ConcurringReviewer)
    }
  }
  return reviews
}

/**
 * Translates the client-side reviewer roles into the storage format.
 */
export function getReviewFlags(
  answer: IEngagementQuestionAnswer
): Partial<ApiModels.AnswerMetadata> {
  const complete = answer.reviewRolesComplete || new Set()
  return {
    clientReviewed: complete.has(RoleCode.ClientPreparer),
    concurringReviewed: complete.has(RoleCode.ConcurringReviewer),
    preparerReviewed: complete.has(RoleCode.Preparer),
    primaryReviewed: complete.has(RoleCode.PrimaryReviewer),
    secondaryReviewed: complete.has(RoleCode.SecondaryReviewer),
  }
}

/**
 * Maps an API version of the engagement to the client-side model.
 */
export function engagementProcessStrategy(
  apiModel: ApiModels.Engagement
): Engagement {
  // tslint:disable-next-line:no-any
  const engagement: any = {
    ...apiModel,
  }

  // All engagements will have the client preparer role enabled
  const assignedRoleCodes = new Set<RoleCode>([RoleCode.ClientPreparer])
  const rolesByUserId = new Map<string, Set<string>>()
  if (apiModel.assignments) {
    for (const assignment of apiModel.assignments) {
      // RSM user ids may or may not be upper case
      const userId = toUpperCase(assignment.user.userId)
      assignedRoleCodes.add(assignment.roleCode)
      let rolesForUser = rolesByUserId.get(userId)
      if (!rolesForUser) {
        rolesForUser = new Set<RoleCode>()
        rolesByUserId.set(userId, rolesForUser)
      }
      rolesForUser.add(assignment.roleCode)
    }
    engagement.assignedRoleCodes = assignedRoleCodes
    engagement.assignedRoleCodesByUserId = rolesByUserId
  }
  return engagement
}

export function isRoleCode(value: string): value is RoleCode {
  return Object.values(RoleCode).includes(value as any)
}

export function reviewRoleIsComplete(
  engagement: Engagement,
  roleCode: RoleCode
): boolean {
  const assignedRoleCodes = engagement.assignedRoleCodes
  if (!assignedRoleCodes) {
    return false
  }

  if (!assignedRoleCodes.has(roleCode)) {
    // If there isn't a reviewer of this type then consider it done
    return true
  }

  switch (roleCode) {
    case RoleCode.Preparer:
      return !!engagement.preparerReviewDone
    case RoleCode.PrimaryReviewer:
      return !!engagement.primaryReviewDone
    case RoleCode.SecondaryReviewer:
      return !!engagement.secondaryReviewDone
    case RoleCode.ConcurringReviewer:
      return !!engagement.concurringReviewDone
    default:
      return false
  }
}

export function updateReviewDone(
  roles: RoleCode[],
  mark: boolean
): ReviewSummary {
  const reviewSummary: ReviewSummary = {}

  for (const role of roles) {
    switch (role) {
      case RoleCode.Preparer:
        reviewSummary.preparerReviewDone = mark
        break
      case RoleCode.PrimaryReviewer:
        reviewSummary.primaryReviewDone = mark
        break
      case RoleCode.SecondaryReviewer:
        reviewSummary.secondaryReviewDone = mark
        break
      case RoleCode.ConcurringReviewer:
        reviewSummary.concurringReviewDone = mark
        break
      default:
        break
    }
  }

  return reviewSummary
}

export function updatedReviewStarted(
  previousReviewStatus: ReviewSummary,
  assignedRoles: RoleCode[]
): boolean {
  let result = false
  for (const role of assignedRoles) {
    switch (role) {
      case RoleCode.ConcurringReviewer:
        result = !previousReviewStatus.concurringReviewStarted
        break
      case RoleCode.Preparer:
        result = !previousReviewStatus.preparerReviewStarted
        break
      case RoleCode.PrimaryReviewer:
        result = !previousReviewStatus.primaryReviewStarted
        break
      case RoleCode.SecondaryReviewer:
        result = !previousReviewStatus.secondaryReviewStarted
        break
      default:
        break
    }
    if (result) {
      break
    }
  }
  return result
}

export function updateReviewStarted(
  assignedRoles: RoleCode[]
): Partial<ReviewSummary> {
  const result: Partial<ReviewSummary> = {}

  for (const role of assignedRoles) {
    switch (role) {
      case RoleCode.ConcurringReviewer:
        result.concurringReviewStarted = true
        break
      case RoleCode.Preparer:
        result.preparerReviewStarted = true
        break
      case RoleCode.PrimaryReviewer:
        result.primaryReviewStarted = true
        break
      case RoleCode.SecondaryReviewer:
        result.secondaryReviewStarted = true
        break
      default:
        break
    }
  }

  return result
}

/**
 * Determine if review is complete. If a user is specified only that user's roles are checked.
 */
export function reviewIsComplete(
  engagement: Engagement,
  user?: IdentityTokenProfile
) {
  const assignedRoleCodes = engagement.assignedRoleCodes
  const assignedRoleCodesByUserId = engagement.assignedRoleCodesByUserId
  if (!assignedRoleCodes || !assignedRoleCodesByUserId) {
    return false
  }

  const userId = toUpperCase(user && user.uniqueId)
  const userRoles = userId && assignedRoleCodesByUserId.get(userId)

  return Array.from(assignedRoleCodes)
    .filter(r => roleIsValidInPhase(r, engagement.phase))
    .filter(r => !userRoles || userRoles.has(r))
    .every(r => reviewRoleIsComplete(engagement, r))
}

export function userIsReviewer(
  engagement: Engagement,
  user?: IdentityTokenProfile
) {
  if (!user) {
    return false
  }
  const assignedRoleCodesByUserId = engagement.assignedRoleCodesByUserId
  const userId = toUpperCase(user.uniqueId)
  return !!assignedRoleCodesByUserId && assignedRoleCodesByUserId.has(userId)
}

function toUpperCase(value: string | undefined): string {
  if (!value) {
    return ''
  }
  return value.toUpperCase()
}

export function questionIsAnswered(
  question?: Question,
  answer?: IEngagementQuestionAnswer
) {
  if (!question || !answer) {
    return false
  }

  if (question.allowNotApplicable && !!answer.notApplicable) {
    // The user selected Not Applicable and the question allows it
    // This qualifies as a valid answer.
    return true
  }

  return !!answer.answerValue
}

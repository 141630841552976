import { EntityMap } from '../../clientModels'
import { OpenerActions, OpenerActionTypes } from './openerActions'

export interface OpenState {
  isDisabled: boolean
  isOpen: boolean
}

export interface OpenerState {
  [group: string]: {
    allowOnlyOne?: boolean
    openers: EntityMap<OpenState>
    keepOpen?: boolean
  }
}

function initializeGroup(
  state: OpenerState,
  group: string,
  allowOnlyOne?: boolean
): OpenerState {
  return {
    ...state,
    [group]: {
      allowOnlyOne,
      openers: {},
    },
  }
}

function updateOpener(
  state: OpenerState,
  group: string,
  id: string | number,
  isOpen: boolean,
  keepOpen?: boolean,
  isDisabled?: boolean
): OpenerState {
  return {
    ...state,
    [group]: {
      ...state[group],
      keepOpen,
      openers: {
        ...(state[group] || {}).openers,
        [id]: { isOpen, isDisabled: !!isDisabled },
      },
    },
  }
}

function closeAllOpen(state: OpenerState = {}, group: string) {
  state[group] = { ...state[group], keepOpen: false }
  state[group].openers = { ...state[group].openers }

  for (const opener in state[group].openers) {
    if (opener) {
      const stateGroupOpener = state[group].openers[opener]
      if (stateGroupOpener !== undefined) {
        state[group].openers[opener] = { ...stateGroupOpener, isOpen: false }
      }
    }
  }
  return state
}

export const openerReducer = (
  state: OpenerState = {},
  action: OpenerActions
): OpenerState => {
  switch (action.type) {
    case OpenerActionTypes.OPENER_ALLOW_ONLY_ONE:
      // don't allow the change of allow only one
      if (typeof state[action.payload.group] === 'undefined') {
        state = initializeGroup(
          state,
          action.payload.group,
          action.payload.allowOnlyOne
        )
      }
      break

    case OpenerActionTypes.OPENER_OPEN: {
      if (
        typeof state[action.payload.group] !== 'undefined' &&
        state[action.payload.group].allowOnlyOne
      ) {
        state = closeAllOpen(state, action.payload.group)
      }

      const openGroup = state[action.payload.group]
      const openOpeners = openGroup && openGroup.openers
      const openOpener = openOpeners && openOpeners[action.payload.openerId]
      if (openOpener && openOpener.isDisabled) {
        state = { ...state }
      } else {
        state = updateOpener(
          state,
          action.payload.group,
          action.payload.openerId,
          true,
          action.payload.keepOpen
        )
      }
      break
    }

    case OpenerActionTypes.OPENER_CLOSE:
      state = updateOpener(
        state,
        action.payload.group,
        action.payload.openerId,
        false
      )
      break

    case OpenerActionTypes.OPENER_CLOSE_GROUP:
      state = { ...closeAllOpen(state, action.payload.group) }
      break

    case OpenerActionTypes.OPENER_DISABLE:
      state = updateOpener(
        state,
        action.payload.group,
        action.payload.openerId,
        false,
        undefined,
        true
      )
      break

    case OpenerActionTypes.OPENER_ENABLE:
      state = updateOpener(
        state,
        action.payload.group,
        action.payload.openerId,
        false,
        undefined,
        false
      )
      break

    case OpenerActionTypes.OPENER_TOGGLE: {
      const group = state[action.payload.group]
      let isOpen = false
      if (group && group.openers) {
        const opener = group.openers[action.payload.openerId]
        isOpen = !!opener && opener.isOpen
      }

      if (group && group.allowOnlyOne && !isOpen) {
        state = closeAllOpen(state, action.payload.group)
      }

      state = updateOpener(
        state,
        action.payload.group,
        action.payload.openerId,
        !isOpen,
        action.payload.keepOpen
      )
      break
    }

    default:
      break
  }

  return state
}

import { Engine, JsonRule, RulesEngineEventHandler } from 'json-rules-engine'
import questionEngines from './questionEngineCache'
import templateEngines from './templateEngineCache'
import { clearCache, configureEngine, findQuestionIds } from './utilities'
import { QuestionMap } from '../../clientModels'

type RuleTuple = [JsonRule, number[]]

export function getEngineByEngagementTemplateId (engagementTemplateId: number) {
  return templateEngines.get(engagementTemplateId)
}

export const getAllQuestionEngines = () => questionEngines

export const getEngineByQuestionId = (questionId: number) =>
  questionEngines.get(questionId)

export function createEngines (
  engagementTemplateId: number,
  rules: JsonRule[],
  handler: RulesEngineEventHandler,
  questions: QuestionMap
) {
  let engine: Engine | undefined
  clearCache(templateEngines, engagementTemplateId)
  clearCache(questionEngines, engagementTemplateId)

  // Temporary rules filter
  // rules = rules.filter(r => {
  //   const ids = findQuestionIds(r)
  //   return ids.length === 0 || ids.includes(287)
  // })

  // Create an engine for each unique question ID
  // This engine will only run the rules that use a particular question's answer as input
  const questionRules = rules.map(rule => [
    rule,
    findQuestionIds(rule),
  ]) as RuleTuple[]
  questionRules.forEach(tuple => {
    const rule = tuple[0]
    const ids = tuple[1]
    // We have a rule which is not related to a question
    // Example would be client audit Rule
    if (ids.length === 0) {
      engine = templateEngines.get(engagementTemplateId)
      if (engine) {
        engine.addRule(tuple[0])
      } else {
        engine = new Engine([rule])
        configureEngine(engine, engagementTemplateId, handler, undefined)
        templateEngines.set(engagementTemplateId, engine)
      }
    }
    ids.forEach(questionId => {
      engine = questionEngines.get(questionId)
      if (engine) {
        engine.addRule(tuple[0])
      } else {
        engine = new Engine([rule])
        configureEngine(
          engine,
          engagementTemplateId,
          handler,
          questions[questionId]
        )
        questionEngines.set(questionId, engine)
      }
    })
  })
}

import moment from 'moment'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router'
import { CSSTransition } from 'react-transition-group'
import { actions } from '../../actions'
import { Engagement } from '../../clientModels'
import { MilestoneCode, PhaseCode, RoleCode } from '../../enums'
import { ensureNumber } from '../../guards'
import { useRouteParams } from '../../hooks'
import { AppState } from '../../store'
import { ClientSearchBox } from '../clientSearch'
import { EngagementSummary } from '../engagementSummary'
import InternalContent from '../internal/internalContent'
import Loading from '../loading'
import { engagementsSelector } from './engagementSelector'
import './engagementsList.scss'
import { clientSearchSelectResultAction } from '../../actions/clientSearchActions'

const EngagementsList = () => {
  const isClientId = useLocation().pathname.indexOf('clientresults') > -1
  const history = useHistory()
  const match = useRouteMatch<{ engagementId: string; entityGroupId: string }>()
  const entityGroupId = ensureNumber(match.params.entityGroupId)
  const dispatch = useDispatch()

  // Get the route parameters.
  const routeParams = useRouteParams()

  const clients = useSelector((state: AppState) => state.clients)
  const engagements = useSelector((state: AppState) =>
    engagementsSelector(state)
  )
  const engagementTemplates = useSelector(
    (state: AppState) => state.engagementTemplates
  )
  const phases = useSelector((state: AppState) => state.phases)
  const entityGroups = useSelector((state: AppState) => state.entityGroups)
  const user = useSelector((state: AppState) => state.auth.user)
  const loading = useSelector((state: AppState) => state.http.loading)
  const error = useSelector((state: AppState) => state.engagements.listError)
  const lastNavigationMap = useSelector(
    (state: AppState) => state.userSettings.lastNavigationMap
  )

  // This is the list of engagements that will be displayed.
  const [engagementsOutput, setEngagementsOutput] = useState(engagements)

  // Update the list of engagements to display, based on the route parameters.
  useEffect(() => {
    const entityGroup = entityGroups[entityGroupId]

    let newEngagements = engagements

    if (entityGroup) {
      newEngagements = newEngagements.filter(e =>
        entityGroup.entityEngagements.some(eg => eg.engagementId === e.id)
      )
    }

    // If the route indicates we are filtering on master client ID, form type or tax year, then apply those.
    if (routeParams.masterClientId) {
      newEngagements = newEngagements.filter(
        e => clients[e.clientId]?.masterId === routeParams.masterClientId
      )
    }
    if (routeParams.formName) {
      newEngagements = newEngagements.filter(
        e => e.engagementTaxForm === routeParams.formName
      )
    }
    if (routeParams.taxYear) {
      newEngagements = newEngagements.filter(
        e => e.taxYear === routeParams.taxYear
      )
    }

    // Set the engagements to our new filtered value.
    setEngagementsOutput(newEngagements)
  }, [
    routeParams.masterClientId,
    routeParams.taxYear,
    routeParams.formName,
    entityGroupId,
    engagements,
    entityGroups,
    clients
  ])

  useEffect(() => {
    if (isClientId) {
      // this is the client search view. entity nav bar does not render for this
      // so engagement list must be queried directly
      dispatch(actions.engagement.getEngagementList(entityGroupId, isClientId))
    }
  }, [entityGroupId, dispatch, isClientId])

  const showEngagement = (engagement: Engagement) => {
    if (
      engagement.favorited ||
      !user ||
      user.isExternal ||
      !engagement.assignedRoleCodesByUserId
    ) {
      return true
    }

    if (engagement.milestones) {
      for (const milestone of engagement.milestones) {
        if (
          milestone.milestone === MilestoneCode.IRS &&
          moment(milestone.completedDate)
            .add(90, 'days')
            .isBefore(new Date())
        ) {
          return false
        }
      }
    }

    const myRoles = engagement.assignedRoleCodesByUserId.get(user.uniqueId)

    if (!myRoles) {
      return false
    }

    return (
      myRoles.has(RoleCode.PrimaryReviewer) ||
      myRoles.has(RoleCode.Preparer) ||
      myRoles.has(RoleCode.SecondaryReviewer) ||
      myRoles.has(RoleCode.ConcurringReviewer) ||
      myRoles.has(RoleCode.Viewer) ||
      myRoles.has(RoleCode.Signer)
    )
  }

  if (error) {
    return (
      <div className='engagements-list-error'>
        Failed to retrieve engagement list.
      </div>
    )
  }
  const showSetupMessage =
    user &&
    user.isExternal &&
    engagementsOutput.find(engagement => engagement.phase === PhaseCode.Setup)

  return (
    <div className='engagements-list'>
      <Loading
        showLoadingMessage={true}
        watch={actions.engagement.GetEngagementsWatchId}
      />
      <InternalContent>
        <Switch>
          <Route path='/' component={ClientSearchBox} />
        </Switch>
      </InternalContent>
      {!!engagementsOutput.length &&
        engagementsOutput.map(e => (
          <CSSTransition
            key={e.id}
            in={showEngagement(e)}
            timeout={400}
            classNames='engagement-container'
          >
            <EngagementSummary
              key={e.id}
              client={clients[e.clientId]}
              engagement={e}
              history={history}
              markReviewDone={engagementId =>
                dispatch(actions.review.markReviewDone(engagementId))
              }
              phases={phases}
              updateEngagementPhase={(engagementId, phase) =>
                dispatch(
                  actions.engagement.updateEngagementPhase(engagementId, phase)
                )
              }
              copyLastYearAnswers={engagementId =>
                dispatch(
                  actions.engagement.copyLastYearAnswers(engagementId, true)
                )
              }
              submitEngagement={engagementId =>
                dispatch(
                  actions.engagement.submitEngagement(
                    engagementId,
                    entityGroupId
                  )
                )
              }
              getLastYearsData={engagementId =>
                dispatch(
                  actions.engagement.getLastYearsData(
                    engagementId,
                    entityGroupId,
                    isClientId
                  )
                )
              }
              user={user}
              engagementTemplate={engagementTemplates[ensureNumber(e.template)]}
              toggleFavorite={engagementId =>
                dispatch(
                  actions.engagement.toggleFavoriteEngagement(engagementId)
                )
              }
              toggleLock={(engagementId, clientId) =>
                dispatch(
                  actions.engagement.toggleLockEngagement(
                    engagementId,
                    clientId
                  )
                )
              }
              lastNavigation={lastNavigationMap && lastNavigationMap[e.id]}
            />
          </CSSTransition>
        ))}
      {engagementsOutput.length === 0 &&
        loading.indexOf(actions.engagement.GetEngagementsWatchId) === -1 && (
          <div>
            <br />
            <span className='no-engagements'>
              No engagements assigned to you at this time.
            </span>
          </div>
        )}
      {showSetupMessage && (
        <span className='engagement-hidden'>
          One or more of your engagements is in the setup phase and was omitted
          from this list. It will appear here when setup is complete.
        </span>
      )}
    </div>
  )
  // }
}

export default EngagementsList

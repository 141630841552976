export class ExtError extends Error {
  status: number
  silent: boolean
  body?: string
  onOk?: () => void
  onCancel?: () => void
  id?: string

  constructor (message: string, status: number, silent?: boolean) {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ExtError) // does not seem to work.
    }

    this.message = message
    this.name = 'CustomError'
    this.status = status
    this.silent = !!silent
  }
}

import classNames from 'classnames'
import * as React from 'react'
import Icon, { IconClickHandler } from '../icon/icon'
import { DocLibraryEntry } from './docLibraryItem'
import './docLibraryItemIcons.scss'
import { engagementLockedPhase } from '../../services/engagementPhaseService'
import { Engagement } from '../../clientModels'
import { UserPermissions } from '../../enums'
import PermissionContent from '../internal/permissionContent'
import { usePermissionManager } from './../../hooks'

interface DocLibraryItemIconsProps extends DocLibraryEntry {
  disabled: boolean
  engagement: Engagement | undefined
  isOpen: boolean
  onClickIcon: IconClickHandler
}

const DocLibraryItemIcons = (props: DocLibraryItemIconsProps) => {
  const { hasAnyPermission } = usePermissionManager()

  const renderDocumentIcons = (skipEdit?: boolean) => {
    const { disabled, fileGroup, onClickIcon } = props

    const isCarriedForward = !!(fileGroup && fileGroup.carryForwardActivityId)

    const onClick = (
      name: string,
      e: React.MouseEvent<Element, MouseEvent>
    ) => {
      if (!isCarriedForward) {
        onClickIcon(name, e)
      }
    }

    return (
      <React.Fragment>
        <PermissionContent permission={UserPermissions.CarryForwardCanEdit}>
          <Icon
            icon='carryForward'
            active={isCarriedForward}
            onClick={onClickIcon}
            tooltip={
              isCarriedForward
                ? 'Item(s) Marked as Carry Forward'
                : 'Mark Item(s) as Carry Forward'
            }
          />
        </PermissionContent>
        {!disabled && (
          <React.Fragment>
            <Icon
              icon='delete'
              className={classNames({ disabled: isCarriedForward })}
              onClick={(name, e) => onClick(name, e)}
              tooltip={
                isCarriedForward &&
                hasAnyPermission([
                  UserPermissions.CarryForwardCanEdit,
                  UserPermissions.CarryForwardCanView,
                ])
                  ? 'You cannot delete a document that has been carried forward.'
                  : isCarriedForward
                  ? 'Remove Document(s) Disabled'
                  : 'Remove Document(s)'
              }
            />
            {!skipEdit && (
              <Icon
                icon='edit'
                className={classNames({ disabled: isCarriedForward })}
                onClick={(name, e) => onClick(name, e)}
                tooltip={
                  isCarriedForward &&
                  hasAnyPermission([
                    UserPermissions.CarryForwardCanEdit,
                    UserPermissions.CarryForwardCanView,
                  ])
                    ? 'You cannot modify a document that has been carried forward.'
                    : isCarriedForward
                    ? 'Manage Document(s) Disabled'
                    : 'Manage Document(s)'
                }
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  const renderDownloadTemplateIcon = () => {
    const { onClickIcon } = props
    return (
      <Icon
        icon='download'
        onClick={onClickIcon}
        tooltip='Download an empty template to fill out with your information.'
      />
    )
  }

  const renderUploadIcon = (hasFiles?: boolean) => {
    const { onClickIcon } = props
    return (
      <Icon
        icon='upload'
        onClick={onClickIcon}
        tooltip={!hasFiles ? 'Upload document(s)' : 'Replace file'}
      />
    )
  }

  //Main body starts here
  const { documentTitle, engagement, fileGroup, isOpen, onClickIcon } = props

  const hasFiles = fileGroup && fileGroup.files && fileGroup.files.length > 0
  const nothingToUpload = fileGroup && fileGroup.notApplicable
  const className = classNames('doc-library-item-icons', { open: isOpen })

  const engagementLocked = engagement
    ? engagement.isEnabled !== undefined
      ? engagement.isEnabled
      : engagementLockedPhase(engagement)
    : true

  if (documentTitle && documentTitle.templateFileName) {
    return (
      <div className={className}>
        {hasFiles && renderDocumentIcons(true)}
        {renderDownloadTemplateIcon()}
        {!engagementLocked && renderUploadIcon(hasFiles)}
        <Icon
          icon='openContext'
          onClick={onClickIcon}
          tooltip='Go to Question'
        />
      </div>
    )
  }

  return (
    <div className={className}>
      {hasFiles && renderDocumentIcons()}
      {!hasFiles &&
        !nothingToUpload &&
        !engagementLocked &&
        renderUploadIcon(hasFiles)}
      <Icon icon='openContext' onClick={onClickIcon} tooltip='Go to Question' />
    </div>
  )
}
export default DocLibraryItemIcons

import moment from 'moment'
import * as React from 'react'
import { TsaJsonSchema } from '../../services/answerSchema'
import { ViewerProps } from './viewers'

export function DateViewer (propertySchema: TsaJsonSchema) {
  return class extends React.PureComponent<ViewerProps> {
    render () {
      const {
        props: { value },
      } = this
      let dispValue = value
      if (value) {
        const timeValue = moment(value, [moment.ISO_8601, 'M/D/YYYY'], true)
        if (timeValue.isValid()) {
          dispValue = timeValue.format('MM/DD/YYYY')
        }
      }
      return <span>{dispValue}</span>
    }
  }
}

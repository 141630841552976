import classNames from 'classnames'
import { compact, find, get, range, toPath, uniqueId } from 'lodash'
import { parse } from 'papaparse'
import * as React from 'react'
import ReactDataSheet from 'react-datasheet'
import { connect } from 'react-redux'
import { ReadOnlyDataSheet } from '.'
import * as rulesActions from '../../actions/rulesActions'
import { EntityMap, Message, Option, PropertyValues } from '../../clientModels'
import { optionsListLookup } from '../../services/answerSchema'
import { TsaDispatch } from '../../store'
import { Alerts } from '../forms/alerts'
import {
  FocusableComponent,
  OptionsFormFieldProps
} from '../forms/formComponentsInterfaces'
import { optionToObject } from '../forms/formUtilities'
import { WarningDialog } from '../modals'
import { ComponentReactDataSheet, GridElement } from './component'
import DatasheetContextMenu from './contextMenu'
import './datasheet.scss'
import {
  buildHeader,
  convertMessages,
  convertValue,
  defaultMaxRows,
  defaultMinRows,
  findOptionValue,
  getColumn,
  getRootObject
} from './datasheetUtilities'
import { PureCellRenderer, SheetHeader } from './pureCellRenderer'
import { PureRowRenderer } from './pureRowRenderer'
import { processSchema, SchemaProperty } from './schemaUtilities'
import { SheetRenderer, SheetRowProps } from './sheetRenderer'
import { ValueRenderer } from './valueRenderer'
import { isObject } from '../../guards'

const delay = 10

interface PasteError {
  startingRow: number
  usedRows: number
  truncatedRows: number
  isValueSet?: boolean
}

export interface DatasheetState {
  columnDefinitionLookup: EntityMap<SchemaProperty>
  columnPathLookup: EntityMap<string>
  columns: SchemaProperty[]
  columnsLength: number
  grid: GridElement[][]
  headerWidth: string | null
  isWholeRow?: boolean
  left?: number
  maxRows: number
  messages: EntityMap<EntityMap<Message[]>>
  minRows: number
  pasteError: PasteError | null
  pathColumnLookup: EntityMap<number>
  rowHeaders: string[] | null
  scrollLeft: number
  scrollTop: number
  selected: ReactDataSheet.Selection
  selectedCol: number
  selectedRow: number
  selectedRowCount: number
  selectionStart?: ReactDataSheet.Selection
  sheetHeader: SheetHeader[][]
  showContextMenu?: boolean | null
  showRemove?: boolean | null
  top?: number
  currentRows: number
}

interface DatasheetDispatchProps {
  clearMessage: (
    engagementId: number,
    questionId: number,
    message: Message
  ) => void
}

export interface DatasheetProps
  extends DatasheetDispatchProps,
    OptionsFormFieldProps {
  className?: string
  maxLength?: number
  hideExtraRow?: boolean
  onSelectionChange?: (selection: ReactDataSheet.Selection) => void
  saveQuestion?: () => void
}

const mapDispatchToProps = (dispatch: TsaDispatch): DatasheetDispatchProps => ({
  clearMessage: (
    engagementId: number,
    questionId: number,
    message: Message
  ) => {
    dispatch(
      rulesActions.ruleClearMessageAction({ engagementId, questionId, message })
    )
  }
})

class Datasheet extends React.Component<DatasheetProps, DatasheetState>
  implements FocusableComponent {
  static defaultProps = {
    showAlerts: true
  }

  // tslint:disable-next-line:no-any - required by interface
  static formatValueLastYear = (value: any, props: any): JSX.Element => {
    props.value = value // Setting to last year's value
    props.messages = [] // Clear messages
    return <ReadOnlyDataSheet disableEditMode={true} {...props} />
  }

  state: DatasheetState

  isPasting: boolean = false
  inputId: string
  labelId: string
  hintId: string
  input?: ComponentReactDataSheet
  shiftDown: boolean = false
  tableContainer?: HTMLDivElement
  gridContentRef?: HTMLDivElement

  constructor(props: DatasheetProps) {
    super(props)

    const id = uniqueId('textbox_')
    this.inputId = `${id}_input`
    this.labelId = `${id}_label`
    this.hintId = `${id}_hint`

    this.state = {
      grid: [],
      columns: [],
      columnsLength: 0,
      columnPathLookup: {},
      pathColumnLookup: {},
      columnDefinitionLookup: {},
      sheetHeader: [],
      messages: {},
      pasteError: null,
      selectedRow: 0,
      selectedCol: 0,
      currentRows: 0,
      maxRows: defaultMaxRows,
      minRows: defaultMinRows,
      headerWidth: null,
      rowHeaders: null,
      scrollLeft: 0,
      scrollTop: 0,
      selected: {
        start: {
          i: 0,
          j: 0
        },
        end: {
          i: 0,
          j: 0
        }
      },
      selectedRowCount: 1
    }

    this.addRows = this.addRows.bind(this)
    this.copyAll = this.copyAll.bind(this)
    this.deleteRows = this.deleteRows.bind(this)
    this.focus = this.focus.bind(this)
    this.getPath = this.getPath.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleCellsChanged = this.handleCellsChanged.bind(this)
    this.handleContextMenuOptionClick = this.handleContextMenuOptionClick.bind(
      this
    )
    this.handleFocus = this.handleFocus.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onContinueValueTruncate = this.onContinueValueTruncate.bind(this)
    this.parsePaste = this.parsePaste.bind(this)
    this.renderCell = this.renderCell.bind(this)
    this.renderRow = this.renderRow.bind(this)
    this.renderSheet = this.renderSheet.bind(this)
    this.selectPath = this.selectPath.bind(this)
    this.setInput = this.setInput.bind(this)
    this.setTableContainer = this.setTableContainer.bind(this)
    this.setGridContentRef = this.setGridContentRef.bind(this)

    let nextIndex = 0
    processSchema({
      datasheetProps: props,
      property: (column: SchemaProperty) =>
        (nextIndex = getColumn(column, this.state, nextIndex)),
      rootObject: (
        type: 'array' | 'object',
        minItems?: number,
        maxItems?: number,
        headerWidth?: string,
        rowHeaders?: string[]
      ) =>
        getRootObject(
          type,
          minItems,
          maxItems,
          headerWidth,
          rowHeaders,
          this.state
        )
    })

    buildHeader(this.state)

    convertMessages(this.state, props.messages)
    convertValue(
      this.state,
      props.value,
      undefined,
      props.hideExtraRow,
      this.handleCellsChanged
    )

    if (
      this.state.currentRows < this.state.minRows &&
      props.onBulkChange &&
      this.state.minRows === this.state.maxRows
    ) {
      const propertyUpdates: PropertyValues = {}
      propertyUpdates[`[${this.state.minRows - 1}]`] = null
      props.onBulkChange(propertyUpdates, true, true)
    }
  }

  componentDidMount() {
    const {
      input,
      props: { getRef, path, selectedPath }
    } = this

    if (selectedPath && path && path === selectedPath) {
      this.focus()
      this.selectPath(this.state, selectedPath)
    }

    this.selectFirstCell(this.tableContainer)

    // tslint:disable-next-line:no-any - needed to get the focusable item in the datasheet and add a listener to it
    const dgDom = (input as any).dgDom
    if (dgDom) {
      this.handleClick = this.handleClick.bind(this)
      this.handleContextMenu = this.handleContextMenu.bind(this)
      this.onKeyDown = this.onKeyDown.bind(this)
      this.onKeyUp = this.onKeyUp.bind(this)
      dgDom.addEventListener('click', this.handleClick)
      dgDom.addEventListener('contextmenu', this.handleContextMenu)
      dgDom.addEventListener('focus', this.handleFocus)
      dgDom.addEventListener('keydown', this.onKeyDown)
      dgDom.addEventListener('keyup', this.onKeyUp)
    }

    if (getRef) {
      getRef(this)
    }
  }

  componentWillUnmount() {
    const { input } = this
    // tslint:disable-next-line:no-any - needed to get the focusable item in the datasheet and add a listener to it
    const dgDom = (input as any).dgDom
    if (dgDom) {
      dgDom.removeEventListener('click', this.handleClick)
      dgDom.removeEventListener('focus', this.handleFocus)
      dgDom.removeEventListener('keydown', this.onKeyDown)
      dgDom.removeEventListener('keyup', this.onKeyUp)
    }
  }

  // eslint-disable-next-line
  async UNSAFE_componentWillReceiveProps(nextProps: DatasheetProps) {
    const {
      selectPath,
      getPath,
      state,
      props: { jsonSchema, value, messages, onFocus, selectedPath }
    } = this
    const nextState = { ...state }
    let updateState = false

    if (
      nextProps.path &&
      nextProps.selectedPath &&
      nextProps.path === nextProps.selectedPath
    ) {
      this.focus()
    }

    if (jsonSchema !== nextProps.jsonSchema) {
      updateState = true
      nextState.columns = []
      nextState.sheetHeader = []
      nextState.columnPathLookup = {}
      nextState.pathColumnLookup = {}
      nextState.columnDefinitionLookup = {}
      nextState.columnsLength = 0
      let nextIndex = 0
      processSchema({
        rootObject: (
          type: 'array' | 'object',
          minItems?: number,
          maxItems?: number,
          headerWidth?: string,
          rowHeaders?: string[]
        ) =>
          getRootObject(
            type,
            minItems,
            maxItems,
            headerWidth,
            rowHeaders,
            nextState
          ),
        property: (column: SchemaProperty) =>
          (nextIndex = getColumn(column, nextState, nextIndex)),
        datasheetProps: nextProps
      })
      buildHeader(nextState)
    }

    if (messages !== nextProps.messages) {
      updateState = true
      nextState.messages = {}
      convertMessages(nextState, nextProps.messages)
    }

    if (value !== nextProps.value || messages !== nextProps.messages) {
      updateState = true
      nextState.grid = []
      convertValue(
        nextState,
        nextProps.value,
        undefined,
        nextProps.hideExtraRow,
        this.handleCellsChanged
      )

      if (
        nextState.currentRows < nextState.minRows &&
        nextProps.onBulkChange &&
        value !== nextProps.value &&
        nextState.minRows === nextState.maxRows
      ) {
        const propertyUpdates: PropertyValues = {}
        propertyUpdates[`[${nextState.minRows - 1}]`] = null
        nextProps.onBulkChange(propertyUpdates, true, true)
      }
    }

    if (onFocus !== nextProps.onFocus && nextProps.onFocus) {
      nextProps.onFocus(getPath(state.selectedRow, state.selectedCol))
    }

    if (selectedPath !== nextProps.selectedPath) {
      selectPath(nextState, nextProps.selectedPath)
    }

    if (updateState) {
      this.setState(nextState)
    }
  }

  focus() {
    const input = this.input
    if (input) {
      input.focus()
    }
  }

  handleBlur() {
    const { path, onBlur } = this.props
    if (onBlur) {
      onBlur(path)
    }
  }

  handleClick(e: React.MouseEvent<HTMLSpanElement>) {
    if (e.type === 'click') {
      this.setState({ showContextMenu: false })
      if (e.shiftKey) {
        this.setState({
          selectionStart: this.state.selectionStart || this.state.selected
        })
      }
    }
  }

  handleContextMenu(e: React.MouseEvent<HTMLSpanElement>) {
    if (!this.state.grid || this.state.grid.length === 1) {
      return
    }

    // Don't allow the normal context menu to show
    e.preventDefault()
    e.stopPropagation()

    // lodash's "get" method ensures this is properly null-checked without having to nest eighteen generations or properties. This will need to change if react datasheet is updated...
    const selectedCell = get(
      e,
      `currentTarget.children[0].children[1].children[0].children[0].children[${
        this.state.selected.start.i
      }].children[${this.state.selected.start.j + 1}]`
    )
    if (selectedCell) {
      const selectedCellBoundingClientRect = selectedCell.getBoundingClientRect()

      if (selectedCellBoundingClientRect) {
        this.setState({
          left: selectedCellBoundingClientRect.left,
          showContextMenu: true,
          top: selectedCellBoundingClientRect.bottom
        })
      }
    }
  }

  handleFocus() {
    const {
      tableContainer,
      state: { scrollLeft, scrollTop },
      props: { path, onFocus }
    } = this
    if (tableContainer) {
      tableContainer.scrollLeft = scrollLeft
      tableContainer.scrollTop = scrollTop
    }
    if (onFocus && path) {
      onFocus(path)
    }
  }

  handleCellsChanged(
    changes: ReactDataSheet.CellsChangedArgs<GridElement>,
    additions: ReactDataSheet.CellsChangedArgs<GridElement>
  ) {
    if (!additions && !changes) {
      return
    }

    const {
      getPath,
      state: { columnsLength, maxRows }
    } = this
    let newOrModifiedRows = 0
    let truncatedRows = 0
    let startingRow = this.state.grid.length
    const propertyUpdates: PropertyValues = {}
    if (additions) {
      const rows = new Map()
      additions.reduce((acc, cv) => {
        if (cv.col < columnsLength) {
          acc.set(cv.row, 1)
        }
        return acc
      }, rows)
      newOrModifiedRows = rows.size
      truncatedRows = newOrModifiedRows + this.state.grid.length - maxRows
      additions.forEach(({ cell, row, col, value }) => {
        if (row >= maxRows || col >= columnsLength) {
          return
        }
        propertyUpdates[getPath(row, col)] = this.convertOptionsValue(
          col,
          value
        )
      })
    }

    if (changes) {
      const rows = new Map()
      changes.forEach(({ cell, row, col, value }) => {
        rows.set(row, 1)
        propertyUpdates[getPath(row, col)] = this.convertOptionsValue(
          col,
          value
        )
      })
      startingRow -= rows.size
      newOrModifiedRows += rows.size
    }

    if (this.isPasting && truncatedRows > 0) {
      this.setState({
        pasteError: {
          startingRow: startingRow + 1,
          truncatedRows,
          usedRows: newOrModifiedRows - truncatedRows
        }
      })
    }

    this.isPasting = false
    if (this.props.onBulkChange) {
      setTimeout(this.props.onBulkChange, delay, propertyUpdates)
    }
  }

  convertOptionsValue(column: number, value: string | null) {
    // We only try to do an options list lookup when we are pasting
    if (!this.isPasting) {
      return value
    }

    const { codeListIdToOptionsId, optionLists } = this.props
    const fieldSchema = this.state.columnDefinitionLookup[column]

    if (fieldSchema && fieldSchema.codeListId) {
      const fieldOptions = optionsListLookup(
        fieldSchema,
        codeListIdToOptionsId,
        optionLists
      )
      const option = findOptionValue(fieldOptions, value)
      return option &&
        (fieldSchema.component !== 'select' || !option.codeNotInList)
        ? optionToObject(option)
        : null
    }

    return value
  }

  handleContextMenuOptionClick(aboveBelowDelete: string) {
    const {
      addRows,
      deleteRows,
      state: { selected }
    } = this

    const sortedIndices = [selected.end.i, selected.start.i].sort()
    const rowIndices = range(sortedIndices[0], sortedIndices[1] + 1)

    switch (aboveBelowDelete) {
      case 'above':
      case 'below':
        addRows(aboveBelowDelete)
        return
      case '':
        deleteRows(rowIndices)
        break

      default:
        break
    }
  }

  addRows(aboveOrBelow: string) {
    const {
      props: { onChange, value },
      state: { selected }
    } = this

    if (onChange && Array.isArray(value)) {
      const sortedIndices = [selected.end.i, selected.start.i].sort()
      const rowIndices = range(sortedIndices[0], sortedIndices[1] + 1)
      const insertIndex =
        aboveOrBelow === 'below'
          ? Math.max(...sortedIndices) + 1
          : Math.min(...sortedIndices)
      rowIndices.forEach(rowIndex => value.splice(insertIndex, 0, {}))
      this.setState({ showContextMenu: false }, () => onChange(value, ''))
    }
  }

  deleteRows(rowIndices: number[]) {
    const {
      props: {
        clearMessage,
        engagementId,
        messages,
        onChange,
        questionId,
        value
      }
    } = this
    if (onChange && Array.isArray(value)) {
      rowIndices.reverse().forEach(rowIndex => {
        if (messages) {
          const lastRowProperty = '[' + (value.length - 1) + ']'
          messages.forEach(message => {
            if (message.path && message.path.startsWith(lastRowProperty)) {
              clearMessage(Number(engagementId), Number(questionId), message)
            }
          })
        }

        value.splice(rowIndex, 1)
      })

      this.setState({ showContextMenu: false }, () => {
        onChange(value, '')
        setTimeout(() => {
          if (this.props.saveQuestion) {
            this.props.saveQuestion()
          }
        })
      })
    }
  }

  copyAll = () => {
    this.buildCopyString(this.state.grid, this.state.columnDefinitionLookup)
  }

  buildCopyString = (data: GridElement[][], headerList: any) => {
    const hiddenInput = document.createElement('textarea')
    document.body.appendChild(hiddenInput)
    const columnCount = Object.keys(headerList).length - 1
    const gridData = [...data]
    let delimitedString = ''
    let i = 0
    for (const index in headerList) {
      if (headerList[index] && headerList[index].title) {
        const title = headerList[index].title.replace(/\r\n|\n|\r/m, ' ')
        delimitedString += i === columnCount ? `${title}\r\n` : `${title}\t`
        i++
      }
    }
    gridData.forEach((row: any) => {
      const rowData = [...row]
      let j = 0
      rowData.forEach((cell: any) => {
        const cellData = { ...cell }
        //added a null check to the below. In JS, null is a type of object, so a null value would get beyond just a type check for object.
        if (isObject(cellData.value)) {
          cellData.value = cellData.value[Object.keys(cellData.value)[0]]
        }
        // added this check to make sure any null objects that get through get coerced to strings.
        if (cellData.value === null) {
          cellData.value = 'null'
        }
        cellData.value = cellData.value === undefined ? '' : cellData.value
        delimitedString +=
          j === columnCount ? `${cellData.value}\r\n` : `${cellData.value}\t`
        j++
      })
    })
    hiddenInput.value = delimitedString
    hiddenInput.select()
    document.execCommand('copy')
    document.body.removeChild(hiddenInput)
  }

  parsePaste(str: string) {
    this.isPasting = true
    str = str.replace(/\r\n$|\n$|\r$/, '')

    // Excel clipboard uses TAB for the seperator and wont double-quote fields comtaining commas
    // As a result we can't currently support pasting of CSV
    const config = {
      delimiter: '\t'
    }

    const result = parse(str, config)
    return result.data as string[][]
  }

  selectFirstCell(el?: HTMLElement): any {
    if (!el) {
      return this.selectFirstCell(this.tableContainer)
    }

    if (el.nodeName === 'SPAN') {
      el.focus()
      return el
    }

    return find(
      compact(el.children),
      (child: HTMLElement) => !!this.selectFirstCell(child)
    )
  }

  setGridContentRef(ref: HTMLDivElement) {
    this.gridContentRef = ref
  }

  setInput(ref: ComponentReactDataSheet) {
    this.input = ref
  }

  setSelection(
    selection: ReactDataSheet.Selection,
    selectedCol: number,
    selectedRow: number,
    isWholeRow?: boolean
  ): void {
    const { onSelectionChange } = this.props
    const startEnd = [
      selection.start.i,
      selection.end.i
    ].sort((first, second) => (first < second ? 1 : -1))

    this.setState(
      {
        isWholeRow,
        selected: selection,
        selectedCol,
        selectedRow,
        selectedRowCount: startEnd[0] - startEnd[1] + 1,
        showContextMenu: false
      },
      () => {
        if (onSelectionChange) {
          onSelectionChange(selection)
        }
      }
    )
  }

  onClose() {
    this.setState({ pasteError: null })
  }

  onContinueValueTruncate() {
    const {
      state: { maxRows },
      props: { value, onChange }
    } = this
    this.setState({ pasteError: null }, () => {
      if (onChange && Array.isArray(value)) {
        value.splice(maxRows)
        onChange(value)
      }
    })
  }

  renderSheet(props: ReactDataSheet.SheetRendererProps<GridElement>) {
    const {
      state: { sheetHeader, headerWidth }
    } = this
    return (
      <SheetRenderer
        columns={sheetHeader}
        headerWidth={headerWidth || undefined}
        setGridContentRef={this.setGridContentRef}
        {...props}
      />
    )
  }

  renderRow(props: SheetRowProps) {
    const {
      state: { columnsLength, headerWidth, rowHeaders }
    } = this
    const rowClicked = (rowNumber: number) => (
      e: React.MouseEvent<HTMLTableDataCellElement>
    ) => {
      const rowNumberIndex = rowNumber - 1
      this.setSelection(
        {
          end: { i: rowNumberIndex, j: 0 },
          start: { i: rowNumberIndex, j: columnsLength }
        },
        0,
        rowNumberIndex,
        true
      )
    }

    return (
      <PureRowRenderer
        rowHeaders={rowHeaders || undefined}
        headerWidth={headerWidth || undefined}
        {...props}
        rowClicked={rowClicked}
      />
    )
  }

  renderCell(props: ReactDataSheet.CellRendererProps<GridElement>) {
    const {
      state: { columnDefinitionLookup, sheetHeader }
    } = this
    const colDef = columnDefinitionLookup[props.col]
    let alignment: string | undefined
    if (colDef) {
      alignment = colDef.alignment
    }

    return (
      <PureCellRenderer
        alignment={alignment}
        columns={
          sheetHeader.length > 0 ? sheetHeader[sheetHeader.length - 1] : []
        }
        gridContentRef={this.gridContentRef}
        {...props}
      />
    )
  }

  handleSelect(selection: ReactDataSheet.Selection, hasDelayed?: boolean) {
    if (!hasDelayed) {
      setTimeout(this.handleSelect, delay, selection, true)
      return
    }

    const {
      getPath,
      props: { onBlur, onFocus }
    } = this
    if (onFocus) {
      if (this.state.selectionStart) {
        selection.end = this.state.selectionStart.start
      }

      const row = Math.min(selection.start.i, selection.end.i)
      const col = Math.min(selection.start.j, selection.end.j)
      onFocus(getPath(row, col))

      this.setSelection(selection, col, row)
    }

    if (onBlur) {
      onBlur()
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.shiftKey) {
      this.shiftDown = true
      this.setState({
        selectionStart: this.state.selectionStart || this.state.selected
      })
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.key === 'Shift') {
      this.shiftDown = false
      this.setState({ selectionStart: undefined })
    }

    if (e.key === 'Delete') {
      if (this.state.isWholeRow) {
        this.deleteRows([this.state.selectedRow])
      }
    }
  }

  selectPath(state: DatasheetState, path?: string) {
    if (!path) {
      return
    }
    const { input, focus } = this
    if (!input) {
      return
    }
    const { pathColumnLookup, selectedCol, selectedRow } = state
    const parts = toPath(path)
    const row = parseInt(parts[0], 10)
    const col = pathColumnLookup[parts.splice(1).join('.')]
    if (
      col !== undefined &&
      !isNaN(row) &&
      (col !== selectedCol || row !== selectedRow)
    ) {
      state.selected = {
        start: {
          i: row,
          j: col
        },
        end: {
          i: row,
          j: col
        }
      }
      state.selectedCol = col
      state.selectedRow = row
      focus()
    }
  }

  getPath(row: number, col: number) {
    const {
      state: { columnPathLookup }
    } = this
    return `[${row}].${columnPathLookup[col]}`
  }

  // tslint:disable-next-line:no-any - scroll has no event type
  handleScroll(e: any) {
    this.setState({
      scrollLeft: e.currentTarget.scrollLeft,
      scrollTop: e.currentTarget.scrollTop
    })
  }

  setTableContainer(e: HTMLDivElement) {
    this.tableContainer = e
  }

  render() {
    const {
      handleCellsChanged,
      handleContextMenuOptionClick,
      handleSelect,
      onClose,
      onContinueValueTruncate,
      parsePaste,
      renderSheet,
      renderRow,
      renderCell,
      state: {
        columnDefinitionLookup,
        grid,
        left,
        maxRows,
        pasteError,
        selected,
        selectedRowCount,
        showContextMenu,
        top
      },
      props: { showAlerts, messages }
    } = this
    let modal: JSX.Element | undefined

    const highestSelectedRow = Math.max(...[selected.start.i, selected.end.i])
    const rowCount = grid.length

    const singleOrPlural = selectedRowCount > 1 ? 's' : ''
    const labelBase = 'Add row' + singleOrPlural

    const options: Option[] = []
    if (rowCount < maxRows) {
      options.push({
        label: labelBase,
        value: 'above'
      })
      options.push({
        label: labelBase,
        value: 'below'
      })
    }

    if (highestSelectedRow < rowCount - 1) {
      options.push({
        label: 'Delete row' + singleOrPlural,
        value: ''
      })
    }

    const contextMenu = (
      <div className={classNames(showContextMenu ? '' : 'hide')}>
        <DatasheetContextMenu
          className='datasheet-context-menu'
          itemClicked={handleContextMenuOptionClick}
          left={left}
          options={options}
          top={top}
        />
      </div>
    )

    if (pasteError) {
      if (pasteError.isValueSet) {
        modal = (
          <WarningDialog
            title='Warning'
            info={"Last Year's value has been truncated"}
            additional={`Only the first ${pasteError.usedRows} record${
              pasteError.usedRows !== 1 ? 's have' : ' has'
            } used from last year's value.  ${pasteError.truncatedRows} record${
              pasteError.truncatedRows !== 1 ? 's have' : ' has'
            } been truncated.`}
            primaryButtonText='Continue'
            onClickPrimary={onContinueValueTruncate}
            onClose={onContinueValueTruncate}
          />
        )
      } else {
        modal = (
          <WarningDialog
            title='Warning'
            info='Your paste has been truncated'
            additional={`Only the first ${pasteError.usedRows} record${
              pasteError.usedRows !== 1 ? 's have' : ' has'
            } pasted from your starting row of ${pasteError.startingRow}. ${
              pasteError.truncatedRows
            } record${
              pasteError.truncatedRows !== 1 ? 's have' : ' has'
            } been truncated.`}
            primaryButtonText='Continue'
            onClickPrimary={onClose}
            onClose={onClose}
          />
        )
      }
    }
    return (
      <div
        ref={this.setTableContainer}
        className='table-container'
        onScroll={this.handleScroll}
      >
        <ComponentReactDataSheet
          cellRenderer={renderCell}
          data={grid}
          onCellsChanged={handleCellsChanged as any}
          onSelect={handleSelect}
          parsePaste={parsePaste}
          ref={this.setInput}
          rowRenderer={renderRow}
          schema={columnDefinitionLookup}
          selected={selected}
          sheetRenderer={renderSheet}
          valueRenderer={ValueRenderer}
        />
        {showAlerts && <Alerts messages={messages} />}
        {modal}
        {contextMenu}
      </div>
    )
  }
}

export default connect(undefined, mapDispatchToProps)(Datasheet)

// tslint:disable:no-any
import { ensureNumber } from '../guards'
import { acquireToken } from '../oidc-auth/oidcHelper'
import { Abort, createAbort } from './abort'
import { ExtError } from './error'
import { REACT_APP_GLOBALHTTPREQUESTTIMEOUTINSECS } from '../envVariables'

declare const window: any

export class BlobResponse {
  file: Promise<Blob>
  filename: string

  constructor(response: Response) {
    let header = response.headers.get('content-disposition') || ''
    this.file = response.blob()
    header = header.split(';')[1].trim()
    header = header.split('=')[1].replace(/"/g, '')
    this.filename = header
  }
}

function isBlobResponse(response: Response) {
  return response.headers.get('content-disposition')
}

function isJsonResponse(response: Response) {
  const contentType = response.headers.get('content-type')
  return contentType && contentType.indexOf('application/json') > -1
}

function isErrorResponse(response: Response) {
  return response.status >= 400
}

/**
 * Handle the response from fetch.
 */
function handleResponse(response: Response) {
  if (isErrorResponse(response)) {
    return response.text().then((v) => {
      const err = new ExtError(response.statusText, response.status)
      err.body = v
      throw err
    })
  }

  if (isJsonResponse(response)) {
    return response.json()
  }

  if (isBlobResponse(response)) {
    return new BlobResponse(response)
  }

  return response.text()
}

/**
 * Make an HTTP request using fetch with support for timeout and cancellation.
 */
function fetchRequest(
  url: string,
  options: RequestInit = {},
  abort: Abort,
  timeout: number
): Promise<Response> {
  return new Promise<Response>((resolve, reject) => {
    const timer = setTimeout(() => {
      abort.timeout()
    }, timeout * 1000)

    const headers: any = options.headers || {}

    const impersonationUserId = sessionStorage.getItem('ImpersonationUserId')
    if (impersonationUserId !== null && impersonationUserId !== '') {
      headers.ImpersonationUserId = impersonationUserId
    }

    const debugPermissions = sessionStorage.getItem('DebugPermissions')

    if (debugPermissions === 'true') {
      const userPermissions = sessionStorage.getItem('UserPermissions')
      const impersonationPermissions = sessionStorage.getItem(
        'ImpersonationPermissions'
      )

      headers.DebugPermissions = debugPermissions
      headers.UserPermissions = userPermissions
      headers.impersonationPermissions = impersonationPermissions
    }

    options.headers = headers

    fetch(url, options)
      .then((response) => {
        clearTimeout(timer)
        resolve(response)
      })
      .catch((reason) => {
        clearTimeout(timer)
        if (reason.name === 'AbortError') {
          if (abort.silent) {
            reject(new ExtError('AbortError', -1, true))
          }
          if (abort.timedOut) {
            reject(
              new ExtError(
                `Http request timed out after ${timeout} seconds`,
                -1
              )
            )
          }
        }
        reject(new Error(reason))
      })
  })
}

/**
 * Make an HTTP request using supplied options.
 */
function request(
  url: string,
  options: RequestInit = {},
  abort?: Abort,
  timeout?: number
) {
  return acquireToken()
    .then((token) => {
      const headers: any = options.headers || {}
      headers.Authorization = `Bearer ${token}`

      const impersonationUserId = sessionStorage.getItem('ImpersonationUserId')
      if (impersonationUserId !== null && impersonationUserId !== '') {
        headers.ImpersonationUserId = impersonationUserId
      }

      const debugPermissions = sessionStorage.getItem('DebugPermissions')

      if (debugPermissions === 'true') {
        const userPermissions = sessionStorage.getItem('UserPermissions')
        const impersonationPermissions = sessionStorage.getItem(
          'ImpersonationPermissions'
        )

        headers.DebugPermissions = debugPermissions
        headers.UserPermissions = userPermissions
        headers.impersonationPermissions = impersonationPermissions
      }

      options.headers = headers
      const fetchAbort = abort || createAbort()
      options.signal = fetchAbort.signal
      const timeoutInSeconds =
        timeout ||
        ensureNumber(REACT_APP_GLOBALHTTPREQUESTTIMEOUTINSECS) ||
        30

      return fetchRequest(url, options, fetchAbort, timeoutInSeconds)
    })
    .then(handleResponse)
}

export function get(url: string, abort?: Abort, timeout?: number) {
  return request(url, undefined, abort, timeout)
}

export function remove(
  url: string,
  options?: RequestInit,
  abort?: Abort,
  timeout?: number
) {
  return request(
    url,
    buildRequestInit('DELETE', undefined, options),
    abort,
    timeout
  )
}

const defaultRequestOptions = {
  headers: { 'Content-Type': 'application/json' },
}

export function post(
  url: string,
  data: any,
  options?: RequestInit,
  abort?: Abort,
  timeout?: number
) {
  // defaults that can be overridden by options
  options = {
    ...defaultRequestOptions,
    ...options,
  }
  return request(url, buildRequestInit('POST', data, options), abort, timeout)
}

export function put(
  url: string,
  data: any,
  options?: RequestInit,
  abort?: Abort,
  timeout?: number
) {
  // defaults that can be overridden by options
  options = {
    ...defaultRequestOptions,
    ...options,
  }
  return request(url, buildRequestInit('PUT', data, options), abort, timeout)
}

export function patch(
  url: string,
  data: any,
  options?: RequestInit,
  abort?: Abort,
  timeout?: number
) {
  // defaults that can be overridden by options
  options = {
    ...defaultRequestOptions,
    ...options,
  }
  return request(url, buildRequestInit('PATCH', data, options), abort, timeout)
}

function buildRequestInit(method: string, data: any, options?: RequestInit) {
  const opts = Object.assign({}, options, { method })
  opts.body = isJsonRequest(opts) ? JSON.stringify(data) : data
  return opts
}

function isJsonRequest(init: RequestInit) {
  const headers: any = init.headers
  const contentType = headers && headers['Content-Type']
  return contentType && contentType.indexOf('application/json') > -1
}

export function saveBlob(response: BlobResponse) {
  return response.file.then((blob) => {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveBlob(blob, response.filename)
    } else {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = response.filename
      document.body.appendChild(link)
      link.click()
    }
  })
}

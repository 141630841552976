import { getUserInfoAsync } from './adminCenterActions'
import { TsaThunkAction } from '../store'
import * as http from '../services/http'
import { REACT_APP_AUTHORIZATIONSERVICEURL } from '../envVariables'

// let controller = new AbortController()

export const getUserInfo = (): TsaThunkAction => async dispatch => {
  // Abort an in-flight request if there is one
  // controller.abort()

  try {
    if (!REACT_APP_AUTHORIZATIONSERVICEURL) {
      throw new Error('Could not find authorization url')
    }
    dispatch(getUserInfoAsync.request())
    const response = await http.get(
      `${REACT_APP_AUTHORIZATIONSERVICEURL}/openid/userinfo`
    )
    dispatch(getUserInfoAsync.success(response))
  } catch (error) {
    dispatch(getUserInfoAsync.failure(error))
  }
}

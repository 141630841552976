import { Engagement } from '../clientModels'
import { MilestoneCode } from '../enums'
import { ensureNumber } from '../guards'
import { EngagementsApi } from '../services/api'
import { trackSave } from '../services/track'
import { AppState, TsaThunkAction } from '../store'
import * as actions from './engagementActions'
import { getEngagement } from './engagementThunks'
import { normalizeEngagement } from './normalization'

export const completeEngagementMilestone = (
  engagementId: number,
  milestoneCode: MilestoneCode,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)

    let engagement = selectEngagement(getState, engagementId)
    if (!engagement.loaded) {
      // If the engagement is not fully loaded we need load it now so we can update it and save a new version
      await dispatch(getEngagement(engagementId))
      engagement = selectEngagement(getState, engagementId)
    }

    const milestone =
      engagement.milestones &&
      engagement.milestones.find(x => x.milestone === milestoneCode)
    if (!milestone) {
      throw new Error(`Could not find engagement milestone ${milestoneCode}`)
    }
    if (milestone.completedDate) {
      // Milestone is already completed so return early
      return
    }

    const savedEngagement = await trackSave(
      EngagementsApi.apiCompleteEngagementMilestone,
      dispatch,
      id,
      engagementId,
      milestoneCode
    )
    const engagementMap = normalizeEngagement(savedEngagement).engagements

    dispatch(
      actions.updateEngagementMilestoneAction.success({
        engagementId,
        engagements: engagementMap,
      })
    )
  } catch (error) {
    dispatch(actions.updateEngagementMilestoneAction.failure(error))
  }
}

function selectEngagement (
  getState: () => AppState,
  engagementId: string | number
): Engagement {
  const engagement = getState().engagements[ensureNumber(engagementId)]
  if (!engagement) {
    throw new Error('Could not find engagement')
  }
  return engagement
}

import { createAction, ActionType } from 'typesafe-actions'
import { DocumentTitle } from '../services/api/apiModels'

export const documentTitlesGetBeginAction = createAction(
  'GET_DOCUMENT_TITLES_BEGIN'
)<{}>()

export const documentTitlesGetEndAction = createAction(
  'GET_DOCUMENT_TITLES_END'
)<{ payload: DocumentTitle[] }>()

export const documentTitlesGetErrorAction = createAction(
  'GET_DOCUMENT_TITLES_ERROR'
)<{
  error: Error
}>()

export type DocumentTitleActions =
  | ActionType<typeof documentTitlesGetBeginAction>
  | ActionType<typeof documentTitlesGetEndAction>
  | ActionType<typeof documentTitlesGetErrorAction>

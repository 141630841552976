import classNames from 'classnames'
import React from 'react'
import { File } from '../../clientModels'
import Icon from '../icon/icon'
import Modal from '../modals/modal'
import './fileUploadDialog.scss'
import { FileUploadDialogFile } from './fileUploadDialogFile'

export interface FileUploadDialogSecondPhaseProps {
  disabledButton: boolean
  files: File[]
  handleAdd: () => void
  handleClose: () => void
  handleDelete: (deletedFile: File) => void
}

export function FileUploadDialogSecondPhase ({
  disabledButton,
  files,
  handleAdd,
  handleClose,
  handleDelete,
}: FileUploadDialogSecondPhaseProps) {
  return (
    <Modal>
      <div className='modal file-upload-dialog'>
        <div className='modal-body'>
          <div className='file-dialog-header'>
            <div className='file-dialog-icon'>
              <Icon icon={'fileAlt'} />
            </div>
            <button
              id='modal-button-close'
              type='button'
              className='close'
              aria-label='Close'
              onClick={handleClose}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='file-dialog-duplicate-warning'>
            The file(s) indicated below have the same name as files already
            uploaded.
          </div>
          <div className='file-dialog-duplicate-warning-info'>
            They will overwrite existing files unless you remove them from this
            <br />
            list and add again with a different name.
          </div>
          <div className='file-dialog-files'>
            <div className='file-dialog-files-container'>
              {files.map(file => (
                <FileUploadDialogFile
                  key={file.name}
                  file={file}
                  duplicate={file.isUpdated}
                  onDelete={handleDelete}
                />
              ))}
            </div>
          </div>
          <div className='file-dialog-action'>
            <button
              type='button'
              className={classNames('btn btn-block file-upload-btn', {
                disabled: disabledButton,
              })}
              disabled={disabledButton}
              onClick={handleAdd}
            >
              Add Documents
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

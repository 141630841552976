import * as React from 'react'

interface Properties {
  elementId: string
}
class DomRemover extends React.Component<Properties> {
  componentDidMount () {
    const { elementId } = this.props
    const el = document.getElementById(elementId)
    if (el) {
      el.outerHTML = ''
    }
  }

  shouldComponentUpdate () {
    return false
  }

  render () {
    return null
  }
}

export default DomRemover

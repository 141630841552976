import { TsaHubAction } from '../actions/index'
import { SearchResult } from '../clientModels'
import { getType } from 'typesafe-actions'
import {
  searchActionSearch,
  searchActionClearResults,
  searchActionSelectResult,
  searchActionClear,
} from '../actions/searchActions'

export interface SearchState {
  searchString: string
  selectedSearchResult?: SearchResult
  results?: SearchResult[]
}

const initialState: SearchState = {
  searchString: '',
}

export function searchReducer (
  state: SearchState = initialState,
  action: TsaHubAction
): SearchState {
  switch (action.type) {
    default:
      return state

    case getType(searchActionClear):
      return initialState

    case getType(searchActionClearResults):
      delete state.results
      delete state.selectedSearchResult
      return { ...state, searchString: action.payload.searchString }
      // return {
      //   ...state,
      //   searchString: searchActionClearResults.prototype.type.searchString,
      // }

    case getType(searchActionSelectResult):
      return { ...state, ...action.payload }
      // return { ...state, ...searchActionSelectResult.prototype.type }

    case getType(searchActionSearch):
      delete state.selectedSearchResult
      return { ...state, ...action.payload }
    // return { ...state, ...searchActionSearch.prototype.type }
  }
}

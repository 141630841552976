import { getType } from 'typesafe-actions'
import { getEngagementAction } from '../actions/engagementActions'
import { TsaHubAction } from '../actions/index'
import {
  showAllQuestionsAction,
  showIncompleteOnlyAction,
} from '../actions/engagementQuestionActions'

interface EngagementVisibility {
  showAllQuestions: boolean
  showIncompleteOnly: boolean
}

export interface VisibilityState {
  [engagementId: string]: EngagementVisibility | undefined
  [engagementId: number]: EngagementVisibility | undefined
}

const initialState: VisibilityState = {}

export function visibilityReducer (
  state: VisibilityState = initialState,
  action: TsaHubAction
): VisibilityState {
  switch (action.type) {
    default:
      break

    case getType(getEngagementAction.success):
      return {
        [action.payload.engagementId]: {
          showAllQuestions: false,
          showIncompleteOnly: false,
        },
      }

    case getType(showAllQuestionsAction):
    case getType(showIncompleteOnlyAction):
      return {
        ...state,
        [action.payload.engagementId]: engagementVisibilityReducer(
          state[action.payload.engagementId],
          action
        ),
      }
  }

  return state
}

const initialEngagementVisibility: EngagementVisibility = {
  showAllQuestions: false,
  showIncompleteOnly: false,
}

export function engagementVisibilityReducer (
  state: EngagementVisibility = initialEngagementVisibility,
  action: TsaHubAction
): EngagementVisibility {
  switch (action.type) {
    default:
      return state

    case getType(showAllQuestionsAction):
      return {
        ...state,
        showAllQuestions: !state.showAllQuestions,
        showIncompleteOnly: false,
      }

    case getType(showIncompleteOnlyAction):
      return {
        ...state,
        showIncompleteOnly: !state.showIncompleteOnly,
        showAllQuestions: false,
      }
  }
}

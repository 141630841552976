import { Alert, Alerts, GeneralAlerts, PathAlerts } from './alerts/index'
import CheckBoxes from './checkBoxes'
import ComboBox from './combobox'
import ComboBoxCreateable from './comboboxCreateable'
import { ComboBoxCreateableMulti, ComboBoxMulti } from './comboboxMulti'
import {
  optionsValueAdaptor,
  optionsValuesAdaptor,
} from './comboboxValueAdaptor'
import DateTime from './dateTime'
import { EmptyForm } from './emptyForm'
import { formField } from './formField'
import { hasError } from './formUtilities'
import Number from './number'
import RadioButtons from './radioButtons'
import TextArea from './textarea'
import TextBox from './textbox'
import TextAreaAuto from './textareaAuto'

const CheckBoxesField = formField(CheckBoxes)
const ComboBoxField = formField(optionsValueAdaptor(ComboBox))
const ComboBoxCreateableField = formField(
  optionsValueAdaptor(ComboBoxCreateable)
)
const ComboBoxMultiSelectField = formField(optionsValuesAdaptor(ComboBoxMulti))
const ComboBoxCreateableMultiSelectField = formField(
  optionsValuesAdaptor(ComboBoxCreateableMulti)
)
const DateTimeField = formField(DateTime)
const NumberField = formField(Number)
const RadioButtonsField = formField(RadioButtons)
const TextAreaField = formField(TextArea)
const TextAreaAutoField = formField(TextAreaAuto)
const TextBoxField = formField(TextBox)

export * from './formComponentsInterfaces'
export { normalizeOptions } from './options'
export {
  Alert,
  Alerts,
  CheckBoxesField,
  ComboBox,
  CheckBoxes,
  ComboBoxField,
  ComboBoxCreateableField,
  ComboBoxMultiSelectField,
  ComboBoxCreateableMultiSelectField,
  DateTimeField,
  EmptyForm,
  GeneralAlerts,
  hasError,
  NumberField,
  PathAlerts,
  RadioButtonsField,
  TextAreaField,
  TextAreaAutoField,
  TextBox,
  TextBoxField,
}

import { ensureNumber } from '../guards'
import { TsaThunkAction } from '../store'
import { searchResults } from './searchResults'
import { searchActionSearch, searchActionClearResults } from './searchActions'

export const search = (
  engagementId: string | number,
  searchString: string
): TsaThunkAction => (dispatch, getState) => {
  engagementId = ensureNumber(engagementId)

  const state = getState()
  const engagement = state.engagements[engagementId]
  if (!engagement) {
    throw new Error(`Engagement ${engagementId} not found.`)
  }

  const template = state.engagementTemplates[engagement.engagementTemplateId]
  if (!template) {
    throw new Error('EngagementTemplate not found.')
  }

  if (searchString.length < 3 && !isNumberSearch(searchString)) {
    dispatch(searchActionClearResults({ searchString }))
    return
  }

  const results = searchResults(searchString, template, state, engagementId)

  dispatch(searchActionSearch({ searchString, results }))
}

function isNumberSearch(searchString: string) {
  let c = searchString.charAt(0)
  if (c === '.') {
    c = searchString.charAt(1)
  }
  return !isNaN(parseInt(c, 10))
}

import { FileGroup } from '../../clientModels'
import * as http from '../http'
import { buildApiUrl } from './apiUrl'

export function apiSaveFileGroup(fileGroup: FileGroup): Promise<FileGroup> {
  if (fileGroup.id > 0) {
    return http.put(buildApiUrl('FileGroups'), fileGroup)
  } else {
    return http.post(buildApiUrl('FileGroups'), fileGroup)
  }
}

export function apiGetFileGroups(engagementId: number): Promise<FileGroup[]> {
  return http.get(buildApiUrl(`FileGroups/${engagementId}`))
}

export function apiGetEntityGroupFileGroups(
  entityGroupId: number | string
): Promise<FileGroup[]> {
  return http.get(buildApiUrl(`entitygroups/${entityGroupId}/filegroups`))
}

export function apiDeleteFileGroup(id: number) {
  return http.remove(buildApiUrl(`FileGroups/${id}`))
}

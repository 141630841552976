import { EntityGroup, EntityMap } from '../clientModels'
import { EntityGroupsApi } from '../services/api'
import { TsaThunkAction } from '../store'
import * as actions from './entityActions'
import { normalizeEntityGroups } from './normalization'

export const getEntityGroup = (
  entityGroupId: string | number
): TsaThunkAction => async dispatch => {
  try {
    const period =
      typeof entityGroupId === 'string' ? entityGroupId.indexOf('.') : -1

    const resolvedEntityGroupId =
      period > 0 ? (entityGroupId as string).slice(0, period) : entityGroupId

    dispatch(actions.entityAction.request())
    const entityGroup = await EntityGroupsApi.apiGetEntityGroup(
      resolvedEntityGroupId
    )
    dispatch(actions.entityAction.success(entityGroup))
  } catch (error) {
    dispatch(actions.entityAction.failure(error))
  }
}

export const getAllEntityGroups = (): TsaThunkAction<Promise<
  EntityMap<EntityGroup>
>> => async dispatch => {
  dispatch(actions.allEntitiesAction.request())
  try {
    const result = (await EntityGroupsApi.apiGetAllEntityGroups()) || []
    const groups = normalizeEntityGroups(result)

    dispatch(actions.allEntitiesAction.success(groups))

    return groups
  } catch (error) {
    dispatch(actions.allEntitiesAction.failure(error))
  }

  return {}
}

import React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { Theme } from '@rsmus/react-ui'

function styles({ palette, transitions, typogrphaphy }: Theme) {
  return {
    myAccount: {
      margin: '1rem 0',
    },
    accountHeading: {
      paddingLeft: '1.5rem',
      fontFamily: typogrphaphy.bold.fontFamily,
    },
    accountSubheading: {
      paddingLeft: '1.5rem',
      fontFamily: typogrphaphy.bold.fontFamily,
    },
    accountSubheadingLink: {
      display: 'block',
      color: palette.common.white,
      padding: '0.25rem 1.5rem 0.5rem',
      fontSize: '1rem',
      transition: transitions.create('background-color'),
      '&:hover': {
        backgroundColor: palette.blue.light,
        color: palette.common.white,
        textDecoration: 'none',
      },
      fontFamily: typogrphaphy.normal.fontFamily,
    },
    link: {
      display: 'block',
      color: palette.common.white,
      padding: '0.25rem 1.5rem',
      fontSize: '.75rem',
      transition: transitions.create('background-color'),
      '&:hover': {
        backgroundColor: palette.blue.light,
        color: palette.common.white,
        textDecoration: 'none',
      },
    },
    linkText: {
      fontSize: '1rem',
      paddingLeft: '0.5rem',
      position: 'relative',
      top: '-1px',
    },
    pendingLinkText: {
      fontSize: '1rem',
      paddingLeft: '1.5rem',
      position: 'relative',
      top: '-1px',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface SupportLink {
  text: string
  url: string
}
export interface MyAccountProps {
  support?: SupportLink[]
  accountLinks?: SupportLink[]
}

const MyAccount = ({ support, accountLinks }: MyAccountProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <div className={classes.myAccount}>
      {accountLinks && (
        <React.Fragment>
          <h3 className={classes.accountHeading}>My Account</h3>
          {accountLinks.map((link: SupportLink, i: number) => (
            <h4 key={i}>
              <a
                className={classes.accountSubheadingLink}
                href={link.url}
                target={
                  link.url.indexOf(window.location.origin) > -1
                    ? '_self'
                    : '_blank'
                }
              >
                {link.text}
              </a>
            </h4>
          ))}
        </React.Fragment>
      )}
      <h3 className={classes.accountHeading}>Help and Support</h3>

      {support && (
        <div>
          {support.map((link: SupportLink, i: number) => (
            <React.Fragment key={i}>
              {link.url.length > 0 && (
                <a
                  className={classes.link}
                  href={link.url}
                  target='_blank'
                  key={i}
                  rel="noopener noreferrer"
                >
                  {link.text}
                </a>
              )}
              {link.url.length <= 0 && (
                <span
                  className={classes.pendingLinkText}
                  key={i}
                >{`${link.text} - link coming soon!`}</span>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default MyAccount

import classNames from 'classnames'
import * as React from 'react'
import { MessageSeverity } from '../../../clientModels'
import Icon from '../../icon/icon'
import './alert.scss'

type IconType = 'success' | 'error' | 'warning' | 'none'

const severityIcons: { [severity: string]: IconType } = {
  none: 'none',
  success: 'success',
  warning: 'warning',
  error: 'error',
  critical: 'error',
}

export interface AlertProps {
  messages?: string | string[]
  severity?: MessageSeverity
  type: string
}

/*
• BULLET               2022
● BLACK CIRCLE         25CF
*/

/**
 * Displays one alert message.
 */
const Alert: React.SFC<AlertProps> = ({ messages, severity, type }) => {
  if (!messages) {
    return null
  }
  let all: string[] = []
  all = all.concat(messages)
  const message = severity !== 'success' ? all.join(' ● ') : undefined
  const generalType = type !== 'docs'

  return (
    <div className={classNames('tsa-alert', `tsa-alert-${severity}`)}>
      <Icon
        icon={severityIcons[severity || 'none']}
        className='tsa-alert-icon'
      />
      <div className='tsa-alert-text'>
        <span
          className={classNames('tsa-alert-title', {
            'general-type': generalType,
          })}
        >
          {generalType ? severity : 'Document(s) required'}
        </span>
        {message && ': ' + message}
      </div>
    </div>
  )
}

export default Alert

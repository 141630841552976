import { IdentityTokenProfile } from '../../clientModels'
import * as http from '../http'
import { getNewAbort } from '../lastRequests'
import {
  NotificationSendOptions,
  NotificationTemplate,
  NotificationTemplateOptions,
} from '../api/apiModels'
import { buildApiUrl } from './apiUrl'

const notificationAbort = getNewAbort()

function buildUser(user?: IdentityTokenProfile) {
  if (user) {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.firstName + ' ' + user.lastName,
      email: user.email,
      organizationName: user.organization.name,
    }
  }
  return undefined
}

export function retrieveTemplate(
  transmissionType: string,
  messageType: string,
  options: NotificationTemplateOptions,
  user?: IdentityTokenProfile
): Promise<NotificationTemplate> {
  notificationAbort.abort()
  return http.post(
    buildApiUrl(`notificationMessages/get/${transmissionType}/${messageType}`),
    {
      ...options,
      user: buildUser(user),
    },
    undefined, // options
    notificationAbort.new()
  )
}

export function sendNotification(
  transmissionType: string,
  messageType: string,
  options: NotificationSendOptions,
  user?: IdentityTokenProfile
): Promise<NotificationTemplate> {
  notificationAbort.abort()
  return http.post(
    buildApiUrl(`notificationMessages/send/${transmissionType}/${messageType}`),
    {
      ...options,
      user: buildUser(user),
    },
    undefined, // options
    notificationAbort.new()
  )
}

export function retrieveCommentsTemplate(
  options: NotificationTemplateOptions,
  user?: IdentityTokenProfile
) {
  return retrieveTemplate('email', 'comments', options, user)
}

export function sendCommentsNotification(
  options: NotificationSendOptions,
  user?: IdentityTokenProfile
) {
  return sendNotification('email', 'comments', options, user)
}

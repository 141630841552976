import { set } from 'lodash'
import { DefaultEngagementSectionsQuestionsAction } from '../actions/engagementSectionsQuestionsActions'
import {
  EngagementQuestion,
  EngagementQuestionMap,
  EntityMap,
  IEngagementQuestionAnswer,
  PropertyValues,
  RoleVersions,
} from '../clientModels'
import { RoleCode } from '../enums'
import { EngagementQuestionsState } from './engagementQuestionsReducer'

interface IEngagementQuestionLocator {
  engagementId: number | string
  questionId: number | string
}

let nextAnswerId = 0

export const defaultEngagementQuestion = (
  id: number,
  sectionId: number,
  engagementId: number
): EngagementQuestion => ({
  answerId: --nextAnswerId,
  answerValue: null,
  answerValueLastYear: null,
  commentsCount: 0,
  engagementId,
  questionId: id,
  flagged: false,
  isEnabled: false,
  isVisible: true,
  isVisibleMemory: true,
  messages: [],
  optionalDocumentTitleIds: [],
  requiredDocumentTitleIds: [],
  reviewRolesComplete: new Set(),
  sectionId,
  clientVersion: 0,
  clientFlagVersions: {
    flagged: 0,
    notApplicable: 0,
    reviewRoles: getReviewRoles(),
  },
  answerVersion: 0,
})

function getReviewRoles (): RoleVersions {
  const result: RoleVersions = {}

  for (const role of Object.keys(RoleCode)) {
    result[role] = 0
  }

  return result
}

export function getDefaultMap ({
  payload: { engagementId, questions },
}: DefaultEngagementSectionsQuestionsAction): EngagementQuestionMap {
  return questions.reduce(
    (map, q) => {
      map[q.id] = defaultEngagementQuestion(q.id, q.sectionId, engagementId)
      return map
    },
    {} as EngagementQuestionMap
  )
}

/**
 * Get and engagement question from the current state.
 */
export function getEngagementQuestion (
  state: EngagementQuestionsState,
  { engagementId, questionId }: IEngagementQuestionLocator
) {
  const engagementQuestions = state[engagementId]
  return engagementQuestions && engagementQuestions[questionId]
}

/**
 * Update an EngagementQuestion. If the engagement question is found return the next state
 * otherwise return the current state.
 */
export function updateEngagementQuestion (
  state: EngagementQuestionsState,
  locator: IEngagementQuestionLocator,
  updates: Partial<EngagementQuestion>
): EngagementQuestionsState {
  const engagementQuestions: EngagementQuestionMap =
    state[locator.engagementId] || {}
  const current = engagementQuestions[locator.questionId]

  if (!current) {
    return state
  }

  if (!updates.answerValueLastYear && !!current.answerValueLastYear) {
    updates.answerValueLastYear = current.answerValueLastYear
  }

  return {
    ...state,
    [locator.engagementId]: {
      ...engagementQuestions,
      [locator.questionId]: { ...current, ...updates },
    },
  }
}

export function updateEngagementQuestionVersion (
  state: EngagementQuestionsState,
  locator: IEngagementQuestionLocator,
  updates: Partial<EngagementQuestion>
): EngagementQuestionsState {
  const engagementQuestions: EngagementQuestionMap =
    state[locator.engagementId] || {}
  const current = engagementQuestions[locator.questionId]
  const updateVersion = updates.answerVersion

  if (!current || !updateVersion) {
    return state
  }

  return {
    ...state,
    [locator.engagementId]: {
      ...engagementQuestions,
      [locator.questionId]: { ...current, answerVersion: updateVersion },
    },
  }
}

/**
 * Set the pristine flag and clear the dirty flag if this is the most recent version.
 */
export function setPristine (
  answer?: IEngagementQuestionAnswer,
  savedVersion?: number
) {
  if (!answer) {
    return
  }

  answer.isPristine = true

  // If the answer has one or more timestamps, it's been modified one or more times
  // Only remove isDirty flag if we are the last modification
  answer.isDirty =
    savedVersion !== undefined &&
    answer.clientVersion !== undefined &&
    answer.clientVersion !== savedVersion
}

/**
 * Set the dirty flag and clear the pristine flag
 */
export function setDirty (answer: IEngagementQuestionAnswer) {
  answer.isPristine = false
  answer.isDirty = true
  answer.clientVersion = ++answer.clientVersion
}

interface IQuestionId {
  questionId: number
}

/**
 * Merge two entity maps. The result will include all entities from the union of the two
 * input maps. Entities that exist in both will be new objects with properties in the entity
 * from updatedMap overwriting those in the entity from currentMap.
 */
export function merge<TEntity extends IQuestionId> (
  currentMap: EntityMap<TEntity> = {},
  updatedMap: EntityMap<TEntity> = {}
): EntityMap<TEntity> {
  const map = { ...currentMap }
  Object.values(updatedMap).forEach(updated => {
    if (!updated) {
      return
    }
    const current = map[updated.questionId]
    if (current) {
      map[updated.questionId] = Object.assign({}, current, updated)
    } else {
      map[updated.questionId] = updated
    }
  })
  return map
}

export function partialUpdate (
  answer: IEngagementQuestionAnswer,
  properties: PropertyValues,
  valueType: 'array' | 'object'
): IEngagementQuestionAnswer {
  if (typeof answer.answerValue !== 'object') {
    throw new Error('Expects an answerValue of null, object or array.')
  }

  let answerValue = answer.answerValue || (valueType === 'array' ? [] : {})
  if (Array.isArray(answerValue)) {
    answerValue = [...answerValue]
  } else {
    answerValue = { ...answerValue }
  }
  for (const path in properties) {
    if (Object.prototype.hasOwnProperty.call(properties, path)) {
      set(answerValue, path, properties[path])
    }
  }
  if (Array.isArray(answerValue)) {
    for (let i = 0; i < answerValue.length; ++i) {
      if (!answerValue[i]) {
        answerValue[i] = {}
      }
    }
  }
  return { ...answer, answerValue }
}

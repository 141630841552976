import React, { Fragment } from 'react'
import { ProgressBar } from '../navbar'
import { File } from '../../services/api/apiModels'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'

interface DataFileUploadProgresssProps {
  files: File[]
}

/**
 * DataFileUploadProgresss is a function component that shows progress as files are uploaded.
 */
const DataFileUploadProgresss = ({ files }: DataFileUploadProgresssProps) => {
  const {
    uploadStatus
  } = useSelector((state: AppState) => state.fileUpload)

  let totalFiles = 0
  let finishedFiles = 0

  for (const file of files) {
    const status = uploadStatus[file.name]
    if (!status) {
      continue
    }
    if (
      status === 'Uploading' ||
      status === 'Processing' ||
      status === 'Uploaded'
    ) {
      ++totalFiles
    }
    if (status === 'Uploaded' || status === 'Ready to Map') {
      ++finishedFiles
    }
  }

  return (
    <Fragment>
      {totalFiles > 0 && (
        <ProgressBar
          label={`${finishedFiles} of ${totalFiles} files imported...`}
          percent={(finishedFiles / totalFiles) * 100}
        />
      )}
    </Fragment>
  )
}

export default DataFileUploadProgresss

import { EngagementReportType } from '../../clientModels'
import { ensureNumber } from '../../guards'
import { Abort } from '../abort'
import * as http from '../http'
import { AsyncOperation } from '../api/apiModels'
import { buildApiUrl } from './apiUrl'
import { REACT_APP_ASYNCOPERATIONREQUESTTIMEOUT } from '../../envVariables'

export function apiSubmitReportRequest(
  engagementId: number,
  reportType: EngagementReportType,
  abort?: Abort,
  tags?: string[]
): Promise<AsyncOperation> {
  let url = buildApiUrl(`EngagementReport/${engagementId}`, { reportType })
  if (tags && tags.length > 0) {
    for (const tag of tags) {
      url += `&tag=${tag}`
    }
  }

  return http.post(
    url,
    undefined,
    undefined,
    abort,
    ensureNumber(REACT_APP_ASYNCOPERATIONREQUESTTIMEOUT) || 120
  )
}

import { ActionType, createAsyncAction, createAction } from 'typesafe-actions'
import { IEngagementQuestionAnswer, PropertyValues } from '../clientModels'

export const AnswerWatch = 'answer'

export const answerSetNotApplicableAction = createAction(
  'SET_NOT_APPLICABLE_ANSWER'
)<{
  engagementId: number | string
  questionId: number | string
  notApplicable: boolean
}>()

export const answerSaveAction = createAsyncAction(
  'SAVE_ANSWER',
  'SAVE_ANSWER_DONE',
  'SAVE_ANSWER_FAIL'
)<
  { engagementId: number | string; answer: IEngagementQuestionAnswer },
  { engagementId: number; answer: IEngagementQuestionAnswer[] },
  Error
>()

export const answerGetAction = createAsyncAction(
  'GET_ANSWER_BEGIN',
  'GET_ANSWER_DONE',
  'GET_ANSWER_FAIL'
)<
  { engagementId: number; questionId: number },
  {
    answer: IEngagementQuestionAnswer
    engagementId: number
    questionId: number
  },
  Error
>()

/**
 * Replaces the entire value property of an answer.
 */
export const answerUpdateAction = createAction('UPDATE_ANSWER')<{
  engagementId: number
  questionId: number
  // tslint:disable-next-line:no-any - answer.value is not typed
  value: any
  updateDirtyFlag: boolean
}>()

export const answerUpdatePartialAnswerAction = createAction(
  'UPDATE_PARTIAL_ANSWER'
)<{
  engagementId: number
  questionId: number
  properties: PropertyValues
  valueType: 'array' | 'object'
  updateDirtyFlag: boolean
}>()

/**
 * Update the specified properties of the answer's value.
 *
 * Note: This action assumes that the answer being updated has
 * a value that is an object or array.
 *
 * @param engagementId
 * @param questionId
 * @param valueType Specifies whether the answer.value property is an array or object.
 * @param properties A set of partial updates to an answer's value property. The
 * key is a path string suitable for the lodash set function. The value is the
 * new value to be set at that property path.
 * https://lodash.com/docs/4.17.5#set
 *
 * e.g.)
 *
 * Before update
 * answer = {  value: { 'first': 'Ryan', 'last': 'Harvey', 'emails': ['rharvey@intertech.com'] } }
 *
 * action.payload.properties = { 'middle': 'R', 'emails[1]': 'ryan.harvey@rsmus.com' }
 *
 * After update
 * answer = {  value: { 'first': 'Ryan', 'middle': 'R', last': 'Harvey', 'emails': ['rharvey@intertech.com', 'ryanharvey@rsmus.com'] } }
 */

export type AnswerActions =
  | ActionType<typeof answerSetNotApplicableAction>
  | ActionType<typeof answerSaveAction.request>
  | ActionType<typeof answerSaveAction.success>
  | ActionType<typeof answerSaveAction.failure>
  | ActionType<typeof answerGetAction.request>
  | ActionType<typeof answerGetAction.success>
  | ActionType<typeof answerGetAction.failure>
  | ActionType<typeof answerUpdateAction>
  | ActionType<typeof answerUpdatePartialAnswerAction>

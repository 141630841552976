import ReactDataSheet from 'react-datasheet'
import { ViewCellComponentFactoryLookup } from './cellComponentFactory'
import { GridElement } from './component'
import { DateViewer } from './dateViewer'
import { SelectViewer } from './selectViewer'
import { TextViewer } from './textViewer'

export type ViewerProps = ReactDataSheet.ValueViewerProps<GridElement>

const ViewerLookup: ViewCellComponentFactoryLookup = {
  date: DateViewer,
  number: TextViewer,
  select: SelectViewer(false, false),
  multiselect: SelectViewer(false, true),
  combobox: SelectViewer(true, false),
  multicombobox: SelectViewer(true, true),
  text: TextViewer,
}

export function getCellViewerComponentClassFactory (component: string = '') {
  return ViewerLookup[component]
}

import classNames from 'classnames'
import { uniqueId } from 'lodash'
import * as React from 'react'
import { Option } from '../../clientModels'
import { isObjectNullOrUndefined } from '../../guards'
import { Alerts } from './alerts/index'
import {
  FocusableComponent,
  OptionsFormFieldProps,
} from './formComponentsInterfaces'
import { by, hasError, objectToOptions, optionToObject } from './formUtilities'
import { RadioButton } from './radioButton'
import './radioButtons.scss'

export default class RadioButtons extends React.Component<OptionsFormFieldProps>
  implements FocusableComponent {
  static defaultProps = {
    showAlerts: true,
  }

  name: string
  labelId: string
  hintId: string
  inputs: HTMLInputElement[] = []

  constructor(props: OptionsFormFieldProps) {
    super(props)
    const name = (this.name = uniqueId('radio_'))
    this.labelId = `${name}_label`
    this.hintId = `${name}_hint`
  }

  componentDidMount() {
    const { selectedPath, path } = this.props

    if (selectedPath && path && path === selectedPath) {
      this.focus()
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: OptionsFormFieldProps) {
    if (
      nextProps.selectedQuestion &&
      nextProps.selectedPath &&
      nextProps.path === nextProps.selectedPath
    ) {
      this.focus()
    }
  }

  focus() {
    const inputs = this.inputs
    const first = inputs[0]
    const checked = inputs.find(i => i.checked)
    if (checked) {
      checked.focus()
    } else if (first) {
      first.focus()
    }
  }

  handleChange = async (option: Option) => {
    const { path, onChange } = this.props
    if (onChange) {
      await onChange(optionToObject(option), path)
    }
  }

  handleBlur = () => {
    const { path, onBlur } = this.props
    if (onBlur) {
      onBlur(path)
    }
  }

  handleFocus = () => {
    const { path, onFocus } = this.props
    if (onFocus) {
      onFocus(path)
    }
  }

  setInput = (ref: HTMLInputElement) => this.inputs.push(ref)

  render() {
    const {
      describedBy,
      disabled,
      hint,
      label,
      labelledBy,
      messages,
      options,
      path,
      showAlerts,
      value,
    } = this.props

    if (!isObjectNullOrUndefined(value)) {
      throw new Error(
        'RadioButtons expects an object that represents the selected option.'
      )
    }

    const ariaDescribedBy = by(describedBy, hint && this.hintId)
    const isInError = hasError(messages, path)
    const selectedOption = objectToOptions(value)[0]

    return (
      <div
        className={classNames('radio-buttons form-group', {
          disabled,
          'is-valid': !isInError,
          'is-invalid': isInError,
        })}
      >
        {label && <label id={this.labelId}>{label}</label>}
        {hint && (
          <div id={this.hintId} className='hint-text'>
            {hint}
          </div>
        )}
        {options &&
          options.map(option => {
            const id = `${this.name}_input_${option.value}`
            const labelId = `${this.name}_label_${option.value}`
            const ariaLabelledBy = by(
              labelledBy,
              label && this.labelId,
              labelId
            )
            return (
              <RadioButton
                key={option.value}
                ariaDescribedBy={ariaDescribedBy}
                ariaLabelledBy={ariaLabelledBy}
                disabled={disabled}
                id={id}
                labelId={labelId}
                name={this.name}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                option={option}
                value={selectedOption && selectedOption.value}
              />
            )
          })}
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

import { TsaHubAction } from '../actions/index'
import { EngagementTaskMap } from '../clientModels'
import { getType } from 'typesafe-actions'
import { taskAction } from '../actions/taskActions'

const initialState: EngagementTaskMap = {}

export function tasksReducer (
  state: EngagementTaskMap = initialState,
  action: TsaHubAction
): EngagementTaskMap {
  switch (action.type) {
    case getType(taskAction.success):
      return { ...state, ...action.payload.tasks }

    default:
      return state
  }
}

import { createAction, ActionType } from 'typesafe-actions'

export const commentSetAction = createAction('SET_COMMENT')<{
  commentText: string
  mentionLabel: string
  mentionValue: string
  questionId: string | number
}>()

export type CommentActions = ActionType<typeof commentSetAction>

import { ensureNumber } from '../guards'
import { selectAnswer } from '../reducers/selectors'
import { TsaThunkAction } from '../store'
import { getActivities } from './activityThunks'
import { updateMetadata } from './answerThunks'
import { flagsToggleFlaggedAction } from './flagsActions'

export const toggleFlagged = (
  engagementId?: string | number,
  questionId?: string | number
): TsaThunkAction => async (dispatch, getState) => {
  engagementId = ensureNumber(engagementId)
  questionId = ensureNumber(questionId)
  if (engagementId === 0 || questionId === 0) {
    return
  }
  dispatch(flagsToggleFlaggedAction({ engagementId, questionId }))
  const answer = selectAnswer(getState(), engagementId, questionId)
  if (!answer) {
    throw new Error('Answer must exist before saving.')
  }
  await dispatch(
    updateMetadata(engagementId, questionId, 'Flag', !!answer.flagged)
  )
  await dispatch(getActivities(engagementId, questionId))
}

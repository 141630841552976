import moment from 'moment'
import { Activity } from '../clientModels'
import { ApiModels } from './api'

export function mapClientActivity(activity: Activity): ApiModels.Activity {
  const apiActivity = {
    ...activity,
    createdDate: activity.createdDate && activity.createdDate.toISOString(),
    sentDate: activity.sentDate && activity.sentDate.toISOString(),
    activityVersion: activity.activityVersion,
  }
  delete (apiActivity as any).author
  return apiActivity
}

export function mapApiActivity(activity: ApiModels.Activity): Activity {
  const clientActivity = {
    ...activity,
    questionId: activity.questionId || -1,
    author: `${activity.firstName && activity.firstName.charAt(0)} ${activity.lastName
      }`.trim(),
    createdDate: moment(activity.createdDate, moment.ISO_8601),
    sentDate: activity.sentDate
      ? moment(activity.sentDate, moment.ISO_8601)
      : undefined,
    activityVersion: activity.activityVersion,
  }
  delete clientActivity.firstName
  delete clientActivity.lastName
  return clientActivity
}
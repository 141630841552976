import { assign } from 'lodash'
import * as React from 'react'
import { FormFieldProps } from './forms/formComponentsInterfaces'

export class CompositeComponent<
T extends FormFieldProps
> extends React.Component<T> {
  buildChildProps = () => {
    // Prefix is the parent path plus a period to denote the relationship, easily parse into properties later on
    return assign({}, this.props, {
      prefix: this.props.path ? this.props.path + '.' : '',
    })
  }
}

import { createAction, ActionType } from 'typesafe-actions'

export const httpBeginAction = createAction('GET_BEGIN')<{
  id: string
}>()
export const httpEndAction = createAction('GET_END')<{ id: string }>()
export const httpSaveBeginAction = createAction('SAVE_BEGIN')<{
  id: string
}>()
export const httpSaveEndAction = createAction('SAVE_END')<{
  id: string
}>()

export type HttpActions =
  | ActionType<typeof httpBeginAction>
  | ActionType<typeof httpEndAction>
  | ActionType<typeof httpSaveBeginAction>
  | ActionType<typeof httpSaveEndAction>

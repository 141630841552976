import { CchProxyResponse } from '../scenes/cchtest'
import { SignalRListener } from './middleware'

type CchResponseCallback = (response: CchProxyResponse) => void

interface CchResponseListener extends SignalRListener {
  register(callback: CchResponseCallback): () => void
}

const listeners = new Map<CchResponseCallback, CchResponseCallback>()

export const CchResponseMessageListener: CchResponseListener = {
  message: 'CchProxyResponseCallback',
  type: 'on',
  callback: (getState, dispatch, response) => {
    listeners.forEach(cb => cb(response))
  },
  register: (callback: CchResponseCallback) => {
    listeners.set(callback, callback)
    return () => {
      listeners.delete(callback)
    }
  },
}

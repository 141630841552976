import { clone, pull, remove } from 'lodash'
import * as React from 'react'
import { FileWithPath } from 'react-dropzone'
import { connect } from 'react-redux'
import { actions } from '../../actions/index'
import { File as TsaFile, Message } from '../../clientModels'
import { AppState, TsaDispatch } from '../../store'
import { Alerts, TextBox } from '../forms/index'
import Icon from '../icon/icon'
import FileDropZone from './fileDropZone'
import './fileUpload.scss'
import FileUploadButton from './fileUploadButton'
import FileUploadFile from './fileUploadFile'
import { FormFieldValue } from '../forms/formComponentsInterfaces'

interface FileUploadDefaultProps {
  file: TsaFile
  maxFileSize: number
  maxDescriptionLength: number
}

interface FileUploadOwnProps extends Partial<FileUploadDefaultProps> {
  onClose?: () => void
}

interface FileUploadMappedProps {
  busy: boolean
  acceptedTemplateMimeTypes: string
}

const mapStateToProps = (state: AppState): FileUploadMappedProps => {
  return {
    busy: state.http.loading.includes(actions.file.FileUploadBusyIndicator),
    acceptedTemplateMimeTypes: state.general.approvedTemplateMimeTypes,
  }
}

interface FileUploadDispatchProps {
  uploadEngagementSetup: (file: TsaFile) => Promise<void>
}

const mapDispatchToProps = (
  dispatch: TsaDispatch
): FileUploadDispatchProps => ({
  uploadEngagementSetup: (file: TsaFile) =>
    dispatch(actions.file.uploadEngagementSetup(file)),
})

type FileUploadProps = FileUploadOwnProps &
  FileUploadMappedProps &
  FileUploadDispatchProps

interface FileUploadState {
  file?: TsaFile
  messages: Message[]
  originalDescription: string
  description: string
}

export class FileUploadQuestionless extends React.Component<
  FileUploadProps,
  FileUploadState
  > {
  static defaultProps = {
    maxDescriptionLength: 150,
    maxFileSize: 10 * 1000 * 1000, // 10MB
  }

  constructor (props: FileUploadProps) {
    super(props)
    this.state = { messages: [], originalDescription: '', description: '' }
  }

  handleDescriptionChange = async (description: FormFieldValue) => {
    this.setState({ description: description + '' })
  }

  handleDrop = (droppedFiles: FileWithPath[]) => {
    this.setState(previousState => {
      const { maxFileSize } = this.props as FileUploadDefaultProps

      const messages: Message[] = []
      if (maxFileSize) {
        const tooLarge = remove(droppedFiles, f => f.size > maxFileSize)
        tooLarge.forEach(file =>
          messages.push({
            type: '',
            severity: 'warning',
            message: `${file.name} too large`,
          })
        )
      }

      const zeroLength = remove(droppedFiles, f => f.size === 0)
      zeroLength.forEach(file =>
        messages.push({
          type: '',
          severity: 'warning',
          message: `${file.name} has no contents`,
        })
      )

      const previousFiles = [clone(previousState.file)]
      const files: TsaFile[] = []

      // Merge the newly dropped files with the existing files
      droppedFiles.forEach(f => {
        const existingFile = previousFiles.find(p => !!p && p.name === f.name)
        if (existingFile) {
          // This file is replacing an existing file
          pull(previousFiles, existingFile)
          files.push({
            contents: f,
            file: existingFile,
            id: existingFile.id,
            isUpdated: true,
            name: f.name,
          })
        } else {
          // This is a new file
          files.push({ contents: f, file: existingFile, id: -1, name: f.name })
        }
      })

      return { file: files[0] }
    })
  }

  fileCount (files: TsaFile[]): number {
    return files.reduce((count, file) => count + (file.isDeleted ? 0 : 1), 0)
  }

  get sizeLimit (): string {
    const { maxFileSize } = this.props
    if (!maxFileSize) {
      return ''
    }

    const size =
      maxFileSize >= 1000000
        ? `${maxFileSize / 1000000}MB`
        : `${maxFileSize / 1000}KB`

    return `File size max ${size}.`
  }

  get acceptedFileTypeLimit (): JSX.Element {
    return <p>File must be an: .xlsx, .xlsm, .xltx, or .xltm</p>
  }

  buttonEnabled (): boolean {
    return !this.props.busy && !!this.state.description && !!this.state.file
  }

  handleButtonClick = () => {
    if (this.state.file) {
      const file = this.state.file
      file.description = this.state.description
      this.props.uploadEngagementSetup(this.state.file).then(() => {
        if (this.props.onClose) {
          this.props.onClose()
        }
      })
    }
  }

  handleDelete = () => {
    this.setState({ file: undefined })
  }

  render () {
    const { file, messages, description } = this.state
    const { busy, onClose, acceptedTemplateMimeTypes } = this.props
    const { maxDescriptionLength } = this.props as FileUploadDefaultProps

    return (
      <div className='file-upload'>
        <div className='file-upload-header d-none d-md-block'>
          Edit file upload
          <span id='file-upload-close'>
            <Icon icon='close' onClick={onClose} />
          </span>
        </div>
        <div className='file-upload-description' id='file-upload-description'>
          <TextBox
            disabled={busy}
            label='Enter a description for the file changes'
            onChange={this.handleDescriptionChange}
            value={description}
            maxLength={maxDescriptionLength}
            messages={
              description
                ? undefined
                : [{ type: '', severity: 'error', message: 'Required' }]
            }
          />
        </div>
        <div className='file-upload-dropzone' id='file-upload-dropzone'>
          {this.sizeLimit}
          {this.acceptedFileTypeLimit}
          <FileDropZone
            acceptedFileTypes={acceptedTemplateMimeTypes}
            busy={busy}
            files={file ? [file] : []}
            maxFileCount={1}
            onDrop={this.handleDrop}
          />
        </div>
        {file && (
          <div className='file-upload-files'>
            <FileUploadFile
              key={file.name}
              file={file}
              onDelete={this.handleDelete}
            />
          </div>
        )}
        <Alerts messages={messages} />
        <div className='file-upload-button-region'>
          <FileUploadButton
            busy={busy}
            disabled={!this.buttonEnabled()}
            onClick={this.handleButtonClick}
            text='Upload'
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadQuestionless)

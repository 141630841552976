import Mousetrap from 'mousetrap'
import * as React from 'react'
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside'
import * as shim from '../../utilities/shims/IE11_reactOnclickoutside'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { actions } from '../../actions/index'
import { searchActionSelectResult } from '../../actions/searchActions'
import { QuestionMap, SearchResult, SectionMap } from '../../clientModels'
import { ensureNumber } from '../../guards'
import { AppState, TsaDispatch } from '../../store'
import Icon from '../icon/icon'
import './search.scss'
import SearchBoxResults from './searchBoxResults'
import { joinPaths } from '../relativeLink'

interface RouteParams {
  engagementId: string
}

interface SearchBoxOwnProps extends RouteComponentProps<RouteParams> {}

type SearchBoxExternalProps = InjectedOnClickOutProps & SearchBoxOwnProps

interface SearchBoxConnectedProps {
  sections: SectionMap
  questions: QuestionMap
  results?: SearchResult[]
  searchString: string
  selectedSearchResult?: SearchResult
  disabled: boolean
}

const mapStateToProps = (
  state: AppState,
  props: SearchBoxExternalProps
): SearchBoxConnectedProps => {
  const engagement =
    state.engagements[ensureNumber(props.match.params.engagementId)]
  return {
    sections: state.sections,
    questions: state.questions,
    results: state.search.results,
    searchString: state.search.searchString,
    selectedSearchResult: state.search.selectedSearchResult,
    disabled: !engagement,
  }
}

interface SearchBoxDispatchProps {
  search: (searchString: string) => void
  selectSearchResult: (result: SearchResult) => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: SearchBoxExternalProps
): SearchBoxDispatchProps => {
  const engagementId = props.match.params.engagementId
  return {
    search: (searchString: string) =>
      dispatch(actions.search.search(engagementId, searchString)),
    selectSearchResult: (selectedSearchResult: SearchResult) =>
      dispatch(searchActionSelectResult({ selectedSearchResult })),
  }
}

type SearchBoxProps = SearchBoxExternalProps &
  SearchBoxConnectedProps &
  SearchBoxDispatchProps

interface SearchBoxState {
  showResults: boolean
}

const shortcut = 'ctrl+/'
Mousetrap.prototype.stopCallback = (
  e: KeyboardEvent,
  element: HTMLElement,
  combo: string
) => {
  if (combo === 'ctrl+/') {
    return false
  }

  // if the element has the class "mousetrap" then no need to stop
  if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
    return false
  }

  // stop for input, select, and textarea
  return (
    element.tagName === 'INPUT' ||
    element.tagName === 'SELECT' ||
    element.tagName === 'TEXTAREA' ||
    (element.contentEditable && element.contentEditable === 'true')
  )
}

export class SearchBox extends React.Component<SearchBoxProps, SearchBoxState> {
  state = {
    showResults: false,
  }

  input?: HTMLInputElement

  setInput = (ref: HTMLInputElement) => (this.input = ref)

  componentDidMount() {
    Mousetrap.bind(shortcut, this.focusInput)
  }

  componentWillUnmount() {
    Mousetrap.unbind(shortcut)
  }

  focusInput = () => this.input && this.input.focus()

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { search } = this.props
    this.showResults()
    search(e.currentTarget.value)
  }

  handleClickOutside = () => this.hideResults()

  handleSelectSearchResult = (result?: SearchResult) => {
    const { results, selectSearchResult } = this.props
    if (!result) {
      result = results && results[0]
    }
    if (result) {
      selectSearchResult(result)
      this.navigateToResult(result)
      this.input && this.input.blur()
      this.hideResults()
    }
  }

  navigateToResult(result: SearchResult) {
    const { history, match, questions } = this.props
    const engagementId = match.params.engagementId

    // Navigate to the target of the search result
    switch (result.type) {
      case 'section':
        history.push(
          joinPaths(
            match.url,
            `engagements/${engagementId}/sections/${result.id}`
          )
        )
        break
      case 'question': {
        const question = questions[result.id]
        if (question) {
          history.push(
            joinPaths(
              match.url,
              `engagements/${engagementId}/sections/${question.sectionId}/questions/${question.id}`
            )
          )
        }
        // selectQuestion(result.id)
        break
      }
      default:
        break
    }
  }

  showResults = () => {
    this.props.enableOnClickOutside()
    this.setState({ showResults: true })
  }

  hideResults = () => {
    this.props.disableOnClickOutside()
    this.setState({ showResults: false })
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        this.nextResult()
        break
      case 'ArrowUp':
        e.stopPropagation()
        e.preventDefault()
        this.previousResult()
        break
      case 'Enter':
        e.stopPropagation()
        e.preventDefault()
        this.handleSelectSearchResult(this.props.selectedSearchResult)
        break
      case 'Escape':
        this.hideResults()
        break
      default:
        break
    }
  }

  selectedIndex() {
    const { selectedSearchResult, results } = this.props
    return results && selectedSearchResult
      ? results.indexOf(selectedSearchResult)
      : -1
  }

  previousResult() {
    const { results, selectSearchResult } = this.props
    if (!results) {
      return
    }
    const selectedIndex = this.selectedIndex()
    const previousIndex =
      selectedIndex <= 0 ? results.length - 1 : selectedIndex - 1
    selectSearchResult(results[previousIndex])
  }

  nextResult() {
    const { results, selectSearchResult } = this.props
    if (!results) {
      return
    }
    const selectedIndex = this.selectedIndex()
    const nextIndex =
      selectedIndex >= results.length - 1 ? 0 : selectedIndex + 1
    selectSearchResult(results[nextIndex])
  }

  render() {
    const { showResults } = this.state
    const {
      disabled,
      questions,
      results,
      searchString,
      sections,
      selectedSearchResult,
    } = this.props

    shim.IE11_react_onclickoutside()

    return (
      <div className='search-box'>
        <form className='form-inline'>
          <input
            className='form-control'
            id='engagement-search-box'
            disabled={disabled}
            onChange={this.handleChange}
            onFocus={this.showResults}
            onKeyDown={this.handleKeyDown}
            placeholder='Search (Ctrl + ?)'
            ref={this.setInput}
            type='text'
            value={searchString}
          />
          <div style={{ display: 'none' }}>
            in: <button className='search-box-link'>Tasks</button>
          </div>
          <div>
            <Icon icon='search' />
          </div>
        </form>
        {showResults && searchString && (
          <SearchBoxResults
            onSelectSearchResult={this.handleSelectSearchResult}
            questions={questions}
            results={results}
            searchString={searchString}
            sections={sections}
            selectedSearchResult={selectedSearchResult}
          />
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(SearchBox))

/**
 * FileUploadButtons is a function component that renders buttons
 * depending on the number and statuses of files that are selected.
 */
import React, { useState } from 'react'
import { Button } from '@rsmus/react-ui'
import Icon from '../icon/icon'
import { Icons } from '../icon/icons'
import classNames from 'classnames'
import { WarningDialog } from '../modals'
import { FileGroup, EntityMap } from '../../clientModels'
import { EtlFileStatus } from '../../enums'
import { SelectedFiles } from '../../reducers'

export interface DataFileButtonsProps {
  fileGroup: FileGroup | undefined
  etlFileStatuses: EntityMap<EtlFileStatus>
  selectedFiles: SelectedFiles
  busy: boolean
  disableGroupButtons: boolean
  onClickDelete: () => void
  onClickDownload: () => void
  onClickSubmitToCch: () => void
}

const DataFileButtons = ({
  busy,
  disableGroupButtons,
  fileGroup,
  etlFileStatuses,
  selectedFiles,
  onClickDelete,
  onClickDownload,
  onClickSubmitToCch,
}: DataFileButtonsProps) => {
  const [showDialog, setShowDialog] = useState(false)
  const selected =
    (fileGroup &&
      fileGroup.files &&
      fileGroup.files.filter(file => selectedFiles[file.id])) ||
    []
  const readyForCch = selected.filter(
    file =>
      etlFileStatuses &&
      etlFileStatuses[file.id] === EtlFileStatus.ReadyToSubmit
  )
  const allReadyForSubmission = selected.every(
    file =>
      etlFileStatuses &&
      etlFileStatuses[file.id] === EtlFileStatus.ReadyToSubmit
  )
  const btns = []
  const disabled = selected.length ? '' : 'disabled'

  const closeModal = () => {
    setShowDialog(false)
  }

  btns.push({
    icon: 'download' as keyof Icons,
    text: 'Download',
    handler: onClickDownload,
    disabled: busy,
    className: `file-upload-advanced-buttons-download btn ${disabled}`,
  })
  btns.push({
    icon: 'delete' as keyof Icons,
    text: 'Delete',
    handler: onClickDelete,
    disabled: disableGroupButtons,
    className: `file-upload-advanced-buttons-delete btn ${disabled}`,
  })
  if (readyForCch && readyForCch.length > 1) {
    btns.push({
      icon: 'carryForwardAll' as keyof Icons,
      text: 'Submit To CCH',
      handler: () => {
        if (allReadyForSubmission) {
          onClickSubmitToCch()
        } else {
          setShowDialog(true)
        }
      },
      disabled: disableGroupButtons,
      className: classNames('file-upload-advanced-buttons-download btn'),
    })
  }

  return (
    <div className={classNames('file-upload-advanced-buttons')}>
      {btns.map(btn => (
        <Button
          key={btn.text}
          variant={'primary'}
          type={'button'}
          squared={false}
          uppercase={true}
          onClick={btn.handler}
          disabled={btn.disabled}
          className={btn.className}
        >
          <Icon icon={btn.icon} />
          {btn.text}
        </Button>
      ))}
      {showDialog && (
        <WarningDialog
          title=''
          info={`Cannot submit as the document(s) status is not "${EtlFileStatus.ReadyToSubmit}". Please check the document(s) status and resubmit.`}
          primaryButtonText='OK'
          onClickPrimary={closeModal}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

export default DataFileButtons

import * as http from '../http'
import { buildApiUrl } from './apiUrl'

export function apiGetPhase(phaseId: number) {
  return http.get(buildApiUrl(`phases/${phaseId}`))
}

/**
 * Get all phases.
 */
export function apiGetPhaseList() {
  return http.get(buildApiUrl('phases'))
}

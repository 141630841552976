import { Abort, createAbort } from './abort'

export interface RequestAbort {
  abort: () => void
  new: () => Abort
}

export function getNewAbort (): RequestAbort {
  let newAbort: Abort | null

  return {
    abort: () => {
      if (newAbort) {
        newAbort.silentAbort()
      }
    },
    new: () => (newAbort = createAbort()),
  }
}

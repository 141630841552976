import { FieldFormat } from '../../clientModels'
import { TsaJsonSchema } from '../answerSchema/index'

interface StringFormatterOptions {
  /** Mask sensitive data when formatting. */
  maskOutput?: boolean
}

interface StringFormatter {
  format: (value?: string | null, options?: StringFormatterOptions) => string
  parse: (value?: string) => string
}

const identity = (a: string) => a

/**
 * The identity formatter passes through value without transformation.
 */
const identityFormatter = {
  format: identity,
  parse: identity,
}

type StringFormats = { [key in FieldFormat]?: StringFormatter }

function formatSsn (value?: string | null, options?: StringFormatterOptions) {
  if (!value) {
    return ''
  }

  if (options && options.maskOutput) {
    value = 'XXXXX'.substring(0, value.length) + value.substring(5)
  }

  return value.length > 5
    ? value.substring(0, 3) +
        '-' +
        value.substring(3, 5) +
        '-' +
        value.substring(5)
    : value.length > 3
      ? value.substring(0, 3) + '-' + value.substring(3)
      : value
}

function formatEin (value?: string | null, options?: StringFormatterOptions) {
  if (!value) {
    return ''
  }

  if (options && options.maskOutput) {
    value = 'XXXXX'.substring(0, value.length) + value.substring(5)
  }

  return value.length > 2
    ? value.substring(0, 2) + '-' + value.substring(2)
    : value
}

function formatEinOrSsn (
  value?: string | null,
  options?: StringFormatterOptions
) {
  if (!value) {
    return ''
  }

  if (options && options.maskOutput) {
    return 'XXXXX'.substring(0, value.length) + value.substring(5)
  }

  return value
}

function parseEinOrSsn (value?: string | null) {
  return value ? value.replace(/\D/g, '').substr(0, 9) : ''
}

function formatNumber (value?: string | null) {
  return value ? formatNumberString(value, true) : ''
}

function parseNumber (value?: string | null) {
  return value ? formatNumberString(value) : ''
}

function formatNumberString (
  value: string | null,
  thousandSeparated: boolean = false
) {
  if (!value) {
    return ''
  }

  // From https://stackoverflow.com/a/49258851
  let result = value
    // eslint-disable-next-line no-useless-escape
    .replace(/[^\d\.-]/g, '') // first, remove all characters that aren't common
    .replace(/(?!^)-/g, '') // replace negative characters that aren't in beginning
    .replace('.', '%FD%') // replace first occurrence of decimal point (placeholder)
    .replace(/\./g, '') // now replace all but first occurrence (refer to above)
    .replace(/%FD%(0+)?$/, '') // remove placeholder if not necessary at end of string
    .replace('%FD%', '.') // otherwise, replace placeholder with period

  let characteristic = ''
  let mantissa = ''

  const isNegative = result[0] === '-'
  if (isNegative) {
    result = result.slice(1)
  }

  const hasDecimal = result.indexOf('.') > -1
  if (hasDecimal) {
    if (result[0] === '.') {
      mantissa = result.slice(1)
    } else {
      characteristic = result.split('.')[0]
      mantissa = result.split('.')[1]
    }
  } else {
    characteristic = result
  }

  if (characteristic.length === 0 && mantissa.length === 0) {
    return '' // empty value
  }

  if (characteristic.length > 0) {
    characteristic = characteristic.replace(/^0+/, '') // remove leading zeros
    if (thousandSeparated) {
      // add comma delimiters
      characteristic = characteristic.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }

  if (isNegative) {
    characteristic = `-${characteristic}`
  }

  if (hasDecimal) {
    result = `${characteristic}.${mantissa}`
  } else {
    result = characteristic
  }

  return result
}

export const formats: StringFormats = {
  ein: {
    format: formatEin,
    parse: parseEinOrSsn,
  },
  einOrSsn: {
    format: formatEinOrSsn,
    parse: parseEinOrSsn,
  },
  number: {
    format: formatNumber,
    parse: parseNumber,
  },
  ssn: {
    format: formatSsn,
    parse: parseEinOrSsn,
  },
}

export function getTextFormat (jsonSchema?: TsaJsonSchema): StringFormatter {
  let format = ''
  if (jsonSchema) {
    if (jsonSchema.format) {
      format = jsonSchema.format
    } else if (jsonSchema.component === 'number') {
      format = 'number'
    }
  }
  return (formats as any)[format] || identityFormatter
}

import * as React from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { MediaMinBreakpoint } from '../../services/MediaQueries'
import { Datasheet } from '../datasheet'
import { OptionsFormFieldProps } from '../forms'
import { QuestionComponentMap } from '../questionContainer'
import { questionEdit } from './questionEdit'

interface ComponentNotFoundRouteParams {
  engagementId: string
  sectionId: string
  questionId: string
}

const UINotFound: React.SFC<OptionsFormFieldProps &
  RouteComponentProps<ComponentNotFoundRouteParams>> = ({
  match: {
    params: { engagementId, sectionId, questionId },
  },
}) => (
  <Redirect
    to={`/engagements/${engagementId}/sections/${sectionId}/questions/${questionId}`}
  />
)

/*
   This editable datasheet is only instantiated from the route: "/sections/:sectionId/questions/:questionId/edit".
 */

// Form Components
export const UINotFoundQuestion = questionEdit(withRouter(UINotFound))
export const EditableDatasheetQuestion = questionEdit(
  Datasheet,
  MediaMinBreakpoint.sm
)

const ComponentLookup: QuestionComponentMap = {
  readonlydatasheet: EditableDatasheetQuestion,
  _default: UINotFoundQuestion,
}

export default ComponentLookup

import { groupBy } from 'lodash'
import { normalize, schema } from 'normalizr'
import {
  Activity,
  ActivityMap,
  AsyncOperationMap,
  ClientMap,
  EngagementMap,
  EngagementQuestion,
  EngagementQuestionMap,
  EngagementQuestionServerData,
  EngagementTaskMap,
  EngagementTemplateFieldMap,
  EngagementTemplateMap,
  EntityMap,
  IdMap,
  OptionListMap,
  PhaseMap,
  Question,
  QuestionMap,
  RelatedClientMap,
  Section,
  SectionMap,
  K1Header,
  EtlFile,
  EntityGroup,
} from '../clientModels'
import { isDefined } from '../guards'
import {
  ApiEntityMap,
  FileGroup,
  OptionsGroup,
} from '../services/api/apiModels'
import { ApiModels } from '../services/api'
import { mapApiActivity } from '../services/map'
import {
  engagementProcessStrategy,
  getReviewRequiredRoleCodes,
} from '../services/questionReviewUtilities'
import { getState } from '../services/rules/getStateProxy'

export const SectionDisplayOrderMultiplier = 10000

// Answers are mapped using the question ID rather than the answer ID. This
// makes it easier to do a lookup for an answer to a particular question.
const answerSchema = new schema.Entity(
  'answers',
  {},
  { idAttribute: 'questionId' }
)

const asyncOperationSchema = new schema.Entity('asyncOperations')

const etlFileSchema = new schema.Entity(
  'etlFiles',
  {},
  {
    idAttribute: 'fileId',
  }
)

const fileGroupSchema = new schema.Entity('fileGroups')

const k1Schema = new schema.Entity('k1s')

const phaseSchema = new schema.Entity(
  'phases',
  {},
  {
    idAttribute: 'code',
  }
)

const addressSchema = new schema.Entity('addresses')

const relatedClientSchema = new schema.Entity('relatedClients')

const clientSchema = new schema.Entity('clients', {
  address: addressSchema,
  master: relatedClientSchema,
})
const questionSchema = new schema.Entity('questions')

const sectionSchema = new schema.Entity('sections')

const engagementTemplateSchema = new schema.Entity('templates', {
  questions: [questionSchema],
  sections: [sectionSchema],
})
const engagementSchema = new schema.Entity(
  'engagements',
  { client: clientSchema, template: engagementTemplateSchema },

  { processStrategy: engagementProcessStrategy }
)

const engagementQuestionSchema = new schema.Entity(
  'engagementQuestions',
  {},
  { idAttribute: 'questionId' }
)

const engagementTemplateFieldSchema = new schema.Entity('fields')

const taskSchema = new schema.Entity('tasks', {
  client: clientSchema,
  engagement: engagementSchema,
  section: sectionSchema,
})

const optionsSchema = new schema.Entity(
  'options',
  {},
  {
    idAttribute: 'lookupId',
    processStrategy: o => o.options,
  }
)

const entityGroupSchema = new schema.Entity('entityGroups')

export function normalizeCurrentAnswers(answers: ApiModels.CurrentAnswer[]) {
  const { entities } = normalize(answers, [answerSchema])
  return (
    entities.answers ||
    ({} as { [questionId: number]: ApiModels.CurrentAnswer })
  )
}

export function normalizeLastYearAnswers(answers: ApiModels.LastYearAnswer[]) {
  const { entities } = normalize(answers, [answerSchema])
  return (entities.answers || {}) as {
    [questionId: number]: ApiModels.LastYearAnswer | undefined
  }
}

export function normalizeActivities(
  activities: ApiModels.Activity[]
): ActivityMap {
  const clientActivities: Activity[] = activities.map(mapApiActivity)
  return groupBy(clientActivities, 'questionId')
}

export function normalizeAsyncOperationArray(
  asyncOperations: ApiModels.AsyncOperation[]
): AsyncOperationMap {
  const { entities } = normalize(asyncOperations, [asyncOperationSchema])
  return entities.asyncOperations || ({} as AsyncOperationMap)
}

export function normalizeEtlFiles(etlFiles: ApiModels.EtlFile[]) {
  const { entities } = normalize(etlFiles, [etlFileSchema])
  return entities.etlFiles as EntityMap<EtlFile>
}

export function normalizeFileGroups(fileGroup: ApiModels.FileGroup[]) {
  const { entities } = normalize(fileGroup, [fileGroupSchema])
  return entities.fileGroups as ApiEntityMap<FileGroup>
}

export function normalizeK1s(k1s: K1Header[]) {
  const { entities } = normalize(k1s, [k1Schema])
  return entities.k1s as ApiEntityMap<K1Header>
}

export function normalizeClient(client: ApiModels.Client) {
  const { entities } = normalize(client, clientSchema)
  return {
    clients: entities.clients as ClientMap,
    relatedClients: entities.relatedClients as RelatedClientMap,
  }
}

export function normalizeClientArray(clients: ApiModels.Client[]) {
  const { entities } = normalize(clients, [clientSchema])
  return {
    clients: entities.clients || ({} as ClientMap),
  }
}

export function normalizeEngagement(engagement: ApiModels.Engagement) {
  const { entities } = normalize(engagement, engagementSchema)
  return {
    engagements: entities.engagements as EngagementMap,
    clients: entities.clients as ClientMap,
  }
}

export function normalizeEngagementQuestionArray(
  engagementQuestions: Array<EngagementQuestion | EngagementQuestionServerData>
) {
  const { entities } = normalize(engagementQuestions, [
    engagementQuestionSchema,
  ])
  return {
    engagementQuestions:
      entities.engagementQuestions || ({} as EngagementQuestionMap),
  }
}

export function normalizeEngagementTemplateFieldArray(
  fields: ApiModels.EngagementTemplateField[]
) {
  const { entities } = normalize(fields, [engagementTemplateFieldSchema])
  return entities.fields || ({} as EngagementTemplateFieldMap)
}

export function normalizeEngagementArray(engagements: ApiModels.Engagement[]) {
  const { entities } = normalize(engagements, [engagementSchema])
  return {
    engagements: entities.engagements || ({} as EngagementMap),
    clients: (entities.clients as ClientMap) || ({} as ClientMap),
    templates:
      ((entities.templates as unknown) as EngagementTemplateMap) ||
      ({} as EngagementTemplateMap),
  }
}

export function normalizeEngagementTemplate(
  template: ApiModels.EngagementTemplate
) {
  const {
    entities: { templates, sections, questions },
  } = normalize(template, engagementTemplateSchema)

  const questionArray = Object.values(questions).filter(isDefined) as Question[]
  const sectionArray = Object.values(sections).filter(isDefined) as Section[]

  const state = getState()
  const user = state.auth.user
  const isExternalUser = state.permissions.isExternalUser

  sectionArray.forEach(s => {
    s.displayOrder *= SectionDisplayOrderMultiplier
    s.questions = questionArray.filter(q => q.sectionId === s.id).map(q => q.id)

    s.isVisible = true
    if (s.isInternalVisibleOnly) {
      if (user?.isExternal || isExternalUser) {
        s.isVisible = false
      }
    }
  })

  questionArray.forEach((question: any) => {
    const section = sections[question.sectionId]
    if (section) {
      question.displayNumber = section.number + '.' + question.number
      question.displayOrder = section.displayOrder + question.displayOrder
    } else {
      question.displayNumber = question.number
    }

    question.reviewRolesRequired = getReviewRequiredRoleCodes(question)
    question.isVisible = true

    if (user?.isExternal || isExternalUser) {
      if (section.isInternalVisibleOnly) {
        question.clientReviewRequired = false
        question.isVisible = false
      } else if (question.isInternalVisibleOnly) {
        question.isVisible = false
      }
    }
  })

  return {
    templates: templates as EngagementTemplateMap,
    sections: sections as SectionMap,
    questions: questions as QuestionMap,
  }
}

export function normalizeEngagementTemplateArray(
  templateArray: ApiModels.EngagementTemplate[]
) {
  const {
    entities: { templates },
  } = normalize(templateArray, [engagementTemplateSchema])
  return {
    templates: templates || ({} as EngagementTemplateMap),
  }
}

export function normalizePhasesArray(phases: ApiModels.Phase[]) {
  const { entities } = normalize(phases, [phaseSchema])
  return entities.phases as PhaseMap
}

export function normalizeTask(task: ApiModels.EngagementTask) {
  const { entities } = normalize(task, taskSchema)
  return {
    tasks: entities.tasks as EngagementTaskMap,
    clients: entities.clients as ClientMap,
    sections: entities.clients as SectionMap,
  }
}

export function normalizeTaskArray(tasks: ApiModels.EngagementTask[]) {
  const { entities } = normalize(tasks, [taskSchema])
  return {
    tasks: entities.tasks as EngagementTaskMap,
    clients: entities.clients as ClientMap,
    sections: entities.clients as SectionMap,
  }
}

export function normalizeEntityGroups(entityGroups: ApiModels.EntityGroup[]) {
  const { entities } = normalize(entityGroups, [entityGroupSchema])
  return entities.entityGroups || ({} as EntityMap<EntityGroup>)
}

export function createOptionsMap(groups: OptionsGroup[]): OptionListMap {
  const entities = normalize(groups, [optionsSchema]).entities
  return entities.options!
}

export function createCodeListIds(groups: OptionsGroup[]): IdMap {
  const codeListIdToOptionsId = groups.reduce((map, g) => {
    g.codeListIds.forEach(codeListId => (map[codeListId] = g.lookupId))
    return map
  }, {} as IdMap)
  return codeListIdToOptionsId
}

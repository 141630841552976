import invariant from 'invariant'
import ReactDataSheet from 'react-datasheet'
import { TsaJsonSchema } from '../../services/answerSchema/index'
import { GridElement } from './component'
import { DatasheetProps } from './datasheet'
import { getCellEditorComponentClassFactory } from './editors'
import { getCellViewerComponentClassFactory } from './viewers'

interface DatasheetSchemaProcessor {
  datasheetProps: DatasheetProps
  property: (property: SchemaProperty) => void
  rootObject: (
    type: 'array' | 'object',
    minRows?: number,
    maxRows?: number,
    headerWidth?: string,
    rowHeaders?: string[]
  ) => void
}

export interface SchemaProperty {
  alignment?: string
  children?: SchemaProperty[]
  codeListId?: number
  columns: number
  component?: string
  default?: string
  depth: number
  displayWidth?: string
  editor?: ReactDataSheet.DataEditor<GridElement>
  format?: string
  maxWidth?: string
  minWidth?: string
  name: string
  title?: string
  type: 'string' | 'number' | 'object'
  viewer?: ReactDataSheet.ValueViewer<GridElement>
}

function validateProperty(
  processor: DatasheetSchemaProcessor,
  propertySchema: TsaJsonSchema,
  name: string
): boolean {
  let result = false

  const editorFactory = getCellEditorComponentClassFactory(
    propertySchema.component
  )
  const editor = editorFactory(propertySchema, processor.datasheetProps)

  const viewerFactory = getCellViewerComponentClassFactory(
    propertySchema.component
  )
  const viewer =
    viewerFactory && viewerFactory(propertySchema, processor.datasheetProps)

  const property: SchemaProperty = {
    alignment: propertySchema.alignment,
    codeListId: propertySchema.codeListId,
    columns: 1,
    component: propertySchema.component,
    default: propertySchema.default,
    depth: 1,
    displayWidth: propertySchema.width,
    editor,
    format: propertySchema.format,
    maxWidth: propertySchema.maxWidth,
    minWidth: propertySchema.minWidth,
    name,
    title: propertySchema.title,
    type: 'string',
    viewer,
  }

  switch (propertySchema.type) {
    case 'string':
      processor.property(property)
      result = true
      break

    case 'integer':
    case 'number':
      property.type = 'number'
      processor.property(property)
      result = true
      break

    case 'object':
      if (propertyCount(propertySchema) === 0) {
        property.type = 'object'
        processor.property(property)
        result = true
      } else {
        // This node is the schema represents a column grouping
        // Process all of the nested columns in this group
        const children: SchemaProperty[] = []
        let columns = 0
        let maxDepth = 0
        const childProcessor: DatasheetSchemaProcessor = {
          datasheetProps: processor.datasheetProps,
          rootObject: () => {},
          property: (childProp: SchemaProperty) => {
            children.push(childProp)
            columns += childProp.columns
            if (childProp.depth > maxDepth) {
              maxDepth = childProp.depth
            }
          },
        }
        result = validateObject(childProcessor, propertySchema)
        ++maxDepth

        // Add a property for the column grouping
        processor.property({
          name,
          title: propertySchema.title,
          type: 'object',
          children,
          columns,
          depth: maxDepth,
        })
      }
      break

    default:
      invariant(false, 'Cannot handle property type')
  }

  return result
}

function validateObject(
  processor: DatasheetSchemaProcessor,
  objectSchema: TsaJsonSchema
): boolean {
  let result = true

  const { properties } = objectSchema
  if (!properties) {
    return result
  }

  for (const propertyName in properties) {
    result =
      result &&
      validateProperty(processor, properties[propertyName], propertyName)
  }

  return result
}

export function processSchema(processor: DatasheetSchemaProcessor): boolean {
  let result = false

  const rootSchema = processor.datasheetProps.jsonSchema

  if (!rootSchema) {
    invariant(false, 'invalid schema')
    return result
  }

  switch (rootSchema.type) {
    case 'array': {
      const { items } = rootSchema

      if (!items) {
        throw new Error('Need items definition.')
      }

      if (Array.isArray(items)) {
        invariant(
          items.length === 1,
          "Can't have varying item definitions per array"
        )
        invariant(items[0].type === 'object', 'Must be an array of objects')
        processor.rootObject(
          'array',
          rootSchema.minItems,
          rootSchema.maxItems,
          rootSchema.headerWidth,
          rootSchema.itemLabels
        )
        result = validateObject(processor, items[0])
      } else {
        invariant(items.type === 'object', 'Must be an array of objects')
        processor.rootObject(
          'array',
          rootSchema.minItems,
          rootSchema.maxItems,
          rootSchema.headerWidth,
          rootSchema.itemLabels
        )
        result = validateObject(processor, items)
      }
      break
    }
    case 'object':
      processor.rootObject('object', 1, 1)
      result = validateObject(processor, rootSchema)
      break

    default:
      invariant(false, 'Invalid type')
  }

  return result
}

function propertyCount(schema: TsaJsonSchema) {
  return schema.properties ? Object.keys(schema.properties).length : 0
}

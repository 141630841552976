import { AppState } from '../store'
import {
  HubConnectionBuilder,
  LogLevel,
  HubConnection
} from '@microsoft/signalr'
import { Dispatch } from 'redux'

export type SignalRReducer = (
  state: () => AppState,
  dispatch: any,
  connection: HubConnection,
  action: any
) => Promise<any>

export type StoreFunctionCall = (
  getState: () => AppState,
  dispatch: Dispatch<any>
) => void

export interface SignalRListener {
  message: string
  type: 'on'
  callback: (
    getState: () => AppState,
    dispatch: Dispatch<any>,
    ...args: any[]
  ) => void
}

export interface SignalRAuthentication {
  actionType: string
  getBearerToken: (action: any) => string
}

export interface SignalRConfiguration {
  hubUrl: string
  authentication?: SignalRAuthentication
  reducers: SignalRReducer[]
  listeners: SignalRListener[]
  onConnecting?: StoreFunctionCall
  onConnected?: StoreFunctionCall
  onDisconnected?: StoreFunctionCall
}

export function ReduxSignalR({
  hubUrl,
  reducers,
  listeners,
  authentication,
  onConnected,
  onConnecting,
  onDisconnected
}: SignalRConfiguration) {
  function empty() {}
  const connecting = onConnecting || empty
  const connected = onConnected || empty
  const disconnected = onDisconnected || empty
  let hubConnection: HubConnection | undefined

  return function(store: any) {
    let queuedActions: any[] = []
    let initialized = false
    let connectedToSignalR = false
    function connect() {
      if (!hubConnection) {
        return
      }
      connecting(store.getState, store.dispatch)
      hubConnection
        .start()
        .then(_ => {
          connectedToSignalR = true
          sendAllQueued()
          connected(store.getState, store.dispatch)
        })
        .catch(_ => {
          disconnected(store.getState, store.dispatch)
          // setTimeout(connect, 1000)
        })
    }
    function initialize(accessToken?: string) {
      initialized = true
      const builder = new HubConnectionBuilder()
      if (accessToken) {
        builder.withUrl(hubUrl, {
          accessTokenFactory: function() {
            return accessToken
          }
        })
      }

      hubConnection = builder.configureLogging(LogLevel.Information).build()

      hubConnection.onclose(_ => {
        connectedToSignalR = false
        disconnected(store.getState, store.dispatch)
        connect()
      })

      for (const listener of listeners) {
        switch (listener.type) {
          case 'on':
            hubConnection.on(listener.message, function(...args: any[]) {
              listener.callback(store.getState, store.dispatch, ...args)
            })
        }
      }

      connect()
    }

    function sendAllQueued() {
      if (queuedActions.length === 0) {
        return
      }
      const actions = queuedActions
      queuedActions = []
      for (const action of actions) {
        processAction(action)
      }
    }

    function processAction(action: any) {
      if (!connectedToSignalR || !hubConnection) {
        queuedActions.push(action)
        return
      }
      for (const reducer of reducers) {
        reducer(store.getState, store.dispatch, hubConnection, action)
      }
    }

    if (!authentication) {
      initialize()
    }

    return function(next: any) {
      return function(action: any) {
        if (!initialized && authentication) {
          switch (action.type) {
            case authentication.actionType: {
              initialize(authentication.getBearerToken(action))
              break
            }
          }
        }
        processAction(action)
        return next(action)
      }
    }
  }
}

import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { createSelector } from 'reselect'
import { actions } from '../../actions'
import {
  Activity,
  EngagementQuestion,
  FileGroup,
  Question,
  Section,
} from '../../clientModels'
import {
  getActivitiesByEngagementIdQuestionId,
  selectQuestionFileGroups,
} from '../../reducers/selectors'
import { formatDateForDisplay } from '../../services/formatters/formatters'
import { getTrackingId } from '../../services/track'
import { AppState, TsaDispatch } from '../../store'
import { DetailHandlers } from '../summary/summary'
import { SummaryProps, SummaryQuestion } from './misc'

interface MappedProperties {
  question?: Question
  section?: Section
  engagementQuestion?: EngagementQuestion
  commentActivity?: Activity
  fileGroup?: FileGroup
  userId?: string
  engagementHasLoaded: boolean
}

interface DispatchProperties {
  getActivities: (engagementId: number, questionId: number) => void
  downloadFile: (fileId: number) => void
}

type Properties = SummaryProps &
  MappedProperties &
  DispatchProperties &
  RouteComponentProps<{}>

function getActivities(state: AppState, props: SummaryProps) {
  const activities = getActivitiesByEngagementIdQuestionId(
    state,
    props.engagementId,
    props.questionId
  )

  if (activities) {
    for (const activity of activities) {
      if (activity.id === props.unitId) {
        return {
          activities,
          activity,
        }
      }
    }
  }
  return {
    activities,
    activity: undefined,
  }
}

const makeSelectFileGroups = () =>
  createSelector(
    (state: AppState) => state.fileGroups,
    (_: any, props: SummaryProps) => props.questionId,
    selectQuestionFileGroups
  )

const makeFileGroupSelector = () => {
  const selectFileGroups = makeSelectFileGroups()
  return createSelector(
    getActivities,
    selectFileGroups,
    ({ activity }, fileGroups) => {
      if (!activity || !activity.fileGroupId || !fileGroups) {
        return {
          fileGroup: undefined,
        }
      }
      const result = {
        fileGroup: undefined as FileGroup | undefined,
      }
      for (const fileGroup of fileGroups) {
        if (fileGroup.id === activity.fileGroupId) {
          result.fileGroup = fileGroup
          break
        }
      }
      return result
    }
  )
}

export const makeMapStateToProps = () => {
  const fileGroupSelector = makeFileGroupSelector()

  return (state: AppState, props: SummaryProps): MappedProperties => {
    const eqs = state.engagementQuestions[props.engagementId]
    const question = state.questions[props.questionId]
    const section = question && state.sections[question.sectionId]
    const comment = getActivities(state, props).activity
    const result: MappedProperties = {
      commentActivity: comment,
      engagementHasLoaded: !!state.engagements[props.engagementId],
      engagementQuestion: eqs ? eqs[props.questionId] : undefined,
      fileGroup: fileGroupSelector(state, props).fileGroup,
      question,
      section,
      userId: state.auth.user ? state.auth.user.uniqueId || '' : '',
    }
    return result
  }
}

function mapDispatchToProps(
  dispatch: TsaDispatch,
  props: SummaryProps
): DispatchProperties {
  return {
    downloadFile: (fileId: number) =>
      dispatch(actions.file.downloadFile(fileId)),
    getActivities: (engagementId: number, questionId: number) =>
      getTrackingId(
        dispatch,
        actions.activity.getActivities,
        engagementId,
        questionId
      ),
  }
}

interface State {
  fileDetails: DetailHandlers[]
}

class DocumentSummary extends React.Component<Properties, State> {
  state: State = {
    fileDetails: [],
  }

  constructor(props: Properties) {
    super(props)

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onViewContext = this.onViewContext.bind(this)
    this.formatAuthor = this.formatAuthor.bind(this)
    this.updateState = this.updateState.bind(this)
    this.onFileDownload = this.onFileDownload.bind(this)

    this.state = this.updateState(this.state, props)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: Properties) {
    const {
      updateState,
      state,
      props: { fileGroup },
    } = this
    let nextState = state
    let updated = false

    if (fileGroup !== nextProps.fileGroup) {
      nextState = updateState(nextState, nextProps)
      updated = true
    }

    if (updated) {
      this.setState(nextState)
    }
  }

  updateState(state: State, props: Properties): State {
    const { onFileDownload } = this
    const result = { ...state }

    result.fileDetails = []
    if (
      props.fileGroup &&
      props.fileGroup.files &&
      props.fileGroup.files.length > 0
    ) {
      const files = props.fileGroup.files

      for (const file of files) {
        result.fileDetails.push({
          handler: () => {
            onFileDownload(file.id)
          },
          label: file.name || file.description || '' + file.id,
        })
      }
    }

    return result
  }

  onFileDownload(fileId: number) {
    this.props.downloadFile(fileId)
  }

  onOpen() {
    const {
      props: { question, unitId, onOpen },
    } = this
    if (!question) {
      return
    }
    onOpen(question.id, unitId)
  }

  onClose() {
    const {
      props: { question, unitId, onClose },
    } = this
    if (!question) {
      return
    }
    onClose(question.id, unitId)
  }

  onViewContext() {
    const {
      props: { question, onViewContext },
    } = this
    if (!question) {
      return
    }
    onViewContext(question.id, question.sectionId)
  }

  formatAuthor(activity: Activity): string {
    const {
      props: { userId },
    } = this
    return activity.userId === userId
      ? 'You'
      : `${activity.createdByInternalUser ? '(RSM) ' : ''}${activity.author}`
  }

  render() {
    const {
      onOpen,
      onClose,
      onViewContext,
      formatAuthor,
      state: { fileDetails },
      props: {
        fileGroup,
        openPath,
        engagementQuestion,
        question,
        section,
        engagementId,
        commentActivity,
      },
    } = this
    if (!question || !engagementQuestion || !section) {
      return <div />
    }
    let text = ''
    if (commentActivity) {
      text = `Carried Forward ${formatDateForDisplay(
        commentActivity.createdDate
      )} by ${formatAuthor(commentActivity)}`
    }

    return (
      <SummaryQuestion
        openPath={openPath}
        label={question.displayNumber}
        secondLabel={section.title}
        hint={text}
        labelDetails={fileDetails}
        engagementId={engagementId}
        question={question}
        engagementQuestion={engagementQuestion}
        hasDocuments={false}
        showFileIcons={false}
        requiresAttention={false}
        hasFlags={engagementQuestion.flagged}
        hasComments={engagementQuestion.commentsCount > 0}
        required={false}
        onOpen={onOpen}
        onClose={onClose}
        onViewContext={onViewContext}
        fileGroupId={
          fileGroup && !fileGroup.documentTitleId ? fileGroup.id : undefined
        }
        documentTitleId={
          fileGroup && !!fileGroup.documentTitleId
            ? fileGroup.documentTitleId
            : undefined
        }
        selected={true}
      />
    )
  }
}

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(DocumentSummary)
)

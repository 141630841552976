import classNames from 'classnames'
import * as React from 'react'
import Icon from '../icon/icon'
import './summaryIcons.scss'

interface SummaryIconsProps {
  disabled?: boolean
  required?: boolean
  requiresAttenion?: boolean
  alwaysShowRequired?: boolean
}

class SummaryIcons extends React.PureComponent<SummaryIconsProps> {
  render () {
    const {
      disabled,
      required,
      alwaysShowRequired,
      requiresAttenion,
    } = this.props
    if (disabled) {
      return null
    }

    return (
      <div className='summary-icons'>
        <span className='icon-container'>
          <Icon
            icon='asterisk'
            active={true}
            className={classNames('summary-icons-icon', {
              show: required,
              'always-show': alwaysShowRequired && !required,
              'requires-attention': requiresAttenion,
            })}
          />
        </span>
      </div>
    )
  }
}

export default SummaryIcons

import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { createSelector } from 'reselect'
import { actions } from '../../actions/index'
import {
  Activity,
  EngagementQuestion,
  Question,
  Section,
} from '../../clientModels'
import { getActivitiesByEngagementIdQuestionId } from '../../reducers/selectors'
import { formatDateForDisplay } from '../../services/formatters/formatters'
import { getTrackingId } from '../../services/track'
import { AppState, TsaDispatch } from '../../store'
import { SummaryProps, SummaryQuestion } from './misc'

interface MappedProperties {
  question?: Question
  section?: Section
  engagementQuestion?: EngagementQuestion
  rootCommentActivity?: Activity
  commentActivity?: Activity
  userId?: string
  engagementHasLoaded: boolean
}

interface DispatchProperties {
  getActivities: (engagementId: number, questionId: number) => void
}

type Properties = SummaryProps &
  MappedProperties &
  DispatchProperties &
  RouteComponentProps<{}>

function getActivities (state: AppState, props: SummaryProps) {
  const activities = getActivitiesByEngagementIdQuestionId(
    state,
    props.engagementId,
    props.questionId
  )

  if (activities) {
    for (const activity of activities) {
      if (activity.id === props.unitId) {
        return {
          activities,
          activity,
        }
      }
    }
  }
  return {
    activities,
    activity: undefined,
  }
}

const makeChildCommentActivitySelector = () => {
  return createSelector(
    getActivities,
    ({ activities, activity }) => {
      if (activities === undefined || activities.length === 0 || !activity) {
        return {
          commentActivity: undefined,
        }
      }
      const result = {
        commentActivity: undefined as Activity | undefined,
      }
      for (
        let i = activities.length - 1;
        !result.commentActivity && i >= 0;
        --i
      ) {
        const a = activities[i]
        if (a.parentId !== activity.id) {
          continue
        }
        if (!result.commentActivity && a.activityTypeCode === 'CarryForward') {
          result.commentActivity = a
        }
      }
      return result
    }
  )
}

export const makeMapStateToProps = () => {
  const childCommentActivitySelector = makeChildCommentActivitySelector()

  return (state: AppState, props: SummaryProps): MappedProperties => {
    const eqs = state.engagementQuestions[props.engagementId]
    const question = state.questions[props.questionId]
    const section = question && state.sections[question.sectionId]
    const result: MappedProperties = {
      engagementHasLoaded: !!state.engagements[props.engagementId],
      question,
      section,
      rootCommentActivity: getActivities(state, props).activity,
      engagementQuestion: eqs ? eqs[props.questionId] : undefined,
      userId: state.auth.user ? state.auth.user.uniqueId || '' : '',
      ...childCommentActivitySelector(state, props),
    }
    return result
  }
}

function mapDispatchToProps (
  dispatch: TsaDispatch,
  props: SummaryProps
): DispatchProperties {
  return {
    getActivities: (engagementId: number, questionId: number) =>
      getTrackingId(
        dispatch,
        actions.activity.getActivities,
        engagementId,
        questionId
      ),
  }
}

class CommentSummary extends React.Component<Properties> {
  constructor (props: Properties) {
    super(props)

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onViewContext = this.onViewContext.bind(this)
    this.formatAuthor = this.formatAuthor.bind(this)
  }

  onOpen () {
    const {
      props: { question, unitId, onOpen },
    } = this
    if (!question) {
      return
    }
    onOpen(question.id, unitId)
  }

  onClose () {
    const {
      props: { question, unitId, onClose },
    } = this
    if (!question) {
      return
    }
    onClose(question.id, unitId)
  }

  onViewContext () {
    const {
      props: { question, onViewContext },
    } = this
    if (!question) {
      return
    }
    onViewContext(question.id, question.sectionId)
  }

  formatAuthor (activity: Activity): string {
    const {
      props: { userId },
    } = this
    return activity.userId === userId
      ? 'You'
      : `${activity.createdByInternalUser ? '(RSM) ' : ''}${activity.author}`
  }

  render () {
    const {
      onOpen,
      onClose,
      onViewContext,
      formatAuthor,
      props: {
        openPath,
        engagementQuestion,
        question,
        section,
        engagementId,
        rootCommentActivity,
        commentActivity,
      },
    } = this
    if (!question || !engagementQuestion || !section) {
      return <div />
    }
    let text = ''
    if (rootCommentActivity) {
      text = `Carried Forward ${formatDateForDisplay(
        (commentActivity || rootCommentActivity).createdDate
      )} by ${formatAuthor(commentActivity || rootCommentActivity)}`
    }

    return (
      <SummaryQuestion
        openPath={openPath}
        label={question.displayNumber}
        secondLabel={section.title}
        hint={text}
        labelDetails={
          (commentActivity || rootCommentActivity || { value: undefined }).value
        }
        engagementId={engagementId}
        question={question}
        engagementQuestion={engagementQuestion}
        hasDocuments={false}
        showFileIcons={false}
        requiresAttention={false}
        hasFlags={engagementQuestion.flagged}
        hasComments={engagementQuestion.commentsCount > 0}
        required={false}
        onOpen={onOpen}
        onClose={onClose}
        onViewContext={onViewContext}
        selected={true}
      />
    )
  }
}

export default withRouter(
  connect(
    makeMapStateToProps,
    mapDispatchToProps
  )(CommentSummary)
)

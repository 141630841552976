import * as React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps } from 'react-router'
import { createSelector } from 'reselect'
import { EngagementQuestionMap, Question } from '../../clientModels'
import { ensureNumber, isDefined, isNumber } from '../../guards'
import { selectEngagementFileGroups } from '../../reducers/selectors'
import { AppState } from '../../store'
import { LeftNav, NavItem } from '../leftNav'
import { SectionLinks } from '../sectionLinks'
import { DocumentLibaryLinks } from '../docLibraryLinks'
import { DocumentLibaryK1UploadLinks } from '../docLibraryK1UploadLinks'
import { UserPermissions, DocumentTitleId } from '../../enums'
import { getState } from '../../services/rules/getStateProxy'

interface RouteParams {
  engagementId: string
  entityGroupId?: string
}

interface EngagementsLeftNavOwnProps extends RouteComponentProps<RouteParams> {}

const empty = {}

const selectEngagementId = (_: AppState, props: EngagementsLeftNavOwnProps) =>
  props.match.params.engagementId

const selectMatch = (_: AppState, props: EngagementsLeftNavOwnProps) =>
  props.match

const selectEngagement = (state: AppState, props: EngagementsLeftNavOwnProps) =>
  state.engagements[ensureNumber(props.match.params.engagementId)]

const selectEngagementQuestions = (
  state: AppState,
  props: EngagementsLeftNavOwnProps
) =>
  state.engagementQuestions[props.match.params.engagementId] ||
  (empty as EngagementQuestionMap)

const selectFileGroupMap = createSelector(
  (state: AppState) => state.fileGroups,
  (_: any, props: EngagementsLeftNavOwnProps) =>
    props.match.params.engagementId,
  selectEngagementFileGroups
)

const selectTotalIssues = createSelector(selectEngagement, engagement =>
  engagement && isNumber(engagement.successfulForUserQuestions)
    ? engagement.totalQuestions - engagement.successfulForUserQuestions
    : 0
)

const selectVisibileEngagementQuestions = createSelector(
  selectEngagementQuestions,
  questions =>
    Object.values(questions)
      .filter(isDefined)
      .filter(q => q.isVisible)
)

const selectMissingReqDocs = createSelector(
  selectVisibileEngagementQuestions,
  selectFileGroupMap,
  (engagementQuestions, fileGroupMap) => {
    let missingReqDocs = 0
    for (const q of engagementQuestions) {
      if (q.requiredDocumentTitleIds.length > 0) {
        const fileGroups = fileGroupMap[q.questionId] || []
        const filteredCount = fileGroups.filter(x =>
          q.requiredDocumentTitleIds.includes(x.documentTitleId || 0)
        ).length
        missingReqDocs += q.requiredDocumentTitleIds.length - filteredCount
      }
    }
    return missingReqDocs
  }
)

export function internalQuestionIds() {
  const state = getState()
  const user = state.auth.user
  const isExternalUser = state.permissions.isExternalUser
  let result: Question[] = []

  if (user?.isExternal || isExternalUser) {
    const questionArray = Object.values(state.questions).filter(
      isDefined
    ) as Question[]

    if (questionArray.length > 0) {
      result = questionArray.filter(q => q.isInternalVisibleOnly)
    }
  }
  return result?.map(x => x.id)
}

const selectUnrepliedComments = createSelector(
  selectVisibileEngagementQuestions,
  questions => {
    questions = questions.filter(function(q) {
      return internalQuestionIds().indexOf(q.questionId) === -1
    })

    return questions.reduce((total, q) => total + q.commentsCount, 0)
  }
)

const selectFlags = createSelector(
  selectVisibileEngagementQuestions,
  questions => {
    questions = questions.filter(function(q) {
      return internalQuestionIds().indexOf(q.questionId) === -1
    })

    return questions.reduce((total, q) => total + (q.flagged ? 1 : 0), 0)
  }
)

const selectHasK1 = createSelector(
  selectVisibileEngagementQuestions,
  questions => {
    for (var question of questions) {
      if (!question) {
        continue
      }
      for (var docTitleId of question.requiredDocumentTitleIds) {
        if (docTitleId === DocumentTitleId.K1Document) {
          return DocumentTitleId.K1Document
        }
        if (docTitleId === DocumentTitleId.K1UpLoadDocument) {
          return DocumentTitleId.K1UpLoadDocument
        }
      }
    }
    return 0
  }
)

const selectNavitems = createSelector(
  selectEngagementId,
  selectTotalIssues,
  selectMissingReqDocs,
  selectUnrepliedComments,
  selectFlags,
  selectMatch,
  selectHasK1,
  (
    engagementId,
    totalIssues,
    missingReqDocs,
    unrepliedComments,
    flags,
    match,
    k1Exists
  ): NavItem[] => {
    const url = match.url.replace(/\/+$/, '')
    const urlSuffix = !match.params.entityGroupId
      ? ''
      : !engagementId
      ? '/engagements/all'
      : ''

    let navigationItems: NavItem[] = [
      {
        exact: true,
        icon: 'dashboard',
        path: `${url.replace('/engagements/all', '')}`,
        title: 'Dashboard',
      },
      {
        badgeCount: totalIssues,
        icon: 'checkedCircle',
        // navigateOnClick: false,
        path: `${url}${urlSuffix}/sections`,
        render: () => (
          <Route
            path={`${match.path}/sections/:sectionId`}
            component={SectionLinks}
          />
        ),
        title: 'Tasks',
      },
    ]

    const documentNavigationItem: NavItem = {
      badgeCount: missingReqDocs,
      icon: 'documents',
      path: `${url}${urlSuffix}/documents`,
      title: 'Documents',
    }

    if (k1Exists === DocumentTitleId.K1Document) {
      documentNavigationItem.render = function() {
        return (
          <Route
            path={`${match.path}/documents`}
            component={DocumentLibaryLinks}
          />
        )
      }
    }
    if (k1Exists === DocumentTitleId.K1UpLoadDocument) {
      documentNavigationItem.render = function() {
        return (
          <Route
            path={`${match.path}/documents`}
            component={DocumentLibaryK1UploadLinks}
          />
        )
      }
    }

    navigationItems = navigationItems.concat([
      documentNavigationItem,
      {
        badgeCount: unrepliedComments,
        icon: 'comments',
        path: `${url}${urlSuffix}/comments`,
        title: 'Comments',
      },
      {
        badgeCount: flags,
        icon: 'flags',
        path: `${url}${urlSuffix}/flags`,
        title: 'Flags',
      },
      {
        icon: 'carryForward',
        isInternal: true,
        path: `${url}${urlSuffix}/carryforward`,
        permission: UserPermissions.CarryForwardCanView,
        title: 'Carry Forward',
      },
      {
        icon: 'cog',
        path: `${url}${urlSuffix}/reports`,
        title: 'Reports',
      },
    ])
    return navigationItems
  }
)

interface EngagementsLeftNavMappedProps {
  items: NavItem[]
}

type EngagementsLeftNavProps = EngagementsLeftNavOwnProps &
  EngagementsLeftNavMappedProps

const mapStateToProps = (
  state: AppState,
  ownProps: EngagementsLeftNavOwnProps
): EngagementsLeftNavMappedProps => ({
  items: selectNavitems(state, ownProps),
})

class EngagementsLeftNav extends React.PureComponent<EngagementsLeftNavProps> {
  render = () => <LeftNav items={this.props.items} />
}

export default connect(mapStateToProps)(EngagementsLeftNav)

interface NameCache {
  activeCalls: {
    [name: string]: string[]
  }
  activeCallsWithId: {
    [name: string]: {
      [id: string]: string[]
      [id: number]: string[]
    }
  }
}
const nameCache: NameCache = {
  activeCalls: {},
  activeCallsWithId: {},
}

export function getNameGenerator (name: string) {
  let activeCalls = nameCache.activeCalls[name]
  let activeCallsWithId = nameCache.activeCallsWithId[name]

  if (!activeCalls) {
    nameCache.activeCalls[name] = activeCalls = []
  }
  if (!activeCallsWithId) {
    nameCache.activeCallsWithId[name] = activeCallsWithId = {}
  }

  function internalGetMarkName (id?: string | number) {
    const mark = id ? `${name}_start_${id}` : `${name}_start`
    return mark
  }
  function internalGetMeasureName (id?: string | number) {
    const measure = id ? `${name} (${id})` : name
    return measure
  }
  function getActiveCalls (id?: string | number) {
    if (id) {
      let calls = activeCallsWithId[id]
      if (!calls) {
        activeCallsWithId[id] = calls = []
      }
      return calls
    }
    return activeCalls
  }
  return {
    getMarkName: (id?: string | number) => {
      let mark = internalGetMarkName(id)
      const calls = getActiveCalls(id)
      if (calls.length > 0) {
        mark += `_subcall_${calls.length + 1}`
      }
      calls.push(mark)
      return mark
    },
    getMeasureName: (id?: string | number) => {
      const calls = getActiveCalls(id)
      const mark = calls.pop()
      const measure = internalGetMeasureName(id)
      return {
        markName: mark,
        measureName:
          calls.length > 0
            ? `${measure} Sub Measure (${calls.length})`
            : measure,
      }
    },
  }
}

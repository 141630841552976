import classNames from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { formatDateForDisplay } from '../../services/formatters/formatters'
import { AppState } from '../../store'
import Icon from '../icon/icon'
import './saving.scss'

interface SavingOwnProps {
  classes?: string[]
  isCompact?: boolean
  savingMessage?: string
  showIcon?: boolean
  showLastSaved?: boolean
  showSavingMessage?: boolean
  watch?: string
}

interface SavingMappedProps {
  lastSaved?: Date
  saving: string[]
}

const mapStateToProps = (state: AppState): SavingMappedProps => {
  return {
    lastSaved: state.http.lastSaved,
    saving: state.http.saving,
  }
}

type SavingProps = SavingOwnProps & SavingMappedProps

class Saving extends React.Component<SavingProps> {
  render () {
    const {
      classes,
      lastSaved,
      saving,
      savingMessage,
      showIcon,
      showLastSaved,
      showSavingMessage,
      watch,
    } = this.props

    const showSavingIcon =
      showIcon &&
      saving.length !== 0 &&
      (watch === undefined || !watch || saving.indexOf(watch) > -1)
    return (
      <div className='savingIcon'>
        {showLastSaved && lastSaved && (
          <div
            className={classNames('hint-text vertically-centered', {
              'icon-hidden': !showSavingIcon,
            })}
          >
            Last saved: {formatDateForDisplay(lastSaved)}
          </div>
        )}
        {showSavingIcon && (
          <div
            className={classNames(
              classes,
              'flex-between flex-row vertically-centered right-padding-small'
            )}
          >
            {(savingMessage || showSavingMessage) && (
              <div className='hint-text vertically-centered'>
                <span>{savingMessage || 'Saving...'}</span>
              </div>
            )}
            <Icon icon='saving' className='full-height' />
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps)(Saving)

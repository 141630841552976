import React, { useMemo } from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'
import { File as TsaFile } from '../../clientModels'
import Icon from '../icon/icon'
import classNames from 'classnames'

export interface FileDropZoneProps {
  maxFileCount: number
  busy: boolean
  files: TsaFile[]
  acceptedFileTypes?: string
  onDrop: (files: FileWithPath[]) => void
  isTemplate?: boolean
}

function getContent (
  isTemplate: boolean | undefined,
  toBeUploaded: number,
  fileCount: number,
  maxFileCount: number
): JSX.Element[] {
  const singleOrPlural = maxFileCount > 1 ? 's' : ''
  return fileCount >= maxFileCount
    ? isTemplate && (toBeUploaded === 0 || fileCount > 1)
      ? [
        <Icon key='1' icon='documents' />,
        <span key='2' className='select-text'>
            Uploading a new file will replace the current one
        </span>,
      ]
      : [
        <Icon key='1' icon='documents' />,
        <span key='2' className='select-text'>
            Maximum number of files reached
        </span>,
      ]
    : [
      <div key='0' className='helper-text d-none d-sm-block'>
          Files must be saved to your computer to be dragged here.
      </div>,
      <Icon key='1' className='d-none d-sm-block' icon='documents' />,
      <span key='2' className='drag-drop-text d-none d-sm-block'>
          Drag and drop file{singleOrPlural} here
      </span>,
      <div key='3' className='select-tex d-none d-sm-block'>
          (or click here to select file{singleOrPlural})
      </div>,
      <div key='4' className='select-text d-sm-none'>
          Click here to select file{singleOrPlural}
      </div>,
    ]
}

function FileDropZone ({
  maxFileCount,
  busy,
  files,
  onDrop,
  acceptedFileTypes,
  isTemplate,
}: FileDropZoneProps) {
  // Don't count deleted files
  const fileCount = useMemo(
    () => files.reduce((count, file) => count + (file.isDeleted ? 0 : 1), 0),
    [files]
  )
  const toBeUploaded = useMemo(
    () => files.reduce((count, file) => count + (file.id < 0 ? 1 : 0), 0),
    [files]
  )
  const disabled = busy || (!isTemplate && fileCount >= maxFileCount)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    disabled,
  })
  const content = getContent(isTemplate, toBeUploaded, fileCount, maxFileCount)

  return (
    <div
      className={classNames('dropzone', {
        'dropzone-disabled': disabled,
        'dropzone-active': isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {content}
    </div>
  )
}

export default FileDropZone

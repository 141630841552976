import classNames from 'classnames'
import * as React from 'react'
import { Option } from '../../clientModels'
import { OpenerInjectedProps, withOpener } from '../opener/opener'
import './dropdown.scss'
import { DropdownItem } from './dropdownItem'

type DropdownDirection = 'up' | 'down' | 'left' | 'right'

export interface DropdownMenuExternalProps {
  className?: string
  direction?: DropdownDirection
  disabled?: boolean
  disabledText?: string
  label?: string
  onChange?: (value: Option) => void
  options?: Option[]
  placeholder?: string
  value?: Option
}

type DropdownMenuProps = DropdownMenuExternalProps & OpenerInjectedProps

interface DropdownMenuState {
  valueLookup: {
    [key: string]: true | undefined
  }
}

class Dropdown extends React.Component<DropdownMenuProps, DropdownMenuState> {
  static defaultProps = {
    direction: 'down' as DropdownDirection,
  }

  state: DropdownMenuState = {
    valueLookup: {},
  }

  constructor(props: DropdownMenuProps) {
    super(props)

    if (props.disabled && props.opener) {
      props.opener.disable()
    }

    if (props.value) {
      if (Array.isArray(props.value)) {
        for (const value of props.value) {
          this.state.valueLookup[value.value] = true
        }
      } else {
        this.state.valueLookup[props.value.value] = true
      }
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: DropdownMenuProps) {
    const { props } = this
    const { disabled, opener } = nextProps

    if (!props.disabled && disabled && opener) {
      opener.disable()
    }
    if (props.disabled && !disabled && opener) {
      opener.enable()
    }

    if (props.value !== nextProps.value) {
      const nextState: DropdownMenuState = { valueLookup: {} }
      if (nextProps.value) {
        if (Array.isArray(nextProps.value)) {
          for (const value of nextProps.value) {
            nextState.valueLookup[value.value] = true
          }
        } else {
          nextState.valueLookup[nextProps.value.value] = true
        }
      }
      this.setState(nextState)
    }
  }

  render() {
    const {
      className,
      disabled,
      disabledText,
      direction,
      label,
      opener,
      options,
      placeholder,
      value,
    } = this.props
    const { valueLookup } = this.state

    const show = opener.isOpen

    const dropDirection = 'drop' + direction

    const dropdownClassName = classNames(
      'dropdown-component',
      className,
      dropDirection,
      {
        show,
      }
    )

    const buttonClassName = classNames('btn', {
      placeholder: !value,
    })

    const valueLabel = (value && value.label) || placeholder
    const buttonText = disabled ? disabledText || valueLabel : valueLabel

    return (
      <div className={dropdownClassName}>
        {label && <div className='dropdown-label'>{label}</div>}
        <button className={buttonClassName} disabled={disabled}>
          <div className='btn-label'>{buttonText}</div>
          {!disabled && (
            <div className='btn-toggle'>
              <div className='triangle' />
            </div>
          )}
        </button>
        <div className={classNames('dropdown-menu', { show })}>
          {options &&
            options.map(o => (
              <DropdownItem
                isActive={!!value && valueLookup[o.value]}
                key={o.value}
                option={o}
                onClick={this.handleChange}
              />
            ))}
        </div>
      </div>
    )
  }

  private handleChange = (value: Option) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }
}

const DropdownOpener = withOpener({
  closeOnClickInside: true,
  closeOnClickOutside: true,
})(Dropdown)

// tslint:disable:no-any - https://github.com/Microsoft/TypeScript/issues/28748
export default DropdownOpener as any

import { applyMiddleware, combineReducers, createStore, AnyAction } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk, { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk'
import { TsaHubAction } from './actions'
import {
  ClientMap,
  DocumentTitle,
  EngagementMap,
  EngagementTaskMap,
  EngagementTemplateFieldMap,
  EngagementTemplateMap,
  EngagementUploadHistory,
  EntityMap,
  EtlFile,
  FileGroup,
  PhaseMap,
  QuestionMap,
  Role,
  SectionMap,
  UserSettings,
  Engagement,
} from './clientModels'
import {
  openerReducer as opener,
  OpenerState,
} from './components/opener/openerReducer'
import { RoleCode } from './enums'
import * as reducers from './reducers'

import {
  AuthorizationState,
  adminCenterReducer as authorization,
} from './oidc-auth/adminCenterReducer'
import { ReduxSignalR } from './signalr/middleware'
import { Configuration } from './signalr'
import callToActionReducer, {
  CallToActionMessage,
} from './components/callToAction/callToActionModule'
import confirmModalReducer, {
  ConfirmModalState,
} from './reducers/confirmModalReducer'
import { PermissionsState } from './reducers/permissionsReducer'
import { batchedSubscribe } from 'redux-batched-subscribe'
import { REACT_APP_ENABLEIMMUTABLESTATEINVARIANT } from './envVariables'

export interface AppState {
  activities: reducers.ActivityState
  appErrorData: reducers.ErrorData[]
  async: reducers.AsyncOperationsState
  auth: reducers.AuthState
  authorization: AuthorizationState
  callToActionQueue: CallToActionMessage[]
  clients: ClientMap
  clientSearch: reducers.ClientSearchState
  clientAccessRequestSearch: reducers.clientAccessRequestSearchState
  confirmModal: ConfirmModalState
  debug?: reducers.DebugData
  dashboardEngagements: Engagement[]
  documentTitles: DocumentTitle[]
  engagementQuestions: reducers.EngagementQuestionsState
  engagements: EngagementMap
  engagementSections: reducers.EngagementSectionsState
  engagementSectionGroups: reducers.EngagementSectionGroupsState
  engagementTemplateFields: EngagementTemplateFieldMap
  engagementTemplates: EngagementTemplateMap
  engagementUploadHistory: EngagementUploadHistory[]
  entityGroups: reducers.EntityGroupsState
  etlFiles: EntityMap<EtlFile>
  etlProcess: reducers.EtlProcessState
  fileGroups: EntityMap<FileGroup>
  fileUpload: reducers.FileUploadState
  general: reducers.GeneralState
  http: reducers.HttpState
  inputProcess: reducers.InputProcessState
  k1Headers: reducers.k1HeaderState
  opener: OpenerState
  options: reducers.OptionsState
  permissions: PermissionsState
  phases: PhaseMap
  questions: QuestionMap
  roles: Map<RoleCode, Role>
  search: reducers.SearchState
  sections: SectionMap
  selection: reducers.SelectionState
  signalR: reducers.SignalRState
  tasks: EngagementTaskMap
  userSettings: UserSettings
  visibility: reducers.VisibilityState
  bannerFilter: reducers.BannerFilterState
}

const reducerMap = {
  activities: reducers.activitiesReducer,
  appErrorData: reducers.appErrorReducer,
  async: reducers.asyncOperationsReducer,
  auth: reducers.authReducer,
  authorization: authorization,
  callToActionQueue: callToActionReducer,
  clients: reducers.clientsReducer,
  clientSearch: reducers.clientSearchReducer,
  clientAccessRequestSearch: reducers.clientAccessRequestSearchReducer,
  dashboardEngagements: reducers.dashboardEngagementReducer,
  confirmModal: confirmModalReducer,
  documentTitles: reducers.documentTitleReducer,
  engagementQuestions: reducers.engagementQuestionsReducer,
  engagements: reducers.engagementsReducer,
  engagementSections: reducers.engagementSectionsReducer,
  engagementSectionGroups: reducers.engagementSectionGroupsReducer,
  engagementTemplateFields: reducers.engagementTemplateFieldsReducer,
  engagementTemplates: reducers.engagementTemplateReducer,
  engagementUploadHistory: reducers.engagementUploadHistoryReducer,
  entityGroups: reducers.entityGroupReducer,
  etlFiles: reducers.etlFilesReducer,
  etlProcess: reducers.etlProcessReducer,
  fileGroups: reducers.fileGroupsReducer,
  fileUpload: reducers.fileUploadReducer,
  general: reducers.generalReducer,
  http: reducers.httpReducer,
  inputProcess: reducers.inputProcessReducer,
  k1Headers: reducers.k1HeaderReducer,
  opener,
  options: reducers.optionsReducer,
  permissions: reducers.permissionsReducer,
  phases: reducers.phasesReducer,
  questions: reducers.questionsReducer,
  roles: reducers.rolesReducer,
  search: reducers.searchReducer,
  sections: reducers.sectionsReducer,
  selection: reducers.selectionReducer,
  signalR: reducers.signalRReducer,
  tasks: reducers.tasksReducer,
  userSettings: reducers.userSettingsReducer,
  visibility: reducers.visibilityReducer,
  bannerFilter: reducers.bannerFilterReducer,
}

if (window.DEBUG) {
  ;(reducerMap as any).debug = reducers.debugReducer
}

export const baseReducer = combineReducers<AppState>(reducerMap)
export const rootReducer = (
  currentState: AppState | undefined,
  action: AnyAction
): AppState => {
  switch (action.type) {
    case 'USE_NEW_STATE':
      // This is only used when the UI updates are no longer being suspended,
      //  and we need to inform observers that something might have changed.
      return currentState as any
    default:
      return baseReducer(currentState, action)
  }
}

export type TsaThunkAction<R = void> = ThunkAction<
  R,
  AppState,
  undefined,
  TsaHubAction
>

export type TsaDispatch = ThunkDispatch<AppState, undefined, TsaHubAction>

const compose = composeWithDevTools({
  maxAge: 400,
  // actionsBlacklist: ['OPENER_ALLOW_ONLY_ONE', 'GET_BEGIN', 'GET_END'],
  predicate: (_, action) => !['RULE'].some(a => action.type.startsWith(a)),
  shouldRecordChanges: false,
})

/** Flag that prevents updates to the UI from being made when dispatching actions.
 *   This allows batching of Rule Events to improve performance. */
let isDelayingUiUpdates = false

/** Returns a boolean value indicating whether or not UI updates are being performed. */
export const getIsDelayingUiUpdates = () => isDelayingUiUpdates

/** Sets a boolean value indicating that UI updates should be suspended.  This is
 *   mainly done for RulesEngine operations. */
export const setIsDelayingUiUpdates = (newVal: boolean) => {
  // Set the new value.
  isDelayingUiUpdates = newVal

  if (newVal) {
    // Make sure interested parties know that an update occurred.
    store.dispatch({ type: 'USE_NEW_STATE' })
  }
}

/** This enhancer is responsible for suspending UI updates and allowing them to proceed. */
const delayEnhancer = batchedSubscribe(notify => {
  if (!getIsDelayingUiUpdates()) {
    notify()
  }
})

export const store = createStore(
  rootReducer,
  {} as any,
  compose(
    process.env.NODE_ENV !== 'production' &&
      REACT_APP_ENABLEIMMUTABLESTATEINVARIANT === 'true'
      ? applyMiddleware(
          thunk as ThunkMiddleware<AppState, TsaHubAction>,
          ReduxSignalR(Configuration),
          require('redux-immutable-state-invariant').default()
        )
      : applyMiddleware(
          thunk as ThunkMiddleware<AppState, TsaHubAction>,
          ReduxSignalR(Configuration)
        ),
    delayEnhancer
  )
)

export default store

import classNames from 'classnames'
import * as React from 'react'
import Responsive from 'react-responsive'
import { matchPath, RouteComponentProps, withRouter } from 'react-router'
import { MediaMaxBreakpoint, MediaQueries } from '../../services/MediaQueries'
import InternalContent from '../internal/internalContent'
import './leftNav.scss'
import { LeftNavItem, NavItem } from './leftNavItem'
import PermissionContent from '../internal/permissionContent'

interface LeftNavProps extends RouteComponentProps<{}> {
  className?: string
  items?: NavItem[]
}

interface LeftNavState {
  open: boolean
  previousPath?: string
}

const itemIsActive = (item: NavItem | null) =>
  !!matchPath(window.location.pathname, item || {})

const findActiveItem = (props: LeftNavProps) => {
  const { items } = props
  return items && items.find(itemIsActive)
}

let screenIsXL = MediaQueries.xlUp.matches
MediaQueries.xlUp.addListener(m => {
  screenIsXL = m.matches
})

// tslint:disable-next-line:max-classes-per-file
class LeftNav extends React.Component<LeftNavProps, LeftNavState> {
  static screenIsXL = MediaQueries.xlUp.matches

  static getDerivedStateFromProps(props: LeftNavProps, state: LeftNavState) {
    const activeItem = findActiveItem(props)
    const haveSubNavContent = !!activeItem && !!activeItem.render
    const activePath = activeItem && activeItem.path

    if (activePath !== state.previousPath) {
      return {
        // Default subnav to open on XL screens
        open: haveSubNavContent && screenIsXL,
        previousPath: activePath,
      }
    }

    return null
  }

  state = { open: false }

  render() {
    const { className, items } = this.props
    const { open } = this.state
    const navClassName = classNames('left-nav', className, { open })
    return (
      <div className={navClassName}>
        <div className='left-nav-items'>
          {items &&
            items.map(i => {
              const Item = (
                <LeftNavItem
                  key={i.path}
                  {...i}
                  onClick={this.onMenuItemClick}
                />
              )

              if (i.permission !== undefined && i.permission != null) {
                return (
                  <PermissionContent key={i.path} permission={i.permission}>
                    {Item}
                  </PermissionContent>
                )
              } else if (i.isInternal) {
                return <InternalContent key={i.path}>{Item}</InternalContent>
              } else {
                return Item
              }
            })}
        </div>
        <Responsive maxWidth={MediaMaxBreakpoint.lg}>
          {this.renderContent}
        </Responsive>
      </div>
    )
  }

  private renderContent = (closeOnClick: boolean) => {
    const activeItem = findActiveItem(this.props)
    if (!activeItem) {
      return <div className='left-nav-content' />
    }

    const handleClick = closeOnClick ? this.closeContent : undefined
    return (
      <div className='left-nav-content' onClick={handleClick}>
        {activeItem && activeItem.render && activeItem.render()}
      </div>
    )
  }

  private closeContent = () => this.setState({ open: false })

  private onMenuItemClick = (item: NavItem, e: React.MouseEvent) => {
    let shouldNavigate: boolean = true
    this.setState(
      ({ open, previousPath }) => {
        const haveSubNavContent = !!item.render
        if (haveSubNavContent && item.path === previousPath) {
          shouldNavigate = false
          return { open: !open }
        }

        return null
      },
      () => {
        if (shouldNavigate) {
          const { history } = this.props
          history.push(item.path)
        }
      }
    )
  }
}

export default withRouter(LeftNav)

import { JsonRule } from 'json-rules-engine'
import {
  DocumentTitle,
  EngagementTemplate,
  EngagementTemplateField,
  OptionsGroup,
  Phase,
  Question,
  Section,
} from '../api/apiModels'
import * as http from '../http'
import { buildApiUrl } from './apiUrl'

export interface EngagementTemplateDocument {
  author: string
  documentTitles: DocumentTitle[]
  fields: EngagementTemplateField[]
  optionsGroups: OptionsGroup[]
  phases: Phase[]
  publicationDate: string
  questions: Question[]
  rules: JsonRule[]
  sections: Section[]
  template: EngagementTemplate
  version: string
}

export function getEngagementTemplateDoc(taxForm: string, taxYear: number) {
  const url = buildApiUrl(
    `taxformcontent/${taxForm}/${taxYear}/files/EngagementTemplate.json`
  )
  return http.get(url) as Promise<EngagementTemplateDocument>
}

export function apiDownloadTaxFormFile(
  taxForm: string,
  taxYear: number,
  fileName: string
) {
  const url = buildApiUrl(
    `taxformcontent/${taxForm}/${taxYear}/files/${fileName}`,
    { saveAsFile: 'true' }
  )
  return http.get(url) as Promise<http.BlobResponse>
}

export function checkIfFileExist(
  taxForm: string,
  taxYear: number,
  fileName: string
) {
  const url = buildApiUrl(`taxformcontent/${taxForm}/${taxYear}/${fileName}`)
  return http.get(url) as Promise<boolean>
}
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../store'
import { getDocumentTitles } from '../../actions/documentTitleThunks'

export function useDocumentTitles () {
  const dispatch = useDispatch()
  const documentTitles = useSelector(function (state: AppState) {
    return state.documentTitles
  })

  useEffect(function () {
    if (!documentTitles || documentTitles.length === 0) {
      dispatch(getDocumentTitles())
    }
  })

  return documentTitles
}

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFileUploadDialogOpen } from '../../actions/fileActions'
import { DocumentTitle, Engagement, FileGroup } from '../../clientModels'
import { AppState, TsaDispatch } from '../../store'
import { ConnectedFileUploadDialog } from '../fileUploadDialog/fileUploadDialog'
import Icon from '../icon/icon'
import './fileUploadAdvanced.scss'
import { ensureNumber } from '../../guards'
import { engagementLockedPhase } from '../../services/engagementPhaseService'
import { actions } from '../../actions'
import { getEntityMapValues, unique } from '../configureImport/helpers'
import { getDisplayStatusMap } from '../../services/fileHelpers'
import { FilterSortDropdown } from '../filterSortDropdown'
import { DataFileManagementOwnProps } from '../dataFileManagement/dataFileManagement'
import { useConfigureImportParams } from '../configureImport/useConfigureImportParams'
import { selectFileGroup } from '../dataFileManagement/dataFileManagementHelpers'

interface FileUploadAdvancedHeaderMappedProps {
  engagement?: Engagement
  engagementId?: number
  questionId?: number
  documentTitle?: DocumentTitle
  fileGroup?: FileGroup
  currentlyUploading: boolean
  statusFilter?: string
  statusFilterList?: any[]
}

const mapStateToProps = (
  state: AppState,
  props: DataFileManagementOwnProps
): FileUploadAdvancedHeaderMappedProps => {
  const fileGroup = selectFileGroup(state, props) || state.fileUpload.fileGroup
  const engagementId = ensureNumber(props.engagementId)
  const engagement = state.engagements[engagementId]
  const questionId = ensureNumber(props.questionId)
  const documentTitleId = props.documentTitleId
    ? ensureNumber(props.documentTitleId)
    : fileGroup
      ? fileGroup.documentTitleId
      : undefined
  const documentTitles =
    documentTitleId !== undefined
      ? state.documentTitles.filter((dt) => dt.id === documentTitleId)
      : undefined
  const documentTitle =
    documentTitles !== undefined && documentTitles.length > 0
      ? documentTitles[0]
      : undefined
  const fileStatus = state.fileUpload.uploadStatus
  const k1FileStatuses = state.inputProcess.k1FileStatuses
  const displayStatusMap = getDisplayStatusMap(
    fileGroup?.files || [],
    k1FileStatuses
  )
  let currentlyUploading = false
  const statusFilter = state.selection.selectedStatusFilter
  const statusFilterList: string[] = unique(
    getEntityMapValues(displayStatusMap)
  ).sort()

  for (const fileName in fileStatus) {
    const status = fileStatus[fileName]
    if (!status) {
      continue
    }
    if (status !== 'Uploaded' && status !== 'Ready to Map') {
      currentlyUploading = true
    }
    if (status !== 'Uploading') {
      currentlyUploading = false
    }
  }
  return {
    engagement,
    engagementId,
    questionId,
    documentTitle,
    fileGroup,
    currentlyUploading,
    statusFilter,
    statusFilterList,
  }
}

interface FileUploadAdvancedHeaderDispatchProps {
  openUploadDialog: (
    engagementId: number,
    questionId: number,
    documentTitle?: DocumentTitle,
    fileGroup?: FileGroup,
    isTemplate?: boolean,
    maxFileCount?: number
  ) => void
  selectFilter: (filter: string) => void
  exportK1documents: (fileIds: number[]) => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch
): FileUploadAdvancedHeaderDispatchProps => ({
  openUploadDialog: (
    engagementId: number,
    questionId: number,
    documentTitle?: DocumentTitle,
    fileGroup?: FileGroup,
    isTemplate?: boolean,
    maxFileCount?: number
  ) =>
    dispatch(
      setFileUploadDialogOpen({
        open: true,
        engagementId,
        questionId,
        documentTitle,
        fileGroup,
        isTemplate,
        maxFileCount,
      })
    ),
  selectFilter: (filter: string) => {
    dispatch(actions.selection.selectionSelectStatusFilterAction({ filter }))
  },
  exportK1documents: (fileIds: number[]) => {
    dispatch(actions.file.downloadFiles(fileIds))
  },
})

export const FileUploadAdvancedHeader = () => {
  const {
    engagementId,
    questionId,
    documentTitleId,
    fileGroupId,
  } = useConfigureImportParams()
  const dispatch = useDispatch()
  const appState = useSelector((state: AppState) => state)

  const props = {
    ...mapDispatchToProps(dispatch),
    ...mapStateToProps(appState, {
      engagementId,
      questionId,
      documentTitleId,
      fileGroupId,
    })
  }

  const addDocument = () => {
    props.openUploadDialog(
      engagementId || 0,
      questionId || 0,
      props.documentTitle,
      props.fileGroup
    )
  }

  const exportK1s = () => {
    const { fileGroup } = props
    let fileIds: number[]
    if (fileGroup && fileGroup.files) {
      fileIds = fileGroup.files.map((x) => x.id)
      props.exportK1documents(fileIds)
    }
  }

  const {
    currentlyUploading,
    documentTitle,
    engagement,
    selectFilter,
    statusFilter,
    statusFilterList,
  } = props

  // declare a new array... save the values from redux into the label value format for use with status dropdown
  const statusList: { value: string; label: string }[] = []
  const obj = { value: 'None', label: 'None' }
  statusList.push(obj)
  statusFilterList &&
    statusFilterList.forEach((item) => {
      const obj = { value: item, label: item }
      statusList.push(obj)
    })

  const engagementLocked = engagement
    ? engagement.isEnabled !== undefined
      ? engagement.isEnabled
      : engagementLockedPhase(engagement)
    : true

  return (
    <div className='file-upload-advanced-header'>
      <div className='file-upload-advanced-header-text'>
        <div className='file-upload-advanced-header-title'>
          {documentTitle && documentTitle.title}
        </div>
      </div>
      <div className='file-upload-advanced-header-buttons'>
        {documentTitle?.id === 59 && (
          <ExportK1DocumentsButton
            onClick={exportK1s}
            disabled={currentlyUploading || engagementLocked}
          />
        )}
        <AddDocumentsComponent
          onClick={addDocument}
          disabled={currentlyUploading || engagementLocked}
          engagementId={engagementId}
        />
      </div>
      <div className='file-upload-advanced-header-status'>
        <p>Filter by: Status</p>
        <div className='file-upload-advanced-header-status-dropdown'>
          <FilterSortDropdown
            options={statusList}
            value={statusFilter}
            onSelect={selectFilter}
          />
        </div>
      </div>
      <ConnectedFileUploadDialog
        engagementId={engagementId || -1}
        questionId={questionId || -1}
      />
    </div>
  )
}

interface AddDocumentsComponentProps {
  disabled?: boolean
  onClick: () => void
  engagementId?: number
}

function ExportK1DocumentsButton({
  disabled,
  onClick,
}: AddDocumentsComponentProps) {
  return (
    <button
      className='file-upload-advanced-buttons export-k1-documents btn'
      onClick={onClick}
      disabled={disabled}
    >
      Export K-1 Documents
    </button>
  )
}

function AddDocumentsComponent({
  disabled,
  onClick,
  engagementId,
}: AddDocumentsComponentProps) {
  // if (engagementId === 'all') {
  //   return <div />
  // }
  return (
    <button
      className='file-upload-advanced-buttons add-documents btn'
      onClick={onClick}
      disabled={disabled}
    >
      <Icon icon='plus' /> Add Documents
    </button>
  )
}

import * as React from 'react'
import './whatsNew.scss'

function WhatsNewHeader() {
  return (
    <div className='flag-header flex-wrap'>
      <div className='title'>What's New</div>
    </div>
  )
}

export default WhatsNewHeader

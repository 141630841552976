import classNames from 'classnames'
import * as React from 'react'
import { Message, Option } from '../../clientModels'
import './checkBox.scss'
import { hasError } from './formUtilities'

export interface CheckBoxProps {
  ariaDescribedBy?: string
  ariaLabelledBy?: string
  disabled?: boolean
  id: string
  labelId: string
  messages: Message[] | undefined
  name: string
  onBlur: (path: string) => void
  onChange: (option: Option, checked: boolean) => void
  onFocus: (path: string) => void
  option: Option
  path: string //  this is the property
  skipRules?: boolean
  value?: object | null
}

export class CheckBox extends React.Component<CheckBoxProps> {
  handleBlur = () => {
    const { onBlur, path } = this.props
    if (onBlur) {
      onBlur(path)
    }
  }

  handleFocus = () => {
    const { onFocus, path } = this.props
    if (onFocus) {
      onFocus(path)
    }
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { onChange, option } = this.props
    onChange(option, e.currentTarget.checked)
  }

  render() {
    const {
      ariaDescribedBy,
      ariaLabelledBy,
      disabled,
      id,
      labelId,
      name,
      option,
      value,
      path,
      messages,
    } = this.props
    const isInError = hasError(messages, path)
    // A checkbox is considered to be checked if the value contains a property
    // that matches the option value.
    const checked = !!value && {}.hasOwnProperty.call(value, option.value)
    return (
      <div
        key={option.value}
        className={classNames('checkbox check-box-input', {
          disabled,
          'is-valid': !isInError,
          'is-invalid': isInError,
        })}
      >
        <input
          aria-describedby={ariaDescribedBy}
          aria-labelledby={ariaLabelledBy}
          checked={checked}
          className='form-check-input'
          disabled={disabled}
          id={id}
          name={name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          type='checkbox'
          value={option.value}
        />
        <label className='form-check-label' id={labelId} htmlFor={id}>
          {option.label}
        </label>
      </div>
    )
  }
}

import * as React from 'react'
import Icon from '../icon/icon'
import Modal from './modal'

export interface AppErrorButton {
  autoFocus?: boolean
  onClick: () => void
  label: string | React.ReactNode
}

export interface AppErrorDialogProps {
  title?: string
  message1?: string
  message2?: string
  context: string
  buttons: AppErrorButton[]
  details?: string
}

export const DefaultErrorMessageLine1: string =
  'An error prevented your request from processing. Please refresh the page and try again. If you continue to encounter a problem,'
export const DefaultErrorMessageLine2: string = 'contact RSM for assistance.'
export const AppErrorDialog: React.SFC<AppErrorDialogProps> = ({
  title,
  message1,
  message2,
  context,
  buttons,
  details,
  children,
}) => {
  const [hideDetails, setHideDetails] = React.useState('hide-details')

  const toggleHideDetails = () => {
    if (hideDetails === 'hide-details') setHideDetails('')
    else setHideDetails('hide-details')
  }

  return (
    <Modal>
      <div className='modal critical'>
        <div className='modal-body'>
          <div className='modal-icon'>
            <Icon icon='timesCircle' />
          </div>
          <div className='modal-title'>{title || 'Error Occurred'}</div>
          {message1 && <div className='modal-info'>{message1}</div>}
          {message2 && <div className='modal-info'>{message2}</div>}
          <div className='modal-info'>{context}</div>
          {children &&
            (<div className='modal-children'>{children}</div>)
          }
          <div className='modal-buttons'>
            {buttons.map((b, i) => (
              <button
                key={i}
                className='btn btn-primary'
                autoFocus={b.autoFocus}
                onClick={b.onClick}
              >
                {b.label}
              </button>
            ))}
          </div>
          {details && (
            <div className='modal-details' onClick={toggleHideDetails}>
              Details
            </div>
          )}
          {details && (
            <div className={`modal-details-info ${hideDetails}`}>{details}</div>
          )}
        </div>
      </div>
    </Modal>
  )
}

import { History } from 'history'
import * as React from 'react'
import { connect } from 'react-redux'
import { match } from 'react-router'
import { AppState, TsaDispatch } from '../../store'
import InternalContent from '../internal/internalContent'
import { close } from '../opener/openerActions'
import { RelativeLink } from '../relativeLink'
import Saving from '../saving'
import './commentHeader.scss'
import { CommentFilterOptions, CommentFilters } from './commentLibraryFilters'
import {
  selectionSelectCommentFilterAction,
  selectionSelectCommentSortAction,
} from '../../actions/selectionActions'
import { CommentSortOptions } from './commentLibrarySorts'
import { CommentSorts } from '.'
import { FilterSortDropdown } from '../filterSortDropdown'

interface RouteParameters {
  engagementId: string
}

interface OwnProperties {
  match: match<RouteParameters>
  history: History
}

interface MappedProperties {
  selectedFilter?: keyof CommentFilters
  selectedSort?: keyof CommentSorts
}

const mapStateToProps = (
  state: AppState,
  ownProps: OwnProperties
): MappedProperties => {
  return {
    selectedFilter: state.selection.selectedCommentFilter,
    selectedSort: state.selection.selectedCommentSort,
  }
}

interface DispatchProperties {
  closeAll: () => void
  selectFilter: (filter: keyof CommentFilters) => void
  selectSort: (sort: keyof CommentSorts) => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  ownProps: OwnProperties
): DispatchProperties => ({
  closeAll: () => dispatch(close('CommentLibrary')),
  selectFilter: (filter: keyof CommentFilters) =>
    dispatch(selectionSelectCommentFilterAction({ filter })),
  selectSort: (sort: keyof CommentSorts) => {
    dispatch(selectionSelectCommentSortAction({ sort }))
  },
})

type Properties = OwnProperties & MappedProperties & DispatchProperties

class CommentHeader extends React.Component<Properties> {
  constructor(props: Properties) {
    super(props)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
  }

  onFilterChange(value: any) {
    const {
      props: {
        closeAll,
        selectFilter,
        selectedFilter,
        history: { replace },
        match: { url },
      },
    } = this
    if (selectedFilter !== value) {
      selectFilter(value)
    }

    replace(`${url}`)
    closeAll()
  }

  onSortChange(value: any) {
    const {
      props: {
        selectedSort,
        selectSort,
        closeAll,
        history: { replace },
        match: { url },
      },
    } = this
    if (selectedSort !== value) {
      selectSort(value)
    }
    replace(`${url}`)
    closeAll()
  }

  render() {
    const {
      onFilterChange,
      onSortChange,
      props: {
        selectedFilter,
        selectedSort,
        match: { params },
      },
    } = this
    return (
      <div className='engagement-header'>
        <div className='comment-header flex-wrap'>
          <div>
            <RelativeLink to={`engagements/${params.engagementId}/comments`}>
              Comments
            </RelativeLink>
            <Saving showLastSaved={true} />
          </div>
          <InternalContent>
            <div className='filter ml-0 ml-lg-auto'>
              <FilterSortDropdown
                options={CommentFilterOptions}
                value={selectedFilter}
                onSelect={onFilterChange}
                label='Filter By:'
              />
            </div>
          </InternalContent>
          <InternalContent>
            <div className='sort d-none d-lg-block'>
              <FilterSortDropdown
                options={CommentSortOptions}
                value={selectedSort}
                onSelect={onSortChange}
                label='Sort By:'
              />
            </div>
          </InternalContent>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentHeader)

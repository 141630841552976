import React, { useEffect, useCallback } from 'react'
import { useRouteMatch, useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../store'
import { ensureNumber } from '../../guards'
import { createUseStyles, useTheme } from 'react-jss'
import { Theme } from '@rsmus/react-ui'
import Icon from '../icon/icon'
import * as callToActionActions from './callToActionModule'
import * as confirmModalActions from '../../actions/confirmModalActions'

function styles({ palette }: Theme) {
  return {
    root: {
      backgroundColor: palette.informational.yellow,
      color: palette.common.white,
    },
    icon: {
      height: '1.5rem',
      width: '1.5rem',
      margin: '0.5rem'
    },
    messageText: {
      display: 'inline'
    },
    actionText: {
      color: palette.blue.dark,
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const CallToAction = () => {
  const route = useRouteMatch<{ engagementId: string }>()
  const history = useHistory();
  const theme = useTheme()
  const classes = useStyles(theme)
  const engagement = useSelector((state: AppState) => {
    return state.engagements[ensureNumber(route.params.engagementId)]
  })
  const busy = useSelector((appState: AppState) => appState.http.loading.includes('update-engagement-phase'))
  const user = useSelector((appState: AppState) => appState.auth.user)
  const currentMessage = useSelector((appState: AppState) => {
    const messageQueue = appState.callToActionQueue
    if (messageQueue.length > 0 && messageQueue[messageQueue.length - 1]) {
      return messageQueue[messageQueue.length - 1]
    }
    return null
  })
  const dispatch = useDispatch()

  const completeMessage = useCallback(() => {
    const modalConfig = {
      phaseChange: 'PBC'
    }
    dispatch(confirmModalActions.setConfirmModalAction({ confirmModal: modalConfig }))
    const endOfPath = history.location.pathname.split('/').pop()
    const redirectPaths = ['comments', 'documents', 'flags', 'carryForward', 'reports']
    if (endOfPath && redirectPaths.includes(endOfPath)) {
      history.push(`/engagements/${route.params.engagementId}`)
    }

  }, [dispatch, history, route.params.engagementId])

  const addMessage = useCallback(() => {
    const message: callToActionActions.CallToActionMessage = {
      type: 'READY_FOR_REVIEW',
      messageText: 'This engagement is ready to ',
      actionText: 'submit for review',
      callback: () => completeMessage()
    }
    dispatch(callToActionActions.addCallToActionMessageAction({ message }))
  }, [dispatch, completeMessage])

  const popMessage = useCallback(() => {
    dispatch(callToActionActions.popCalltoActionMessageAction())
  }, [dispatch])

  useEffect(() => {
    if (user?.isExternal && engagement?.phase === 'pbc' && !busy) {
      const phaseComplete = engagement.successfulQuestions >= engagement.totalQuestions
      const existingMessage = currentMessage?.type === 'READY_FOR_REVIEW'
      if (phaseComplete && !existingMessage) {
        addMessage()
      }
      if (!phaseComplete && existingMessage) {
        popMessage()
      }
    } else if (user?.isExternal && engagement?.phase !== 'pbc') {
      popMessage()
    }
    // eslint-disable-next-line
  }, [user, engagement, busy])

  if (currentMessage) {
    return (
      <div className={classes.root}>
        <Icon className={classes.icon} icon='infoCircle'></Icon>
        <p className={classes.messageText}>{currentMessage.messageText}<span className={classes.actionText} onClick={currentMessage.callback}>{currentMessage.actionText}</span>.</p>
      </div>
    )
  }
  return (
    null
  )
}

export default CallToAction
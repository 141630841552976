import React, { useRef, useState, useEffect } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { ensureNumber, isString } from '../../guards'
import { useMatchMediaQuery, MediaQueries } from '../../services/MediaQueries'

export interface VirtualListProps {
  children: (index: number) => JSX.Element
  itemCount: number
}

/**
 * Creates a list of elements with virtual scrolling that grows to the dimensions of its parent container.
 * @param children The render function for each list item. Requires an index parameter.
 * @param itemCount The count of items to render in the list. Used for calculating scrollable area.
 */
const VirtualList: React.SFC<VirtualListProps> = ({ children, itemCount }) => {
  const [rowWidth, setRowWidth] = useState<string | undefined>()
  const [rowHeight, setRowHeight] = useState<number | null>(null)
  const rowsRef: any = useRef()
  const rowRef: any = useRef()

  useEffect(() => {
    if (rowsRef && rowsRef.current) {
      const width = window.getComputedStyle(rowsRef.current).width
      setRowWidth(width)
    }
    if (rowRef && rowRef.current) {
      const heightStyle = window.getComputedStyle(rowRef.current).height
      if (isString(heightStyle)) {
        const height = ensureNumber((heightStyle as string).replace('px', ''))
        setRowHeight(height)
      }
    }
  }, [])

  // Need these listeners in place to ensure that this component rerenders correctly on resize
  useMatchMediaQuery(MediaQueries.smDown)
  useMatchMediaQuery(MediaQueries.mdDown)
  useMatchMediaQuery(MediaQueries.lgDown)

  return (
    <div
      className='virtual-list'
      style={{ position: 'relative', height: '100%', width: '100%' }}
      ref={rowsRef}
    >
      <div
        style={{
          visibility: 'hidden',
          width: rowWidth,
          marginBottom: rowHeight ? -rowHeight : 0,
        }}
        ref={rowRef}
      >
        {children(0)}
      </div>
      {rowHeight && (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              itemCount={itemCount}
              itemSize={rowHeight}
              width={width}
            >
              {({ index, style }) => {
                return <div style={style}>{children(index)}</div>
              }}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </div>
  )
}

export default VirtualList

import * as React from 'react'
import { Address } from '../address/index'
import { AddressCheck } from '../addressCheck/index'
import { AddressCountyCheck } from '../addressCountyCheck'
import BooksInCareOf from '../booksInCareOf/booksInCareOf'
import { Datasheet, ReadOnlyDataSheet } from '../datasheet/index'
import {
  CheckBoxesField,
  ComboBoxCreateableField,
  ComboBoxCreateableMultiSelectField,
  ComboBoxField,
  ComboBoxMultiSelectField,
  DateTimeField,
  EmptyForm,
  NumberField,
  OptionsFormFieldProps,
  RadioButtonsField,
  TextAreaField,
  TextBoxField,
  TextAreaAutoField,
} from '../forms'
import { Name } from '../name/index'
import { QuestionComponentMap } from '../questionContainer/index'
import { TextBoxCheckBox } from '../textBoxCheckBox/index'
import question from './question'

interface UINotFoundProps {
  component: string
}

const UINotFound: React.SFC<OptionsFormFieldProps & UINotFoundProps> = ({
  component,
}) => (
  <div className='form-group'>
    <em className='form-check-inline'>
      Form component not found. ({component})
    </em>
  </div>
)

// Form Components
export const UINotFoundQuestion = question(UINotFound)
export const AddressQuestion = question(Address)
export const AddressCheckQuestion = question(AddressCheck)
export const AddressCountyCheckQuestion = question(AddressCountyCheck)
export const BooksInCareOfQuestion = question(BooksInCareOf)
export const CheckBoxesQuestion = question(CheckBoxesField)
export const ComboBoxQuestion = question(ComboBoxField)
export const ComboBoxCreateableQuestion = question(ComboBoxCreateableField)
export const ComboBoxMultiSelectQuestion = question(ComboBoxMultiSelectField)
export const ComboBoxCreateableMultiSelectQuestion = question(
  ComboBoxCreateableMultiSelectField
)
export const DatasheetQuestion = question(Datasheet)
export const DateTimeQuestion = question(DateTimeField)
export const EmptyQuestion = question(EmptyForm)
export const NameQuestion = question(Name)
export const NumberQuestion = question(NumberField)
export const RadioButtonsQuestion = question(RadioButtonsField)
export const ReadOnlyDatasheetQuestion = question(ReadOnlyDataSheet)
export const TextAreaQuestion = question(TextAreaField)
export const TextAreaAutoQuestion = question(TextAreaAutoField)
export const TextBoxCheckBoxQuestion = question(TextBoxCheckBox)
export const TextBoxQuestion = question(TextBoxField)

export const QuestionComponentLookup: QuestionComponentMap = {
  address: AddressQuestion,
  addresscheck: AddressCheckQuestion,
  addresscountycheck: AddressCountyCheckQuestion,
  booksincareof: BooksInCareOfQuestion,
  checkbox: CheckBoxesQuestion,
  combobox: ComboBoxCreateableQuestion,
  datasheet: DatasheetQuestion,
  date: DateTimeQuestion,
  empty: EmptyQuestion,
  multicombobox: ComboBoxCreateableMultiSelectQuestion,
  multiselect: ComboBoxMultiSelectQuestion,
  name: NameQuestion,
  number: NumberQuestion,
  radio: RadioButtonsQuestion,
  readonlydatasheet: ReadOnlyDatasheetQuestion,
  select: ComboBoxQuestion,
  text: TextBoxQuestion,
  textarea: TextAreaQuestion,
  textareaauto: TextAreaAutoQuestion,
  textcheck: TextBoxCheckBoxQuestion,
  _default: UINotFoundQuestion,
}

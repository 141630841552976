import * as React from 'react'
import { AppInsights } from '../../services/appInsights/index'
import {
  AppErrorDialog,
  DefaultErrorMessageLine1,
  DefaultErrorMessageLine2,
} from '../modals/index'

interface ErrorBoundarySidebarState {
  error: Error | undefined
  errorInfo: object
  showWarningDialog: boolean
}

export default class ErrorBoundary extends React.Component<
  {},
  ErrorBoundarySidebarState
  > {
  constructor (props: {}) {
    super(props)

    this.state = {
      error: undefined,
      errorInfo: {},
      showWarningDialog: false,
    }
  }

  componentDidCatch (error: Error, errorInfo: object) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
      showWarningDialog: true,
    })

    const errorBoundaryName = 'TsaErrorBoundary'

    // You can also log error messages to an error reporting service here
    AppInsights.trackException(error, undefined, { errorBoundaryName })
  }

  closeModal = () => {
    this.setState({
      // error: undefined,
      // errorInfo: {},
      showWarningDialog: false,
    })
  }

  // tslint:disable:align whitespace no-unused-expression
  render () {
    return (
      <React.Fragment>
        {!this.state.showWarningDialog &&
          !this.state.error &&
          this.props.children}
        {this.state.showWarningDialog && (
          <AppErrorDialog
            message1={DefaultErrorMessageLine1}
            message2={DefaultErrorMessageLine2}
            context={''}
            buttons={[
              { label: 'Close', autoFocus: true, onClick: this.closeModal },
            ]}
          />
        )}
      </React.Fragment>
    )
  }
}

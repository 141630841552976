import {
  UserSettings,
  LastNavigation,
  LastNavigationMap,
} from '../clientModels'

// This will read local storage  and save the last navigation to LocalStorage.
// This is called when a user selected a quesiton in engagementsection.tsx
export function SaveLastNavigation (lastNav: LastNavigation) {
  const userSettingString = localStorage.getItem('usersettings')
  let userSetting: UserSettings = {
    lastNavigationMap: {},
  }
  if (userSettingString) {
    userSetting = JSON.parse(userSettingString)
    userSetting.lastNavigationMap[lastNav.engagementId] = lastNav
  } else {
    userSetting.lastNavigationMap[lastNav.engagementId] = lastNav
  }
  localStorage.setItem('usersettings', JSON.stringify(userSetting))
}

// This will read from local storage the all the last navigation properties
// for all the engagements the user has interacted with.

export function GetAllLastNavigation (): LastNavigationMap {
  const lastNavMap: LastNavigationMap = {}
  const userSettingString = localStorage.getItem('usersettings')
  let userSetting: UserSettings
  if (userSettingString) {
    userSetting = JSON.parse(userSettingString)
    if (userSetting.lastNavigationMap) {
      return userSetting.lastNavigationMap
    }
  }
  return lastNavMap
}

import classNames from 'classnames'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { createSelector } from 'reselect'
import { actions } from '../../actions/index'
import { FileGroup, File } from '../../clientModels'
import { ensureNumber } from '../../guards'
import { engagementLockedPhase } from '../../services/engagementPhaseService'
import { latestFilesFromFileGroups } from '../../services/fileHelpers'
import { AppState } from '../../store'
import Icon from '../icon/icon'
import DataFileGrid from './dataFileGrid'
import './dataFileManagement.scss'
import { useDocumentTitles } from './useDocumentTitles'
import LoadingComponent from '../loading'
import { groupBy, Dictionary } from 'lodash'

interface RouteParams {
  documentTitleId?: string
  engagementId: string
  questionId?: string
  fileGroupId?: string
  entityGroupId?: string
}

interface DataFileManagementGroupProps
  extends RouteComponentProps<RouteParams> {}

const selectBusyIndicator = createSelector(
  (state: AppState) => state.http.loading,
  loading => loading.includes(actions.file.FileUploadBusyIndicator)
)

function selectEntityGroup(
  state: AppState,
  props: DataFileManagementGroupProps
) {
  if (!props.match.params.entityGroupId) {
    return
  }
  return state.entityGroups[props.match.params.entityGroupId]
}

function selectSelectedFiles(state: AppState) {
  return state.fileUpload.selectedFiles
}

function selectFileStatus(state: AppState) {
  return state.fileUpload.uploadStatus
}

function makeFileGroupsSelector() {
  return createSelector(
    selectEntityGroup,
    (state: AppState) => state.fileGroups,
    (_: AppState, props: DataFileManagementGroupProps) =>
      props.match.params.documentTitleId,
    (entityGroup, fileGroups, documentTitleId) => {
      const groups: FileGroup[] = []
      if (!entityGroup || !documentTitleId) {
        return groups
      }

      const docTitleId = ensureNumber(documentTitleId)

      const engagementIds = entityGroup.entityEngagements.map(
        e => e.engagementId
      )
      for (const fileGroup of Object.values(fileGroups)) {
        if (
          fileGroup &&
          fileGroup.engagementId &&
          engagementIds.includes(fileGroup.engagementId) &&
          fileGroup.documentTitleId === docTitleId
        ) {
          groups.push(fileGroup)
        }
      }

      return groups
    }
  )
}

function convertToNumberOrUndefined(value?: string) {
  if (!value) {
    return undefined
  }
  return ensureNumber(value)
}

function DataFileManagementGroup(props: DataFileManagementGroupProps) {
  // #region Props
  const engagementId = useMemo(
    function() {
      return convertToNumberOrUndefined(props.match.params.engagementId)
    },
    [props.match.params.engagementId]
  )
  const questionId = useMemo(
    function() {
      return convertToNumberOrUndefined(props.match.params.questionId)
    },
    [props.match.params.questionId]
  )
  const entityGroupId = useMemo(
    function() {
      return convertToNumberOrUndefined(props.match.params.entityGroupId)
    },
    [props.match.params.entityGroupId]
  )
  // #endregion

  // #region App State
  // #region Selectors
  const selectFileGroups = useMemo(makeFileGroupsSelector, [])
  // #endregion

  const busy = useSelector(selectBusyIndicator)
  const uploadStatus = useSelector(selectFileStatus)
  const stateSelectedFiles = useSelector(selectSelectedFiles)
  const entityGroup = useSelector(function(state: AppState) {
    return selectEntityGroup(state, props)
  })
  const etlFiles = useSelector((state: AppState) => state.etlFiles)
  const fileGroups = useSelector(function(state: AppState) {
    return selectFileGroups(state, props)
  })
  const [loading, setLoading] = useState(true)
  // #endregion

  // #region memoized
  const files = useMemo(
    function() {
      return latestFilesFromFileGroups(fileGroups)
    },
    [fileGroups]
  )

  const selectedFiles = useMemo(
    function() {
      return files.filter(x => stateSelectedFiles[x.id])
    },
    [files, stateSelectedFiles]
  )

  const draftFileSelected = useMemo(
    function() {
      return selectedFiles.some(
        f => f.tags && f.tags.some(t => t.tag === 'draft')
      )
    },
    [selectedFiles]
  )

  const allFilesSelected = useMemo(
    function() {
      return files.length > 0 && selectedFiles.length === files.length
    },
    [files, selectedFiles]
  )

  const fileGroupsLookup = useMemo(
    function() {
      const result: Dictionary<FileGroup> = {}
      return fileGroups.reduce(function(previousValue, currentValue) {
        previousValue[currentValue.id] = currentValue
        return previousValue
      }, result)
    },
    [fileGroups]
  )

  const lockedEngagements = useMemo(
    function() {
      const result: Dictionary<boolean> = {}
      const entityEngagements = entityGroup && entityGroup.entityEngagements

      if (entityEngagements) {
        for (const ee of entityEngagements) {
          result[ee.engagementId] = engagementLockedPhase(ee)
        }
      }

      return result
    },
    [entityGroup]
  )

  // #endregion

  // #region dispatch
  const dispatch = useDispatch()

  const downloadFile = useCallback(
    function(fileId: number) {
      return dispatch(actions.file.downloadFile(fileId))
    },
    [dispatch]
  )

  const downloadFiles = useCallback(
    function(fileIds: number[]) {
      return dispatch(actions.file.downloadFiles(fileIds))
    },
    [dispatch]
  )

  const toggleSelectFile = useCallback(
    function(fileId: number) {
      return dispatch(actions.file.toggleSelectFile(fileId))
    },
    [dispatch]
  )

  const toggleTag = useCallback(
    function(tag: string, fileIds: number[]) {
      const filesByFileGroupId = groupBy(files, 'fileGroupId')
      for (const fgi in filesByFileGroupId) {
        const filesLookup = groupBy(filesByFileGroupId[fgi], 'id')
        const fileGroupId = ensureNumber(fgi)
        const applicableFileIds = fileIds.filter(fi => !!filesLookup[fi])

        if (fileGroupId === 0 && applicableFileIds.length > 0) {
          continue
        }

        return dispatch(
          actions.file.toggleTag(
            tag,
            engagementId || 0,
            questionId || 0,
            fileGroupId,
            applicableFileIds
          )
        )
      }
    },
    [dispatch, engagementId, files, questionId]
  )

  // #endregion

  // #region effects
  useEffect(() => {
    async function loadEntityGroups() {
      if (entityGroupId) {
        await dispatch(actions.file.getFileGroupsForEntityGroup(entityGroupId))
      }
      setLoading(false)
    }
    loadEntityGroups()
  }, [dispatch, entityGroupId])

  useDocumentTitles()
  // #endregion

  // #region Callbacks
  const onClickSelectRow = useCallback(
    function(fileId: number) {
      toggleSelectFile(fileId)
    },
    [toggleSelectFile]
  )

  const onClickFileCheckbox = useCallback(
    function(fileId: number, tag: string) {
      toggleTag(tag, [fileId])
    },
    [toggleTag]
  )

  const selectAllFiles = useCallback(
    function() {
      if (allFilesSelected) {
        files.map(file => onClickSelectRow(file.id))
      } else {
        files
          .filter(x => !stateSelectedFiles[x.id])
          .map(file => onClickSelectRow(file.id))
      }
    },
    [allFilesSelected, files, onClickSelectRow, stateSelectedFiles]
  )

  const unselectAllFiles = useCallback(
    function() {
      files
        .filter(x => stateSelectedFiles[x.id])
        .map(file => onClickSelectRow(file.id))
    },
    [files, onClickSelectRow, stateSelectedFiles]
  )

  const groupMarkAsDraft = useCallback(
    function() {
      if (busy) {
        return false
      }

      let toggleFiles

      const selectedDraftFiles = selectedFiles
        .filter(x => x.tags && x.tags.some(y => y.tag === 'draft'))
        .map(x => x.id)

      if (
        selectedDraftFiles.length === selectedFiles.length ||
        selectedDraftFiles.length === 0
      ) {
        toggleFiles = selectedFiles.map(x => x.id)
      } else {
        toggleFiles = selectedFiles
          .filter(x => !selectedDraftFiles.includes(x.id))
          .map(x => x.id)
      }
      toggleTag('', toggleFiles)

      unselectAllFiles()
    },
    [busy, selectedFiles, toggleTag, unselectAllFiles]
  )

  const groupDownload = useCallback(
    function() {
      if (busy) {
        return false
      }

      const selectedFileCount = selectedFiles.length

      if (selectedFileCount === 1) {
        if (downloadFile) {
          const selectedFile = selectedFiles[0]
          downloadFile(selectedFile.id)
        }
      }

      if (selectedFileCount > 1) {
        const fileIds = selectedFiles.map(file => file.id)
        downloadFiles(fileIds)
      }

      unselectAllFiles()
    },
    [busy, selectedFiles, unselectAllFiles, downloadFile, downloadFiles]
  )

  const engagementLocked = useCallback(
    function(id: number) {
      const lockedEngagement = lockedEngagements[id]

      return lockedEngagement !== undefined ? lockedEngagement : true
    },
    [lockedEngagements]
  )

  const fileEngagementLocked = useCallback(
    function(file: File) {
      if (!file.fileGroupId) {
        return true
      }
      const fileGroup = fileGroupsLookup[file.fileGroupId]
      if (!fileGroup || !fileGroup.engagementId) {
        return true
      }
      return engagementLocked(fileGroup.engagementId)
    },
    [fileGroupsLookup, engagementLocked]
  )

  // #endregion

  // #region Memo Depending on Callback
  const selectedEngagementLocked = useMemo(
    function() {
      for (const file of selectedFiles) {
        if (fileEngagementLocked(file)) {
          return true
        }
      }
      return false
    },
    [selectedFiles, fileEngagementLocked]
  )
  // #endregion

  if (loading && (!fileGroups || fileGroups.length === 0)) {
    return <LoadingComponent alwaysLoading={true} showLoadingMessage={true} />
  }

  const fileSelected = selectedFiles.length > 0

  const disableGroupButtons = selectedEngagementLocked || !fileSelected || busy

  return (
    <div className='file-upload-advanced'>
      <div
        className={classNames('file-upload-advanced-content', {
          disabled: !disableGroupButtons,
        })}
      >
        <div className={classNames('file-upload-advanced-buttons')}>
          <button
            className={classNames('file-upload-advanced-buttons-download btn', {
              disabled: !fileSelected || busy,
            })}
            onClick={fileSelected ? groupDownload : undefined}
          >
            <Icon icon='download' active={fileSelected} /> Download
          </button>
          <button
            className={classNames('file-upload-advanced-buttons-draft btn', {
              disabled: disableGroupButtons || draftFileSelected,
            })}
            onClick={!disableGroupButtons ? groupMarkAsDraft : undefined}
          >
            <Icon icon='fileText' active={fileSelected} /> Mark as Draft
          </button>
        </div>
        <DataFileGrid
          allFilesSelected={allFilesSelected}
          disabled={fileEngagementLocked}
          files={files}
          etlFiles={etlFiles}
          handlers={{
            onClickDownload: downloadFile,
            onClickFileCheckbox,
            onClickSelectRow,
          }}
          selectAllFiles={selectAllFiles}
          selectedFiles={stateSelectedFiles}
          uploadStatus={uploadStatus}
        />
      </div>
    </div>
  )
}

export default DataFileManagementGroup

import classNames from 'classnames'
import computeScrollIntoView from 'compute-scroll-into-view'
import * as React from 'react'
import {
  Activity,
  Engagement,
  IdentityTokenProfile,
  Question,
} from '../../clientModels'
import Icon from '../icon/icon'
import InternalContent from '../internal/internalContent'
import './activity.scss'
import { ActivityListGroup } from './activityListGroup'
import { ActivityMarkAllCarryForward } from './activityMarkAllCarryForward'
import { PhaseCode } from '../../enums'
import { useState, useEffect, useRef } from 'react'
import { usePermissionManager } from '../../hooks'

interface ActivityListProps {
  activities: Activity[]
  deleteActivity: (activityId: number) => void
  engagement?: Engagement
  saveActivity: (activity: Activity) => void
  selectedActivityId: number
  user?: IdentityTokenProfile
  question?: Question
  questionDisabled: boolean
  autoScroll: boolean
}

interface ActivityListState {
  carryForwardAllId: number | null
  hasComments: boolean
}

export const ActivityList = (props: ActivityListProps) => {
  const prevActivityCount = usePrevious(parentActivityCount(props.activities))
  const prevSelectedActivityId = usePrevious(props.selectedActivityId)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const [
    selectedActivityGroup,
    setSelectedActivityGroup,
  ] = useState<HTMLDivElement | null>(null)
  const defaultActivityListState = {
    carryForwardAllId: null,
    hasComments: false,
  }

  const checkForCarryForwardAll = (state: ActivityListState) => {
    if (props.activities && props.activities.length > 0) {
      state.hasComments = false
      state.carryForwardAllId = null
      for (
        let i = 0;
        (state.carryForwardAllId === null || !state.hasComments) &&
        i < props.activities.length;
        ++i
      ) {
        const activity = props.activities[i]
        if (activity.activityTypeCode === 'CarryForwardAllComments') {
          state.carryForwardAllId = activity.id
        }
        if (activity.activityTypeCode === 'Comment') {
          state.hasComments = true
        }
      }
    }

    return state
  }
  const [activityListState, setActivityListState] = useState(
    checkForCarryForwardAll(defaultActivityListState)
  )

  useEffect(() => {
    scrollToBottom()
  }, [])

  useEffect(() => {
    setActivityListState(checkForCarryForwardAll(activityListState))

    // If new activity added scroll to bottom
    if (
      prevActivityCount &&
      prevActivityCount !== parentActivityCount(props.activities)
    ) {
      scrollToBottom()
    }

    if (
      prevSelectedActivityId &&
      prevSelectedActivityId !== props.selectedActivityId
    ) {
      scrollToSelectedActivity()
    }
  }, [props.activities, props.selectedActivityId])

  const scrollToBottom = () => {
    if (!props.autoScroll) {
      return
    }
    const scrollParent = getScrollParent(container)
    if (scrollParent) {
      scrollParent.scrollTop = scrollParent.scrollHeight
    }
  }

  const scrollToSelectedActivity = () => {
    const { autoScroll } = props
    if (autoScroll && selectedActivityGroup) {
      computeScrollIntoView(selectedActivityGroup, {
        block: 'nearest',
        scrollMode: 'if-needed',
      }).forEach(({ el, top, left }) => {
        el.scrollTop = top
        el.scrollLeft = left
      })
    }
  }

  const {
    activities,
    deleteActivity,
    engagement,
    saveActivity,
    selectedActivityId,
    user,
    question,
    questionDisabled,
  } = props
  const { carryForwardAllId, hasComments } = activityListState
  const { isExternalUser, impersonateUser } = usePermissionManager()
  const parents = new Map<number, Activity>()
  const childrenByParent = new Map<Activity, Activity[]>()
  const isInSetupPhase =
    (engagement && engagement.phase && engagement.phase === PhaseCode.Setup) ||
    false

  for (const activity of activities) {
    if (activity.parentId) {
      const parent = parents.get(activity.parentId)
      const childActivities = parent && childrenByParent.get(parent)
      if (childActivities) {
        childActivities.push(activity)
      }
    } else {
      parents.set(activity.id, activity)
      childrenByParent.set(activity, [])
    }
  }

  const groups: React.ReactNode[] = []
  childrenByParent.forEach((children, parent) => {
    const isSelectedActivity =
      selectedActivityId === parent.id ||
      !!children.find(c => c.id === selectedActivityId)
    const setRef = isSelectedActivity ? setSelectedActivityGroup : undefined

    groups.push(
      <ActivityListGroup
        childActivities={children}
        deleteActivity={deleteActivity}
        key={parent.id}
        parentActivity={parent}
        innerRef={setRef}
        isExternal={isExternalUser || impersonateUser}
        saveActivity={saveActivity}
        selected={isSelectedActivity}
        user={user}
        hasCarryAllCommentsForward={carryForwardAllId !== null}
        isInSetupPhase={isInSetupPhase}
      />
    )
  })

  let act: Activity | undefined
  if (activities && activities.length > 0) {
    // just care about the content of any activity
    act = activities[0]
  }

  const helpClassName = classNames('activity-list-help', {
    internal: !isExternalUser,
  })

  return (
    <div className='activity-list' ref={setContainer}>
      {hasComments && act && act.questionId && (
        <div className='activity-list-header'>
          <InternalContent>
            <ActivityMarkAllCarryForward
              engagementId={act.engagementId}
              clientId={act.clientId}
              questionId={act.questionId}
              carryForwardAllId={carryForwardAllId || undefined}
              saveActivity={saveActivity}
              deleteActivity={deleteActivity}
            />
          </InternalContent>
        </div>
      )}
      <div className='activity-list-header'>
        <div className='activity-list-header-label'>
          {question && (
            <div className='activity-list-header-label-number'>
              ({question.displayNumber}){' '}
            </div>
          )}
          Recent Activity
        </div>
      </div>
      {groups.length > 0 && <div className='activity-list-items'>{groups}</div>}
      {groups.length === 0 &&
        (questionDisabled ? (
          <div className={helpClassName}>
            <Icon icon='lock' />
            Engagement locked <br />
            <br />
            Comments &amp; documents cannot be modified.
          </div>
        ) : (
          <div className={helpClassName}>
            You can add comments &amp;
            <Icon icon='downArrow' /> attach documents in this space.
          </div>
        ))}
    </div>
  )
}
ActivityList.defaultProps = {
  autoScroll: true,
}

function parentActivityCount(activities: Activity[]) {
  let count = 0
  for (const activity of activities) {
    if (!activity.parentId) {
      count++
    }
  }
  return count
}

function getScrollParent(node?: Element | null): Element | null {
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode as Element)
  }
}

function usePrevious(value: any) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

import React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { Theme } from '@rsmus/react-ui'
import { capitalize } from 'lodash'

function styles({ palette, transitions, typogrphaphy }: Theme) {
  return {
    root: {
      fontFamily: 'Prelo-Book',
      color: palette.blue.main,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: '3rem',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export default function NothingToShow() {
  const theme = useTheme()
  const classes = useStyles(theme)
  const carryForward = 'Carryforward'
  const url = window.location.pathname
  var pageName = capitalize(url.substring(url.lastIndexOf('/') + 1))

  if (pageName === carryForward) {
    pageName = 'Carry Forward'
  }

  return (
    <h1 className={classes.root}>{`Select Engagement to View ${pageName}`}</h1>
  )
}

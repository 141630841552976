import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { usePermissionManager } from './../../hooks'
import { UserPermissions } from '../../enums'

// tslint:disable:jsx-no-lambda

interface MappedProps {
  permission: UserPermissions
}

type InternalRouteProps = RouteProps & MappedProps

const InternalRoute = (props: InternalRouteProps) => {
  const { component, render, children, permission, ...rest } = props
  const { hasPermission } = usePermissionManager()

  return (
    <Route
      {...rest}
      render={props => {
        if (!hasPermission(permission)) {
          return <Redirect to={'/'} />
        }
        if (component) {
          const Component = component
          return <Component {...props} />
        }
        if (render) {
          return render(props)
        }
        return children
      }}
    />
  )
}

export default InternalRoute

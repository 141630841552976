import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { createSelector } from 'reselect'
import { actions } from '../../actions'
import {
  AsyncOperationMap,
  EngagementReportRequestResponse,
  EngagementReportType,
} from '../../clientModels'
import { ensureNumber } from '../../guards'
import { selectEngagementFileGroups } from '../../reducers/selectors'
import { Abort } from '../../services/abort'
import { Guid } from '../../services/guid'
import { getNewAbort, RequestAbort } from '../../services/lastRequests'
import { AppState, TsaDispatch } from '../../store'
import Icon from '../icon/icon'
import InternalContent from '../internal/internalContent'
import { joinPaths } from '../relativeLink'
import {
  filterByAlreadyUploaded,
  filterByNeedUpload,
} from './docLibraryFilters'
import DocLibraryItem, { DocLibraryEntry } from './docLibraryItem'
import {
  sortByNotRequired,
  sortByQuestionDisplayOrder,
  sortByRequired,
} from './docLibrarySorting'
import { docLibraryEntryKey, fileGroupIsEmpty } from './docLibraryUtilities'
import { ExportDialog } from '../modals'

interface RouteParams {
  engagementId: string
  questionId?: string
  fileGroupId?: string
  documentTitleId?: string
}

interface DocLibraryOwnProps extends RouteComponentProps<RouteParams> {}

interface DocLibraryMappedProps {
  asyncOperations: AsyncOperationMap
  entries: DocLibraryEntry[]
  selectedDocumentFilter?: string | number
  selectedDocumentSort?: string | number
}

interface DocLibraryDispatchProps {
  addWatch: (asyncOperationId: number) => void
  documentArchiveDownload: (engagementId: number, id?: string) => void
  removeWatch: (asyncOperationId: number) => void
  requestReport: (
    engagementId: number,
    reportType: EngagementReportType,
    token: string,
    abort: Abort
  ) => Promise<EngagementReportRequestResponse>
}

type DocLibraryProps = DocLibraryOwnProps &
  DocLibraryMappedProps &
  DocLibraryDispatchProps

const selectDocumentTitles = (state: AppState) => state.documentTitles

const selectEngagementQuestionsMap = (
  state: AppState,
  props: DocLibraryOwnProps
) => state.engagementQuestions[props.match.params.engagementId]

const selectQuestions = (state: AppState) => state.questions

const makeSelectFileGroups = () =>
  createSelector(
    (state: AppState) => state.fileGroups,
    (_: any, props: DocLibraryOwnProps) => props.match.params.engagementId,
    selectEngagementFileGroups
  )

const makeSelectDocLibraryEntries = () => {
  const selectFileGroups = makeSelectFileGroups()
  return createSelector(
    selectEngagementQuestionsMap,
    selectFileGroups,
    selectDocumentTitles,
    selectQuestions,
    (engagementQuestions, fileGroupMap, documentTitles, questions) => {
      if (!engagementQuestions || !fileGroupMap) {
        return []
      }

      const entries: DocLibraryEntry[] = []
      for (const questionId in engagementQuestions) {
        const engagementQuestion = engagementQuestions[questionId]
        const fileGroups = fileGroupMap[questionId] || []
        const question = questions[questionId]

        if (!engagementQuestion || !question) {
          continue
        }

        for (const fileGroup of fileGroups) {
          // Only provide a document title if the file is required by the business rules.
          const isRequired = fileGroup.documentTitleId
            ? engagementQuestion.requiredDocumentTitleIds.includes(
                fileGroup.documentTitleId
              ) ||
              engagementQuestion.optionalDocumentTitleIds.includes(
                fileGroup.documentTitleId
              )
            : false

          if (fileGroup.notApplicable && !isRequired) {
            // Don't add a placeholder (N/A) file group to the document library unless
            // the file is required by the business rules.
            continue
          }

          const documentTitle = isRequired
            ? documentTitles.find(d => d.id === fileGroup.documentTitleId)
            : undefined

          // Add an entry for each file group that exists
          entries.push({
            documentTitle,
            fileGroup,
            question,
            allowNotApplicable: false,
          })
        }

        const missingDocTitleIds = engagementQuestion.requiredDocumentTitleIds.filter(
          id => !fileGroups.some(g => g.documentTitleId === id)
        )
        // Add an entry for each required document that is missing

        for (const documentTitleId of missingDocTitleIds) {
          entries.push({
            documentTitle: documentTitles.find(d => d.id === documentTitleId),
            question,
            allowNotApplicable: false,
          })
        }

        const missingOptionalDocTitleIds = engagementQuestion.optionalDocumentTitleIds.filter(
          id => !fileGroups.some(g => g.documentTitleId === id)
        )

        for (const documentTitleId of missingOptionalDocTitleIds) {
          const documentTitle = documentTitles.find(
            d => d.id === documentTitleId
          )
          entries.push({
            documentTitle: documentTitle && {
              ...documentTitle,
              optional: true,
            },
            question,
            allowNotApplicable: true,
          })
        }
      }

      return entries
    }
  )
}

const makeMapStateToProps = () => {
  const selectDocLibraryEntries = makeSelectDocLibraryEntries()
  return (
    state: AppState,
    ownProps: DocLibraryOwnProps
  ): DocLibraryMappedProps => {
    const { async } = state
    const { selectedDocumentSort, selectedDocumentFilter } = state.selection
    return {
      asyncOperations: async.operations,
      entries: selectDocLibraryEntries(state, ownProps),
      selectedDocumentFilter,
      selectedDocumentSort,
    }
  }
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: DocLibraryOwnProps
): DocLibraryDispatchProps => {
  return {
    addWatch: asyncOperationId =>
      dispatch(actions.async.addWatch(asyncOperationId)),
    documentArchiveDownload: (engagementId, id?) =>
      dispatch(actions.engagement.documentArchiveDownload(engagementId, id)),
    removeWatch: asyncOperationId =>
      dispatch(actions.async.removeWatch(asyncOperationId)),
    requestReport: (engagementId, reportType, token, abort) =>
      dispatch(
        actions.engagementReport.requestReport(
          ensureNumber(engagementId),
          reportType,
          token,
          abort
        )
      ),
  }
}

interface DocLibraryState {
  asyncOperationId?: number
  downloadInProgress: boolean
  openItems: number
  requestAbort: RequestAbort
  showRequestDialog: boolean
  token?: string
}

class DocLibrary extends React.Component<DocLibraryProps, DocLibraryState> {
  state: DocLibraryState = {
    downloadInProgress: false,
    openItems: 0,
    requestAbort: getNewAbort(),
    showRequestDialog: false,
  }

  hasDocuments() {
    const { entries } = this.props
    const entry = entries.find(x =>
      x.fileGroup && x.fileGroup.files ? x.fileGroup.files.length > 0 : false
    )
    return !!entry
  }

  render() {
    const {
      entries,
      match,
      selectedDocumentFilter,
      selectedDocumentSort,
    } = this.props
    const { afterClose } = this

    const asyncOperation = this.getAsyncOperation()

    let internalEntries

    switch (selectedDocumentFilter) {
      case 'NeedUpload':
        internalEntries = entries.filter(filterByNeedUpload)
        break
      case 'AlreadyUploaded':
        internalEntries = entries.filter(filterByAlreadyUploaded)
        break
      default:
        internalEntries = entries
    }

    switch (selectedDocumentSort) {
      case 'RequiredDocs':
        internalEntries = internalEntries.sort(sortByRequired)
        break
      case 'NonRequiredDocs':
        internalEntries = internalEntries.sort(sortByNotRequired)
        break
      default:
        internalEntries = internalEntries.sort(sortByQuestionDisplayOrder)
    }

    return (
      <div className='document-library'>
        <div className='document-library-header'>
          <div className='summary'>
            Based on the information you've supplied we've compiled an initial
            document task list.
          </div>
          <div className='info'>
            You can add these documents now or complete later.
          </div>
        </div>
        <InternalContent>
          {this.hasDocuments() && (
            <div className='document-library-download-all'>
              <button
                className='btn btn-primary btn-save'
                onClick={this.handleDownloadButtonClick}
                disabled={this.state.downloadInProgress}
              >
                Export All Documents
              </button>
            </div>
          )}
        </InternalContent>
        <div className='document-library-legend'>
          <Icon className='required-document-need' icon='asterisk' />/
          <Icon className='required-document-no-need' icon='asterisk' /> =
          Required Document
        </div>
        {internalEntries.map(e => (
          <DocLibraryItem
            {...e}
            engagementId={match.params.engagementId}
            key={docLibraryEntryKey(e)}
            onClose={this.handleItemClose}
            onOpen={this.handleItemOpen}
            onSelectItem={this.handleSelectItem}
            openPath={this.itemPath(e)}
            afterClose={afterClose}
          />
        ))}
        {this.state.showRequestDialog && (
          <ExportDialog
            asyncComplete={
              !!asyncOperation && asyncOperation.status === 'Complete'
            }
            isError={!!asyncOperation && asyncOperation.status === 'Errored'}
            loadingTitle={`Preparing Documents`}
            onClose={this.closeModal}
            onExport={this.handleArchiveDownload}
            readyTitle={`Documents Ready for Export`}
            errorTitle={`Error`}
          />
        )}
      </div>
    )
  }

  private getAsyncOperation = () => {
    const {
      props: { asyncOperations },
      state: { asyncOperationId },
    } = this
    return asyncOperationId ? asyncOperations[asyncOperationId] : undefined
  }

  private closeModal = () => {
    const {
      props: { removeWatch },
      state: { asyncOperationId, requestAbort },
    } = this
    if (asyncOperationId) {
      // Stop polling for status updates on current async operation
      removeWatch(asyncOperationId)
    } else {
      // Abort request for report -- this reduces the amount of error dialogs the user
      // will see if cancelling out of the download during a long-running request
      requestAbort.abort()
    }

    this.setState({
      asyncOperationId: undefined,
      showRequestDialog: false,
      token: undefined,
    })
  }

  private handleRequestResponse = (
    response: EngagementReportRequestResponse
  ) => {
    const {
      props: { addWatch },
      state: { token },
    } = this
    if (token === response.token) {
      if (response.asyncOperationId) {
        // Start polling for status updates on current async operation
        addWatch(response.asyncOperationId)
        this.setState({
          asyncOperationId: response.asyncOperationId,
          token: undefined,
        })
      } else {
        this.closeModal()
      }
    }
  }

  private requestReportByType = (reportType: EngagementReportType) => {
    const {
      props: { requestReport },
      state: { requestAbort },
    } = this
    const newToken = Guid.newGuid()
    const { engagementId } = this.props.match.params

    requestReport(
      ensureNumber(engagementId),
      reportType,
      newToken,
      requestAbort.new()
    ).then(this.handleRequestResponse)

    this.setState({
      asyncOperationId: undefined,
      showRequestDialog: true,
      token: newToken,
    })
  }

  private handleDownloadButtonClick = async () => {
    this.setState({ downloadInProgress: true, showRequestDialog: true })
    this.requestReportByType('engagementData')
  }

  private handleArchiveDownload = async () => {
    const { engagementId } = this.props.match.params
    const { documentArchiveDownload } = this.props
    this.setState({ showRequestDialog: false })
    await documentArchiveDownload(ensureNumber(engagementId))
    this.setState({ downloadInProgress: false })
  }

  private handleItemOpen = () => this.setState(this.addOpenItem)

  private handleItemClose = () => {
    setTimeout(() => {
      this.setState(this.removeOpenItem, this.afterClose)
    }, 0)
  }

  private addOpenItem = ({ openItems }: DocLibraryState) => {
    return { openItems: openItems + 1 }
  }

  private removeOpenItem = ({ openItems }: DocLibraryState) => {
    return { openItems: openItems > 0 ? openItems - 1 : 0 }
  }

  private afterClose = () => {
    const {
      state: { openItems },
      props: { history, match },
    } = this
    if (openItems === 0) {
      history.replace(
        joinPaths(
          match.url,
          `engagements/${match.params.engagementId}/documents`
        )
      )
    }
  }

  private handleSelectItem = (e: DocLibraryEntry, short: boolean) => {
    const { history } = this.props
    const nextPath = this.itemPath(e, short)
    history.replace(nextPath)
  }

  private itemPath = (e: DocLibraryEntry, short?: boolean) => {
    const { question, documentTitle, fileGroup } = e
    const { match } = this.props
    let path = joinPaths(match.url, `documents/questions/${question.id}`)
    if (short) {
      return path
    }
    if (fileGroup && !fileGroupIsEmpty(fileGroup)) {
      path += `/filegroup/${fileGroup.id}`
    } else if (documentTitle) {
      path += `/documenttitle/${documentTitle.id}`
    }
    return path
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(DocLibrary)

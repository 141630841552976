import classNames from 'classnames'
import React, { useState } from 'react'
import { Activity, DocumentTitle, FileGroup } from '../../clientModels'
import * as ActivityFactory from '../../services/activityFactory'
import Icon, { IconClickHandler } from '../icon/icon'
import { WarningDialog } from '../modals/index'
import { NotApplicableCheckBox } from '../notApplicableCheckBox'
import { History } from 'history'
import { Anchor } from '../anchor'
import { latestFilesFromFileGroup } from '../../services/fileHelpers'
import { DocumentTitleId, UserPermissions } from '../../enums'
import { usePermissionManager } from '../../hooks'

interface FileSummaryHeaderProps {
  allowNotApplicable: boolean
  disabled?: boolean
  documentTitle?: DocumentTitle
  engagementId: number
  questionId: string | number
  fileGroup?: FileGroup
  history: History
  onDelete?: (fileGroupId: number) => void
  onDownloadFile?: (fileId: number) => void
  onToggleNA: (documentTitle: DocumentTitle, fileGroupId?: number) => void
  openFileUpload: (
    engagementId: string | number,
    questionId: string | number,
    documentTitle?: DocumentTitle,
    history?: History,
    fileGroup?: FileGroup
  ) => void
  saveActivity: (activity: Activity) => void
}

const FileSummaryHeader = (props: FileSummaryHeaderProps) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const { hasPermission } = usePermissionManager()

  const handleDeleteClick = () => {
    if (files.length <= 1) {
      deleteFileGroup()
    } else {
      setShowConfirmDelete(true)
    }
  }

  const handleEditClick: IconClickHandler = (name, e) => {
    handleOpenFile()
  }

  const handleUploadClick: IconClickHandler = (name, e) => {
    handleOpenFile()
  }

  const handleViewFilesClick = (e: React.MouseEvent) => {
    handleOpenFile()
  }

  const handleOpenFile = () => {
    const {
      documentTitle,
      engagementId,
      fileGroup,
      history,
      openFileUpload,
      questionId
    } = props
    openFileUpload(engagementId, questionId, documentTitle, history, fileGroup)
  }

  const handleCarryForwardClick = () => {
    const { saveActivity, fileGroup } = props
    if (fileGroup) {
      const activity = ActivityFactory.CreateCarryForwardFileGroupActivity(
        fileGroup,
        !fileGroup.carryForwardActivityId
      )
      saveActivity(activity)
    }
  }

  const onFileClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onDownloadFile } = props
    if (onDownloadFile) {
      const id = parseInt(e.currentTarget.dataset.fileId || '', 10)
      onDownloadFile(id)
    }
  }

  const deleteFileGroup = () => {
    const { fileGroup, onDelete } = props
    if (fileGroup && onDelete) {
      onDelete(fileGroup.id)
    }
    setShowConfirmDelete(false)
  }

  const files = latestFilesFromFileGroup(props.fileGroup)

  const handleNotApplicableChanged = () => {
    const { fileGroup, documentTitle, onToggleNA } = props
    if (documentTitle) {
      onToggleNA(documentTitle, fileGroup && fileGroup.id)
    }
  }

  const { allowNotApplicable, fileGroup, documentTitle, disabled } = props
  const fileCount = files.length
  const title = documentTitle
    ? documentTitle.title
    : fileGroup && fileGroup.title

  const isCarryForward =
    (files.length > 0 &&
      fileGroup &&
      fileGroup.carryForwardActivityId &&
      fileGroup.carryForwardActivityId !== null) ||
    false
  const isRequired = !!documentTitle && !documentTitle.optional
  const isNotApplicable = fileGroup && fileGroup.notApplicable
  const isInvalid = isRequired && !isNotApplicable && files.length === 0
  const isValid = isRequired && (isNotApplicable || files.length > 0)
  const canEditCarryForward = hasPermission(UserPermissions.CarryForwardCanEdit)

  return (
    <div
      className={classNames('summary-header', {
        'is-invalid': isInvalid,
        'is-valid': isValid,
        disabled
      })}
    >
      <div className='summary-header-text'>
        {documentTitle && !documentTitle.optional && (
          <Icon
            className={classNames('required-document', {
              'requires-attention': isInvalid
            })}
            icon='asterisk'
          />
        )}
        {title}
        <div className='summary-header-text-file-count'>
          {documentTitle &&
          (documentTitle.id === DocumentTitleId.K1Document ||
            documentTitle.id === DocumentTitleId.K1UpLoadDocument ||
            documentTitle.id === DocumentTitleId.EtlDocument) ? (
            <Anchor onClick={handleViewFilesClick}>
              {`${fileCount} documents imported (view)`}
            </Anchor>
          ) : (
            `${fileCount} files uploaded`
          )}
        </div>
        {files.length > 0 && (
          <div className='summary-header-files'>
            {files.map(f => (
              <div className='summary-header-files-file' key={f.id}>
                {f.status === 'Uploaded' ||
                f.status === 'Complete' ||
                f.status === undefined ? (
                  <button
                    key={f.id}
                    id={`summary-files-${f.id}`}
                    className='file-summary-link'
                    data-file-id={f.id}
                    onClick={onFileClick}
                    title={f.name}
                  >
                    {f.name}
                  </button>
                ) : (
                  <span key={f.id} id={`summary-files-${f.id}`}>
                    {f.name + ` (${f.status})`}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {files.length === 0 && documentTitle && allowNotApplicable && (
        <div>
          <NotApplicableCheckBox
            mode='file'
            checked={fileGroup && fileGroup.notApplicable}
            onChange={handleNotApplicableChanged}
          />
        </div>
      )}
      {files.length > 0 && fileGroup && !disabled && (
        <div className='summary-header-icons'>
          {canEditCarryForward && (
            <Icon
              active={isCarryForward}
              icon='carryForward'
              onClick={handleCarryForwardClick}
              tooltip={
                isCarryForward
                  ? 'Item(s) marked as carry forward'
                  : 'Mark item(s) as carry forward'
              }
            />
          )}
          <Icon
            active={!isCarryForward}
            icon='delete'
            onClick={!isCarryForward ? handleDeleteClick : undefined}
            tooltip={
              isCarryForward && canEditCarryForward
                ? 'You cannot delete a document that has been carried forward'
                : isCarryForward
                ? 'Remove document(s) disabled'
                : 'Remove document(s)'
            }
          />
          <Icon
            active={!isCarryForward}
            onClick={!isCarryForward ? handleEditClick : undefined}
            icon='edit'
            tooltip={
              isCarryForward && canEditCarryForward
                ? 'You cannot modify a document that has been carried forward'
                : isCarryForward
                ? 'Manage document(s) disabled'
                : 'Manage document(s)'
            }
          />
        </div>
      )}
      {!files.length && fileGroup && !fileGroup.notApplicable && !disabled && (
        <div className='summary-header-icons'>
          <Icon
            icon='delete'
            onClick={handleDeleteClick}
            tooltip='Remove document(s)'
          />
          <Icon
            icon='upload'
            onClick={handleUploadClick}
            tooltip='Upload document(s)'
          />
        </div>
      )}
      {!files.length && !fileGroup && !disabled && (
        <div className='summary-header-icons'>
          <Icon
            icon='upload'
            onClick={handleUploadClick}
            tooltip='Upload document(s)'
          />
        </div>
      )}
      {showConfirmDelete && (
        <WarningDialog
          title='Confirm Delete'
          info='This will delete multiple files. Are you sure? If you want to remove individual files, please select edit.'
          onClickPrimary={deleteFileGroup}
          onClickSecondary={() => setShowConfirmDelete(false)}
          onClose={() => setShowConfirmDelete(false)}
        />
      )}
    </div>
  )
}

export default FileSummaryHeader

import { ActionType, createAction } from 'typesafe-actions'
import {
  EngagementTemplateFieldMap,
  EngagementTemplateMap,
  IdMap,
  OptionListMap,
  PhaseMap,
  QuestionMap,
  SectionMap,
} from '../clientModels'
import { DocumentTitle } from '../services/api/apiModels'

export const engagementTemplateBeginAction = createAction('GET_ETDOC_BEGIN')<{
  taxForm: string
  taxYear: number
}>()

export const engagementTemplateCacheHitAction = createAction(
  'GET_TEMPLATE_CACHE_HIT'
)<{}>()

export const engagementTemplatesGetAction = createAction(
  'GET_TEMPLATES_BEGIN'
)<{}>()

export const engagementTemplateDoneAction = createAction('GET_TEMPLATES_DONE')<{
  templates: EngagementTemplateMap
}>()

export const engagementTemplateFailAction = createAction('GET_TEMPLATES_FAIL')<{
  payload: Error
  error: true
}>()

export interface EngagementTemplateDocEndActionPayload {
  documentTitles: DocumentTitle[]
  fields: EngagementTemplateFieldMap
  optionsLists: OptionListMap
  /** Lookup map of options list ID by code list ID. */
  optionsListLookup: IdMap
  phases: PhaseMap
  questions: QuestionMap
  sections: SectionMap
  taxForm: string
  taxYear: number
  templates: EngagementTemplateMap
}

export const engagementTemplatesDocEndAction = createAction('GET_ETDOC_END')<{
  payload: EngagementTemplateDocEndActionPayload
}>()

export const engagementTemplatesDocErrorAction = createAction(
  'GET_ETDOC_ERROR'
)<{
  taxForm: string
  taxYear: number
  error: Error
}>()

export type EngagementTemplateActions =
  | ActionType<typeof engagementTemplateCacheHitAction>
  | ActionType<typeof engagementTemplateBeginAction>
  | ActionType<typeof engagementTemplatesDocEndAction>
  | ActionType<typeof engagementTemplatesDocErrorAction>
  | ActionType<typeof engagementTemplatesGetAction>
  | ActionType<typeof engagementTemplateDoneAction>
  | ActionType<typeof engagementTemplateFailAction>

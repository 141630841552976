import { SupportLink } from './AccountMenu'

/**
 * A utility function for AccountMenu that filters the support links available to the user based on that user's claims.
 * It was determined that not all support links should be presented to users that do not have claims of 'RSMAdmin' or 'RSMUser'
 * Those users without the claims above are not presented with a link to the "What's New" page.

 * @param claims
 * @param supportLinks
 */
const filterSupportLinks = (claims: string[], supportLinks: SupportLink[]) => {
  const claimsWithUnfilteredSupportLinks = ['RSMAdmin', 'RSMUser']
  let shouldFilterSupportLinks = true
  for (const claim of claimsWithUnfilteredSupportLinks) {
    if (claims.indexOf(claim) > -1) {
      shouldFilterSupportLinks = false
      break
    }
  }
  if (shouldFilterSupportLinks) {
      return supportLinks.slice(0, supportLinks.length - 1)
  } else return supportLinks
}

export default filterSupportLinks

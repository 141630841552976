import { createReducer } from 'typesafe-actions'
import { getAllActivitiesAction } from '../actions/activityActions'
import { getEngagementAction } from '../actions/engagementActions'
import * as inputProcessActions from '../actions/inputProcessActions'
import * as actions from '../actions/fileActions'
import { TsaHubAction } from '../actions/index'
import { normalizeFileGroups } from '../actions/normalization'
import { Activity, EntityMap, FileGroup } from '../clientModels'

export const fileGroupsReducer = createReducer<
  EntityMap<FileGroup>,
  TsaHubAction
>({})
  .handleAction(
    [
      actions.getFileGroupsForEntityGroup.success,
      getEngagementAction.success,
      actions.getFileGroupsForEngagementActions.success,
    ],
    (state, action) => {
      const { fileGroups } = action.payload
      const map = normalizeFileGroups(fileGroups) || {}
      return {
        ...state,
        ...map,
      }
    }
  )
  .handleAction(actions.deleteFileGroup.success, (state, action) => {
    const { fileGroupId } = action.payload
    if (!state[fileGroupId]) return state
    state = { ...state }
    delete state[fileGroupId]
    return state
  })
  .handleAction(actions.addUpdateFileGroup.success, (state, action) => {
    const { group, replaceId } = action.payload
    state = { ...state, [group.id]: group }
    if (replaceId) {
      delete state[replaceId]
    }
    return state
  })
  .handleAction(
    [
      actions.updateFile.success,
      inputProcessActions.updateK1FileProgressAction.success,
      inputProcessActions.completeK1FileProgressActions.success,
    ],
    (state, action) => {
      const file = action.payload
      if (!file.fileGroupId) return state
      const fileGroup = state[file.fileGroupId]
      const existingFiles = fileGroup && fileGroup.files
      if (!fileGroup || !existingFiles) return state
      const updatedFiles = existingFiles.map(e => (file.id === e.id ? file : e))
      const newState = {
        ...state,
        [file.fileGroupId]: {
          ...fileGroup,
          files: updatedFiles,
        },
      }
      return newState
    }
  )
  .handleAction(actions.toggleTag.success, (state, action) => {
    const { fileGroupId, files } = action.payload
    const fileGroup = state[fileGroupId]
    const existingFiles = fileGroup && fileGroup.files
    if (!fileGroup || !existingFiles) return state
    const updatedFiles = existingFiles.map(
      e => files.find(f => f.id === e.id) || e
    )
    return {
      ...state,
      [fileGroupId]: {
        ...fileGroup,
        files: updatedFiles,
      },
    }
  })
  .handleAction(actions.submitFileToCchAction.success, (state, action) => {
    const file = action.payload
    const fileGroupId = file.fileGroupId
    if (fileGroupId) {
      const fileGroup = state[fileGroupId]
      const existingFiles = fileGroup && fileGroup.files
      if (!fileGroup || !existingFiles) return state
      const updatedFiles = existingFiles.map(e => (file.id === e.id ? file : e))
      return {
        ...state,
        [fileGroupId]: {
          ...fileGroup,
          files: updatedFiles,
        },
      }
    } else {
      return state
    }
  })
  .handleAction(actions.softDeleteFilesStatus.success, (state, action) => {
    const { fileGroupId, fileIds } = action.payload
    const fileGroup = state[fileGroupId]
    const files = fileGroup && fileGroup.files
    if (!fileGroup || !files) return state
    const filteredFiles = files.filter(f => !fileIds.includes(f.id))
    return {
      ...state,
      [fileGroupId]: {
        ...fileGroup,
        files: filteredFiles,
      },
    }
  })
  .handleAction(getAllActivitiesAction.success, (state, action) => {
    const { activities, user } = action.payload
    if (user && user.isExternal) {
      return state
    }

    const carryForwardActivities: Activity[] = []
    for (const questionId in activities) {
      const questionActivities = activities[questionId] || []
      for (const activity of questionActivities) {
        if (activity.activityTypeCode === 'CarryForward') {
          carryForwardActivities.push(activity)
        }
      }
    }

    if (carryForwardActivities.length === 0) {
      return state
    }

    carryForwardActivities.sort(sortByCreatedDate)
    state = { ...state }

    for (const activity of carryForwardActivities) {
      const fileGroup = state[activity.fileGroupId || 0]
      if (!fileGroup) continue

      state[fileGroup.id] = {
        ...fileGroup,
        carryForwardActivityId:
          activity.activitySubTypeCode === 'Add' ? activity.id : undefined,
      }
    }

    return state
  })

function sortByCreatedDate(a: Activity, b: Activity) {
  return a.createdDate.diff(b.createdDate)
}

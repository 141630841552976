import {
  K1FileStatus,
  Option,
  FileGroup,
  File,
  EntityMap,
  K1FileStatusMap,
} from '../clientModels'
import { groupBy } from 'lodash'
import {
  getEntityMapValues,
  getK1FileProgress,
} from '../components/configureImport/helpers'
import { EtlFileProgress } from './api/apiModels'
import {
  EtlFileStatus as K1Status,
  EtlDisplayStatus, FileStatus
} from '../enums'
import { ensureString, isDefinedNotNull } from '../guards'
import { ApiModels } from './api'

function sortByVersionId(a: File, b: File) {
  return b.versionId! - a.versionId!
}

type NewFileOldFile = [File[], File[]]

function filesFromFileGroup(fileGroup?: FileGroup): NewFileOldFile {
  const fileGroupFiles = (fileGroup && fileGroup.files) || []
  const sortedFiles = fileGroupFiles.sort(sortByVersionId)
  const [newFiles, existingFiles] = sortedFiles.reduce(
    (previousValue, currentFile) => {
      if (currentFile.versionId === undefined) {
        previousValue[0].push(currentFile)
      } else {
        previousValue[1].push(currentFile)
      }
      return previousValue
    },
    [[], []] as NewFileOldFile
  )
  const groupedFiles = groupBy(existingFiles, 'parentId')
  return [newFiles, Object.keys(groupedFiles).map(x => groupedFiles[x][0])]
}

export function latestFilesFromFileGroup(fileGroup: FileGroup | undefined) {
  const [newFiles, currentFiles] = filesFromFileGroup(fileGroup)
  return currentFiles.concat(newFiles)
}

export function latestFilesFromFileGroups(fileGroups: FileGroup[]) {
  const [newFiles, currentFiles] = fileGroups.reduce(
    (previousValue, fileGroup) => {
      const [nf, cf] = filesFromFileGroup(fileGroup)
      return [previousValue[0].concat(nf), previousValue[1].concat(cf)]
    },
    [[], []] as NewFileOldFile
  )
  return currentFiles.concat(newFiles)
}

// Remove all versions of the file
export function removeFile(files: File[], id: number) {
  files = [...files]
  const file = files.find(g => g.id === id)
  if (file) {
    return files.filter(x => x.parentId !== file.parentId)
  } else {
    return files
  }
}

export function defaultTagsbyDocumentTitle(documentTitle: number): string[] {
  switch (documentTitle) {
    case 59: // K-1 Documents
      return ['subject-to-7216']
  }
  return []
}

export function getFile(
  fileId: number,
  fileGroupMap: EntityMap<FileGroup>
): File | undefined {
  const fileGroups = getEntityMapValues(fileGroupMap)
  const files = latestFilesFromFileGroups(fileGroups)
  return files.find(f => f.id === fileId)
}

export function getDisplayStatus(
  uploadStatus?: string,
  etlProgress?: EtlFileProgress | null,
  k1FileStatus?: K1FileStatus
) {
  let displayStatus = uploadStatus

  if (
    [
      FileStatus.Uploaded.toString(),
      K1Status.Processing.toString()
    ].includes(uploadStatus || '') &&
    isDefinedNotNull<K1FileStatus>(k1FileStatus)
  ) {
    if (k1FileStatus?.status === K1Status.InProgressContinue) {
      switch (etlProgress && etlProgress.Step) {
        case 'categories':
          displayStatus = EtlDisplayStatus.TaxCodeMapping
          break
        case 'entities':
          displayStatus = EtlDisplayStatus.EntityMapping
          break
        case 'scan':
          displayStatus = EtlDisplayStatus.DataReady
          break
        default:
          displayStatus = k1FileStatus.status
      }
    } else {
      displayStatus = ensureString(k1FileStatus?.status)
    }
  } else {
    displayStatus = uploadStatus
  }

  return displayStatus || ''
}

export function getDisplayStatusMap(
  files: ApiModels.File[],
  k1FileStatus: K1FileStatusMap
) {
  const map: EntityMap<string> = {}
  for (const file of files) {
    const progress = getK1FileProgress(file)
    const k1Status = k1FileStatus[file.id]
    const upload = file.status
    map[file.id] = getDisplayStatus(upload, progress, k1Status)
  }
  return map
}

export const getWorksheetsFromFile = function (
  fileId: number,
  fileGroupMap: EntityMap<FileGroup>
): Option[] {
  const file = getFile(fileId, fileGroupMap)
  if (file && file.k1FileSheetDetails) {
    return file.k1FileSheetDetails.map((f: any) => {
      return {
        value: f.sheetName,
        label: f.sheetName,
      }
    })
  }
  return []
}

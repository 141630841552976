import React from 'react'
import { useRouteMatch } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { CarryForwardHeader } from '../carryForwardLibrary'
import { CommentHeader } from '../commentLibrary'
import { DocumentHeader } from '../documentLibrary'
import EngagementHeader from './engagementHeader'
import { EngagementReportsHeader } from '../engagementReports'
import FlagHeader from '../flagLibrary/flagHeader'
import InternalRoute from '../internal/internalRoute'
import CallToAction from '../callToAction/callToAction'
import DataFileManagementHeaderContainer from '../dataFileManagement/dataFileManagementHeaderContainer'
import { UserPermissions } from '../../enums'
import { ProxyBanner } from '../proxyBanner'

const EngagementHeaderRoutes = () => {
  const match = useRouteMatch()

  const undefinedRoutes = [
    `${match.path}/sections/:sectionId/edit`,
    `${match.path}/sections/:sectionId/questions/:questionId/edit`,
  ]

  const fileUploadAdvancedHeaderRoutes = [
    `${match.path}/documents/upload/questions/:questionId/filegroup/:fileGroupId/documenttitle/:documentTitleId?`,
    `${match.path}/documents/upload/filegroup/:fileGroupId/documenttitle/:documentTitleId?`,
    `${match.path}/documents/upload/filegroup/:fileGroupId`,
    `${match.path}/documents/upload/documenttitle/:documentTitleId?`,
    `${match.path}/documents/upload/questions/:questionId/documenttitle/:documentTitleId?`,
    `${match.path}/documents/upload/questions/:questionId/filegroup/:fileGroupId`,
    `${match.path}/documents/upload/questions/:questionId`,
    `${match.path}/documents/upload/`,
  ]

  const EngagementHeaderRoutes = [`${match.path}/sections/:sectionId`]

  return (
    <>
      <ProxyBanner />
      <CallToAction />
      <Switch>
        <Route path={undefinedRoutes} component={undefined} />
        <Route
          path={fileUploadAdvancedHeaderRoutes}
          component={DataFileManagementHeaderContainer}
        />

        <Route path={`${match.path}/documents`} component={DocumentHeader} />
        <Route path={`${match.path}/flags`} component={FlagHeader} />
        <Route path={`${match.path}/comments`} component={CommentHeader} />
        <InternalRoute
          path={`${match.path}/carryForward`}
          component={CarryForwardHeader}
          permission={UserPermissions.CarryForwardCanView}
        />
        <Route
          path={`${match.path}/reports`}
          component={EngagementReportsHeader}
        />
        <Route path={EngagementHeaderRoutes} component={EngagementHeader} />
      </Switch>
    </>
  )
}

export default EngagementHeaderRoutes

import * as React from 'react'
import { IconProperties } from './properties'

const None: React.SFC<IconProperties> = ({ className, onClick }) => {
  return (
    <div
      className={className}
      style={{ maxHeight: '2.25rem' }}
      onClick={onClick}
    />
  )
}

export default None

import { JsonRule } from 'json-rules-engine'
import { ensureNumber } from '../guards'
import { isEngagementTemplateLoaded } from '../reducers/selectors'
import { setAnswerSchemas } from '../services/answerSchema/index'
import { TaxFormContentApi } from '../services/api'
import {
  EngagementTemplate,
  Question,
  Section,
} from '../services/api/apiModels'
import { perf } from '../services/performance'
import { ruleProcessStrategy } from '../services/rules/utilities'
import { trackGet } from '../services/track'
import { TsaThunkAction } from '../store'
import * as actions from './engagementTemplateActions'
import {
  createCodeListIds,
  createOptionsMap,
  normalizeEngagementTemplate,
  normalizeEngagementTemplateFieldArray,
  normalizePhasesArray,
} from './normalization'
import { loadEngagementTemplateRules } from './rulesThunks'

const perfEngagementTemplate = perf('EngagementTemplate (Single)', true)

/**
 * Get an engagement template document.
 */
export const getEngagementTemplateDocument = (
  taxForm: string = '',
  taxYear?: string | number
): TsaThunkAction => async (dispatch, getState) => {
  taxYear = ensureNumber(taxYear)

  if (isEngagementTemplateLoaded(getState(), taxForm, taxYear)) {
    dispatch(actions.engagementTemplateCacheHitAction({}))
    return
  }

  const perfId = `${taxForm}_${taxYear}`
  perfEngagementTemplate.beginMark(perfId)

  try {
    dispatch(actions.engagementTemplateBeginAction({ taxForm, taxYear }))

    const {
      documentTitles,
      fields,
      optionsGroups,
      phases,
      questions,
      rules,
      sections,
      template,
    } = await trackGet(
      TaxFormContentApi.getEngagementTemplateDoc,
      dispatch,
      undefined,
      taxForm,
      taxYear
    )
    //----------------- check if glossary file is in template.
    const
      hasGlossary
        = await trackGet(
          TaxFormContentApi.checkIfFileExist,
          dispatch,
          undefined,
          taxForm,
          taxYear, 'Glossary.pdf'
        )

    template.hasGlossary = hasGlossary as boolean
    const processedRules: JsonRule[] = []

    for (const rule of rules) {
      processedRules.push(ruleProcessStrategy(rule))
    }

    const payload: actions.EngagementTemplateDocEndActionPayload = {
      ...processTemplate(template, questions, sections),
      documentTitles,
      fields: normalizeEngagementTemplateFieldArray(fields),
      optionsListLookup: createCodeListIds(optionsGroups),
      optionsLists: createOptionsMap(optionsGroups),
      phases: normalizePhasesArray(phases),
      taxForm,
      taxYear,
    }

    // Generate answer schemas for each question
    setAnswerSchemas(payload.questions, fields)

    // Load the business rules
    loadEngagementTemplateRules(
      template.id,
      processedRules,
      payload.questions,
      fields
    )

    dispatch(actions.engagementTemplatesDocEndAction({ payload }))
    perfEngagementTemplate.endMark(perfId)
  } catch (error) {
    dispatch(
      actions.engagementTemplatesDocErrorAction({ taxForm, taxYear, error })
    )
    perfEngagementTemplate.endMark(perfId)
    throw error
  }
}

const processTemplate = (
  template: EngagementTemplate,
  questions: Question[],
  sections: Section[]
) => {
  // Rearrange the template a little bit to make it backwards
  // compatible with the original shape of the data from the API
  template.questions = questions
  template.sections = sections
  const results = normalizeEngagementTemplate(template)
  return results
}

import { getType } from 'typesafe-actions'
import * as engagementActions from '../actions/engagementActions'
import { TsaHubAction } from '../actions/index'
import {
  reviewDoneAction,
  reviewQuestionAction,
} from '../actions/reviewActions'
import { EngagementMap } from '../clientModels'
import { ensureNumber } from '../guards'
import {
  updatedReviewStarted,
  updateReviewStarted,
} from '../services/questionReviewUtilities'
import { setEngagementState } from './engagementsReducerHelpers'
import { ExtError } from '../services/error'

const initialState: EngagementMap = {}

export function engagementsReducer(
  state: EngagementMap = initialState,
  action: TsaHubAction
): EngagementMap {
  switch (action.type) {
    // TODO: This reducer directly mutates state and should be refactored in the future
    case getType(engagementActions.getEngagementListAction.request): {
      state = { ...state }
      for (const engagementId in state) {
        const engagement = state[engagementId]
        if (!engagement) {
          continue
        }
        engagement.visible = undefined
      }
      return state
    }

    case getType(engagementActions.getEngagementListAction.success):
      // This action returns ClientLight objects so we can't just overwrite entire client objects. We must merge each one.
      const newState = { ...state }
      Object.entries(action.payload.engagements).forEach(([id, e]) => {
        const key = ensureNumber(id)
        return (newState[key] = Object.assign({}, newState[key], e, {
          visible: true,
        }))
      })
      return { ...newState, listError: undefined }

    case getType(engagementActions.getEngagementListAction.failure):
      const error = action.payload as ExtError
      if (error?.silent) {
        // If engagement list load was aborted early, no need to display error to user
        return state
      }
      return { ...state, listError: action.payload }

    case getType(engagementActions.getEngagementAction.request): {
      const engagementId = action.payload
      const engagement = state[engagementId]
      if (engagement) {
        return {
          ...state,
          [engagementId]: { ...engagement, loaded: false },
        }
      }
      return state
    }

    case getType(engagementActions.getEngagementFinishedLoadingAction): {
      const { engagementId } = action.payload
      const engagement = state[engagementId]
      if (engagement) {
        return {
          ...state,
          [engagementId]: { ...engagement, loaded: true },
        }
      }
      return state
    }

    // Removed cases ActionType.UPDATE_ENGAGEMENT_PHASE_DONE and ActionType.REVIEW_DONE because PUT response is not consistent with GET response
    // Moved cases ActionType.SET_ENGAGEMENT_CRITICAL_DONE and ActionType.SET_ENGAGEMENT_NOT_CRITICAL_DONE into their own function so only relevant properties are updated.
    case getType(engagementActions.getEngagementAction.success):
    case getType(engagementActions.updateEngagementMilestoneAction.success): {
      const { engagements } = action.payload
      for (const id in engagements) {
        let engagement = state[id]
        let newEngagement = engagements[id]
        engagement = setEngagementState(engagement, newEngagement)
        state = { ...state, [id]: engagement }
      }
      return state
    }

    case getType(engagementActions.setEngagementCriticalAction.success):
    case getType(engagementActions.setEngagementNotCriticalAction.success): {
      const { engagements } = action.payload
      for (const id in engagements) {
        let engagement = state[id]
        const updatedEngagement = engagements[id]
        if (engagement && updatedEngagement) {
          engagement = {
            ...engagement,
            criticalError: updatedEngagement.criticalError,
            lastUpdatedBy: updatedEngagement.lastUpdatedBy,
            lastUpdatedDate: updatedEngagement.lastUpdatedDate,
          }
        } else {
          // use the new engagement
          engagement = engagements[id]
        }
        state = { ...state, [id]: engagement }
      }
      return state
    }

    case getType(engagementActions.toggleFavoriteEngagementAction.request): {
      const { engagementId } = action.payload
      const engagement = state[ensureNumber(engagementId)]
      if (engagement) {
        return {
          ...state,
          [engagementId]: { ...engagement, favorited: !engagement.favorited },
        }
      }
      return state
    }

    case getType(engagementActions.toggleFavoriteEngagementAction.success): {
      const engagement = action.payload.engagement

      if (engagement) {
        const engagement2 = Object.assign({}, state[engagement.id], engagement)
        return { ...state, [engagement.id]: engagement2 }
      }
      break
    }

    case getType(engagementActions.toggleLockEngagementAction.request): {
      const { engagementId } = action.payload
      const engagement = state[ensureNumber(engagementId)]
      const updatedLockStatus =
        engagement && engagement.isEnabled !== undefined
          ? !engagement.isEnabled
          : false
      if (engagement) {
        state = {
          ...state,
          [engagementId]: { ...engagement, isEnabled: updatedLockStatus },
        }
      }
      return state
    }

    case getType(engagementActions.copyLastYearAnswersAction.success): {
      const id = ensureNumber(action.payload.engagementId)
      const engagement = state[id]
      if (engagement) {
        state = {
          ...state,
          [id]: { ...engagement, usedLastYearsAnswers: true },
        }
      }
      return state
    }

    case getType(engagementActions.updateEngagementPhaseAction.request):
    case getType(engagementActions.updateEngagementPhaseRollbackAction): {
      const { engagementId, phase } = action.payload
      const engagement = state[engagementId]
      if (engagement) {
        return {
          ...state,
          [engagementId]: { ...engagement, phase },
        }
      }
      return state
    }

    case getType(
      engagementActions.updateEngagementQuestionStateSummaryAction.success
    ): {
      const { engagementId, engagementSummary } = action.payload
      const engagement = state[engagementId]

      if (engagement) {
        return {
          ...state,
          [engagementId]: { ...engagement, ...engagementSummary },
        }
      }
      return state
    }

    case getType(reviewDoneAction.request): {
      const { engagementId, reviewSummary } = action.payload
      const engagement = state[ensureNumber(engagementId)]

      if (engagement) {
        return { ...state, [engagementId]: { ...engagement, ...reviewSummary } }
      }

      return state
    }

    case getType(reviewQuestionAction): {
      const { engagementId, roles, action: reviewAction } = action.payload
      const engagement = state[ensureNumber(engagementId)]
      if (
        engagement &&
        reviewAction === 'mark' &&
        updatedReviewStarted(engagement, roles)
      ) {
        return {
          ...state,
          [engagementId]: { ...engagement, ...updateReviewStarted(roles) },
        }
      }

      return state
    }

    case getType(engagementActions.updateEngagementLastModifiedAction): {
      const { engagementId, lastUpdatedDate } = action.payload
      const engagement = state[ensureNumber(engagementId)]

      if (engagement) {
        return { ...state, [engagementId]: { ...engagement, lastUpdatedDate } }
      }
      break
    }

    case getType(engagementActions.startLoadAnswersEtlAction.success):
    case getType(engagementActions.duoExportAction.success): {
      const { engagementId, taxIntegrationStatus } = action.payload
      const engagement = state[ensureNumber(engagementId)]

      const taxIntegrationStatuses = [
        ...(engagement?.taxIntegrationStatuses || []),
        taxIntegrationStatus,
      ]

      if (engagement) {
        return {
          ...state,
          [engagementId]: {
            ...engagement,
            taxIntegrationStatuses,
          },
        }
      }
      break
    }

    default:
      break
  }
  return state
}

import * as React from 'react'
import { connect } from 'react-redux'
import { actions } from '../../actions'
import { TsaDispatch } from '../../store'

interface EngagementSidebarCollapserProps {
  collapse: () => void
  restore: () => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch
): EngagementSidebarCollapserProps => ({
  collapse: () =>
    dispatch(actions.selection.selectionSidebarCollapseAction({})),
  restore: () => dispatch(actions.selection.selectionSidebarRestoreAction({})),
})

class EngagementSidebarCollapser extends React.Component<
  EngagementSidebarCollapserProps
> {
  componentDidMount() {
    this.props.collapse()
  }

  componentWillUnmount() {
    this.props.restore()
  }

  shouldComponentUpdate = () => false
  render = () => null
}

export default connect(
  undefined,
  mapDispatchToProps
)(EngagementSidebarCollapser)

import classNames from 'classnames'
import React from 'react'
import { FileWithPath } from 'react-dropzone'
import { File, Message } from '../../clientModels'
import { Alerts } from '../forms'
import Icon from '../icon/icon'
import Modal from '../modals/modal'
import './fileUploadDialog.scss'
import { FileUploadDialogDropZone } from './fileUploadDialogDropZone'
import { FileUploadDialogFile } from './fileUploadDialogFile'
import {
  formatAcceptedFileTypes,
  formatNumber,
  getSizeLabel,
} from './fileUploadDialogHelpers'

export interface FileUploadDialogFirstPhaseProps {
  maxFileCount: number
  maxFileSize: number
  acceptedFileTypeExtensions?: string
  acceptedFileTypes?: string
  disabledUpload: boolean
  disabledButton: boolean
  files: File[]
  messages: Message[]
  replacing?: boolean
  handleAdd: () => void
  handleClose: () => void
  handleDelete: (deleted: File) => void
  handleUpload: (droppedFiles: FileWithPath[]) => void
}

export function FileUploadDialogFirstPhase ({
  acceptedFileTypes,
  acceptedFileTypeExtensions,
  disabledButton,
  disabledUpload,
  maxFileCount,
  maxFileSize,
  files,
  messages,
  handleAdd,
  handleClose,
  handleDelete,
  handleUpload,
  replacing = false,
}: FileUploadDialogFirstPhaseProps) {
  const buttonLabel = replacing ? 'Update Document' : 'Add Documents'
  const maxUploadFileCount = replacing ? 1 : maxFileCount

  return (
    <Modal>
      <div className='modal file-upload-dialog'>
        <div className='modal-body'>
          <div className='file-dialog-header'>
            <div className='file-dialog-icon'>
              <Icon icon={'fileAlt'} />
            </div>
            <div className='file-dialog-info'>
              <div>Max Files: {formatNumber(maxUploadFileCount)}</div>
              <div>
                File Size/Format: {getSizeLabel(maxFileSize)}/
                {formatAcceptedFileTypes(acceptedFileTypeExtensions)}
              </div>
            </div>
            <button
              id='modal-button-close'
              type='button'
              className='close'
              aria-label='Close'
              onClick={handleClose}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='file-dialog-drop'>
            <FileUploadDialogDropZone
              acceptedFileTypes={acceptedFileTypes}
              disabled={disabledUpload}
              onDrop={handleUpload}
            />
          </div>
          <div className='file-dialog-files'>
            <div className='file-dialog-files-container'>
              {files.map(file => (
                <FileUploadDialogFile
                  key={file.name}
                  file={file}
                  onDelete={handleDelete}
                />
              ))}
            </div>
            <Alerts messages={messages} />
          </div>
          <div className='file-dialog-action'>
            <button
              type='button'
              className={classNames('btn btn-block file-upload-btn', {
                disabled: disabledButton,
              })}
              disabled={disabledButton}
              onClick={handleAdd}
            >
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

import { Condition, JsonRule } from 'json-rules-engine'
import { EngagementTemplateField, FieldFormat } from '../../clientModels'
import { formatNumber } from '../formatters/formatters'
import { operators } from './operators'
import { rulePriorities } from './rulePriorities'

export function buildRules (fields: EngagementTemplateField[]) {
  const rules: JsonRule[] = []

  fields.forEach(f => {
    // Add a rule if there is a format to the field
    switch (f.format as FieldFormat) {
      case 'ein':
        rules.push(buildEinRule(f))
        break
      case 'einOrSsn':
        rules.push(buildEinOrSsnRule(f))
        break
      case 'number':
        rules.push(buildNumberRule(f))
        break
      case 'ssn':
        rules.push(buildSsnRule(f))
        break
      default:
        break
    }

    if (f.required) {
      rules.push(buildRequiredRule(f))
    }

    if (f.maxLength) {
      rules.push(buildMaxLengthRule(f))
    }

    if (f.minValue) {
      rules.push(buildMinValueRule(f))
    }

    if (f.minItems) {
      const rule = buildMinItemsRule(f)

      if (rule) {
        rules.push(rule)
      }
    }

    if (f.maxItems) {
      const rule = buildMaxItemsRule(f)

      if (rule) {
        rules.push(rule)
      }
    }

    if (f.component === 'date') {
      const rule = buildDateRule(f)

      if (rule) {
        rules.push(rule)
      }
    }
  })

  return rules
}

export function buildDateRule (
  field: EngagementTemplateField
): JsonRule | undefined {
  const { isPropertyOfArrayItem, path } = processSchemaPath(field.schemaPath)

  const message = field.title
    ? `${field.title} must be a valid date matching format: mm/dd/yyyy.`
    : 'Must be a valid date matching format: mm/dd/yyyy.'
  const conditions: Condition[] = getRowConditions(
    field.questionId,
    operators.validDate,
    isPropertyOfArrayItem,
    '',
    path
  )

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        message,
        messageType: 'validDate',
        path,
        questionId: field.questionId,
      },
    },
  }
}

export function buildMinItemsRule (
  field: EngagementTemplateField
): JsonRule | undefined {
  const { isPropertyOfArrayItem } = processSchemaPath(field.schemaPath)

  if (
    !!isPropertyOfArrayItem ||
    !field.minItems ||
    !(
      field.component === 'datasheet' || field.component === 'readonlydatasheet'
    ) ||
    field.dataType !== 'array'
  ) {
    return undefined
  }

  const conditions = getTableCondition(
    field.questionId,
    operators.greaterThanInclusive,
    field.minItems
  )

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        questionId: field.questionId,
        messageType: operators.greaterThanInclusive,
        message: `${field.title ? field.title : 'Grid'} must have at least ${
          field.minItems
        } row${field.minItems !== 1 ? 's' : ''}`,
      },
    },
  }
}

export function buildMaxItemsRule (
  field: EngagementTemplateField
): JsonRule | undefined {
  const { isPropertyOfArrayItem } = processSchemaPath(field.schemaPath)

  if (
    !!isPropertyOfArrayItem ||
    !field.maxItems ||
    !(
      field.component === 'datasheet' || field.component === 'readonlydatasheet'
    ) ||
    field.dataType !== 'array'
  ) {
    return undefined
  }

  const conditions = getTableCondition(
    field.questionId,
    operators.lessThanInclusive,
    field.maxItems
  )

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        questionId: field.questionId,
        messageType: operators.lessThanInclusive,
        message: `${field.title ? field.title : 'Grid'} must have at most ${
          field.maxItems
        } row${field.maxItems !== 1 ? 's' : ''}`,
      },
    },
  }
}

export function buildEinRule (field: EngagementTemplateField): JsonRule {
  const { isPropertyOfArrayItem, path } = processSchemaPath(field.schemaPath)

  const message = field.title
    ? `${field.title} must match the pattern NN-NNNNNNN.`
    : 'Must match the pattern NN-NNNNNNN'
  const conditions: Condition[] = getRowConditions(
    field.questionId,
    operators.matches,
    isPropertyOfArrayItem,
    '^\\d{9}$',
    path
  )

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        message,
        messageType: 'ein',
        path,
        questionId: field.questionId,
      },
    },
  }
}

export function buildEinOrSsnRule (field: EngagementTemplateField): JsonRule {
  const { isPropertyOfArrayItem, path } = processSchemaPath(field.schemaPath)

  const message = field.title
    ? `${field.title} must match the format NNNNNNNNN.`
    : 'Must match the pattern NNNNNNNNN'
  const conditions: Condition[] = getRowConditions(
    field.questionId,
    operators.matches,
    isPropertyOfArrayItem,
    '^\\d{9}$',
    path
  )

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        message,
        messageType: 'einOrSsn',
        path,
        questionId: field.questionId,
      },
    },
  }
}

export function buildNumberRule (field: EngagementTemplateField): JsonRule {
  const { isPropertyOfArrayItem, path } = processSchemaPath(field.schemaPath)

  const message = field.title
    ? `${field.title} is improperly formatted. Please use only digits`
    : 'Please use only digits'
  const conditions: Condition[] = getRowConditions(
    field.questionId,
    operators.number,
    isPropertyOfArrayItem,
    0,
    path
  )

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        message,
        messageType: operators.number,
        path,
        questionId: field.questionId,
      },
    },
  }
}

export function buildSsnRule (field: EngagementTemplateField): JsonRule {
  const { isPropertyOfArrayItem, path } = processSchemaPath(field.schemaPath)

  const message = field.title
    ? `${field.title} must match the format NNN-NN-NNNN.`
    : 'Must match the pattern NNN-NN-NNNN'
  const conditions: Condition[] = getRowConditions(
    field.questionId,
    operators.matches,
    isPropertyOfArrayItem,
    '^\\d{9}$',
    path
  )

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        questionId: field.questionId,
        path,
        messageType: 'ssn',
        message,
      },
    },
  }
}

export function buildRequiredRule (field: EngagementTemplateField): JsonRule {
  const { isPropertyOfArrayItem, path } = processSchemaPath(field.schemaPath)

  const message = field.title ? `${field.title} required` : 'Required'
  const conditions: Condition[] = getRowConditions(
    field.questionId,
    operators.required,
    isPropertyOfArrayItem,
    0,
    path,
    true
  )

  const notApplicable: Condition = {
    fact: 'notApplicable',
    operator: operators.equal,
    value: true,
    params: { questionId: field.questionId },
  }

  conditions.push(notApplicable)

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        questionId: field.questionId,
        path,
        messageType: operators.required,
        message,
      },
    },
  }
}

export function buildMaxLengthRule (field: EngagementTemplateField): JsonRule {
  const { isPropertyOfArrayItem, path } = processSchemaPath(field.schemaPath)

  const message = field.title
    ? `${field.title} maximum length is ${field.maxLength} characters`
    : `maximum length is ${field.maxLength} characters`

  const conditions: Condition[] = getMaxLengthConditions(field, path)
  if (isPropertyOfArrayItem) {
    addGridRowConditions(conditions)
  }

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        questionId: field.questionId,
        path,
        messageType: operators.maxLengthInclusive,
        message,
      },
    },
  }
}

export function buildMinValueRule (field: EngagementTemplateField): JsonRule {
  const { isPropertyOfArrayItem, path } = processSchemaPath(field.schemaPath)

  const formattedNumber = formatNumber(field.minValue + '')
  const message = field.title
    ? `${field.title} minimum value is ${formattedNumber}`
    : `minimum value is ${formattedNumber}`

  const conditions: Condition[] = getRowConditions(
    field.questionId,
    operators.greaterThanInclusive,
    isPropertyOfArrayItem,
    field.minValue,
    path
  )

  return {
    priority: rulePriorities.message,
    conditions: { any: conditions },
    event: {
      type: 'message',
      params: {
        questionId: field.questionId,
        path,
        messageType: operators.greaterThanInclusive,
        message,
      },
    },
  }
}

export function getTableCondition (
  questionId: number,
  operator: string,
  value?: number
): Condition[] {
  const conditions: Condition[] = [
    {
      fact: 'totalRows',
      operator,
      value,
      params: {
        questionId,
      },
    },
    {
      fact: 'rowIndex',
      operator: operators.notEqual,
      value: -1,
    },
  ]

  return conditions
}
// will add condition true condtion for if the answer is in a grid and the grid is no populated yet.
export function addGridRowConditions (conditions: Condition[]) {
  conditions.unshift({
    fact: 'rowIndex',
    operator: operators.equal,
    value: -1,
  })
}

export function getMaxLengthConditions (
  field: EngagementTemplateField,
  inPath?: string
): Condition[] {
  let path = inPath
  if (field.component === 'combobox') {
    if (path) {
      path = path + '.code-not-in-list'
    } else {
      path = 'code-not-in-list'
    }
  }
  const questionId = field.questionId
  const conditions: Condition[] = [
    {
      fact: 'answer',
      operator: operators.maxLengthInclusive,
      params: { questionId },
      path,
      value: field.maxLength,
    },
  ]
  const emptyCheck: Condition = {
    fact: 'answer',
    operator: operators.empty,
    params: { questionId },
    path: inPath,
    value: 0,
  }
  // only add if we had a path
  if (inPath) {
    emptyCheck.path = inPath
  }

  conditions.push(emptyCheck)

  // We need to add this one for combo box to obly check if the combo is user entered,
  if (field.component === 'combobox') {
    const comboCheck: Condition = {
      fact: 'answer',
      operator: operators.notHasProperty,
      value: 'code-not-in-list',
      params: { questionId },
    }
    if (inPath) {
      comboCheck.path = inPath
    }
    conditions.push(comboCheck)
  }

  return conditions
}
// Private commnon Helper function to build condtions.
export function getRowConditions (
  questionId: number,
  operator: string,
  isPropertyOfArrayItem: boolean,
  value?: number | string,
  path?: string,
  isRequired?: boolean
): Condition[] {
  const conditions: Condition[] = [
    {
      fact: 'answer',
      operator,
      params: { questionId },
      path,
      value,
    },
  ]
  // for grids
  if (isPropertyOfArrayItem) {
    // If this is a property on an object in an array
    // then we must ignore it if rowIndex = -1
    conditions.unshift({
      fact: 'rowIndex',
      operator: operators.equal,
      value: -1,
    })
  }

  if (!isRequired) {
    conditions.unshift({
      fact: 'answer',
      operator: operators.deepEmpty,
      params: { questionId },
      path,
      value: null,
    })
  }

  return conditions
}

export function processSchemaPath (schemaPath?: string) {
  return !schemaPath
    ? {
      isPropertyOfArrayItem: false,
      path: undefined,
    }
    : {
      isPropertyOfArrayItem: schemaPath.includes('items'),
      path: schemaPath.replace(/^items\./, '').replace(/properties\./g, ''),
    }
}

import * as React from 'react'
import { isNull, isObject } from '../../guards'
import { renderComplexAnswerLastYear } from '../../services/question/questionHelper'
import { CompositeComponent } from '../compositeComponent'
import { Alerts } from '../forms/alerts/index'
import { FormFieldProps } from '../forms/formComponentsInterfaces'
import {
  ComboBoxCreateableField,
  ComboBoxField,
  TextBoxField,
} from '../forms/index'
import './address.scss'

interface AddressOwnProps extends FormFieldProps {
  showNameField?: boolean
  showCountyField?: boolean
}

class Address extends CompositeComponent<AddressOwnProps> {
  static defaultProps = {
    showNameField: false,
    showCountyField: false,
  }

  // tslint:disable-next-line:no-any - value is not typed
  static formatValueLastYear = (value: any): string => {
    return renderComplexAnswerLastYear(
      value.street1,
      value.street2,
      value.city,
      value.state,
      value.postalCode,
      value.country,
      value.county,
      value.phone
    )
  }

  render () {
    const props = this.buildChildProps()
    const {
      messages,
      showAlerts,
      value,
      showNameField,
      showCountyField,
    } = props

    if (!isNull(value) && !isObject(value)) {
      throw new Error(
        `Address expects an object value but received ${JSON.stringify(value)}.`
      )
    }

    return (
      <div className='address'>
        {showNameField && (
          <TextBoxField {...props} label='Name of org/person' path='name' />
        )}
        <TextBoxField
          {...props}
          className='address-street1'
          label='Street Address 1'
          path='street1'
        />
        <TextBoxField
          {...props}
          className='address-street2'
          label='Street Address 2 (optional)'
          path='street2'
        />
        <div className='d-flex flex-wrap'>
          <TextBoxField
            {...props}
            className='address-city'
            label='City'
            path='city'
          />
          <ComboBoxCreateableField
            {...props}
            className='address-state'
            label='State / Province / Region'
            path='state'
          />
          <TextBoxField
            {...props}
            className='address-zip'
            label='Zip Code'
            path='postalCode'
          />
        </div>
        {showCountyField && (
          <TextBoxField
            {...props}
            className='address-county'
            label='County'
            path='county'
          />
        )}
        <ComboBoxField
          {...props}
          className='address-country'
          label='Country'
          path='country'
        />
        <TextBoxField {...props} label='Phone' path='phone' />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

export default Address

import { Activity, FileGroup } from '../clientModels'

export function CreateCommentActivity (parentActivity: Activity, value: string) {
  return {
    activityTypeCode: 'Comment',
    clientId: parentActivity.clientId,
    parentId: parentActivity.id,
    engagementId: parentActivity.engagementId,
    questionId: parentActivity.questionId,
    value,
  } as Activity
}

export function CreateCarryForwardAllCommentsActivity (
  engagementId: number,
  clientId: number,
  questionId: number,
  value: string,
  add: boolean
) {
  return {
    activityTypeCode: 'CarryForwardAllComments',
    activitySubTypeCode: add ? 'Add' : 'Remove',
    clientId,
    engagementId,
    questionId,
    value,
  } as Activity
}

export function CreateCarryForwardCommentActivity (
  parentActivity: Activity,
  value: string,
  add: boolean
) {
  return {
    activityTypeCode: 'CarryForward',
    activitySubTypeCode: add ? 'Add' : 'Remove',
    clientId: parentActivity.clientId,
    engagementId: parentActivity.engagementId,
    parentId: parentActivity.id,
    questionId: parentActivity.questionId,
    value,
  } as Activity
}

export function CreateCarryForwardFileGroupActivity (
  fileGroup: FileGroup,
  add: boolean
) {
  return {
    activityTypeCode: 'CarryForward',
    activitySubTypeCode: add ? 'Add' : 'Remove',
    clientId: fileGroup.clientId,
    engagementId: fileGroup.engagementId,
    fileGroupId: fileGroup.id,
    questionId: fileGroup.questionId,
    value: 'Carried forward ' + fileGroup.title || 'files',
  } as Activity
}

export function CreateDocumentActivity (
  value: string,
  propertyBag: string,
  engagementId: number,
  questionId?: number,
  clientId?: number
) {
  return {
    activityTypeCode: 'Document',
    clientId,
    engagementId,
    questionId,
    value,
    propertyBag,
  } as Activity
}

export function CreateEngagementLockActivity (
  engagementId: number,
  clientId?: number
) {
  return {
    activityTypeCode: 'LockEngagement',
    activitySubTypeCode: 'Remove',
    clientId,
    engagementId,
    questionId: undefined,
    value: '',
    propertyBag: '',
  } as Activity
}

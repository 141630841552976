import classNames from 'classnames'
import moment from 'moment'
import * as React from 'react'
import { Option, OptionWithDate } from '../../clientModels'
import { DateTimeField } from '../forms'
import { FormFieldValue } from '../forms/formComponentsInterfaces'
import Dropdown, { DropdownMenuExternalProps } from './dropdown'

interface Props extends DropdownMenuExternalProps {
  onChange: (value: OptionWithDate) => void
  valuesToGrabDate: string[]
}

interface State {
  selectedValue: Option | null
  selectedDate: moment.Moment | null
}

class SubHeaderDropdownWithDate extends React.Component<Props, State> {
  state: State = {
    selectedValue: null,
    selectedDate: null,
  }

  constructor(props: Props) {
    super(props)

    // #region bind this
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.pickDate = this.pickDate.bind(this)
    // #endregion
  }

  handleChange(value: Option) {
    const {
      props: { onChange, valuesToGrabDate },
    } = this

    if (valuesToGrabDate.indexOf(value.value) > -1) {
      this.setState({ selectedValue: value, selectedDate: null })
    } else {
      this.setState({ selectedValue: null, selectedDate: null })
      if (onChange) {
        onChange(value)
      }
    }
  }

  async handleDateChange(value: FormFieldValue) {
    // stringify so typescript knows that it is a string
    value = '' + value
    if (value !== '') {
      const date = moment(value)
      if (date.isValid()) {
        this.setState({ selectedDate: date })
      }
    }
  }

  pickDate() {
    const {
      state: { selectedDate, selectedValue },
      props: { onChange },
    } = this

    if (selectedDate && selectedValue) {
      if (onChange) {
        onChange({
          ...selectedValue,
          selectedDate,
        })
        this.setState({ selectedDate: null, selectedValue: null })
      }
    }
  }

  render() {
    const {
      handleChange,
      pickDate,
      handleDateChange,
      props,
      state: { selectedValue },
    } = this
    const dropdownProps = {
      ...props,
      onChange: handleChange,
      className: classNames(props.className, 'sub-header-dropdown'),
    }

    if (selectedValue) {
      dropdownProps.value = selectedValue
    }

    return (
      <div
        className={classNames('sub-header-dropdown-date-container', {
          'show-date': !!selectedValue,
        })}
      >
        <Dropdown {...dropdownProps} />
        {selectedValue && (
          <div className='date-section'>
            <div className='sub-header-dropdown-date'>
              <DateTimeField
                onChange={handleDateChange}
                placeholder='MM/DD/YYYY'
              />
            </div>
            <div className='sub-header-dropdown-pick-date' onClick={pickDate}>
              Ok
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default SubHeaderDropdownWithDate

import * as React from 'react'
import { TsaJsonSchema } from '../../services/answerSchema/index'
import { FormFieldValue } from '../forms/formComponentsInterfaces'
import { TextBox } from '../forms/index'
import { EditorProps } from './editors'

export function TextBoxEditor (property: TsaJsonSchema) {
  return class extends React.PureComponent<EditorProps> {
    constructor (props: EditorProps) {
      super(props)
      this.handleChange = this.handleChange.bind(this)
      this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    async handleChange (value: FormFieldValue) {
      const {
        props: { onChange },
      } = this
      onChange(value + '')
    }

    handleKeyDown (e: React.KeyboardEvent<HTMLElement>) {
      this.props.onKeyDown(e)
    }

    render () {
      const {
        handleChange,
        handleKeyDown,
        props: { value },
      } = this
      return (
        <TextBox
          autoFocus={true}
          jsonSchema={property}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value + ''}
        />
      )
    }
  }
}

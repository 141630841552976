import { PhasesApi } from '../services/api'
import { trackGet } from '../services/track'
import { TsaThunkAction } from '../store'
import { normalizePhasesArray } from './normalization'
import {
  phaseCacheHitAction,
  phaseListBeginAction,
  phaseListDoneAction,
  phaseListFailAction,
} from './phaseActions'

/**
 * Get a list of all engagements. This will need to be filtered in the future.
 */
export const getPhaseList = (id?: string): TsaThunkAction => async (
  dispatch,
  getState
) => {
  try {
    dispatch(phaseListBeginAction({}))

    const p = getState().phases
    let has = false
    for (const i in p) {
      if (i !== '') {
        has = true
        break
      }
    }
    if (has) {
      return dispatch(phaseCacheHitAction({}))
    }
    const phaseArray = await trackGet(PhasesApi.apiGetPhaseList, dispatch, id)
    const phases = normalizePhasesArray(phaseArray)

    return dispatch(phaseListDoneAction({ payload: { phases } }))
  } catch (error) {
    return dispatch(phaseListFailAction({ error }))
  }
}

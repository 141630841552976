import { sortByDisplayOrder } from '../../sorting'
import { DocLibraryEntry } from './docLibraryItem'
import { getDocLibraryEntryTitle } from './docLibraryUtilities'

export const sortByQuestionDisplayOrder = (
  left: DocLibraryEntry,
  right: DocLibraryEntry
) => {
  const sort = sortByDisplayOrder(left.question, right.question)
  return sort === 0 ? sortByTitle(left, right) : sort
}

export const sortByRequired = (
  left: DocLibraryEntry,
  right: DocLibraryEntry
) => {
  if (left.documentTitle && right.documentTitle) {
    return sortByQuestionDisplayOrder(left, right)
  }
  if (left.documentTitle) {
    return -1
  }
  return 1
}

export const sortByNotRequired = (
  left: DocLibraryEntry,
  right: DocLibraryEntry
) => {
  if (left.documentTitle && right.documentTitle) {
    return sortByQuestionDisplayOrder(left, right)
  }
  if (left.documentTitle) {
    return 1
  }
  return -1
}

export const sortByTitle = (left: DocLibraryEntry, right: DocLibraryEntry) => {
  const leftTitle = getDocLibraryEntryTitle(left)
  const rightTitle = getDocLibraryEntryTitle(right)
  return leftTitle.localeCompare(rightTitle)
}

import { Sortable } from './clientModels'
import { isDefined, isUndefined } from './guards'

export function sortByDisplayOrder (lhs?: Sortable, rhs?: Sortable): number {
  if (isUndefined(lhs)) {
    return 0
  }
  if (isUndefined(rhs)) {
    return 0
  }

  const leftRowIndex = lhs.rowIndex
  const rightRowIndex = rhs.rowIndex

  // Only sort by row index if both objects have a row index greater than zero.
  // We use a row index of -1 to indicate that an object is not a row in a grid.
  if (
    isDefined(leftRowIndex) &&
    isDefined(rightRowIndex) &&
    leftRowIndex > -1 &&
    rightRowIndex > -1
  ) {
    if (leftRowIndex > rightRowIndex) {
      return 1
    } else if (leftRowIndex < rightRowIndex) {
      return -1
    }
  }

  const leftDisplayOrder = lhs.displayOrder
  const rightDisplayOrder = rhs.displayOrder

  if (isUndefined(leftDisplayOrder)) {
    return 0
  }
  if (isUndefined(rightDisplayOrder)) {
    return 0
  }

  return leftDisplayOrder > rightDisplayOrder
    ? 1
    : leftDisplayOrder < rightDisplayOrder
      ? -1
      : 0
}

import * as React from 'react'
import { TaxIntegrationStatus } from '../../clientModels'
import CchErrorLinks from '../engagementSummary/cchErrorLinks/cchErrorLinks'

export function statusMessage(
  engagementid: number,
  latestTaxIntegrationStatus?: TaxIntegrationStatus
) {
  switch (latestTaxIntegrationStatus?.status) {
    case 'REQUESTED':
      return <div className='loading'>ENGAGEMENT DATA EXPORT REQUESTED...</div>
    case 'IN PROGRESS':
      return (
        <div className='loading'>ENGAGEMENT DATA EXPORT IN PROGRESS....</div>
      )
    case 'COMPLETED':
      return (
        <div className='submission-success'>
          <div className='submission-success-heading'>
            ENGAGEMENT DATA EXPORT COMPLETED
          </div>
        </div>
      )
    case 'ERROR':
      return (
        <div className='submission-failed'>
          <CchErrorLinks
            headerMessage={'UNABLE TO EXPORT DATA TO DUO'}
            engagementId={engagementid}
            message={latestTaxIntegrationStatus?.message}
            messageClassName='submission-failed-message'
          />
        </div>
      )
    default:
      return null
  }
}

export function duoIntegrationStatusMessage(
  engagementid: number,
  latestTaxIntegrationStatus?: TaxIntegrationStatus
) {
  const message = statusMessage(engagementid, latestTaxIntegrationStatus)

  return message ? (
    <div className='engagement-summary-message'>{message}</div>
  ) : null
}

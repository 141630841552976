/**
 * Wraps AbortController with additional flags
 */
export interface Abort {
  /**
   * Indicates if fetch request was aborted silently
   */
  silent: boolean

  /**
   * Indicates if fetch request timed out
   */
  timedOut: boolean

  /**
   * Used by RequestInit.signal
   */
  signal?: AbortSignal | null

  /**
   * Abort a fetch request when it is no longer needed
   */
  silentAbort: () => void

  /**
   * Abort a fetch request when it exceeds a timeout
   */
  timeout: () => void
}

/**
 * Create new instance of Abort
 */
export function createAbort (): Abort {
  return new FetchAbort()
}

class FetchAbort implements Abort {
  silent: boolean = false
  timedOut: boolean = false
  private controller: AbortController

  constructor () {
    this.controller = new AbortController()
  }

  get signal (): AbortSignal {
    return this.controller.signal
  }

  get aborted (): boolean {
    return this.controller.signal.aborted
  }

  abort () {
    this.controller.abort()
  }

  silentAbort () {
    this.silent = true
    this.abort()
  }

  timeout () {
    this.timedOut = true
    this.abort()
  }
}

import * as React from 'react'

class Swipe {
  startX: undefined | number = undefined
  startY: undefined | number = undefined
  direction: undefined | string = undefined
  swipeLeft: () => void
  swipeRight: () => void
  swipeUp: () => void
  swipeDown: () => void

  constructor (
    swipeLeft: () => void,
    swipeRight: () => void,
    swipeUp: () => void,
    swipeDown: () => void
  ) {
    this.swipeLeft = swipeLeft
    this.swipeRight = swipeRight
    this.swipeUp = swipeUp
    this.swipeDown = swipeDown

    this.handleTouchEnd = this.handleTouchEnd.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
  }

  handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    this.startX = e.touches[0].clientX
    this.startY = e.touches[0].clientY
  }

  handleTouchEnd = (e: React.TouchEvent<HTMLElement>) => {
    const { direction, swipeLeft, swipeRight, swipeUp, swipeDown } = this
    switch (direction) {
      case 'left':
        swipeLeft()
        break
      case 'right':
        swipeRight()
        break
      case 'up':
        swipeUp()
        break
      case 'down':
        swipeDown()
        break
      default:
    }
    this.direction = ''
  }

  handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    if (this.startX && this.startY) {
      const endX = e.touches[0].clientX
      const endY = e.touches[0].clientY

      const xDiff = this.startX - endX
      const yDiff = this.startY - endY

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          this.direction = 'left'
        } else {
          this.direction = 'right'
        }
      } else {
        if (yDiff > 0) {
          this.direction = 'up'
        } else {
          this.direction = 'down'
        }
      }

      this.startX = undefined
      this.startY = undefined
    }
  }
}

export default Swipe

import * as React from 'react'
import ComboBox, { ComboBoxProps } from './combobox'
import './combobox.scss'

type ComboBoxCreateableProps = Omit<ComboBoxProps, 'allowUserInput'>

const ComboBoxMulti = (props: ComboBoxCreateableProps) => {
  return <ComboBox {...props} multiSelect={true} />
}

const ComboBoxCreateableMulti = (props: ComboBoxCreateableProps) => {
  return <ComboBox {...props} allowUserInput={true} multiSelect={true} />
}

export { ComboBoxMulti, ComboBoxCreateableMulti }

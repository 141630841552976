import React from 'react'
import { NavLink } from 'react-router-dom'
import { EtlFileStatus, FileStatus, UserPermissions } from '../../enums'
import { ensureString, isDefinedNotNull } from '../../guards'
import { usePermissionManager } from '../../hooks'
import { EtlFileProgress, K1FileStatus } from '../../services/api/apiModels'
import { getDisplayStatus } from '../../services/fileHelpers'
import { Anchor } from '../anchor'
import { EtlFileStatus as K1Status } from '../../enums'

export const DisplayStatus = (props: {
  configureUrl?: any
  etlProgress: EtlFileProgress | null
  k1FileStatus?: K1FileStatus
  status?: string
  statusMessage?: string
  onClickShowError?: () => void
}) => {
  const { k1FileStatus, status, onClickShowError } = props
  const { hasPermission } = usePermissionManager()
  const canEdit = hasPermission(UserPermissions.K1DocumentsCanEdit)

  if (
    isDefinedNotNull(k1FileStatus?.status) &&
    isDefinedNotNull(k1FileStatus?.error) &&
    k1FileStatus?.error !== ''
  ) {
    return (
      <Anchor className='no-text-transform' onClick={onClickShowError}>
        {k1FileStatus!.status}
      </Anchor>
    )
  } else if (
    canEdit &&
    [
      FileStatus.Uploaded.toString(),
      EtlFileStatus.Processing.toString(),
    ].includes(status || '') &&
    isDefinedNotNull<K1FileStatus>(k1FileStatus)
  ) {
    return <StatusLink {...props} />
  } else {
    return <StatusMessage {...props} />
  }
}

const StatusLink = (props: {
  configureUrl?: any
  etlProgress: EtlFileProgress | null
  k1FileStatus?: K1FileStatus
  status?: string
}) => {
  const { configureUrl, etlProgress, k1FileStatus, status } = props
  let latestStatus = getDisplayStatus(status, etlProgress, k1FileStatus)
  console.log('StatusLink', latestStatus)

  return [
    ensureString(K1Status.Processing),
    K1Status.SubmissionInProgress,
    K1Status.SubmittedToTrapeze,
  ].includes(latestStatus) || configureUrl === undefined ? (
    <StatusMessage status={latestStatus} />
  ) : (
    <NavLink to={configureUrl} className='nav-link'>
      {latestStatus}
    </NavLink>
  )
}

const StatusMessage = (props: { status?: string; statusMessage?: string }) => {
  const { status, statusMessage } = props
  return (
    <div className='status-message'>
      {status}
      {statusMessage !== null &&
        statusMessage !== undefined &&
        statusMessage !== '' &&
        ` - ${statusMessage}`}
    </div>
  )
}

import { JsonRule } from 'json-rules-engine'
import { Moment } from 'moment'
import {
  ActivitySubType,
  ActivityType,
  PhaseCode,
  RoleCode,
  EtlFileStatus
} from './enums'
import { TsaJsonSchema } from './services/answerSchema/index'
import { InputProcessTemplate } from './services/api/apiModels'

type YesNo = 'Y' | 'N'

export interface Activity extends Entity {
  activitySubTypeCode?: ActivitySubType
  activityTypeCode: ActivityType
  author: string
  clientId: number
  createdByInternalUser?: boolean
  createdDate: Moment
  engagementId: number
  fileGroupId?: number
  isExternal?: boolean
  isLocked?: boolean // Only set on parent activities
  isCarryForwardLocked?: boolean // Only set on parent activities
  mentions?: Mention[]
  parentId?: number
  questionId?: number
  userId: string
  value: string
  propertyBag: string
  status?: string
  sentDate?: Moment
  isDeleted: boolean
  activityVersion: number
}

export type ActivityMap = EntityMap<Activity[]>

export type AsyncOperationMap = EntityMap<AsyncOperation>

export interface AsyncOperation extends Entity {
  clientId: number
  createdDateTime: string
  lastUpdatedDateTime: string
  propertyBag: string
  status: 'Submitted' | 'Processing' | 'Pending' | 'Complete' | 'Errored'
  userId: string
}

export interface AuthReactContext {
  logOut: () => void
}

export interface ClientData extends Entity {
  clientId: number
}

export interface Client extends Entity {
  fiscalYearEnd?: number
  isAudit?: YesNo
  masterId?: number
  name?: string
  nameContact?: string
}

export type ClientLight = Pick<
  Client,
  'id' | 'masterId' | 'name' | 'nameContact' | 'fiscalYearEnd' | 'isAudit'
>

export type ClientMap = EntityMap<Client>

export interface DocumentTitle {
  id: number
  optional?: boolean
  title: string
  templateFileName?: string
  processRule?: number
}

export interface Engagement
  extends Entity,
  QuestionStateSummary,
  ReviewSummary {
  activities?: Activity[]
  assignedRoleCodes?: Set<RoleCode> // Roles that are assigned to any user on this engagement
  assignedRoleCodesByUserId?: Map<string, Set<RoleCode>> // Roles that are assigned by user
  assignments?: EngagementAssignment[]
  cchVersion: number
  client?: number
  clientId: number
  clientsLastModified?: ClientLastModified[]
  completionDate?: string
  creationDate?: string
  criticalError: boolean
  engagementTaxForm?: string
  engagementTemplateId: number
  hasEtlData: boolean
  lastQuestionId?: number
  lastSectionId?: number
  lastUpdatedBy?: UserProfile
  lastUpdatedDate?: string
  favorited?: boolean
  loaded?: boolean
  name?: string
  nextYearTemplateAvailable: boolean
  phase?: PhaseCode
  milestones?: EngagementMilestone[]
  priorYearReturnId?: string
  rollForwardDate?: string
  taxIntegrationStatuses?: TaxIntegrationStatus[]
  taxYear?: string
  template?: number
  type?: string
  isEnabled: boolean
  visible?: boolean
  usedLastYearsAnswers?: boolean
}

/** Represents a relationship between a cch field code and a TSA codes, and provides the question information
 *   for the related question(s). */
export interface CchFieldTranslation {
  cchFieldId: string
  tsaFields: string[]
  questionTranslations: QuestionTranslation[]
}

export interface QuestionTranslation {
  tsaNumber: string
  sectionId: number
  questionId: number
  questionNumber: string
}

export interface ClientLastModified extends Entity {
  engagementId: number
  questionNumber: string
  sectionNumber: string
  emailAddress: string
  createdDate: string
}

export interface EngagementAssignment extends Entity {
  engagementId: number
  roleCode: RoleCode
  userId: number
  user: UserProfile
}

export interface EngagementMap {
  listError?: Error
  [engagementId: number]: Engagement | undefined
}

export interface EngagementMilestone extends Entity {
  engagementId: number
  milestone: string
  dueDate?: string
  completedDate?: string
}

export type AnswerValue =
  // Textbox, Number
  | string

  // Options-based answers store an object that represents the selected options
  // A single select (radio button, combobox) is stored as an object with a single property.
  // Mutli-select components like checkboxes store an object with a property that
  // represents each checked box.
  | object

  // Grids
  | object[]

  // Not answered yet or value was removed
  | null

/**
 * The data related to the answer given by the client.
 */
export interface IEngagementQuestionAnswer {
  answerId: number
  answerValue: AnswerValue
  answerValueLastYear: AnswerValue
  engagementId: number
  answerMetadataId?: number
  flagged?: boolean
  isDirty?: boolean
  isPristine?: boolean
  notApplicable?: boolean
  active?: boolean
  questionId: number
  reviewRolesComplete: Set<RoleCode>
  clientVersion: number
  clientFlagVersions: FlagVersions
  userId?: string
  user?: UserProfile
  answerVersion: number
  answerMetadataVersion?: number
}

/**
 * These properties are set by business rules. A business
 * rule can raise an event which in turn dispatches a Redux
 * action.
 */
interface IEngagementQuestionRulesProperties {
  /**
   * Stores the isVisible value that is set by question visibility
   * rules. If a section visibility rule overrides the question level
   * visibility the memory allows us to restore the previous value
   * when the section becomes visible again.
   */
  isEnabled: boolean
  isVisibleMemory: boolean
  isVisible: boolean
  messages: Message[]
  requiredDocumentTitleIds: number[]
}

/**
 * This data was originally derived from the Activities
 * table. Comment count is still done that way but
 * the flagged flag is now stored in Answers.
 */
interface IEngagementQuestionActivityData {
  questionId: number
  commentsCount: number
}

export interface RoleVersions {
  [role: string]: number | undefined
}

interface FlagVersions {
  flagged: number
  notApplicable: number
  reviewRoles: RoleVersions
}

/**
 * This is for the storage of data that is specific to a question on a particular engagement
 */
export interface EngagementQuestion
  extends IEngagementQuestionRulesProperties,
  IEngagementQuestionActivityData,
  IEngagementQuestionAnswer {
  answerValue: AnswerValue
  answerValueLastYear: AnswerValue
  commentsCount: number
  engagementId: number
  flagged?: boolean
  isDirty?: boolean
  isPristine?: boolean
  isVisible: boolean
  isVisibleMemory: boolean
  messages: Message[]
  notApplicable?: boolean
  optionalDocumentTitleIds: number[]
  requiredDocumentTitleIds: number[]
  reviewRolesComplete: Set<RoleCode>
  sectionId: number
  clientVersion: number
  clientFlagVersions: FlagVersions
}

export interface EngagementQuestionMap {
  [questionId: string]: EngagementQuestion | undefined
  [questionId: number]: EngagementQuestion | undefined
}

export type EngagementQuestionServerData = Pick<
  EngagementQuestion,
  'questionId' | 'commentsCount'
>

export interface EngagementReportRequestResponse {
  asyncOperationId?: number
  token?: string
}

export type EngagementReportType =
  | 'pbc'
  | 'audit'
  | 'k1summary'
  | 'engagementData'
  | 'phaseChangePbc'
  | 'duoExport'

/**
 * This is for the storage of data that is specific to a section on a particular an engagement
 */
export interface EngagementSection extends Entity {
  id: number
  engagementId: number
  isVisible: boolean
  isVisibleDebug: number
}

export interface EngagementSectionMap {
  [sectionId: string]: EngagementSection | undefined
  [sectionId: number]: EngagementSection | undefined
}

export interface EngagementTask extends ClientData {
  client?: number
  dueDate?: string
  endDate?: string
  engagement?: number
  engagementId: number
  startDate?: string
  status?: string
  section?: number
  sectionId: number
  sectionRole?: string
  userId: string
}

export type EngagementTaskMap = EntityMap<EngagementTask>

export interface EngagementTemplate extends Entity {
  cchReturnType: string
  hasCchPhase: boolean
  integrateWithCch: boolean
  name: string
  questions: number[]
  sections: number[]
  taxYear: number
  loaded?: boolean
  resourceType?: string
  hasGlossary?: boolean
}

export interface EngagementTemplateField extends Entity {
  alignment?: string
  codeListid?: number
  component: string
  dataType: string
  displayOrder: number
  engagementTemplateId: number
  fieldCode: string
  format?: string
  headerWidth?: string
  itemLabels: ItemLabel[]
  maxItems?: number
  maxLength?: number
  maxWidth?: string
  minItems?: number
  minValue?: number
  minWidth?: string
  questionId: number
  required: boolean
  schemaPath?: string
  title?: string
  width?: string
}

export type EngagementTemplateFieldMap = EntityMap<EngagementTemplateField>

export type EngagementTemplateMap = EntityMap<EngagementTemplate>

export interface EngagementUploadHistory extends Entity {
  createdBy: string
  createdDate: Date
  engagementUploadHistoryStatuses: EngagementUploadHistoryStatus[]
  file?: File
  fileId?: number
}

export interface EngagementUploadHistoryStatus extends Entity {
  message: string
  status: string
}

export interface Entity {
  id: number
  // TODO: We need to get rid of this indexer. It invalidates all property type checking
  // on anything the extends Entity. It was added to allow for entity['validpropertyname']
  // when we moved to TypeScript 3.4. We need a different way to allow for indexed access.
  [property: string]: any
}

export interface EntityMap<T> {
  [id: string]: T | undefined
  [id: number]: T | undefined
}

export enum EntityListOperation {
  add,
  update,
  delete,
  replace
}

export interface EtlFile {
  id: number
  fileId: number
  etlTemplateId?: number
  fileSheetDetailsId?: number
  engagementId?: number
  clientId: number
  status?: string
  statusMessage?: string
  error?: string
  step?: string
}

export interface EtlFileStatusMap {
  [id: string]: EtlFileStatus
  [id: number]: EtlFileStatus
}

export interface EntityListUpdate<T> {
  op: EntityListOperation
  value: T
}

export type EtlScreen =
  | 'define'
  | 'initial'
  | 'scan'
  | 'format'
  | 'pivot'
  | 'categories'
  | 'entities'
  | 'review'
  | 'structure'
  | 'taxCodes'

export enum LoadStatus {
  notLoaded,
  loading,
  loaded
}

export type EtlScreenMap<T> = {
  [id in EtlScreen]: T
}

export function etlScreenMapContains<T>(
  map: EtlScreenMap<T>,
  value: T
): boolean {
  const vals = Object.values<T>(map)
  return vals.includes(value)
}

/**
 * Registered engagement template field formats.
 */
export type FieldFormat =
  | 'bothValueLabel'
  | 'ein'
  | 'einOrSsn'
  | 'gen' // group exemption number
  | 'integer'
  | 'label'
  | 'number'
  | 'simple'
  | 'ssn'
  | 'value'

export interface File extends Entity {
  clientId?: number
  contentLength?: number
  contents?: Blob
  contentType?: string
  createdDate?: string
  description?: string
  file?: File
  fileGroupId?: number
  isDeleted?: boolean
  isUpdated?: boolean
  name: string
  parentId?: number
  path?: string
  sasUri?: string
  status?: string
  statusMessage?: string
  tags?: FileTag[]
  updatedDate?: string
  userProfile?: UserProfile
  versionId?: number
  fileTagsToBeUpdated?: EntityListUpdate<FileTag>[]
}

export interface FileContentsMap {
  [fileName: string]: Blob | undefined
}

export interface FileGroup extends Entity {
  carryForwardActivityId?: number
  clientId?: number
  createdDate?: string
  documentTitleId?: number
  engagementId?: number
  files?: File[]
  notApplicable?: boolean
  questionId?: number
  title?: string
  updatedDate?: string
  userId?: string
}

export interface FileGroupMap {
  [questionId: string]: FileGroup[] | undefined
  [questionId: number]: FileGroup[] | undefined
}

export interface FileTag extends Entity {
  id: number
  fileId: number
  tag?: string
  value?: string
}

export const FileTagCodes = {
  etlProgress: 'etl-progress'
}

/**
 * Maps the ID of one entity to the ID of another entity.
 */
export interface IdMap {
  [id: number]: number
}

// export interface InputProcessTemplate extends Entity {
//   clientId?: number
//   isLocked: boolean
//   isLockedByCurrentUser: boolean
//   newTemplateName: string
//   templateType: TemplateType
//   worksheet: StructureWorksheet
// }

export interface InputProcessTemplateMap {
  [id: string]: InputProcessTemplate
  [id: number]: InputProcessTemplate
}

export interface K1FileStatusMap {
  [id: string]: K1FileStatus
  [id: number]: K1FileStatus
}

export interface K1Header extends Entity {
  clientId: number
  dateModified: string
  draft: boolean
  engagementId: number
  fileId: number
  hasState: boolean
  isFailed: boolean
  isManualEntry: boolean
  issuingEntityId: number
  issuingId: string
  issuingIdLocation: string
  issuingName: string
  issuingNameLocation: string
  k1MappingFiles: File[]
  receivingEntityId: number
  receivingId: string
  receivingIdLocation: string
  receivingName: string
  receivingNameLocation: string
  state: string
  status: string
  statusMessage: string
  userId: number
}

export interface K1Mapping extends Entity {
  clientId: number
  engagementId: number
  file: File
  fileId: number
  k1HeaderId: number
  tsaCode: string
  value: string
  k1Value: string
  k1ValueLocation: string
  k1Code: string
  k1CodeLocation: string
  k1Description: string
  k1DescriptionLocation: string
  k1ActivityCode: string
  k1ActivityCodeLocation: string
  k1State: string
  k1StateLocation: string
  k1Passive: string
  k1PassiveLocation: string
  userId: number
  dateModified: string
}

export interface FormatSetup {
  etlType: string
  excludeRows: string
  fileFormat: string
  startingCell: string
  worksheetName: string
}

export interface StructureWorksheet {
  formatSetup: FormatSetup
  numberOfRows?: number
  overrideExisting?: boolean
  pivotSetup?: PivotConfiguration<string>
}

export interface StructureTemplate extends StructureWorksheet { }

export interface ItemLabel extends Entity {
  engagementTemplateId: number
  displayOrder: number
  label: string
}

export interface K1FileStatus extends Entity {
  fileId: number
  status: string
  statusMessage: string
  error: string
  userId: string
  createDate: Date
  updateDate: Date
}

export interface Mention extends Entity {
  activityId: number
  label: string | null
  value: string | null
  questionId?: number
  resolved: boolean
}

export type MessageSeverity = 'success' | 'warning' | 'error' | 'critical'

export interface Message {
  acknowledged?: boolean
  displayOrder?: number
  message?: string
  path?: string
  rowIndex?: number
  severity: MessageSeverity
  type: string
}

export interface Name extends Entity {
  prefix?: string
  firstName?: string
  middleInitial?: string
  lastName?: string
  suffix?: string
}

export type Option = {
  value: string
  label: string
  isDisabled?: boolean // changed from disabled to isDisabled as of react-select v2
  codeNotInList?: boolean
  group?: boolean
}

export type OptionIntValue = {
  value: number
  label: string
  isDisabled?: boolean // changed from disabled to isDisabled as of react-select v2
  codeNotInList?: boolean
  group?: boolean
}

export type OptionWithDate = Option & {
  selectedDate?: Moment
}

export interface OptionListMap {
  [id: string]: Option[] | undefined
  [id: number]: Option[] | undefined
}

export interface OrgProfile {
  id: string
  name?: string
}

export interface EntityEngagement {
  entityId: number
  engagementId: number
  entityShortName: string
  clientId: number
  engagementName: string
  phase?: PhaseCode
  resourceType: string
  taxYear: string
  taxIntegrationStatuses?: TaxIntegrationStatus[]
}

export interface EntityGroup extends Entity {
  name: string
  entityEngagements: EntityEngagement[]
}

export interface Phase extends Entity {
  code: PhaseCode
  description: string
  displayOrder: number
}

export type PhaseMap = EntityMap<Phase>

export interface PivotConfiguration<T> {
  columns: EntityMap<T>
  rows: EntityMap<T>
}

export interface PropertyBag {
  engagementId?: number
  fileId?: number
}

export interface PropertyValues {
  /**
   * Path is a property path string.
   * Value is the value to be set at the location specified in the path.
   */
  [path: string]: any
}

export interface Question extends Entity, Sortable {
  answerSchema: TsaJsonSchema
  displayNumber: string
  displayOrder: number
  engagementTemplateId: number
  help?: string
  hint?: string
  number: string
  reviewRolesRequired: RoleCode[]
  allowNotApplicable: boolean
  sectionId: number
  text: string
  uploadText?: string
  showUseLastYear: boolean
  isInternalVisibleOnly: boolean
  isVisible: boolean
}

export type QuestionMap = EntityMap<Question>

export interface QuestionStateSummary {
  totalQuestions: number
  successfulQuestions: number
  successfulForUserQuestions?: number
  totalVisibleQuestions?: number
}

export interface QuestionTuple {
  question: Question
  engagementQuestion?: EngagementQuestion
}

export interface RelatedClient extends Entity {
  addressId: number
  isPerson?: boolean
  name?: string
  nameConsolidated?: string
  nameConsolidated2?: string
  nameContact?: string
  nameFirst?: string
  nameLast?: string
  nameMiddle?: string
  namePrefix?: string
  nameSort?: string
  nameSuffix?: string
}

export type RelatedClientMap = EntityMap<RelatedClient>

export interface ReviewSummary {
  concurringReviewStarted?: boolean
  preparerReviewStarted?: boolean
  primaryReviewStarted?: boolean
  secondaryReviewStarted?: boolean
  concurringReviewDone?: boolean
  preparerReviewDone?: boolean
  primaryReviewDone?: boolean
  secondaryReviewDone?: boolean
}

export interface Role {
  code: RoleCode
  description: string
}

export interface Rule extends Entity {
  id: number
  description?: string
  engagementTemplateId: number
  options: JsonRule
}

export type RuleMap = EntityMap<Rule>

export interface SearchResult extends Sortable {
  type: SearchResultType
  property: string
  id: number | string
  match: RegExpMatchArray
  displayOrder: number
}

type SearchResultType = 'section' | 'question'

export interface Section extends Entity, Sortable {
  description?: string
  displayOrder: number
  engagementTemplateId: number
  number: string
  questions: number[]
  title: string
  isInternalVisibleOnly: boolean
  isVisible: boolean
}

export type SectionMap = EntityMap<Section>

export type SidebarNames = 'activity' | 'help' | 'file' | ''

export interface Sortable {
  displayOrder?: number
  rowIndex?: number
}

export type SubMenuItem = Section | undefined

export interface TaxIntegrationStatus extends Entity {
  createdDate: Date
  engagementId: number
  message: string
  status: string
  type: string
  duoFileType?: string
}

export type TemplateType = 'DataMapping' | 'StructureMapping'

export interface OrganizationClaim {
  organizationId: string
  claims: string[]
}

export interface IdentityTokenProfile {
  name: string
  firstName?: string
  lastName?: string
  organization: OrgProfile
  isExternal: boolean
  uniqueId: string
  email?: string
  exp: number
  clientId: string[]
  applicationId: string[]
  permissionsEnabled?: boolean
  permissions?: string[]
  impersonationPermissions?: string[]
  organizationClaims?: OrganizationClaim[]
}

export interface UserProfile {
  id: number
  userId: string
  isInternal: boolean
  firstName?: string
  lastName?: string
  email?: string
}

export interface StringMatch {
  value: string
  isMatch: boolean
}

export interface ClientSearch {
  clientId: number
  masterId?: number
  name: string
  matchClient: boolean
  matchMaster: boolean
  nameMatch: StringMatch[]
}

export interface ClientAccessRequest {
  id: number
  userId?: string
  firstName?: string
  lastName?: string
  email?: string
  createDate?: string
  requestStatus?: string
  clientId: string
  approverUserId? : string
  approvedDate? : string
  notificationDate? : string
  notificationStatus? : string
  approvedErrMessage? : string
  notificationErrMessage? : string
  tsaAuthorized? : boolean
  twbAuthorized?: boolean
  dmsAuthorized? : boolean
}

export interface StringLookup {
  [key: string]: string
}
export interface LastNavigationMap {
  [engagementId: number]: LastNavigation | undefined
}
export interface LastNavigation extends Entity {
  engagementId: number
  lastSectionId: number
  lastQuestionId: number
}
export interface UserSettings {
  lastNavigationMap: LastNavigationMap
}

export interface ClientEntity extends Entity {
  firstName?: string
  id: number
  lastName?: string
  middleInitial?: string
  entityName?: string
  entityType?: string
  entityShortName?: string
  suffix?: string
  nameContinued?: string
  isRSMClient?: boolean
  clientId?: number
  entityGroups?: ClientGroup[]
}

export interface ClientGroup extends Entity {
  id: number
  name: string
}

export interface Tab {
  step: EtlScreen
  title: string
  subtitle: string
}

export interface TaxCode {
  id: number
  taxForm: string
  schedule: string
  part: string
  line: string
  tsaCodeId: string
  tsaCodeDescription: string
  comment: string
}

export interface DropdownOption {
  label: string
  value: string
}

export interface ClientGroupSelection {
  clientGroupId: number
  taxYear?: string
  type?: string
}

export interface EtlField {
  id: number
  name: string
  dataFormat: string
  precision: number
  scale?: number
  required: boolean
  dataRule: boolean
  mappingType: string
}

export interface EtlTemplate {
  id: number
  name: string
  description: string
  fields?: EtlField[]
}

export interface EngagementSectionGroup {
  id: number
  label: string
  taxYear: string
  taxForm: string
  startSectionIndex: number
  endSectionIndex: number
}

export interface EngagementSectionNavItem {
  sectionId: number
  displayOrder: number
  navItem: JSX.Element | null
}

export interface ClientFilterNode {
  label: string
  id?: number
  ids?: number[]
  children?: ClientFilterNode[]
}

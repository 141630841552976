import { getType } from 'typesafe-actions'
import * as actions from '../actions/fileActions'
import { TsaHubAction } from '../actions/index'
import { DocumentTitle, FileGroup } from '../clientModels'
import { ensureNumber } from '../guards'
import { removeFile } from '../services/fileHelpers'
import { File as TsaFile } from '../services/api/apiModels'

export interface FilesUploadStatus {
  [name: string]: string | undefined
}

export interface SelectedFiles {
  [fileId: string]: boolean
  [fileId: number]: boolean
}

export interface FileUploadState {
  engagementId?: number
  questionId?: number
  documentTitle?: DocumentTitle
  fileGroup?: FileGroup
  replace?: number
  isTemplate?: boolean
  maxFileCount?: number
  uploadStatus: FilesUploadStatus
  openDialog?: boolean
  selectedFiles: SelectedFiles
  showUploadErrorDialog: boolean
}

const initialState: FileUploadState = {
  uploadStatus: {},
  selectedFiles: {},
  showUploadErrorDialog: false,
}

export function fileUploadReducer(
  state: FileUploadState = initialState,
  action: TsaHubAction
): FileUploadState {
  switch (action.type) {
    case getType(actions.openFileUploadAction): {
      if (!action.payload.documentTitle) {
        delete state.documentTitle
      }
      if (!action.payload.fileGroup) {
        delete state.fileGroup
      }
      state.selectedFiles = {}
      state.uploadStatus = {}
      return { ...state, ...action.payload }
    }

    case getType(actions.toggleSelectFileAction): {
      const { fileId } = action.payload
      const selectedFiles = { ...state.selectedFiles }

      selectedFiles[fileId] = !selectedFiles[fileId]

      return { ...state, selectedFiles }
    }

    case getType(actions.toggleTag.success): {
      const { files } = action.payload
      const { fileGroup } = state

      const stateFiles = (fileGroup && fileGroup.files) || []

      const updatedFiles = stateFiles.map(x => {
        const updatedFile = files.find(y => y.id === x.id)
        if (updatedFile) {
          return { ...x, tags: updatedFile.tags }
        }

        return x
      })

      if (fileGroup) {
        return { ...state, fileGroup: { ...fileGroup, files: updatedFiles } }
      }

      return state
    }

    case getType(actions.softDeleteFilesStatus.success): {
      const { fileIds } = action.payload
      const { fileGroup } = state
      fileIds.forEach(fileId => {
        if (fileGroup) {
          if (fileGroup.files) {
            fileGroup.files = [
              ...removeFile(fileGroup.files, ensureNumber(fileId)),
            ]
          }
        }
      })
      if (fileGroup) {
        return { ...state, fileGroup: { ...fileGroup } }
      }
      return state
    }

    case getType(actions.setFileUploadStatus): {
      const { status, name } = action.payload.updates
      const uploadStatus = { ...(state.uploadStatus || {}), [name]: status }
      return { ...state, openDialog: false, fileGroup: undefined, uploadStatus }
    }

    case getType(actions.clearFileUploadStatus):
      return { ...state, uploadStatus: {} }

    case getType(actions.setFileUploadDialogClose): {
      const { open } = action.payload
      const newState = { ...state }

      newState.uploadStatus = {}
      delete newState.replace
      newState.openDialog = open
      if (!open) {
        delete newState.fileGroup
      }
      return newState
    }

    case getType(actions.setFileUploadDialogOpen): {
      const { open, replace } = action.payload
      const newState = { ...state, ...action.payload }
      if (!action.payload.documentTitle) {
        delete newState.documentTitle
      }
      if (!action.payload.fileGroup || !open) {
        delete newState.fileGroup
      }
      state.selectedFiles = {}
      newState.uploadStatus = {}
      newState.openDialog = open
      newState.replace = replace
      return newState
    }

    case getType(actions.addUpdateFileGroup.success): {
      const { group } = action.payload
      return { ...state, fileGroup: group }
    }

    case getType(actions.setFileUploadFileGroup): {
      const { fileGroup } = action.payload
      return { ...state, fileGroup }
    }

    case getType(
      actions.updateFile.success
    ): // case getType(inputProcessActions.completeK1FileProgressActions.success): // case getType(inputProcessActions.updateK1FileProgressAction.success):
      {
        const file = action.payload
        const fileGroup = state.fileGroup
        const existingFiles = fileGroup && fileGroup.files
        if (!fileGroup || !existingFiles) return state
        const matcher = (existingFile: TsaFile) => {
          // newly uploaded files will have an id of -1
          // so this will fail if all we do is match by id
          // so match by file name if existing id is -1
          if (file.id === existingFile.id || (existingFile.id === -1 && existingFile.name === file.name)) {
            return file
          }
          return existingFile
        }
        const updatedFiles = existingFiles.map(matcher)
        const uploadStatus = {
          ...(state.uploadStatus || {}),
          [file.name]: file.status,
        }
        const showUploadErrorDialog =
          state.showUploadErrorDialog === true ||
          (!state.showUploadErrorDialog && file.status === 'Error')

        return {
          ...state,
          uploadStatus,
          showUploadErrorDialog: showUploadErrorDialog,
          fileGroup: {
            ...fileGroup,
            files: updatedFiles,
          },
        }
      }

    case getType(actions.hideUploadErrorDialog): {
      return {
        ...state,
        showUploadErrorDialog: false,
      }
    }

    default:
      return state
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { createSelector } from 'reselect'
import { actions } from '../../actions'
import { FileGroup } from '../../clientModels'
import { ensureNumber } from '../../guards'
import { AppState } from '../../store'
import DocLibraryAllTable from './docLibraryAllTable'

const selectEntityGroup = (state: AppState, entityGroupId: number) =>
  state.entityGroups[entityGroupId]

const selectFileGroups = createSelector(
  selectEntityGroup,
  (state: AppState) => state.fileGroups,
  (entityGroup, fileGroups) => {
    const groups: FileGroup[] = []
    if (!entityGroup) {
      return groups
    }

    const engagementIds = entityGroup.entityEngagements.map(e => e.engagementId)
    for (const fileGroup of Object.values(fileGroups)) {
      if (
        fileGroup &&
        fileGroup.engagementId &&
        engagementIds.includes(fileGroup.engagementId)
      ) {
        groups.push(fileGroup)
      }
    }

    return groups
  }
)

interface RouteParams {
  entityGroupId: string
}

interface DocLibraryAllProps extends RouteComponentProps<RouteParams> {}

export const DocLibraryAll = ({ match }: DocLibraryAllProps) => {
  const entityGroupId = ensureNumber(match.params.entityGroupId)
  const dispatch = useDispatch()
  const entityGroup = useSelector((state: AppState) =>
    selectEntityGroup(state, entityGroupId)
  )
  const fileGroups = useSelector((state: AppState) =>
    selectFileGroups(state, entityGroupId)
  )

  React.useEffect(() => {
    if (entityGroupId) {
      dispatch(actions.file.getFileGroupsForEntityGroup(entityGroupId))
    }
  }, [dispatch, entityGroupId])

  if (!entityGroup) {
    return null
  }

  return (
    <DocLibraryAllTable fileGroups={fileGroups} entityGroup={entityGroup} />
  )
}

import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useTermsOfUse } from '../../hooks'
import Loader from '../loader'
import { Card, CardContent } from '@material-ui/core'
import rsmLogo from '../../assets/img/icon/rsm-logo.png'
const TermsOfUse = () => {
  const { Id } = useParams<{ Id: string }>()
  const { getTermsOfUse, requestStatus, termOfUse } = useTermsOfUse()
  // read up the eula
  useEffect(() => {
    getTermsOfUse(Id)
  }, [getTermsOfUse, Id])
  const ready = requestStatus === 'Success'
  const createContent = () => {
    return { __html: termOfUse as string }
  }
  return (
    <Loader ready={ready}>
      <Card>
        <CardContent>
          <div style={{ width: '100%' }}>
            <img src={rsmLogo} alt='RSM' style={{ marginLeft: '45%' }} />
          </div>
          <div>
            <div dangerouslySetInnerHTML={createContent()} />
          </div>
        </CardContent>
      </Card>
    </Loader>
  )
}
export default TermsOfUse

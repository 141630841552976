import classNames from 'classnames'
import * as React from 'react'
import Icon from '../icon/icon'

interface FileUploadButtonProps {
  busy: boolean
  text: string
  disabled: boolean
  onClick: () => void
}

const FileUploadButton: React.SFC<FileUploadButtonProps> = ({
  busy,
  text,
  disabled,
  onClick,
}) => (
  <button
    type='button'
    className={classNames('btn btn-block file-upload-btn', { busy })}
    disabled={disabled}
    onClick={onClick}
  >
    {text}
    {busy && <Icon icon='loading' />}
  </button>
)

export default FileUploadButton

import classNames from 'classnames'
import * as React from 'react'
import SmoothCollapse from 'react-smooth-collapse'
import { Option } from '../../clientModels'
import './contextMenu.scss'

interface Properties {
  className?: string
  itemClicked?: (value: string) => void
  left?: number
  onChange?: (value: string) => void
  options: Option[]
  top?: number
  value?: string
}

// TODO: Components in contextMenu.tsx, documentFilter.tsx, documentSort.tsx, headerSelector.tsx, addrow.tsx look an awful lot alike. Can we extract a component?
class DatasheetContextMenu extends React.Component<Properties> {
  constructor (props: Properties) {
    super(props)

    this.optionSelected = this.optionSelected.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
  }

  optionSelected (e: React.MouseEvent<HTMLDivElement>) {
    const { itemClicked } = this.props
    if (itemClicked) {
      itemClicked(e.currentTarget.id)
    }
  }

  onKeyPress (e: React.KeyboardEvent<HTMLDivElement>) {
    const { onChange } = this.props
    if (!onChange) {
      return
    }
    if (e.charCode === 13 || e.charCode === 32) {
      onChange(e.currentTarget.id)
    }
  }

  render () {
    const {
      optionSelected,
      onKeyPress,
      props: { className, left, options, top, value },
    } = this

    return (
      <div
        className={classNames('datasheet-context-menu', className)}
        style={{ position: 'fixed', left: left + 'px', top: top + 'px' }}
      >
        <SmoothCollapse expanded={true}>
          <div className='tcont datasheet-context-menu-items'>
            {options.map((o, i) => {
              const selected = o.value === value
              return (
                <div
                  key={i}
                  id={o.value}
                  onClick={!selected ? optionSelected : undefined}
                  onKeyPress={!selected ? onKeyPress : undefined}
                  className={classNames({ selected })}
                  tabIndex={!selected ? 0 : undefined}
                >
                  {o.label + ' ' + o.value}
                </div>
              )
            })}
          </div>
        </SmoothCollapse>
      </div>
    )
  }
}

export default DatasheetContextMenu

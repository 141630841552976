import * as React from 'react'
import { QuestionMap, SearchResult, SectionMap } from '../../clientModels'
import './search.scss'
import { SearchBoxResult } from './searchBoxResult'

interface SearchBoxResultsProps {
  sections: SectionMap
  questions: QuestionMap
  results?: SearchResult[]
  searchString: string
  selectedSearchResult?: SearchResult
  onSelectSearchResult: (result: SearchResult) => void
}

export class SearchBoxResults extends React.Component<SearchBoxResultsProps> {
  render () {
    const {
      onSelectSearchResult,
      questions,
      results,
      searchString,
      sections,
      selectedSearchResult,
    } = this.props

    if (!results) {
      return null
    }

    return (
      <div className='search-box-results'>
        {results.length === 0 && (
          <div className='search-box-result search-result-none'>
            No results found.
          </div>
        )}
        {results.map((r, i) => (
          <SearchBoxResult
            key={i}
            onSelectSearchResult={onSelectSearchResult}
            questions={questions}
            result={r}
            searchString={searchString}
            sections={sections}
            selectedSearchResult={selectedSearchResult}
          />
        ))}
      </div>
    )
  }
}

export default SearchBoxResults

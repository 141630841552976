import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

export function useWindowWidth() {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const handleResize = throttle(() => setWidth(window.innerWidth), 1000)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return width
}

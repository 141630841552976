import { ClientFilterNode } from '../clientModels'
import { createAction, ActionType, createAsyncAction } from 'typesafe-actions'
import * as typeHelpers from './actionTypeHelpers'

// NOTE: Banner Filters are the dropdown at the top of the page (masterClientId>FormName>TaxYear), and in the URL.

export const updateBannerFilters = createAsyncAction(
  'BANNER_FILTERS_UPDATE_BEGIN',
  'BANNER_FILTERS_UPDATE_DONE',
  'BANNER_FILTERS_UPDATE_FAIL'
)<void, ClientFilterNode[], Error>()

export const selectBannerFilter = createAction('BANNER_FILTERS_SELECT_FILTER')<
  ClientFilterNode[] | null
>()

export type BannerFilterActions =
  | typeHelpers.UnwrapActionTypes<typeof updateBannerFilters>
  | ActionType<typeof selectBannerFilter>

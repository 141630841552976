import React from 'react'
import { EtlFile } from '../../clientModels'
import { isDefinedNotNull, ensureString } from '../../guards'
import { EtlFileStatus, UserPermissions } from '../../enums'
import { Anchor } from '../anchor'
import { getDisplayStatus } from './dataFileHelpers'
import { usePermissionManager } from '../../hooks'

interface DataFileStatusProps {
  etlFile?: EtlFile
  status?: string
  onClickShowError?: () => void
}

/**
 * DataFileStatus is a function component that renders
 * a status component based on external input.
 */
const DataFileStatus = ({
  etlFile,
  onClickShowError,
  status,
}: DataFileStatusProps) => {
  const { hasPermission } = usePermissionManager()
  const canEditDataFiles = hasPermission(UserPermissions.DataFilesCanEdit)
  const displayStatus = getDisplayStatus(!canEditDataFiles, etlFile, status)

  const isProcessing = (status: string) => {
    return [
      ensureString(EtlFileStatus.Processing),
      EtlFileStatus.SubmissionInProgress,
    ].includes(status)
  }

  const inProcess = isProcessing(displayStatus)

  if (
    !inProcess &&
    canEditDataFiles &&
    isDefinedNotNull(etlFile) &&
    /error/gi.test(etlFile.status!!)
  ) {
    return (
      <Anchor className='no-text-transform' onClick={onClickShowError}>
        {etlFile.status!}
      </Anchor>
    )
  }

  return <div>{displayStatus}</div>
}

export default DataFileStatus

import {
  Engagement,
  EngagementTemplate,
  IEngagementQuestionAnswer,
  PropertyValues,
  Question,
} from '../clientModels'
import { ensureNumber } from '../guards'
import { selectAnswer } from '../reducers/selectors'
import { chain } from '../services/promiseHelpers'
import { getEngineByQuestionId } from '../services/rules/engines'
import { AppState } from '../store'

export interface RuntimeFacts {
  engagementTemplateId: number
  engagementId: number
  clientId: number
  rowIndex: number
  totalRows?: number
}

export function selectEngagement(
  state: AppState,
  engagementId: number | string
): Engagement {
  const engagement = state.engagements[ensureNumber(engagementId)]
  if (!engagement) {
    throw new Error(
      `Engagement ${engagementId} must be loaded before running rules.`
    )
  }
  return engagement
}

export function selectEngagementTemplate(
  state: AppState,
  engagementTemplateId: number
): EngagementTemplate {
  const template = state.engagementTemplates[engagementTemplateId]
  if (!template) {
    throw new Error(
      `Engagement template ${engagementTemplateId} must be loaded before running rules.`
    )
  }
  return template
}

function selectQuestion(
  state: AppState,
  questionId: number | string
): Question {
  const question = state.questions[questionId]
  if (!question) {
    throw new Error(
      `Question ${questionId} must be loaded before running rules.`
    )
  }
  return question
}

/**
 * Internal implementation of the runQuestionRules thunk.
 */
export function runQuestionRulesInternal(
  getState: () => AppState,
  engagementId: number | string,
  questionId: number | string,
  properties?: PropertyValues
) {
  const state = getState()
  const engagement = selectEngagement(state, engagementId)
  const question = selectQuestion(state, questionId)
  const answer = selectAnswer(state, engagementId, questionId)
  const run = answerValueIsArray(question)
    ? runArrayQuestionEngine
    : runQuestionEngine
  return run(engagement, question, answer, properties)
}

/**
 * Run the rules for the specified question.
 */
export function runQuestionEngine(
  engagement: Engagement,
  question: Question,
  answer?: IEngagementQuestionAnswer,
  properties?: PropertyValues
) {
  const engine = getEngineByQuestionId(question.id)
  if (!engine) {
    // This question doesn't have any rules
    return Promise.resolve()
  }

  const runtimeFacts: RuntimeFacts = {
    engagementTemplateId: engagement.engagementTemplateId,
    engagementId: engagement.id,
    clientId: engagement.clientId,
    rowIndex: -1,
  }

  return engine.run(runtimeFacts).then(returnVoid)
}

/**
 * Run the rules for the specified question. The question must be configured
 * to expect an answer that is an array of values.
 */
export function runArrayQuestionEngine(
  engagement: Engagement,
  question: Question,
  answer?: IEngagementQuestionAnswer,
  properties?: PropertyValues
) {
  const engine = getEngineByQuestionId(question.id)
  if (!engine) {
    // This question doesn't have any rules
    return Promise.resolve()
  }

  let rowIndexes: number[]
  const answerValue = answer && answer.answerValue
  const rowCount = Array.isArray(answerValue) ? answerValue.length : 0
  if (properties) {
    // If updated properties were supplied then check this object to find rows that were updated.
    // We only need to rerun the rules on rows that were updated.
    rowIndexes = getChangedRowIndexes(properties)
  } else {
    // If updated properties were not supplied then run the rules on all rows
    rowIndexes = Array.from(Array(rowCount).keys())
  }

  if (rowIndexes.indexOf(-1) === -1) {
    rowIndexes.unshift(-1)
  }

  const runtimeFacts: RuntimeFacts = {
    engagementTemplateId: engagement.engagementTemplateId,
    engagementId: engagement.id,
    clientId: engagement.clientId,
    rowIndex: -1,
    totalRows: rowCount,
  }
  // Run the rules for this question for each row serially
  return chain(rowIndexes, rowIndex =>
    engine.run({ ...runtimeFacts, rowIndex })
  )
}

/**
 * Check to see if the question expects an answer that is an array of values.
 */
export function answerValueIsArray(question: Question) {
  const schema = question.answerSchema
  return Array.isArray(schema.type)
    ? schema.type.includes('array')
    : schema.type === 'array'
}

// tslint:disable-next-line:no-empty
function returnVoid() {}

function getChangedRowIndexes(properties: PropertyValues): number[] {
  return Object.values(Object.keys(properties).reduce(mapIndexes, {}))
}

function mapIndexes(indexes: { [key: number]: number }, path: string) {
  if (path.charAt(0) === '[') {
    const index = getIndexFromPath(path)
    if (!isNaN(index)) {
      indexes[index] = index
    }
  }
  return indexes
}

function getIndexFromPath(path: string) {
  return parseInt(path.substr(1, path.indexOf(']') - 1), 10)
}

import classNames from 'classnames'
import * as React from 'react'
import './anchor.scss'

interface AnchorProps {
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  tooltip?: string
  type?: 'submit' | 'reset' | 'button'
}

export const Anchor: React.SFC<AnchorProps> = ({
  children,
  onClick,
  className,
  tooltip,
  type,
}) => {
  return (
    <button
      className={classNames('simple-link', className)}
      onClick={onClick}
      title={tooltip}
      type={type}
    >
      {children}
    </button>
  )
}

import * as React from 'react'
import {
  IoIosDocument,
  IoIosShareAlt,
  IoMdClose,
  IoMdCreate,
  IoMdHeart,
  IoMdPaperPlane,
} from 'react-icons/io'
import { IconProperties } from './properties'

class IoIcons {
  CarryForward = IoIosShareAlt
  Close = IoMdClose
  Edit = IoMdCreate
  FileText = IoIosDocument
  Heart = IoMdHeart
  PaperAirplaine = IoMdPaperPlane
}

const lookup = new IoIcons()

function ioIconHOC (iconId: keyof IoIcons) {
  const Component = lookup[iconId]

  const innerClass: React.SFC<IconProperties> = ({
    className,
    onClick,
    tooltip,
  }) => {
    return (
      <Component
        className={className}
        focusable='false'
        onClick={onClick}
        data-tip={tooltip}
      />
    )
  }

  return innerClass
}

export default ioIconHOC

import { ActionType, createAsyncAction, createAction } from 'typesafe-actions'
import {
  Activity,
  Activity as ClientActivity,
  EntityMap,
  IdentityTokenProfile,
} from '../clientModels'
import { Activity as ApiActivity } from '../services/api/apiModels'

export const clearDirtyActivityAction = createAction('CLEAR_DIRTY_ACTIVITY')()

export const getAllActivitiesAction = createAsyncAction(
  'GET_ALL_ACTIVITIES_BEGIN',
  'GET_ALL_ACTIVITIES_DONE',
  'GET_ALL_ACTIVITIES_FAIL'
)<
  number,
  {
    activities: EntityMap<Activity[]>
    engagementId: number
    user?: IdentityTokenProfile
  },
  Error
>()

export const getActivitiesAction = createAsyncAction(
  'GET_ACTIVITIES_BEGIN',
  'GET_ACTIVITIES_DONE',
  'GET_ACTIVITIES_FAIL'
)<
  void,
  {
    activities: Activity[]
    engagementId: number
    questionId: number
    user?: IdentityTokenProfile
  },
  Error
>()

export const deleteActivityAction = createAsyncAction(
  'DELETE_ACTIVITY_BEGIN',
  'DELETE_ACTIVITY_DONE',
  'DELETE_ACTIVITY_FAIL'
)<number, number, Error>()

export const saveActivityAction = createAsyncAction(
  'SAVE_ACTIVITY_BEGIN',
  'SAVE_ACTIVITY_DONE',
  'SAVE_ACTIVITY_FAIL'
)<ApiActivity, ClientActivity, Error>()

export const setFileUploadIsDirtyAction = createAction(
  'SET_FILE_UPLOAD_IS_DIRTY'
)<boolean>()

export type ActivityActions =
  | ActionType<typeof clearDirtyActivityAction>
  | ActionType<typeof deleteActivityAction.failure>
  | ActionType<typeof deleteActivityAction.request>
  | ActionType<typeof deleteActivityAction.success>
  | ActionType<typeof getActivitiesAction.failure>
  | ActionType<typeof getActivitiesAction.request>
  | ActionType<typeof getActivitiesAction.success>
  | ActionType<typeof getAllActivitiesAction.failure>
  | ActionType<typeof getAllActivitiesAction.request>
  | ActionType<typeof getAllActivitiesAction.success>
  | ActionType<typeof saveActivityAction.failure>
  | ActionType<typeof saveActivityAction.request>
  | ActionType<typeof saveActivityAction.success>
  | ActionType<typeof setFileUploadIsDirtyAction>

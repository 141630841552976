import * as opener from '../components/opener/openerActions'
import { ActivityActions } from './activityActions'
import * as activity from './activityThunks'
import { AnswerActions } from './answerActions'
import * as answer from './answerThunks'
import { AsyncOperationsActions } from './asyncOperationsActions'
import * as async from './asyncOperationsThunk'
import * as auth from './authenticationActions'
import { ClientActions } from './clientActions'
import * as client from './clientThunks'
import * as clientSearch from './clientSearchActions'
import * as clientAccessRequestSearch from './clientAccessRequestSearchActions'
import { CommentActions } from './commentActions'
import * as comment from './commentThunks'
import * as documentTitles from './documentTitleThunks'
import { EngagementActions } from './engagementActions'
import * as engagementQuestion from './engagementQuestionActions'
import { EngagementReportActions } from './engagementReportActions'
import * as engagementReport from './engagementReportThunk'
import { DefaultEngagementSectionsQuestionsAction } from './engagementSectionsQuestionsActions'
import * as engagementSectionsQuestions from './engagementSectionsQuestionsThunks'
import { EngagementTemplateActions } from './engagementTemplateActions'
import * as engagementTemplate from './engagementTemplateThunks'
import * as engagement from './engagementThunks'
import * as engagementUploadHistory from './engagementUploadHistoryActions'
import { EntityActions } from './entityActions'
import * as etlActions from './etlActions'
import * as etlThunks from './etlThunks'
import * as entity from './entityThunks'
import * as error from './errorActions'
import { FileActions } from './fileActions'
import * as file from './fileThunks'
import { FlagActions } from './flagsActions'
import * as flag from './flagsThunks'
import { GeneralActions } from './generalActions'
import * as http from './httpActions'
import * as inputProcessActions from './inputProcessActions'
import * as inputProcessThunks from './inputProcessThunks'
import { K1Actions } from './k1Actions'
import * as k1Thunks from './k1Thunks'
import * as lastNav from './lastnavActions'
import * as phase from './phaseActions'
import { ReviewActions } from './reviewActions'
import * as review from './reviewThunks'
import { RulesActions } from './rulesActions'
import * as rule from './rulesThunks'
import { SearchActions } from './searchActions'
import * as search from './searchThunks'
import * as selection from './selectionActions'
import { TaskActions } from './taskActions'
import * as task from './taskThunk'
import { DocumentTitleActions } from './documentTitleActions'
import { AdminCenterActions } from '../oidc-auth/adminCenterActions'
import * as adminCenter from '../oidc-auth/adminCenterThunk'
import * as signalR from './signalRActions'
import * as dashboardEngagements from './dashboardEngagementsActions'
import * as callToAction from '../components/callToAction/callToActionModule'
import * as confirmModal from '../actions/confirmModalActions'
import * as permission from './permissionActions'
import * as bannerFilterActions from './bannerFilterActions'
import * as duoExport from './duoExportThunk'

// ActionType is not exported here because it causes a circular reference and Webpack fails to load the module at run time.

export type TsaHubAction =
  | ActivityActions
  | AnswerActions
  | AsyncOperationsActions
  | auth.AuthActions
  | AdminCenterActions
  | callToAction.CallToActionActions
  | ClientActions
  | clientSearch.ClientSearchActions
  | clientAccessRequestSearch.ClientAccessRequestSearchActions
  | CommentActions
  | confirmModal.ConfirmModalActions
  | inputProcessActions.InputProcessActions
  | DefaultEngagementSectionsQuestionsAction
  | EngagementActions
  | engagementQuestion.EngagementQuestionActions
  | EngagementReportActions
  | EngagementTemplateActions
  | etlActions.EtlActions
  | DocumentTitleActions
  | dashboardEngagements.DashboardEngagementActions
  | error.ErrorActions
  | FileActions
  | FlagActions
  | GeneralActions
  | http.HttpActions
  | K1Actions
  | lastNav.LastNavActions
  | opener.OpenerActions
  | permission.PermissionActions
  | phase.PhaseActions
  | ReviewActions
  | RulesActions
  | SearchActions
  | selection.SelectionActions
  | TaskActions
  | EntityActions
  | signalR.SignalRActions
  | bannerFilterActions.BannerFilterActions

export const actions = {
  activity,
  answer,
  async,
  auth,
  adminCenter,
  callToAction,
  client,
  clientSearch,
  clientAccessRequestSearch,
  comment,
  confirmModal,
  inputProcess: {
    ...inputProcessActions,
    ...inputProcessThunks,
  },
  dashboardEngagements,
  documentTitles,
  duoExport,
  engagement,
  engagementQuestion,
  engagementReport,
  engagementSectionsQuestions,
  engagementTemplate,
  engagementUploadHistory,
  entity,
  error,
  etl: {
    ...etlActions,
    ...etlThunks,
  },
  file,
  flag,
  http,
  k1Thunks,
  lastNav,
  opener,
  phase,
  review,
  rule,
  search,
  selection,
  signalR,
  task,
}

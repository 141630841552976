import { createReducer } from 'typesafe-actions'
import {
  allEntitiesAction,
  entityAction,
  EntityActions,
} from '../actions/entityActions'
import { EntityGroup, EntityMap } from '../clientModels'

export type EntityGroupsState = EntityMap<EntityGroup>

export const entityGroupReducer = createReducer<
  EntityGroupsState,
  EntityActions
>({})
  .handleAction(allEntitiesAction.success, (_, action) => {
    return action.payload || {}
  })
  .handleAction(entityAction.success, (state, action) => {
    return { ...state, [action.payload.id]: action.payload }
  })

import { History } from 'history'
import * as React from 'react'
import { connect } from 'react-redux'
import { match } from 'react-router'
import { actions } from '../../actions/index'
import { Engagement } from '../../clientModels'
import { ensureNumber } from '../../guards'
import { AppState, TsaDispatch } from '../../store'
import { close } from '../opener/openerActions'
import { joinPaths, RelativeLink } from '../relativeLink'
import Saving from '../saving/index'
import './documentHeader.scss'
import './documentLibrary.scss'
import {
  DocumentFilterOptions,
  DocumentFilters,
  DocumentSortOptions,
  DocumentSorts,
} from './misc'
import { FilterSortDropdown } from '../filterSortDropdown'

interface RouteParameters {
  engagementId: string
}

interface OwnProperties {
  match: match<RouteParameters>
  history: History
}

interface MappedProperties {
  engagement?: Engagement
  selectedFilter?: string | number
  selectedSort?: string | number
}

interface DispatchProperties {
  closeAll: () => void
  selectFilter: (filter: string | number) => void
  selectSort: (sort: string | number) => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: OwnProperties
): MappedProperties => {
  return {
    engagement:
      state.engagements[ensureNumber(ownProps.match.params.engagementId)],
    selectedFilter: state.selection.selectedDocumentFilter,
    selectedSort: state.selection.selectedDocumentSort,
  }
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  ownProps: OwnProperties
): DispatchProperties => ({
  closeAll: () => dispatch(close('DocumentLibrary')),
  selectFilter: (filter: keyof DocumentFilters) =>
    dispatch(actions.selection.selectionSelectDocumentFilterAction({ filter })),
  selectSort: (sort: keyof DocumentSorts) =>
    dispatch(actions.selection.selectionSelectDocumentSortAction({ sort })),
})

type Properties = OwnProperties & MappedProperties & DispatchProperties

class DocumentHeader extends React.Component<Properties> {
  constructor(props: Properties) {
    super(props)

    this.onFilterChange = this.onFilterChange.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
  }

  onFilterChange(value: keyof DocumentFilters) {
    const {
      props: {
        match: {
          params: { engagementId },
          url,
        },
      },
    } = this
    if (this.props.selectedFilter !== value) {
      this.props.selectFilter(value)
    }
    if (value === 'NeedUpload') {
      this.onSortChange('SectionTask')
    }

    this.props.history.replace(
      joinPaths(url, `engagements/${engagementId}/documents`)
    )
  }

  onSortChange(value: keyof DocumentSorts) {
    const {
      props: {
        match: {
          params: { engagementId },
          url,
        },
      },
    } = this
    if (this.props.selectedSort !== value) {
      this.props.selectSort(value)
    }
    this.props.history.replace(
      joinPaths(url, `engagements/${engagementId}/documents`)
    )
  }

  render() {
    const {
      onFilterChange,
      onSortChange,
      props: {
        selectedFilter,
        selectedSort,
        match: {
          params: { engagementId },
        },
      },
    } = this
    return (
      <div className='engagement-header'>
        <div className='document-header flex-wrap'>
          <div>
            <RelativeLink to={`engagements/${engagementId}/documents`}>
              Document Library
            </RelativeLink>
            <Saving showLastSaved={true} />
          </div>
          <div className='filter ml-0 ml-lg-auto'>
            <FilterSortDropdown
              options={DocumentFilterOptions}
              value={selectedFilter}
              onSelect={onFilterChange}
              label='Filter By:'
            />
          </div>
          <div className='d-none d-lg-block'>
            <FilterSortDropdown
              options={DocumentSortOptions}
              value={selectedSort}
              onSelect={onSortChange}
              label='Sort By:'
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentHeader)

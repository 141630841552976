import { User, UserManager } from 'oidc-client'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { connect } from 'react-redux'
import { actions } from '../actions'
import { updateAssignments } from '../actions/generalThunks'
import { getAllLastNav } from '../actions/lastnavThunks'
import {
  AuthReactContext,
  IdentityTokenProfile,
  OrganizationClaim,
} from '../clientModels'
import { IdleTimer } from '../components/idleTimer/idleTimer'
import { AppErrorDialog, WarningDialog } from '../components/modals'
import { ensureNumber } from '../guards'
import { handleLeavePage } from '../services/eventListeners'
import { PrefixedStorage } from '../services/storage'
import { TsaDispatch } from '../store'
import { createUserManager } from './oidcHelper'
import * as http from '../services/http'
import { 
          REACT_APP_AUTHORIZATIONSERVICEURL,
          REACT_APP_GLOBALIDLETIMEOUTINSECS,
          REACT_APP_GLOBALMODALTIMEOUTINSECS,
          REACT_APP_INTERNALUSERGROUPS,
          REACT_APP_PINGAUTHSCOPES
       } from '../envVariables'

/** Returns configuration variables from the window.config. */
const getConfigVars = () => {

  return {
    IDLE_TIMEOUT:
      ensureNumber(REACT_APP_GLOBALIDLETIMEOUTINSECS) * 1000 ||
      14 * 60 * 1000, // Defaults to 14 min
    MODAL_TIMEOUT:
      ensureNumber(REACT_APP_GLOBALMODALTIMEOUTINSECS) * 1000 ||
      60 * 1000, // Defaults to 60 sec
    INTERNAL_USERS_GROUPS_VALUE:
      (REACT_APP_INTERNALUSERGROUPS) || 'Internal Users',
  }
}

const checkIfExternal = (userGroupsClaim: string | string[]) => {
  var allGroups = getConfigVars().INTERNAL_USERS_GROUPS_VALUE.split('|')

  if (Array.isArray(userGroupsClaim)) {
    var result = true
    for (var i in userGroupsClaim) {
      var claim = userGroupsClaim[i]
      var isInternal = allGroups.indexOf(claim) !== -1
      if (isInternal) {
        result = false
        break
      }
    }
    return result
  } else {
    return allGroups.indexOf(userGroupsClaim) === -1
  }
}

interface OidcLoginManagerExternalProps {
  clientId: string
  silentRefreshOffsetTime: number
  redirectUri?: string
  autoLogin?: boolean
  authority: string
}

interface OidcLoginManagerMappedProps {
  // tslint:disable-next-line:no-any - we don't know what the data will look like on the store
  dispatch: TsaDispatch
}

type OidcLoginManagerProps = OidcLoginManagerExternalProps &
  OidcLoginManagerMappedProps

interface OidcLoginManagerState {
  oidcAuthContext: UserManager
  user?: IdentityTokenProfile
  showTimeoutWarning?: boolean
  // tslint:disable-next-line:no-any - conflict with Storybook
  dialogTimer?: any
  failedUpdateAssignments?: boolean | null
  failedAuthentication?: boolean | null
  failedAuthenticationMessage?: string
  error?: any
}

export interface OidcLoginManagerContext {
  oidcAuthContext: UserManager
}

export class OidcLoginManager extends React.Component<
  OidcLoginManagerProps,
  OidcLoginManagerState
> {
  static defaultProps = {
    autoLogin: false,
    redirectUri: window.location.origin,
  }

  static childContextTypes = {
    logOut: PropTypes.func,
  }

  private idleTimer = React.createRef<IdleTimer>()
  private storage = new PrefixedStorage<
    'redirect-url' | 'timeout' | 'user_organization_claims'
  >('tsa', sessionStorage)

  constructor(props: OidcLoginManagerProps) {
    super(props)
    this.idleTimer = React.createRef<IdleTimer>()
    this.state = {
      oidcAuthContext: this.createAuthContext(props),
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.checkAuthentication()
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: OidcLoginManagerProps) {
    const props = this.props

    if (
      props.autoLogin === nextProps.autoLogin &&
      props.clientId === nextProps.clientId &&
      props.authority === nextProps.authority
    ) {
      return
    }

    const nextState = { oidcAuthContext: this.createAuthContext(nextProps) }
    this.setState(nextState, this.checkAuthentication)
  }

  async getUserInfo(): Promise<OrganizationClaim[]> {
    try {
      const storedClaims = this.storage.getItem('user_organization_claims')
      if (storedClaims) {
        return JSON.parse(storedClaims) as OrganizationClaim[]
      }

      if (REACT_APP_AUTHORIZATIONSERVICEURL) {
        throw new Error('Could not find authorization url')
      }

      var userClaims = await http.get(
        `${REACT_APP_AUTHORIZATIONSERVICEURL}/openid/userinfo`
      )

      const organizationClaims = Object.keys(userClaims).map(
        (key: string): OrganizationClaim => {
          const claims = userClaims[key]
          return {
            organizationId: key,
            claims: claims,
          }
        }
      )

      this.storage.setItem(
        'user_organization_claims',
        JSON.stringify(organizationClaims)
      )

      return organizationClaims
    } catch {
      return []
    }
  }

  createAuthContext(props: OidcLoginManagerProps) {
    const scopes = (
      (REACT_APP_PINGAUTHSCOPES) ||
      'openid profile'
    )
      .trim()
      .split(' ')

    sessionStorage.removeItem('user_organization_claims')

    return createUserManager({
      client_id: props.clientId,
      redirect_uri: props.redirectUri,
      authority: props.authority,
      response_type: 'code',
      scope: scopes.join(' '),
      automaticSilentRenew: true,
      accessTokenExpiringNotificationTime: props.silentRefreshOffsetTime, // 7140 // 7200 - 60 = 7140  is two hours set to every 1 minute
      // metadata: {
      //   // authorization_endpoint:
      //   //   'https://rsm.oktapreview.com/oauth2/v1/authorize',
      //   userinfo_endpoint: 'https://localhost:5021/openid/userinfo',
      // },
    })
  }
  useQuery() {
    return new URLSearchParams(window.location.search)
  }

  checkAuthentication = async () => {
    const { oidcAuthContext } = this.state
    const { dispatch } = this.props

    const isCallBackCode = window.location.href.includes('code')
    //error still comes as hash
    const isError = window.location.href.includes('error')
    // call back
    const testUser = await oidcAuthContext.getUser()
    const isSilentRenew = testUser !== null
    // console.log(
    //   'isCallBackCode: ' + isCallBackCode + ' isSilentRenew' + isSilentRenew
    // )
    // console.log('href: ' + window.location.href)
    // console.log('hash: ' + window.location.hash)

    let user: IdentityTokenProfile | undefined
    let failedAuthentication: boolean | undefined
    let failedAuthenticationMessage: string

    if (isCallBackCode) {
      if (isSilentRenew) {
        // just update user and session storage token.
        await oidcAuthContext.signinSilentCallback()
        return
      }
      const oidcUserInfo: User = await oidcAuthContext.signinRedirectCallback()
      let failedUpdateAssignments: boolean | undefined

      //
      if (oidcUserInfo) {
        // parse out the access token to get the user info
        user = this.getUserProfile(oidcUserInfo)

        // over ride to the user expires at
        user.exp = oidcUserInfo.expires_at

        try {
          await dispatch(updateAssignments(user, true))
        } catch (error) {
          failedUpdateAssignments = true
          this.setState({ error })
        }
        // get all lastnav settings
        try {
          dispatch(getAllLastNav())
        } catch {}
      }
      const accessToken = oidcUserInfo.access_token
      dispatch(actions.auth.authGetUserAction({ user, accessToken }))
      this.setState({ user, failedUpdateAssignments })
      // get redirect url from session storage
      const redirectUrl = this.storage.getItem('redirect-url')

      if (redirectUrl) {
        sessionStorage.removeItem('redirect-url')
        window.location.replace(redirectUrl)
      } else {
        // just clear out the token
        window.location.hash = ''
      }
    } else if (isError) {
      // error
      const errorMessage = this.useQuery().get('error_description') + '' // ensure a string.
      failedAuthentication = true
      failedAuthenticationMessage = errorMessage
      this.setState({
        failedAuthentication,
        failedAuthenticationMessage,
      })

      dispatch(actions.auth.authGetUserFailAction({ errorMessage }))
    } else {
      // attempt to read up previous user
      const previousUser = await oidcAuthContext.getUser()
      if (previousUser) {
        user = this.getUserProfile(previousUser)

        let failedUpdateAssignments: boolean | undefined

        try {
          await dispatch(updateAssignments(user, true))
        } catch (error) {
          failedUpdateAssignments = true
          this.setState({ error })
        }
        try {
          dispatch(getAllLastNav())
        } catch {}

        const accessToken = previousUser.access_token
        dispatch(actions.auth.authGetUserAction({ user, accessToken }))
        this.setState({
          user,
          showTimeoutWarning: false,
          failedUpdateAssignments,
        })
      } else {
        // no previous user.
        // if we have a path then we set redirect url else No redirect url.
        if (window.location.pathname !== '/') {
          this.storage.setItem('redirect-url', window.location.href)
        }
        oidcAuthContext.signinRedirect()
      }
    }
  }

  getUserProfile = (user: User): IdentityTokenProfile => {
    const { id_token, access_token, profile } = user
    const parsedIdToken = this.getTokenInfo(id_token)
    const parsedAccessToken = this.getTokenInfo(access_token)

    const userProfile: IdentityTokenProfile = {
      email: parsedAccessToken.sub,
      firstName: profile.given_name || 'NONE',
      lastName: profile.family_name || 'NONE',
      organization: {
        id: parsedIdToken.rsmOrgId,
        name: parsedIdToken.rsmPersonOrgName,
      },
      name: profile.given_name + ', ' + profile.family_name,
      uniqueId: parsedIdToken.rsmuid,
      exp: parsedAccessToken.exp,
      isExternal: checkIfExternal(parsedAccessToken.groups),
      applicationId: [], // this.getArray(parsedAccessToken.rsmApplicationId),
      clientId: [], //this.getArray(parsedAccessToken.rsmOrgId),
    }

    return userProfile
  }

  getArray = (item?: string | string[]) => {
    if (!item) {
      return []
    }
    if (Array.isArray(item)) {
      return item
    }
    return [item]
  }

  // tslint:disable-next-line:no-any
  getTokenInfo(token: string): any {
    const splitToken = token.split('.')
    let payload = splitToken[1].replace('-', '+').replace('_', '/')
    payload = payload.padEnd(
      payload.length + ((4 - (payload.length % 4)) % 4),
      '='
    )
    return JSON.parse(window.atob(payload))
  }

  getChildContext(): AuthReactContext {
    return {
      logOut: this.logOut.bind(this),
    }
  }

  logOut(isIdle?: boolean): void {
    const {
      state: { oidcAuthContext },
    } = this
    // Uncommnet this code  if you want the isIdle timeout logout to redirect to the app and cause the login dialog to be
    //launched. You will need to have the proper logout_redirect_uris set up in OKTA.
    // currenlty only https://rsmus.com is the only logout redirect setup
    // Business discussed and they do not want the idle time out to lauch a login. 12/08/2022
    // They want it to continue to redirect to https://rsmus.com for both idle and user click logout.
    // const targetResource = isIdle
    //   ? window.location.origin
    //   : this.config
    //   ? this.config['Global.LogoutRedirectUrl']
    //   : 'https://rsmus.com/'
    const targetResource = 'https://rsmus.com' // always this no matter what mode or what environment.

    oidcAuthContext.getUser().then(user => {
      if (user) {
        const { id_token } = user
        oidcAuthContext.removeUser()
        window.location.assign(
          `${this.props.authority}/v1/logout?id_token_hint=${id_token}&post_logout_redirect_uri=${targetResource}`
        )
      }
    })
  }

  handleOnIdle = () => {
    var timeoutConfig = getConfigVars()

    this.setState({
      showTimeoutWarning: true,
      dialogTimer: setTimeout(
        this.onIdleWarningTimeout,
        timeoutConfig.MODAL_TIMEOUT
      ),
    })
  }

  handleOnReset = () => {
    this.setState(state => {
      if (state.dialogTimer) {
        clearTimeout(state.dialogTimer)
        return {
          showTimeoutWarning: false,
          dialogTimer: undefined,
        }
      }
      return {}
    })
  }

  onIdleWarningTimeout = () => {
    window.removeEventListener('beforeunload', handleLeavePage)
    this.logOut(true)
  }

  dismiss = () => {
    if (this.idleTimer.current) {
      this.idleTimer.current.reset()
    }
  }

  handleRefresh = () => {
    window.location.reload()
  }

  render() {
    const {
      user,
      showTimeoutWarning,
      failedUpdateAssignments,
      failedAuthentication,
      failedAuthenticationMessage,
      error,
    } = this.state
    const { children } = this.props
    const { handleRefresh } = this

    let errBodyMessage = ''

    if (error) {
      try {
        // .net Exception
        errBodyMessage = JSON.parse(error.body).Message
      } catch {
        errBodyMessage =
          error.body ||
          'Error does not have more information. Please contact RSM Support.'
      }

      const errOutput = `${error.message}: ${errBodyMessage}`
      return (
        <AppErrorDialog
          message1='The following error occurred on the server.'
          message2={errOutput}
          context=''
          title='Server Error'
          buttons={[
            { autoFocus: true, label: 'Try Again', onClick: handleRefresh },
          ]}
          details={undefined}
        />
      )
    }

    if (failedUpdateAssignments) {
      return (
        <AppErrorDialog
          message1='There was a problem communicating with the server.'
          message2='Please try again in a few moments.'
          context=''
          title='Server Error'
          buttons={[
            { autoFocus: true, label: 'Try Again', onClick: handleRefresh },
          ]}
          details={undefined}
        />
      )
    }
    if (failedAuthentication) {
      return (
        <AppErrorDialog
          message1={failedAuthenticationMessage}
          message2='Contact customer support or try again in a few moments.'
          context=''
          title='Authentication Error'
          buttons={[
            { autoFocus: true, label: 'Try Again', onClick: handleRefresh },
          ]}
          details={undefined}
        />
      )
    }

    if (!user) {
      return null
    }

    if (!user.isExternal) {
      return children || null
    }

    var timeoutConfig = getConfigVars()

    return (
      <IdleTimer
        ref={this.idleTimer}
        timeout={timeoutConfig.IDLE_TIMEOUT}
        onIdle={this.handleOnIdle}
        onReset={this.handleOnReset}
      >
        {children}
        {showTimeoutWarning && (
          <WarningDialog
            title=''
            info='Session Timeout Approaching'
            onClose={this.dismiss}
            onClickPrimary={this.dismiss}
            primaryButtonText='Continue Session'
          />
        )}
      </IdleTimer>
    )
  }
}

export default connect()(OidcLoginManager)

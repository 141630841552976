// tslint:disable:no-any - required for third party api
import { actions } from '../actions/index'
import { TsaDispatch, TsaThunkAction } from '../store'
import { Guid } from './guid'

export type TrackedAction = (...actionParams: any[]) => TsaThunkAction

export function getTrackingId(
  dispatch: TsaDispatch,
  action: TrackedAction,
  ...actionParams: any[]
): string {
  const id = Guid.newGuid()
  if (actionParams.length) {
    dispatch(action(...actionParams, id))
  } else {
    dispatch(action(id))
  }
  return id
}

export async function trackGet<T>(
  apiCall: (...actionParams: any[]) => Promise<T>,
  dispatch: TsaDispatch,
  id?: string,
  ...actionParams: any[]
): Promise<T> {
  if (id) {
    dispatch(actions.http.httpBeginAction({ id }))
  }

  let results: T

  try {
    results = await apiCall(...actionParams)
  } catch (ex) {
    if (id) {
      dispatch(actions.http.httpEndAction({ id }))
      dispatch(actions.http.httpSaveEndAction({ id }))
    }
    throw ex
  }

  if (id) {
    dispatch(actions.http.httpEndAction({ id }))
  }

  return results
}

export async function trackSave<T>(
  apiCall: (...actionParams: any[]) => Promise<T>,
  dispatch: TsaDispatch,
  id?: string,
  ...actionParams: any[]
): Promise<T> {
  if (id) {
    dispatch(actions.http.httpSaveBeginAction({ id }))
  }

  let results: T

  try {
    results = await apiCall(...actionParams)
  } catch (ex) {
    if (id) {
      dispatch(actions.http.httpSaveEndAction({ id }))
    }
    throw ex
  }

  if (id) {
    dispatch(actions.http.httpSaveEndAction({ id }))
  }

  return results
}

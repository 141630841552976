import classNames from 'classnames'
import * as React from 'react'
import { isNull, isObject } from '../../guards'
import { renderComplexAnswerLastYear } from '../../services/question/questionHelper'
import { Address } from '../address/index'
import { Alerts } from '../forms/alerts/index'
import { FormFieldProps } from '../forms/formComponentsInterfaces'
import { CheckBoxesField, hasError } from '../forms/index'

class AddressCheck extends React.Component<FormFieldProps> {
  // tslint:disable-next-line:no-any - value is not typed
  static formatValueLastYear = (value: any): string => {
    return renderComplexAnswerLastYear(
      value.address.street1,
      value.address.street2,
      value.address.city,
      value.address.state,
      value.address.postalCode,
      value.address.country,
      value.address.phone
    )
  }

  render () {
    const props = this.props
    const { messages, showAlerts } = props

    if (!isNull(props.value) && !isObject(props.value)) {
      throw new Error(
        `AddressCheck expects an object value but received ${JSON.stringify(
          props.value
        )}.`
      )
    }

    const hasErrors = hasError(messages, ['address', 'codes'])

    return (
      <div
        className={classNames('form-group', {
          'is-valid': !hasErrors,
          'is-invalid': hasErrors,
        })}
      >
        <Address {...props} path='address' />
        <CheckBoxesField
          {...{
            ...props,
            className: 'textboxcheckbox-check',
            path: 'codes',
            skipRules: true,
          }}
        />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

export default AddressCheck

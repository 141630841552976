import { throttle } from 'lodash'
import * as React from 'react'
import { PrefixedStorage } from '../../services/storage'

type IdleTimerStorageKeys = 'last_active'

const THROTTLE_TIME = 10 * 1000 // 10sec

interface IdleTimerProps {
  onIdle: () => void
  onReset: () => void
  element: Element
  events: string[]
  capture: boolean
  passive: boolean
  timeout: number
}

interface IdleTimerState {
  idle: boolean
  lastActive: string
  pageX: number
  pageY: number
}

export class IdleTimer extends React.Component<IdleTimerProps, IdleTimerState> {
  static defaultProps = {
    capture: true,
    passive: true,
    element: window.document,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mouseWheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
  }

  state = {
    idle: false,
    lastActive: '',
    pageX: 0,
    pageY: 0,
  }

  // tslint:disable-next-line:no-any - conflict with Storybook
  private timeout: any | null = null
  private storage = new PrefixedStorage<IdleTimerStorageKeys>('idle')

  constructor (props: IdleTimerProps) {
    super(props)
    this.setActive = throttle(this.setActive.bind(this), THROTTLE_TIME)
  }

  componentDidMount () {
    const { events, element, capture, passive } = this.props
    this.storage.onChange('last_active', this.handleLastActiveChanged)
    if (element) {
      events.forEach(event => {
        element.addEventListener(event, this.handleEvent, { capture, passive })
      })
    }
    this.reset()
  }

  componentWillUnmount () {
    const { events, element, capture } = this.props
    this.storage.offChange('last_active', this.handleLastActiveChanged)
    if (element) {
      events.forEach(event => {
        element.removeEventListener(event, this.handleEvent, { capture })
      })
    }
  }

  render () {
    return this.props.children || null
  }

  reset = () => {
    const { timeout, onReset } = this.props
    this.clearTimeout()

    this.setState({ idle: false }, () => {
      onReset()
      this.timeout = setTimeout(this.handleTimeout, timeout)
    })
  }

  private clearTimeout () {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  private handleTimeout = () => {
    this.timeout = null
    this.setState({ idle: true }, () => {
      this.props.onIdle()
    })
  }

  private setActive () {
    this.reset()

    const now = +new Date() + ''

    this.setState(
      {
        idle: false,
        lastActive: now,
      },
      () => {
        this.storage.setItem('last_active', now)
      }
    )
  }

  private handleEvent = () => {
    // Once we are idle the only way to bet back to active is
    // for something to call this.reset()
    if (this.state.idle) {
      return
    }
    this.setActive()
  }

  private handleLastActiveChanged = (e: StorageEvent) => {
    if (e.newValue !== this.state.lastActive) {
      this.props.onReset()
      this.reset()
    }
  }
}

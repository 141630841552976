import classNames from 'classnames'
import * as React from 'react'
import { Activity } from '../../clientModels'
import { CreateCarryForwardAllCommentsActivity } from '../../services/activityFactory'
import { Anchor } from '../anchor'
import { InlineComment } from '../comment'
import { Alert } from '../forms'
import Icon from '../icon/icon'

interface Properties {
  engagementId: number
  questionId: number
  clientId: number
  carryForwardAllId?: number
  saveActivity: (activity: Activity) => void
  deleteActivity: (activityId: number) => void
}

interface State {
  displayCommentBox: boolean
  displayError: boolean
}

export class ActivityMarkAllCarryForward extends React.Component<Properties> {
  state: State = {
    displayCommentBox: false,
    displayError: false,
  }

  constructor(props: Properties) {
    super(props)

    this.closeComment = this.closeComment.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.handleCommentSave = this.handleCommentSave.bind(this)
    this.openCommentCarryForward = this.openCommentCarryForward.bind(this)
  }

  closeComment() {
    this.setState({
      displayCommentBox: false,
      displayError: false,
    })
  }

  handleCommentChange(commentValue: string) {
    this.setState({
      displayError: !commentValue,
    })
  }

  handleCommentSave(commentValue: string) {
    const {
      closeComment,
      props: { engagementId, clientId, questionId, saveActivity },
    } = this

    if (!commentValue) {
      this.setState({ displayError: true })
      return
    }

    const carryForwardAllActivity = CreateCarryForwardAllCommentsActivity(
      engagementId,
      clientId,
      questionId,
      commentValue,
      true
    )
    saveActivity(carryForwardAllActivity)

    closeComment()
  }

  openCommentCarryForward() {
    const {
      props: {
        carryForwardAllId,
        saveActivity,
        engagementId,
        clientId,
        questionId,
      },
    } = this
    if (carryForwardAllId) {
      const carryForwardAllActivity = CreateCarryForwardAllCommentsActivity(
        engagementId,
        clientId,
        questionId,
        '',
        false
      )
      saveActivity(carryForwardAllActivity)
    } else {
      this.setState({ displayCommentBox: true })
    }
  }

  render() {
    const {
      closeComment,
      handleCommentChange,
      handleCommentSave,
      openCommentCarryForward,
      props: { carryForwardAllId },
      state: { displayCommentBox, displayError },
    } = this

    const hasCarryForwardAllComments = !!carryForwardAllId
    return (
      <div className={classNames('activity-carry-forward-all')}>
        <div className={classNames('activity-list-group-gutter')} />
        <div className={'activity-carry-forward-action'}>
          <div className={'activity-carry-forward-link'}>
            {displayCommentBox && (
              <div>
                <Anchor onClick={closeComment}>cancel</Anchor>
              </div>
            )}
            <div className={classNames('activity-carry-forward-all-content')}>
              <Anchor
                onClick={
                  !displayCommentBox ? openCommentCarryForward : undefined
                }
              >
                <Icon
                  icon='carryForwardAll'
                  className={classNames({ active: hasCarryForwardAllComments })}
                />
                {hasCarryForwardAllComments ? 'remove ' : ''}carry all comments
                forward
              </Anchor>
            </div>
          </div>
          <div>
            {displayCommentBox && (
              <InlineComment
                onCancel={closeComment}
                onChange={handleCommentChange}
                onSave={handleCommentSave}
                placeholder={'Comment Required'}
              />
            )}
            {displayCommentBox && displayError && (
              <Alert
                messages='A comment is required to carry all comments forward.'
                severity='error'
                type='msg'
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

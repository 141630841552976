import { usePermissionManager } from '../../hooks'

interface ExternalContentProps {
  children: JSX.Element | undefined | null | false
}

function ExternalContent({ children }: ExternalContentProps) {
  const { isExternalUser } = usePermissionManager()

  if (!children) {
    children = null
  }

  return !isExternalUser ? null : (children as JSX.Element | null)
}

export default ExternalContent

import { difference, pullAllWith, union } from 'lodash'
import { getType, ActionType } from 'typesafe-actions'
import {
  answerGetAction,
  answerSaveAction,
  answerSetNotApplicableAction,
  answerUpdateAction,
  answerUpdatePartialAnswerAction,
} from '../actions/answerActions'
import {
  copyLastYearAnswersAction,
  getEngagementAction,
  getEngagementQuestionsDoneAction,
} from '../actions/engagementActions'
import { acknowledgeCriticalErrorAction } from '../actions/engagementQuestionActions'
import {
  DefaultEngagementSectionsQuestionsAction,
  defaultEngagementQuestionsAction,
} from '../actions/engagementSectionsQuestionsActions'
import { flagsToggleFlaggedAction } from '../actions/flagsActions'
import { TsaHubAction } from '../actions/index'
import { normalizeEngagementQuestionArray } from '../actions/normalization'
import { reviewQuestionAction } from '../actions/reviewActions'
import {
  EngagementQuestion,
  EngagementQuestionMap,
  IEngagementQuestionAnswer,
  Message,
} from '../clientModels'
import { RoleCode } from '../enums'
import { sortByDisplayOrder } from '../sorting'
import {
  getDefaultMap,
  getEngagementQuestion,
  merge,
  partialUpdate,
  setDirty,
  setPristine,
  updateEngagementQuestion,
  updateEngagementQuestionVersion,
} from './engagementQuestionsHelpers'
import {
  ruleSetQuestionEnabledAction,
  ruleSetQuestionVisiblityAction,
  ruleSetDocsRequiredAction,
  ruleSetDocsOptionalAction,
  rulesClearDocsRequiredAction,
  rulesClearDocsOptionalAction,
  ruleClearMessageAction,
  ruleClearMessagesAction,
  ruleSetMessageAction,
} from '../actions/rulesActions'

/**
 * An EngagementQuestion holds data related to a question on a particular enagagement.
 */
export interface EngagementQuestionsState {
  [engagementId: string]: EngagementQuestionMap | undefined
  [engagementId: number]: EngagementQuestionMap | undefined
}

const initialState: EngagementQuestionsState = {}

function handleDefaultEngagementSectionsQuestionsAction(
  state: EngagementQuestionsState,
  action: DefaultEngagementSectionsQuestionsAction
): EngagementQuestionsState {
  return {
    ...state,
    [action.payload.engagementId]: getDefaultMap(action),
  }
}

function handleGetEngagementQuestionsDoneAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof getEngagementQuestionsDoneAction>
): EngagementQuestionsState {
  const { engagementQuestions } = normalizeEngagementQuestionArray(
    action.payload.engagementQuestions
  )
  return {
    ...state,
    [action.payload.engagementId]: merge(
      state[action.payload.engagementId],
      engagementQuestions
    ),
  }
}

function handleRuleSetQuestionEnabledAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof ruleSetQuestionEnabledAction>
): EngagementQuestionsState {
  const isEnabled = action.payload.isEnabled
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (
    engagementQuestion &&
    engagementQuestionEnabledChanged(engagementQuestion, isEnabled)
  ) {
    const update: Partial<EngagementQuestion> = {
      isEnabled,
    }
    state = updateEngagementQuestion(state, action.payload, update)
  }
  return state
}

function handleRuleSetQuestionVisibilityAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof ruleSetQuestionVisiblityAction>
): EngagementQuestionsState {
  const isVisible = action.payload.isVisible
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (
    engagementQuestion &&
    engagementQuestionVisibilityChanged(engagementQuestion, isVisible)
  ) {
    const update: Partial<EngagementQuestion> = {
      isVisible,
    }
    if (action.payload.updateMemory) {
      update.isVisibleMemory = isVisible
    }
    state = updateEngagementQuestion(state, action.payload, update)
  }
  return state
}

function handleRuleSetDocsRequiredAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof ruleSetDocsRequiredAction>
): EngagementQuestionsState {
  let requiredDocumentTitleIds = action.payload.requiredDocumentTitleIds
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  // If any of the required document title IDs are missing in the engagement question
  if (
    engagementQuestion &&
    !requiredDocumentTitleIds?.every(id =>
      engagementQuestion.requiredDocumentTitleIds.includes(id)
    )
  ) {
    requiredDocumentTitleIds = union(
      engagementQuestion.requiredDocumentTitleIds,
      requiredDocumentTitleIds
    )
    state = updateEngagementQuestion(state, action.payload, {
      requiredDocumentTitleIds,
    })
  }
  return state
}

function handleRuleSetDocsOptionalAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof ruleSetDocsOptionalAction>
): EngagementQuestionsState {
  let optionalDocumentTitleIds = action.payload.documentTitleIds
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  // If any of the required document title IDs are missing in the engagement question
  if (
    engagementQuestion &&
    !optionalDocumentTitleIds.every(id =>
      engagementQuestion.optionalDocumentTitleIds.includes(id)
    )
  ) {
    optionalDocumentTitleIds = union(
      engagementQuestion.optionalDocumentTitleIds,
      optionalDocumentTitleIds
    )
    state = updateEngagementQuestion(state, action.payload, {
      optionalDocumentTitleIds,
    })
  }
  return state
}

function handleRuleClearDocsRequiredAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof rulesClearDocsRequiredAction>
): EngagementQuestionsState {
  let requiredDocumentTitleIds = action.payload.requiredDocumentTitleIds
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  // If none of the required document title IDs are already in the array
  if (
    engagementQuestion &&
    requiredDocumentTitleIds.some(id =>
      engagementQuestion.requiredDocumentTitleIds.includes(id)
    )
  ) {
    // Then remove them and update the property with a new array
    requiredDocumentTitleIds = difference(
      engagementQuestion.requiredDocumentTitleIds,
      requiredDocumentTitleIds
    )
    state = updateEngagementQuestion(state, action.payload, {
      requiredDocumentTitleIds,
    })
  }
  return state
}

function handleRuleClearDocsOptionalAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof rulesClearDocsOptionalAction>
): EngagementQuestionsState {
  let optionalDocumentTitleIds = action.payload.documentTitleIds
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  // If none of the required document title IDs are already in the array
  if (
    engagementQuestion &&
    optionalDocumentTitleIds.some(id =>
      engagementQuestion.optionalDocumentTitleIds.includes(id)
    )
  ) {
    // Then remove them and update the property with a new array
    optionalDocumentTitleIds = difference(
      engagementQuestion.optionalDocumentTitleIds,
      optionalDocumentTitleIds
    )
    state = updateEngagementQuestion(state, action.payload, {
      optionalDocumentTitleIds,
    })
  }
  return state
}

function handleRuleClearMessageAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof ruleClearMessageAction>
): EngagementQuestionsState {
  const message = action.payload.message
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (engagementQuestion && includesMessage(engagementQuestion, message)) {
    const messages = [
      ...pullAllWith(engagementQuestion.messages, [message], messagesAreEqual),
    ]
    state = updateEngagementQuestion(state, action.payload, { messages })
  }
  return state
}

function handleRuleClearMessagesAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof ruleClearMessagesAction>
): EngagementQuestionsState {
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (engagementQuestion) {
    const { rows } = action.payload
    let messages: Message[]
    if (rows) {
      messages = [...engagementQuestion.messages]
      for (let i = messages.length - 1; i >= 0; --i) {
        const message = messages[i]
        for (const rowIndex of rows) {
          if (message.rowIndex === rowIndex) {
            messages.splice(i, 1)
            break
          }
        }
      }
    } else {
      messages = []
    }
    state = updateEngagementQuestion(state, action.payload, { messages })
  }
  return state
}

function handleRuleSetMessageAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof ruleSetMessageAction>
): EngagementQuestionsState {
  const message = action.payload.message
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (engagementQuestion && !includesMessage(engagementQuestion, message)) {
    const messages = [...engagementQuestion.messages, message].sort(
      sortByDisplayOrder
    )
    state = updateEngagementQuestion(state, action.payload, { messages })
  }
  return state
}
function handleAckCriticalErrorAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof acknowledgeCriticalErrorAction>
): EngagementQuestionsState {
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (engagementQuestion) {
    engagementQuestion.messages.forEach(ackCriticalError)
    const messages = [...engagementQuestion.messages]
    state = updateEngagementQuestion(state, action.payload, { messages })
  }
  return state
}

// #region Actions that used to be handled by answersReducer
function handleToggleFlaggedAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof flagsToggleFlaggedAction>
): EngagementQuestionsState {
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (engagementQuestion) {
    const version = engagementQuestion.clientFlagVersions.flagged + 1
    const updates: Partial<EngagementQuestion> = {
      flagged: !engagementQuestion.flagged,
      clientFlagVersions: {
        ...engagementQuestion.clientFlagVersions,
        flagged: version,
      },
    }
    state = updateEngagementQuestion(state, action.payload, updates)
  }
  return state
}
function handleReviewQuestionAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof reviewQuestionAction>
): EngagementQuestionsState {
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (engagementQuestion) {
    const clientFlagVersions = {
      ...engagementQuestion.clientFlagVersions,
      reviewRoles: { ...engagementQuestion.clientFlagVersions.reviewRoles },
    }
    for (const roleCode of action.payload.roles) {
      clientFlagVersions.reviewRoles[roleCode] =
        (clientFlagVersions.reviewRoles[roleCode] || 0) + 1
      switch (action.payload.action) {
        case 'mark':
          engagementQuestion.reviewRolesComplete.add(roleCode)
          break
        case 'clear':
          engagementQuestion.reviewRolesComplete.delete(roleCode)
          break
        default:
          break
      }
    }
    const updates: Partial<EngagementQuestion> = {
      reviewRolesComplete: engagementQuestion.reviewRolesComplete,
      clientFlagVersions,
    }
    state = updateEngagementQuestion(state, action.payload, updates)
  }
  return state
}

function handleGetAnswerDoneAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof answerGetAction.success>
): EngagementQuestionsState {
  const { answer } = action.payload
  setPristine(answer)
  return updateEngagementQuestion(state, action.payload, answer)
}

function handleSaveAnswerDoneAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof answerSaveAction.success>
): EngagementQuestionsState {
  const { answer } = action.payload
  for (const ans of answer) {
    const engagementsQuestions = state[ans.engagementId] || {}
    const engagementsQuestion = engagementsQuestions[ans.questionId]
    const currentVersion = engagementsQuestion
      ? engagementsQuestion.clientVersion
      : ans.clientVersion

    if (engagementsQuestion) {
      if (
        ans.clientFlagVersions.flagged <
        engagementsQuestion.clientFlagVersions.flagged
      ) {
        ans.flagged = engagementsQuestion.flagged
      }
      if (
        ans.clientFlagVersions.notApplicable <
        engagementsQuestion.clientFlagVersions.notApplicable
      ) {
        ans.notApplicable = engagementsQuestion.notApplicable
      }
      for (const roleCode in RoleCode) {
        const rc: RoleCode = roleCode as RoleCode
        const savedVersion = ans.clientFlagVersions.reviewRoles[rc] || 0
        const clientVersion =
          engagementsQuestion.clientFlagVersions.reviewRoles[rc] || 0
        if (savedVersion < clientVersion) {
          if (
            ans.reviewRolesComplete.has(rc) &&
            !engagementsQuestion.reviewRolesComplete.has(rc)
          ) {
            ans.reviewRolesComplete.delete(rc)
            continue
          }
          if (
            !ans.reviewRolesComplete.has(rc) &&
            engagementsQuestion.reviewRolesComplete.has(rc)
          ) {
            ans.reviewRolesComplete.add(rc)
            continue
          }
        }
      }
    }

    setPristine(ans, currentVersion)
    if (!ans.isDirty) {
      state = updateEngagementQuestion(state, ans, ans)
    } else {
      state = updateEngagementQuestionVersion(state, ans, ans)
    }
  }
  return state
}

function handleGetEngagementDoneAction(
  state: EngagementQuestionsState,
  action:
    | ActionType<typeof getEngagementAction.success>
    | ActionType<typeof copyLastYearAnswersAction.success>
): EngagementQuestionsState {
  const { engagementId, answers } = action.payload
  const merged = merge(state[engagementId], answers) as EngagementQuestionMap

  // retain client versions
  for (const property in answers) {
    const answer = answers[property]
    const target = merged[property]
    if (answer && target) {
      target.clientVersion = answer.clientVersion
    }
  }
  return {
    ...state,
    [engagementId]: merged,
  }
}

function handleUpdateAnswerAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof answerUpdateAction>
): EngagementQuestionsState {
  let engagementQuestion = getEngagementQuestion(state, action.payload)
  if (engagementQuestion) {
    const { value: answerValue, updateDirtyFlag } = action.payload
    state = updateEngagementQuestion(state, action.payload, { answerValue })
    engagementQuestion = getEngagementQuestion(state, action.payload)
    if (updateDirtyFlag && engagementQuestion) {
      setDirty(engagementQuestion)
    }
  }
  return state
}

function handleUpdatePartialAnswerAction(
  state: EngagementQuestionsState,
  action: ActionType<typeof answerUpdatePartialAnswerAction>
): EngagementQuestionsState {
  let engagementQuestion:
    | IEngagementQuestionAnswer
    | undefined = getEngagementQuestion(state, action.payload)
  if (engagementQuestion) {
    const { properties, valueType, updateDirtyFlag } = action.payload
    engagementQuestion = partialUpdate(
      engagementQuestion,
      properties,
      valueType
    )
    state = updateEngagementQuestion(state, action.payload, engagementQuestion)
    engagementQuestion = getEngagementQuestion(state, action.payload)
    if (updateDirtyFlag && engagementQuestion) {
      setDirty(engagementQuestion)
    }
  }
  return state
}

function handleSetNotApplicableAnswer(
  state: EngagementQuestionsState,
  action: ActionType<typeof answerSetNotApplicableAction>
): EngagementQuestionsState {
  const engagementQuestion = getEngagementQuestion(state, action.payload)
  if (engagementQuestion) {
    const { notApplicable } = action.payload
    const version = engagementQuestion.clientFlagVersions.notApplicable + 1
    const updates: Partial<EngagementQuestion> = {
      notApplicable,
      clientFlagVersions: {
        ...engagementQuestion.clientFlagVersions,
        notApplicable: version,
      },
    }
    state = updateEngagementQuestion(state, action.payload, updates)
  }
  return state
}
// #endregion

const reducerLookup: any = {
  [getType(
    defaultEngagementQuestionsAction
  )]: handleDefaultEngagementSectionsQuestionsAction,
  [getType(
    getEngagementQuestionsDoneAction
  )]: handleGetEngagementQuestionsDoneAction,
  [getType(ruleSetQuestionEnabledAction)]: handleRuleSetQuestionEnabledAction,
  [getType(
    ruleSetQuestionVisiblityAction
  )]: handleRuleSetQuestionVisibilityAction,
  [getType(ruleSetDocsOptionalAction)]: handleRuleSetDocsOptionalAction,
  [getType(ruleSetDocsRequiredAction)]: handleRuleSetDocsRequiredAction,
  [getType(rulesClearDocsOptionalAction)]: handleRuleClearDocsOptionalAction,
  [getType(rulesClearDocsRequiredAction)]: handleRuleClearDocsRequiredAction,
  [getType(ruleClearMessageAction)]: handleRuleClearMessageAction,
  [getType(ruleClearMessagesAction)]: handleRuleClearMessagesAction,
  [getType(ruleSetMessageAction)]: handleRuleSetMessageAction,
  [getType(acknowledgeCriticalErrorAction)]: handleAckCriticalErrorAction,
  [getType(flagsToggleFlaggedAction)]: handleToggleFlaggedAction,
  [getType(answerSetNotApplicableAction)]: handleSetNotApplicableAnswer,
  REVIEW_QUESTION: handleReviewQuestionAction,
  [getType(answerGetAction.success)]: handleGetAnswerDoneAction,
  [getType(answerSaveAction.success)]: handleSaveAnswerDoneAction,
  GET_ENGAGEMENT_DONE: handleGetEngagementDoneAction,
  [getType(copyLastYearAnswersAction.success)]: handleGetEngagementDoneAction,
  [getType(answerUpdateAction)]: handleUpdateAnswerAction,
  [getType(answerUpdatePartialAnswerAction)]: handleUpdatePartialAnswerAction,
}

/**
 * This is for the storage of data that is specific to a particular question on an engagement
 */
export function engagementQuestionsReducer(
  state: EngagementQuestionsState = initialState,
  action: TsaHubAction
): EngagementQuestionsState {
  const reducer = reducerLookup[action.type]

  if (reducer) {
    state = reducer(state, action)
  }

  return state
}

function includesMessage(
  engagementQuestion: EngagementQuestion,
  message: Message
) {
  if (!engagementQuestion.messages) {
    return false
  }
  return (
    engagementQuestion.messages.findIndex(m => messagesAreEqual(m, message)) >
    -1
  )
}

function messagesAreEqual(m1: Message, m2: Message) {
  return m1.type === m2.type && m1.path === m2.path
}

/**
 * Set the acknowledged flag for critical errors contained in
 * the messages array.
 */
export function ackCriticalError(message: Message) {
  if (message.severity === 'critical') {
    message.acknowledged = true
  }
}

function engagementQuestionVisibilityChanged(
  engagementQuestion: EngagementQuestion | undefined,
  isVisible: boolean
) {
  return (
    !!engagementQuestion &&
    (engagementQuestion.isVisible !== isVisible ||
      engagementQuestion.isVisibleMemory !== isVisible)
  )
}

function engagementQuestionEnabledChanged(
  engagementQuestion: EngagementQuestion | undefined,
  isEnabled: boolean
) {
  return !!engagementQuestion && engagementQuestion.isEnabled !== isEnabled
}

import moment from 'moment'
import * as React from 'react'
import { Activity, IdentityTokenProfile } from '../../clientModels'
import { formatDateForDisplay } from '../../services/formatters/formatters'
import { Icons } from '../icon/icons'
import { QuestionContainer } from '../question/questionContainer'
import asSummary, { SummaryAction } from '../summary/summary'
import { CommentActivity } from './selectComments'
import { usePermissionManager } from './../../hooks'

const SummaryQuestion = asSummary({ groupName: 'CommentLibrary' })(
  QuestionContainer
)

interface OwnProperties extends CommentActivity {
  engagementHasLoaded: boolean
  engagementId: number
  hasCheckboxes?: boolean
  isChecked?: boolean
  onClose: (questionId: number, commentId: number) => void
  onOpen: (questionId: number, commentId: number) => void
  onSelect?: (questionId: number, commentId: number) => void
  onViewContext: (questionId: number, sectionId: number) => void
  openPath?: string
  sendSingleEmail?: (activity: Activity) => void
  user?: IdentityTokenProfile
}

const CommentSummary = (props: OwnProperties) => {
  const { impersonateUser, isExternalUser } = usePermissionManager()

  const onOpen = () => {
    const {
      question,
      newestChild: { id },
      onOpen,
    } = props

    if (!question) {
      return
    }
    onOpen(question.id, id)
  }

  const onSelect = () => {
    const {
      question,
      newestChild: { id },
      onSelect,
    } = props
    if (!question || !onSelect) {
      return
    }
    onSelect(question.id, id)
  }

  const onClose = () => {
    const {
      question,
      newestChild: { id },
      onClose,
    } = props
    if (!question) {
      return
    }
    onClose(question.id, id)
  }

  const onViewContext = () => {
    const { question, onViewContext } = props
    if (!question) {
      return
    }
    onViewContext(question.id, question.sectionId)
  }

  const formatAuthor = (activity: Activity): string => {
    const { user } = props
    const userId = user ? user.uniqueId : undefined
    return activity.userId === userId
      ? 'You'
      : `${activity.createdByInternalUser ? '(RSM) ' : ''}${activity.author}`
  }

  const sendEmail = (
    icon?: keyof Icons,
    e?: React.SyntheticEvent<HTMLElement>
  ) => {
    const { newestChild, sendSingleEmail } = props
    if (!sendSingleEmail || !e) {
      return
    }
    e.stopPropagation()
    sendSingleEmail(newestChild)
  }

  const {
    engagementId,
    engagementQuestion,
    hasCheckboxes,
    isChecked,
    newestChild,
    openPath,
    parent,
    question,
    responseRequiredByClient,
    responseRequiredByRSM,
    section,
    user,
  } = props

  if (!question || !engagementQuestion || !section) {
    return <div />
  }

  if (user?.isExternal || isExternalUser) {
    if (question.isInternalVisibleOnly) {
      return <div />
    }
  }

  let text = ''
  if (parent) {
    text = `Last commented on ${formatDateForDisplay(
      (newestChild || parent).createdDate
    )} by ${formatAuthor(newestChild || parent)}`
  }

  const requiresAttention = isExternalUser
    ? responseRequiredByClient
    : responseRequiredByRSM

  const showCheckbox =
    (isExternalUser && responseRequiredByRSM) ||
    (!isExternalUser && responseRequiredByClient)

  const sendEmailAction = !impersonateUser
    ? sendEmail
    : (icon?: keyof Icons, e?: React.SyntheticEvent<HTMLElement>) => {
        e?.stopPropagation()
      }

  const summaryAction: SummaryAction = {
    active: !impersonateUser,
    handler: sendEmailAction,
    icon: getIcon(newestChild.status),
    tooltip: getTooltip(newestChild.status, newestChild.sentDate),
  }

  return (
    <SummaryQuestion
      engagementId={engagementId}
      engagementQuestion={engagementQuestion}
      hasCheckbox={showCheckbox}
      hasComments={engagementQuestion.commentsCount > 0}
      hasDocuments={false}
      hasFlags={engagementQuestion.flagged}
      hint={text}
      isChecked={isChecked}
      label={question.displayNumber}
      labelDetails={(newestChild || parent || { value: undefined }).value}
      onClose={onClose}
      onOpen={onOpen}
      onSelect={onSelect}
      onViewContext={onViewContext}
      openPath={openPath}
      question={question}
      required={false}
      requiresAttention={requiresAttention}
      reserveCheckboxSpace={hasCheckboxes}
      secondLabel={section.title}
      selected={true}
      showFileIcons={false}
      summaryActions={
        !impersonateUser && !question.isInternalVisibleOnly
          ? [summaryAction]
          : []
      }
    />
  )

  function getTooltip(status?: string, sentDate?: moment.Moment) {
    if (impersonateUser) {
      return `You're impersonating a user`
    }
    if (!sentDate) {
      return 'Send Via Email'
    }
    switch (status) {
      case 'Sent':
        return `Email Sent on ${sentDate.format('MM/DD/YYYY')}`
      case 'Error':
        return `Email Failed on ${sentDate.format('MM/DD/YYYY')}`
      case 'Pending':
        return 'Email Pending'
      default:
        return 'Send Via Email'
    }
  }

  function getIcon(status?: string) {
    switch (status) {
      case 'Sent':
        return 'notificationSent'
      case 'Error':
        return 'notificationError'
      case 'Pending':
        return 'notificationPending'
      default:
        return 'notification'
    }
  }
}

export default CommentSummary

import classNames from 'classnames'
import * as React from 'react'
import { Option } from '../../clientModels'
import Pill from './pill'
import './pills.scss'

export interface PillsExternalProps {
  className?: string
  label?: string
  values?: Array<
    Array<{
      clear: (value: Option) => void
      option: Option
    }>
  >
}

type PillsProps = PillsExternalProps

class Pills extends React.Component<PillsProps> {
  render () {
    const { className, label, values } = this.props

    const pillsClassName = classNames('pills', className)

    return (
      <div className={pillsClassName}>
        {label && <div className='pills-label'>{label}</div>}
        {values &&
          values.map((group, index) => (
            <div className='pills-group' key={index}>
              {group.map((pillOption, index) => (
                <Pill
                  value={pillOption.option}
                  onClear={pillOption.clear}
                  key={index}
                />
              ))}
            </div>
          ))}
      </div>
    )
  }
}

export default Pills

import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import SmoothCollapse from 'react-smooth-collapse'
import {
  Engagement,
  IdentityTokenProfile,
  PhaseMap,
  EngagementTemplate,
} from '../../clientModels'
import { MilestoneCode } from '../../enums'
import { ensureNumber } from '../../guards'
import { AppState } from '../../store'
import { OpenerInjectedProps, withOpener } from '../opener/opener'
import { DueDateHeader, DueDateHeaderProps } from './dueDateHeader'
import { DueDateItem } from './dueDateItem'
import { getNextMilestoneDue, isExternalUser, MilestoneInfo } from './utilities'
import './dueDate.scss'

interface DueDateOwnProps {
  engagementId: string
}

interface DueDateMappedProps {
  engagement?: Engagement
  engagementTemplate?: EngagementTemplate
  user?: IdentityTokenProfile
  phases: PhaseMap
}

const mapStateToProps = (
  state: AppState,
  props: DueDateOwnProps
): DueDateMappedProps => {
  const engagement = state.engagements[ensureNumber(props.engagementId)]
  const engagementTemplate =
    state.engagementTemplates[ensureNumber(engagement?.engagementTemplateId)]

  return {
    engagement,
    engagementTemplate,
    user: state.auth.user,
    phases: state.phases,
  }
}

type DueDateProps = DueDateOwnProps & DueDateMappedProps & OpenerInjectedProps

class DueDate extends React.Component<DueDateProps> {
  container?: HTMLDivElement
  milestoneDetails: MilestoneInfo[] = [
    {
      code: MilestoneCode.EngagementStarted,
      caption: 'ENGAGEMENT AVAILABLE',
      doneCaption: null,
      displayOrder: 1,
      clientVisible: false,
    },
    {
      code: MilestoneCode.Setup,
      caption: 'SET UP',
      doneCaption: null,
      displayOrder: 2,
      clientVisible: true,
    },
    {
      code: MilestoneCode.InviteSent,
      caption: 'ENGAGEMENT EMAIL INVITE SENT',
      doneCaption: null,
      displayOrder: 3,
      clientVisible: false,
    },
    {
      code: MilestoneCode.ClientValidation,
      caption: 'CLIENT VALIDATION',
      doneCaption: null,
      displayOrder: 4,
      clientVisible: false,
    },
    {
      code: MilestoneCode.PBC,
      caption: 'PBC',
      doneCaption: null,
      displayOrder: 5,
      clientVisible: true,
    },
    {
      code: MilestoneCode.Review,
      caption: 'PBC REVIEW',
      doneCaption: null,
      displayOrder: 6,
      clientVisible: true,
    },
    {
      code: MilestoneCode.CCH,
      caption: 'POPULATE RETURN WITH PBC DATA',
      doneCaption: null,
      displayOrder: 7,
      clientVisible: false,
    },
    {
      code: MilestoneCode.Released,
      caption: 'FILING RELEASE FORM',
      doneCaption: null,
      displayOrder: 8,
      clientVisible: false,
    },
    {
      code: MilestoneCode.IRS,
      caption: 'FILE WITH IRS',
      doneCaption: 'FILED WITH IRS',
      displayOrder: 9,
      clientVisible: true,
    },
    {
      code: MilestoneCode.Closed,
      caption: 'CLOSE ENGAGEMENT',
      doneCaption: 'ENGAGEMENT CLOSED',
      displayOrder: 10,
      clientVisible: true,
    },
  ]

  setContainer = (ref: HTMLDivElement) => {
    this.container = ref
  }

  render() {
    const {
      milestoneDetails,
      props: {
        engagement,
        engagementTemplate,
        opener: { isOpen, toggle },
        user,
      },
    } = this

    const irsMilestone =
      engagement &&
      engagement.milestones &&
      engagement.milestones.find(x => x.milestone === 'irs')
    const irsFiledDate = irsMilestone ? irsMilestone.completedDate : undefined
    const nextMilestoneDue = getNextMilestoneDue(
      milestoneDetails,
      engagement,
      user
    )
    const milestones = engagement && engagement.milestones
    const listStyle = this.container
      ? { minWidth: this.container.clientWidth }
      : {}
    // The IRSFiled Date Will Drive  as the final stage of the milestones.
    const date = nextMilestoneDue ? nextMilestoneDue.dueDate : irsFiledDate
    const dueDate = moment.utc(date, moment.ISO_8601)
    const headerProps: DueDateHeaderProps = {
      dueDate,
      engagement,
      milestoneDetails,
      user,
    }
    const header = dueDate.isValid() ? <DueDateHeader {...headerProps} /> : null

    return isExternalUser(user) ? (
      header
    ) : header && milestones ? (
      <div
        className='due-date-dropdown-container d-none d-sm-flex'
        ref={this.setContainer}
      >
        <button
          className='clickable due-date-link'
          id='dueDateMenuNavbarDropdown'
          onClick={toggle}
          aria-haspopup='true'
          aria-expanded={isOpen}
        >
          {header}
        </button>
        <div
          className='due-date-dropdown-menu-list'
          aria-labelledby={'dueDateMenuNavbarDropdown'}
          style={listStyle}
        >
          <SmoothCollapse expanded={isOpen}>
            <div className='nav-link due-date-list-item due-date-header'>
              Engagement Activity
            </div>
            {Object.values(this.milestoneDetails).map((milestone, index) => {
              if (
                engagement &&
                !engagementTemplate?.hasCchPhase &&
                milestone.code === MilestoneCode.CCH
              ) {
                return null
              } else {
                return (
                  <DueDateItem
                    engagement={engagement}
                    milestone={milestone}
                    index={index}
                    key={index}
                  />
                )
              }
            })}
          </SmoothCollapse>
        </div>
      </div>
    ) : (
      header
    )
  }
}

// tslint:disable:no-any - https://github.com/Microsoft/TypeScript/issues/28748
export default withOpener({
  closeOnClickOutside: true,
  closeOnClickInside: true,
})(connect(mapStateToProps)(DueDate)) as any

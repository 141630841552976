import { filter, map, pull, remove, sortBy } from 'lodash'
import * as React from 'react'
import { FileWithPath } from 'react-dropzone'
import { connect } from 'react-redux'
import { actions } from '../../actions'
import { setFileUploadIsDirtyAction } from '../../actions/activityActions'
import {
  DocumentTitle,
  File as TsaFile,
  FileGroup,
  Message,
  Question,
} from '../../clientModels'
import { AppState, TsaDispatch } from '../../store'
import { Alerts, TextBox } from '../forms'
import { FormFieldValue } from '../forms/formComponentsInterfaces'
import Icon from '../icon/icon'
import FileDropZone from './fileDropZone'
import './fileUpload.scss'
import FileUploadButton from './fileUploadButton'
import FileUploadFile from './fileUploadFile'

// Hopefully this is a temporary fix.
// See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/11640 for details.
// We can't use the HOC solution for now because at the time of this modification we are
// using Typescript 2.7.1.
interface FileUploadDefaultProps {
  maxTitleLength: number
  maxFileSize: number
  maxFileCount: number
  acceptedFileTypes: string
}

// TODO: Change engagementId and questionId to string | number
interface FileUploadOwnProps extends Partial<FileUploadDefaultProps> {
  engagementId: number
  question: Question
}

interface FileUploadMappedProps {
  acceptedFileTypeExtensions: string
  acceptedFileTypes: string
  busy: boolean
  documentTitle?: DocumentTitle /** The well known document title to use for the file group */
  fileGroup?: FileGroup /** The file group being edited */
  isTemplate?: boolean
  maxFileCount?: number
  questionId: number
}

const mapStateToProps = (
  state: AppState,
  props: FileUploadOwnProps
): FileUploadMappedProps => {
  const busy = state.http.loading.includes(actions.file.FileUploadBusyIndicator)
  const documentTitle = state.fileUpload.documentTitle
  const isTemplate = state.fileUpload.isTemplate
  const maxFileCount = state.fileUpload.maxFileCount
  const fileGroup = state.fileUpload.fileGroup
  const acceptedFileTypes = isTemplate
    ? state.general.approvedTemplateMimeTypes
    : state.general.approvedMimeTypes
  const acceptedFileTypeExtensions = isTemplate
    ? state.general.approvedTemplateMimeTypeExtensions
    : state.general.approvedMimeTypeExtensions
  return {
    acceptedFileTypeExtensions,
    acceptedFileTypes,
    busy,
    documentTitle,
    fileGroup,
    isTemplate,
    maxFileCount,
    questionId: props.question.id,
  }
}

interface FileUploadDispatchProps {
  addOrUpdateFileGroup: (group: FileGroup, isTemplate?: boolean) => void
  deleteFileGroup: (group: FileGroup) => void
  setFileUploadIsDirty: (isDirty: boolean) => void
  onClose: () => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: FileUploadOwnProps
): FileUploadDispatchProps => ({
  addOrUpdateFileGroup: (group: FileGroup, isTemplate?: boolean) =>
    dispatch(
      actions.file.addOrUpdateFileGroup(
        props.engagementId,
        props.question.id,
        group,
        isTemplate
      )
    ),
  deleteFileGroup: (group: FileGroup) =>
    dispatch(
      actions.file.deleteFileGroup(
        props.engagementId,
        props.question.id,
        group.id
      )
    ),
  setFileUploadIsDirty: (isDirty: boolean) =>
    dispatch(setFileUploadIsDirtyAction(isDirty)),
  onClose: () => dispatch(actions.file.fileUploadDone()),
})

type FileUploadProps = FileUploadOwnProps &
  FileUploadMappedProps &
  FileUploadDispatchProps

type FileUploadMode = 'new' | 'edit'

interface FileUploadState {
  files: TsaFile[]
  messages: Message[]
  originalTitle: string
  title: string
  uploadMode: FileUploadMode
}

// TODO: Break up this moster component. Way too big.
export class FileUpload extends React.Component<
  FileUploadProps,
  FileUploadState
> {
  static defaultProps = {
    maxTitleLength: 150,
    maxFileSize: 10 * 1000 * 1000, // 10MB
    maxFileCount: 25,
    invalidFileTypes: [],
  }

  constructor(props: FileUploadProps) {
    super(props)
    this.state = this.initialStateFromProps(props)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(next: FileUploadProps) {
    const props = this.props
    if (
      props.engagementId !== next.engagementId ||
      props.questionId !== next.questionId ||
      props.fileGroup !== next.fileGroup ||
      props.documentTitle !== next.documentTitle
    ) {
      this.setState(this.initialStateFromProps(next))
    }
  }

  componentWillUnmount() {
    this.props.setFileUploadIsDirty(false)
  }

  initialStateFromProps(nextProps: FileUploadProps): FileUploadState {
    const { documentTitle, fileGroup } = nextProps
    const files = map(
      [...sortBy((fileGroup && fileGroup.files) || [], 'name')],
      file => {
        file.isDeleted = false
        file.isUpdated = false
        return file
      }
    )

    const title =
      (documentTitle && documentTitle.title) ||
      (fileGroup && fileGroup.title) ||
      ''
    return {
      files,
      messages: [],
      originalTitle: title,
      title,
      uploadMode: fileGroup ? 'edit' : 'new',
    }
  }

  handleDelete = (name: string) => {
    this.props.setFileUploadIsDirty(true)
    this.setState(prev => {
      const file = prev.files.find(f => f.name === name)
      if (!file) {
        return null
      }
      if (file.id < 0) {
        // This is a new file
        pull(prev.files, file)
      } else {
        if (!this.props.isTemplate) {
          // This is an existing file. Mark for deletion
          file.isDeleted = true
        } else {
          file.isUpdated = false
        }
      }

      return { files: [...prev.files] }
    })
  }

  handleTitleChange = async (title: FormFieldValue) => {
    this.props.setFileUploadIsDirty(true)
    this.setState({ title: title + '' })
  }

  handleButtonClick = () => {
    const {
      addOrUpdateFileGroup,
      deleteFileGroup,
      documentTitle,
      engagementId,
      questionId,
      isTemplate,
    } = this.props
    const { files, title } = this.state

    const fileGroup = this.props.fileGroup || {
      id: -1,
      engagementId,
      questionId,
      documentTitleId: documentTitle && documentTitle.id,
    }

    fileGroup.title = title
    fileGroup.files = files

    if (files.every(f => !!f.isDeleted)) {
      deleteFileGroup(fileGroup)
    } else {
      addOrUpdateFileGroup(fileGroup, isTemplate)
    }
  }

  handleDrop = (droppedFiles: FileWithPath[]) => {
    this.setState(previousState => {
      const { maxFileCount, maxFileSize, acceptedFileTypes } = this
        .props as FileUploadDefaultProps
      const isTemplate = this.props.isTemplate

      const messages: Message[] = []
      if (maxFileSize) {
        const tooLarge = remove(droppedFiles, f => f.size > maxFileSize)
        tooLarge.forEach(file =>
          messages.push({
            type: '',
            severity: 'warning',
            message: `${file.name} too large`,
          })
        )
      }

      const invalidType = remove(
        droppedFiles,
        f => !acceptedFileTypes.includes(f.type)
      )
      invalidType.forEach(file =>
        messages.push({
          type: '',
          severity: 'warning',
          message: `${file.name} invalid file type`,
        })
      )

      const zeroLength = remove(droppedFiles, f => f.size === 0)
      zeroLength.forEach(file =>
        messages.push({
          type: '',
          severity: 'warning',
          message: `${file.name} has no content`,
        })
      )

      const previousFiles = [...previousState.files]
      const files: TsaFile[] = []

      // Merge the newly dropped files with the existing files
      droppedFiles.forEach(f => {
        const existingFile = previousFiles.find(p => p.name === f.name)
        if (existingFile) {
          // This file is replacing an existing file
          pull(previousFiles, existingFile)
          files.push({
            id: existingFile.id,
            name: f.name,
            contents: f,
            isUpdated: true,
          })
        } else {
          // This is a new file
          files.push({ id: -1, name: f.name, contents: f })
        }
      })

      const nextFiles = previousFiles.concat(files)
      const fileCount = this.fileCount(nextFiles)
      if (
        (!isTemplate && fileCount > maxFileCount) ||
        (isTemplate &&
          nextFiles.reduce(
            (count, file) =>
              file.id < 0 || (file.id > 0 && file.isUpdated)
                ? count + 1
                : count,
            0
          ) > 1)
      ) {
        // Only add an error message. Don't modify file state at all
        const message: Message = {
          type: '',
          severity: 'warning',
          message: 'Max file count exceeded',
        }
        return {
          files: previousState.files,
          messages: [message],
        }
      }

      // Return state updates
      return {
        files: sortBy(nextFiles, 'name'),
        messages,
      }
    })
  }

  fileCount(files: TsaFile[]): number {
    return files.reduce((count, file) => count + (file.isDeleted ? 0 : 1), 0)
  }

  get sizeLimit(): string {
    const { maxFileSize } = this.props
    if (!maxFileSize) {
      return ''
    }

    const size =
      maxFileSize >= 1000000
        ? `${maxFileSize / 1000000}MB`
        : `${maxFileSize / 1000}KB`

    return `File size max ${size}.`
  }

  get countLimit(): string {
    const { maxFileCount } = this.props
    if (!maxFileCount) {
      return ''
    }
    return `Max ${maxFileCount} files.`
  }

  get acceptedFileTypeLimit(): JSX.Element {
    const { isTemplate, acceptedFileTypeExtensions } = this.props
    if (isTemplate) {
      return (
        <p>
          File must be an: .{acceptedFileTypeExtensions.replace(/,/g, ', .')}
        </p>
      )
    }
    return <p />
  }

  haveFileUpdates(): boolean {
    return !!this.state.files.find(
      f =>
        f.id < 0 || // new file
        !!f.isDeleted || // deleted file
        !!f.isUpdated // updated (replaced) file
    )
  }

  buttonEnabled(): boolean {
    if (this.props.busy) {
      return false
    }

    const { uploadMode, title, originalTitle } = this.state

    switch (uploadMode) {
      case 'new':
        return this.haveFileUpdates() && !!title
      case 'edit':
        return this.haveFileUpdates() || title !== originalTitle
      default:
        throw new Error('Unknown uploadMode')
    }
  }

  handleClose = () => {
    const {
      props: { onClose },
    } = this
    onClose()
  }

  render() {
    const { files, messages, originalTitle, title, uploadMode } = this.state
    const {
      acceptedFileTypes,
      acceptedFileTypeExtensions,
      busy,
      documentTitle,
      isTemplate,
      question,
    } = this.props
    const { maxFileCount, maxTitleLength } = this
      .props as FileUploadDefaultProps
    const { handleClose } = this

    const remainingFiles = files.filter(f => !f.isDeleted)
    const addedFilesCount = filter(files, ['id', -1]).length
    const deletedFilesCount = filter(files, 'isDeleted').length
    const updatedFilesCount = filter(files, 'isUpdated').length
    // utility function to make sure TSA Hub's dropzone can handle additional file types.
    const modifiedAcceptedFileTypes = (
      fileTypes: string,
      fileExtensions: string
    ) => {
      return `${fileTypes},${fileExtensions
        .split(',')
        .map(a => `.${a.trim()}`)}`
    }

    const buttonText =
      uploadMode === 'new' ||
      (addedFilesCount !== 0 &&
        deletedFilesCount === 0 &&
        updatedFilesCount === 0 &&
        title === originalTitle)
        ? 'Upload'
        : addedFilesCount === 0 &&
          deletedFilesCount !== 0 &&
          updatedFilesCount === 0 &&
          title === originalTitle
        ? 'Delete'
        : 'Update'

    const fileUploadCloseId = `file-upload-close-${this.props.questionId}`
    return (
      <div className='file-upload' id={`file-upload-${this.props.questionId}`}>
        <div className='file-upload-scrollable'>
          <div className='file-upload-header d-none d-md-block'>
            ({question.displayNumber}){' '}
            {uploadMode === 'edit' ? 'Edit file upload' : 'Upload a file'}
            <span id={fileUploadCloseId} onClick={handleClose}>
              <Icon icon='close' onClick={handleClose} />
            </span>
          </div>
          <div
            className='file-upload-title'
            id={`file-upload-title-${this.props.questionId}`}
          >
            {documentTitle && documentTitle.title}
            {!documentTitle && (
              <TextBox
                disabled={busy}
                label='Enter a description for the file(s)'
                onChange={this.handleTitleChange}
                value={title}
                maxLength={maxTitleLength}
                messages={
                  title
                    ? undefined
                    : [{ type: '', severity: 'error', message: 'Required' }]
                }
              />
            )}
          </div>
          <div
            className='file-upload-dropzone'
            id={`file-upload-dropzone-${this.props.questionId}`}
          >
            {this.countLimit} {this.sizeLimit}
            {this.acceptedFileTypeLimit}
            {isTemplate || remainingFiles.length < maxFileCount ? (
              <FileDropZone
                acceptedFileTypes={modifiedAcceptedFileTypes(
                  acceptedFileTypes,
                  acceptedFileTypeExtensions
                )}
                busy={busy}
                files={files}
                isTemplate={isTemplate}
                maxFileCount={maxFileCount}
                onDrop={this.handleDrop}
              />
            ) : (
              <div className='file-upload-max-files'>
                Maximum number of files reached
              </div>
            )}
          </div>
          {!isTemplate && files.length > 0 && (
            <div className='file-upload-files'>
              {remainingFiles.map(file => (
                <FileUploadFile
                  key={file.name}
                  file={file}
                  onDelete={this.handleDelete}
                />
              ))}
            </div>
          )}
          {isTemplate && files.length > 0 && (
            <div className='file-upload-files'>
              {files
                .filter(f => (f.id < 0 || f.isUpdated) && !f.isDeleted)
                .map(file => (
                  <FileUploadFile
                    key={file.name}
                    file={file}
                    onDelete={this.handleDelete}
                  />
                ))}
            </div>
          )}
          <Alerts messages={messages} />
        </div>
        <div className='file-upload-button-region'>
          <FileUploadButton
            text={buttonText}
            busy={busy}
            disabled={!this.buttonEnabled()}
            onClick={this.handleButtonClick}
          />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload)

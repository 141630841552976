import React from 'react'
import Icon from '../icon/icon'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import { Theme } from '@rsmus/react-ui'

function styles(theme: Theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '1.2rem',
      width: '1.2rem',
      paddingTop: '0.2rem',
      paddingBottom: '0.2rem',
      '&.active': {
        backgroundColor: theme.palette.grey[500],
        '&.resolved': {
          backgroundColor: theme.palette.green.dark,
        },
      },
      '& .icon': {
        height: '1em',
        width: '1em',
        stroke: theme.palette.grey[100],
        fill: theme.palette.grey[100],
      },
    },
    gutterIcon: {
      paddingTop: '0.1rem',
      paddingBottom: '0.1rem',
    },
    gutterCarryForward: {
      fontSize: '1rem',
    },
    gutterCheck: {
      fontSize: '0.8rem',
    },
    gutterLock: {
      fontSize: '0.8rem',
    },
    gutterClientInvisible: {
      fontSize: '0.8rem',
      '&.icon': {
        fill: 'none',
        strokeWidth: '0.14rem',
      },
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface ActivityGutterProps {
  className?: string
  showResolved: boolean
  showCarryForward: boolean
  showLocked: boolean
  showNotClientVisbile: boolean
}

const ActivityGutter = ({
  className,
  showResolved,
  showCarryForward,
  showLocked,
  showNotClientVisbile,
}: ActivityGutterProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const containerClassName = classNames(
    classes.container,
    className,
    {
      active:
        showResolved || showCarryForward || showNotClientVisbile || showLocked,
    },
    { resolved: showResolved }
  )

  return (
    <div className={containerClassName}>
      {showResolved && (
        <div className={classNames(classes.gutterIcon, classes.gutterCheck)}>
          <Icon icon='check' tooltip='Comment marked as resolved.' />
        </div>
      )}
      {showCarryForward && (
        <div
          className={classNames(classes.gutterIcon, classes.gutterCarryForward)}
        >
          <Icon icon='carryForward' tooltip='Comment carried foward.' />
        </div>
      )}
      {showLocked && (
        <div className={classNames(classes.gutterIcon, classes.gutterLock)}>
          <Icon icon='lock' tooltip='Comment locked.' />
        </div>
      )}
      {showNotClientVisbile && (
        <div
          className={classNames(
            classes.gutterIcon,
            classes.gutterClientInvisible
          )}
        >
          <Icon
            icon='visibilityOff'
            tooltip='Client can not see this comment.'
          />
        </div>
      )}
    </div>
  )
}

export default ActivityGutter

/**
 * Allow for type safe keys with the Web Storage API
 *
 * @example
 * const storage: KeyedStorage<'key1' | 'key2'> = localStorage
 * storage.getItem('key1')    // returns string | null
 * storage.getItem('invalid') // Error!
 */
export interface KeyedStorage<TKeys extends string> {
  readonly length: number
  clear(): void
  getItem(key: TKeys): string | null
  removeItem(key: TKeys): void
  setItem(key: TKeys, value: string): void
}

/**
 * Create a prefixed set of key/value pairs using the Web Storage API.
 * Values can be set, retrieved, or cleared independently from other
 * sets of keys by using a prefix.
 */
export class PrefixedStorage<TKeys extends string>
implements KeyedStorage<TKeys> {
  private onStorage?: (e: StorageEvent) => void
  private onChangeHandlers: { [key: string]: (e: StorageEvent) => void } = {}

  constructor (
    public readonly prefix: string,
    public readonly storage: Storage = localStorage
  ) {}

  get length (): number {
    return this.getKeys().length
  }

  /**
   * Clears all entries in storage whos keys start with the prefix
   * defined in this class.
   */
  clear (): void {
    for (const key of this.getKeys()) {
      this.removeItem(key)
    }
  }

  /**
   * Gets a value from storage.
   * @example
   * const storage = new PrefixedStorage<'key1' | 'key2'>('tsa')
   * storage.getItem('key1') // Returns value for "tsa/key1"
   * storage.getItem('lala') // Error!
   */
  getItem (key: TKeys): string | null {
    return this.storage.getItem(this.prefixedKey(key))
  }

  /**
   * Removes a value from storage.
   * @example
   * const storage = new PrefixedStorage<'key1' | 'key2'>('tsa')
   * storage.removeItem('key1') // Removes key tsa/key1
   */
  removeItem (key: TKeys): void {
    this.storage.removeItem(this.prefixedKey(key))
  }

  /**
   * Sets a value in storage.
   * @example
   * const storage = new PrefixedStorage<'key1' | 'key2'>('tsa')
   * storage.setItem('key1', 'value1') // tsa/key1=value1
   */
  setItem (key: TKeys, data: string): void {
    this.storage.setItem(this.prefixedKey(key), data)
  }

  /**
   * Registers a callback for storage events. The callback is called
   * if the event matches the specified key.
   */
  onChange (key: TKeys, callback: (e: StorageEvent) => void) {
    this.onChangeHandlers[this.prefixedKey(key)] = callback
    if (!this.onStorage) {
      this.onStorage = e => {
        if (!e.key) {
          return
        }
        const cb = this.onChangeHandlers[e.key]
        if (cb) {
          cb(e)
        }
      }
      window.addEventListener('storage', this.onStorage)
    }
  }

  /**
   * Unregisters a callback for storage events on the specified key.
   */
  offChange (key: TKeys, callback?: (e: StorageEvent) => void) {
    delete this.onChangeHandlers[this.prefixedKey(key)]
    if (Object.keys(this.onChangeHandlers).length === 0 && this.onStorage) {
      window.removeEventListener('storage', this.onStorage)
    }
  }

  private prefixedKey (key: string) {
    return `${this.prefix}/${key}`
  }

  private getKeys (): TKeys[] {
    const keys: TKeys[] = []
    for (let i = 0, len = this.storage.length; i < len; ++i) {
      const key = this.storage.key(i)
      if (key && key.startsWith(this.prefix)) {
        keys.push()
      }
    }
    return keys
  }
}

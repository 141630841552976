import { TaxIntegrationStatus } from '../../clientModels'
import * as http from '../http'
import { buildApiUrl } from './apiUrl'

export function apiDuoExportDataRequest(
  engagementId: number,
  fileType: string
): Promise<TaxIntegrationStatus>  {
  return http.post(
    buildApiUrl(`/duointegration/initiateexport`, { engagementId, fileType }),
    {}
  )
}
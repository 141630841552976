import classnames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState, useCallback } from 'react'
import { MdFileDownload } from 'react-icons/md'
import { useTheme } from 'react-jss'
import { CchRequest } from '.'
import { useTableStyles } from './tableStyles'
import { Message } from './message'

type CchRequestsRowProps = {
  request: CchRequest
}

const requestTypes = {
  submittaxdocument: 'Submit Return',
  requesttaxdocument: 'Export Return',
}

export const CchRequestsRow = ({ request }: CchRequestsRowProps) => {
  const theme = useTheme()
  const classes = useTableStyles(theme)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      setMessage(() => {
        const diff = moment().diff(request.created)
        const timer = moment.utc(diff).format('HH:mm:ss')
        if (request.state === 'In Progress') {
          return `Running for ${timer}`
        } else {
          clearInterval(interval)
          return `Ran for ${timer}`
        }
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [request])

  const handleClickDownload = useCallback(() => {
    if (request.response) {
      const { payload, documentIdentifier } = request.response
      const fileName = documentIdentifier?.replace(':', '_') + '.xml'
      download(fileName, payload)
    }
  }, [request])

  return (
    <tr className={classes.row}>
      <td className={classes.cell} title={request.requestId}>
        {requestTypes[request.type]}
      </td>
      <td className={classes.cell}>{request.cchReturnId}</td>
      <td className={classes.cell}>{request.created.format('LTS')}</td>
      <td
        className={classnames(classes.cell, {
          [classes.inProgress]: request.state === 'In Progress',
        })}
      >
        {request.state}
      </td>
      <td className={classnames(classes.cell, classes.icon)}>
        {request.response?.payload && (
          <MdFileDownload
            onClick={handleClickDownload}
            title='Download Return XML'
          />
        )}
      </td>
      <td className={classes.cell}>
        <Message message={request.message || message} />
      </td>
    </tr>
  )
}

function download(filename: string, text: string) {
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:application/xml;charset=utf-8,' + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

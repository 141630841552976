import classNames from 'classnames'
import { History, Location } from 'history'
import * as React from 'react'
import { connect } from 'react-redux'
import { match, RouteComponentProps } from 'react-router'
import { createSelector } from 'reselect'
import { actions } from '../../actions'
import { Activity, IdentityTokenProfile } from '../../clientModels'
import { ensureNumber } from '../../guards'
import { NotificationApi } from '../../services/api'
import {
  NotificationSendOptions,
  NotificationTemplate,
  NotificationTemplateOptions,
  NotificationTemplateReplacement,
} from '../../services/api/apiModels'
import { getTrackingId } from '../../services/track'
import { AppState, TsaDispatch } from '../../store'
import { optionToObject } from '../forms/formUtilities'
import { CheckBoxes } from '../forms/index'
import Icon from '../icon/icon'
import CustomEmailDialog from '../modals/customEmailDialog'
import { joinPaths } from '../relativeLink'
import './commentLibrary.scss'
import { CommentFilterMethods } from './commentLibraryFilters'
import { CommentSortMethods } from './commentLibrarySorts'
import CommentSummary from './commentSummary'
import { CommentActivity, retrieveCommentActivities } from './selectComments'
import { hasPermission } from '../../services/permissionManagerHelpers'
//import PermissionContent from '../internal/permissionContent'
import { UserPermissions } from '../../enums'

function getActivities(state: AppState, props: CommentLibraryOwnProps) {
  return state.activities[ensureNumber(props.match.params.engagementId)] || {}
}

function getQuestions(state: AppState) {
  return state.questions
}

function getSections(state: AppState) {
  return state.sections
}

function getEngagementQuestions(state: AppState) {
  return state.engagementQuestions
}

function getFilter(state: AppState) {
  return state.selection.selectedCommentFilter
}

function getSort(state: AppState) {
  return state.selection.selectedCommentSort
}

const getCommentActivities = createSelector(
  getActivities,
  getQuestions,
  getSections,
  getEngagementQuestions,
  (activities, questions, sections, engagementQuestions) => {
    return retrieveCommentActivities(
      activities,
      questions,
      sections,
      engagementQuestions
    )
  }
)

const getFilteredCommentActivities = createSelector(
  getCommentActivities,
  getFilter,
  (activities, filter) => {
    let filterMethod = CommentFilterMethods[filter]
    if (!filterMethod) {
      filterMethod = CommentFilterMethods.__default
    }
    return activities.filter(filterMethod)
  }
)

const getSortedCommentActivities = createSelector(
  getFilteredCommentActivities,
  getSort,
  (activities, sort) => {
    let sortMethod = CommentSortMethods[sort]
    if (!sortMethod) {
      sortMethod = CommentSortMethods.__default
    }

    return activities.sort(sortMethod)
  }
)

// #endregion
interface CommentLibraryOwnProps extends RouteComponentProps<RouteProperties> {
  selectUnsent?: boolean
}

interface RouteProperties {
  engagementId: string
  questionId?: string
}

interface MappedProperties {
  activities: CommentActivity[]
  engagementHasLoaded: boolean
  history: History
  location: Location
  match: match<RouteProperties>
  user?: IdentityTokenProfile
  permissions: string[]
}

interface DispatchProperties {
  getAllActivities: (engagementId: number) => string
}

type CommentLibraryProps = CommentLibraryOwnProps &
  MappedProperties &
  DispatchProperties

function mapStateToProps(
  state: AppState,
  props: CommentLibraryOwnProps
): MappedProperties {
  const result: MappedProperties = {
    activities: getSortedCommentActivities(state, props),
    engagementHasLoaded: !!state.engagements[
      ensureNumber(props.match.params.engagementId)
    ],
    history: props.history,
    location: props.location,
    match: props.match,
    user: state.auth.user,
    permissions: state.permissions.permissions,
  }

  return result
}

function mapDispatchToProps(
  dispatch: TsaDispatch,
  props: CommentLibraryOwnProps
): DispatchProperties {
  return {
    getAllActivities: (engagementId: number) =>
      getTrackingId(dispatch, actions.activity.getAllActivities, engagementId),
  }
}

export interface SelectedComment {
  commentId: number
  questionId?: number
}

interface State {
  allSelected: boolean
  engagementId: number
  openItems: number
  questionId?: number
  selectedItems?: SelectedComment[]
  template: NotificationTemplate | null
  options: NotificationTemplateOptions | null
  sendingEmail: boolean
}

class CommentLibrary extends React.Component<CommentLibraryProps, State> {
  selectAllOption = { value: 'selected', label: 'Select All' }
  getActivitiesId?: string
  state: State

  constructor(props: CommentLibraryProps) {
    super(props)

    this.afterClose = this.afterClose.bind(this)
    this.closeRemoveItem = this.closeRemoveItem.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onOpen = this.onOpen.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.onViewContext = this.onViewContext.bind(this)
    this.openAddItem = this.openAddItem.bind(this)
    this.sendSingleEmail = this.sendSingleEmail.bind(this)
    this.onSelectUnsentEmails = this.onSelectUnsentEmails.bind(this)

    const {
      match: {
        params: { engagementId, questionId },
      },
    } = props

    this.state = {
      allSelected: false,
      engagementId: parseInt(engagementId, 10),
      openItems: 0,
      questionId: questionId ? parseInt(questionId, 10) : undefined,
      template: null,
      options: null,
      sendingEmail: false,
    }

    if (props.selectUnsent) {
      this.state.selectedItems = this.onSelectUnsentEmails()
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: CommentLibraryProps) {
    const { props, state } = this
    const nextState = { ...state }
    let updated = false

    if (
      props.match.params.engagementId !== nextProps.match.params.engagementId
    ) {
      nextState.engagementId = parseInt(nextProps.match.params.engagementId, 10)
      updated = true
    }

    if (props.match.params.questionId !== nextProps.match.params.questionId) {
      const nextQuestionId = nextProps.match.params.questionId
      nextState.questionId = nextQuestionId
        ? parseInt(nextQuestionId, 10)
        : undefined
      updated = true
    }

    if (nextProps.selectUnsent && !props.selectUnsent) {
      nextState.selectedItems = this.onSelectUnsentEmails()
      updated = true
    }

    if (updated) {
      this.setState(nextState)
    }
  }

  onSelectUnsentEmails(): SelectedComment[] {
    const {
      props: { activities, user },
    } = this
    const isExternal = user && user.isExternal
    const result: SelectedComment[] = []
    for (const activity of activities) {
      if (
        (isExternal &&
          activity.responseRequiredByRSM &&
          !activity.newestChild.sentDate) ||
        (!isExternal &&
          activity.responseRequiredByClient &&
          !activity.newestChild.sentDate)
      ) {
        result.push({
          commentId: activity.newestChild.id,
          questionId: activity.newestChild.questionId,
        })
      }
    }
    return result
  }

  onOpen(questionId: number, commentId: number) {
    const {
      props: { history, match },
      openAddItem,
    } = this
    history.replace(
      joinPaths(
        match.url,
        `comments/questions/${questionId}/comments/${commentId}`
      )
    )
    this.setState(openAddItem)
  }

  onClose(questionId: number, commentId: number) {
    const { afterClose, closeRemoveItem } = this
    setTimeout(() => {
      this.setState(closeRemoveItem, afterClose)
    }, 0)
  }

  onSelect(questionId: number, commentId: number) {
    const {
      props: { activities, user },
      state,
    } = this
    let selectedItems = state.selectedItems ? [...state.selectedItems] : []
    let allSelected = state.allSelected
    const otherItems = selectedItems.filter(
      item => item.commentId !== commentId || item.questionId !== questionId
    )
    if (selectedItems.length === otherItems.length) {
      selectedItems.push({ commentId, questionId })
      if (
        selectedItems.length ===
        activities.filter(
          activity =>
            !!user &&
            ((user.isExternal && activity.responseRequiredByRSM) ||
              (!user.isExternal && activity.responseRequiredByClient))
        ).length
      ) {
        allSelected = true
      }
    } else {
      selectedItems = otherItems
      allSelected = false
    }

    this.setState({ selectedItems, allSelected })

    return Promise.resolve()
  }

  openAddItem({ openItems }: State) {
    this.setState({ openItems: openItems + 1 })
  }

  closeRemoveItem({ openItems }: State) {
    this.setState({ openItems: openItems > 0 ? openItems - 1 : 0 })
  }

  afterClose() {
    const {
      state: { openItems },
      props: { history, match },
    } = this
    if (openItems === 0) {
      history.replace(joinPaths(match.url, 'comments'))
    }
  }

  onViewContext(questionId: number, sectionId: number) {
    const { history, match } = this.props
    const url = joinPaths(
      match.url,
      `engagements/${match.params.engagementId}/sections/${sectionId}/questions/${questionId}`
    )
    history.push(url)
  }

  onSelectAll = () => {
    const {
      props: { user, activities },
    } = this
    this.setState({ allSelected: !this.state.allSelected }, () => {
      this.setState({
        selectedItems: this.state.allSelected
          ? activities
              .filter(
                activity =>
                  !!user &&
                  ((user.isExternal && activity.responseRequiredByRSM) ||
                    (!user.isExternal && activity.responseRequiredByClient))
              )
              .map(activity => ({
                commentId: activity.newestChild.id,
                questionId: activity.newestChild.questionId,
              }))
          : [],
      })
    })

    return Promise.resolve()
  }

  openEmailDialog = async () => {
    const {
      props: { user },
      state: { selectedItems, engagementId, sendingEmail },
    } = this
    if (sendingEmail) {
      return
    }
    if (selectedItems) {
      const questionIds = []
      const activityIds = []
      let send = false
      for (const selectedItem of selectedItems) {
        if (activityIds.indexOf(selectedItem.commentId) < 0) {
          activityIds.push(selectedItem.commentId)
        }
        if (
          selectedItem.questionId &&
          questionIds.indexOf(selectedItem.questionId) < 0
        ) {
          questionIds.push(selectedItem.questionId!)
        }
        send = true
      }
      if (send) {
        const options: NotificationTemplateOptions = {
          engagementId,
          questionIds,
          activityIds,
        }

        this.setState({ sendingEmail: true }, async () => {
          const template = await NotificationApi.retrieveCommentsTemplate(
            options,
            user
          )
          this.setState({ template, options })
        })
      }
    }
  }

  async sendSingleEmail(activity: Activity) {
    const {
      props: { user },
      state: { sendingEmail },
    } = this
    if (sendingEmail) {
      return
    }
    const options: NotificationTemplateOptions = {
      engagementId: activity.engagementId,
      questionIds: activity.questionId ? [activity.questionId] : [],
      activityIds: [activity.id],
    }
    this.setState({ sendingEmail: true }, async () => {
      const template = await NotificationApi.retrieveCommentsTemplate(
        options,
        user
      )
      this.setState({ template, options })
    })
  }

  handleSendEmail = async (
    options: NotificationTemplateOptions,
    to: string[] | null,
    cc: string[] | null,
    bcc: string[] | null,
    subject: string | null,
    replaceableValues: NotificationTemplateReplacement | null
  ) => {
    const {
      props: { user },
    } = this

    const sendOptions: NotificationSendOptions = {
      ...options,
      to: to || undefined,
      cc: cc || undefined,
      bcc: bcc || undefined,
      subject: subject || undefined,
      replaceableValues: replaceableValues || {},
    }

    await NotificationApi.sendCommentsNotification(sendOptions, user)
    this.setState({ template: null, options: null, sendingEmail: false })
    this.getActivitiesId = this.props.getAllActivities(
      Number(this.props.match.params.engagementId)
    )
  }

  handleCancelEmail = () => {
    this.setState({ template: null, options: null, sendingEmail: false })
  }

  render() {
    const {
      handleSendEmail,
      handleCancelEmail,
      onClose,
      onOpen,
      onSelect,
      onViewContext,
      props: { activities, engagementHasLoaded, user, match },
      selectAllOption,
      sendSingleEmail,
      state: { engagementId, selectedItems, template, options, sendingEmail },
    } = this

    if (template && options) {
      return (
        <CustomEmailDialog
          template={template}
          options={options}
          onSendEmail={handleSendEmail}
          onCancel={handleCancelEmail}
        />
      )
    }

    if (activities.length === 0) {
      return (
        <div className='comment-library-no-comments'>You have no comments.</div>
      )
    }

    const value = this.state.allSelected
      ? optionToObject(selectAllOption)
      : null

    const hasCheckboxes = !!activities.find(
      activity =>
        !!user &&
        ((user.isExternal && activity.responseRequiredByRSM) ||
          (!user.isExternal && activity.responseRequiredByClient))
    )
    const hasEmailPermissions = hasPermission(
      this.props.user,
      this.props.permissions,
      UserPermissions.SendEmailCanEdit
    )

    return (
      <div className='comment-library'>
        <div>
          <span className='bold'>Bold comments</span> are those requiring a
          response by you.
          <br />
          <Icon icon='notificationSent' /> means a user has received an email
          notification.
        </div>
        <div className='d-flex flex-row'>
          {hasEmailPermissions && (
            <CheckBoxes
              className='comment-library-select-all d-flex'
              onChange={this.onSelectAll}
              options={[selectAllOption]}
              value={value}
            />
          )}

          {hasEmailPermissions && (
            <button
              className={classNames(
                'btn btn-primary btn-save d-flex send-email',
                { disabled: !hasEmailPermissions, sendingEmail }
              )}
              disabled={!selectedItems || !selectedItems.length || sendingEmail}
              onClick={this.openEmailDialog}
            >
              {!sendingEmail ? 'Send Email' : 'Retrieving...'}
            </button>
          )}
        </div>
        <br />
        {activities.map((fq, i) => (
          <CommentSummary
            engagementHasLoaded={engagementHasLoaded}
            engagementId={engagementId}
            hasCheckboxes={hasCheckboxes}
            isChecked={
              !!selectedItems &&
              !!selectedItems.find(
                item =>
                  item.commentId === fq.newestChild.id &&
                  item.questionId === fq.newestChild.questionId
              )
            }
            key={i}
            onClose={onClose}
            onOpen={onOpen}
            onSelect={onSelect}
            onViewContext={onViewContext}
            openPath={joinPaths(
              match.url,
              `comments/questions/${fq.newestChild.questionId}/comments/${fq.newestChild.id}`
            )}
            sendSingleEmail={sendSingleEmail}
            user={user}
            {...fq}
          />
        ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentLibrary)

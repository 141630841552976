import * as React from 'react'
import { TsaJsonSchema } from '../../services/answerSchema'
import DateTimePicker from '../forms/dateTime'
import { FormFieldValue } from '../forms/formComponentsInterfaces'
import { EditorProps } from './editors'

export function DateEditor (property: TsaJsonSchema) {
  return class extends React.PureComponent<EditorProps> {
    constructor (props: EditorProps) {
      super(props)
      this.handleChange = this.handleChange.bind(this)
      this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    async handleChange (value: FormFieldValue) {
      const {
        props: { onChange },
      } = this
      // tslint:disable-next-line:no-console
      onChange(value + '')
    }

    handleKeyDown (e: React.KeyboardEvent<HTMLElement>) {
      this.props.onKeyDown(e)
    }

    render () {
      const {
        handleChange,
        handleKeyDown,
        props: { value },
      } = this
      return (
        <DateTimePicker
          value={value + ''}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          jsonSchema={property}
          autoFocus={true}
          selectedQuestion={true}
        />
      )
    }
  }
}

import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { generatePath, NavLink, RouteComponentProps } from 'react-router-dom'
import Toggle from 'react-switch'
import { createSelector } from 'reselect'
import * as questionActions from '../../actions/engagementQuestionActions'
import {
  Engagement,
  EngagementQuestionMap,
  EngagementSectionMap,
  EntityMap,
  IdentityTokenProfile,
  IEngagementQuestionAnswer,
  QuestionMap,
  Section,
  EngagementSectionNavItem
} from '../../clientModels'
import { ensureNumber, isDefined } from '../../guards'
import {
  selectAnswers,
  getEngagements,
  getEngagementTemplates,
  getSections,
  getShowAllQuestions,
  getShowIncompleteOnly
} from '../../reducers/selectors'
import { questionNeedsReview } from '../../services/questionReviewUtilities'
import { sortByDisplayOrder } from '../../sorting'
import { AppState, TsaDispatch } from '../../store'
import { hasVisibleError, isComplete } from '../forms/formUtilities'
import Icon from '../icon/icon'
import InternalContent from '../internal/internalContent'
import './sectionLinks.scss'
import { PhaseCode } from '../../enums'
import ProxyToggle from './proxyToggle'
import { EngagementSectionList } from '../engagementSectionList'

interface RouteParams {
  engagementId: string
  sectionId?: string
}

interface SectionLinksOwnProps {
  title: string
}

type SectionLinksExternalProps = SectionLinksOwnProps &
  RouteComponentProps<RouteParams>

const getRouteParams = (state: AppState, props: SectionLinksExternalProps) =>
  props.match.params
const getEngagementQuestions = (state: AppState) => state.engagementQuestions
const getEngagementSections = (state: AppState) => state.engagementSections
const getEngagementQuestionsForEngagement = createSelector(
  getRouteParams,
  getEngagementQuestions,
  (params, questions) => questions[params.engagementId] || {}
)
const getEngagementSectionsForEngagement = createSelector(
  getRouteParams,
  getEngagementSections,
  (params, sections) => sections[params.engagementId] || {}
)
const getAnswersForEngagement = createSelector(
  getRouteParams,
  selectAnswers,
  (params, answers) => answers[params.engagementId] || {}
)
const getSectionsForEngagement = createSelector(
  getRouteParams,
  getEngagements,
  getSections,
  getEngagementTemplates,
  (params, engagementMap, sectionMap, templateMap) => {
    const engagement = engagementMap[ensureNumber(params.engagementId)]
    const template = engagement && templateMap[engagement.engagementTemplateId]
    return !template || !template.sections
      ? []
      : template.sections
          .map(id => sectionMap[id])
          .filter(isDefined)
          .sort(sortByDisplayOrder)
  }
)
const getEngagementById = createSelector(
  getRouteParams,
  getEngagements,
  (params, engagements) => {
    return engagements[ensureNumber(params.engagementId)]
  }
)

interface SectionLinksMappedProps {
  answers: EntityMap<IEngagementQuestionAnswer>
  engagement?: Engagement
  engagementQuestions: EngagementQuestionMap
  engagementSections: EngagementSectionMap
  questions: QuestionMap
  sections: Section[]
  showAllQuestions: boolean
  showIncompleteOnly: boolean
  user?: IdentityTokenProfile
}

const mapStateToProps = (
  state: AppState,
  props: SectionLinksExternalProps
): SectionLinksMappedProps => {
  const { engagementId } = props.match.params
  return {
    answers: getAnswersForEngagement(state, props),
    engagement: getEngagementById(state, props),
    engagementQuestions: getEngagementQuestionsForEngagement(state, props),
    engagementSections: getEngagementSectionsForEngagement(state, props),
    questions: state.questions,
    sections: getSectionsForEngagement(state, props),
    showAllQuestions: getShowAllQuestions(state, engagementId),
    showIncompleteOnly: getShowIncompleteOnly(state, engagementId),
    user: state.auth.user
  }
}

interface SectionLinksDispatchProps {
  toggleShowAll: () => void
  toggleShowIncomplete: () => void
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: SectionLinksExternalProps
): SectionLinksDispatchProps => {
  const { engagementId } = props.match.params
  return {
    toggleShowAll: () =>
      dispatch(questionActions.showAllQuestionsAction({ engagementId })),
    toggleShowIncomplete: () =>
      dispatch(questionActions.showIncompleteOnlyAction({ engagementId }))
  }
}

type SectionLinksProps = SectionLinksExternalProps &
  SectionLinksMappedProps &
  SectionLinksDispatchProps

class SectionLinks extends React.Component<SectionLinksProps> {
  static defaultProps = { title: 'Tasks' }

  render() {
    const {
      answers,
      engagement,
      engagementQuestions,
      engagementSections,
      match,
      questions,
      sections,
      showAllQuestions,
      showIncompleteOnly,
      title,
      toggleShowAll,
      toggleShowIncomplete,
      user
    } = this.props

    const inSetup =
      engagement && engagement.phase && engagement.phase === PhaseCode.Setup

    const sectionNavItems: (EngagementSectionNavItem | null)[] = sections
      .map(section => {
        const engagementSection = engagementSections[section.id]
        const isVisible =
          !!engagementSection &&
          (engagementSection.isVisible || engagementSection.isVisibleDebug)
        if (!isVisible) {
          return null
        }

        const visibleQuestions = section.questions
          .map(questionId => engagementQuestions[questionId])
          .filter(isDefined)
          .filter(q => q.isVisible)

        const allQuestionsComplete = visibleQuestions.every(
          q =>
            isComplete(q, answers[q.questionId]) &&
            !questionNeedsReview(
              engagement,
              questions[q.questionId],
              answers[q.questionId],
              user
            )
        )

        const forcedVisible = visibleQuestions.every(q => !q.isVisibleMemory)

        const hasErrorQuestion = visibleQuestions.some(q => hasVisibleError(q))

        const icon = forcedVisible
          ? 'na'
          : hasErrorQuestion
          ? 'error'
          : allQuestionsComplete
          ? 'checkedCircle'
          : ''

        const to = generatePath(match.path, {
          ...match.params,
          sectionId: section.id
        })

        const navItem = (
          <div>
           {section.isVisible && 
            <NavLink
                key={section.id}
                className={classNames('section-link', {
                  complete: icon === 'checkedCircle' || forcedVisible
                })}
                id={`engagement-section-${section.id}`}
                to={to}
              >
                {icon && <Icon icon={icon} />}
                <div
                  id={`engagement-section-number-${section.id}`}
                  className='section-number'
                >
                  {section.number}.
                </div>
                <div
                  id={`engagement-section-title-${section.id}`}
                  className='section-title'
                >
                  {section.title}
                </div>
              </NavLink>
            }
          </div>
        )

        const item: EngagementSectionNavItem = {
          sectionId: section.id,
          displayOrder: section.displayOrder,
          navItem: navItem
        }
        return item
      })
      // Remove null values for unavailable sections
      .filter(section => section)

    const selectedSectionId = match.params.sectionId

    return (
      <div className='tasks-menu'>
        <div className='section-links'>
          <h1>{title}</h1>
          <EngagementSectionList
            sectionNavItems={sectionNavItems}
            selectedSectionId={selectedSectionId}
            showAllSections={showAllQuestions}
          />
        </div>
        {!inSetup && <ProxyToggle />}
        <label htmlFor='show-all-tasks'>
          <span>Incomplete Questions Only</span>
          <Toggle
            checked={showIncompleteOnly}
            handleDiameter={24}
            height={20}
            onChange={toggleShowIncomplete}
            onColor='#3F9C46'
            width={48}
          />
        </label>
        <InternalContent>
          <label htmlFor='show-all-tasks'>
            <span>
              Show All Questions <span>(RSM view only)</span>
            </span>
            <Toggle
              checked={showAllQuestions}
              handleDiameter={24}
              height={20}
              onChange={toggleShowAll}
              onColor='#3F9C46'
              width={48}
            />
          </label>
        </InternalContent>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionLinks)

import * as React from 'react'
import ReactDataSheet from 'react-datasheet'
import { GridElement } from './component'

export class DefaultValueViewer extends React.PureComponent<
  ReactDataSheet.ValueViewerProps<GridElement>
  > {
  render () {
    const {
      props: { value },
    } = this
    return <span className='value-viewer'>{value}</span>
  }
}

import * as React from 'react'
import MediaQuery from 'react-responsive'
import { MediaMinBreakpoint } from '../../services/MediaQueries'

interface UseDefaultValueProps {
  disabled: boolean
  label: string | React.ReactNode | undefined
  onClick: (() => void) | undefined
}

class UseDefaultValue extends React.Component<UseDefaultValueProps> {
  handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onClick } = this.props
    if (onClick) {
      onClick()
    }
    e.preventDefault()
  }

  render () {
    const { disabled, label } = this.props
    return disabled ? (
      <span className='use'>{label}</span>
    ) : (
      <MediaQuery minWidth={MediaMinBreakpoint.sm}>
        {matches => (
          <button
            className='use'
            onClick={matches ? this.handleClick : undefined}
          >
            {label}
          </button>
        )}
      </MediaQuery>
    )
  }
}

export default UseDefaultValue

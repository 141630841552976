import * as React from 'react'
import { Name as NameModel } from '../../clientModels'
import { renderComplexAnswerLastYear } from '../../services/question/questionHelper'
import { Alerts } from '../forms/alerts/index'
import { FormFieldProps } from '../forms/formComponentsInterfaces'
import { TextBoxField } from '../forms/index'
import './name.scss'

// tslint:disable-next-line:no-any - value is not typed
export function isName (value: any): value is NameModel {
  return typeof value === 'object'
}

class Name extends React.Component<FormFieldProps> {
  // tslint:disable-next-line:no-any - value can be anything
  static formatValueLastYear = (value: any): string => {
    return renderComplexAnswerLastYear(
      value.prefix,
      value.firstName,
      value.middleInitial,
      value.lastName,
      value.suffix
    )
  }

  render () {
    const {
      onBlur,
      onChange,
      onFocus,
      value,
      disabled,
      messages,
      showAlerts,
      selectedPath,
    } = this.props

    const name = value || {}

    if (!isName(name)) {
      throw new Error('Name expects an object value')
    }

    const fieldProps = {
      disabled,
      messages,
      onBlur,
      onChange,
      onFocus,
      showAlerts: false,
      selectedPath,
      value,
    }

    return (
      <div className='name'>
        <TextBoxField
          {...{
            ...fieldProps,
            className: 'name-prefix',
            label: 'Prefix (optional)',
            path: 'prefix',
          }}
        />
        <div className='d-flex flex-wrap align-items-end'>
          <TextBoxField
            {...{
              ...fieldProps,
              className: 'name-first',
              label: 'First Name',
              path: 'firstName',
            }}
          />
          <TextBoxField
            {...{
              ...fieldProps,
              className: 'name-middle',
              label: 'Middle Initial (optional)',
              path: 'middleInitial',
            }}
          />
          <TextBoxField
            {...{
              ...fieldProps,
              className: 'name-last',
              label: 'Last Name',
              path: 'lastName',
            }}
          />
        </div>
        <TextBoxField
          {...{
            ...fieldProps,
            className: 'name-suffix',
            label: 'Suffix (optional)',
            path: 'suffix',
          }}
        />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

export default Name

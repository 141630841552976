import moment from 'moment'
import { createSelector } from 'reselect'
import {
  Client,
  Engagement,
  EngagementTemplate,
  EntityMap,
  Phase,
} from '../../clientModels'
import { MilestoneCode } from '../../enums'
import { ensureNumber, isDefinedNotNull as isDefined } from '../../guards'
import { SelectionDashboardFilters } from '../../reducers/selectionReducer'
import {
  getClients,
  getEngagements,
  getEngagementTemplates,
  getPhases,
} from '../../reducers/selectors'
import { reviewIsComplete } from '../../services/questionReviewUtilities'
import { AppState } from '../../store'

const isoMoment = (date?: string): moment.Moment =>
  moment(date, moment.ISO_8601)

const getDueDate = (e: Engagement, m: string): string | undefined => {
  let dueDate
  if (e.milestones) {
    const milestone = e.milestones.filter(x => x.milestone === m)[0]
    dueDate = milestone && milestone.dueDate
  }
  return dueDate
}

const createFilteredEngagementListSelector = () => {
  const getDefinedEngagementsList = createDefinedEngagementListSelector()
  const getDefinedEngagementTemplates = createEngagementTemplatesSelector()

  return createSelector(
    getDefinedEngagementsList,
    getDefinedEngagementTemplates,
    getDashboardFilters,
    (engagements, engagementTemplates, dashboardFilters) => {
      if (!dashboardFilters) {
        return engagements
      }
      const filter = createEngagementFilters(
        engagementTemplates,
        dashboardFilters
      )
      return engagements.filter(filter)
    }
  )
}

const createEngagementsSelector = () => {
  const getDefinedEngagementsList = createFilteredEngagementListSelector()

  const getDashboardSort = (state: AppState) => {
    return state.selection ? state.selection.selectedDashboardSort : undefined
  }

  const getIsExternal = (state: AppState) => {
    return !state.auth
      ? true
      : !state.auth.user
      ? true
      : state.auth.user.isExternal
  }

  return createSelector(
    getDefinedEngagementsList,
    getDashboardSort,
    getIsExternal,
    getClients,
    getPhases,
    (engagements: Engagement[], dashboardSort, isExternal, clients, phases) => {
      const clientSorts = createClientSorts(clients)
      const phaseSorts = createPhaseSorts(phases)
      const engagementSorts = createEngagementSorts()
      const clientNameSort = clientSorts.byClientName(engagementSorts.byName())
      const clientIdSort = clientSorts.byMasterId(
        clientSorts.byClientId(engagementSorts.byName())
      )
      const phaseSort = phaseSorts.byPhase(engagementSorts.byName())
      const dueDateSort = createDueDateSort(engagementSorts.byName())
      const lastUpdatedDateSort = createLastUpdatedSort(
        engagementSorts.byName()
      )
      switch (dashboardSort) {
        case 'ClientName':
          return [...engagements].sort(clientNameSort)
        case 'DueDate':
          return [...engagements].sort(dueDateSort)
        case 'MasterClientID':
          return [...engagements].sort(clientIdSort)
        case 'Status':
          return [...engagements].sort(phaseSort)
        case 'LastUpdatedDate':
          return [...engagements].sort(lastUpdatedDateSort)
        default:
          return [...engagements].sort(isExternal ? dueDateSort : phaseSort)
      }
    }
  )
}

const createClientSorts = (clients: EntityMap<Client>) => {
  if (!clients) {
    clients = {}
  }

  return {
    byMasterId: (next?: (left: Engagement, right: Engagement) => number) => {
      return (left: Engagement, right: Engagement): number => {
        if (!isDefined(left) || !isDefined(right)) {
          return isDefined(left)
            ? -1
            : isDefined(right)
            ? 1
            : next
            ? next(left, right)
            : 0
        }
        const leftClient = clients[left.clientId]
        const rightClient = clients[right.clientId]
        if (!isDefined(leftClient) || !isDefined(rightClient)) {
          return isDefined(leftClient)
            ? -1
            : isDefined(rightClient)
            ? 1
            : next
            ? next(left, right)
            : 0
        }
        const leftMasterId = leftClient.masterId
        const rightMasterId = rightClient.masterId
        if (!isDefined(leftMasterId) || !isDefined(rightMasterId)) {
          return isDefined(leftMasterId)
            ? -1
            : isDefined(rightMasterId)
            ? 1
            : next
            ? next(left, right)
            : 0
        }

        return leftMasterId < rightMasterId
          ? -1
          : leftMasterId > rightMasterId
          ? 1
          : next
          ? next(left, right)
          : 0
      }
    },
    byClientId: (next?: (left: Engagement, right: Engagement) => number) => {
      return (left: Engagement, right: Engagement): number => {
        if (!isDefined(left) || !isDefined(right)) {
          return isDefined(left)
            ? -1
            : isDefined(right)
            ? 1
            : next
            ? next(left, right)
            : 0
        }
        const leftClient = clients[left.clientId]
        const rightClient = clients[right.clientId]
        if (!isDefined(leftClient) || !isDefined(rightClient)) {
          return isDefined(leftClient)
            ? -1
            : isDefined(rightClient)
            ? 1
            : next
            ? next(left, right)
            : 0
        }
        const leftClientId = leftClient.id
        const rightClientId = rightClient.id
        if (!isDefined(leftClientId) || !isDefined(rightClientId)) {
          return isDefined(leftClientId)
            ? -1
            : isDefined(rightClientId)
            ? 1
            : next
            ? next(left, right)
            : 0
        }

        return leftClientId < rightClientId
          ? -1
          : leftClientId > rightClientId
          ? 1
          : next
          ? next(left, right)
          : 0
      }
    },
    byClientName: (next?: (left: Engagement, right: Engagement) => number) => {
      return (left: Engagement, right: Engagement): number => {
        if (!isDefined(left) || !isDefined(right)) {
          return isDefined(left)
            ? -1
            : isDefined(right)
            ? 1
            : next
            ? next(left, right)
            : 0
        }
        const leftClient = clients[left.clientId]
        const rightClient = clients[right.clientId]
        if (!isDefined(leftClient) || !isDefined(rightClient)) {
          return isDefined(leftClient)
            ? -1
            : isDefined(rightClient)
            ? 1
            : next
            ? next(left, right)
            : 0
        }
        const leftName = leftClient.name
        const rightName = rightClient.name
        if (!isDefined(leftName) || !isDefined(rightName)) {
          return isDefined(leftName) ? -1 : isDefined(rightName) ? 1 : 0
        }

        return leftName < rightName ? -1 : leftName > rightName ? 1 : 0
      }
    },
  }
}

const createPhaseSorts = (phases: EntityMap<Phase>) => {
  if (!phases) {
    phases = {}
  }

  return {
    byPhase: (next?: (left: Engagement, right: Engagement) => number) => {
      return (left: Engagement, right: Engagement): number => {
        if (!isDefined(left) || !isDefined(right)) {
          return isDefined(left)
            ? 1
            : isDefined(right)
            ? -1
            : next
            ? next(left, right)
            : 0
        }
        if (!isDefined(left.phase) || !isDefined(right.phase)) {
          return next ? next(left, right) : 0
        }
        const leftPhase = phases[left.phase]
        const rightPhase = phases[right.phase]
        if (!isDefined(leftPhase) || !isDefined(rightPhase)) {
          return isDefined(leftPhase)
            ? 1
            : isDefined(rightPhase)
            ? -1
            : next
            ? next(left, right)
            : 0
        }
        const leftDisplayOrder = leftPhase.displayOrder
        const rightDisplayOrder = rightPhase.displayOrder
        if (!isDefined(leftDisplayOrder) || !isDefined(rightDisplayOrder)) {
          return isDefined(leftDisplayOrder)
            ? 1
            : isDefined(rightDisplayOrder)
            ? -1
            : next
            ? next(left, right)
            : 0
        }

        return leftDisplayOrder < rightDisplayOrder
          ? -1
          : leftDisplayOrder > rightDisplayOrder
          ? 1
          : next
          ? next(left, right)
          : 0
      }
    },
  }
}

const createEngagementSorts = () => {
  return {
    byName: (next?: (left: Engagement, right: Engagement) => number) => {
      return (left: Engagement, right: Engagement): number => {
        if (!isDefined(left) || !isDefined(right)) {
          return isDefined(left)
            ? 1
            : isDefined(right)
            ? -1
            : next
            ? next(left, right)
            : 0
        }

        const leftName = left.name
        const rightName = right.name

        if (!isDefined(leftName) || !isDefined(rightName)) {
          return isDefined(leftName)
            ? 1
            : isDefined(rightName)
            ? -1
            : next
            ? next(left, right)
            : 0
        }

        return leftName < rightName
          ? -1
          : leftName > rightName
          ? 1
          : next
          ? next(left, right)
          : 0
      }
    },
  }
}

const createDueDateSort = (
  next?: (left: Engagement, right: Engagement) => number
) => {
  return (left: Engagement, right: Engagement) => {
    const leftDate = isoMoment(getDueDate(left, MilestoneCode.CCH))
    const rightDate = isoMoment(getDueDate(right, MilestoneCode.CCH))

    return leftDate.isAfter(rightDate)
      ? 1
      : rightDate.isAfter(leftDate)
      ? -1
      : next
      ? next(left, right)
      : 0
  }
}

const createLastUpdatedSort = (
  next?: (left: Engagement, right: Engagement) => number
) => {
  return (left: Engagement, right: Engagement) => {
    const leftDate = isoMoment(left.lastUpdatedDate)
    const rightDate = isoMoment(right.lastUpdatedDate)

    return leftDate.isAfter(rightDate)
      ? -1
      : rightDate.isAfter(leftDate)
      ? 1
      : next
      ? next(left, right)
      : 0
  }
}

const createDefinedEngagementListSelector = () => {
  return createSelector(getEngagements, engagements => {
    if (!engagements) {
      return []
    }
    return Object.values(engagements)
      .filter(isDefined)
      .filter(e => e.visible)
  })
}

const createEngagementTemplatesSelector = () => {
  return createSelector(getEngagementTemplates, engagementTemplates => {
    if (!engagementTemplates) {
      return {}
    }
    return engagementTemplates
  })
}

const getDashboardFilters = (state: AppState) => {
  return state.selection.selectedDashboardFilters
}

const createEngagementFilters = (
  engagementTemplates: EntityMap<EngagementTemplate>,
  dashboardFilters: SelectionDashboardFilters
) => {
  const filters: Array<(engagement: Engagement) => boolean> = []
  if (dashboardFilters.DateFilters) {
    const dateFilters: Array<(
      taxYear: moment.Moment,
      dueDates: moment.Moment[]
    ) => boolean> = []
    const today = moment()
    const nextMonth = moment().add(1, 'month')
    for (const dateFilter of dashboardFilters.DateFilters) {
      switch (dateFilter.filter) {
        case 'DueNextMonth':
          dateFilters.push(
            (taxYear: moment.Moment, dueDates: moment.Moment[]) => {
              let foundDueDate = false
              dueDates.forEach(dueDate => {
                if (dueDate.month() === nextMonth.month()) {
                  foundDueDate = true
                }
              })
              return foundDueDate
            }
          )
          break
        case 'DueThisMonth':
          dateFilters.push(
            (taxYear: moment.Moment, dueDates: moment.Moment[]) => {
              let foundDueDate = false
              dueDates.forEach(dueDate => {
                if (dueDate.month() === today.month()) {
                  foundDueDate = true
                }
              })
              return foundDueDate
            }
          )
          break
        case 'EnterDueDate':
          dateFilters.push(
            (taxYear: moment.Moment, dueDates: moment.Moment[]) => {
              let foundDueDate = !dateFilter.date
              dueDates.forEach(dueDate => {
                if (dueDate.isBefore(dateFilter.date)) {
                  foundDueDate = true
                }
              })
              return foundDueDate
            }
          )
          break
        case 'EnterTaxYear':
          dateFilters.push(
            (taxYear: moment.Moment, dueDates: moment.Moment[]) => {
              return !dateFilter.date || taxYear.isBefore(dateFilter.date)
            }
          )
          break
        default:
          break
      }
    }
    filters.push((engagement: Engagement) => {
      const milestoneCodes = [
        MilestoneCode.Setup,
        MilestoneCode.PBC,
        MilestoneCode.Review,
        MilestoneCode.CCH,
        MilestoneCode.IRS,
      ]

      const dueDates = milestoneCodes
        .map(milestoneCode => {
          return isoMoment(getDueDate(engagement, milestoneCode))
        })
        .filter(dueDate => dueDate.isValid())

      const taxYear = isoMoment(getDueDate(engagement, MilestoneCode.IRS))
      for (const dateFilter of dateFilters) {
        if (dateFilter(taxYear, dueDates)) {
          // first true in an or chain makes the whole chain true
          return true
        }
      }
      return false
    })
  }
  if (dashboardFilters.StatusFilters) {
    const statusFilters: Array<(engagement: Engagement) => boolean> = []
    for (const statusFilter of dashboardFilters.StatusFilters) {
      switch (statusFilter) {
        case 'Completed':
          statusFilters.push((engagement: Engagement): boolean => {
            return engagement.phase === 'cch'
          })
          break
        case 'InProgress':
          statusFilters.push((engagement: Engagement): boolean => {
            return engagement.phase === 'pbc'
          })
          break
        case 'NotStarted':
          statusFilters.push((engagement: Engagement): boolean => {
            return (
              engagement.phase === 'setup' &&
              (engagement.lastQuestionId === undefined ||
                engagement.lastQuestionId === null)
            )
          })
          break
        case 'ReviewReady':
          statusFilters.push((engagement: Engagement): boolean => {
            return (
              ((engagement.phase === 'pbc' &&
                engagement.successfulQuestions === engagement.totalQuestions) ||
                engagement.phase === 'review') &&
              !engagement.concurringReviewStarted &&
              !engagement.preparerReviewStarted &&
              !engagement.primaryReviewStarted &&
              !engagement.secondaryReviewStarted
            )
          })
          break
        case 'ReviewDone':
          statusFilters.push((engagement: Engagement): boolean => {
            return engagement.phase === 'review' && reviewIsComplete(engagement)
          })
          break
        default:
          break
      }
    }
    filters.push((engagement: Engagement) => {
      for (const statusFilter of statusFilters) {
        if (statusFilter(engagement)) {
          // first true in an or chain makes the whole chain true
          return true
        }
      }
      return false
    })
  }
  if (dashboardFilters.YearFilters) {
    const yearFilters: Array<(engagement: Engagement) => boolean> = []
    for (const yearFilter of dashboardFilters.YearFilters) {
      yearFilters.push((engagement: Engagement): boolean => {
        const template = engagementTemplates[engagement.template as number]
        return !!template && template.taxYear === ensureNumber(yearFilter)
      })
    }
    filters.push((engagement: Engagement) => {
      for (const yearFilter of yearFilters) {
        if (yearFilter(engagement)) {
          return true
        }
      }
      return false
    })
  }
  if (dashboardFilters.TypeFilters) {
    const typeFilters: Array<(engagement: Engagement) => boolean> = []
    for (const typeFilter of dashboardFilters.TypeFilters) {
      typeFilters.push((engagement: Engagement): boolean => {
        const template = engagementTemplates[engagement.template as number]
        return !!template && template.resourceType === typeFilter
      })
    }
    filters.push((engagement: Engagement) => {
      for (const typeFilter of typeFilters) {
        if (typeFilter(engagement)) {
          return true
        }
      }

      return false
    })
  }

  return (engagement: Engagement): boolean => {
    for (const filter of filters) {
      if (!filter(engagement)) {
        return false
      }
    }

    return true
  }
}

export const engagementsSelector = createEngagementsSelector()

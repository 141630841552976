import React, { useEffect, useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../store'
import { ensureNumber } from '../../guards'
import { SidebarNames } from '../../clientModels'
import { actions } from '../../actions'
import {
  reviewRolesForQuestion,
  questionNeedsReview,
} from '../../services/questionReviewUtilities'
import { BottomDrawer } from '../bottomDrawer'
import classNames from 'classnames'
import { Help } from '../help'
import { ActivityListContainer } from '../activity'
import { useQuestionDisabled } from '../../services/question/useQuestionDisabled'
import './engagementBottomDrawer.scss'
import Comment from '../comment/comment'
import { FileUpload } from '../fileUpload'
import Icon from '../icon/icon'

interface RouteParams {
  activityId?: string
  engagementId: string
  questionId: string
  sectionId?: string
}

interface EngagementBottomDrawerProps extends RouteComponentProps<RouteParams> {
  open?: boolean
  questionDisabled: boolean
}


export const EngagementBottomDrawer = (props: EngagementBottomDrawerProps) => {
  const {
    match: {
      params: { activityId, engagementId, questionId },
    },
  } = props

  const dispatch = useDispatch()

  const selectSidebar = useCallback((component: SidebarNames) => {
    dispatch(
      actions.selection.selectionSidebarSelectAction({
        selectedSidebarName: component,
        toggle: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    selectSidebar('')
  }, [selectSidebar])

  const engagement = useSelector((state: AppState) => {
    return state.engagements[ensureNumber(engagementId)]
  })

  const engagementQuestions = useSelector((state: AppState) => {
    return state.engagementQuestions[engagementId]
  })
  const engagementQuestion =
    engagementQuestions && engagementQuestions[questionId]

  const question = useSelector((state: AppState) => {
    return state.questions[questionId]
  })

  const selectedSidebar = useSelector((state: AppState) => {
    return state.selection.selectedSidebarName
  })

  const showMoreHelp = useSelector((state: AppState) => {
    return state.selection.showMoreHelp
  })

  const user = useSelector((state: AppState) => {
    return state.auth.user
  })

  const questionDisabled = useQuestionDisabled(engagementId, questionId)

  const toggleFlagged = () => {
    dispatch(actions.flag.toggleFlagged(engagementId, questionId))
  }

  const toggleTickMarks = () => {
    dispatch(actions.review.toggleTickMarks(engagementId, questionId))
  }

  const selectHelp = () => selectSidebar('help')
  const selectActivity = () => selectSidebar('activity')
  const selectFile = () => selectSidebar('file')
  const close = () => selectSidebar('')

  const renderContent = () => {
    if (!question) {
      return null
    }

    switch (selectedSidebar) {
      default:
        return null

      case 'help':
        return <Help question={question} readMore={showMoreHelp} />

      case 'activity':
        return (
          <React.Fragment>
            <ActivityListContainer
              autoScroll={false}
              engagementId={engagementId}
              question={question}
              selectedActivityId={activityId}
              questionDisabled={questionDisabled}
            />
            <Comment
              engagementId={engagementId}
              questionId={questionId}
              showMention={true}
              questionDisabled={questionDisabled}
            />
          </React.Fragment>
        )

      case 'file':
        return (
          <FileUpload
            engagementId={ensureNumber(engagementId)}
            question={question}
          />
        )
    }
  }

  const reviewerRoles = reviewRolesForQuestion(engagement, question, user)
  const isReviewed = !questionNeedsReview(
    engagement,
    question,
    engagementQuestion,
    user
  )

  const showReviewCheckmark = reviewerRoles.length > 0
  const showHelpIcon = question && question.help

  if (!engagementQuestion) {
    return <BottomDrawer />
  }

  const flagClassName = classNames('flag-icon', {
    active: engagementQuestion.flagged,
  })

  return (
    <BottomDrawer
      className='engagement-bottom-drawer'
      onClose={close}
      open={!!selectedSidebar}
      renderContent={renderContent}
    >
      <div className='engagement-bottom-drawer-left'>
        {showReviewCheckmark && (
          <Icon
            active={isReviewed}
            icon='circleBigCheck'
            onClick={toggleTickMarks}
          />
        )}
        <div className={flagClassName} onClick={toggleFlagged}>
          <Icon icon='flag' />
        </div>
      </div>
      <div className='engagement-bottom-drawer-right'>
        {showHelpIcon && <Icon icon='questionCircle' onClick={selectHelp} />}
        <Icon icon='comment' onClick={selectActivity} />
        <Icon icon='fileText' onClick={selectFile} />
      </div>
    </BottomDrawer>
  )
}

import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { ensureNumber } from '../../guards'
import { PhaseCode } from '../../enums'
import { engagementLockedPhase } from '../engagementPhaseService'
import { Activity } from '../../clientModels'
import { usePermissionManager } from '../../hooks'

export function useQuestionDisabled(
  engagementId: string | number,
  questionId: string | number
) {
  const engagements = useSelector((state: AppState) => {
    return state.engagements
  })
  const activities = useSelector((state: AppState) => {
    return state.activities
  })
  const engagementQuestions = useSelector((state: AppState) => {
    return state.engagementQuestions[engagementId]
  })
  const user = useSelector((state: AppState) => {
    return state.auth && state.auth.user
  })
  const engagement = engagements[ensureNumber(engagementId)]
  const engagementQuestion =
    engagementQuestions && engagementQuestions[questionId]
  const permissionManager = usePermissionManager()
  const questionDisabledPhases = [
    PhaseCode.Review,
    PhaseCode.CCH,
    PhaseCode.IRS,
    PhaseCode.Closed,
    undefined,
  ]

  if (!engagement || !user) {
    return true
  }

  if (!permissionManager.isExternalUser) {
    return engagement.isEnabled !== undefined
      ? engagement.isEnabled
      : engagementLockedPhase(engagement)
  }

  if (engagementQuestion && engagementQuestion.isEnabled) {
    return false
  }

  const disabledByPhase = questionDisabledPhases.includes(engagement.phase)
  if (!disabledByPhase) {
    // We lock questions during the review, cch, closed, and irs phases for external users
    return false
  }

  const engagementActivities = activities[ensureNumber(engagementId)]
  const questionActivities =
    (engagementActivities && engagementActivities[questionId]) || []
  const hasUnresolvedClientConversation = questionActivities.some(parent => {
    if (parent.parentId) {
      // This isn't actually a parent activity so move on
      return false
    }

    if (!mentionsClient(parent)) {
      // We only allow conversations that start by mentioning the client to unlock a question
      return false
    }

    if (hasUnresolveMention(parent)) {
      // If the parent has an unresolved mention then unlock the question
      return true
    }

    // If any of the parent's children have an unresolved mention then unlock the question
    return questionActivities.some(
      c => c.parentId === parent.id && hasUnresolveMention(c)
    )
  })

  return !hasUnresolvedClientConversation
}

function hasUnresolveMention(activity: Activity) {
  return !!activity.mentions && activity.mentions.some(m => !m.resolved)
}

function mentionsClient(activity: Activity) {
  return !!activity.mentions && activity.mentions.some(m => m.label !== 'RSM')
}

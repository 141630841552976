import classNames from 'classnames'
import computeScrollIntoView from 'compute-scroll-into-view'
import {
  cloneDeep,
  compact,
  filter,
  find,
  isEmpty,
  remove,
  uniqueId
} from 'lodash'
import * as React from 'react'
import ReactDataSheet from 'react-datasheet'
import MediaQuery from 'react-responsive'
import { Prompt, Redirect, withRouter, StaticContext } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { DocumentTitle, PropertyValues, Question } from '../../clientModels'
import displayName from '../../displayName'
import { safeAnswerLength } from '../../guards'
import { defaultMaxRows, defaultMinRows } from '../datasheet/index'
import { EngagementSidebarCollapser } from '../engagementSidebar'
import { findErrors } from '../forms/formUtilities'
import {
  GeneralAlerts,
  OptionsFormFieldProps,
  PathAlerts
} from '../forms/index'
import Icon from '../icon/icon'
import { WarningDialog } from '../modals/index'
import { QuestionOwnProps } from '../question/question'
import '../question/question.scss'
import { joinPaths } from '../relativeLink'
import AddRow from './addRow'
import './questionEdit.scss'

/*
    This component appears to only be related to editable tables (datagrid).  Other question controls do not use this HOC.
*/

interface QuestionState {
  canCollapseLastYear?: boolean
  fromUseThis?: boolean
  isAnswerDirty?: boolean
  navigatePush?: boolean
  navigateTo?: string
  previousPath?: string | null
  redirectToQuestion?: boolean
  selectedPath?: string | null
  selectedRowIndices?: number[]
  showAdd?: boolean | null
  showRemove?: boolean | null
  showWarningDialog?: boolean
  copyAllClicked?: boolean
}

interface QuestionEditProps
  extends QuestionOwnProps,
    RouteComponentProps<{}, StaticContext, { fromUseThis: boolean }> {}

export interface QuestionWrappedComponentInterface {
  // tslint:disable-next-line:no-any - not able to specify type at this level
  formatValueLastYear?: (value: any, props: any) => React.ReactNode
  isReadOnly?: boolean
}

function getMinItems(question?: Question) {
  const result = {
    minItems: 0
  }
  if (question && question.answerSchema && question.answerSchema.minItems) {
    result.minItems = question.answerSchema.minItems
  }
  return result
}

type FormFieldComponentType<
  WrappedComponentProps extends OptionsFormFieldProps
> = React.ComponentType<WrappedComponentProps> &
  QuestionWrappedComponentInterface

export const questionEdit = <
  WrappedComponentProps extends OptionsFormFieldProps
>(
  FormFieldComponent: FormFieldComponentType<WrappedComponentProps>,
  minWidth?: number
) =>
  withRouter(
    class extends React.Component<QuestionEditProps, QuestionState> {
      static displayName = displayName('QuestionEdit', FormFieldComponent)

      formFieldComponent?: FormFieldComponentType<WrappedComponentProps>
      id: string
      labelId: string
      hintId: string
      outer = React.createRef<HTMLDivElement>()
      state: QuestionState = {}
      minWidth?: number

      constructor(props: QuestionEditProps) {
        super(props)
        const id = (this.id = uniqueId('question_'))
        this.labelId = `${id}_label`
        this.hintId = `${id}_hint`
        this.onChange = this.onChange.bind(this)
        this.setState = this.setState.bind(this)
        this.updateAddRemove = this.updateAddRemove.bind(this)
        this.scroll = this.scroll.bind(this)
        this.selectFirstInput = this.selectFirstInput.bind(this)

        this.state = {
          isAnswerDirty: false,
          redirectToQuestion: false,
          showWarningDialog: false,
          copyAllClicked: false
        }

        this.updateAddRemove(this.state, props)

        if (props.location.state && props.location.state.fromUseThis) {
          this.state.fromUseThis = true
        }

        this.minWidth = minWidth
      }

      addRow = (value: string) => {
        const selectedRowIndices = this.state.selectedRowIndices
        if (!selectedRowIndices || !this.props.answer) {
          return
        }

        const { answer } = this.props
        const answerValue = answer && answer.answerValue

        if (Array.isArray(answerValue)) {
          this.props.answer.isDirty = true

          const insertAtIndex =
            value === 'above'
              ? selectedRowIndices.length > 1
                ? selectedRowIndices[1]
                : selectedRowIndices[0]
              : selectedRowIndices[0] + 1
          const maxItemsToAdd = defaultMaxRows - answerValue.length - 1
          const numOfItems = Math.min(
            maxItemsToAdd,
            selectedRowIndices.length === 1
              ? 1
              : selectedRowIndices[0] - selectedRowIndices[1] + 1
          )

          // We have to splice in separately or the empty objects are seen as the same instance
          new Array(numOfItems).fill({}).forEach(newItemIndex => {
            answerValue.splice(insertAtIndex, 0, {})
          })

          this.setState({ isAnswerDirty: true })
          this.updateAddRemove()
        }
      }

      closeModal = () => {
        this.setState({ showWarningDialog: false, redirectToQuestion: true })
      }

      closeModalExit = () => {
        const { onClickCancel } = this.props
        // onClickCancel makes an API call to get answer from the database and updates the store
        if (onClickCancel !== undefined) {
          onClickCancel()
        }
        this.setState({ showWarningDialog: false, redirectToQuestion: true })
      }

      componentDidMount() {
        if (this.props.selected) {
          this.scroll()
          this.selectFirstInput()
        }
      }

      // eslint-disable-next-line
      UNSAFE_componentWillReceiveProps(nextProps: QuestionEditProps) {
        const {
          props: { selected },
          state,
          setState,
          updateAddRemove,
          scroll,
          selectFirstInput
        } = this
        const nextState = state
        let updatedState = false
        if (selected !== nextProps.selected) {
          if (nextProps.selected) {
            // This question was just selected
            scroll()
            selectFirstInput()
          } else {
            // This question was just unselected
            updatedState = true
            nextState.previousPath = null
          }
        }

        updatedState = updateAddRemove(nextState, nextProps) || updatedState

        if (
          !nextState.fromUseThis &&
          nextProps.location.state &&
          nextProps.location.state.fromUseThis
        ) {
          nextState.fromUseThis = true
          updatedState = true
        }

        if (updatedState) {
          setState(nextState)
        }
        if (this.props.answer && this.props.answer.isDirty === true) {
          this.setState({ isAnswerDirty: true })
        }
      }

      deleteRows = () => {
        if (this.props.answer) {
          const startEnd = this.state.selectedRowIndices
          if (!startEnd) {
            return
          }

          const numRowsDeleted =
            startEnd.length === 1 ? 1 : startEnd[0] - startEnd[1] + 1
          const rowIndices = new Array(numRowsDeleted)
            .fill(1)
            .map((rowIndex, index) => index + startEnd[1])

          // tslint:disable-next-line:no-any - could be anything
          const grid = this.formFieldComponent as any
          if (grid && grid.deleteRows) {
            // Ensure we save the grid row deletion(s) on the server side
            const { saveQuestion, answer } = this.props
            if (saveQuestion) {
              saveQuestion(answer, false)
            }
            grid.deleteRows(rowIndices)
          }
        }
      }

      deleteRowKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === ' ' || e.key === 'Enter') {
          this.deleteRows()
        }
      }

      handleCopyAll = () => {
        this.setState({ copyAllClicked: true })
        // tslint:disable-next-line:no-any - could be anything
        const grid = this.formFieldComponent as any
        if (grid && grid.copyAll) {
          grid.copyAll()
        }
        setTimeout(() => {
          this.setState({ copyAllClicked: false })
        }, 5000)
      }

      dismissCopyAll = () => {
        this.setState({ copyAllClicked: false })
      }

      handleFlagClick = (e: React.SyntheticEvent<HTMLElement>) => {
        this.props.onClickFlag()
      }

      handleFocus = (path?: string) => {
        this.setState(
          { selectedPath: path || null, previousPath: path || null },
          this.selectQuestion
        )
      }

      handleReviewClick = (e: React.MouseEvent<HTMLElement>) => {
        this.props.onClickTickmark()
      }

      handleReviewKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
          this.props.onClickTickmark()
          e.preventDefault()
        }
      }

      // tslint:disable-next-line:no-any - could work for any input, conceivably
      handleSelectionChanged = (selection: any) => {
        // Grids
        const gridSelection = selection as ReactDataSheet.Selection
        if (gridSelection) {
          const startEnd = [
            gridSelection.start.i,
            gridSelection.end.i
          ].sort((first, second) => (first < second ? 1 : -1))
          this.setState({ selectedRowIndices: startEnd }, () => {
            this.updateAddRemove()
            this.forceUpdate()
          })
        }
      }

      // tslint:disable-next-line:no-any - value could be anything
      async onChange(value: any, path?: string) {
        this.updateAddRemove()
        this.props.onChange(value, path)
      }

      requiredDocumentTitles(): DocumentTitle[] {
        const { engagementQuestion, documentTitles } = this.props

        // documentTitles are used to render a placeholder for required documents
        // only show them if docsRequired is true
        return engagementQuestion &&
          engagementQuestion.requiredDocumentTitleIds.length > 0 &&
          documentTitles
          ? filter(documentTitles, x =>
              engagementQuestion.requiredDocumentTitleIds.includes(x.id)
            )
          : []
      }

      save = () => {
        const { saveQuestion, answer, question } = this.props

        if (answer && answer.isDirty === true) {
          // Remove empty rows as necessary
          const { minItems } = getMinItems(question)
          if (
            Array.isArray(answer.answerValue) &&
            answer.answerValue.length > minItems
          ) {
            remove(answer.answerValue, (answerValue: object, index: number) => {
              return index > minItems && isEmpty(answerValue)
            })
          }

          // onBlur should contain the save method, but we'd like to be more explicit
          //  with our intentions, and use the saveQuestion method instead.
          //onBlur(answer, true)

          if (!saveQuestion) {
            throw new Error(`saveQuestion must be provided to save answers.`)
          }
          saveQuestion(answer, false)
        }
      }

      exitEditMode = () => {
        const { location } = this.props
        this.setState({
          navigateTo: location.pathname.substring(
            0,
            location.pathname.length - 5
          ),
          navigatePush: true,
          showWarningDialog: false,
          redirectToQuestion: true
        })
      }

      scroll() {
        const outerDiv = this.outer.current
        if (outerDiv) {
          computeScrollIntoView(outerDiv, {
            block: 'nearest',
            scrollMode: 'if-needed'
          }).forEach(({ el, top, left }) => {
            el.scrollTop = top
            el.scrollLeft = left
          })
        }
      }

      selectFirstInput(el?: HTMLElement): any {
        if (!el) {
          const outerDiv = this.outer.current
          if (!outerDiv) {
            return
          }
          return this.selectFirstInput(outerDiv)
        }

        if (el.nodeName === 'INPUT' || el.nodeName === 'TEXTAREA') {
          el.focus()
          return el
        }

        return find(
          compact(el.children),
          (child: HTMLElement) => !!this.selectFirstInput(child)
        )
      }

      setFormFieldComponent = (
        ref: FormFieldComponentType<WrappedComponentProps>
      ) => {
        this.formFieldComponent = ref
      }

      selectQuestion = () => {
        const { disabled, onSelect, question, selected } = this.props
        if (question && !selected && !disabled) {
          onSelect(question.id)
        }
      }

      showMoreLess = () => {
        this.setState({ canCollapseLastYear: !this.state.canCollapseLastYear })
      }

      updateAddRemove(
        state: QuestionState = this.state,
        nextProps: QuestionEditProps = this.props
      ): boolean {
        let result: boolean = false

        if (nextProps.question && nextProps.question.answerSchema) {
          const {
            props: { question, answer }
          } = this
          const newMinRows =
            nextProps.question.answerSchema.minItems || defaultMinRows
          const newMaxRows =
            (nextProps.question.answerSchema.maxItems || defaultMaxRows) - 1
          const newRows =
            (nextProps.answer &&
              Array.isArray(nextProps.answer.answerValue) &&
              nextProps.answer.answerValue.length) ||
            undefined
          let oldMinRows: number | undefined
          let oldMaxRows: number | undefined
          let oldRows: number | undefined

          if (question) {
            const { answerSchema } = question
            oldMinRows = answerSchema.minItems || defaultMinRows
            oldMaxRows = answerSchema.maxItems || defaultMaxRows
          }

          if (answer && Array.isArray(answer.answerValue)) {
            oldRows = answer.answerValue.length
          }

          result =
            oldRows !== newRows ||
            oldMinRows !== newMinRows ||
            oldMaxRows !== newMaxRows
          state.showAdd =
            state.selectedRowIndices &&
            nextProps.answer &&
            (newRows === undefined || newRows < newMaxRows) &&
            newMinRows !== newMaxRows

          const nextAnswerValueLength = safeAnswerLength(nextProps.answer)
          state.showRemove =
            state.selectedRowIndices &&
            nextProps.answer &&
            newRows !== undefined &&
            newRows > newMinRows &&
            newMinRows !== newMaxRows &&
            nextAnswerValueLength > Math.max(...state.selectedRowIndices)
        }

        return result
      }

      useLastYear = () => {
        const { answer } = this.props
        this.onChange(answer && cloneDeep(answer.answerValueLastYear))
        this.save()
        this.selectFirstInput()
      }

      handleBulkChange = async (
        propertyChanges: PropertyValues,
        skipRules?: boolean,
        runTableRules?: boolean
      ): Promise<void> => {
        if (this.props.onBulkChange) {
          await this.props.onBulkChange(
            propertyChanges,
            skipRules,
            runTableRules
          )
        }

        this.save()
      }

      render() {
        const {
          addRow,
          deleteRowKeyPress,
          deleteRows,
          handleCopyAll,
          onChange,
          exitEditMode,
          props: {
            answer,
            codeListIdToOptionsId,
            disabled,
            engagementId,
            engagementQuestion,
            match,
            optionLists,
            options,
            question,
            reviewRolesComplete,
            reviewRolesForUser
          },
          state: {
            copyAllClicked,
            fromUseThis,
            isAnswerDirty,
            navigatePush,
            navigateTo,
            previousPath,
            redirectToQuestion,
            selectedPath,
            selectedRowIndices,
            showAdd,
            showRemove
          }
        } = this

        if (!question) {
          return null
        }

        const sectionId = question.sectionId
        const questionId = question.id
        const notApplicable = answer && answer.notApplicable
        const disabledOrNA = disabled || notApplicable

        if (redirectToQuestion === true || disabledOrNA) {
          return (
            <Redirect
              to={joinPaths(
                match.url,
                `engagements/${engagementId}/sections/${sectionId}/questions/${questionId}`
              )}
            />
          )
        }

        const messages = findErrors(
          undefined,
          undefined,
          undefined,
          answer,
          true,
          engagementQuestion
        )

        // These are the properties passed to the grid cell controls of the datagrid.
        const fieldProps = {
          codeListIdToOptionsId,
          component: question.answerSchema.component,
          disabled: disabledOrNA,
          engagementId,
          hint: question.hint,
          jsonSchema: question.answerSchema,
          messages: messages.allMessages,
          onBulkChange: this.handleBulkChange,
          onChange,
          onFocus: this.handleFocus,
          onSelectionChange: this.handleSelectionChanged,
          saveQuestion: this.save,
          optionLists,
          options,
          questionId: question.id,
          sectionId: question.sectionId,
          selectedPath: selectedPath || undefined,
          showAlerts: false,
          value: answer && answer.answerValue,
          minWidth: this.minWidth
        }

        const flagged = answer && answer.flagged
        const useThis =
          fromUseThis &&
          answer &&
          Array.isArray(answer.answerValueLastYear) &&
          answer.answerValueLastYear.length > 0
        const selectedRowCount =
          selectedRowIndices &&
          selectedRowIndices[0] - selectedRowIndices[1] + 1
        const multipleRowsSelected = !!selectedRowCount && selectedRowCount > 1
        const selectedRowEnding = multipleRowsSelected ? 's' : ''

        // Tick marks (review)
        const reviewsMissing = reviewRolesForUser.filter(r =>
          reviewRolesComplete.includes(r)
        )
        const reviewComplete = reviewsMissing.length > 0

        return (
          <div
            className={classNames('question-edit selected', { disabled })}
            id={`question-edit-id-${question.id}`}
            ref={this.outer}
            onClick={this.selectQuestion}
          >
            {!!isAnswerDirty && (
              <Prompt
                message={`Discard Answer?${engagementId}-${questionId}`}
                when={true}
              />
            )}
            <div className='question-edit-form'>
              <div className='question-edit-label'>
                <div className='question-edit-label-text'>
                  <div className='text'>
                    <label id={this.labelId}>
                      <span className='question-edit-number'>
                        {question.displayNumber}
                      </span>{' '}
                      &nbsp; {question.text}
                    </label>
                  </div>
                  <div className='buttons'>
                    <div className='buttons-center'>
                      {/* <div className='cancel' onClick={cancel}>
                        Cancel
                      </div> */}
                      <div className='done' onClick={() => exitEditMode()}>
                        Exit
                      </div>
                    </div>
                  </div>
                </div>
                {question.hint && (
                  <div id={this.hintId} className='question-hint-text'>
                    {question.hint}
                  </div>
                )}
                <div className='question-modify-rows'>
                  <div
                    className={classNames(
                      'add-delete-container',
                      showAdd || showRemove ? '' : 'invisible'
                    )}
                  >
                    <AddRow
                      className={classNames(showAdd ? '' : 'hide')}
                      icon='plusCircle'
                      multipleRowsSelected={multipleRowsSelected}
                      onChange={addRow}
                    />
                    <div
                      className={classNames(
                        showRemove ? '' : 'hide',
                        'delete-rows'
                      )}
                      tabIndex={0}
                      onClick={deleteRows}
                      onKeyPress={deleteRowKeyPress}
                    >
                      <Icon icon='delete' /> <div>Row{selectedRowEnding}</div>
                    </div>
                  </div>
                  <div className='copy-all'>
                    <div>
                      <span onClick={handleCopyAll}>
                        <Icon icon='copy' />
                        Copy All
                      </span>
                      {copyAllClicked && (
                        <span className='copy-all-text'>
                          - data has been copied to your clipboard.
                          <span
                            className='copy-all-text-dismiss'
                            onClick={this.dismissCopyAll}
                          >
                            OK
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <FormFieldComponent
                getRef={this.setFormFieldComponent}
                {...(fieldProps as any)}
              />
              <div className='question-edit-footer'>
                <div className='question-edit-footer-metadata'>
                  <div className='question-edit-alerts'>
                    {!disabled && (
                      <GeneralAlerts messages={messages.allMessages} />
                    )}
                    {!disabled && (
                      <PathAlerts
                        messages={messages.allMessages}
                        selectedPath={previousPath || undefined}
                        onSelectPath={
                          !FormFieldComponent.isReadOnly
                            ? this.handleFocus
                            : undefined
                        }
                      />
                    )}
                  </div>
                </div>
                <div className='question-edit-buttons'>
                  {reviewRolesForUser.length > 0 && (
                    <div
                      id={`question-edit-check-icon-${question && question.id}`}
                      className='clickable'
                      onClick={this.handleReviewClick}
                      onKeyDown={this.handleReviewKeyPress}
                      tabIndex={0}
                    >
                      <Icon
                        icon='circleBigCheck'
                        className='question-edit-icon-large'
                        active={reviewComplete}
                      />
                    </div>
                  )}
                  <div
                    id={`question-edit-flag-icon-${question && question.id}`}
                    className={classNames(
                      'question-edit-icon-large hover-to-full-opacity',
                      { active: flagged }
                    )}
                    onClick={this.handleFlagClick}
                  >
                    <Icon
                      icon='flags'
                      className='question-edit-icon-large'
                      active={flagged}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.minWidth && (
              <MediaQuery minWidth={this.minWidth}>
                {matches => {
                  return (
                    !matches && (
                      <WarningDialog
                        title='Warning'
                        info='You can not update the table on this device. Please try again on a device with a wider screen.'
                        primaryButtonText='Exit'
                        onClickPrimary={this.closeModalExit}
                        onClose={this.closeModalExit}
                      />
                    )
                  )
                }}
              </MediaQuery>
            )}
            {navigateTo && <Redirect to={navigateTo} push={navigatePush} />}
            <EngagementSidebarCollapser />
          </div>
        )
      }
    }
  )

import {
  CchFieldTranslation,
  ClientEntity,
  ClientFilterNode,
  Engagement as ClientEngagment,
  IdentityTokenProfile,
  TaxIntegrationStatus
} from '../../clientModels'
import { MilestoneCode } from '../../enums'
import { Engagement } from '../api/apiModels'
import * as http from '../http'
import { getNewAbort } from '../lastRequests'
import { buildApiUrl, buildAggregatorUrl } from './apiUrl'

const abort = getNewAbort()

const engagementListAbort = getNewAbort()

export function apiCompleteEngagementMilestone(
  engagementId: number,
  milestone: MilestoneCode
): Promise<Engagement> {
  return http.put(
    buildApiUrl(`Engagements/${engagementId}/completeMilestone/${milestone}`),
    undefined,
    undefined,
    abort.new()
  )
}

export function apiGetEngagementListByClientId(entityGroupid?: number) {
  engagementListAbort.abort()
  return http.get(
    buildApiUrl(
      `Engagements${entityGroupid ? `/client/${entityGroupid}` : ''}`
    ),
    engagementListAbort.new()
  )
}

export function apiGetEngagementListByMasterClientId(masterClientId: number) {
  engagementListAbort.abort()
  return http.get(
    buildApiUrl(
      `Engagements${masterClientId ? `/masterclient/${masterClientId}` : ''}`
    ),
    engagementListAbort.new()
  )
}

/**
 * Get an engagement that includes the client's data.
 * @param engagementId
 */
export function apiGetEngagement(engagementId: number) {
  return http.get(buildApiUrl(`Engagements/${engagementId}`))
}

export function apiToggleEngagementFavorite(engagementId: string | number) {
  return http.put(
    buildApiUrl(`Engagements/${engagementId}/toggleFavorite`),
    undefined,
    undefined,
    abort.new()
  )
}

export function apiGetArchivedDocument(
  engagementId: number
): Promise<http.BlobResponse> {
  return http.get(
    buildApiUrl(`Engagements/${engagementId}/documentArchive`),
    abort.new()
  )
}

/**
 * Get all engagements.
 */
export function apiGetEngagementsWithClient() {
  return http.get(buildApiUrl('Engagements/WithClient'))
}

/**
 * Save an update to an engagement
 * @param engagement Engagement to save to the database
 */
export function apiSaveEngagement(engagement: Engagement): Promise<Engagement> {
  // tslint:disable-next-line:no-any
  const apiEngagement: any = { ...engagement }
  delete apiEngagement.assignments
  delete apiEngagement.assignedRoleCodes
  delete apiEngagement.assignedRoleCodesByUserId
  delete apiEngagement.needsReviewByUserQuestions
  delete apiEngagement.milestones
  delete apiEngagement.favorited
  delete apiEngagement.visible
  // clear out this tsa does not modify the entities.
  apiEngagement.engagementEntities = []
  return http.put(buildApiUrl('Engagements'), buildApiEngagement(apiEngagement))
}

function buildApiEngagement(engagement: ClientEngagment): Engagement {
  const temp = {
    ...engagement,
    errorQuestions: -1,
    needsReviewQuestions: -1,
    unansweredQuestions: -1,
    template: undefined
  }
  delete temp.client
  delete temp.loaded
  delete temp.successfulForUserQuestions
  return temp as unknown as Engagement
}

export function engagementIsCritical(
  engagementId: number,
  questionId: number,
  userProfile: IdentityTokenProfile
) {
  const params = {
    questionId,
    userFirstName: userProfile.firstName || '',
    userLastName: userProfile.lastName || '',
    userName: userProfile.name || '',
    userEmail: userProfile.email || '',
    organizationName: userProfile.organization.name || ''
  }

  return http.post(
    buildApiUrl(`Engagements/${engagementId}/IsCritical`),
    params
  )
}

export function oDataEngagementIsNotCritical(
  engagementId: number,
  userProfile: IdentityTokenProfile
) {
  const params = {
    userFirstName: userProfile.firstName || '',
    userLastName: userProfile.lastName || '',
    userName: userProfile.name || '',
    userEmail: userProfile.email || '',
    organizationName: userProfile.organization.name || ''
  }

  return http.post(
    buildApiUrl(`Engagements/${engagementId}/IsNotCritical`),
    params
  )
}

export function oDataEngagementSubmit(engagementId: number) {
  return http.post(buildApiUrl(`Engagements/${engagementId}/Submit`), {})
}

export function oDataGetLastYearsData(engagementId: number) {
  return http.post(
    buildApiUrl('/lastyearanswers/initiateexport', { engagementId }),
    {}
  )
}

export function apiGetEngagementEntities(
  engagementId: number
): Promise<ClientEntity[]> {
  return http.get(buildApiUrl(`engagements/${engagementId}/entities`))
}

export function apiGetEngagementClient(
  engagementId: number
): Promise<ClientEntity | undefined | null> {
  return http.get(buildApiUrl(`engagements/${engagementId}/client/entity`))
}

export const startLoadAnswersEtl = async (
  engagementId: number
): Promise<TaxIntegrationStatus> => {
  var result = await http.get(
    buildAggregatorUrl(`etltransfer/${engagementId}/startloadanswersfrometl`)
  )
  return result
}

export function getEngagementSectionGroups(taxYear: string, taxForm: string) {
  return http.get(
    buildApiUrl(`Engagements/sectionGroups/${taxYear}/${taxForm}`)
  )
}

/** Returns a set of CchFieldTranslation objects that are related to
 *   a specified set of CCH Codes, on a specified Engagement ID. */
export async function getQuestionsForCchCodes(
  engagementId: number,
  cchCodes: string[]
): Promise<CchFieldTranslation[]> {
  return await http.post(
    buildApiUrl(`CchTranslations/GetQuestionsForCchCodes/${engagementId}`),
    cchCodes,
    undefined,
    undefined,
    60
  )
}

export async function getEngagementFilters(): Promise<ClientFilterNode[]> {
  return await http.get(buildApiUrl('engagements/filters'))
}

import { IdentityTokenProfile } from '../clientModels'
import { UserPermissions } from '../enums'

export function hasPermission(permission: UserPermissions, user?: IdentityTokenProfile) {
  if (!user) {
    return false
  }

  // If permissions aren't enabled in this environment then always return true
  if (user.permissionsEnabled === false) {
    return true
  }

  // We don't support user permissions for external users
  if (user.isExternal) {
    return false
  }

  if (!user.permissions) {
    return false
  }

  return user.permissions.includes(permission)
}

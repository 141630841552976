import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../store'
import { useEffect } from 'react'
import {
  setIsExternalAction,
  setPermissionsAction,
} from '../actions/permissionActions'
import { UserPermissions } from '../enums'

export const usePermissionManager = () => {
  const dispatch = useDispatch()
  const {
    auth: { user },
    permissions: { isExternalUser, permissions },
  } = useSelector((state: AppState) => state)
  const impersonateUser =
    sessionStorage.getItem('ImpersonationUserId') === 'E000000'

  const toggleUserView = async (reload = true) => {
    // Saves the current state of impersonation to the session storage
    // so that when we make any http calls it knows what the current state is
    impersonateUser
      ? sessionStorage.setItem('ImpersonationUserId', '')
      : sessionStorage.setItem('ImpersonationUserId', 'E000000')
    // Reload the page so any api calls that were done before updating the state are refreshed
    if (reload) window.location.reload(false)
  }

  const hasPermission = (permission: string) => {
    // If permissions aren't enabled in this environment then always return true
    if (user?.permissionsEnabled === false) {
      return true
    }

    return permissions.includes(permission) || false
  }

  const hasAnyPermission = (permissionsPassed: UserPermissions[]) => {
    const returnValue = permissionsPassed.some(permission =>
      hasPermission(permission)
    )

    return returnValue
  }

  useEffect(() => {
    // Set state based on user if impersonation is off
    if (user && !impersonateUser) {
      if (isExternalUser !== user.isExternal) {
        dispatch(setIsExternalAction(user.isExternal))
      }
      dispatch(setPermissionsAction(user.permissions || []))
    }

    if (impersonateUser) {
      if (user?.impersonationPermissions) {
        dispatch(setPermissionsAction(user?.impersonationPermissions))
      }
    }
  }, [dispatch, impersonateUser, isExternalUser, user])

  return {
    hasPermission,
    toggleUserView,
    isExternalUser,
    impersonateUser,
    hasAnyPermission,
  }
}

import classNames from 'classnames'
import * as React from 'react'
import { Activity, IdentityTokenProfile, Mention } from '../../clientModels'
import { formatDateForDisplay } from '../../services/formatters/formatters'
import { Anchor } from '../anchor'

interface MentionDisplayProps {
  mention: Mention
}

const MentionComponent: React.SFC<MentionDisplayProps> = ({ mention }) => {
  let label = mention.label || ''
  let value = mention.value || ''

  if (!value.match(/[A-Za-z-]/)) {
    const clientId = parseInt(value, 10)

    if (!isNaN(clientId)) {
      label = 'Client'
    }
  }

  if (label === 'RSM Team') {
    label = 'RSM'
  }

  return <span className='mention'>@{label}</span>
}

interface ActivityListItemProps {
  activity: Activity
  user?: IdentityTokenProfile
  onDelete?: (activityId: number) => void
}

export class ActivityListItem extends React.Component<ActivityListItemProps> {
  handleDeleteClick = () => {
    const { activity, onDelete } = this.props
    if (onDelete) {
      onDelete(activity.id)
    }
  }

  getReviewType(): string {
    const {
      props: {
        activity: { activitySubTypeCode },
      },
    } = this
    switch (activitySubTypeCode) {
      case 'ClientReview':
        return 'client'
      case 'ConcurrentReview':
        return 'concurrent'
      case 'PreparerReview':
        return 'preparer'
      case 'PrimaryReview':
        return 'primary'
      case 'SecondaryReview':
        return 'secondary'
      default:
        return ''
    }
  }

  value(): string {
    const {
      props: {
        activity: { value, activityTypeCode },
      },
    } = this
    switch (activityTypeCode) {
      case 'Flag':
        return (value === '1' ? '' : 'un') + 'flagged this item'
      case 'Review':
        return `${this.getReviewType()} ${
          value === '1' ? '' : 'un'
        }reviewed this item`
      case 'NA':
        return value === '1' ? 'Marked not applicable' : 'Marked applicable'
      default:
        return value
    }
  }

  render() {
    const {
      activity: {
        author,
        userId,
        mentions,
        createdDate,
        parentId,
        createdByInternalUser,
      },
      user,
      onDelete,
    } = this.props

    return (
      <div className={classNames('activity-list-item', { child: !!parentId })}>
        <span className='author'>
          {!user || user.uniqueId !== userId
            ? `${createdByInternalUser ? '(RSM) ' : ''}${author}`
            : 'You'}
          :
        </span>
        {mentions &&
          mentions.map(m => <MentionComponent key={m.id} mention={m} />)}
        {this.value()}
        <span className='created-date'>
          {formatDateForDisplay(createdDate)}
        </span>
        {onDelete && <Anchor onClick={this.handleDeleteClick}>delete</Anchor>}
      </div>
    )
  }
}

import { TsaHubAction } from '../actions/index'
import { QuestionMap } from '../clientModels'
import { engagementTemplatesDocEndAction } from '../actions/engagementTemplateActions'
import { getType } from 'typesafe-actions'

const initialState: QuestionMap = {}

export function questionsReducer (
  state: QuestionMap = initialState,
  action: TsaHubAction
): QuestionMap {
  switch (action.type) {
    case getType(engagementTemplatesDocEndAction):
      return { ...state, ...action.payload.payload.questions }
    default:
      return state
  }
}

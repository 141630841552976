import { Button, Grid, Theme } from '@rsmus/react-ui'
import classnames from 'classnames'
import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { createUseStyles, useTheme } from 'react-jss'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { hasPermission } from '../../services/permissions'
import { AppState } from '../../store'
import { CchRequestsTable } from './cchRequestsTable'
import { useCchTestHarness } from './useCchTestHarness'
import { UserPermissions } from '../../enums'

function styles(theme: Theme) {
  return {
    root: {
      padding: '1rem',
    },
    busy: {
      cursor: 'wait',
    },
    label: {
      marginRight: '1rem',
    },
    input: {
      width: '15rem',
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.grey[400]}`,
      outline: 'none',
      padding: '0.1rem 0.5rem',
      transition: theme.transitions.create('all'),
      margin: 0,
      '&:focus': {
        outline: 'none',
        borderColor: theme.palette.grey[600],
      },
      '&:invalid': {
        borderColor: theme.palette.informational.red,
      },
    },
    button: {
      marginLeft: '1rem',
    },
    dropzone: {
      maxWidth: '25rem',
      height: '3rem',
      backgroundColor: theme.palette.grey[200],
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: theme.transitions.create(),
    },
    dragActive: {
      backgroundColor: theme.palette.grey[400],
    },
  }
}

type Styles = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, Styles>(styles)

export const useInput = () => {
  const [value, setValue] = useState('')
  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
      },
    },
  }
}

export const CchTestScene = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const user = useSelector((state: AppState) => state.auth.user)
  const canUpdateTemplates = hasPermission(UserPermissions.CchImportExportCanEdit, user)
  const {
    busy,
    exportCchReturn,
    requests,
    submitCchReturn,
  } = useCchTestHarness()
  const exportForm = useRef<HTMLFormElement>(null)
  const returnId = useInput()
  const onDrop = useCallback((files: File[]) => submitCchReturn(files[0]), [
    submitCchReturn,
  ])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  if (!canUpdateTemplates) {
    return <Redirect to='/' />
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    exportCchReturn(returnId.value)
    returnId.setValue('')
  }

  const rootClassName = classnames(classes.root, {
    [classes.busy]: busy,
  })

  const dropzoneClassName = classnames(classes.dropzone, {
    [classes.dragActive]: isDragActive,
  })

  const formIsValid = exportForm.current?.reportValidity
    ? exportForm.current?.reportValidity()
    : true // IE doesn't support reportValidity()
  const isValid = returnId.value && formIsValid

  return (
    <div className={rootClassName}>
      <Grid container>
        <Grid item xs={6}>
          <h1>Export CCH Return</h1>
          <form ref={exportForm} onSubmit={handleSubmit}>
            <label className={classes.label}>CCH Return ID</label>
            <input
              className={classes.input}
              disabled={busy}
              pattern='^\d{4}\w:\d{1,7}-\d{1,7}:V[1-9]$'
              placeholder='2018P:0123456-0123456:V1'
              type='text'
              {...returnId.bind}
            />
            <Button
              className={classes.button}
              disabled={busy || !isValid}
              type='submit'
              variant='primary'
            >
              Export
            </Button>
          </form>
        </Grid>
        <Grid item xs={6}>
          <h1>Submit CCH Return</h1>
          <form>
            <div {...getRootProps({ className: dropzoneClassName })}>
              <input {...getInputProps({ disabled: busy })} />
              Drop file here or click to select file.
            </div>
          </form>
        </Grid>
      </Grid>
      <CchRequestsTable requests={requests} />
    </div>
  )
}

import classNames from 'classnames'
import computeScrollIntoView from 'compute-scroll-into-view'
import { History, LocationDescriptor } from 'history'
import { cloneDeep, compact, filter, find, isEmpty, uniqueId } from 'lodash'
import * as React from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Activity,
  DocumentTitle,
  Engagement,
  EngagementQuestion,
  FileGroup,
  IdMap,
  IEngagementQuestionAnswer,
  Option,
  OptionListMap,
  PropertyValues,
  Question,
  StringLookup
} from '../../clientModels'
import displayName from '../../displayName'
import { RoleCode, DocumentTitleId } from '../../enums'
import { engagementLockedPhase } from '../../services/engagementPhaseService'
import { Anchor } from '../anchor'
import { findErrors, hasVisibleError, isComplete } from '../forms/formUtilities'
import {
  Alert,
  GeneralAlerts,
  OptionsFormFieldProps,
  PathAlerts
} from '../forms/index'
import UseDefaultValue from '../forms/useDefaultValue'
import Icon from '../icon/icon'
import { NotApplicableCheckBox } from '../notApplicableCheckBox/notApplicableCheckBox'
import { joinPaths } from '../relativeLink'
import FileSummaryHeader from '../summary/fileSummaryHeader'
import FileSummaryHeaderEtl from '../summary/fileSummaryHeaderEtl'
import TemplateSummaryHeader from '../summary/templateSummaryHeader'
import './question.scss'
import { QuestionIcons } from './questionIcons'
import { isDefined } from '../../guards'

export interface QuestionOwnProps {
  answer?: IEngagementQuestionAnswer
  calledFromSummary?: boolean
  clientId?: number
  codeListIdToOptionsId?: IdMap
  deleteFileGroup?: (fileGroupId: number) => void
  disabled?: boolean
  documentTitleId?: number
  documentTitles?: DocumentTitle[]
  downloadFile?: (fileId: number) => void
  downloadExcelTemplate?: (fileName: string, engagementId: number) => void
  engagement?: Engagement
  engagementId: number
  engagementQuestion?: EngagementQuestion
  fileGroupId?: number
  fileGroups?: FileGroup[]
  hasComments?: boolean
  hasDocuments?: boolean
  isExternal?: boolean
  onBlur: (answer: IEngagementQuestionAnswer, runTheRules?: boolean) => void
  onBulkChange?: (
    propertyChanges: PropertyValues,
    skipRules?: boolean,
    runTableRules?: boolean
  ) => Promise<void>
  onChange: (value: any, path?: string) => Promise<void> // tslint:disable-line:no-any
  onClickCancel?: () => void
  onClickFlag: () => void
  onClickTickmark: () => void
  onSelect: (questionId: number) => void
  openFileUpload: (
    engagementId?: string | number,
    questionId?: string | number,
    documentTitle?: DocumentTitle,
    history?: History,
    fileGroup?: FileGroup
  ) => void
  optionLists?: OptionListMap
  options?: Option[]
  question?: Question
  reviewRolesComplete: RoleCode[]
  reviewRolesForUser: RoleCode[]
  /** This is the same as onBlur, but we want saving to be explicit for some implementations. */
  saveQuestion?: (
    answer: IEngagementQuestionAnswer,
    runTheRules?: boolean
  ) => void
  saveActivity: (activity: Activity) => void
  selected?: boolean
  setNotApplicable: (notApplicable: boolean) => void
  toggleFileGroupNA: (
    documentTitle: DocumentTitle,
    fileGroupId?: number
  ) => void
}

interface QuestionProps extends QuestionOwnProps, RouteComponentProps<{}> {}

interface QuestionState {
  canCollapseLastYear?: boolean
  previousPath?: string | null
  selectedPath?: string | null
  navigateTo?: LocationDescriptor
  navigatePush?: boolean
}

export interface QuestionWrappedComponentInterface {
  // tslint:disable-next-line:no-any - not able to specify type at this level
  formatValueLastYear?: (value: any, props: any) => React.ReactNode
  isReadOnly?: boolean
}

// tslint:disable-next-line:no-any - value can be anything
function formatValueLastYear(value: any, props: any): any {
  return value
}

function isOverflown(questionId?: number) {
  if (!questionId) {
    return false
  }

  const element = document.getElementById('value-' + questionId)

  return (
    element &&
    (element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth)
  )
}

// tslint:disable-next-line:no-any - value can be anything
function renderValueLastYear(
  engagementId: number | undefined,
  label: string | undefined,
  value: any,
  question?: Question,
  useThisLabel?: string,
  disabled?: boolean,
  onUseThis?: () => void,
  canCollapse?: boolean,
  onShowMoreLess?: () => void
): React.ReactNode {
  const isDatasheet =
    question &&
    (question.answerSchema.component === 'datasheet' ||
      question.answerSchema.component === 'readonlydatasheet')
  let valueId = null
  let hasMore: boolean | null = false

  const datasheetHasLastYear = isDatasheet && value
  let questionId
  if (question) {
    questionId = question.id
    hasMore = isOverflown(questionId)
    valueId = 'value-' + questionId
  }

  const showMoreLessText = isDatasheet
    ? (canCollapse === true ? 'hide' : 'show') + " last year's data"
    : 'show ' + (canCollapse === true ? 'less' : 'more')
  useThisLabel = isDatasheet ? 'use data (you can preview first)' : useThisLabel
  const classes = {
    canCollapse,
    datasheetHasLastYear,
    hasMore: hasMore || canCollapse === false
  }
  return (
    <div className='question-last'>
      <div className={classNames('value', classes)} id={valueId || ''}>
        {label && <span className='label'>{label}</span>}
        {value}
      </div>
      <button onClick={onShowMoreLess} className={classNames('show', classes)}>
        {showMoreLessText} |{' '}
      </button>
      {question && question.showUseLastYear && useThisLabel && (
        <UseDefaultValue
          label={useThisLabel}
          disabled={!!disabled}
          onClick={onUseThis}
        />
      )}
    </div>
  )
}

function countOtherFiles(
  fileGroupId?: number,
  documentTitleId?: number,
  fileGroups?: FileGroup[]
) {
  if (
    (!fileGroupId && !documentTitleId) ||
    !fileGroups ||
    fileGroups.length === 0
  ) {
    return 0
  }

  const otherGroups = fileGroups.filter(
    f =>
      (!!fileGroupId && f.id !== fileGroupId) ||
      (!!documentTitleId && f.documentTitleId !== documentTitleId)
  )
  let otherFiles = 0

  for (const group of otherGroups) {
    otherFiles += group.files ? group.files.length : 0
  }

  return otherFiles
}

export const roleLabels: StringLookup = {
  [RoleCode.ClientPreparer]: 'client',
  [RoleCode.Preparer]: 'preparer',
  [RoleCode.PrimaryReviewer]: 'primary',
  [RoleCode.SecondaryReviewer]: 'secondary',
  [RoleCode.ConcurringReviewer]: 'concurring'
}

type FormFieldComponentType<
  WrappedComponentProps extends OptionsFormFieldProps
> = React.ComponentType<WrappedComponentProps> &
  QuestionWrappedComponentInterface

const questionHoc = <WrappedComponentProps extends OptionsFormFieldProps>(
  FormFieldComponent: FormFieldComponentType<WrappedComponentProps>
) => {
  return withRouter(
    class extends React.Component<QuestionProps, QuestionState> {
      static displayName = displayName('Question', FormFieldComponent)

      id: string
      labelId: string
      hintId: string
      outer?: HTMLDivElement
      state: QuestionState = {}

      constructor(props: QuestionProps) {
        super(props)
        const id = (this.id = uniqueId('question_'))
        this.labelId = `${id}_label`
        this.hintId = `${id}_hint`
        this.setState = this.setState.bind(this)
      }

      componentDidMount() {
        if (this.props.selected) {
          this.scroll()
          this.selectFirstInput()
        }
      }

      // eslint-disable-next-line
      UNSAFE_componentWillReceiveProps(nextProps: QuestionProps) {
        const {
          props: { selected },
          state: { selectedPath },
          setState
        } = this
        if (selected !== nextProps.selected) {
          if (nextProps.selected) {
            // This question was just selected
            this.scroll()
            // If question has a selected path, then an inner control already has focus.
            if (!selectedPath) {
              // If no selected path, then select first input in question.
              this.selectFirstInput(this.outer)
            }
          } else {
            // This question was just unselected
            setState({ previousPath: null })
          }
        }
      }

      scroll() {
        const { outer } = this
        if (outer) {
          computeScrollIntoView(outer, {
            block: 'nearest',
            scrollMode: 'if-needed'
          }).forEach(({ el, top, left }) => {
            el.scrollTop = top
            el.scrollLeft = left
          })
        }
      }

      setOuterRef = (ref: HTMLDivElement) => {
        this.outer = ref
      }

      handleFocus = (path?: string) => {
        const {
          props: { selected, question, onSelect }
        } = this
        this.setState(
          { selectedPath: path || null, previousPath: path || null },
          () => {
            if (question && !selected) {
              // Selecting the new question and its side effects.
              // Awaiting setState to ensure that questions have
              // updated props before selecting new question.
              onSelect(question.id)
            }
          }
        )
      }

      handleBlur = (path?: string) => {
        this.setState({ selectedPath: null }, this.save)
      }

      save = () => {
        const { onBlur, answer } = this.props
        if (!!onBlur && answer && answer.isDirty === true) {
          onBlur(answer)
        }
      }

      selectFirstInput(el?: HTMLElement): any {
        if (!el) {
          return this.selectFirstInput(this.outer)
        }

        if (el.nodeName === 'INPUT' || el.nodeName === 'TEXTAREA') {
          el.focus()
          return el
        }

        return find(
          compact(el.children),
          (child: HTMLElement) => !!this.selectFirstInput(child)
        )
      }

      fireEvent = (element: EventTarget, eventType: string) => {
        if ((new Error().stack || '').split('this.fireEvent').length > 3) {
          // 1. Before first reference. 2. First reference. 3. After first reference. Any more than that and we're looping
          return
        }

        const evt = document.createEvent('HTMLEvents')
        evt.initEvent(eventType, true, true)
        element.dispatchEvent(evt)
      }

      selectQuestion = (e: React.MouseEvent) => {
        const { disabled, onSelect, question, selected } = this.props
        if (question && !selected && !disabled) {
          onSelect(question.id)
        }
      }

      handleFlagClick = (e: React.SyntheticEvent<HTMLElement>) => {
        this.props.onClickFlag()
      }

      handleTickmarkClick = (e: React.MouseEvent<HTMLElement>) => {
        this.props.onClickTickmark()
      }

      handleTickmarkKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
          this.props.onClickTickmark()
        }
      }

      handleNotApplicableChanged = () => {
        const { answer, setNotApplicable } = this.props
        if (answer) {
          setNotApplicable(!answer.notApplicable)
        }
      }

      showMoreLess = () => {
        this.setState({ canCollapseLastYear: !this.state.canCollapseLastYear })
      }

      useLastYear = () => {
        const { answer, onChange, question, engagementId } = this.props
        if (answer) {
          answer.isDirty = true
          onChange(cloneDeep(answer.answerValueLastYear))
          this.save()
        }
        this.selectFirstInput()

        // if datasheet transfer
        const isDatasheet =
          question && question.answerSchema.component === 'readonlydatasheet'
        if (isDatasheet) {
          const sectionId = question && question.sectionId
          const questionId = question && question.id
          this.setState({
            navigateTo: {
              pathname: `/engagements/${engagementId}/sections/${sectionId}/questions/${questionId}/edit`,
              state: { fromUseThis: 'true' }
            },
            navigatePush: true
          })
        } else {
          this.save()
        }
      }

      requiredDocumentTitles(): DocumentTitle[] {
        const { engagementQuestion, documentTitles } = this.props

        // documentTitles are used to render a placeholder for required documents
        // only show them if docsRequired is true
        return engagementQuestion &&
          engagementQuestion.requiredDocumentTitleIds.length > 0 &&
          documentTitles
          ? filter(documentTitles, x =>
              engagementQuestion.requiredDocumentTitleIds.includes(x.id)
            )
          : []
      }

      optionalDocumentTitles(): DocumentTitle[] {
        const { engagementQuestion, documentTitles } = this.props

        // documentTitles are used to render a placeholder for optional documents
        const docTitles =
          engagementQuestion &&
          engagementQuestion.optionalDocumentTitleIds.length > 0 &&
          documentTitles
            ? filter(documentTitles, x =>
                engagementQuestion.optionalDocumentTitleIds.includes(x.id)
              )
            : []

        return docTitles.map(x => ({ ...x, optional: true }))
      }

      handleToggleFileGroupNA = (
        docTitle: DocumentTitle,
        fileGroupId?: number
      ) => {
        const { toggleFileGroupNA } = this.props
        toggleFileGroupNA(docTitle, fileGroupId)
      }

      clearSelection = () => {
        const { answer, onChange, setNotApplicable } = this.props
        if (answer) {
          answer.isDirty = true
          if (answer.notApplicable === true) {
            setNotApplicable(false)
          }
          onChange(undefined)
        }
      }

      // tslint:disable:align whitespace no-unused-expression
      render() {
        const {
          answer,
          calledFromSummary,
          clientId,
          codeListIdToOptionsId,
          deleteFileGroup,
          disabled,
          documentTitleId,
          downloadExcelTemplate,
          downloadFile,
          engagement,
          engagementId,
          engagementQuestion,
          fileGroupId,
          fileGroups,
          hasComments,
          hasDocuments,
          history,
          match,
          onBulkChange,
          onChange,
          openFileUpload,
          optionLists,
          options,
          question,
          reviewRolesComplete,
          reviewRolesForUser,
          saveActivity,
          selected
        } = this.props

        const {
          selectedPath,
          previousPath,
          navigatePush,
          navigateTo
        } = this.state

        if (!question) {
          return null
        }

        const messages = findErrors(
          fileGroups,
          documentTitleId,
          fileGroupId,
          answer,
          selected,
          engagementQuestion
        )
        const otherFiles = countOtherFiles(
          fileGroupId,
          documentTitleId,
          fileGroups
        )
        const questionNotApplicable =
          question.allowNotApplicable && answer && answer.notApplicable

        // we added the calledFromSummary for conditional disable of the readonlydatasheet
        const disabledOrNA =
          disabled ||
          questionNotApplicable ||
          (calledFromSummary &&
            question.answerSchema.component === 'readonlydatasheet')

        const fieldProps = {
          codeListIdToOptionsId,
          component: question.answerSchema.component,
          disabled: disabledOrNA,
          hint: '',
          jsonSchema: question.answerSchema,
          maxLength: question.answerSchema.maxLength, // currently only used for textarea
          messages: messages.compMessages,
          onBlur: this.handleBlur,
          onBulkChange,
          onChange,
          onFocus: this.handleFocus,
          options,
          optionLists,
          selectedPath: selectedPath || undefined,
          showAlerts: false,
          value: answer && answer.answerValue,
          engagementId,
          sectionId: question.sectionId,
          questionId: question.id,
          selectedQuestion: selected
        }

        // Flags
        const flagged = answer && answer.flagged

        // Question Complete
        const questionComplete = isComplete(engagementQuestion, answer)
        // Tick marks (review)
        const reviewsCanReview = reviewRolesForUser.filter(r => {
          if (!engagement) {
            return true
          }
          switch (r) {
            case RoleCode.ConcurringReviewer:
              return !engagement.concurringReviewDone
            case RoleCode.Preparer:
              return !engagement.preparerReviewDone
            case RoleCode.PrimaryReviewer:
              return !engagement.primaryReviewDone
            case RoleCode.SecondaryReviewer:
              return !engagement.secondaryReviewDone
            default:
              return true
          }
        })
        const reviewsMissing = reviewRolesForUser.filter(
          r => !reviewRolesComplete.includes(r)
        )
        const reviewsMissingCanReview = reviewsCanReview.filter(
          r => !reviewRolesComplete.includes(r)
        )
        const reviewComplete = reviewsMissingCanReview.length === 0
        const canReview = reviewsCanReview.length > 0 && questionComplete
        const reviewWarning = !reviewComplete
          ? `This question requires ${reviewsMissingCanReview
              .map(r => roleLabels[r] || r)
              .join(', ')} review.`
          : ''
        const reviewsCompleteRoleCodes = Array.from(reviewRolesComplete || [])
        const tickmarkTooltip = !questionComplete
          ? 'Please provide a valid answer'
          : canReview
          ? `${reviewComplete ? 'Clear ' : 'Mark '} ${reviewsCanReview
              .map(r => roleLabels[r] || r)
              .join(', ')} review`
          : `${
              reviewsCompleteRoleCodes.length > 0
                ? `${reviewsCompleteRoleCodes
                    .map(r => roleLabels[r] || r)
                    .join(', ')} reviewed.`
                : ''
            }${
              reviewsCompleteRoleCodes.length > 0 && reviewsMissing.length > 0
                ? ' '
                : ''
            }${
              reviewsMissing.length > 0
                ? `${reviewsMissing
                    .map(r => roleLabels[r] || r)
                    .join(', ')} not reviewed.`
                : ''
            }`

        // Last year's value
        const valueLastYear =
          answer &&
          !isEmpty(answer.answerValueLastYear) &&
          answer.answerValueLastYear !== '{}' &&
          answer.answerValueLastYear

        const formatVLY = FormFieldComponent.formatValueLastYear
          ? FormFieldComponent.formatValueLastYear
          : formatValueLastYear

        // Links
        let docTitlesLink = joinPaths(
          match.url,
          `engagements/${engagementId}/documents`
        )
        const questionLink = joinPaths(
          match.url,
          `engagements/${engagementId}/sections/${question.sectionId}/questions/${question.id}`
        )

        // Documents
        const docTitles = [
          ...this.requiredDocumentTitles(),
          ...this.optionalDocumentTitles()
        ]
        if (docTitles.length > 0) {
          docTitlesLink += `/questions/${question.id}`
          const fileGroup =
            fileGroups &&
            fileGroups.filter(f => f.documentTitleId === docTitles[0].id)
          if (fileGroup && fileGroup.length > 0) {
            docTitlesLink += `/filegroup/${fileGroup[0].id}`
          } else {
            docTitlesLink += `/documenttitle/${docTitles[0].id}`
          }
        }

        // Last Year
        const showLastYearData =
          engagement &&
          engagement.priorYearReturnId &&
          !fileGroupId &&
          !documentTitleId &&
          engagement.taxIntegrationStatuses &&
          engagement.taxIntegrationStatuses.some(
            x =>
              x.type === 'CCHImport' &&
              (x.status === 'SUCCESS' || x.status === 'PARTIAL SUCCESS')
          )

        // default display width for component
        const displayWidth: string | undefined =
          (question.answerSchema && question.answerSchema.width) || '35rem'

        // errors
        const hasErrors = hasVisibleError(engagementQuestion)

        const engagementLocked =
          engagement === undefined ||
          (engagement.isEnabled !== undefined
            ? engagement.isEnabled
            : engagementLockedPhase(engagement))

        const questionClassName = classNames('question', {
          selected: selected && !disabledOrNA,
          error: hasErrors,
          disabledOrNA
        })

        // tslint:disable:no-any - <FormFieldComponent {...fieldProps as any} /> https://github.com/Microsoft/TypeScript/issues/28748
        return (
          <div>
            {question.isVisible && 
              <div
                className={questionClassName}
                id={'question-id-' + question.id}
                ref={this.setOuterRef}
                onClick={this.selectQuestion}
              >
                <div className='question-form'>
                  <div className='question-label'>
                    <label id={this.labelId}>
                      <span className='question-number'>
                        {question.displayNumber} &nbsp;{' '}
                      </span>
                      {question.text}
                    </label>
                    <QuestionIcons
                      disabled={disabled}
                      hasComments={hasComments}
                      hasDocuments={hasDocuments}
                      hasError={hasErrors}
                      hasFlags={flagged}
                      reviewsComplete={reviewsCompleteRoleCodes}
                      reviewsMissing={reviewsMissingCanReview}
                      selected={selected}
                    />
                  </div>
                  {question.hint && (
                    <div id={this.hintId} className='question-hint-text'>
                      {question.hint}
                    </div>
                  )}
                  <div
                    style={displayWidth ? { maxWidth: displayWidth } : undefined}
                  >
                    <FormFieldComponent {...(fieldProps as any)} />
                  </div>
                  <div className='question-na-clear'>
                    {question.allowNotApplicable && (
                      <NotApplicableCheckBox
                        checked={questionNotApplicable}
                        disabled={disabled}
                        onChange={this.handleNotApplicableChanged}
                      />
                    )}
                    {!engagementLocked &&
                      !disabled &&
                      question.answerSchema.component === 'radio' && (
                        <Anchor onClick={this.clearSelection}>
                          Clear Selection
                        </Anchor>
                      )}
                  </div>
                  {!fileGroupId && !documentTitleId && docTitles.length > 0 && (
                    <div className='question-required-docs'>
                      {isDefined(question.uploadText)
                        ? question.uploadText
                        : 'Based on your answer we require additional supporting documentation.'}{' '}
                      This has also been added to your{' '}
                      <Link to={docTitlesLink}>document library</Link>.
                    </div>
                  )}
                  {!fileGroupId &&
                    docTitles
                      .sort((a, b) => (a.title < b.title ? -1 : 1))
                      .filter(d => !documentTitleId || d.id === documentTitleId)
                      .map(docTitle => {
                        return !docTitle.templateFileName ? (
                          documentTitleId === DocumentTitleId.K1Document ||
                          documentTitleId === DocumentTitleId.K1UpLoadDocument ||
                          documentTitleId === DocumentTitleId.EtlDocument ? (
                            <FileSummaryHeaderEtl
                              allowNotApplicable={question.allowNotApplicable}
                              disabled={disabledOrNA}
                              documentTitle={docTitle}
                              engagementId={engagementId}
                              fileGroup={
                                fileGroups &&
                                fileGroups.find(
                                  g => g.documentTitleId === docTitle.id
                                )
                              }
                              history={history}
                              key={docTitle.id}
                              onDownloadFile={downloadFile}
                              onToggleNA={this.handleToggleFileGroupNA}
                              openFileUpload={openFileUpload}
                              questionId={question.id}
                              saveActivity={saveActivity}
                            />
                          ) : (
                            <FileSummaryHeader
                              allowNotApplicable={!!docTitle.optional}
                              disabled={disabledOrNA}
                              documentTitle={docTitle}
                              engagementId={engagementId}
                              fileGroup={
                                fileGroups &&
                                fileGroups.find(
                                  g =>
                                    g.documentTitleId === docTitle.id &&
                                    g.engagementId === engagementId
                                )
                              }
                              history={history}
                              key={docTitle.id}
                              onDelete={deleteFileGroup}
                              onDownloadFile={downloadFile}
                              onToggleNA={this.handleToggleFileGroupNA}
                              openFileUpload={openFileUpload}
                              questionId={question.id}
                              saveActivity={saveActivity}
                            />
                          )
                        ) : (
                          <TemplateSummaryHeader
                            clientId={clientId || 0}
                            disabled={disabledOrNA}
                            documentTitle={docTitle}
                            fileGroup={
                              fileGroups &&
                              fileGroups.find(
                                g =>
                                  g.documentTitleId === docTitle.id &&
                                  g.engagementId === engagementId
                              )
                            }
                            engagementId={engagementId}
                            key={docTitle.id}
                            onDelete={deleteFileGroup}
                            onDownloadFile={downloadFile}
                            onDownloadExcelTemplate={downloadExcelTemplate}
                            openFileUpload={openFileUpload}
                            questionId={question.id}
                            saveActivity={saveActivity}
                          />
                        )
                      })}
                  {!documentTitleId &&
                    fileGroups &&
                    fileGroups
                      .filter(
                        f =>
                          (!docTitles.length || !f.documentTitleId) &&
                          (!fileGroupId || f.id === fileGroupId) &&
                          f.engagementId === engagementId
                      )
                      .filter(
                        f =>
                          !questionNotApplicable ||
                          (questionNotApplicable && !f.notApplicable)
                      )
                      .map(f => (
                        <FileSummaryHeader
                          allowNotApplicable={question.allowNotApplicable}
                          disabled={disabledOrNA}
                          engagementId={engagementId}
                          fileGroup={f}
                          history={history}
                          key={f.id}
                          onDelete={deleteFileGroup}
                          onDownloadFile={downloadFile}
                          onToggleNA={this.handleToggleFileGroupNA}
                          openFileUpload={openFileUpload}
                          questionId={question.id}
                          saveActivity={saveActivity}
                        />
                      ))}
                  {otherFiles > 0 && (
                    <div className='question-other-files'>
                      <span>Not shown: </span>
                      {otherFiles} more file{otherFiles > 1 && 's'} associated with
                      this question.{' '}
                      <Link to={questionLink}>
                        Go to question to view all files
                      </Link>
                    </div>
                  )}
                  <div className='question-footer'>
                    <div className='question-footer-metadata'>
                      <div className='question-alerts'>
                        {selected && !disabledOrNA && (
                          <React.Fragment>
                            {!reviewComplete && canReview && (
                              <Alert
                                messages={reviewWarning}
                                severity='warning'
                                type='msg'
                              />
                            )}
                            <GeneralAlerts messages={messages.allMessages} />
                            <PathAlerts
                              messages={messages.allMessages}
                              selectedPath={previousPath || undefined}
                              onSelectPath={
                                !FormFieldComponent.isReadOnly
                                  ? this.handleFocus
                                  : undefined
                              }
                            />
                            {reviewComplete && messages.success && (
                              <Alert
                                messages='Success'
                                severity='success'
                                type='msg'
                              />
                            )}
                          </React.Fragment>
                        )}
                      </div>
                      {showLastYearData &&
                        (valueLastYear
                          ? renderValueLastYear(
                              engagementId,
                              'Last Year: ',
                              formatVLY(valueLastYear, fieldProps),
                              question,
                              'use this',
                              disabledOrNA,
                              this.useLastYear,
                              this.state.canCollapseLastYear,
                              this.showMoreLess
                            )
                          : renderValueLastYear(
                              engagementId,
                              'Last Year: ',
                              '[no answer provided]'
                            ))}
                    </div>
                    {!engagementLocked && (
                      <div className='question-buttons'>
                        {reviewRolesForUser.length > 0 && (
                          <div
                            className={classNames({ clickable: canReview })}
                            onClick={
                              canReview ? this.handleTickmarkClick : undefined
                            }
                            onKeyDown={
                              canReview ? this.handleTickmarkKeyPress : undefined
                            }
                            tabIndex={0}
                          >
                            <Icon
                              active={reviewComplete}
                              className={classNames(
                                'question-icon-large',
                                { 'cant-review': !canReview },
                                { disabled: !questionComplete }
                              )}
                              icon='circleBigCheck'
                              tooltip={tickmarkTooltip}
                            />
                          </div>
                        )}
                        <div
                          className={classNames(
                            'question-icon-large hover-to-full-opacity',
                            { active: flagged }
                          )}
                          onClick={this.handleFlagClick}
                        >
                          <Icon
                            active={flagged}
                            className='question-icon-large'
                            icon='flags'
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {navigateTo && <Redirect to={navigateTo} push={navigatePush} />}
              </div>
            }
          </div>
        )
      }
    }
  )
}

export default questionHoc

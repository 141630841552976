import * as React from 'react'
import './glossary.scss'

function GlossaryHeader () {
  return (
    <div className='flag-header flex-wrap'>
      <div className='title'>Glossary</div>
    </div>
  )
}

export default GlossaryHeader

import React, { useEffect, useCallback } from 'react'
import TextareaAutosize from 'react-autosize-textarea/lib'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../../actions/index'
import { Mention, Option, Section } from '../../clientModels'
import { Dropdown } from '../../components/dropdown/index'
import { ensureNumber, isDefined } from '../../guards'
import { getTrackingId } from '../../services/track'
import { AppState, TsaDispatch } from '../../store'
import Icon from '../icon/icon'
import './comment.scss'
import { commentSetAction } from '../../actions/commentActions'

const NoTag = 'no-tag'
const RsmLabel = 'RSM Team'
const RsmTag = 'RSM'
const ClientTeam = 'Client Team'

interface CommentProps {
  engagementId: string | number
  lastMention?: Mention
  questionId: string | number
  showMention?: boolean
  questionDisabled: boolean
}

const Comment = (props: CommentProps): JSX.Element => {
  const dispatch: TsaDispatch = useDispatch()

  const sectionArray = useSelector(
    (state: AppState) =>
      Object.values(state.sections).filter(isDefined) as Section[]
  )
  const currentQuestionId = useSelector(
    (state: AppState) => state.activities.currentQuestionId
  )
  const currentQuestion = useSelector(
    (state: AppState) => state.questions[currentQuestionId]
  )
  const engagement = useSelector(
    (state: AppState) =>
      state.engagements && state.engagements[ensureNumber(props.engagementId)]
  )
  const organization = useSelector(
    (state: AppState) => engagement && state.clients[engagement.clientId]
  )
  const commentText = useSelector(
    (state: AppState) => state.activities.commentText
  )
  const mentionLabel = useSelector(
    (state: AppState) => state.activities.mentionLabel
  )
  const mentionValue = useSelector(
    (state: AppState) => state.activities.mentionValue
  )
  const user = useSelector((state: AppState) => state.auth.user)
  const firstName = user ? user.firstName : ''
  const lastName = user ? user.lastName : ''
  const isInternal = !!user && !user.isExternal
  const userId = user ? user.uniqueId : ''
  const engagementAssignments = engagement?.assignments

  const clientId = organization
    ? organization.id
    : engagement && engagement.clientId

  const mentionOptions: Option[] = setMentionOptions()

  const setComment = useCallback(
    (commentText: string, mentionLabel: string, mentionValue: string) => {
      dispatch(
        commentSetAction({
          commentText,
          mentionLabel,
          mentionValue,
          questionId: props.questionId,
        })
      )
    },
    [dispatch, props.questionId]
  )

  useEffect(() => {
    if (props.questionId !== currentQuestionId) {
      setComment('', '', NoTag)
    }
  }, [currentQuestionId, props.questionId, setComment])

  function setMentionOptions() {
    let mentionOptions: Option[] = [
      { value: NoTag, label: 'None' },
      { value: RsmTag, label: RsmLabel },
    ]

    let commentsOnlyforInternal = false

    if (currentQuestion?.isInternalVisibleOnly) {
      commentsOnlyforInternal = true
    } else if (sectionArray.length > 0) {
      if (
        sectionArray.filter(s => s.id === currentQuestion?.sectionId)[0]
          ?.isInternalVisibleOnly
      ) {
        commentsOnlyforInternal = true
      }
    }

    if (!commentsOnlyforInternal) {
      //add client team
      mentionOptions.push({
        value: (clientId && clientId.toString()) || '',
        label: ClientTeam,
      })

      if (engagementAssignments) {
        for (const assignment of engagementAssignments) {
          if (
            mentionOptions.find(
              option => option.value === assignment.user.userId
            )
          ) {
            continue
          }

          const assignmentOption: Option = {
            label: `${assignment.user.firstName} ${assignment.user.lastName}`,
            value: assignment.user.userId,
          }

          //add client names
          mentionOptions.push(assignmentOption)
        }
      }
    }
    return mentionOptions.filter(x => x.value !== '')
  }

  function handleClickAttachment() {
    dispatch(actions.file.openFileUpload())
  }

  function onSelect(mentionOption: Option) {
    setComment(commentText || '', mentionOption.label, mentionOption.value)
  }

  function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setComment(e.currentTarget.value, mentionLabel || '', mentionValue || NoTag)
  }

  function onKeyPress(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      onSave()
    }

    if (e.key === 'Escape') {
      e.preventDefault()
      setComment('', '', NoTag)
    }
  }

  function onSave() {
    const { lastMention } = props

    if (commentText) {
      let localMentionLabel =
        mentionValue && mentionValue !== NoTag ? mentionLabel : undefined
      let localMentionValue =
        mentionValue && mentionValue !== NoTag ? mentionValue : undefined
      if (lastMention && !lastMention.resolved) {
        if (isInternal && lastMention.label === RsmTag) {
          localMentionValue = '' + clientId + ''
        } else if (
          !isInternal &&
          parseInt(lastMention.label || '', 10) === clientId
        ) {
          localMentionValue = RsmTag
        }
      }

      getTrackingId(
        dispatch,
        actions.comment.saveComment,
        commentText || '',
        props.engagementId,
        props.questionId,
        firstName,
        lastName,
        userId,
        isInternal,
        localMentionLabel,
        localMentionValue,
        undefined
      )

      setComment('', '', NoTag)
    }
  }

  const { questionId, showMention, questionDisabled } = props

  if (!questionId || questionDisabled) {
    return <React.Fragment />
  }

  const placeholderText = `${
    isInternal
      ? `Comments will only be shown to clients if you select '${ClientTeam}' from the notification drop-down above.`
      : ''
  }`
  const labelText = 'Add Comment / Document'

  return (
    <React.Fragment>
      {
        <div className='comment-component'>
          <div className='comment-component-label'>
            <div className='comment-component-label-text'>{labelText}</div>
            <div className='comment-component-label-notification'>
              {showMention && (
                <div className='comment-component-notification-dropdown'>
                  <Icon icon='bell' />
                  <Dropdown
                    className='comment-dropdown'
                    direction='up'
                    onChange={onSelect}
                    options={mentionOptions}
                    placeholder='Select template'
                    value={mentionOptions.find(m => m.value === mentionValue)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='comment-component-input'>
            <div className='comment-input'>
              <TextareaAutosize
                id='comment-content'
                maxLength={1000}
                onChange={onChange}
                onKeyDown={onKeyPress}
                placeholder={placeholderText}
                rows={3}
                value={commentText}
              />
              <div
                className='icon-container'
                id='comment-icon-attachment'
                onClick={handleClickAttachment}
              >
                <Icon icon='attachment' onClick={handleClickAttachment} />
              </div>
            </div>
            <div className='comment-save' onClick={onSave}>
              <Icon icon='send' />
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default Comment

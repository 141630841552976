import { Moment } from 'moment'
import { getType } from 'typesafe-actions'
import { TsaHubAction } from '../actions/index'
import {
  selectionAddDashboardDateFilterAction,
  selectionAddDashboardStatusFilterAction,
  selectionAddDashboardTypeFilterAction,
  selectionAddDashboardYearFilterAction,
  selectionClearAllDashboardFiltersAction,
  selectionClearDashboardDateFilterAction,
  selectionClearDashboardStatusFilterAction,
  selectionClearDashboardTypeFilterAction,
  selectionClearDashboardYearFilterAction,
  selectionGetFilteredK1sAction,
  selectionHelpToggleShowMoreAction,
  selectionSelectCommentFilterAction,
  selectionSelectCommentSortAction,
  selectionSelectDashboardSortAction,
  selectionSelectDocumentFilterAction,
  selectionSelectDocumentSortAction,
  selectionSelectK1sListAction,
  selectionSelectStateFilterAction,
  selectionSelectStatusFilterAction,
  selectionSelectStatusFilterListAction,
  selectionSidebarCollapseAction,
  selectionSidebarRestoreAction,
  selectionSidebarSelectAction,
} from '../actions/selectionActions'
import { SidebarNames } from '../clientModels'
import {
  CommentFilters,
  DefaultCommentFilter,
} from '../components/commentLibrary/commentLibraryFilters'
import {
  CommentSorts,
  DefaultCommentSort,
} from '../components/commentLibrary/commentLibrarySorts'
import {
  DefaultDocumentFilter,
  DefaultDocumentSort,
  DocumentFilters,
  DocumentSorts,
} from '../components/documentLibrary/misc'
import {
  DashboardDateFilters,
  DashboardSorts,
  DashboardStatusFilters,
  DashboardTypeFilters,
  DashboardYearFilters,
} from '../components/engagementsListHeader'

export interface SelectionState {
  previousSidebarName: SidebarNames | null
  selectedCommentFilter: keyof CommentFilters
  selectedCommentSort: keyof CommentSorts
  selectedDashboardFilters?: SelectionDashboardFilters
  selectedDashboardSort?: keyof DashboardSorts
  selectedDocumentFilter?: keyof DocumentFilters
  selectedDocumentSort?: keyof DocumentSorts
  selectedSidebarName: SidebarNames
  selectedStatusFilter?: string
  selectedStateFilter?: string
  selectedStatusFilterList?: any[]
  selectedK1sList?: number[]
  showMoreHelp: boolean
}

export interface SelectionDashboardFilters {
  TypeFilters?: Array<keyof DashboardTypeFilters>
  StatusFilters?: Array<keyof DashboardStatusFilters>
  DateFilters?: DashboardDateFilter[]
  YearFilters?: Array<keyof DashboardYearFilters>
}

export interface DashboardDateFilter {
  filter: keyof DashboardDateFilters
  date?: Moment
}

const initialState: SelectionState = {
  previousSidebarName: null,
  selectedCommentFilter: DefaultCommentFilter,
  selectedCommentSort: DefaultCommentSort,
  selectedDashboardFilters: undefined,
  selectedDashboardSort: undefined,
  selectedDocumentFilter: DefaultDocumentFilter,
  selectedDocumentSort: DefaultDocumentSort,
  selectedSidebarName: 'activity',
  selectedStatusFilter: '',
  selectedStateFilter: '',
  selectedStatusFilterList: [],
  selectedK1sList: [],
  showMoreHelp: false,
}

export function selectionReducer(
  state: SelectionState = initialState,
  action: TsaHubAction
) {
  switch (action.type) {
    case getType(selectionHelpToggleShowMoreAction):
      return { ...state, showMoreHelp: !state.showMoreHelp }

    case getType(selectionSidebarSelectAction): {
      const { selectedSidebarName, toggle } = action.payload
      const nextSidebarName = toggle
        ? selectedSidebarName === state.selectedSidebarName
          ? ''
          : selectedSidebarName
        : selectedSidebarName
      return {
        ...state,
        selectedSidebarName: nextSidebarName,
        showMoreHelp: nextSidebarName === 'help',
      }
    }

    case getType(selectionSidebarCollapseAction):
      return {
        ...state,
        selectedSidebarName: '' as SidebarNames,
        previousSidebarName: state.selectedSidebarName,
      }

    case getType(selectionSidebarRestoreAction):
      if (state.previousSidebarName === null) {
        return state
      }
      return {
        ...state,
        selectedSidebarName: state.previousSidebarName,
        previousSidebarName: null,
      }

    case getType(selectionSelectDashboardSortAction):
      return {
        ...state,
        selectedDashboardSort: action.payload.sort,
      }

    case getType(selectionSelectDocumentFilterAction):
      return {
        ...state,
        selectedDocumentFilter: action.payload.filter,
      }

    case getType(selectionSelectStatusFilterAction):
      return {
        ...state,
        selectedStatusFilter: action.payload.filter,
      }
    case getType(selectionSelectStateFilterAction):
      return {
        ...state,
        selectedStateFilter: action.payload.filter,
      }

    case getType(selectionGetFilteredK1sAction):
      return {
        ...state,
        filtered: action.payload.k1s,
      }
    case getType(selectionSelectStatusFilterListAction):
      return {
        ...state,
        selectedStatusFilterList: action.payload.list,
      }

    case getType(selectionSelectK1sListAction):
      return {
        ...state,
        selectedK1sList: action.payload.list,
      }

    case getType(selectionSelectDocumentSortAction):
      return {
        ...state,
        selectedDocumentSort: action.payload.sort,
      }

    case getType(selectionSelectCommentFilterAction):
      return {
        ...state,
        selectedCommentFilter: action.payload.filter,
      }

    case getType(selectionSelectCommentSortAction):
      return {
        ...state,
        selectedCommentSort: action.payload.sort,
      }
    // #region Dashboard Filters
    case getType(selectionAddDashboardDateFilterAction):
    case getType(selectionAddDashboardStatusFilterAction):
    case getType(selectionAddDashboardTypeFilterAction):
    case getType(selectionAddDashboardYearFilterAction):
    case getType(selectionClearDashboardDateFilterAction):
    case getType(selectionClearDashboardStatusFilterAction):
    case getType(selectionClearDashboardTypeFilterAction):
    case getType(selectionClearDashboardYearFilterAction):
    case getType(selectionClearAllDashboardFiltersAction): {
      const selectedDashboardFilters = dashboardFilterReducer(
        state.selectedDashboardFilters,
        action
      )
      if (selectedDashboardFilters !== state.selectedDashboardFilters) {
        return {
          ...state,
          selectedDashboardFilters,
        }
      }
      break
    }
    // #endregion
    default:
      break
  }
  return state
}

function dashboardFilterReducer(
  state: SelectionDashboardFilters | undefined,
  action: TsaHubAction
) {
  let nextState = state
  switch (action.type) {
    case getType(selectionAddDashboardDateFilterAction): {
      if (action.payload.filter === 'AllDates') {
        // all dates means no filter
        if (nextState) {
          // we have a state, so set the date filters to undefined
          nextState = { ...nextState, DateFilters: undefined }
        }
      } else if (!nextState || !nextState.DateFilters) {
        nextState = nextState ? { ...nextState } : {}
        nextState.DateFilters = (nextState.DateFilters || []).concat({
          ...action.payload,
        })
      } else {
        const dateIndex = nextState.DateFilters.findIndex(
          createFindDateFilter(action.payload.filter)
        )
        if (dateIndex === -1) {
          nextState = nextState ? { ...nextState } : {}
          nextState.DateFilters = (nextState.DateFilters || []).concat({
            ...action.payload,
          })
        } else {
          const DateFilters = [...nextState.DateFilters]
          DateFilters.splice(dateIndex, 1, { ...action.payload })
          nextState = { ...nextState, DateFilters }
        }
      }
      break
    }
    case getType(selectionAddDashboardStatusFilterAction): {
      if (action.payload.filter === 'AllStatuses') {
        // all statuses means no filter
        if (nextState) {
          // we have a state, so set the status filters to undefined
          nextState = { ...nextState, StatusFilters: undefined }
        }
      } else if (
        !nextState ||
        !nextState.StatusFilters ||
        nextState.StatusFilters.indexOf(action.payload.filter) === -1
      ) {
        nextState = nextState ? { ...nextState } : {}
        nextState.StatusFilters = (nextState.StatusFilters || []).concat(
          action.payload.filter
        )
      }
      break
    }

    case getType(selectionAddDashboardYearFilterAction): {
      if (action.payload.filter === 'AllYears') {
        nextState = nextState
          ? { ...nextState, YearFilters: undefined }
          : nextState
      } else if (
        !nextState ||
        !nextState.YearFilters ||
        nextState.YearFilters.indexOf(action.payload.filter) === -1
      ) {
        nextState = nextState ? { ...nextState } : {}
        nextState.YearFilters = (nextState.YearFilters || []).concat(
          action.payload.filter
        )
      }
      break
    }

    case getType(selectionAddDashboardTypeFilterAction): {
      if (action.payload.filter === 'AllTypes') {
        nextState = nextState
          ? { ...nextState, TypeFilters: undefined }
          : nextState
      } else if (
        !nextState ||
        !nextState.TypeFilters ||
        nextState.TypeFilters.indexOf(action.payload.filter) === -1
      ) {
        nextState = nextState ? { ...nextState } : {}
        nextState.TypeFilters = (nextState.TypeFilters || []).concat(
          action.payload.filter
        )
      }
      break
    }

    case getType(selectionClearDashboardDateFilterAction): {
      if (nextState && nextState.DateFilters) {
        const dateIndex = nextState.DateFilters.findIndex(
          createFindDateFilter(action.payload.filter)
        )
        if (dateIndex >= 0) {
          const DateFilters = [...nextState.DateFilters]
          DateFilters.splice(dateIndex, 1)

          if (DateFilters.length > 0) {
            nextState = { ...nextState, DateFilters }
          } else {
            nextState = { ...nextState, DateFilters: undefined }
          }
        }
      }
      break
    }
    case getType(selectionClearDashboardStatusFilterAction): {
      if (nextState && nextState.StatusFilters) {
        const statusIndex = nextState.StatusFilters.indexOf(
          action.payload.filter
        )
        if (statusIndex >= 0) {
          const StatusFilters = [...nextState.StatusFilters]
          StatusFilters.splice(statusIndex, 1)

          if (StatusFilters.length > 0) {
            nextState = { ...nextState, StatusFilters }
          } else {
            nextState = { ...nextState, StatusFilters: undefined }
          }
        }
      }
      break
    }

    case getType(selectionClearDashboardYearFilterAction): {
      if (nextState && nextState.YearFilters) {
        const yearIndex = nextState.YearFilters.indexOf(action.payload.filter)
        if (yearIndex >= 0) {
          const YearFilters = [...nextState.YearFilters]
          YearFilters.splice(yearIndex, 1)

          if (YearFilters.length > 0) {
            nextState = { ...nextState, YearFilters }
          } else {
            nextState = { ...nextState, YearFilters: undefined }
          }
        }
      }
      break
    }

    case getType(selectionClearDashboardTypeFilterAction): {
      if (nextState && nextState.TypeFilters) {
        const typeIndex = nextState.TypeFilters.indexOf(action.payload.filter)
        if (typeIndex >= 0) {
          const TypeFilters = [...nextState.TypeFilters]
          TypeFilters.splice(typeIndex, 1)

          if (TypeFilters.length > 0) {
            nextState = { ...nextState, TypeFilters }
          } else {
            nextState = { ...nextState, TypeFilters: undefined }
          }
        }
      }
      break
    }
    case getType(selectionClearAllDashboardFiltersAction):
      nextState = undefined
      break
    default:
      break
  }

  if (
    nextState &&
    !nextState.DateFilters &&
    !nextState.YearFilters &&
    !nextState.StatusFilters &&
    !nextState.TypeFilters
  ) {
    nextState = undefined
  }

  return nextState
}

function createFindDateFilter(filter: keyof DashboardDateFilters) {
  return (date: DashboardDateFilter): boolean => {
    return date.filter === filter
  }
}

import * as React from 'react'
import { MdAttachFile, MdSend, MdVisibilityOff } from 'react-icons/md'
import { IconProperties } from './properties'

class MdIcons {
  Attachment = MdAttachFile
  Send = MdSend
  VisibilityOff = MdVisibilityOff
}

const lookup = new MdIcons()

function mdIconHOC (iconId: keyof MdIcons) {
  const Component = lookup[iconId]

  const innerClass: React.SFC<IconProperties> = ({
    className,
    onClick,
    tooltip,
  }) => {
    return (
      <Component
        className={className}
        focusable='false'
        onClick={onClick}
        data-tip={tooltip}
      />
    )
  }

  return innerClass
}

export default mdIconHOC

import moment from 'moment'
import * as React from 'react'
import { Engagement } from '../../clientModels'
import Icon from '../icon/icon'
import { MilestoneInfo } from './utilities'

export interface DueDateItemProps {
  engagement?: Engagement
  milestone: MilestoneInfo
  index: number
}

export const DueDateItem: React.SFC<DueDateItemProps> = ({
  engagement,
  milestone,
  index,
}) => {
  const m =
    engagement &&
    engagement.milestones &&
    engagement.milestones.filter(x => x.milestone === milestone.code)[0]
  if (!m) {
    return null
  }

  const date = moment.utc(m.completedDate || m.dueDate, moment.ISO_8601)
  const isValidDate = date.isValid()
  const remainingMessage = isValidDate && date.fromNow(true)
  const now = moment.utc(new Date())
  const duration = isValidDate && moment.duration(now.diff(date))
  const overdueDays = duration ? duration.asDays() : 0
  const icon = m.completedDate
    ? 'checkedCircle'
    : m.dueDate && overdueDays > 0 && 'error'
  const overdue =
    icon === 'error' && isValidDate ? ` - ${remainingMessage} overdue` : ''

  return (
    m && (
      <div
        id={`due-date-dropdown-menu-${index}`}
        key={index}
        className='nav-link due-date-list-item'
      >
        <div className='due-date-icon'>{icon && <Icon icon={icon} />}</div>
        <div className='due-date-status'>
          <div className=''>
            {m.completedDate && milestone.doneCaption
              ? milestone.doneCaption
              : milestone.caption}
          </div>
          <div className='due-date'>
            {isValidDate ? date.format('l') : 'Not Completed Yet'}
            {overdue}
          </div>
        </div>
      </div>
    )
  )
}

import * as React from 'react'
import classNames from 'classnames'
import Icon from '../icon/icon'
import FileVersionList from './fileVersionList'
import { useDispatch } from 'react-redux'
import { File } from '../../services/api/apiModels'
import { setCurrentVersion } from '../../actions/fileThunks'
import { ApiModels } from '../../services/api'
import Popup from '../popup'

interface VersionIconProperties {
  active?: boolean
  className?: string
  disabled?: boolean
  files: File[]
  parentId?: number
  tooltip?: string
  onClickDownload?: (id: number) => void
}

export const VersionIcon: React.SFC<VersionIconProperties> = ({
  active,
  className,
  disabled,
  files,
  parentId,
  tooltip,
  onClickDownload,
}) => {
  const [showMenu, displayMenu] = React.useState(false)
  const fileVersions = files.filter(f => f.parentId === parentId)

  const dispatch = useDispatch()

  if (!fileVersions) return <div></div>

  const handleChange = (file: ApiModels.File) => {
    dispatch(setCurrentVersion(file.id))
    displayMenu(false)
  }

  return (
    <div
      className={classNames('configure-icon ' + className)}
      data-tip={tooltip}
    >
      <Icon
        icon='clock'
        active={active}
        className={className}
        tooltip='Version history'
        onClick={() => displayMenu(true)}
      />
      <Popup
        show={showMenu}
        horizontalAlign='right'
        className='file-version-popup'
      >
        <FileVersionList
          files={fileVersions}
          handleChange={handleChange}
          onClickDownload={onClickDownload}
          displayMenu={displayMenu}
        />
      </Popup>
    </div>
  )
}

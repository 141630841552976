import { SignalRListener } from './middleware'
import { AppState } from '../store'
import { Dispatch } from 'redux'
import { getEngagementK1s } from './../actions/inputProcessThunks'

const SignalRK1HeaderListener: SignalRListener = {
  message: 'UpdateK1HeadersCompleteCallback',
  type: 'on',
  callback: function(
    getState: () => AppState,
    dispatch: Dispatch<any>,
    engagementId: number
  ) {
    dispatch(getEngagementK1s(engagementId))
  },
}
export const listeners: SignalRListener[] = [SignalRK1HeaderListener]

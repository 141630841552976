import {
  Activity,
  EngagementMap,
  EngagementQuestionMap,
  IdentityTokenProfile,
} from '../../clientModels'
import { ActivityState } from '../../reducers'
import { engagementLockedPhase } from '../engagementPhaseService'
import { PhaseCode } from '../../enums'

const questionDisabledPhases = [
  PhaseCode.Review,
  PhaseCode.CCH,
  PhaseCode.IRS,
  PhaseCode.Closed,
  undefined,
]

function hasUnresolveMention(activity: Activity) {
  return !!activity.mentions && activity.mentions.some(m => !m.resolved)
}

function mentionsClient(activity: Activity) {
  return !!activity.mentions && activity.mentions.some(m => m.label !== 'RSM')
}

export function questionDisabled(
  engagementId: number,
  questionId: number,
  engagements: EngagementMap,
  activities: ActivityState,
  engagementQuestions?: EngagementQuestionMap,
  user?: IdentityTokenProfile,
  isExternalUser?: boolean
) {
  const engagement = engagements[engagementId]
  const engagementQuestion =
    engagementQuestions && engagementQuestions[questionId]

  if (!engagement || !user) {
    return true
  }

  if (!isExternalUser) {
    return engagement.isEnabled !== undefined
      ? engagement.isEnabled
      : engagementLockedPhase(engagement)
  }

  if (engagementQuestion && engagementQuestion.isEnabled) {
    return false
  }

  const disabledByPhase = questionDisabledPhases.includes(engagement.phase)
  if (!disabledByPhase) {
    // We lock questions during the review, cch, closed, and irs phases for external users
    return false
  }

  const engagementActivities = activities[engagementId]
  const questionActivities =
    (engagementActivities && engagementActivities[questionId]) || []
  const hasUnresolvedClientConversation = questionActivities.some(parent => {
    if (parent.parentId) {
      // This isn't actually a parent activity so move on
      return false
    }

    if (!mentionsClient(parent)) {
      // We only allow conversations that start by mentioning the client to unlock a question
      return false
    }

    if (hasUnresolveMention(parent)) {
      // If the parent has an unresolved mention then unlock the question
      return true
    }

    // If any of the parent's children have an unresolved mention then unlock the question
    return questionActivities.some(
      c => c.parentId === parent.id && hasUnresolveMention(c)
    )
  })

  return !hasUnresolvedClientConversation
}

import * as http from '../http'
import { buildApiUrl } from './apiUrl'
import { EntityGroup } from './apiModels'

export function apiGetEntityGroup(
  entityGroupId: number | string
): Promise<EntityGroup> {
  const url = buildApiUrl(`/entitygroups/${entityGroupId}`)
  return http.get(url)
}

export function apiGetAllEntityGroups(): Promise<EntityGroup[]> {
  const url = buildApiUrl(`/entitygroups`)
  return http.get(url)
}

import {
  getEngagementByEngagementId,
  getEngagementTemplatebyId,
} from '../reducers/selectors'
import { FilesApi } from '../services/api'
import { saveBlob } from '../services/http'
import { TsaThunkAction } from '../store'

export const downloadEmptyExcelTemplate = (
  fileName: string,
  engagementId: number
): TsaThunkAction => async (_, getState) => {
  const state = getState()
  const engagement = getEngagementByEngagementId(state, engagementId)
  if (!engagement) {
    throw new Error(`Engagement (${engagementId}) not found.`)
  }

  const { clientId, engagementTemplateId } = engagement
  const engagementTemplate = getEngagementTemplatebyId(
    state,
    engagementTemplateId
  )
  if (!engagementTemplate) {
    throw new Error(`Engagement template (${engagementTemplateId}) not found.`)
  }

  const { resourceType, taxYear } = engagementTemplate
  const blob = await FilesApi.postDownloadExcelTemplate(
    resourceType || '',
    taxYear,
    fileName,
    clientId,
    engagementId
  )

  await saveBlob(blob)
}

import moment from 'moment'
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType,
} from 'typesafe-actions'
import { CchProxyResponse, CchRequest, CchTestHarnessResult } from '.'

export const requestTaxDocAction = createAsyncAction(
  'EXPORT_REQUEST',
  'EXPORT_REQUEST_DONE',
  'EXPORT_REQUEST_ERROR'
)<void, CchTestHarnessResult, CchTestHarnessResult>()

export const submitTaxDocAction = createAsyncAction(
  'SUBMIT_REQUEST',
  'SUBMIT_REQUEST_DONE',
  'SUBMIT_REQUEST_ERROR'
)<void, CchTestHarnessResult, CchTestHarnessResult>()

export const cchRequestAction = createAction('REQUEST_RESPONSE')<
  CchProxyResponse
>()

type CchExportAction =
  | ActionType<typeof cchRequestAction>
  | ActionType<typeof requestTaxDocAction.failure>
  | ActionType<typeof requestTaxDocAction.request>
  | ActionType<typeof requestTaxDocAction.success>
  | ActionType<typeof submitTaxDocAction.failure>
  | ActionType<typeof submitTaxDocAction.request>
  | ActionType<typeof submitTaxDocAction.success>

export function cchRequestsReducer(
  state: CchRequest[],
  action: CchExportAction
): CchRequest[] {
  switch (action.type) {
    default:
      return state

    case getType(requestTaxDocAction.success):
      return [
        ...state,
        {
          ...action.payload,
          created: moment(),
          state: 'In Progress',
          type: 'requesttaxdocument',
        },
      ]

    case getType(requestTaxDocAction.failure):
      return [
        ...state,
        {
          ...action.payload,
          created: moment(),
          state: 'Error',
          type: 'requesttaxdocument',
        },
      ]

    case getType(submitTaxDocAction.success):
      return [
        ...state,
        {
          ...action.payload,
          created: moment(),
          state: 'In Progress',
          type: 'submittaxdocument',
        },
      ]

    case getType(submitTaxDocAction.failure):
      return [
        ...state,
        {
          ...action.payload,
          created: moment(),
          state: 'Error',
          type: 'submittaxdocument',
        },
      ]

    case getType(cchRequestAction): {
      const { payload } = action
      var index = state.findIndex(r => r.requestId === payload.requestId)
      if (index === -1) {
        return state
      }
      const failed = payload.status.toLowerCase().includes('fail')
      return state.map((request, i) =>
        i === index
          ? {
            ...request,
            state: failed ? 'Error' : 'Complete',
            message: payload.message,
            response: payload,
          }
          : request
      )
    }
  }
}

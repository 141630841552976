import * as React from 'react'
import {
  FaAngleDown,
  FaAngleUp,
  FaAsterisk,
  FaBell,
  FaCaretDown,
  FaCaretRight,
  FaCheck,
  FaCheckCircle,
  FaCircleNotch,
  FaClock,
  FaCog,
  FaComment,
  FaCopy,
  FaDatabase,
  FaDownload,
  FaEdit,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaExternalLinkSquareAlt,
  FaEye,
  FaFileDownload,
  FaFlag,
  FaInfoCircle,
  FaLock,
  FaPlus,
  FaPlusCircle,
  FaProjectDiagram,
  FaQuestionCircle,
  FaRegEnvelope,
  FaReplyAll,
  FaSave,
  FaSearch,
  FaShareAltSquare,
  FaSignOutAlt,
  FaTasks,
  FaTimesCircle,
  FaTrash,
  FaUnlock,
  FaUpload,
  FaFileAlt,
  FaTrashAlt,
  FaFilter
} from 'react-icons/fa'
import { IconProperties } from './properties'
import { FiRotateCcw, FiRotateCw } from 'react-icons/fi'

// fa check
class FAIcons {
  Asterisk = FaAsterisk
  Bell = FaBell
  CarryForwardAll = FaReplyAll
  CaretDown = FaCaretDown
  CaretRight = FaCaretRight
  CarryForwardOutline = FaShareAltSquare
  Check = FaCheck
  CheckCircle = FaCheckCircle
  CircleLeft = FiRotateCcw
  CircleNotch = FaCircleNotch
  CircleRight = FiRotateCw
  Clock = FaClock
  Cog = FaCog
  Comment = FaComment
  Copy = FaCopy
  Database = FaDatabase
  Delete = FaTrash
  DeleteAlt = FaTrashAlt
  Download = FaDownload
  Edit = FaEdit
  Error = FaExclamationTriangle
  ExternalLink = FaExternalLinkSquareAlt
  Eye = FaEye
  FileDownload = FaFileDownload
  FileAlt = FaFileAlt
  Filter = FaFilter
  Flag = FaFlag
  InfoCircle = FaInfoCircle
  IsClosed = FaAngleDown
  IsOpened = FaAngleUp
  Lock = FaLock
  Notification = FaRegEnvelope
  OpenContext = FaExternalLinkSquareAlt
  Plus = FaPlus
  PlusCircle = FaPlusCircle
  QuestionCircle = FaQuestionCircle
  Rule = FaProjectDiagram
  Saving = FaSave
  Search = FaSearch
  SignOut = FaSignOutAlt
  Success = FaCheckCircle
  Tasks = FaTasks
  TimesCircle = FaTimesCircle
  Unlock = FaUnlock
  Upload = FaUpload
  Warning = FaExclamationCircle
}

const lookup = new FAIcons()

function faIconHOC(iconId: keyof FAIcons) {
  const Component = lookup[iconId]

  const innerClass: React.SFC<IconProperties> = ({
    className,
    onClick,
    tooltip
  }) => {
    return (
      <Component
        className={className}
        focusable='false'
        onClick={onClick}
        data-tip={tooltip}
      />
    )
  }

  return innerClass
}

export default faIconHOC

import * as React from 'react'
import { connect } from 'react-redux'
import { downloadTaxFormFile } from '../../actions/fileThunks'
import { TsaDispatch } from '../../store'
import Icon from '../icon/icon'
import './glossary.scss'
import { RouteComponentProps } from 'react-router'
import { ensureNumber } from '../../guards'
interface OwnProperties extends RouteComponentProps<RouteProperties> {}

interface RouteProperties {
  formName?: string
  taxYear?: string
}

interface DispatchProperties {
  downloadHelpFile: () => void
}

export const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: OwnProperties
): DispatchProperties => {
  const formName = props.match.params.formName ? props.match.params.formName : ''
  const taxYear = props.match.params.taxYear ? ensureNumber(props.match.params.taxYear) : 0
  
  return {
    downloadHelpFile: () =>
      dispatch(downloadTaxFormFile(formName, taxYear, 'Glossary.pdf')),
  }
}

type Properties = DispatchProperties
class GlossaryBody extends React.Component<Properties> {
  download990Glossary = () => {
    const {
      props: { downloadHelpFile },
    } = this

    downloadHelpFile()
  }

  render () {
    const { download990Glossary } = this
    return (
      <div className='glossary-body flex-wrap'>
        The following terms are used throughout this engagement and are provided
        to you for clarity purposes in order to not only understand the
        questions you are answering, but to also understand the backup
        information required from your organization for presentation on the Form
        990.
        <br />
        <div
          className='download-990-glossary btn btn-primary'
          onClick={download990Glossary}
        >
          <Icon icon='download' /> <div className='text'>Glossary (pdf)</div>
        </div>
      </div>
    )
  }
}

export default connect(
  undefined,
  mapDispatchToProps
)(GlossaryBody)

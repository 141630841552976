import React, { useEffect, ReactNode } from 'react'
import { AppState } from '../../store'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PhaseCode } from '../../enums'

export type CanAccessEngagementProps = {
  phase?: PhaseCode
  children: ReactNode
}

export const CanAccessEngagement = ({
  phase,
  children,
}: CanAccessEngagementProps) => {
  const {
    auth: { user },
  } = useSelector((state: AppState) => state)
  let history = useHistory()

  useEffect(() => {
    if (user?.isExternal && phase === PhaseCode.Setup) {
      history.push('/')
    }
  }, [user, phase])

  if (!user || !phase) {
    return null
  }

  return <>{children}</>
}

import React from 'react'

type MessageProps = {
  message?: string
}

export const Message = ({ message }: MessageProps) => {
  if (!message) {
    return <></>
  }

  // Remove some ugly formatting from the CCH messages and split on \r\n literals
  let sanitized = message.replace(/^[["\\]*/, '')
  sanitized = sanitized.replace(/[\]"\\]*$/, '')
  const paragraphs = sanitized.split('\\r\\n')
  if (paragraphs.length === 1) {
    return <>{paragraphs[0]}</>
  }

  return (
    <>
      {paragraphs.map((p, i) => (
        <p key={i}>{p.trim()}</p>
      ))}
    </>
  )
}

import classNames from 'classnames'
import * as React from 'react'
import { Option } from '../../clientModels'
import { Anchor } from '../anchor'

interface DropdownItemProps {
  isActive?: boolean
  onClick: (value: Option) => void
  option: Option
}

export class DropdownItem extends React.Component<DropdownItemProps> {
  render () {
    const { option, isActive } = this.props
    if (option.isDisabled) {
      return (
        <div
          className={classNames('dropdown-item', {
            active: isActive,
            disabled: option.isDisabled,
          })}
        >
          {option.label}
        </div>
      )
    }
    return (
      <Anchor
        className={classNames('dropdown-item', {
          active: isActive,
          disabled: option.isDisabled,
        })}
        onClick={this.handleClick}
      >
        {option.label}
      </Anchor>
    )
  }

  private handleClick = () => {
    const { option, onClick } = this.props
    if (!option.isDisabled) {
      onClick(option)
    }
  }
}

import { ActionType, createAction } from 'typesafe-actions'
import { Engagement } from '../clientModels'

export const setDashboardEngagements = createAction(
  'SET_DASHBOARD_ENGAGEMENTS'
)<Engagement[]>()

export type DashboardEngagementActions = ActionType<
  typeof setDashboardEngagements
>

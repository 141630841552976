import { ClientLight, EntityMap } from '../clientModels'
import { ActionType, createAsyncAction } from 'typesafe-actions'

export const clientAction = createAsyncAction(
  'GET_CLIENT_LIST_BEGIN',
  'GET_CLIENT_LIST_DONE',
  'GET_CLIENT_LIST_FAIL'
)<
  {},
  {
    clients: EntityMap<ClientLight>
  },
  Error
>()

export type ClientActions =
  | ActionType<typeof clientAction.request>
  | ActionType<typeof clientAction.success>
  | ActionType<typeof clientAction.failure>

import { TsaHubAction } from '../actions/index'
import { ClientAccessRequest, ClientSearch } from '../clientModels'
import { getType } from 'typesafe-actions'
import * as clientAccessRequestSearchActions from '../actions/clientAccessRequestSearchActions'

export interface clientAccessRequestSearchState {
  searchString: string
  selectedSearchResult?: ClientSearch
  submitResult?: ClientAccessRequest
  results?: ClientSearch[]
  clientAccessSearchStatus: string
}

const initialState: clientAccessRequestSearchState = {
  searchString: '',
  clientAccessSearchStatus: 'Idle'
}

export function clientAccessRequestSearchReducer (
  state: clientAccessRequestSearchState = initialState,
  action: TsaHubAction
): clientAccessRequestSearchState {
  switch (action.type) {
    default:
      return state

    case getType(clientAccessRequestSearchActions.clientSearchClearAction):
      return initialState

    case getType(clientAccessRequestSearchActions.clientSearchClearResultsAction):
      delete state.results
      delete state.selectedSearchResult
      delete state.submitResult
      return { ...state, searchString: action.payload.searchString }

    case getType(clientAccessRequestSearchActions.clientSearchSelectResultAction):
      return { ...state, ...action.payload }

    case getType(clientAccessRequestSearchActions.clientSearchSubmitResultAction):
      return { ...state, ...action.payload }

    case getType(clientAccessRequestSearchActions.clientSearchAction):
      delete state.selectedSearchResult
      return { ...state, ...action.payload }
  }
}
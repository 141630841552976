import { Theme } from '@rsmus/react-ui'
import clsx from 'classnames'
import React from 'react'
import { IconType } from 'react-icons'
import { useTheme, createUseStyles } from 'react-jss'

function styles({ palette }: Theme) {
  return {
    root: {
      fontSize: '1.5rem',
      padding: '0 0.5rem',
      marginBottom: '0.25rem',
      cursor: 'pointer',
      color: palette.grey[200],
      lineHeight: 1,
      '&:hover': {
        color: palette.common.white,
      },
    },
    disabled: {
      color: palette.grey[500],
      cursor: 'default',
      '&:hover': {
        color: palette.grey[500],
      },
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface TabTabsIconProps {
  icon: IconType
  disabled: boolean
  onClick: (e: React.MouseEvent) => void
}

const NavTabsIcon = ({ icon: Icon, disabled, onClick }: TabTabsIconProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <div className={clsx(classes.root, { [classes.disabled]: disabled })}>
      <Icon onClick={onClick} />
    </div>
  )
}

export default NavTabsIcon

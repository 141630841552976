import * as React from 'react'
import { IconProperties } from './properties'

const Dashboard: React.SFC<IconProperties> = ({
  className,
  onClick,
  tooltip,
}) => {
  return (
    <svg
      viewBox='0 0 32.27 32.27'
      height='1em'
      width='1em'
      className={className}
      onClick={onClick}
      focusable='false'
      data-tip={tooltip}
    >
      <circle cx='16.13' cy='16.13' r='15.5' />
      <path d='M18.05,16.43a1.86,1.86,0,1,1-1.78-1.94A1.86,1.86,0,0,1,18.05,16.43Z' />
      <line x1='17.96' y1='15.78' x2='26.34' y2='13.4' />
      <line x1='11.5' y1='17.62' x2='14.39' y2='16.8' />
      <line x1='16.13' y1='2.97' x2='16.13' y2='5.38' />
      <line x1='12.73' y1='3.42' x2='13.35' y2='5.74' />
      <line x1='9.55' y1='4.73' x2='10.76' y2='6.82' />
      <line x1='6.82' y1='6.82' x2='8.53' y2='8.53' />
      <line x1='4.73' y1='9.55' x2='6.82' y2='10.76' />
      <line x1='3.42' y1='12.73' x2='5.74' y2='13.35' />
      <line x1='26.53' y1='18.92' x2='28.85' y2='19.54' />
      <line x1='2.97' y1='16.13' x2='5.38' y2='16.13' />
      <line x1='26.89' y1='16.13' x2='29.3' y2='16.13' />
      <line x1='3.42' y1='19.54' x2='5.74' y2='18.92' />
      <line x1='26.53' y1='13.35' x2='28.85' y2='12.73' />
      <line x1='25.45' y1='10.76' x2='27.54' y2='9.55' />
      <line x1='23.74' y1='8.53' x2='25.44' y2='6.82' />
      <line x1='21.51' y1='6.82' x2='22.72' y2='4.73' />
      <line x1='18.92' y1='5.74' x2='19.54' y2='3.42' />
      <path d='M1.77,22a47.08,47.08,0,0,1,14.36-2.11,47.14,47.14,0,0,1,14.2,2.06' />
      <path d='M18.89,27.45H13.22a2.13,2.13,0,0,1-2.13-2.13h0a2.13,2.13,0,0,1,2.13-2.13h5.67A2.14,2.14,0,0,1,21,25.32h0A2.14,2.14,0,0,1,18.89,27.45Z' />
    </svg>
  )
}

export default Dashboard

import { createAsyncAction, ActionType } from 'typesafe-actions'

export const generalAction = createAsyncAction(
  'UPDATE_ASSIGNMENTS_BEGIN',
  'UPDATE_ASSIGNMENTS_DONE',
  'UPDATE_ASSIGNMENTS_FAIL'
)<
  {},
  {
    approvedMimeTypes: string[]
    approvedMimeTypeExtensions: string[]
    approvedTemplateMimeTypes: string[]
    approvedTemplateMimeTypeExtensions: string[]
  },
  Error
>()

export type GeneralActions =
  | ActionType<typeof generalAction.request>
  | ActionType<typeof generalAction.success>
  | ActionType<typeof generalAction.failure>

import { TsaHubAction } from '../actions/index'
import { Role } from '../clientModels'
import { RoleCode } from '../enums'

const initialState: Map<RoleCode, Role> = new Map([
  [
    RoleCode.ClientPreparer,
    { code: RoleCode.ClientPreparer, description: 'Client' },
  ],
  [RoleCode.Preparer, { code: RoleCode.Preparer, description: 'Preparer' }],
  [
    RoleCode.PrimaryReviewer,
    { code: RoleCode.PrimaryReviewer, description: 'Primary' },
  ],
  [
    RoleCode.SecondaryReviewer,
    { code: RoleCode.SecondaryReviewer, description: 'Secondary' },
  ],
  [
    RoleCode.ConcurringReviewer,
    { code: RoleCode.ConcurringReviewer, description: 'Concurring' },
  ],
])

export function rolesReducer (
  state: Map<RoleCode, Role> = initialState,
  action: TsaHubAction
): Map<RoleCode, Role> {
  switch (action.type) {
    default:
      return state
  }
}

import { map, pull, sortBy } from 'lodash'
import moment from 'moment'
import { FileWithPath } from 'react-dropzone'
import {
  DocumentTitle,
  File,
  FileGroup,
  IdentityTokenProfile,
  Message,
  EntityListOperation,
} from '../../clientModels'

export function CheckValid(
  engagementId: number,
  questionId: number,
  documentTitle?: DocumentTitle,
  openDialog?: boolean
): boolean {
  return engagementId > -1 && questionId > -1 && !!openDialog && !!documentTitle
}

export function countFiles(files: File[]) {
  return files.reduce((count, file) => count + (file.isDeleted ? 0 : 1), 0)
}

export function createWarningMessage(message: string): Message {
  return {
    type: '',
    severity: 'warning',
    message,
  }
}

export function addHandler(
  engagementId: number,
  questionId: number,
  isTemplate: boolean | undefined,
  documentTitle: DocumentTitle | undefined,
  fileGroup: FileGroup | undefined,
  files: File[],
  phase: number,
  needsSecondPhase: boolean,
  setPhase: (phase: number) => void,
  setNeedsSecondPhase: (needsSecondPhase: boolean) => void,
  setMessages: (messages: Message[]) => void,
  addOrUpdateFileGroup: (group: FileGroup, isTemplate?: boolean) => void,
  setFileUploadFileGroup: (fileGroup: FileGroup) => void
) {
  return function() {
    if (needsSecondPhase && phase === 1) {
      setPhase(2)
      return
    }
    const fg = fileGroup || {
      id: -1,
      engagementId,
      questionId,
      documentTitleId: documentTitle && documentTitle.id,
      title: documentTitle && documentTitle.title,
    }

    fg.files = files

    if (!fileGroup) {
      setFileUploadFileGroup(fg)
    }

    addOrUpdateFileGroup(fg, isTemplate)
    setPhase(1)
    setNeedsSecondPhase(false)
    setMessages([])
  }
}

export function deleteHandler(
  currentFiles: File[],
  setFiles: (files: File[]) => void
) {
  return function(deletedFile: File) {
    const newFiles = [...currentFiles]
    if (deletedFile.id < 0) {
      pull(newFiles, deletedFile)
    } else {
      deletedFile.isUpdated = false
    }
    setFiles(newFiles)
  }
}

export function dropHandler(
  maxFileSize: number,
  maxFileCount: number,
  acceptedFileTypes: string | undefined,
  currentFiles: File[],
  currentUser: IdentityTokenProfile | undefined,
  setMessages: (message: Message[]) => void,
  setFiles: (files: File[]) => void,
  setNeedsSecondPhase: (needsSecondPhase: boolean) => void,
  replace?: number,
  defaultTags?: string[]
) {
  return function(droppedFiles: FileWithPath[]) {
    const messages: Message[] = []
    const nextFiles: File[] = [...currentFiles]
    droppedFiles.forEach(file => {
      if (maxFileSize < file.size) {
        messages.push(createWarningMessage(`${file.name} too large`))
        return
      }

      if (acceptedFileTypes && !acceptedFileTypes.includes(file.type)) {
        messages.push(createWarningMessage(`${file.name} invalid file type`))
        return
      }

      if (file.size === 0) {
        messages.push(createWarningMessage(`${file.name} has no content`))
        return
      }

      if (replace !== null && replace !== undefined) {
        const existingFile = nextFiles.find(f => f.id === replace)
        if (existingFile) {
          pull(nextFiles, existingFile)
          nextFiles.push({
            id: existingFile.id,
            name: file.name,
            contents: file,
            isUpdated: true,
            createdDate: existingFile.createdDate,
            userProfile: existingFile.userProfile,
          })
        }
        return
      }

      const existingFile = currentFiles.find(p => p.name === file.name)
      if (existingFile) {
        pull(nextFiles, existingFile)
        setNeedsSecondPhase(true)
        nextFiles.push({
          id: existingFile.id,
          name: file.name,
          contents: file,
          isUpdated: true,
          createdDate: existingFile.createdDate,
          userProfile: existingFile.userProfile,
        })
      } else {
        nextFiles.push({
          id: -1,
          name: file.name,
          contents: file,
          createdDate: moment().toISOString(),
          userProfile: {
            firstName: currentUser && currentUser.firstName,
            lastName: currentUser && currentUser.lastName,
            id: -1,
            isInternal: (currentUser && !currentUser.isExternal) || false,
            userId: (currentUser && currentUser.name) || '',
          },
          fileTagsToBeUpdated: defaultTags
            ? defaultTags.map(newTag => {
                return {
                  op: EntityListOperation.add,
                  value: {
                    id: 0,
                    fileId: 0,
                    tag: newTag,
                  },
                }
              })
            : undefined,
        })
      }
    })

    if (countFiles(nextFiles) > maxFileCount) {
      setMessages([createWarningMessage('Max file count exceeded')])
    } else {
      setFiles(sortBy(nextFiles, 'name'))
      setMessages(messages)
    }
  }
}

export function processFileGroupFiles(
  files?: File[],
  replace?: number
): File[] {
  return map(sortBy([...(files || [])], 'name'), file => {
    const newFile = { ...file }
    newFile.isDeleted = false
    newFile.isUpdated = file.id === replace
    return newFile
  })
}

export function checkDisabled(
  files: File[],
  disabled: boolean,
  maxFileCount: number
): boolean {
  return disabled || (maxFileCount <= countFiles(files) && false)
}

const BYTE_LABELS = ['B', 'KB', 'MB', 'GB', 'TB']
export function getSizeLabel(fileSize: number, allParts?: boolean) {
  let result = ''
  let index = 0
  const byteLabelLoopLength = BYTE_LABELS.length - 1
  let spacer = ''
  while (fileSize > 1024 && index < byteLabelLoopLength) {
    const partSize = fileSize % 1024
    if (partSize > 0) {
      result = `${partSize} ${BYTE_LABELS[index]}${spacer}${result}`
      spacer = ' '
    }
    fileSize = (fileSize - partSize) / 1024
    ++index
  }
  return (
    `${fileSize} ${BYTE_LABELS[index]}` + (allParts ? `${spacer}${result}` : '')
  )
}

export function formatNumber(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatAcceptedFileTypes(approvedFileTypes?: string) {
  return approvedFileTypes ? `.${approvedFileTypes.replace(/,/g, ', .')}` : ''
}

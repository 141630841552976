import { EngagementUploadHistoriesApi } from '../services/api'
import { TsaThunkAction } from '../store'
import * as actions from './fileActions'
import { httpBeginAction, httpEndAction } from './httpActions'

export const getEngagementUploadHistories = (): TsaThunkAction<Promise<
  void
>> => async dispatch => {
  // Start the get engagement upload history busy indicator
  const id = 'get engagement upload history'
  dispatch(httpBeginAction({ id }))

  try {
    dispatch(
      actions.uploadEngagementSetup.success(
        await EngagementUploadHistoriesApi.odataGetEngagementUploadHistories()
      )
    )
  } catch (error) {
    dispatch(actions.addUpdateFileGroup.failure(error))
  }

  // Stop the file upload busy indicator
  dispatch(httpEndAction({ id }))
}

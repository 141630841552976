import moment from 'moment'
import { ThunkDispatch } from 'redux-thunk'
import {
  EngagementQuestion,
  EngagementQuestionMap,
  IEngagementQuestionAnswer,
} from '../clientModels'
import { ensureNumber } from '../guards'
import { EngagementQuestionsState } from '../reducers/engagementQuestionsReducer'
import { AnswersApi } from '../services/api'
import { CurrentAnswer, UpdateFlag } from '../services/api/apiModels'
import { getReviewsComplete } from '../services/questionReviewUtilities'
import { AppState } from '../store'
import { TsaHubAction } from './'
import { answerGetAction, answerSaveAction } from './answerActions'
import { saveAnswer } from './answerThunks'
import { httpSaveBeginAction, httpSaveEndAction } from './httpActions'
import { runEngagementLevelRules, runQuestionRules } from './rulesThunks'
import { updateEngagementQuestionStateSummaryAction } from './engagementActions'
import { REACT_APP_ANSWERSAVEDELAY } from '../envVariables'

/** Set of flags that changed for a related question. */
interface FlagsChanged {
  [flagType: string]: boolean | undefined
}

/** Contains change details for a single question. */
interface QuestionChanges {
  flagsChanged: FlagsChanged
  valueChanged: boolean
  runRules: boolean
}

/** Dictionary of question ID and change information about that question. */
interface QuestionsChanges {
  [questionId: number]: QuestionChanges | undefined
}

/** Contains change details for a specific question. */
interface EngagementChanges {
  questionId: number
  questionsChanges: QuestionsChanges
}

/** A set of engagements that have changed, and details about those engagements. */
interface EngagementsChanges {
  [engagementId: number]: EngagementChanges | undefined
}

export const flagUpdateLastModifiedQuestion: {
  [flagType: string]: boolean | undefined
} = {
  Flag: true,
  Active: false,
  NotApplicable: true,
  ClientPreparer: true,
  SecondaryReviewer: true,
  ConcurringReviewer: true,
  PrimaryReviewer: true,
  Preparer: true,
}

/** Contains functions that accept a question and a current value, for each flag type, that return a boolean value indicating
 *   whether or not that flag has changed on the question.  It's assumed that, if many of the flags are
 *   being checked, that they are changed by default - thus returning true without additional logic. */
export const flagLookup: {
  [flagType: string]: (
    question: EngagementQuestion,
    currentValue: boolean
  ) => boolean | undefined
} = {
  Flag: (question: EngagementQuestion, currentValue: boolean) => true,
  Active: (question: EngagementQuestion, currentValue: boolean) =>
    question.active !== currentValue,
  NotApplicable: (question: EngagementQuestion, currentValue: boolean) => true,
  ClientPreparer: (question: EngagementQuestion, currentValue: boolean) => true,
  SecondaryReviewer: (question: EngagementQuestion, currentValue: boolean) =>
    true,
  ConcurringReviewer: (question: EngagementQuestion, currentValue: boolean) =>
    true,
  PrimaryReviewer: (question: EngagementQuestion, currentValue: boolean) =>
    true,
  Preparer: (question: EngagementQuestion, currentValue: boolean) => true,
}

/** Contains the length of time that the save function should delay before attempting to retry. */
const delay = ensureNumber(REACT_APP_ANSWERSAVEDELAY) || 10

/** Contains all changes that are currently pending.  This is a global variable. */
let pending: EngagementsChanges = {}

/** Contains the ID of the timeout timer, used to retry saving questions after
 *   a current save is complete. */
let timeoutId: number | undefined

/** Global flag indicating whether or not a save is currently in progress. */
let saving = false

/** Promise.resolve function type. */
type EmptyCallback = () => void

/** Contains a set of Promise.resolve functions to be called when the current
 *   save operation is completed. */
let callbacks: EmptyCallback[] = []

export function getQuestion(
  engagementId: number,
  questionId: number
): QuestionChanges {

  // Get the engagement from the pending engagement questions set, by its ID.
  let engagement = pending[engagementId]

  // If there's no pending engagement, then set up a blank value for it.
  if (!engagement) {
    engagement = pending[engagementId] = {
      questionsChanges: {},
      questionId,
    }
  } else {
    // Set the ID of the question to be updated on this update object
    //  to the one passed to the function.
    engagement.questionId = questionId
  }

  // Get the changes for the desired question.
  let question = engagement.questionsChanges[questionId]
  // If there are none, create a new record for it.
  if (!question) {
    question = engagement.questionsChanges[questionId] = {
      flagsChanged: {},
      valueChanged: false,
      runRules: false,
    }
  }

  // Return this question data.
  return question
}

/**  Clears the values of callbacks and pending (global variables), then returns them in a single object. */
export function clearHelper(): {
  local: EngagementsChanges
  notifications: EmptyCallback[]
} {
  // Create the return value, using the global 'pending' and 'callbacks' variables.
  const result = {
    local: pending,
    notifications: callbacks,
  }

  // Clear the global variables.
  callbacks = []
  pending = {}

  // Return the result.
  return result
}

/** Sets the value of a specified flag on a specified question, to a newly specified value. */
export function registerFlagChange(
  engagementId: number,
  questionId: number,
  flagType: string,
  value: boolean
) {
  const question = getQuestion(engagementId, questionId)
  question.flagsChanged[flagType] = value
}

/** Sets a specified question, on a specified engagement, as changed. */
export function registerValueChange(
  engagementId: number,
  questionId: number,
  runRules: boolean
) {
  const question = getQuestion(engagementId, questionId)
  question.valueChanged = true
  question.runRules = runRules
}

/** Calls the performMetaUpdate method on a timeout, and returns a promise that resolves when it's finished. */
export function updateServerAnswer(
  dispatch: ThunkDispatch<AppState, undefined, TsaHubAction>,
  getState: () => AppState
): Promise<void> {
  return new Promise(resolve => {
    window.clearTimeout(timeoutId)
    callbacks.push(resolve as () => void)
    timeoutId = window.setTimeout(
      performMetadataUpdate,
      delay,
      dispatch,
      getState
    )
  })
}

async function performMetadataUpdate(
  dispatch: ThunkDispatch<AppState, undefined, TsaHubAction>,
  getState: () => AppState
) {
  // If we're already saving, then just requeue the save operation,
  //  and wait for this one to finish.  We need to do this, since the
  //  user can make changes after this process starts, and they won't be included.
  if (saving) {
    timeoutId = window.setTimeout(
      performMetadataUpdate,
      delay,
      dispatch,
      getState
    )
    return
  }

  // Create an ID for this operation.
  const id = Math.random().toString()
  // Flag to indicate that we're currently in the save process, and prevent reentry into the function.
  saving = true

  // This will grab the pending changes/notifications, and clear out the buffer
  //  so we don't reprocess them later.
  const { local, notifications } = clearHelper()

  // Get the state so we can process it.
  const state = getState()

  // We can only do this if there's a valid user logged in.
  if (state.auth.user) {
    // Process everything in local.
    for (const engId in local) {
      const engagementId = ensureNumber(engId)
      const engagement = local[engagementId]
      const storeEngagement = state.engagements[engagementId]
      const engagementQuestions = state.engagementQuestions[engagementId] || {}
      if (!engagement || !storeEngagement) {
        continue
      }
      const latestUpdatedEQ = state.questions[engagement.questionId]
      if (!latestUpdatedEQ) {
        continue
      }
      const flagUpdates: UpdateFlag[] = []
      const answerUpdates: IEngagementQuestionAnswer[] = []
      let runAllEngagementRules = false
      for (const qId in engagement.questionsChanges) {
        const question = engagement.questionsChanges[qId]
        const eqQuestion = engagementQuestions[qId]
        if (!question || !eqQuestion) {
          continue
        }
        for (const flag in question.flagsChanged) {
          const flagValue = question.flagsChanged[flag]
          const changed = flagLookup[flag]
          if (
            flagValue === undefined ||
            changed === undefined ||
            !changed(eqQuestion, flagValue)
          ) {
            // either trying to save an undefined flag, there was no check for this flag type, or it hasn't changed
            continue
          }
          flagUpdates.push({
            engagementId,
            questionId: ensureNumber(qId),
            flagType: flag,
            flagValue,
          })
        }
        if (question.valueChanged) {
          answerUpdates.push(eqQuestion)
          dispatch(
            answerSaveAction.request({ engagementId, answer: eqQuestion })
          )
        }

        // if any of the questions want all the rules to run, run all the rules
        runAllEngagementRules = runAllEngagementRules || question.runRules
      }

      if (runAllEngagementRules) {
        // Run engagement level rules. A phase-change rule will update the question summary state.
        await dispatch(runEngagementLevelRules(engagementId))
      }

      if (flagUpdates.length > 0 || answerUpdates.length > 0) {
        // Add this ID to the list of IDs currently being saved.
        dispatch(httpSaveBeginAction({ id }))
        const {
          mostRecentMetadataVersion,
          mostRecentVersion,
        } = findLatestVersion(engagementQuestions)
        try {
          // Make the actual API call to save the answer.
          const metadata = await AnswersApi.apiSaveAnswer(
            latestUpdatedEQ,
            answerUpdates,
            flagUpdates,
            storeEngagement,
            state.auth.user,
            mostRecentVersion,
            mostRecentMetadataVersion
          )
          const clientResponseAnswer = convertAnswer(
            state.engagementQuestions,
            metadata,
            local
          )
          // We need to update all of these properties for the current engagement.
          // for the dashboard view of the engagment to render properly.
          // map IdentityToken info  to UserProfile.
          const engagementSummary: any = {
            lastUpdatedBy: {
              isInternal: !state.auth.user.isExternal,
              firstName: state.auth.user.firstName,
              lastName: state.auth.user.lastName,
              email: state.auth.user.email,
            },
            lastUpdatedDate: moment(new Date()),
          }
          await dispatch(
            updateEngagementQuestionStateSummaryAction.success({
              engagementId,
              engagementSummary,
            })
          )
          await dispatch(
            answerSaveAction.success({
              engagementId,
              answer: clientResponseAnswer,
            })
          )
          await runRulesOnNewAnswers(
            engagementId,
            answerUpdates,
            mostRecentVersion,
            clientResponseAnswer,
            dispatch
          )
        } catch (error) {
          if (error.status === 409 && error.body && error.body !== '') {
            const apiAnswer: CurrentAnswer[] = JSON.parse(error.body)
            error.body = undefined
            const successAnswers = convertAnswer(
              state.engagementQuestions,
              apiAnswer.filter(a => !a.conflictError && !a.generalError),
              local
            )
            const conflictAnswers = convertAnswer(
              state.engagementQuestions,
              apiAnswer.filter(a => a.conflictError),
              local
            )
            await dispatch(
              answerSaveAction.success({ engagementId, answer: successAnswers })
            )
            await runRulesOnNewAnswers(
              engagementId,
              answerUpdates,
              mostRecentVersion,
              successAnswers,
              dispatch
            )
            let errorId = 0
            const errors = []
            for (const conflictAnswer of conflictAnswers) {
              const answer = engagementQuestions[conflictAnswer.questionId]

              if (!answer) {
                // we didn't save this answer so it isn't a real conflict
                continue
              }

              const conflictError = { ...error }
              conflictError.body = JSON.stringify(conflictAnswer)
              conflictError.id = `conflict-error${++errorId}`
              conflictError.onOk = ((ei, cra, d) => async () => {
                const answers = [cra]
                d(
                  answerSaveAction.success({
                    engagementId: ei,
                    answer: answers,
                  })
                )
                // re-run the rules
                await runRulesOnNewAnswers(
                  engagementId,
                  [
                    /* forcing the rules to run for this one */
                  ],
                  mostRecentVersion,
                  answers,
                  dispatch
                )
              })(engagementId, conflictAnswer, dispatch)

              conflictError.onCancel = ((ei, cra, a, d) => async () => {
                const newSaveAnswer = { ...a }
                if (
                  cra.engagementId === a.engagementId &&
                  cra.questionId === a.questionId
                ) {
                  newSaveAnswer.answerVersion = cra.answerVersion
                }
                await d(
                  answerGetAction.success({
                    answer: newSaveAnswer,
                    engagementId: ei,
                    questionId: newSaveAnswer.questionId,
                  })
                )
                await d(saveAnswer(ei, newSaveAnswer.questionId))
                // no need to re-run the rules since we are taking our answer
              })(engagementId, conflictAnswer, answer, dispatch)

              errors.push(conflictError)
            }
            error.status = 400
            errors.push(error)
            for (const err of errors) {
              await dispatch(answerSaveAction.failure(err))
            }
          } else {
            await dispatch(answerSaveAction.failure(error))
          }
        }
        await dispatch(httpSaveEndAction({ id }))
      }
    }
  }

  saving = false

  for (const callback of notifications) {
    callback()
  }
}

function findLatestVersion(
  questions: EngagementQuestionMap
): { mostRecentVersion: number; mostRecentMetadataVersion: number } {
  const result = {
    mostRecentMetadataVersion: 0,
    mostRecentVersion: 0,
  }
  for (const qid in questions) {
    const q = questions[qid]
    if (q && q.answerVersion > result.mostRecentVersion) {
      result.mostRecentVersion = q.answerVersion
    }
    if (
      q &&
      q.answerMetadataVersion &&
      q.answerMetadataVersion > result.mostRecentMetadataVersion
    ) {
      result.mostRecentMetadataVersion = q.answerMetadataVersion
    }
  }
  return result
}

function convertAnswer(
  engagementQuestions: EngagementQuestionsState,
  answers: CurrentAnswer[],
  changes: EngagementsChanges
): IEngagementQuestionAnswer[] {
  const result: IEngagementQuestionAnswer[] = []

  for (const answer of answers) {
    const clientAnswer = buildClientAnswer(answer)
    const engagements = engagementQuestions[answer.engagementId] || {}
    const currentAnswer = engagements[answer.questionId]

    const engagementChange = changes[answer.engagementId]
    const questionChange =
      engagementChange && engagementChange.questionsChanges[answer.questionId]
    if (currentAnswer && questionChange) {
      if (questionChange.valueChanged) {
        clientAnswer.clientVersion = currentAnswer.clientVersion
      }
      if (Object.keys(questionChange.flagsChanged).length > 0) {
        clientAnswer.clientFlagVersions = currentAnswer.clientFlagVersions
      }
    }
    result.push(clientAnswer)
  }

  return result
}

export function buildClientAnswer(
  answer: CurrentAnswer
): IEngagementQuestionAnswer {
  const buildAns: IEngagementQuestionAnswer = {
    answerId: answer.id,
    answerValue: answer.value,
    answerValueLastYear: null,
    answerVersion: Number.parseInt(answer.answerVersion),
    engagementId: answer.engagementId,
    answerMetadataId: answer.answersMetadataId,
    questionId: answer.questionId,
    reviewRolesComplete: getReviewsComplete(answer),
    clientVersion: 0,
    clientFlagVersions: {
      flagged: 0,
      notApplicable: 0,
      reviewRoles: {},
    },
    userId: answer.userId,
    user: answer.user,
  }

  if (answer.answersMetadata) {
    buildAns.active = answer.answersMetadata.active
    buildAns.flagged = answer.answersMetadata.flagged
    buildAns.notApplicable = answer.answersMetadata.notApplicable
    buildAns.answerMetadataVersion = Number.parseInt(
      answer.answersMetadata.answerMetadataVersion
    )
  }

  return buildAns
}

async function runRulesOnNewAnswers(
  engagementId: number,
  sentAnswers: IEngagementQuestionAnswer[],
  mostRecentVersion: number,
  newAnswers: IEngagementQuestionAnswer[],
  dispatch: ThunkDispatch<AppState, undefined, TsaHubAction>
) {
  let skip = false
  let atLeastOneChange = false
  for (const newAnswer of newAnswers) {
    for (const sentAnswer of sentAnswers) {
      if (
        sentAnswer.engagementId === newAnswer.engagementId &&
        sentAnswer.questionId === newAnswer.questionId
      ) {
        // sent this answer, do not need to re-run the rules
        skip = true
        break
      }
    }
    if (skip) {
      skip = false
      continue
    }
    if (newAnswer.answerVersion > mostRecentVersion) {
      // the answer version has changed on the server, re-run the rules to be in the most correct state
      await dispatch(
        runQuestionRules(newAnswer.engagementId, newAnswer.questionId)
      )
      atLeastOneChange = true
    }
  }

  if (atLeastOneChange) {
    await dispatch(runEngagementLevelRules(engagementId))
  }
}

import * as React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { Theme } from '@rsmus/react-ui'
import SmoothCollapse from 'react-smooth-collapse'

function styles({ palette }: Theme) {
  return {
    popup: {
      position: 'absolute',
      borderRadius: '0.75rem !important',
      border: `solid 1px ${palette.common.white}`,
      backgroundColor: palette.blue.main,
      textAlign: 'left',
      marginLeft: 'auto',
      padding: '0.8rem',
      paddingTop: '0.6rem',
      color: palette.common.white,
      zIndex: 5,
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface PopupOwnProperties {
  className?: string
  show: boolean
  horizontalAlign?: 'left' | 'right'
  verticalAlign?: 'top' | 'bottom'
}

interface PopupProperties extends PopupOwnProperties {}

const Popup = ({
  show,
  className,
  children,
  horizontalAlign,
  verticalAlign,
}: React.PropsWithChildren<PopupProperties>) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const alignment: any = {}

  if (horizontalAlign && horizontalAlign === 'right') {
    alignment.right = 0
  } else {
    alignment.left = 0
  }

  if (verticalAlign && verticalAlign === 'bottom') {
    alignment.bottom = '15px'
  } else {
    alignment.top = '18px'
  }

  return (
    <SmoothCollapse expanded={show}>
      <div className={`${classes.popup} ${className}`} style={alignment}>
        {children}
      </div>
    </SmoothCollapse>
  )
}

export default Popup

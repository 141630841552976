import classNames from 'classnames'
import * as React from 'react'

function columnClass(
  prefix: string,
  breakpoint?: Breakpoint,
  value?: boolean | string | number
) {
  let className
  if (value !== undefined) {
    className = prefix
    if (breakpoint) {
      className += `-${breakpoint}`
    }
    if (typeof value !== 'boolean') {
      className += `-${value}`
    }
  }
  return className
}

function widthClass(width?: ColumnWidth, breakpoint?: Breakpoint) {
  return columnClass('col', breakpoint, width)
}

function orderClass(order?: ColumnOrder, breakpoint?: Breakpoint) {
  return columnClass('order', breakpoint, order)
}

function offsetClass(offset?: ColumnOffset, breakpoint?: Breakpoint) {
  return columnClass('offset', breakpoint, offset)
}

function alignmentClass(align?: ColumnAlignment) {
  return align ? `align-self-${align}` : ''
}

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl'

export type ColumnWidth =
  | boolean
  | 'auto'
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12

type ColumnAlignment = 'start' | 'center' | 'end'

type ColumnOrder = 'first' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

type ColumnOffset = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

interface ColumnProps {
  className?: string
  xs?: ColumnWidth
  xsOrder?: ColumnOrder
  xsOffset?: ColumnOffset
  sm?: ColumnWidth
  smOrder?: ColumnOrder
  smOffset?: ColumnOffset
  md?: ColumnWidth
  mdOrder?: ColumnOrder
  mdOffset?: ColumnOffset
  lg?: ColumnWidth
  lgOrder?: ColumnOrder
  lgOffset?: ColumnOffset
  xl?: ColumnWidth
  xlOrder?: ColumnOrder
  xlOffset?: ColumnOffset
  align?: ColumnAlignment
  onClick?: () => void
}

const Column: React.SFC<ColumnProps> = props => {
  /* eslint-disable operator-linebreak */
  const {
    className,
    children,
    align,
    xs,
    xsOrder,
    xsOffset,
    sm,
    smOrder,
    smOffset,
    md,
    mdOrder,
    mdOffset,
    lg,
    lgOrder,
    lgOffset,
    xl,
    xlOrder,
    xlOffset,
    onClick,
  } = props

  const widths =
    xs || sm || md || lg || xl
      ? // If any width options are specified then use them
        [
          widthClass(xs),
          widthClass(sm, 'sm'),
          widthClass(md, 'md'),
          widthClass(lg, 'lg'),
          widthClass(xl, 'xl'),
        ]
      : // Else default to equal width columns using the 'col' class
        ['col']

  const offsets = [
    offsetClass(xsOffset),
    offsetClass(smOffset, 'sm'),
    offsetClass(mdOffset, 'md'),
    offsetClass(lgOffset, 'lg'),
    offsetClass(xlOffset, 'xl'),
  ]

  const order = [
    orderClass(xsOrder),
    orderClass(smOrder, 'sm'),
    orderClass(mdOrder, 'md'),
    orderClass(lgOrder, 'lg'),
    orderClass(xlOrder, 'xl'),
  ]

  const classes = widths
    .concat(offsets)
    .concat(order)
    .concat([alignmentClass(align), className])

  return (
    <div className={classNames(classes)} onClick={onClick}>
      {children}
    </div>
  )
}

Column.defaultProps = {}

export default Column

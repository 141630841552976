import * as React from 'react'

interface ProgressBarProps {
  percent: number
  label: string
}

const ProgressBar: React.SFC<ProgressBarProps> = ({ percent, label }) => {
  const width = `${percent}%`
  return (
    <div className='navbar-progress' title={width}>
      {label}
      <div className='progress'>
        <div
          className='progress-bar bg-success'
          role='progressbar'
          style={{ width }}
          aria-valuenow={percent}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </div>
  )
}

export default ProgressBar

import * as React from 'react'
import { EngagementsList } from '../../components/engagementsList'
import './dashboardContainer.scss'

function EngagementsListDashboard () {
  return (
    <div className='dashboard-container'>
      <EngagementsList />
    </div>
  )
}

export default EngagementsListDashboard

import * as React from 'react'
import { ClientLastModified } from '../../clientModels'
import moment from 'moment'

interface ClientLastModifiedMessageProps {
  clientsLastModified?: ClientLastModified[] | null
}

const ClientLastModifiedMessage = ({
  clientsLastModified,
}: ClientLastModifiedMessageProps) => {
  if (
    clientsLastModified === null ||
    clientsLastModified === undefined ||
    clientsLastModified?.length === 0
  ) {
    return <></>
  }

  const lastModifiedRecord = clientsLastModified
    .sort(
      (a, b) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    )
    .reverse()[0]

  const lastModifiedDate = moment(
    lastModifiedRecord?.createdDate,
    moment.ISO_8601
  )

  return (
    <>
      <div className='engagement-summary-info'>
        <div className='engagementInfo'>
          <div className='date summary-label'>
            LAST MODIFIED BY CLIENT:&nbsp;
          </div>
          <div> {lastModifiedDate.format('L')}</div>
        </div>
        <div className='engagementInfo'>
          <div className='modified-by'>
            <div>&nbsp;by</div>
            <div>&nbsp;{lastModifiedRecord.emailAddress}</div>
          </div>
        </div>
        <div className='engagementInfo'>
          <div className='lastQuestion summary-label'>
            LAST QUESTION MODIFIED:&nbsp;
          </div>
          <div>
            {lastModifiedRecord.sectionNumber}.
            {lastModifiedRecord.questionNumber}
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientLastModifiedMessage

import { History } from 'history'
import * as React from 'react'
import { match } from 'react-router'
import { RelativeLink } from '../relativeLink'
import Saving from '../saving/index'
import './carryForwardHeader.scss'

interface RouteParameters {
  engagementId: string
}

interface Properties {
  match: match<RouteParameters>
  history: History
}

function CarryForwardHeader({
  match: {
    params: { engagementId },
  },
}: Properties) {
  return (
    <div className='engagement-header'>
      <div className='carry-forward-header flex-wrap'>
        <div>
          <RelativeLink to={`engagements/${engagementId}/carryForward`}>
            Carry Forward
          </RelativeLink>
          <Saving showLastSaved={true} />
        </div>
      </div>
    </div>
  )
}

export default CarryForwardHeader

export function joinPaths (url: string, relativePath: string) {
  if (relativePath.startsWith('/')) {
    throw new Error(`Path '${relativePath}' is fully qualified.`)
  }

  const urlParts = url.split('/')
  const relativeParts = relativePath.split('/')
  const index = urlParts.indexOf(relativeParts[0])

  if (index === -1) {
    throw new Error(`Path '${relativePath}' not found in '${url}'.`)
  }

  return urlParts
    .splice(0, index)
    .concat(relativeParts)
    .join('/')
}

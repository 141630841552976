import classNames from 'classnames'
import * as React from 'react'
import { QuestionMap, SearchResult, SectionMap } from '../../clientModels'
import Icon from '../icon/icon'
import { Icons } from '../icon/icons'

interface SearchBoxResultProps {
  sections: SectionMap
  questions: QuestionMap
  result: SearchResult
  searchString: string
  selectedSearchResult?: SearchResult
  onSelectSearchResult: (result: SearchResult) => void
}

export class SearchBoxResult extends React.Component<SearchBoxResultProps> {
  // tslint:disable-next-line:no-any
  me: any
  setMe = (ref: HTMLDivElement) => (this.me = ref)

  handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button === 0) {
      const { onSelectSearchResult, result } = this.props
      onSelectSearchResult(result)
    }
  }

  componentDidUpdate() {
    const {
      props: { result, selectedSearchResult },
      me,
    } = this
    if (result === selectedSearchResult) {
      if (me.scrollIntoViewIfNeeded) {
        me.scrollIntoView(me, { block: 'nearest', scrollMode: 'if-needed' })
      } else {
        me.scrollIntoView()
      }
    }
  }

  createResultText(result: SearchResult) {
    const before = result.match[1] || ''
    const match = result.match[2] || ''
    const after = result.match[3] || ''
    return (
      <span>
        {before.length > 49 && '...'}
        {before}
        <span className='search-text'>{match}</span>
        {after}
        {after.length > 49 && '...'}
      </span>
    )
  }

  createBasicText(text: string) {
    return text.length > 100 ? text.substr(0, 100) + '...' : text
  }

  render() {
    const { questions, result, sections, selectedSearchResult } = this.props
    let text: string | React.ReactNode | undefined
    let num: string | undefined
    let icon: keyof Icons | undefined
    // If we add many more result types it would be cleaner
    // to create individual components for each result type
    // and just have the components share the createResultText
    // and createBasicText utility functions.
    switch (result.type) {
      case 'section': {
        const section = sections[result.id]
        if (section) {
          text =
            result.property === 'number'
              ? this.createBasicText(section.title)
              : this.createResultText(result)
          num = section.number
        }
        break
      }
      case 'question': {
        const question = questions[result.id]
        if (question) {
          num = question.displayNumber
          text =
            result.property === 'displayNumber'
              ? this.createBasicText(question.text)
              : this.createResultText(result)
          if (result.property === 'help') {
            icon = 'questionCircle'
          }
        }
        break
      }
      default:
        throw new Error('Unknown search result type')
    }
    const selected = result === selectedSearchResult
    if (!text) {
      return null
    }
    return (
      <div
        id={`search-box-results-${num}`}
        className={classNames('search-box-result', { selected })}
        onMouseDown={this.handleClick}
      >
        <div ref={this.setMe}>{icon && <Icon icon={icon} />}</div>
        <span id={`search-result-${num}`}>
          {num && <span className='number'>{num}</span>} {text}
        </span>
      </div>
    )
  }
}

import classNames from 'classnames'
import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import { Question } from '../../clientModels'
import { Anchor } from '../anchor'
import Icon from '../icon/icon'
import './help.scss'

interface HelpProps {
  question?: Question
  readMore: boolean
  onToggleReadMore?: () => void
}

class Help extends React.Component<HelpProps> {
  render() {
    const { onToggleReadMore, question, readMore } = this.props

    if (!question || !question.help) {
      return null
    }

    const helpClassName = classNames('question-help', { 'read-more': readMore })

    return (
      <div className={helpClassName}>
        <Icon icon='questionCircle' />
        <div>
          <ReactMarkdown
            className='question-help-text'
            children={question.help}
          />
          {onToggleReadMore && (
            <Anchor onClick={onToggleReadMore}>
              {readMore ? '- read less' : '+ read all'}
            </Anchor>
          )}
        </div>
      </div>
    )
  }
}

export default Help

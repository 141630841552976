import * as React from 'react'
import { connect } from 'react-redux'
import { errorClearAction } from '../../actions/errorActions'
import { ErrorData } from '../../reducers/appErrorReducer'
import { ExtError } from '../../services/error'
import { CurrentAnswer } from '../../services/api/apiModels'
import { AppState, TsaDispatch } from '../../store'
import {
  AppErrorDialog,
  DefaultErrorMessageLine1,
  DefaultErrorMessageLine2,
} from '../modals'
import { AppErrorDialogProps } from '../modals/appErrorDialog'
import { logErrors } from './logErrors'
import { StringLookup } from '../../clientModels'
import { REACT_APP_GLOBALUNAUTHORIZEDREDIRECTURL } from '../../envVariables'

interface AppErrorMappedProps {
  errors: ErrorData[]
}

interface AppErrorDispatchProps {
  errorClear: () => void
}

interface MessageGenButton {
  label: string | React.ReactNode
  onClick: (clearErrors: () => void, errors: ErrorData[]) => () => void
  autoFocus?: boolean
}

interface MessageGen {
  title?: string | ((errors: ErrorData[]) => string)
  message1: string | ((errors: ErrorData[]) => string)
  message2: string | ((errors: ErrorData[]) => string)
  context: string | ((errors: ErrorData[]) => string)
  buttons: MessageGenButton[]
}

export type AppErrorProps = AppErrorDispatchProps & AppErrorMappedProps

const mapStateToProps = (state: AppState): AppErrorMappedProps => ({
  errors: state.appErrorData,
})

const mapDispatchToProps = (dispatch: TsaDispatch): AppErrorDispatchProps => ({
  errorClear: () => {
    dispatch(errorClearAction({}))
  },
})

// For now we will just have messages hard coded here.
// in the future we may want these table driven.
// some actions we do not want to have any extended context message for
// based on email from Dave Z.
const contextMessages: StringLookup = {
  // default: 'A general application error'
  // GET_ANSWER_FAIL: 'The request to read an answer failed.',
  // GET_PHASE_FAIL: 'The request  to read the current phase failed.',
  // GET_PHASE_LIST_FAIL: 'The request to read the list of phases failed.',
  // GET_TASKS_FAIL: 'The request to read  the tasks failed.',
  // GET_TEMPLATE_FAIL: 'The request to read a template failed.',
  // GET_TEMPLATES_FAIL: 'The request to read templates failed.',
  ADD_FILE_FAIL: 'The request to add file(s) failed',
  ADD_UPDATE_FILE_GROUP_FAIL:
    'The request to add or update file(s) failed. Please check your data',
  ADD_UPDATE_FILE_GROUP_TEMPLATE_FAIL:
    'Please do a File > Save As for the file being uploaded. If you attempt to drag the file from an unsaved location an error will occur. Please also ensure the macros have been enabled in Excel before reloading the template, and that the template includes valid data/information.',
  AUTH_GET_USER_FAIL:
    'The last request to refresh your user information failed',
  DELETE_ACTIVITY_FAIL: 'The last request  to delete an activity failed',
  DELETE_FILE_GROUP_FAIL: 'The request to delete file(s) failed',
  DOWNLOAD_FILE_FAIL: 'The last request to download a file failed',
  DOWNLOAD_TEMPLATE_FAIL: 'The request to download a template failed',
  GET_ACTIVITIES_FAIL:
    'The last request to read the engagement activities failed',
  GET_ALL_ENTITIES_ERROR: 'The request to read the client group(s) failed',
  GET_CLIENT_LIST_FAIL: 'The request to read the organization(s) failed',
  GET_DOCUMENT_TITLES_FAIL:
    'The last request to read the document titles failed',
  GET_ENGAGEMENT_FAIL: 'The request to read an engagement failed',
  GET_ENGAGEMENT_LIST_FAIL:
    'The request to read the list  of engagements failed',
  GET_FILE_TEMPLATES_FAIL: 'The request to read file templates failed',
  GET_FILES_FAIL: 'The request to read a file or files failed',
  GET_LAST_YEARS_DATA_FAIL:
    'The request to retrieve last years tax return failed.',
  REQUEST_ENGAGEMENT_REPORT_FAIL:
    'The request for the engagement report failed.',
  SAVE_ACTIVITY_FAIL: 'The last request to save an activity failed',
  SAVE_ANSWER_FAIL: 'The last request to save an answer failed',
  SAVE_COMMENT_FAIL: 'The last request to save a comment failed',
  SUBMIT_ENGAGEMENT_FAIL:
    'The last request to submit the engagement to CCH Axcess failed',
  UPDATE_ENGAGEMENT_PHASE_FAIL:
    'The last request to update your engagement status failed',
  UPLOAD_ENGAGEMENT_SETUP_FAIL:
    'Engagement setup file processing failed. Please check your data',
  START_LOAD_ANSWERS_FAIL: 'Could not retrieve organizer data.',
}

const contextOnlyMessages: { [key: string]: number } = {
  ADD_UPDATE_FILE_GROUP_TEMPLATE_FAIL: 1,
}

function getMessageContext(errors: ErrorData[]): string {
  let message: string = ''
  if (errors.length > 0) {
    errors.forEach((value: ErrorData, index: number) => {
      // only add a comma if we are going to add a new message.
      if (
        message !== '' &&
        value.actionType &&
        contextMessages[value.actionType]
      ) {
        message += ', '
      }
      // only add a message if there is a mapped message to add
      if (value.actionType && contextMessages[value.actionType]) {
        message += contextMessages[value.actionType]
      }
    })
  }
  return message
}

function ackDefault(clearErrors: () => void, errors: ErrorData[]) {
  return () => clearErrors()
}

function ack401(clearErrors: () => void, errors: ErrorData[]) {
  return () => {
    // run the default functionality
    ackDefault(clearErrors, errors)()
    // redirect to the rsm home page
    window.location.replace(
      (REACT_APP_GLOBALUNAUTHORIZEDREDIRECTURL) || ''
    )
  }
}

function ack404(clearErrors: () => void, errors: ErrorData[]) {
  return () => {
    // run the default functionality
    ackDefault(clearErrors, errors)()
    // redirect to the home page
    window.location.replace(window.location.origin)
  }
}

function ack409Accept(clearErrors: () => void, errors: ErrorData[]) {
  const ack = ackDefault(clearErrors, errors)
  let onClick: () => void = () => {}
  for (const error of errors) {
    const extError = error.error as ExtError
    if (extError.status && extError.status === 409) {
      if (extError.onOk) {
        onClick = extError.onOk
      }
      break
    }
  }
  return () => {
    onClick()

    ack()
  }
}

function ack409Cancel(clearErrors: () => void, errors: ErrorData[]) {
  const ack = ackDefault(clearErrors, errors)
  let onClick: () => void = () => {}
  for (const error of errors) {
    const extError = error.error as ExtError
    if (extError.status && extError.status === 409) {
      if (extError.onCancel) {
        onClick = extError.onCancel
      }
      break
    }
  }
  return () => {
    onClick()

    ack()
  }
}

function get409Message(errors: ErrorData[]): string {
  for (const error of errors) {
    const extError = error.error as ExtError
    if (
      extError.status &&
      extError.status === 409 &&
      extError.body &&
      extError.body !== ''
    ) {
      const client = JSON.parse(extError.body) as CurrentAnswer
      if (client.user && (client.user.firstName || client.user.lastName)) {
        return `This question has been changed by ${client.user.firstName} ${client.user.lastName} since you opened it.`
      }
      break
    }
  }
  return 'This question has been changed by someone else since you opened it.'
}

interface MessageLookup {
  [code: string]: any
  [code: number]: any
}

const messages: MessageLookup = {
  401: {
    context: 'Please contact your admin to have permissions updated.',
    message1: "You haven't been granted access for this section.",
    message2: '',
    title: 'Unauthorized Access',
    buttons: [
      {
        label: 'Close',
        onClick: ack401,
        autoFocus: true,
      },
    ],
  },
  404: {
    context: getMessageContext,
    message1: 'The page you were looking for was not found.',
    message2: '',
    buttons: [
      {
        label: 'Close',
        onClick: ack404,
        autoFocus: true,
      },
    ],
  },
  409: {
    context: '',
    message1: '',
    message2: get409Message,
    onOk: 'Accept Remote Changes',
    title: 'Saving Error',
    buttons: [
      {
        label: (
          <span>
            Keep My Updates &amp;
            <br />
            Overwrite Their Version
          </span>
        ),
        onClick: ack409Cancel,
        autoFocus: true,
      },
      {
        label: (
          <span>
            Cancel My Updates &amp;
            <br />
            Keep Their Version
          </span>
        ),
        onClick: ack409Accept,
      },
    ],
  },
  default: {
    context: getMessageContext,
    message1: DefaultErrorMessageLine1,
    message2: DefaultErrorMessageLine2,
    buttons: [
      {
        label: 'Close',
        onClick: ackDefault,
        autoFocus: true,
      },
    ],
  },
}

export function getMessage(
  errors: ErrorData[],
  clearErrors: () => void
): AppErrorDialogProps {
  let messageGen: MessageGen | undefined

  if (errors.length > 0) {
    for (const errorData of errors) {
      const extError: ExtError = errorData.error as ExtError
      // Look to see if the status has special messaging
      if (extError.status) {
        messageGen = messages[extError.status]
      }
      // Found a special message, so use it, no need to continue looking through errors
      if (messageGen) {
        break
      }
    }
  }

  const message = messageGen || messages.default

  const result: AppErrorDialogProps = {
    context:
      typeof message.context === 'string'
        ? message.context
        : message.context(errors),
    title: !message.title
      ? undefined
      : typeof message.title === 'string'
      ? message.title
      : message.title(errors),
    buttons: [],
  }

  const showContextOnly = errors.every(
    (x) => !!x.actionType && !!contextOnlyMessages[x.actionType]
  )
  if (!showContextOnly) {
    result.message1 =
      typeof message.message1 === 'string'
        ? message.message1
        : message.message1(errors)
    result.message2 =
      typeof message.message2 === 'string'
        ? message.message2
        : message.message2(errors)
  }

  for (const button of message.buttons) {
    result.buttons.push({
      label: button.label,
      autoFocus: button.autoFocus,
      onClick: button.onClick(clearErrors, errors),
    })
  }

  return result
}

export class AppError extends React.Component<AppErrorProps> {
  render() {
    const { errors, errorClear } = this.props

    if (errors && errors.length > 0) {
      logErrors(errors)
      const message = getMessage(errors, errorClear)

      return (
        <div>
          <AppErrorDialog {...message} />
        </div>
      )
    } else {
      return null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppError)

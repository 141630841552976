import { TsaThunkAction } from '../store'
import { ClientSearch, StringMatch } from '../clientModels'
import * as actions from './clientSearchActions'
import { ClientSearchApi } from '../services/api'

export const searchClients = (
  searchString: string
): TsaThunkAction => async dispatch => {
  if (searchString.length < 3 && !isNumberSearch(searchString)) {
    dispatch(actions.clientSearchClearResultsAction({ searchString }))
    return
  }

  try {
    const tempResults = await ClientSearchApi.clientSearch(searchString)

    const results: ClientSearch[] = []

    for (const result of tempResults) {
      const numberedSearchString = parseInt(searchString)
      const convertedResult: ClientSearch = {
        clientId: result.clientId,
        masterId: result.masterId,
        name: result.name,
        matchClient: result.clientId === numberedSearchString,
        matchMaster: result.masterId === numberedSearchString,
        nameMatch: findMatches(result.name, searchString),
      }
      results.push(convertedResult)
    }

    dispatch(actions.clientSearchAction({ searchString, results }))
  } catch (error) {
    if (error.message !== 'AbortError') {
      dispatch(actions.clientSearchFailAction({ error }))
    }
  }
}

function isNumberSearch(searchString: string) {
  let c = searchString.charAt(0)
  if (c === '.') {
    c = searchString.charAt(1)
  }
  return !isNaN(parseInt(c, 10))
}

export function findMatches(name: string, searchString: string): StringMatch[] {
  const result: StringMatch[] = []
  const searchParts = searchString.toLowerCase().split(' ') // find each individual word
  const nameParts = name.split(' ')
  let part = ''

  for (const namePart of nameParts) {
    const namePartLC = namePart.toLowerCase()
    let longestMatch = 0

    for (const searchPart of searchParts) {
      if (
        searchPart.length > longestMatch &&
        namePartLC.startsWith(searchPart)
      ) {
        longestMatch = searchPart.length
      }
    }

    if (longestMatch > 0) {
      if (part !== '') {
        result.push({
          isMatch: false,
          value: part + ' ',
        })
      }
      result.push({
        isMatch: true,
        value: namePart.substring(0, longestMatch),
      })
      part = namePart.substring(longestMatch)

      if (!part.length) {
        part += ' '
      }
    } else {
      if (part.length && part !== ' ') {
        part += ' '
      }
      part += namePart
    }
  }

  if (part.length && part !== ' ') {
    result.push({
      isMatch: false,
      value: part,
    })
  }

  return result
}

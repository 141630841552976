import { Theme } from '@rsmus/react-ui'
import classNames from 'classnames'
import React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { Tab, TAB_WIDTH } from './NavTabs'
import { Anchor } from '../anchor'
import { matchPath } from 'react-router-dom'
import { useHistory } from 'react-router'

function styles({ palette, transitions, breakpoints }: Theme) {
  return {
    root: {
      display: 'inline-block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      transition: transitions.create('transform', 'shortest'),
    },
    tab: {
      display: 'inline-block',
      padding: '0.5rem 0.75rem',
      color: palette.grey[200],
      marginRight: 1,
      textAlign: 'center',
      width: TAB_WIDTH,
      cursor: 'pointer',
      userSelect: 'none',
      fontSize: '1rem',
      lineHeight: 1,
      transition: transitions.create(),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      outline: 'none',
      '&:last-child': {
        borderRightWidth: 1,
      },
      '&:hover': {
        textDecoration: 'none',
        fontWeight: 'bold',
      },
      '&.active': {
        boxShadow: `inset 0 -.25rem 0 ${palette.blue.light}`,
      },
      [breakpoints.md]: {
        fontSize: '0.875rem',
        lineHeight: '1rem',
      },
    },
    anchorButton: {
      outline: 'none !important',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface NavTabsTabListProps {
  tabs: Tab[]
  tabWidth: number
  offset?: number
  className?: string
  exact?: boolean
}

const NavTabsTabList = React.forwardRef<HTMLUListElement, NavTabsTabListProps>(
  ({ className, offset, tabs }, ref) => {
    const theme = useTheme()
    const classes = useStyles(theme)
    const history = useHistory()

    return (
      <nav
        className={classNames(classes.root, className)}
        style={{
          transform: `translate3d(${offset}px, 0, 0)`,
        }}
        ref={ref}
      >
        {tabs.map((t, i) => {
          const active = matchPath(window.location.pathname, {
            path: t.path,
          })
            ? 'active'
            : ''
          return (
            <Anchor
              className={classes.anchorButton}
              onClick={() => {
                history.push(t.path)
              }}
              tooltip={t.title}
              key={i}
            >
              <div className={classNames(active, classes.tab)}> {t.title}</div>
            </Anchor>
          )
        })}
      </nav>
    )
  }
)

NavTabsTabList.displayName = 'NavTabsTabList'

export default NavTabsTabList

import { TaxIntegrationStatus, Engagement } from '../clientModels'

export function setEngagementState(
  engagement: Engagement | undefined,
  newEngagement: Engagement | undefined
) {
  if (!newEngagement) {
    return engagement
  }
  if (engagement) {
    let taxIntegrationStatuses = setTaxIntegrationStatuses(
      engagement.taxIntegrationStatuses,
      newEngagement.taxIntegrationStatuses
    )
    // merge with the existing engagement
    engagement = {
      ...engagement,
      ...newEngagement,
      taxIntegrationStatuses: taxIntegrationStatuses,
    }
  } else {
    // use the new engagement
    engagement = newEngagement
  }
  return engagement
}

export function setTaxIntegrationStatuses(
  currentStatuses: TaxIntegrationStatus[] | undefined,
  newStatuses: TaxIntegrationStatus[] | undefined
) {
  if (currentStatuses && currentStatuses.length > 0) {
    if (newStatuses === undefined || newStatuses.length === 0) {
      return currentStatuses
    }
  }
  return newStatuses
}

import { ActionType, createAction } from 'typesafe-actions'

export const acknowledgeCriticalErrorAction = createAction(
  'ACK_CRITICAL_ERROR'
)<{ engagementId: number; questionId: number }>()

export const showAllQuestionsAction = createAction('SHOW_ALL_QUESTIONS')<{
  engagementId: string | number
}>()

export const showIncompleteOnlyAction = createAction('SHOW_INCOMPLETE_ONLY')<{
  engagementId: string | number
}>()

export type EngagementQuestionActions =
  | ActionType<typeof acknowledgeCriticalErrorAction>
  | ActionType<typeof showAllQuestionsAction>
  | ActionType<typeof showIncompleteOnlyAction>

import * as React from 'react'
import { Option } from '../../clientModels'
import { optionToObject } from '../forms/formUtilities'
import { CheckBoxes } from '../forms/index'
import './notApplicableCheckBox.scss'

interface NotApplicableCheckboxProps {
  checked?: boolean
  disabled?: boolean
  onChange: () => void
  mode: 'question' | 'file'
}

const notApplicableOptions: Option[] = [
  { value: 'N/A', label: 'Not applicable' },
]
const nothingToUploadOptions: Option[] = [
  { value: 'N/A', label: 'Nothing to upload' },
]

export class NotApplicableCheckBox extends React.Component<
  NotApplicableCheckboxProps
  > {
  static defaultProps = {
    mode: 'question',
  }

  handleOnChange = () => {
    const { onChange } = this.props
    onChange()
    return Promise.resolve()
  }

  render () {
    const { checked, disabled, mode } = this.props
    const options =
      mode === 'question' ? notApplicableOptions : nothingToUploadOptions

    const value = checked ? optionToObject(options[0]) : null
    return (
      <CheckBoxes
        className='na-checkbox'
        disabled={disabled}
        onChange={this.handleOnChange}
        options={options}
        value={value}
      />
    )
  }
}

import { TsaHubAction } from '../actions/index'
import { ClientSearch } from '../clientModels'
import * as clientSearchActions from '../actions/clientSearchActions'
import { getType } from 'typesafe-actions'

export interface ClientSearchState {
  searchString: string
  selectedSearchResult?: ClientSearch
  results?: ClientSearch[]
}

const initialState: ClientSearchState = {
  searchString: '',
}

export function clientSearchReducer (
  state: ClientSearchState = initialState,
  action: TsaHubAction
): ClientSearchState {
  switch (action.type) {
    default:
      return state

    case getType(clientSearchActions.clientSearchClearAction):
      return initialState

    case getType(clientSearchActions.clientSearchClearResultsAction):
      delete state.results
      delete state.selectedSearchResult
      return { ...state, searchString: action.payload.searchString }

    case getType(clientSearchActions.clientSearchSelectResultAction):
      return { ...state, ...action.payload }

    case getType(clientSearchActions.clientSearchAction):
      delete state.selectedSearchResult
      return { ...state, ...action.payload }
   
  }
}
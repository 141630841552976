// tslint:disable:jsx-no-lambda - debug components only
import { Select } from '@rsmus/react-ui'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import UserInfo from '../../components/userInfo/userInfo'
import { getApiVersion, setApiVersion } from '../../services/api'
import { AppState } from '../../store'
import './debug.scss'
import DebugUserPermissions from './debugUserPermissions'

interface Option {
  label: string
  value: string
}

const versions: Option[] = [
  { label: 'API Version Not Specified', value: '' },
  { label: 'API v1.0', value: '1.0' },
  { label: 'API v2.0', value: '2.0' },
]

function getVersionOption(ver: string) {
  return versions.find(v => v.value === ver)
}

const DebugScene = () => {
  const user = useSelector((state: AppState) => state.auth.user)
  const accessToken = useSelector((state: AppState) => state.auth.accessToken)
  const [version, setVersion] = useState(getVersionOption(getApiVersion()))

  const onChangeVersion = useCallback(
    (option: any) => {
      setVersion(option)
      if (option) {
        setApiVersion(option.value)
      }
    },
    [setVersion]
  )

  return (
    <form>
      <div id='debug' className='container mt-3'>
        <div className='row'>
          <div className='col'>
            <UserInfo user={user} accessToken={accessToken} />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <h1>Options</h1>
            <Select
              placeholder='API Version'
              value={version}
              options={versions}
              onChange={onChangeVersion}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <DebugUserPermissions />
          </div>
        </div>
      </div>
    </form>
  )
}

export default DebugScene

import { combineReducers } from 'redux'
import { createReducer, StateType } from 'typesafe-actions'
import { ClientFilterNode } from '../clientModels'
import { BannerFilterActions } from '../actions/bannerFilterActions'
import * as actions from '../actions/bannerFilterActions'

export type UserFiltersState = ClientFilterNode[]
export type SelectedFilterState = ClientFilterNode[] | null

const initialState = {
  filters: [] as UserFiltersState,
  selectedFilter: null as SelectedFilterState,
  isLoading: false
}

export const userFiltersReducer = createReducer<
  UserFiltersState,
  BannerFilterActions
>(initialState.filters)
  // Clear the existing filters.
  .handleAction(actions.updateBannerFilters.request, (state, action) => [])
  // When we fail or are done getting the filters, reflect the loading state
  .handleAction(
    actions.updateBannerFilters.success,
    (state, action) => action.payload
  )

export const selectedFilterReducer = createReducer<
  SelectedFilterState,
  BannerFilterActions
>(initialState.selectedFilter).handleAction(
  actions.selectBannerFilter,
  (state, action) => {
    // Whatever the value is, this is the new setting.
    return action.payload
  }
)

export const isLoadingReducer = createReducer<boolean, BannerFilterActions>(
  initialState.isLoading
)
  // When we start the update process from the server, then reflect that the filters are loading.
  .handleAction(actions.updateBannerFilters.request, (state, action) => true)
  // When we fail or are done getting the filters, reflect the loading state.
  .handleAction(
    [actions.updateBannerFilters.success, actions.updateBannerFilters.failure],
    (state, action) => false
  )

export const bannerFilterReducer = combineReducers({
  filters: userFiltersReducer,
  selectedFilter: selectedFilterReducer,
  isLoading: isLoadingReducer
})

export type BannerFilterState = StateType<typeof bannerFilterReducer>

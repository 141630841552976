import * as http from '../http'
import { buildApiUrl } from './apiUrl'
import * as ApiModels from './apiModels'
import { K1Header } from '../../clientModels'

export const deleteK1Headers = (k1HeaderId: number[]): Promise<any> => {
  return http.post(buildApiUrl(`K1/Delete`), k1HeaderId)
}

export const getK1sByEngagementId = async (
  engagementId: number
): Promise<K1Header[]> => {
  var k1s = await http.get(buildApiUrl(`K1/${engagementId}`))
  return k1s
}

export const getK1sByFileId = async (fileId: number): Promise<K1Header[]> => {
  var k1s = await http.get(buildApiUrl(`K1/headersbyfile/${fileId}`))
  return k1s
}

export const getById = async (k1Id: number): Promise<K1Header> => {
  var k1s = await http.get(buildApiUrl(`K1/getbyid/${k1Id}`))
  return k1s
}

export const submitFileToCch = async (
  fileId: number
): Promise<ApiModels.File> => {
  var files = await http.post(buildApiUrl('K1/SubmitBatchByFileId'), [fileId])
  return files[0]
}

export const submitK1ToCch = (
  engagementId: number,
  k1HeaderIds: number[],
): Promise<any> => {
  return http.post(buildApiUrl('K1/submitbatch'), {
    engagementId,
    k1HeaderIds,
  })
}

export const submitMultipleFilesToCch = (
  fileId: number[]
): Promise<ApiModels.File[]> => {
  return http.post(buildApiUrl('K1/SubmitBatchByFileId'), fileId)
}

export const getOcrFileOutput = (fileId: number): Promise<any> => {
  return http.get(buildApiUrl(`ocr/${fileId}/fileoutput`))
}

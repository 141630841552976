import { SignalRListener } from './middleware'
import { AppState } from '../store'
import { Dispatch } from 'redux'
import { getEngagement } from './../actions/engagementThunks'

const SignalRUpdateEtlToAnswerListener: SignalRListener = {
  message: 'UpdateEtlToAnswerCompleteCallback',
  type: 'on',
  callback: function(
    getState: () => AppState,
    dispatch: Dispatch<any>,
    engagementId: number
  ) {
    dispatch(getEngagement(engagementId))
  },
}
export const listeners: SignalRListener[] = [SignalRUpdateEtlToAnswerListener]

import classNames from 'classnames'
import * as React from 'react'
import { renderComplexAnswerLastYear } from '../../services/question/index'
import { Alerts } from '../forms/alerts/index'
import { OptionsFormFieldProps } from '../forms/formComponentsInterfaces'
import { hasError } from '../forms/formUtilities'
import { CheckBoxesField, TextBoxField } from '../forms/index'
import './textBoxCheckBox.scss'

interface TextCheckValue {
  text: string
  codes: string[]
}

// tslint:disable-next-line:no-any - value is not typed
export function isValueObjectOrUndefined (value: any): value is TextCheckValue {
  return typeof value === 'object' || typeof value === 'undefined'
}

class TextBoxCheckBox extends React.Component<OptionsFormFieldProps> {
  // tslint:disable-next-line:no-any - value is not typed
  static formatValueLastYear = (value: any): string => {
    return renderComplexAnswerLastYear(value.text, value.codes)
  }

  render () {
    const {
      disabled,
      codeListIdToOptionsId,
      hint,
      jsonSchema,
      messages,
      onBlur,
      onChange,
      onFocus,
      options,
      optionLists,
      selectedPath,
      showAlerts,
      value,
    } = this.props

    const fieldProps: OptionsFormFieldProps = {
      disabled,
      codeListIdToOptionsId,
      jsonSchema,
      messages,
      onBlur,
      onChange,
      onFocus,
      options,
      optionLists,
      selectedPath,
      showAlerts: false,
      value,
    }

    let textboxMaxLength: number | undefined
    if (jsonSchema && jsonSchema.properties && jsonSchema.properties.text) {
      textboxMaxLength = jsonSchema.properties.text.maxLength
    }

    const isInError = hasError(messages, 'codes')

    if (!isValueObjectOrUndefined(value)) {
      throw new Error('TextBoxCheckBox expects an object value.')
    }

    return (
      <div
        className={classNames('text-check form-group', {
          disabled,
          'is-valid': !isInError,
          'is-invalid': isInError,
        })}
      >
        {hint && <div className='hint-text'>{hint}</div>}
        <TextBoxField
          {...{
            ...fieldProps,
            className: 'textboxcheckbox-text',
            path: 'text',
          }}
          maxLength={textboxMaxLength}
        />
        <CheckBoxesField
          {...{
            ...fieldProps,
            className: 'textboxcheckbox-check',
            path: 'codes',
          }}
        />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

export default TextBoxCheckBox

import { ClientAccessRequest, ClientSearch } from '../clientModels'
import { createAction, ActionType as TypedAction } from 'typesafe-actions'

export const clientSearchAction = createAction('CLIENT_ACCESS_REQUEST_SEARCH')<{
  searchString: string
  results: ClientSearch[]
  clientAccessSearchStatus: string
}>()

export const clientSearchFailAction = createAction('CLIENT_ACCESS_REQUEST_SEARCH_FAIL')<{
  error: Error
}>()

export const clientSearchSubmitFailAction = createAction('CLIENT_ACCESS_REQUEST_SUBMIT_FAIL')<{
  error: Error
}>()

export const clientSearchClearAction = createAction('CLIENT_ACCESS_REQUEST_SEARCH_CLEAR')<{}>()

export const clientSearchClearResultsAction = createAction(
  'CLIENT_ACCESS_REQUEST_SEARCH_CLEAR_RESULTS'
)<{ searchString: string }>()

export const clientSearchSelectResultAction = createAction(
  'CLIENT_ACCESS_REQUEST_SEARCH_SELECT_RESULT'
)<{ selectedSearchResult: ClientSearch }>()

export const clientSearchSubmitResultAction = createAction(
  'CLIENT_ACCESS_REQUEST_SEARCH_SUBMIT_RESULT'
)<{ submitResult: ClientAccessRequest }>()

export type ClientAccessRequestSearchActions =
  | TypedAction<typeof clientSearchAction>
  | TypedAction<typeof clientSearchFailAction>
  | TypedAction<typeof clientSearchSubmitFailAction>
  | TypedAction<typeof clientSearchClearAction>
  | TypedAction<typeof clientSearchClearResultsAction>
  | TypedAction<typeof clientSearchSelectResultAction>
  | TypedAction<typeof clientSearchSubmitResultAction>
import { usePermissionManager } from '../../hooks'

interface InternalContentProps {
  children: JSX.Element | undefined | null | false
}

function InternalContent({ children }: InternalContentProps) {
  const { isExternalUser } = usePermissionManager()

  if (!children) {
    children = null
  }

  return isExternalUser ? null : (children as JSX.Element | null)
}

export default InternalContent

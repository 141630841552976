import classNames from 'classnames'
import { uniqueId } from 'lodash'
import * as React from 'react'
import { isStringOrNullOrUndefined } from '../../guards'
import { getTextFormat } from '../../services/formatters/index'
import { Alerts } from './alerts/index'
import { FocusableComponent, FormFieldProps } from './formComponentsInterfaces'
import './forms.scss'
import { by, charactersRemaining, hasError } from './formUtilities'

interface TextBoxProps extends FormFieldProps {
  className?: string
  maxLength?: number
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

interface TextBoxState {
  hasFocus: boolean
}

export default class TextBox extends React.Component<TextBoxProps, TextBoxState>
  implements FocusableComponent {
  static defaultProps = {
    showAlerts: true,
  }

  static formatValueLastYear = (value: string, props: TextBoxProps) => {
    const formatter = getTextFormat(props.jsonSchema)
    return formatter.format(value, { maskOutput: true })
  }

  state = {
    hasFocus: false,
  }

  inputId: string
  labelId: string
  hintId: string
  input?: HTMLInputElement

  constructor(props: TextBoxProps) {
    super(props)
    const id = uniqueId('textbox_')
    this.inputId = `${id}_input`
    this.labelId = `${id}_label`
    this.hintId = `${id}_hint`
  }

  componentDidMount() {
    const { selectedPath, path } = this.props

    if (selectedPath && path && path === selectedPath) {
      this.focus()
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: TextBoxProps) {
    if (
      nextProps.selectedQuestion &&
      nextProps.selectedPath &&
      nextProps.path === nextProps.selectedPath
    ) {
      this.focus()
    }
  }

  focus() {
    const input = this.input
    if (input) {
      input.focus()
    }
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { path, onChange, jsonSchema } = this.props
    if (onChange) {
      const formatter = getTextFormat(jsonSchema)
      onChange(formatter.parse(e.currentTarget.value), path)
    }
  }

  handleBlur = () => {
    const { path, onBlur } = this.props
    this.setState({ hasFocus: false }, () => {
      if (onBlur) {
        onBlur(path)
      }
    })
  }

  handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
    const { path, onFocus } = this.props
    this.setState({ hasFocus: true }, () => {
      if (onFocus) {
        onFocus(path)
      }
    })
  }

  handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { onKeyUp } = this.props

    if (onKeyUp) {
      onKeyUp(e)
    }
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { onKeyDown } = this.props

    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  setInput = (ref: HTMLInputElement) => (this.input = ref)

  render() {
    const { hasFocus } = this.state
    const {
      className,
      describedBy,
      disabled,
      hint,
      jsonSchema,
      label,
      labelledBy,
      maxLength,
      messages,
      path,
      placeholder,
      showAlerts,
      value,
      autoFocus,
    } = this.props

    if (!isStringOrNullOrUndefined(value)) {
      throw new Error('TextBox expects a string value.')
    }

    const ariaLabelledBy = by(labelledBy, label && this.labelId)
    const ariaDescribedBy = by(describedBy, hint && this.hintId)
    const isInError = hasError(messages, path)

    const formatter = getTextFormat(jsonSchema)
    const formattedValue = formatter.format(value, { maskOutput: !hasFocus })
    const statusText = charactersRemaining(formattedValue || '', maxLength)

    return (
      <div className={classNames(className, 'form-group', { disabled })}>
        {label && <label id={this.labelId}>{label}</label>}
        <div className='d-flex'>
          {hint && (
            <div id={this.hintId} className='hint-text'>
              {hint}
            </div>
          )}
          {statusText && <div className='status-text'>{statusText}</div>}
        </div>
        <input
          aria-describedby={ariaDescribedBy}
          aria-labelledby={ariaLabelledBy}
          className={classNames('form-control', {
            'is-invalid': isInError,
            'is-valid': !isInError,
          })}
          disabled={disabled}
          id={this.inputId}
          maxLength={maxLength}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          placeholder={placeholder}
          ref={this.setInput}
          type='text'
          value={formattedValue || ''}
          autoFocus={autoFocus}
        />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

import { ErrorData } from '../../reducers/appErrorReducer'
import { AppInsights } from '../../services/appInsights/index'
import { ExtError } from '../../services/error'

export function logErrors (errors: ErrorData[]): void {
  if (typeof AI === 'undefined') {
    return
  }
  if (errors && errors.length > 0) {
    errors.forEach((value: ErrorData, index: number) => {
      // convert to properties to pass to tractrace.
      const extError: ExtError = value.error as ExtError
      const properties = {
        actionType: value.actionType as string,
        message: extError.message,
        name: extError.name,
        stack: extError.stack ? extError.stack : '',
        status: extError.status + '',
      }
      AppInsights.trackTrace('appError', properties, 3)
    })
    AppInsights.flush() // flush the trace calls
  }
}

import { Engagement } from '../clientModels'
import { RoleCode } from '../enums'
import { ensureNumber } from '../guards'
import { selectAnswer } from '../reducers/selectors'
import { EngagementsApi } from '../services/api'
import {
  questionNeedsReview,
  reviewRolesForQuestion,
  reviewRolesForUser,
  updatedReviewStarted,
  updateReviewDone,
} from '../services/questionReviewUtilities'
import { trackSave } from '../services/track'
import { AppState, TsaThunkAction } from '../store'
import { getActivities } from './activityThunks'
import { updateMetadata } from './answerThunks'
import { getEngagement } from './engagementThunks'
import { normalizeEngagement } from './normalization'
import { reviewDoneAction, reviewQuestionAction } from './reviewActions'
import { runEngagementLevelRules, runQuestionRules } from './rulesThunks'

export const toggleTickMarks = (
  engagementId?: string | number,
  questionId?: string | number
): TsaThunkAction => async (dispatch, getState) => {
  engagementId = ensureNumber(engagementId)
  questionId = ensureNumber(questionId)
  if (engagementId === 0 || questionId === 0) {
    return
  }

  const state = getState()
  const engagement = state.engagements[engagementId]
  if (!engagement) {
    throw new Error('Engagement not loaded.')
  }

  const question = state.questions[questionId]
  if (!question) {
    throw new Error('Question not loaded.')
  }

  const user = state.auth.user
  if (!user) {
    throw new Error('User not loaded.')
  }

  const roles = reviewRolesForQuestion(engagement, question, user, true)
  if (roles.length === 0) {
    // User doesn't have any roles in this phase
    return
  }

  const answer = selectAnswer(state, engagementId, questionId)
  const needsReview = questionNeedsReview(engagement, question, answer, user)
  const action = needsReview ? 'mark' : 'clear'

  // Update the answer
  dispatch(
    reviewQuestionAction({
      engagementId,
      questionId,
      roles,
      action,
    })
  )

  await dispatch(runQuestionRules(engagementId, questionId))
  await dispatch(runEngagementLevelRules(engagementId))

  if (state.auth.user) {
    // tslint:disable-next-line:no-any
    const flags: any[] = []

    for (const role of roles) {
      flags.push(
        dispatch(updateMetadata(engagementId, questionId, role, needsReview))
      )
    }

    for (const flag of flags) {
      await flag
    }
  }

  const saveEngagement = updatedReviewStarted(engagement, roles)

  if (saveEngagement) {
    const updatedEngagement = getState().engagements[engagementId]
    if (updatedEngagement) {
      const engagements = await trackSave(
        EngagementsApi.apiSaveEngagement,
        dispatch,
        undefined,
        updatedEngagement
      )
      const engagementMap = normalizeEngagement(engagements).engagements
      dispatch(reviewDoneAction.success({ engagements: engagementMap }))
    }
  }

  await dispatch(getActivities(engagementId, questionId))
}

/**
 * Changing an answer constitutes client review. If the question requires client
 * review and client review has not been completed then do it now.
 */
export const answerUpdateClientReview = (
  engagementId: number,
  questionId: number
): TsaThunkAction => async (dispatch, getState) => {
  const state = getState()

  const engagement = state.engagements[engagementId]
  if (!engagement) {
    throw new Error('Engagement not loaded.')
  }

  const question = state.questions[questionId]
  if (!question) {
    throw new Error('Question not loaded.')
  }

  const user = state.auth.user
  if (!user) {
    throw new Error('User not loaded.')
  }

  const questionRequiresClientReview = question.reviewRolesRequired.includes(
    RoleCode.ClientPreparer
  )
  const userHasClientReviewRole = reviewRolesForUser(engagement, user).includes(
    RoleCode.ClientPreparer
  )
  const requiresClientReview =
    questionRequiresClientReview && userHasClientReviewRole

  if (!requiresClientReview) {
    return
  }

  const answer = selectAnswer(state, engagementId, questionId)
  if (!answer || !answer.reviewRolesComplete.has(RoleCode.ClientPreparer)) {
    dispatch(
      reviewQuestionAction({
        engagementId,
        questionId,
        roles: [RoleCode.ClientPreparer],
        action: 'mark',
      })
    )
    await dispatch(
      updateMetadata(engagementId, questionId, RoleCode.ClientPreparer, true)
    )
  }
}

export const markReviewDone = (
  engagementId: string | number,
  saveId?: string
): TsaThunkAction => async (dispatch, getState) => {
  try {
    const state = getState()
    const user = state.auth.user
    if (!user) {
      throw new Error('User not loaded.')
    }

    let engagement = selectEngagement(getState, engagementId)

    if (!engagement.loaded) {
      // If the engagement is not fully loaded we need load it now so we can update it and save a new version
      await dispatch(getEngagement(engagementId, saveId))
    }

    // Get the updated version of the engagement
    // Refactor this into multiple thunks. We should need to keep getting a new copy of the engagement.
    engagement = selectEngagement(getState, engagementId)

    const assignedRoles = reviewRolesForUser(engagement, user)
    if (assignedRoles.length === 0) {
      // User doesn't have any roles in this phase
      return
    }

    // Update review summary for user's assigned roles
    const reviewSummary = updateReviewDone(assignedRoles, true)

    // Update engagement in the store
    dispatch(reviewDoneAction.request({ engagementId, reviewSummary }))

    await dispatch(runEngagementLevelRules(ensureNumber(engagementId)))

    // Get the updated version of the engagement
    engagement = selectEngagement(getState, engagementId)

    const savedEngagement = await trackSave(
      EngagementsApi.apiSaveEngagement,
      dispatch,
      saveId,
      engagement
    )
    const engagementMap = normalizeEngagement(savedEngagement).engagements

    dispatch(reviewDoneAction.success({ engagements: engagementMap }))
    dispatch(getEngagement(engagementId))
  } catch (error) {
    dispatch(reviewDoneAction.failure(error))
  }
}

function selectEngagement(
  getState: () => AppState,
  engagementId: string | number
): Engagement {
  const engagement = getState().engagements[ensureNumber(engagementId)]
  if (!engagement) {
    throw new Error('Engagement not loaded.')
  }
  return engagement
}

import { TsaThunkAction } from '../store'
import * as actions from './bannerFilterActions'
import { getEngagementFilters } from '../services/api/engagementsApi'
import {
  isBannerFilterUrlCorrect,
  getBannerFilterInfoFromUrl,
  getFilterNodeTrees
} from '../services/filterHelpers'
import { ensureNumber } from '../guards'

// NOTE: Banner Filters are the dropdown at the top of the page (masterClientId>FormName>TaxYear), and in the URL.

/** Updates the filters list, in the store, from the UI.  This set of filters
 *   is unique to the current user, and their accessible clients/engagements. */
export const updateBannerFilters = (): TsaThunkAction => async (
  dispatch,
  getState
) => {
  // Indicate that we're beginning the API request.
  dispatch(actions.updateBannerFilters.request())

  try {
    // Get the filters from the server.
    const newFilters = await getEngagementFilters()

    // Set the new filters.
    dispatch(actions.updateBannerFilters.success(newFilters))
  } catch (err) {
    dispatch(actions.updateBannerFilters.failure(err))
  }
}

/** Checks the current URL to see that the filter is correct, based
 *   on the currently selected filter and engagement. */
export const fixSelectedFilter = (history: any): TsaThunkAction => async (
  dispatch,
  getState
) => {
  const state = getState()
  const filterState = isBannerFilterUrlCorrect(history.location.pathname, state)

  // Exit if it's already valid.  This accounts for any of the 'valid' state types.
  if (filterState.isValid) {
    return
  }

  // Get the filter information from the URL.
  const filterInfo = getBannerFilterInfoFromUrl(history.location.pathname)

  // Get the engagement if we have an engagementId.
  const engagement = filterInfo?.engagementId
    ? state.engagements[ensureNumber(filterInfo.engagementId)]
    : undefined

  if (filterState.state === 'engagement-mismatch-filter') {
    // Either the URL doesn't contain filter information, or it doesn't match
    //  the selected engagement.  We need to update the URL to have filter information
    //  that matches the selected engagement.

    // If we don't have an engagement, we can't do much here.  This would be strange, but
    //  needs to be accounted for anyway.
    if (!engagement) {
      return
    }

    // Get the master ID for this engagement.
    const masterId = state.clients?.[engagement.clientId]?.masterId

    // Create node trees for the filters.
    const nodeTrees = getFilterNodeTrees(state.bannerFilter.filters)

    // Find the correct filter.
    const filter = nodeTrees.find(
      t =>
        t.taxFormName === engagement.engagementTaxForm &&
        t.year === engagement.taxYear &&
        t.masterId === masterId
    )

    // If we found one, then use it as the new filter.
    if (filter && filterInfo?.filterPath) {
      let newFilterPath = filter.filterPath
      if (filterInfo.filterPath.endsWith('/')) {
        newFilterPath += '/'
      }

      let newPath = history.location.pathname.replace(
        filterInfo.filterPath,
        newFilterPath
      )
      if (!newPath.endsWith('/')) {
        newPath += '/'
      }
      history.push(newPath)

      return dispatch(actions.selectBannerFilter(filter.filterList))
    } else if (filter) {
      // We have a filter to change to, but no filter in the URL.  This
      //  means we need to just pre-pend the new filter to the existing path
      //  instead of replacing anything.
      history.push(filter.filterPath + history.location.pathname)

      return dispatch(actions.selectBannerFilter(filter.filterList))
    }

    // No new filter.  We can't change the URL to anything
    //  meaningful, so let's just leave it be.
    return
  } else if (filterState.state === 'invalid-filter') {
    // The filter in the URL does not match any of the filters we received from the server, and
    //  there is no client object that we can use to determine the right one.  We'll remove
    //  the filter from the URL.

    //  Clear the filter out.
    let newPath = history.location.pathname.replace(filterInfo!.filterPath, '')
    if (!newPath.startsWith('/')) {
      newPath = '/' + newPath
    }
    history.push(newPath)

    return dispatch(actions.selectBannerFilter(null))
  } else if (filterState.state === 'selection-mismatch') {
    // The filter in the UI doesn't match the filter in the URL.  We just have to
    //  select the right one in the UI.

    // Create node trees for the filters.
    const nodeTrees = getFilterNodeTrees(state.bannerFilter.filters)

    // Find a filter that matches this, in the filter list.
    const expectedSelection = nodeTrees.find(
      f =>
        f.masterId.toString() === filterInfo?.masterClientId.toString() &&
        f.taxFormName === filterInfo?.form &&
        f.year === filterInfo?.year
    )

    // Set the selection to this.
    return dispatch(
      actions.selectBannerFilter(expectedSelection?.filterList ?? null)
    )
  }

  throw new Error(`Unhandled filter state: ${filterState.state}`)
}

import { Almanac, Engine, FactOptions, Params } from 'json-rules-engine'
import { AnswerValue, QuestionStateSummary } from '../../clientModels'
import { hasError } from '../../components/forms/formUtilities'
import { isDefined } from '../../guards'
import {
  selectAnswer,
  getEngagementAnswers,
  getEngagementByEngagementId,
  getEngagementQuestions,
  getEngagementSections,
  getEngagementTemplatebyId,
  selectQuestionFileGroups,
  getQuestionByQuestionId,
} from '../../reducers/selectors'
import {
  questionIsAnswered,
  questionNeedsReview,
} from '../questionReviewUtilities'
import { getState } from './getStateProxy'

export enum Fact {
  Answer = 'answer',
  AnswerAsNumber = 'answerAsNumber',
  AnswerLastYear = 'answerLastYear',
  Client = 'client',
  ClientId = 'clientId',
  EngagementId = 'engagementId',
  EngagementSummary = 'engagementSummary',
  FileErrors = 'fileErrors',
  NotApplicable = 'notApplicable',
  Phase = 'phase',
  RequiredDocTitleIds = 'requiredDocTitleIds',
  Row = 'row',
  RowIndex = 'rowIndex',
  TotalFiles = 'totalFiles',
  TotalRows = 'totalRows',
  True = 'true',
  UploadedDocTitleIds = 'uploadedDocTitleIds',
}

/**
 * This option turns off caching of the fact value in the almanac. This is helpful
 * if we already have the value on the Redux store and that value can be retrieved
 * inexpensively.
 */
const doNotCache: FactOptions = { cache: false }

// Turn on rule debugging
// localStorage.debug = 'json-rules-engine'
export default function addFacts(engine: Engine) {
  engine.addFact(Fact.Answer, answerValueFact, doNotCache)
  // The row fact is an alias for "answer" that provides forward compatibility
  // with the server side rules engine.
  engine.addFact(Fact.Row, answerValueFact, doNotCache)
  engine.addFact(Fact.AnswerAsNumber, answerAsNumberFact)
  engine.addFact(Fact.AnswerLastYear, answerLastYearValueFact, doNotCache)
  engine.addFact(Fact.Client, clientFact, doNotCache)
  engine.addFact(Fact.EngagementSummary, engagementSummary)
  engine.addFact(Fact.FileErrors, fileErrorsFact)
  engine.addFact(Fact.NotApplicable, notApplicable, doNotCache)
  engine.addFact(Fact.Phase, phase, doNotCache)
  engine.addFact(Fact.RequiredDocTitleIds, requiredDocumentTitleIds)
  engine.addFact(Fact.TotalFiles, totalFilesFact)
  engine.addFact(Fact.True, trueFact)
  engine.addFact(Fact.UploadedDocTitleIds, uploadedDocumentTitleIds, doNotCache)
}

async function trueFact(params: Params, almanac: Almanac) {
  return true
}

async function totalFilesFact(params: Params, almanac: Almanac) {
  const fileGroups = selectQuestionFileGroups(
    getState().fileGroups,
    params.questionId
  )
  const fileList = fileGroups
    .map(item => item.files && item.files.length)
    .filter(isDefined)
  const fileCount = fileList.reduce(
    (prev: number, next: number) => prev + next,
    0
  )
  return fileCount
}

/**
 * Get the response to a question from the store.
 * Required Facts:
 *   engagementId
 * Required Params:
 *   questionId
 */
export async function answerValueFact(params: Params, almanac: Almanac) {
  const rowIndex = await almanac.factValue(Fact.RowIndex)
  const engagementId = await almanac.factValue(Fact.EngagementId)
  const answer = selectAnswer(getState(), engagementId, params.questionId)
  const answerValue: any = answer && answer.answerValue

  if (rowIndex > -1) {
    return (answerValue && answerValue[rowIndex]) || {}
  } else {
    return answerValue || null
  }
}

/**
 * Get the response from last year to a question from the store.
 * Required Facts:
 *   engagementId
 * Required Params:
 *   questionId
 */
async function answerLastYearValueFact(params: Params, almanac: Almanac) {
  const rowIndex = await almanac.factValue(Fact.RowIndex)
  const engagementId = await almanac.factValue(Fact.EngagementId)
  const answer = selectAnswer(getState(), engagementId, params.questionId)

  if (rowIndex > -1) {
    const lastYear: any = answer && answer.answerValueLastYear
    return (lastYear && lastYear[rowIndex]) || {}
  } else {
    return (answer && answer.answerValueLastYear) || null
  }
}

function sumAnswerValues(answer: AnswerValue): number {
  let sumValues = 0.0

  if (typeof answer === 'string' && !Number.isNaN(Number(answer))) {
    sumValues += Number(answer)
  } else if (Array.isArray(answer)) {
    for (const item of answer) {
      sumValues += sumAnswerValues(item)
    }
  } else if (answer && typeof answer === 'object') {
    for (const p in Object.keys(answer)) {
      const a: any = answer
      sumValues += sumAnswerValues(a[Object.keys(a)[p]])
    }
  }
  return sumValues
}

export async function answerAsNumberFact(params: Params, almanac: Almanac) {
  const engagementId = await almanac.factValue(Fact.EngagementId)
  const answer = selectAnswer(getState(), engagementId, params.questionId)
  const answerValue = answer && answer.answerValue
  return answerValue && sumAnswerValues(answerValue)
}

export async function notApplicable(params: Params, almanac: Almanac) {
  const engagementId = await almanac.factValue(Fact.EngagementId)
  const state = getState()
  const answer = selectAnswer(state, engagementId, params.questionId)
  const question = getQuestionByQuestionId(state, params.questionId)
  if (!question || !answer) {
    return false
  }
  return question.allowNotApplicable && !!answer.notApplicable
}

async function phase(params: Params, almanac: Almanac) {
  const engagementId = await almanac.factValue(Fact.EngagementId)
  const engagement = getEngagementByEngagementId(getState(), engagementId)

  return engagement && engagement.phase
}

/**
 * Get a client from the store.
 * Required Facts:
 *   clientId
 */
function clientFact(params: Params, almanac: Almanac) {
  return almanac
    .factValue(Fact.ClientId)
    .then((clientId: number) => getState().clients[clientId] || {})
}

/**
 * Get required document title IDs for a question.
 * Required params:
 *   questionId
 */
function requiredDocumentTitleIds(params: Params, almanac: Almanac) {
  return params.requiredDocumentTitleIds || []
}

async function fileErrorsFact(params: Params, almanac: Almanac) {
  const fileGroups = selectQuestionFileGroups(
    getState().fileGroups,
    params.questionId
  )
  return fileGroups.filter(
    x => x.files && x.files.some(y => y.status === 'Error')
  )
}

/**
 * Get the document title IDs that have been uploaded for the specified question.
 * Required Params:
 *   questionId
 */
async function uploadedDocumentTitleIds(params: Params, almanac: Almanac) {
  const fileGroups = selectQuestionFileGroups(
    getState().fileGroups,
    params.questionId
  )
  return fileGroups.filter(g => g.documentTitleId).map(g => g.documentTitleId)
}

export async function engagementSummary(
  params: Params,
  almanac: Almanac
): Promise<QuestionStateSummary> {
  const summary = {
    totalQuestions: 0,
    successfulQuestions: 0,
    successfulForUserQuestions: 0,
    totalVisibleQuestions: 0
  }

  const engagementId = await almanac.factValue(Fact.EngagementId)

  const state = getState()
  const engagement = getEngagementByEngagementId(state, engagementId)

  if (!engagement) {
    // could not find the right pieces, don't move to next phase
    return summary
  }

  const engagementTemplate = getEngagementTemplatebyId(
    state,
    engagement.engagementTemplateId
  )
  const engagementQuestions = getEngagementQuestions(state, engagementId)
  const answers = getEngagementAnswers(state, engagementId)
  const engagementSections = getEngagementSections(state, engagementId)
  const user = state.auth.user

  if (
    !engagementTemplate ||
    !engagementTemplate.questions ||
    !engagementQuestions ||
    !answers ||
    !engagementSections
  ) {
    // could not find the right pieces, don't move to next phase
    return summary
  }

  for (const questionId of engagementTemplate.questions) {
    const eq = engagementQuestions[questionId]

    if (eq) {
      if (!eq.isVisible) {
        continue
      }
      const es = engagementSections[eq.sectionId]
      if (es && !es.isVisible) {
        // section isn't visible, so question isn't applicable
        continue
      }
    }

    const ans = answers[questionId]
    const question = getQuestionByQuestionId(state, questionId)
    const messages = (eq && eq.messages) || []
    const isInError = hasError(messages)
    const isAnswered = questionIsAnswered(question, ans)

    const needsReviewByAnyReviewer = questionNeedsReview(
      engagement,
      question,
      ans
    )
    const needsReviewByCurrentUser = questionNeedsReview(
      engagement,
      question,
      ans,
      user
    )

    if (!isInError && !isAnswered) {
      // This question must not have any required fields so we don't count it in the total questions
      continue
    }

    if (question?.isVisible) {
      ++summary.totalQuestions
      ++summary.totalVisibleQuestions
    }

    if (isInError) {
      continue
    }

    if (question?.isVisible) {
      if (!needsReviewByAnyReviewer) {
        ++summary.successfulQuestions
      }
      if (!needsReviewByCurrentUser) {
        ++summary.successfulForUserQuestions
      }
    }
  }

  almanac.addRuntimeFact(Fact.EngagementSummary, summary)

  return summary
}

import { TsaHubAction } from "../../actions"
import { getType, createAction, ActionType } from "typesafe-actions"

export interface CallToActionMessage {
  type: string,
  messageText: string,
  actionText: string,
  callback: () => void
}

// Reducer
export default function callToActionReducer(state: CallToActionMessage[] = [], action: TsaHubAction): CallToActionMessage[] {
  let newState = [...state]
  switch (action.type) {
    case getType(addCallToActionMessageAction):
      newState.unshift(action.payload.message)
      return newState
    case getType(popCalltoActionMessageAction):
      newState.pop()
      return newState
    default:
      return state
  }
}

// Action Creators
export const addCallToActionMessageAction = createAction('ADD_CALL_TO_ACTION_MESSAGE')<{ message: CallToActionMessage }>()
export const popCalltoActionMessageAction = createAction('POP_CALL_TO_ACTION_MESSAGE')<undefined>()

export type CallToActionActions =
  | ActionType<typeof addCallToActionMessageAction>
  | ActionType<typeof popCalltoActionMessageAction>

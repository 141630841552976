import { EngagementsApi } from '../services/api'
import { trackGet } from '../services/track'
import { TsaThunkAction } from '../store'
import { clientAction } from './clientActions'
import { normalizeEngagementArray } from './normalization'

export const getClientList = (
  id?: string
): TsaThunkAction => async dispatch => {
  try {
    dispatch(clientAction.request({}))
    // This is not a mistake. We get engagements and expand clients.
    return dispatch(
      clientAction.success({
        clients: normalizeEngagementArray(
          await trackGet(
            EngagementsApi.apiGetEngagementsWithClient,
            dispatch,
            id
          )
        ).clients,
      })
    )
  } catch (error) {
    return dispatch(clientAction.failure(error))
  }
}

import React, { useState } from 'react'
import { usePermissionManager } from '../../hooks'
import { Theme, useTheme } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'
import Toggle from 'react-switch'
import { Anchor } from '../anchor'

function styles({ palette }: Theme) {
  return {
    cancelButton: {},
    messageText: {
      display: 'inline',
      fontWeight: 'Bold',
      paddingRight: '40px',
      color: 'white',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ProxyToggle = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { impersonateUser } = usePermissionManager()
  const [proxyTimer, setProxyTimer] = useState<NodeJS.Timeout | null>()
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [userImpersonated, setUserImpersonated] = useState<boolean>(
    impersonateUser
  )
  const { isExternalUser, toggleUserView } = usePermissionManager()

  const toggleUser = (
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent
  ) => {
    setRefreshing(true)
    setUserImpersonated(!userImpersonated)
    const tmr = setTimeout(() => {
      toggleUserView()
    }, 3000)
    setProxyTimer(tmr)
    event.preventDefault()
  }

  const clearTimer = () => {
    setRefreshing(false)
    setUserImpersonated(!userImpersonated)
    if (proxyTimer) {
      clearInterval(proxyTimer)
      setProxyTimer(null)
    }
  }

  return !isExternalUser || userImpersonated ? (
    <>
      {refreshing && (
        <label htmlFor='show-all-tasks'>
          <span>Refreshing...</span>
          <Anchor onClick={clearTimer} className={classes.messageText}>
            Cancel
          </Anchor>
        </label>
      )}
      <label htmlFor='show-all-tasks'>
        <span>Client View</span>
        <Toggle
          checked={userImpersonated}
          handleDiameter={24}
          height={20}
          onChange={(checked, event, id) => toggleUser(event)}
          onColor='#3F9C46'
          width={48}
        />
      </label>
    </>
  ) : (
    <></>
  )
}

export default ProxyToggle

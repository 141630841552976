import { groupBy } from 'lodash'
import {
  EngagementQuestion,
  EngagementSectionMap,
  EntityMap,
  FileGroup,
  IEngagementQuestionAnswer
} from '../clientModels'
import { ensureNumber, isDefined } from '../guards'
import { AppState } from '../store'
import { DocumentTitleId } from '../enums'

/** Returns the current state.bannerFilter.selectedFilter. */
export const getSelectedBannerFilter = (state: AppState) =>
  state.bannerFilter.selectedFilter

/** Returns the bannerFilter.filters from the state. */
export const getBannerFilters = (state: AppState) => state.bannerFilter.filters

export const getUser = (state: AppState) => state.auth.user

export const getEngagements = (state: AppState) => state.engagements
export const getClients = (state: AppState) => state.clients || {}
export const getPhases = (state: AppState) => state.phases || {}
export const getEngagementByEngagementId = (
  state: AppState,
  id: string | number
) => state.engagements[ensureNumber(id)]
export const getEngagementTemplates = (state: AppState) =>
  state.engagementTemplates
export const getEngagementTemplatebyId = (state: AppState, id: number) =>
  state.engagementTemplates[id]
export const getQuestions = (state: AppState) => state.questions
export const getQuestionByQuestionId = (state: AppState, id: number | string) =>
  state.questions[id]
export const getSections = (state: AppState) => state.sections
export const getSectionGroups = (state: AppState) =>
  state.engagementSectionGroups
export const getSectionBySectionId = (state: AppState, id: number | string) =>
  state.sections[id]
export const selectAnswers = (state: AppState) => state.engagementQuestions

export const getEngagementAnswers = (
  state: AppState,
  engagementId: number
): EntityMap<IEngagementQuestionAnswer> | undefined =>
  state.engagementQuestions[engagementId]

export const selectAnswer = (
  state: AppState,
  engagementId: number | string,
  questionId: number | string
): IEngagementQuestionAnswer | undefined =>
  state.engagementQuestions[engagementId] &&
  state.engagementQuestions[engagementId]![questionId]

export const getEngagementQuestions = (
  state: AppState,
  engagementId: number | string
) => state.engagementQuestions[engagementId]

export const getEngagementQuestion = (
  state: AppState,
  engagementId: number,
  questionId: number
) =>
  state.engagementQuestions[engagementId] &&
  state.engagementQuestions[engagementId]![questionId]

export const getEngagementSections = (
  state: AppState,
  engagementId: number | string
) => state.engagementSections[engagementId]

export const getEngagementSection = (
  state: AppState,
  engagementId: number,
  sectionId: number
) =>
  state.engagementSections[engagementId] &&
  state.engagementSections[engagementId]![sectionId]

export const getEngagementSectionOffMap = (
  engagementSectionMap?: EngagementSectionMap,
  sectionId?: number
) => {
  const engagementSection =
    engagementSectionMap && engagementSectionMap[sectionId || -1]
  return engagementSection
}

export const selectQuestionFileGroups = (
  fileGroups: EntityMap<FileGroup>,
  questionId: string | number
) => {
  questionId = ensureNumber(questionId)
  return Object.values(fileGroups)
    .filter(isDefined)
    .filter(g => g.questionId === questionId)
}

export const selectEngagementFileGroups = (
  fileGroups: EntityMap<FileGroup>,
  engagementId: string | number
) => {
  engagementId = ensureNumber(engagementId)
  const engagementGroups = Object.values(fileGroups)
    .filter(isDefined)
    .filter(f => f.engagementId === engagementId)
  return groupBy(engagementGroups, 'questionId')
}

export const selectK1FileGroup = (
  fileGroups: EntityMap<FileGroup>,
  engagementId: string | number
) => {
  engagementId = ensureNumber(engagementId)
  const k1Group = Object.values(fileGroups)
    .filter(isDefined)
    .filter(f => f.engagementId === engagementId)
    .filter(f => f.documentTitleId === DocumentTitleId.K1Document)
  return (k1Group && k1Group.length > 0 && k1Group[0]) || undefined
}

export const selectEtlFileGroup = (
  fileGroups: EntityMap<FileGroup>,
  engagementId: string | number
) => {
  engagementId = ensureNumber(engagementId)
  const etlGroup = Object.values(fileGroups)
    .filter(isDefined)
    .filter(f => f.engagementId === engagementId)
    .filter(f => f.documentTitleId === DocumentTitleId.EtlDocument)
  return (etlGroup && etlGroup.length > 0 && etlGroup[0]) || undefined
}

export const getDocumentTitles = (state: AppState) => state.documentTitles

export const getActivitiesByEngagementIdQuestionId = (
  state: AppState,
  engagementId: number,
  questionId: number
) =>
  state.activities[engagementId] && state.activities[engagementId]![questionId]

export const getActivitiesByEngagementId = (
  state: AppState,
  engagementId: number
) => state.activities[engagementId]
export const getLastNavigations = (state: AppState) =>
  state.userSettings.lastNavigationMap
export const getShowAllQuestions = (
  state: AppState,
  engagementId: string | number
) => {
  const visibility = state.visibility[engagementId]
  return !!visibility && visibility.showAllQuestions
}

export const getShowIncompleteOnly = (
  state: AppState,
  engagementId: string | number
) => {
  const visibility = state.visibility[engagementId]
  return !!visibility && visibility.showIncompleteOnly
}

export const hasError = (engagementQuestion: EngagementQuestion) =>
  engagementQuestion.messages.some(
    m => m.severity === 'critical' || m.severity === 'error'
  )

export const isEngagementTemplateLoaded = (
  state: AppState,
  taxForm: string,
  taxYear: number
) => {
  const engagementTemplates = state.engagementTemplates || {}
  return Object.values(engagementTemplates).some(
    t =>
      !!t && t.resourceType === taxForm && t.taxYear === taxYear && !!t.loaded
  )
}

export function areDocumentTitlesLoaded(state: AppState) {
  const documentTitles = state.documentTitles
  return documentTitles && documentTitles.length > 0
}

import classNames from 'classnames'
import { uniqueId } from 'lodash'
import * as React from 'react'
import { isStringOrNullOrUndefined } from '../../guards'
import { getNumberFormat } from '../../services/formatters/index'
import { Alerts } from './alerts/index'
import { FocusableComponent, FormFieldProps } from './formComponentsInterfaces'
import NumberFormat, {
  NumberFormatValues,
  SourceInfo
} from 'react-number-format'
import './forms.scss'

interface TextBoxProps extends FormFieldProps {
  allowNegative?: boolean
  className?: string
  decimalScale?: number
  decimalSeparator?: string
  fixedDecimalScale?: boolean
  format?: string
  mask?: string
  prefix?: string
  suffix?: string
  thousandSeparator?: boolean
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

interface NumberValues {
  floatValue: number
  formattedValue: string
  value: string
}

// tslint:disable-next-line:no-any
function TextInput(props: any) {
  return <input className='form-control' {...props} />
}

export default class TextBox extends React.Component<TextBoxProps>
  implements FocusableComponent {
  static defaultProps = {
    allowNegative: true,
    mask: '_',
    showAlerts: true,
    thousandSeparator: true
  }

  inputId: string
  labelId: string
  hintId: string
  input?: HTMLInputElement

  constructor(props: TextBoxProps) {
    super(props)
    const id = uniqueId('textbox_')
    this.inputId = `${id}_input`
    this.labelId = `${id}_label`
    this.hintId = `${id}_hint`
  }

  componentDidMount() {
    const { selectedPath, path } = this.props

    if (selectedPath && path && path === selectedPath) {
      this.focus()
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: TextBoxProps) {
    if (
      nextProps.selectedQuestion &&
      nextProps.selectedPath &&
      nextProps.path === nextProps.selectedPath
    ) {
      this.focus()
    }
  }

  focus() {
    const input = this.input
    if (input) {
      input.focus()
    }
  }

  handleChange = (values: NumberFormatValues) => {
    const { path, onChange } = this.props
    if (onChange) {
      onChange(values.value, path)
    }
  }

  handleBlur = () => {
    const { path, onBlur } = this.props
    if (onBlur) {
      onBlur(path)
    }
  }

  handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
    const { path, onFocus } = this.props
    if (onFocus) {
      onFocus(path)
    }
  }

  setInput = (ref: HTMLInputElement) => (this.input = ref)

  render() {
    const {
      allowNegative,
      autoFocus,
      className,
      decimalScale,
      decimalSeparator,
      disabled,
      fixedDecimalScale,
      format,
      hint,
      jsonSchema,
      label,
      mask,
      messages,
      onKeyDown,
      placeholder,
      prefix,
      showAlerts,
      suffix,
      thousandSeparator,
      value
    } = this.props

    let val = value
    if (!isStringOrNullOrUndefined(val)) {
      throw new Error('Number expects a string value.')
    }

    if (val === null || val === '') {
      val = undefined
    }

    const formatProps = getNumberFormat(jsonSchema)

    return (
      <div className={classNames(className, 'form-group', { disabled })}>
        {label && <label id={this.labelId}>{label}</label>}
        {hint && (
          <div id={this.hintId} className='hint-text'>
            {hint}
          </div>
        )}
        <NumberFormat
          allowNegative={allowNegative}
          autoFocus={autoFocus}
          customInput={TextInput}
          decimalScale={decimalScale}
          decimalSeparator={decimalSeparator}
          disabled={disabled}
          fixedDecimalScale={fixedDecimalScale}
          format={format || undefined}
          isNumericString={true}
          mask={mask || undefined}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyDown={onKeyDown}
          onValueChange={this.handleChange}
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          thousandSeparator={thousandSeparator}
          value={val as string | number}
          {...formatProps}
        />
        {showAlerts && <Alerts messages={messages} />}
      </div>
    )
  }
}

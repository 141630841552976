/**
 * FileErrorsDialog is a function component that retrieves errors for a file and displays them in a dialog.
 */
import React, { useState, useEffect } from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { Theme } from '@rsmus/react-ui'
import { InfoDialog } from '../modals'
import Icon from '../icon/icon'
import { isDefinedNotNull } from '../../guards'
import { getFileErrors } from '../../actions/etlThunks'
import { useDispatch } from 'react-redux'
import { TsaDispatch } from '../../store'

const styles = ({ palette }: Theme) => {
  return {
    errors: {
      whiteSpace: 'pre',
      textAlign: 'left',
    },
  }
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface FileErrorsDialogProps {
  fileId: number
  onClose: () => void
}

const FileErrorsDialog = ({ fileId, onClose }: FileErrorsDialogProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [errors, setErrors] = useState<string[] | null>(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const tsaDispatch: TsaDispatch = dispatch

  useEffect(() => {
    async function fetchData() {
      if (!loading && errors === null) {
        setLoading(true)
        const fileErrors = await tsaDispatch(getFileErrors(fileId))
        setErrors(fileErrors)
        setLoading(false)
      }
    }
    fetchData()
  }, [fileId, setErrors, loading, errors, tsaDispatch])

  const handleClose = () => {
    setErrors(null)
    onClose()
  }

  const formatMessage = (msg: string): string => {
    if (isDefinedNotNull(msg) === false) return ''
    return msg.replace(/\\r/g, '\r').replace(/\\n/g, '\n')
  }

  return (
    <InfoDialog
      enableCopy={true}
      copyText={errors?.map(e => formatMessage(e)).join('\n')}
      onClose={handleClose}
      onClickPrimary={handleClose}
      info=''
      title='File status errors'
    >
      <div className={classes.errors}>
        {loading && <Icon icon={'loading'} />}
        {errors?.map(e => formatMessage(e))}
      </div>
    </InfoDialog>
  )
}

export default FileErrorsDialog

interface Batch<T> {
  values: T[]
  // tslint:disable-next-line:no-any
  timeout?: any
}

/**
 * Holds a batch of values by key. The callback is executed when the specified
 * timeout has been reached or the maximum batch size has been reached, whichever
 * comes first.
 */
export class DebouncedBatchMap<TKey, TValue> {
  private batches: Map<TKey, Batch<TValue>>

  /**
   *
   * @param callback The callback to be executed with a batch of values.
   * @param timeout The maximum time to wait before calling the callback in milliseconds.
   * @param maxBatchSize The maximum batch size before the callback is called.
   */
  constructor (
    private callback: (key: TKey, value: TValue[]) => void,
    private timeout: number = 10000,
    private maxBatchSize: number = 50
  ) {
    this.batches = new Map<TKey, Batch<TValue>>()
  }

  /**
   * Add a value to the batch. This operation resets the
   * timeout for the specifed batch to zero.
   */
  addValue (key: TKey, value: TValue) {
    const batch = this.batches.get(key)
    if (batch) {
      this.updateBatch(batch, key, value)
    } else {
      this.createBatch(key, value)
    }
  }

  private updateBatch (batch: Batch<TValue>, key: TKey, value: TValue) {
    clearTimeout(batch.timeout)
    batch.values.push(value)
    if (batch.values.length >= this.maxBatchSize) {
      this.processBatch(key)
    } else {
      this.setTimeout(key, batch)
    }
  }

  private createBatch (key: TKey, value: TValue) {
    const batch = { values: [value] }
    this.batches.set(key, batch)
    this.setTimeout(key, batch)
  }

  private setTimeout (key: TKey, batch: Batch<TValue>) {
    batch.timeout = setTimeout(() => this.processBatch(key), this.timeout)
    return batch
  }

  private processBatch (key: TKey) {
    const batch = this.batches.get(key)
    if (!batch) {
      return
    }

    clearTimeout(batch.timeout)
    this.batches.delete(key)

    this.callback(key, batch.values)
  }
}

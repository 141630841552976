import { getType } from 'typesafe-actions'
import {
  deleteActivityAction,
  getActivitiesAction,
  saveActivityAction,
} from '../actions/activityActions'
import { answerGetAction, answerSaveAction } from '../actions/answerActions'
import * as asyncOperationsActions from '../actions/asyncOperationsActions'
import { authGetUserFailAction } from '../actions/authenticationActions'
import { clientAction } from '../actions/clientActions'
import * as clientSearchActions from '../actions/clientSearchActions'
import * as clientAccessRequestSearchActions from '../actions/clientAccessRequestSearchActions'
import * as engagementActions from '../actions/engagementActions'
import { requestEngagementReportFailAction } from '../actions/engagementReportActions'
import {
  engagementTemplateFailAction,
  engagementTemplatesDocErrorAction,
} from '../actions/engagementTemplateActions'
import { allEntitiesAction, entityAction } from '../actions/entityActions'
import { errorClearAction } from '../actions/errorActions'
import * as fileActions from '../actions/fileActions'
import { TsaHubAction } from '../actions/index'
import {
  getCategoryOptionsActions,
  getInputProcessTemplatesActions,
  getPivotOptionsActions,
  getReceivingPartnersAction,
  getWorksheetOptionsFailAction,
  processInputWorksheetFailAction,
  processK1MappingsFailAction,
  setTaxCodeDataAction,
  updateOcrDataAction,
  validateK1FileAction,
} from '../actions/inputProcessActions'
import { phaseFailAction, phaseListFailAction } from '../actions/phaseActions'
import { reviewDoneAction } from '../actions/reviewActions'
import { taskAction } from '../actions/taskActions'
import { ExtError } from '../services/error'
import * as etlActions from '../actions/etlActions'

// tslint:disable:no-console
export interface ErrorData {
  error?: Error | ExtError
  actionType?: string
}
const initialState: ErrorData[] = []

export const appErrorReducer = (
  state: ErrorData[] = initialState,
  action: TsaHubAction
): ErrorData[] => {
  switch (action.type) {
    case getType(errorClearAction):
      state = [] // clear the error store
      break
    case getType(answerGetAction.failure):
    case getType(asyncOperationsActions.getAsyncOperationsAction.failure):
    case getType(authGetUserFailAction):
    case getType(clientAction.failure):
    case getType(clientSearchActions.clientSearchFailAction):
    case getType(clientAccessRequestSearchActions.clientSearchFailAction):      
    case getType(clientAccessRequestSearchActions.clientSearchSubmitFailAction):
    case getType(engagementActions.getEngagementAction.failure):
    case getType(engagementActions.getEngagementListAction.failure):
    case getType(engagementActions.getLastYearsDataAction.failure):
    case getType(engagementActions.submitEngagementAction.failure):
    case getType(engagementActions.toggleFavoriteEngagementAction.failure):
    case getType(engagementActions.updateEngagementMilestoneAction.failure):
    case getType(engagementActions.updateEngagementPhaseAction.failure):
    case getType(engagementTemplateFailAction):
    case getType(engagementTemplatesDocErrorAction):
    case getType(etlActions.getFileErrorsActions.failure):
    case getType(fileActions.addUpdateFileGroup.failure):
    case getType(fileActions.addUpdateFileGroupTemplateFailure):
    case getType(fileActions.deleteFileGroup.failure):
    case getType(fileActions.downloadFile.failure):
    case getType(fileActions.softDeleteFilesStatus.failure):
    case getType(fileActions.uploadEngagementSetup.failure):
    case getType(getCategoryOptionsActions.failure):
    case getType(etlActions.getEntityOptionsActions.failure):
    case getType(entityAction.failure):
    case getType(allEntitiesAction.failure):
    case getType(getInputProcessTemplatesActions.failure):
    case getType(getPivotOptionsActions.failure):
    case getType(getReceivingPartnersAction.failure):
    case getType(getWorksheetOptionsFailAction):
    case getType(phaseFailAction):
    case getType(phaseListFailAction):
    case getType(requestEngagementReportFailAction):
    case getType(reviewDoneAction.failure):
    case getType(taskAction.failure):
    case getType(validateK1FileAction.failure):
      state = processError(state, action.payload as ExtError, action.type, true)
      break
    case getType(processK1MappingsFailAction):
    case getType(processInputWorksheetFailAction):
    case getType(answerSaveAction.failure):
    case getType(setTaxCodeDataAction.failure):
    case getType(updateOcrDataAction.failure):
    case getType(saveActivityAction.failure):
    case getType(getActivitiesAction.failure):
    case getType(deleteActivityAction.failure):
    case getType(engagementActions.startLoadAnswersEtlAction.failure):
    case getType(engagementActions.duoExportAction.failure):
      state = processError(state, action.payload as ExtError, action.type)
      break
    default:
      break
  }

  return state
}

function processError(
  state: ErrorData[],
  error: ExtError,
  actionType: string,
  allowSilent?: boolean
): ErrorData[] {
  logError(error)
  if (allowSilent && error.silent) {
    return state
  }
  return [...state, { error: { ...error }, actionType }]
}

function logError(error: ExtError): void {
  console.log('Error Status: ' + error.status)
  console.log('Error Message: ' + error.message)
  console.log('Error Type: ' + error.name)
  console.log('Error Stack: ' + error.stack)
}

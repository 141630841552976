import classNames from 'classnames'
import * as React from 'react'
import { matchPath } from 'react-router-dom'
import { isNumber } from '../../guards'
import { Anchor } from '../anchor'
import Icon from '../icon/icon'
import { Icons } from '../icon/icons'
import { UserPermissions } from '../../enums'

interface BadgeProps {
  count: number
}

const Badge = ({ count }: BadgeProps) => (
  <span className='badge'>{count > 999 ? '999+' : count}</span>
)

export interface NavItem {
  /**
   * If a count is provided an alert badge will be displayed in the navigation item.
   */
  badgeCount?: number
  /**
   * If true then the location must match the "path" prop exactly before it will be
   * considered active. See React Router documentation for details.
   */
  exact?: boolean
  /**
   * The icon name for the navigation item.
   */
  icon: keyof Icons
  /**
   * If true the navigation item will only be displayed to internal (RSM) users.
   */
  isInternal?: boolean
  /**
   * The navigation item title.
   */
  title: string
  /**
   * A navigation path for React Router.
   */
  path: string
  /**
   * Display if user has this permission.
   */
  permission?: UserPermissions
  /**
   * Render content in the sub-navigation panel.
   */
  render?: () => React.ReactNode
}

interface LeftNavItemProps extends NavItem {
  navigateOnClick: boolean
  onClick: (item: NavItem, e: React.MouseEvent) => void
}

export class LeftNavItem extends React.Component<LeftNavItemProps> {
  static defaultProps = { navigateOnClick: true }

  render() {
    const { path, exact } = this.props

    const active = matchPath(window.location.pathname, { path, exact })
    return (
      <Anchor className={classNames({ active })} onClick={this.handleClick}>
        {this.renderContents()}
      </Anchor>
    )
  }

  private renderContents() {
    const { badgeCount, icon, title } = this.props
    return (
      <div>
        <Icon icon={icon} tooltip={title} />
        <div className='title'>{title} </div>
        {this.renderBadge(badgeCount)}
      </div>
    )
  }

  private renderBadge(count?: number) {
    return isNumber(count) && count > 0 ? <Badge count={count} /> : null
  }

  private handleClick = (e: React.MouseEvent) => {
    this.props.onClick(this.props, e)
  }
}
